import { observable, action, makeObservable } from "mobx";

class InvoiceStore {

  constructor() {
    makeObservable(this)
  }

  // 总金额
  @observable invoiceAmount = "0.00"

  // 开票选择数据
  @observable invoiceList = []

  // 设置金额
  @action.bound setInvoiceAmount(val) {
    this.invoiceAmount = val
  }

  // 设置开票选择数据
  @action.bound setInvoiceList(list) {
    this.invoiceList = list
  }
}

export default new InvoiceStore();