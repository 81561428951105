
import { amapUIKey } from './variable';
import { getBaseCitys } from './localStorageHandler'


let AMap = undefined

// 高德地图适配器
class AMapAdapter {

    loadTask = undefined;

    async init() {
        this.loadTask = this.loadAMap();
        AMap = await this.loadTask;
        return new Promise((ok, err) => {
            AMap.service(['AMap.PlaceSearch', 'AMap.Geocoder'], () => {
                ok()
            })
        })
    }

    /**
     * 加载高德地图
     */
    loadAMap() {
        return new Promise((resolve, reject) => {
            if (AMap || window.AMap) {
                resolve(AMap || window.AMap);
                return
            }
    
            let script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://webapi.amap.com/maps?v=1.4.15&key=' + amapUIKey;
            script.onerror = reject;
    
            script.onload = () => {
                resolve(window.AMap);
            };
            document.getElementsByTagName('head')[0].appendChild(script);
        });
    }

    /**
     * 地址检索
     * @param {*} key 
     * @param {*} cityName 
     * @param {*} lang 
     * @returns 
     */
    async place(key, cityName, lang) {
        let cityDistrict = ''
        let baseCitys = getBaseCitys();
        let ct = baseCitys.filter(a => a.cityName === cityName || a.cityPinYin === cityName)
        if (ct && ct.length) {
            let _ct = ct[0]
            cityDistrict = _ct.cityDistrict
        }

        if (this.loadTask) {
            await this.loadTask;
        }
        let placeSearch = new AMap.PlaceSearch({
            city: cityDistrict,
            citylimit: true,
            lang: lang
        });

        return new Promise((ok, err) => {
            placeSearch.search(key, (status, result) => {
                if (status === 'complete') {
                    let res = {
                        data: {
                            status: 0,
                            message: 'query ok',
                            count: result.poiList.count,
                            data: result.poiList.pois.map(a => {
                                return {
                                    id: a.id,
                                    title: a.name,
                                    address: a.address,
                                    category: a.type,
                                    type: a.shopinfo,
                                    _distance: 0,
                                    location: {
                                        lat: a.location.lat,
                                        lng: a.location.lng
                                    },
                                    adcode: '',
                                    province: '',
                                    city: cityName,
                                    district: '',
                                }
                            }),
                            sub_pois: []
                        }
                    }
                    ok(res)
                } else {
                    err(status)
                }
            })
        })
    }

    /**
     * 逆地理位置查询
     * @param {*} lng 
     * @param {*} lat 
     */
    async geocoder(lng, lat) {
        let geocoder = new AMap.Geocoder({
            radius: 1000,
            extensions: 'all',
            lang: 'en'
        });

        return new Promise((ok, err) => {
            geocoder.getAddress([lng, lat], (status, result) => {
                if (status === 'complete') {
                    let res = {
                        data: {
                            status: 0,
                            message: 'query ok',
                            result: {
                                address: result.regeocode.formattedAddress,
                                address_component: {
                                    nation: result.regeocode.addressComponent.country,
                                    province: result.regeocode.addressComponent.province,
                                    city: result.regeocode.addressComponent.city,
                                    district: result.regeocode.addressComponent.district,
                                    street: result.regeocode.addressComponent.street,
                                    street_number: result.regeocode.addressComponent.streetNumber,
                                },
                                pois: result.regeocode.pois.map(a => {
                                    return {
                                        id: a.id,
                                        title: a.name,
                                        address: a.address,
                                        category: a.type,
                                        location: {
                                            lat: a.location.lat,
                                            lng: a.location.lng
                                        },
                                        _distance: a.distance
                                    }
                                })
                            },
                            
                        }
                    }
                    ok(res)
                } else {
                    err(status)
                }
            })
        })
        
    }

}



export default AMapAdapter;