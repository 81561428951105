/**
 * 接机礼宾弹出
 */
import React from 'react'
import style from './concierge.scss';
import { Dialog, Popup} from 'antd-mobile'
import {CloseOutline} from 'antd-mobile-icons'

import {airportPickupToCn} from './../../util/paramsHelper';

import CancelReason from './cancelReason';
import webService from '../../util/webService';

class Concierge extends React.Component {

    state = {
        showCancelReason: false,
        airportPickup: this.props.airportPickup
    }

    t = this.props.t

    render() {
        let {airportPickup} = this.state;
        return <div className={style.concierge}>
            <div className={style.title}>{this.t('runing.k057')}
                <div><CloseOutline onClick={() => {
                    this.props.onClose()
                }} /></div>
            </div>
            {
                airportPickup.state === 0
                    ? (<div className={style.waiting}>
                        <div>{this.t('runing.k058')}</div>
                        <div onClick={() => {
                                this.setState({
                                    showCancelReason: true
                                })
                            }}>{this.t('runing.k059')}</div>
                    </div>)
                    : (<div className={style.info}>
                        <div>
                            <div>
                                <img src={airportPickup.concierge.avatar} alt={this.t('runing.k075')} />
                            </div>
                            <div>
                                <div>{airportPickup.concierge.name}({this.t('runing.k060')}{airportPickup.concierge.code})</div>
                                <div>{this.t('runing.k061')}</div>
                            </div>
                            <div>{airportPickupToCn(airportPickup.state, this.t)}</div>
                        </div>
                        <div>
                            <div onClick={() => {
                                this.setState({
                                    showCancelReason: true
                                })
                            }}>{this.t('runing.k059')}</div>
                            <div onClick={() => {
                                window.top.location.href="tel://" + airportPickup.concierge.phone
                            }}>{this.t('runing.k062')}</div>
                        </div>
                    </div>)
            }            

            <Popup
                visible={this.state.showCancelReason}
                onMaskClick={() => {
                    this.setState({
                        showCancelReason: false
                    })
                }}
            >
                <CancelReason onClick={(confirm, reason) => {
                    this.setState({
                        showCancelReason: false
                    })
                    if (confirm) {
                        this.cancelAirportPickup(reason)
                    }
                }} list={[
                    this.t('runing.k063'),
                    this.t('runing.k064'),
                    this.t('runing.k065'),
                    this.t('runing.k066'),
                    this.t('runing.k067')
                ]} cancelText={this.t('runing.k068')} />
            </Popup>
        </div>
    }

    componentWillReceiveProps(props) {
        this.setState({
            airportPickup: props.airportPickup
        })
    }

    cancelAirportPickup = async (reason) => {
        let d = await webService.order.cancelAirportPickup({orderId: this.props.orderId, reason: reason});
        if (d.data.code !== 0) {
            Dialog.alert({
                content: d.data.message,
                confirmText: this.t('common.k003')
            })
        } else {
            this.props.onClose()
        }
    }
}
export default Concierge