import React from "react"
import style from './404.scss'

import images from '../util/images'
import {customerServiceTelephone} from '../util/config'

class NotFound extends React.Component {

    render() {
        return <div className={style.container}>
            <div className={style.tips}>
                <div>
                    <img src={images.notfount} alt='找不到' />
                </div>
                <div>抱歉,出错了...请移动至信号较强的位置再次尝试或拨打客服电话{customerServiceTelephone}</div>
            </div>
        </div>
    }
}

export default NotFound