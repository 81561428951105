import React from 'react';
import style from './smartChoose.scss';
import {inject, observer } from 'mobx-react'
import { Button, Mask, Dialog } from 'antd-mobile'

import images from './../../util/images';

import SmartChooseDetails from './smartChooseDetails';

@inject('globalStore','mainStore', 'estimateStore', 'confirmStore')
@observer
class SmartChoose extends React.Component {

    state = {
        activeLevel: 1,
        cars: [],
        scene: {},
        currentCarLevelDetails: {},

        showDetails: false
    }
    globalStore = this.props.globalStore;
    mainStore = this.props.mainStore;
    estimateStore = this.props.estimateStore;
    confirmStore = this.props.confirmStore;

    t = this.props.t

    render() {
        return <div className={style.container}>

            <div className={style.cars}>
                {
                    this.state.cars.filter(a => a.list.length > 0).map(item => {
                        return <div key={item.id} className={this.state.activeLevel === item.id ? `${style.active} ${style.car}` : style.car} onClick={() => {this.fillCurrentCarLevelDetails(item)}}>
                            <div className={style.cover}>
                                <img src={this.findCarLevelImage(item.id)} alt="车型图标" />
                            </div>
                            <div className={style.carname}>{item.nameCn}</div>
                            <div className={style.reimbursement}>{item.reimbursementCn}</div>
                            <div className={style.pointer}>
                            <div></div>
                            </div>
                        </div>
                    })
                }
                                
            </div>

            <div className={style.price}>
                {
                    this.state.cars.filter(a => a.id === this.state.activeLevel).map(item => {
                        return <div key={item.id} className={style._price} onClick={() => {this.setState({showDetails: true})}} >
                            <span>￥ </span>{item.priceLab}
                        </div>   
                    })
                }
                  
            </div>
            <div className={style.btncont}>
                <Button block color='primary' size='large' onClick={this.call}>{this.t('estimate.k012')}</Button>
            </div>

            <Mask
                visible={this.state.showDetails}
                onMaskClick={() => {this.setState({showDetails: false})}}
            >
                <SmartChooseDetails t={this.props.t} currentCarLevelDetails={this.state.currentCarLevelDetails} onHide={() => {this.setState({showDetails: false})}} />
            </Mask>
            
        </div>
    }

    componentDidMount() {
    }

    init = (data) => {
        this.estimateStore.setEstimateId(data.data.estimateId)

        for (let i in data.data.cars) {
            let car = data.data.cars[i];
            car.checkCount = 0;
            car.checkAll = false;
        }

        this.setState({
            scene: data.data.scene,
            cars: data.data.cars
        })

        setTimeout(() => {
            let carlevel = undefined;
            if (this.estimateStore.choosedEstimateCarLevel > 0) {
                for (let i in data.data.cars) {
                    let item = data.data.cars[i];
                    if (item.id === this.estimateStore.choosedEstimateCarLevel) {
                        carlevel = item;
                    }
                }
                
            } else {
                carlevel = data.data.cars[0];
            }

            this.fillCurrentCarLevelDetails(carlevel);
            
        }, 200);
        
    }

    /**
     * 获取车型图标
     * @param {*} carLevelId 
     * @returns 
     */
    findCarLevelImage = (carLevelId) => {
        switch(carLevelId) {
            case 1:
                return images.car_ecnomy;
            case 2:
                return images.car_taxi;
            case 3:
                return images.car_deluxe;
            case 4:
                return images.car_business;
            default:
                return images.car_ecnomy;
        }
    }

    /**
     * 填充当前激活车型
     * @param {*} item 
     */
    fillCurrentCarLevelDetails = (item) => {
        let { cars, currentCarLevelDetails} = this.state;
        let _item = cars.filter(a => a.id === item.id)[0];
        let lastIndex = _item.list.length - 1;
        currentCarLevelDetails.leftSourceName = _item.list[0].carSource;
        currentCarLevelDetails.rightSourceName = _item.list[lastIndex].carSource;
        currentCarLevelDetails.leftLogo = _item.list[0].carSourceImg;
        currentCarLevelDetails.rightLogo = _item.list[lastIndex].carSourceImg;
        currentCarLevelDetails.leftPrice = _item.list[0].estimatePrice;
        currentCarLevelDetails.rightPrice = _item.list[lastIndex].estimatePrice;
        currentCarLevelDetails.leftLeveName = _item.list[0].carLevelName;
        currentCarLevelDetails.rightLeveName = _item.list[lastIndex].carLevelName;
        currentCarLevelDetails.leftTip = _item.list[0].priceTip ? '' : '1';
        currentCarLevelDetails.rightTip = _item.list[lastIndex].priceTip? '' : '1';

        this.setState({
            activeLevel: item.id,
            currentCarLevelDetails: currentCarLevelDetails
        });
    }


    /**
     * 跳转到下一步
     */
    call = () => {
        let {currentCarLevelDetails} = this.state;
        // 场景限制:最大可用额度,单笔最大额度
        let {availableAmount, maxAmountPerOrder} = this.state.scene;

        if (maxAmountPerOrder !== -1 && currentCarLevelDetails.rightPrice > maxAmountPerOrder) {
            Dialog.alert({
                content: this.t('estimate.k013'),
                confirmText: this.t('common.k003')
            })
            return;
        }

        if (availableAmount !== -1 && currentCarLevelDetails.rightPrice > availableAmount) {
            Dialog.alert({
                content: this.t('estimate.k014'),
                confirmText: this.t('common.k003')
            })
            return;
        }

        let choosedEstimates = this.state.cars.filter(a => a.id === this.state.activeLevel)[0].list;
        this.estimateStore.setChoosedEstimateCarLevel(this.state.activeLevel);
        this.estimateStore.setChoosedEstimates(choosedEstimates);

        // this.confirmStore.clear();
        this.props.history.push('/confirm')
    }
}

export default SmartChoose;