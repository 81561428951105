import React from 'react'
import style from './chooseCoupon.scss';
import {inject, observer } from 'mobx-react'
import { InformationCircleOutline } from 'antd-mobile-icons'
import { Dialog } from 'antd-mobile'

import { withTranslation } from "react-i18next";

import Coupon from './../component/callcar/coupon'


@inject('confirmStore')
@observer
class ChooseCoupon extends React.Component {

    state = {};
    confirmStore = this.props.confirmStore;

    t = this.props.t

    render() {
        return <div className={style.container}>
            <div className={style.cancel} onClick={() => {
                this.confirmStore.setCoupon(undefined);
                this.props.history.goBack()
            }}>
                <div>{this.t('chooseCoupon.k010')}</div>                
            </div>
            <div className={style.alert}>
                <InformationCircleOutline color="var(--adm-color-danger)"></InformationCircleOutline>　
                {this.t('chooseCoupon.k011')}
            </div>
            {
                this.confirmStore.dependency.coupons.filter(a => a.isValid === true).map(item => {
                    return <Coupon t={this.props.t} data={item} key={item.id} onClick={() => {
                        this.confirmStore.setCoupon(item);
                        this.props.history.goBack();
                    }} />
                })
            }

            {
                this.confirmStore.dependency.coupons.filter(a => a.isValid === false).map(item => {
                    return <div key={item.id} >
                        <Coupon t={this.props.t} data={item} onClick={() => {
                            Dialog.alert({
                                content: this.t('chooseCoupon.k012'),
                                confirmText: this.t('common.k003')
                            })
                        }} />
                        <div className={style.reason}>
                            <InformationCircleOutline color="var(--adm-color-danger)"></InformationCircleOutline>　
                            { this.getInvalidReason(item) }
                        </div>
                    </div>
                })
            }

        </div>
    }

    /**
     * 获取不可用原因
     * @param {*} item 
     * @returns 
     */
    getInvalidReason = (item) => {
        let reasion;
        switch(item.invalidReason) {
            case 1:
                reasion = this.t('chooseCoupon.k013');
                break; 
            case 2:
                reasion = this.t('chooseCoupon.k014');
                break;
            default:
                reasion = '';
                break;
        }
        return reasion;
    }
}

export default withTranslation()(ChooseCoupon);