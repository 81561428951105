import React from "react";

import style from './privacyAgreement.scss'

import webService from "../../util/webService";

import { inject, observer } from 'mobx-react'
import { Modal, Toast, Dialog } from 'antd-mobile'

@inject('globalStore')
@observer
class PrivacyAgreement extends React.Component {
  state = {
    visible: true,
    isPrivacy: false
  }

  globalStore = this.props.globalStore

  get isShowPrivacy() {
    return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.main_hide_privacy
  }

  t = this.props.t

  componentDidMount = () => {
    if (this.isShowPrivacy) {
      this.setState({
        isPrivacy: true,
      });
    }
  }

  render() {
    return <div>
      <Modal
        visible={this.state.visible}
        bodyClassName={style.failBody}
        title={this.isShowPrivacy ? this.t('main.k0174') : this.t('main.k0159')}
        content={
          <div>
            <div style={{whiteSpace: 'pre-wrap', color: '#9d9d9d', fontSize: '13px', maxHeight: '30vh', overflow: 'hidden', overflowY: 'auto'}}>{this.globalStore.userInfo.privacyAgreement}</div>
            <div className={style.content}>
              {
                !this.isShowPrivacy ? <label>
                    <input type="checkbox" value={this.state.isPrivacy} onChange={(e) => {
                      this.setState({
                        isPrivacy: e.target.checked
                      })
                    }}/>
                    <span className={style.agreement}>{this.t('main.k0160')}</span>
                </label> : <></>
              }
            </div>
          </div>
        }
        closeOnAction
        onAction={async () => {
          if (!this.state.isPrivacy) {
            Toast.show({
              content: this.t('main.k0161'),
              duration: 1000
            })
          } else {
            let {data} = await webService.user.setReadPrivacyAgreement({isPrivacy: this.state.isPrivacy})
            if (data.code === 0) {
              this.setState({
                isPrivacy: false
              })
              // this.getUserInfo()
              window.location.reload()
            }
          }
        }}
        actions={[
          {
            key: 'confirm',
            text: this.isShowPrivacy ? this.t('main.k0173'):this.t('common.k002'),
            primary: true,
            disabled: this.state.isPrivacy ? false : true
          },
        ]}
      />
    </div>
  }

  /**
   *
   *用户信息接口
   */
  getUserInfo = async () => {
    let d = await webService.basCity.getUserInfo();
    let {data} = d;
    if (data.code === 0) {
      this.globalStore.setUserInfo(data.data);
      if (!this.globalStore.userInfo.phone) {
        Dialog.show({
          title: this.t('main.k098'),
          content: this.t('main.k099'),
          actions: [[
            {
              key: 'go_set', text: this.t('main.k0100'), bold: true,
              onClick: () => {
                this.props.history.push('/settings')
              }
            }
          ]],
          closeOnAction: true
        })
      }
    }
  }
}

export default PrivacyAgreement