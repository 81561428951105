import React from 'react'
import style from './debug.scss';
import { inject, observer } from 'mobx-react'
import { Button, TextArea, Form, Input, Dialog } from 'antd-mobile'

import eruda from 'eruda'

import webService from '../util/webService';

import { customerServiceTelephone, ipay_url, feishu_open_url } from './../util/config'
import { createPayUrl, WeChatWorkJsSdk, DingJsSdk, copy, payType } from './../util/utils'
import { getCoords } from './../util/geo'

import { oauthType, webBaseUrl, appId } from './../util/config'

@inject('globalStore')
@observer
class Debug extends React.Component {
  state = {
    satoken: '',
    userid: '',
    userAgent: '',
    transaction_no: '',
    payUrl: '',
    orderId: '',
    driverInfo: {
      name: "",
      vehicleModel: "",
      vehicleColor: "",
      vehicleNo: "",
      level: ""
    },
    location: {
      name: ""
    },
    amount: '',
    timer: null
  }
  globalStore = this.props.globalStore;
  t = this.props.t;

  render() {
    return <div className={style.container}>
      <div>
        satoken:{this.state.satoken}
      </div>
      <div>
        userid:{this.state.userid}
      </div>
      <div>
        user-agent:{this.state.userAgent}
      </div>
      <div>
        版本号:2023-1-23 16:27
      </div>
      <div>
        window.location.origin: {window.location.origin}
      </div>
      <div>
        <TextArea placeholder='输入支付单号' maxLength={100} rows={3} value={this.state.transaction_no} onChange={(val) => this.setState({ transaction_no: val })} />
      </div>
      <div>
        <Button block color='primary' size='middle' onClick={this.createPay}>制作支付连接</Button>
      </div>
      <div>
        <a href={this.state.payUrl}>{this.state.payUrl}</a>
      </div>

      <div>
        {
          `${webBaseUrl}/?oauth=10000000&satoken=${this.state.satoken}`
        }
        <Button block color='primary' size='middle' onClick={() => {
          copy(`${webBaseUrl}/?oauth=10000000&satoken=${this.state.satoken}`)
        }}>复制授权链接</Button>
      </div>

      <br></br>

      <div>
        <Button block color='primary' size='middle' onClick={this.createConsole}>
          vConsole
        </Button>
      </div>

      <Form layout='horizontal' mode='card'>
        <Form.Item label='订单id'>
          <Input value={this.state.orderId} onChange={(val) => {
            this.setState({
              orderId: val
            })
          }} placeholder='请输入' />
        </Form.Item>
        <Form.Item label='司机名字'>
          <Input value={this.state.driverInfo.name} placeholder='请输入' onChange={(val) => {
            this.setState({
              driverInfo: {
                ...this.state.driverInfo,
                name: val
              }
            })
          }} />
        </Form.Item>
        <Form.Item label='车型'>
          <Input value={this.state.driverInfo.vehicleModel} placeholder='请输入' onChange={(val) => {
            this.setState({
              driverInfo: {
                ...this.state.driverInfo,
                vehicleModel: val
              }
            })
          }} />
        </Form.Item>
        <Form.Item label='车颜色'>
          <Input value={this.state.driverInfo.vehicleColor} placeholder='请输入' onChange={(val) => {
            this.setState({
              driverInfo: {
                ...this.state.driverInfo,
                vehicleColor: val
              }
            })
          }} />
        </Form.Item>
        <Form.Item label='车牌号'>
          <Input value={this.state.driverInfo.vehicleNo} placeholder='请输入' onChange={(val) => {
            this.setState({
              driverInfo: {
                ...this.state.driverInfo,
                vehicleNo: val
              }
            })
          }} />
        </Form.Item>
        <Form.Item label='时间评分'>
          <Input value={this.state.driverInfo.level} placeholder='请输入' onChange={(val) => {
            this.setState({
              driverInfo: {
                ...this.state.driverInfo,
                level: val
              }
            })
          }} />
        </Form.Item>
        <Form.Item label='当前位置'>
          <Input value={this.state.driverInfo.location} placeholder='请输入' onChange={(val) => {
            this.setState({
              driverInfo: {
                ...this.state.driverInfo,
                location: val
              }
            })
          }} />
        </Form.Item>
        <Form.Header />
      </Form>
      <div>
        <Button block color='primary' size='middle' onClick={() => this.call(customerServiceTelephone)}>一键报警短信</Button>
      </div>
      <br></br>
      <div>
        <Button block color='primary' size='middle' onClick={() => this.callTest(customerServiceTelephone)}>测试接口</Button>
      </div>
      <Form layout='horizontal' mode='card'>
        <Form.Item label='订单id'>
          <Input value={this.state.orderId} onChange={(val) => {
            this.setState({
              orderId: val
            })
          }} placeholder='请输入' />
        </Form.Item>
        <Form.Item label='金额'>
          <Input value={this.state.amount} onChange={(val) => {
            this.setState({
              amount: val
            })
          }} placeholder='请输入' />
        </Form.Item>
      </Form>
      <div>
        <Button block color='primary' size='middle' onClick={this.pay}>支付</Button>
      </div>
      <br></br>
      <span>oauthType: {oauthType}</span>
      <span>appId: {appId}</span>
      <br/>
      <div>
        分享
        <Button block color='primary' size='middle' onClick={this.share}>分享</Button>
        <Button block color='primary' size='middle' onClick={this.getLocation}>通用定位</Button>
        <Button block color='primary' size='middle' onClick={this.getLocationByDing}>钉钉定位</Button>
      </div>
      <div>
        2023-10-10
      </div>
      <div>
        H5浏览器定位测试
        <Button block color='primary' size='middle' onClick={this.h5getLocation}>H5浏览器定位测试</Button>
      </div>
      <img alt='' src={window.location.origin + window.location.pathname + 'logo192.png'} />
      <br />
      <span>分享图片地址：{window.location.origin + window.location.pathname + 'logo192.png'}</span>
      <br />
      <span>使用：window.location.origin + window.location.pathname + 'logo192.png'</span>
      <br />
      <img alt='' src={window.location.origin + '/share_tip.png'} />
      <br />
      <span>分享图片地址2：{window.location.origin + '/share_tip.png'}</span>
      <br />
      <span>使用：window.location.origin + '/share_tip.png'</span>
          
      <div>
        上传通讯录导出文件
        <form>
        <input type='file'></input>上传
        </form>
        
      </div>
      <div>
        <Button block color='primary' size='middle' onClick={this.open}>打开知乎</Button>
      </div>
      <div>
      <Button block color='primary' size='middle' onClick={() => {
        window.jd.miniProgram.navigateTo({url: `/pages/middle/index?type=0`});
      }}>打开京东外链</Button>
      </div>
    </div>
  }

  componentDidMount() {

    window.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        clearTimeout(this.state.timer)
      }
    })

    let isPay = localStorage.getItem('isPay')

    if (isPay) {
      Dialog.show({
        title: '请确认支付是否完成',
        actions: [
          {
            key: 'go_set', text: '已支付完成',
            onClick: () => {
              localStorage.setItem('isPay', '');
            }
          },
          {
            key: 'cancel', text: '支付遇到问题，重新支付', bold: true, onClick: () => {
              localStorage.setItem('isPay', '');
            }
          }
        ],
        closeOnAction: true
      })
    }

    let el = document.getElementsByClassName('pay_iframe')
    for (let i = 0; i < el.length; i++) {
      el[i].remove()
    }

    this.setState({
      satoken: localStorage.getItem('satoken'),
      userid: this.globalStore.userInfo.id,
      userAgent: window.navigator.userAgent
    })
  }

  componentWillUnmount() {
    localStorage.setItem('isPay', '');
    let el = document.getElementsByClassName('pay_iframe')
    for (let i = 0; i < el.length; i++) {
      el[i].remove()
    }
  }

  createPay = () => {
    let payUrl = createPayUrl(this.state.transaction_no, '/debug');
    this.setState({
      payUrl: payUrl
    })
  }

  createConsole = () => {
    eruda.init()
    // let script = document.createElement('script');
    // script.type = 'text/javascript';
    // script.onload = () => {
    //   // eslint-disable-next-line
    //   var vConsole = new VConsole();
    // };
    // script.src = "https://cdn.bootcdn.net/ajax/libs/vConsole/3.9.0/vconsole.min.js";
    // document.getElementsByTagName('head')[0].appendChild(script);
  }

  call = (tel) => {
    let { driverInfo, location } = this.state;
    let orderId = this.state.orderId;
    let data = {
      orderId,
      driverInfo,
      location
    }
    webService.order.callPolice(data);

    setTimeout(() => {
      window.top.location.href = `tel://${tel}`;
    }, 200)

  }

  callTest = async (tel) => {
    let { driverInfo, location } = this.state;
    let orderId = this.state.orderId;
    let data = {
      orderId,
      driverInfo,
      location
    }
    let d = await webService.order.callTest(data)

    if (d.data.code === 0) {
      window.top.location.href = `tel://${tel}`;
    } else {
      window.top.location.href = `tel://${tel}`;
    }

  }

  pay = async () => {
    let orders = [{ id: this.state.orderId, amount: this.state.amount, payType: 2 }]
    let url = window.location.href;
    let d = await webService.order.pay({ orders: orders, redirectUri: url, paymentType: payType() });
    if (d.data.code === 0) {
      console.log('d', d)
      this.ipay(d.data.data.transaction_no, '/debug');
      setTimeout(() => {
        localStorage.setItem('isPay', true);
      })
    } else {
      Dialog.alert({
        content: d.data.message
      })
    }
  }

  ipay = (orderId, backuri) => {
    let href = this.createPayUrl(orderId, backuri);
    window.location.href = href
  }

  createPayUrl = (orderId, backuri) => {
    // 飞书
    let feishu = feishu_open_url + '/#' + backuri;
    // 回调 url
    let callBackUrl = ''
    // 支付 url
    let href = ''
    // 判断型号
    let ua = this.dge()

    if (ua.indexOf('feishu') !== -1) {
      // 飞书
      localStorage.setItem('hash', '#' + backuri);
      callBackUrl = encodeURIComponent(`${feishu}`);
    } else {
      // 石油商旅
      if (ua.indexOf('smartcomtrip-mobile') !== -1) {
        callBackUrl = 'ZSYApp'
      } else {
        // 企业微信
        callBackUrl = encodeURIComponent(`${window.location.origin}/#${backuri}`)
      }
    }

    href = ipay_url.replace('$payOrderid$', orderId);
    href = href.replace('$callBackUrl$', callBackUrl);

    return href;
  }
  dge = () => {
    let isMobile = window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i); // 是否手机端
    // 是否微信
    let isWx = /micromessenger/i.test(navigator.userAgent);
    // 是否企业微信
    let isComWx = /wxwork/i.test(navigator.userAgent);
    // 是否飞书
    let isFeishu = /feishu/i.test(navigator.userAgent) || /workplace/i.test(navigator.userAgent);
    // 是否石油商旅
    let isSmartcomTrip = /SmartcomTrip/i.test(navigator.userAgent);

    // 手机端企业微信
    if (isComWx && isMobile) {
      return 'com-wx-mobile'
    }
    // PC端企业微信
    else if (isComWx && !isMobile) {
      return 'com-wx-pc'
    }
    // 手机端飞书
    else if (isFeishu && isMobile) {
      return 'feishu-mobile'
    }
    // PC端飞书
    else if (isFeishu && !isMobile) {
      return 'feishu-pc'
    }
    // 手机端微信
    else if (isWx && isMobile) {
      return 'wx-mobile';
    }
    // PC端微信
    else if (isWx && !isMobile) {
      return 'wx-pc';
    }
    // 手机端石油商旅
    else if (isSmartcomTrip && isMobile) {
      return 'smartcomtrip-mobile';
    }
    // 其他
    else {
      return 'other'
    }
  }
  share = async () => {
    let sdk = new WeChatWorkJsSdk();
    if (oauthType === '17' || oauthType === '11') {
      await sdk.init(true)
    } else {
      await sdk.init()
    }
    sdk.share({
      link: `${window.location.origin}${window.location.pathname}#/watchShare/1599641655509934080`,
      title: '分享行程中',
      imgUrl: `${window.location.origin}/share_tip.png`,
      desc: `测试分享行程中接口`
    })
  }

  shareByedoctor = () => {
    window.wx.invoke("shareAppMessage", {
      link: `${window.location.origin}${window.location.pathname}#/watchShare/${this.props.orderId}`,
      title: '测试edoctor分享',
      imgUrl: `${window.location.origin}${window.location.pathname}logo192.png`,
      desc: `这里是简述文字`
    },
      function (res) {
        if (res.err_msg === "shareAppMessage:ok") {
          //正确处理
          console.log('分享成功', `${window.location.origin}${window.location.pathname}#/watchShare/${this.props.orderId}`)
        } else {
          console.log('分享失败', res.err_msg)
          //错误处理
        }
      }
    );
  }

  getLocation = async () => {
    // let sdk = new WeChatWorkJsSdk();
    // await sdk.init()
    // let res = await sdk.getLocation()
    // console.log(res)

    let rr = await getCoords()
    console.log(rr)


  }


  getLocationByDing = async () => {
    console.log('------------------------')
    let dd_sdk = new DingJsSdk();
    await dd_sdk.init()
    let res = await dd_sdk.getLocation()
    console.log(res)
  }

  h5getLocation = async () => {
    navigator.geolocation.getCurrentPosition(async (res) => {
      console.log('navigator.geolocation.getCurrentPosition 返回', res)
    }, async (err) => {
      console.error('navigator.geolocation.getCurrentPosition 异常', err)
    }, {
      enableHighAccuracy: true,
      timeout: 2000,
      maximumAge: 2000,
      coordsType: 'wgs84',
      geocode: false
    })
    console.log('navigator.geolocation 调用完成，等待返回')
  }

  open = () => {
    this.setState({
      timer: setTimeout(() => {
        alert('跳转下载页面')
      }, 3000)
    })
    window.location.href = 'feishu://'
  }



}

export default Debug;
