import React from 'react'
import style from './smartWaitingOrders.scss';
import {inject, observer } from 'mobx-react'
import { Button } from 'antd-mobile'
import images from '../../util/images';

@inject('runingOrderStore')
@observer
class SmartWaitingOrders extends React.Component {

    state = {}
    runingOrderStore = this.props.runingOrderStore;
    id = this.props.id;

    t = this.props.t

    render() {
        let cars = this.runingOrderStore.cars;
        return <>
            <div className={style.smartOrderState}>
                <div>
                    <div>
                        {this.t('runing.k013')}
                    </div>
                    <div>
                        <img src={images.waiting} alt={this.t('runing.k014')} />
                    </div>
                </div>
                <div>
                    <Button color='primary' fill='outline' onClick={() => {this.props.cancelOrder();}}>{this.t('runing.k007')}</Button>
                </div>
            </div>
            
            {
                cars && cars.length 
                    ? (<div className={style.smartOrderDetail}>
                      {this.t('runing.k015', {carSource: cars[0].list[0].carSource, carLevelName: cars[0].list[0].carLevelName, carLength: this.runingOrderStore.carLength, price:cars[0].priceLab})}
                      </div>)
                    : (<></>)
            }
            
        </>
    }

}

export default SmartWaitingOrders;