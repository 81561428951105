import { observable, action, makeObservable } from "mobx";

class SettingsStore {

  constructor() {
    makeObservable(this)
  }
  // 手机号
  @observable userPhone = ''

  // 紧急联系手机号
  @observable urgencyPhone = ''

  // 设置手机号
  @action.bound setUserPhone(num) {
    this.userPhone = num
  }

  // 设置紧急联系人
  @action.bound setUrgencyPhone(num) {
    this.urgencyPhone = num
  }
}

export default new SettingsStore();