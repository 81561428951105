import React from "react";
import style from './login.scss'

import images from "../util/images";

import { Form, Input, Button, Dialog } from 'antd-mobile'

import { webBaseUrl } from './../util/config'
import webService from './../util/webService'
import { dge } from './../util/utils';

import { oauthType } from './../util/globalConfiguration'
import gc from './../util/globalConfiguration'

import { withTranslation } from "react-i18next";
import eruda from 'eruda'

class Logo extends React.Component {

    state = {
        countDown: 60,
        isAllowGetCode: true,

        phone: '',
        verificationCode: '',

        openId: '',

        email: '',
        isPhone: !gc.process.isEmailFirst,
        uuid: '',

        captcha: '',
        captchaUuid: '',
        captchaImage: '',

        verificationBtnStatus: false
    }

    form = undefined;
    t = this.props.t



    renderPhone() {
        return <Form.Item
            name='phone'
            rules={[{ required: true, message: this.t('login.k001'), validateTrigger: ['onSubmit'] },
            { pattern: /^1\d{10}$/, message: this.t('login.k005'), validateTrigger: ['onSubmit'] }]}>
            <Input onChange={(value) => {
                this.setState({
                    phone: value
                })
            }} placeholder={this.t('login.k001')} clearable="true" />
        </Form.Item>
    }

    renderEmail() {
        return <Form.Item
            name='email'
            rules={[{ required: true, message: this.t('login.k008'), validateTrigger: ['onSubmit'] },
            { pattern: '^([A-Za-z0-9_\\-\\.])+\\@([A-Za-z0-9_\\-\\.])+\\.([A-Za-z]{2,4})$', message: this.t('login.k009'), validateTrigger: ['onSubmit'] }]}>
            <Input onChange={(value) => {
                this.setState({
                    email: value
                })
            }} placeholder={this.t('login.k008')} clearable="true" />
        </Form.Item>
    }

    renderCaptcha() {
        <Form.Item
            name='captcha'
            rules={[{ required: true, message: this.t('login.k012'), validateTrigger: ['onSubmit'] },
            ]}
            extra={
                <div className={style.captcha}>
                    <img alt='验证码图片' src={'data:image/png;base64,' + this.state.captchaImage} onClick={() => {this.loadCaptchaImage()}} />
                </div>
            }>
            <Input onChange={(value) => {
                this.setState({
                    captcha: value
                })
            }} placeholder={this.t('login.k012')} clearable="true" />
        </Form.Item>
    }

    render() {
        return <div className={style.container} >
            <div className={style.logo}>
                <img alt="" src={images.logo192} onClick={this.debugMenu} />
            </div>
            {
                oauthType === '12'
                    ? <div className={style.wxs}>
                        {/* 服务号 */}
                        {this.t('login.k007')}
                    </div>
                    : <></>
            }
            <div className={style.form}>
                <Form style={{
                    '--border-bottom': 'none',
                    '--border-top': 'none',
                    '--border-inner': 'none'
                }} ref={(dom) => { this.form = dom; }}>
                    {/* 切换手机号和邮箱 */}
                    {this.state.isPhone ? this.renderPhone() : this.renderEmail()}
                    {/* 图片验证码 */}
                    {/* {this.renderCaptcha()} */}
                    <Form.Item
                        layout='horizontal'
                        name='verificationCode'
                        rules={[{ required: true, message: this.t('login.k002'), validateTrigger: ['onSubmit'] }]}
                        extra={
                            <div className={style.verificationCode}>
                                <Button block color='primary' size='middle' disabled={this.state.verificationBtnStatus} onClick={() => { this.getVerificationCode() }}>
                                    {
                                        this.state.isAllowGetCode
                                            ? this.t('login.k003')
                                            : `${this.state.countDown}s`
                                    }
                                </Button>
                            </div>
                        }
                    >
                        {/* 修复clearable事件不清空验证码 */}
                        {/* <div className={style.verificationCode}>
                            <div> */}

                        <Input onChange={(value) => {
                            this.setState({
                                verificationCode: value
                            })
                        }} placeholder={this.t('login.k002')} clearable="true"
                        />
                        {/* </div>
                            <div>
                                <Button block color='primary' size='middle' onClick={() => { this.getVerificationCode() }}>
                                    {
                                        this.state.isAllowGetCode
                                            ? this.t('login.k003')
                                            : `${this.state.countDown}s`
                                    }
                                </Button>
                            </div>
                        </div> */}
                    </Form.Item>
                </Form>
            </div>
            <div className={style.btn}>
                <Button block color='primary' size='middle' onClick={() => { this.login() }}>{this.t('login.k004')}</Button>
            </div>
            {/* <div className={style.register} onClick={this.goRegister}>
                <div>
                    <img alt="" src={images.arrowCircleLeft} />
                    <span>注册账号</span>
                </div>
            </div> */}
            {
                this.state.isDebug
                    ? <div>
                        <Button block size='middle' onClick={() => {
                            eruda.init()
                            // let script = document.createElement('script');
                            // script.type = 'text/javascript';
                            // script.onload = () => {
                            //     // eslint-disable-next-line
                            //     var vConsole = new VConsole();
                            // };
                            // script.src = "https://cdn.bootcdn.net/ajax/libs/vConsole/3.9.0/vconsole.min.js";
                            // document.getElementsByTagName('head')[0].appendChild(script);
                        }}>启动控制台</Button>
                    </div>
                    : <></>
            }
            {oauthType === '12'
                ? <div className={style.LoginMode} onClick={this.changeLogin}> {this.state.isPhone ? this.t('login.k010') : this.t('login.k011')}</div>
                : <></>
            }
        </div>
    }

    async componentDidMount() {
      let id = this.props.match.params.id;
        this.setState({
            openId: sessionStorage.getItem('openId') || id
        }, () => {
        });

        await this.loadCaptchaImage()
    }

    /**
     * 加载反机器人验证码图片
     */
    loadCaptchaImage = async () => {
        // let {data} = await webService.user.getCode()
        // if (data.code === 0) {
        //     this.setState({
        //         captchaUuid: data.data.uuid,
        //         captchaImage: data.data.img
        //     })
        // }
    }

    /**
     * 获取验证码
     */
    getVerificationCode = async () => {
        try {
            this.setState({ verificationBtnStatus: true });
            if (this.state.isAllowGetCode) {
                await this.form.validateFields(['phone', 'email']);
                let { isPhone, phone, email, captcha, captchaUuid } = this.state;
                let res;
                if (isPhone) {
                    res = await webService.user.sendSMS({ PhoneNum: phone, captcha: captcha, captchaUuid: captchaUuid });
                } else {
                    res = await webService.user.sendEmailSMS(email, captcha, captchaUuid);
                }
                const { data } = res
                if (data.code === 0) {
                    this.setState({ uuid: isPhone ? '' : data.data.uuid });
                    this.beginCountDown();
                } else {
                    Dialog.alert({
                        content: data.message || this.t('login.k006'),
                        confirmText: this.t('common.k003')
                    })
                }
            }
        } catch (exp) {
            console.log('表单不可提交', exp)
        } finally {
            this.setState({ verificationBtnStatus: false });
        }
    }

    /**
     * 开始倒计时
     */
    beginCountDown = () => {
        this.setState({
            countDown: 60,
            isAllowGetCode: false
        }, () => {
            setTimeout(() => {
                this.countDownTimer();
            }, 1000);
        });
    }

    /**
     * 倒计时
     */
    countDownTimer = () => {
        let { countDown } = this.state;
        countDown -= 1;
        this.setState({
            countDown: countDown
        }, () => {
            if (countDown > 0) {
                setTimeout(() => {
                    this.countDownTimer();
                }, 1000);
            } else {
                this.setState({
                    isAllowGetCode: true
                })
            }
        });
    }

    login = async () => {
        try {
            await this.form.validateFields();
            let { isPhone, phone, email, verificationCode, uuid, captcha, captchaUuid } = this.state;
            let res;
            if (isPhone) {
                res = await webService.user.saveOpenIDBindPhone({
                    openID: this.state.openId,
                    phone: phone,
                    code: verificationCode,
                    oauthType: oauthType,
                    captcha: captcha,
                    captchaUuid: captchaUuid
                })
            } else {
                res = await webService.user.loginByEmail({
                    openID: this.state.openId,
                    email,
                    code: verificationCode,
                    uuid
                })
            }
            const { data } = res;
            if (data.code === 0) {
                sessionStorage.setItem('satoken', data.data.satoken)
                window.location.replace(webBaseUrl);
            } else {
                Dialog.confirm({
                    content: data.message,
                    cancelText: this.t('common.k001'),
                    confirmText: this.t('common.k002')
                })
            }
        } catch (exp) {
            console.log('表单不可提交', exp)
        }
    }

    goRegister = () => {
        this.props.history.push('/register');
    }

    firstClickTime = 0;
    totleClick = 0;
    /**
     * 启动调试模式
     */
    debugMenu = (e) => {
        if (e && e.stopPropagation) {
            e.stopPropagation()
        }
        if (new Date().getTime() - this.firstClickTime > 2000) {
            this.firstClickTime = new Date().getTime()
        } else {
            this.totleClick = this.totleClick + 1;
            if (this.totleClick >= 9) {
                this.setState({
                    isDebug: true
                })
                console.log('成功触发', this.totleClick)
                console.log(dge())
            }
        }
    }

    // 切换登录状态
    changeLogin = async () => {
        let phone = this.state.phone;
        let email = this.state.email;
        this.state.isPhone ? phone = '' : email = '';
        this.setState({ isPhone: !this.state.isPhone, phone, email, verificationCode: '' });
        await this.form.resetFields();
    }
}

export default withTranslation()(Logo);