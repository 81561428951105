import React from "react";
import style from './promotionNewUser.scss'

import { Mask } from 'antd-mobile'
import { CloseCircleOutline } from 'antd-mobile-icons'

import webService from '../../util/webService'

class PromotionNewUser extends React.Component {
  
  state = {
    imageSrc: "",
    isShow: false
  }

  render() {
    return <>
      <Mask opacity={0.7} visible={this.state.isShow} onMaskClick={this.close}>
        <div className={style.activityContent}>
          <div className={style.box}>
            <img alt="" src={this.state.imageSrc} />
          </div>
          <div className={style.close}>
            <CloseCircleOutline color='#ffffff' fontSize={25} onClick={this.close}/>
          </div>
        </div>
      </Mask>
    </>
  }

  componentDidMount = async () => {
    // 查询新用户活动
    try {
      let {data} = await webService.promotion.searchNewUser();
      if (data && data.code === 0 && data.data.imageSrc) {
          this.setState({
              imageSrc: data.data.imageSrc,
              isShow: true
          })
      }
    } catch(e) {
      console.log(e)
    }
  }

  close = () => {
    this.setState({
      isShow: false
    })
  }

}

export default PromotionNewUser