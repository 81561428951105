import React from "react";
import PropTypes from 'prop-types'
import style from './InvoiceCell.scss'

import { Checkbox } from 'antd-mobile'

class InvoiceCell extends React.Component {

  t = this.props.t

  render() {
    return (
      <div className={style.invoiceCell}>
        {/* 头部金额及选择 */}
        <div className={style.price}>
          <Checkbox onChange={(e)=>{
            this.props.onChange(e, this.props.id, this.props.index)
          }} checked={this.props.checked} style={{'--icon-size': '20px'}} value='apple'></Checkbox>
          <div className={style.header}>
            <span className={style.amount}>{this.props.invoiceAmount}<em>{this.t('invoice.k002')}</em></span>
            <p>{this.props.carSource}</p>
          </div>
        </div>
        {/* 底部信息部分 */}
        <div className={style.message} onClick={() => this.props.onClick(this.props.id)}>
          <p>{this.t('invoice.k001')}{this.props.departTime}</p>
          <p>{this.t('invoice.k003')}{this.props.deparLocation}</p>
          <p>{this.t('invoice.k004')}{this.props.destLocation}</p>
          <p>{this.t('invoice.k005')}{this.props.userNameCn}</p>
        </div>
      </div>
    )
  }
}

InvoiceCell.propTypes = {
  index: PropTypes.number,
  // 订单 id
  id: PropTypes.string,
  // 金额
  invoiceAmount: PropTypes.string,
  // 车型
  carSource: PropTypes.string,
  // 创建时间
  departTime: PropTypes.string,
  // 起点地址
  deparLocation: PropTypes.string,
  // 终点地址
  destLocation: PropTypes.string,
  // 用车人中文
  userNameCn: PropTypes.string,
  // 用车人英文
  userNameEn: PropTypes.string,
  // 是否选中
  checked: PropTypes.bool,
}

InvoiceCell.defaultProps = {
  id: "",
  invoiceAmount: "",
  carSource: "",
  createTime: "",
  deparLocation: "",
  destLocation: "",
  userNameCn: "",
  userNameEn: "",
  checked: null,
  onClick: () => {}
}

export default InvoiceCell