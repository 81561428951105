import React from 'react'
import style from './invoice.scss'
import {inject, observer } from 'mobx-react'

import InvoiceCell from '../component/Invoice/InvoiceCell'

import { Dialog, Tabs, Empty, Button, Checkbox, InfiniteScroll, Loading, List, DatePicker, Toast } from 'antd-mobile'
import { UpOutline, RightOutline } from 'antd-mobile-icons'

import moment from 'moment'

import images from '../util/images'

import webService from '../util/webService'
// import gc from '../util/globalConfiguration'

import { withTranslation } from "react-i18next";

@inject('invoiceStore', 'globalStore')
@observer
class Invoice extends React.Component {

  state = {
    activeKey: '1',

    // 控制滚动加载
    hasMore: true,

    //全选按钮
    isAllChecked: false,

    // 行程数量
    orderCount: 0,

    // 共计金额
    tripQtyAmount: "0.00",

    // 选中开票
    checkedList: [],

    // 选中月份
    startMonth: "",

    // 控制已开票日期弹窗显隐
    isShowTime: false,

    isData: null,

    // 入参
    pageDate: {
      pageNum: 1,
      pageSize: 25,
      total: 0,
      inVoiceData: [],
      hasInvocieData: [],
      totalAmount: ""
    }
  }

  invoiceStore = this.props.invoiceStore
  globalStore = this.props.globalStore

  t = this.props.t

  // 石油单独权限功能判断
  get isInvoiceSame() {
    return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.invoice_same_prompt
  }

  /**
   * 滚动文案
   */
   renderScrollContent = () => {
    return (
      <>
        {this.state.hasMore ? (
          <>
            <div className={style.loadingbox}>
              <div className={style.loading}>Loading</div>
              <Loading />
            </div>
          </>
        ) : (
          <div className={style.msg}>--- {this.t('invoice.k006')} ---</div>
        )}
      </>
    )
  }

  /**
   *
   *待审核底部操作
   */
  renderInvoiceBar = () => {
    const { isAllChecked } = this.state
    return(
      <div className={style.bar}>
        <div className={style.left}>
          <Checkbox checked={isAllChecked} onChange={(e) => {
            this.allCheckApproval(e)
          }} style={{'--icon-size': '20px'}} value='apple'>{this.t('invoice.k007')}</Checkbox>
          <div className={style.price}>
            {this.t('invoice.k008', {count: this.state.orderCount})}<span>¥{this.state.tripQtyAmount}</span>
          </div>
        </div>
        <div className={style.right}>
          <Button onClick={() => {this.handleNext()}} style={{'--background-color':'#2F7DCD'}} color='primary'>{this.t('invoice.k009')}</Button>
        </div>
      </div>
    )
  }

  /**
   *
   *已开票日期金额 bar
   */
  renderInvoiceTimePop = () => {
    return(
      <div className={style.dateSelect}>
        <div onClick={() => {
          this.setState({
            isShowTime: true
          })
        }}>
          {this.t('invoice.k010')}{this.state.startMonth}&nbsp;<UpOutline color="#C7C7CC"  fontSize={12}/>
        </div>
        <div onClick={() => {
        }}>
          {this.t('invoice.k011')}{this.state.pageDate.totalAmount}
        </div>
      </div>
    )
  }

   /**
   *
   * 日期组件
   */
  renderPicker = () => {
    return <div>
      <DatePicker defaultValue={new Date()} visible={this.state.isShowTime} onClose={()=> {
        this.setState({
          isShowTime: false
        })
      }} precision="month" max={new Date()} min={new Date(moment().add(-4, 'Y'))} onConfirm={val => {
        this.reset(val)
      }}></DatePicker>
    </div>
  }

  /**
   *
   *待开票列表
   */
  renderInvoiceCell = () => {
    return (
      <>
        {
          this.state.pageDate.inVoiceData.map((item, index) => {
            return(
              <InvoiceCell
                key={item.id + index}
                id={item.id}
                t={this.props.t}
                checked={item.checked}
                invoiceAmount={item.invoiceAmount}
                carSource={item.carSource}
                departTime={item.departTime}
                deparLocation={item.deparLocation}
                destLocation={item.destLocation}
                userNameCn={item.userNameCn}
                onChange={(e, id) => {
                  this.addCheck(e, id, index)
                }}
              ></InvoiceCell>
            )
          })
        }
        <InfiniteScroll loadMore={this.loadInvocieList} hasMore={this.state.hasMore} threshold={10}>
          {this.renderScrollContent()}
        </InfiniteScroll>
      </>
    )
  }

  /**
   *
   *已开票列表
   */
  renderHasInvoiceCell = () => {
    return(
      <div className={style.box}>
        {
          this.state.pageDate.hasInvocieData.map(item => {
            return (
              <List key={item.id}>
                <List.Item arrow={false} onClick={() => {
                  this.props.history.push(`/invoiceDetails/${item.id}`)
                }}>
                  <div>
                    <p className={style.price}>
                      {this.t('invoice.k012')} ¥ {item.invoiceAmount}
                    </p>
                    <p className={style.time}>
                      {item.createTime}
                    </p>
                  </div>
                  <div className={style.trip}>
                      {this.t('invoice.k013', {count: item.orderCount})}
                    <RightOutline color="#c9c9ce"></RightOutline>
                  </div>
                </List.Item>
              </List>
            )
          })
        }
      </div>
    )
  }

  /**
   * 发票列表 tab 切换
   */
  renderInvoiceTabs = () => {
    return(
      <Tabs defaultActiveKey={this.state.activeKey} onChange={(val) => {
        Toast.show({
          icon: 'loading',
          content: 'loading',
          duration: 0,
          maskClickable: false
        })
          this.setState({
            activeKey: val,
            isData: null
          }, () => {
            if (this.state.activeKey === '1') {
              this.setState({
                orderCount: 0,
                tripQtyAmount: "0.00",
                checkedList: [],
                pageDate: {
                  ...this.state.pageDate,
                  pageNum: 1,
                  inVoiceData: [],
                },
              }, () => {
                this.loadInvocieList()
              })
            } else {
              this.setState({
                pageDate: {
                  ...this.state.pageDate,
                  hasInvocieData: [],
                },
              }, () => {
                this.loadHasInvocieList()
              })
            }
          })
        
        }}>
        <Tabs.Tab title={this.t('invoice.k014')} key='1'>
          {/* {this.state.pageDate.inVoiceData.length > 0 ? this.renderInvoiceCell() : <Empty description={this.t('invoice.k016')} image={images.invoice}/>} */}
          {this.state.isData && this.renderInvoiceCell()}
          {this.state.isData === false ? <Empty description={this.t('invoice.k016')} image={images.invoice}/> : ''}
        </Tabs.Tab>
        <Tabs.Tab title={this.t('invoice.k015')} key='2'>
          {/* {this.state.pageDate.hasInvocieData.length > 0 ? this.renderHasInvoiceCell() : <Empty description={this.t('invoice.k017')} image={images.invoice}/>} */}
          {this.state.isData && this.renderHasInvoiceCell()}
          {this.state.isData === false ? <Empty description={this.t('invoice.k017')} image={images.invoice}/> : ''}
        </Tabs.Tab>
      </Tabs>
    )
  }

  render() {
    return(
      <div className={style.invoice}>
        {/* tabs 切换 */}
        {this.renderInvoiceTabs()}
        {/* 日期弹窗 */}
        {this.renderPicker()}
        {/* 待审核底部操作 */}
        {this.state.activeKey === "1" ? this.renderInvoiceBar() : ""}
        {this.state.activeKey === "2" ? this.renderInvoiceTimePop() : ""}
      </div>
    )
  }

  componentDidMount() {
    Toast.show({
      icon: 'loading',
      content: 'loading',
      duration: 0,
      maskClickable: false
    })
    this.setState({
      startMonth: moment().format('YYYY-MM')
    })
    this.loadInvocieList()

    if (this.isInvoiceSame) {
      // 如果是石油用户，则提醒其开票之时勾选订单的完整支付记录
      Dialog.alert({
        // 请您确认是否存在同一订单两笔支付请一并勾选前往开票
        content: this.t('invoice.k019')
      })
    }
  }

  /**
   * 获取待开票列表
   */
  loadInvocieList = async () => {
    if (Number(this.state.pageDate.total) !== 0 && Number(this.state.pageDate.total) === this.state.pageDate.inVoiceData.length) {
      this.setState({
        hasMore: false
      })
      return
    }
    let params = {
      pageNum: this.state.pageDate.pageNum,
      pageSize: this.state.pageDate.pageSize
    }
    let d = await webService.invoice.getInvoiceList(params)
    if (d.data && d.data.code === 0 && d.data.data && d.data.data.list) {
      d.data && d.data.data && d.data.data.list.forEach(item => {
        item.checked = false
      })
      this.setState({
        hasMore: true,
        pageDate: {
          ...this.state.pageDate,
        pageNum: d.data && d.data.data ? d.data.data.pageNum+1 : 1,
        total: d.data && d.data.data && d.data.data.total,
        inVoiceData: [...this.state.pageDate.inVoiceData, ...d.data.data.list]
        },
      }, () => {
        if (this.state.pageDate.inVoiceData.length > 0) {
          this.setState({
            isData: true
          })
        } else {
          this.setState({
            isData: false
          })
        }
        if (this.state.checkedList.length !== this.state.pageDate.inVoiceData.length) {
          this.setState({
            isAllChecked: false
          })
        }
    })
    } else {
      this.setState({
        isData: false
      })
    }
    Toast.clear();
  }

  /**
   * 获取已开票列表
   */
  loadHasInvocieList = () => {
    let parmas = {
      month: this.state.startMonth
    }
    webService.invoice.getHasInvoiceList(parmas).then(d => {
      if (d.data.code === 0 && d.data && d.data.data) {
        this.setState({
          pageDate: {
            ...this.state.pageDate,
            hasInvocieData: d.data.data.list,
            totalAmount: d.data.data.totalAmount
          }
        }, () => {
          if (this.state.pageDate.hasInvocieData.length > 0) {
            this.setState({
              isData: true
            })
          } else {
            this.setState({
              isData: false
            })
          }
        })
      } else {
        this.setState({
          isData: false
        })
      }
      Toast.clear();
    })
  }

  /**
   *
   *开票全选
   */
   allCheckApproval = (e) => {

    if (this.state.pageDate.inVoiceData.length === 0) return

    const newData = JSON.parse(JSON.stringify(this.state.pageDate.inVoiceData));

    let amountAll = JSON.parse(JSON.stringify(this.state.tripQtyAmount));

    amountAll = '0.00'

    newData.forEach((item) => {
      item.checked = e
      amountAll = this.accAdd(amountAll, item.invoiceAmount)
    })

    const newCheckedList = newData.map(item => {
      return {
        id: item.id,
        amount: item.invoiceAmount
      }
    })

    this.setState({
      isAllChecked: e,
      checkedList: e ? newCheckedList : [],
      orderCount: newCheckedList.length,
      tripQtyAmount: amountAll,
      pageDate: {
        ...this.state.pageDate,
        inVoiceData: newData
      }
    }, () => {
      if (this.state.checkedList.length === 0) {
        this.setState({
          tripQtyAmount: "0.00",
          orderCount: 0
        })
      }
    })
  }

  /**
   *
   *开票单选
   */
  addCheck = (e, id, index) => {
    const newData = JSON.parse(JSON.stringify(this.state.pageDate.inVoiceData));
    // const newCheckedList = JSON.parse(JSON.stringify(this.state.checkedList));

    let amountAll = 0
    for (let i in newData) {
      if(newData[i].id === id) {
        newData[i].checked = e
      }
    }

    let checkedOrders = newData.filter(a => a.checked === true);

    for (let i in checkedOrders) {
      amountAll += Number(checkedOrders[i].invoiceAmount)
    }

    // newData.forEach((item, idx) => {
    //   if (item.id === id && e && index === idx) {
    //     item.checked = e
    //     amountAll = this.accAdd(amountAll, item.invoiceAmount)
    //     newCheckedList.push({id: item.id, amount: item.invoiceAmount})
    //   } 
    //   if (item.id === id && !e && index === idx) {
    //     amountAll = this.accSub(amountAll, item.invoiceAmount)
    //     item.checked = e
    //   }
    // })

    // newCheckedList.forEach((item, idx) => {
    //   if (item.id === id && !e && index === idx) {
    //     newCheckedList.splice(idx, 1)
    //   }
    // })

    this.setState({
      // checkedList: newCheckedList,
      orderCount: checkedOrders.length,
      tripQtyAmount: amountAll.toFixed(2),
      pageDate: {
        ...this.state.pageDate,
        inVoiceData: newData,
      }
    }, () => {
      if (this.state.pageDate.inVoiceData.length === checkedOrders.length) {
        this.setState({
          isAllChecked: true
        })
      } else {
        this.setState({
          isAllChecked: false
        })
      }
    })
  }

  /**
   *
   *加
   */
  accAdd = (num1,num2) => {
    let r1,r2,m;
    try {
        r1 = num1.toString().split('.')[1].length;
    } catch(e) {
        r1 = 0;
    }
    try {
        r2=num2.toString().split(".")[1].length;
    } catch(e) {
        r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    return Math.round( num1 * m + num2 * m ) / m;
  }

  /**
   *
   *减
   */
  accSub = (num1,num2) => {
    let r1,r2,m,n;
    try {
        r1 = num1.toString().split('.')[1].length;
    } catch(e) {
        r1 = 0;
    }
    try {
        r2 = num2.toString().split(".")[1].length;
    } catch(e) {
        r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    n = (r1 >= r2) ? r1 : r2;
    return (Math.round( num1 * m - num2 * m ) / m).toFixed(n);
  }

  /**
   *
   *日期选择加载月份数据
   */
  reset = (val) => {
    this.setState({
      pageDate: {
        ...this.state.pageDate,
        hasInvocieData: []
      }
    }, () => {
      let time = moment(val).format('YYYY-MM')
      this.setState({
        startMonth: time
      }, () => {
        this.loadHasInvocieList()
      })
    })
  }

  /**
   *
   *下一步
   */
  handleNext = () => {
    let newData = JSON.parse(JSON.stringify(this.state.pageDate.inVoiceData));
    let checkedOrders = newData.filter(a => a.checked === true);
    const newCheckedList = checkedOrders.map(item => {
      return {
        id: item.id,
        amount: item.invoiceAmount
      }
    })
    if (checkedOrders.length > 0) {
      this.invoiceStore.setInvoiceAmount(this.state.tripQtyAmount)
      this.invoiceStore.setInvoiceList(newCheckedList)
      this.props.history.push('/writeinVoice')
    } else {
      Toast.show({
        content: this.t('invoice.k018'),
      })
    }
  }
}

export default withTranslation()(Invoice);