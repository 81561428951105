import { wssRuningOrderUrl } from './config'
// import webService from './webService';
import loger from './loger'

let isDebugReceiveWss = false
let debugReceiveWss = undefined

window.openDebugReceiveWss = (json) => {
  isDebugReceiveWss = true
  if (debugReceiveWss && json) {
    debugReceiveWss(json)
  }
}

class WebSocketHelper {
  constructor(satoken, coreOrderId, orderId, callback) {
    this.url = `${wssRuningOrderUrl}?satoken=${satoken}&coreOrderId=${coreOrderId}`;
    this.callback = callback;
    this.openState = false;
    this.orderId = orderId
    this.satoken = satoken
    this.coreOrderId = coreOrderId
    this.id = new Date().getTime()

    debugReceiveWss = callback
  }

  isOpenState() {
    return this.openState;
  }

  open() {
    if (!this.coreOrderId) {
      // webService.logger.report(this.orderId, 'orderRuning', '未获取到coreOrderId：', `${this.id} satoken：${this.satoken} coreOrderId：${this.coreOrderId}`)
    }
    this.wss = new WebSocket(this.url);
    this.openState = true;
    this.wss.onopen = (e) => {
      // try {
      //   webService.logger.report(this.orderId, 'orderRuning', 'ws连接：', `${this.id} satoken：${this.satoken} coreOrderId：${this.coreOrderId}`)
      // } catch (e) {
      //   console.log('ws记录日志异常：' + e)
      // }
    }
    this.wss.onmessage = (e) => {
      if (!isDebugReceiveWss) {
        try {
          if (e.data) {
            let data = JSON.parse(e.data);
            if (data) {
              this.callback(data)
            }
          }

        } catch (e) {
          console.error(e)
        }
      }

      try {
        this.wss.send('ping')
      } catch (e) {
        console.error('发送心跳数据包异常', e)
      }
    }
    this.wss.onclose = (e) => {
      console.log('ws断开');
      // try {
      //   webService.logger.report(this.orderId, 'orderRuning', 'ws非主观断开：', `${this.id} satoken：${this.satoken} coreOrderId：${this.coreOrderId}`)
      // } catch (e) {
      //   console.log('ws非主观断, 开日志异常：' + e)
      // }
      if (this.openState) {
        loger.info('wss', 'onclose', 'ws非主观断开，尝试重连')
        console.log('ws非主观断开，尝试重连');
        this.open();
      }
    }
    this.wss.onerror = (e) => {
      console.log('ws onerror', e);
    }
  }

  close() {
    console.log('页面端主动断开ws连接');
    this.openState = false;
    try {
      if (this.wss) {
        // webService.logger.report(this.orderId, 'orderRuning', 'ws主动断开：', `${this.id} satoken：${this.satoken} coreOrderId：${this.coreOrderId}`)
        this.wss.close(3005, '主动断开')
      }
    } catch (exp) {
      console.log('页面端主动断开ws连接发生异常', exp);
    }

  }
}

export default WebSocketHelper