import React from "react";
import { RightOutline } from 'antd-mobile-icons'
import { Card, Empty, Toast } from 'antd-mobile'

import { DatePicker } from 'antd-mobile'

import moment from "moment"
import images from "../../util/images";
import ComEcharts from '../../component/utils/comEcharts'
import webService from "../../util/webService";

import style from './carbonEmission.scss'
import { withTranslation } from "react-i18next";

class CarbonEmission extends React.Component {
    t = this.props.t
    state = {
    startTime: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    endTiem: moment().toDate(),
    titleSummay: {
      monthCarbon: '',
      monthOrderCount: '',
      yearCarbon: '',
      yearOrderCount: ''
    },
    // 月度碳减排配置数据
    monthCarbonOption: {
      series: null
    },
    // 城市减排排行数据
    cityCarbonOption: {
      series: null
    },
    // 低碳出行场景分析
    sceneSummaryOption: {
      series: null
    },
    // 减排订单对比
    monthCarbonRatioOption: {
      series: null
    },
    // 部门减排数据
    departmentCarbonsList: [],
    // 场景/低碳其他占比
    sceneRatioSummary: {
      series: null
    }
  }

  componentDidMount() {
    moment.suppressDeprecationWarnings = true;
    this.getMenuCarbonQuery()
  }

  // 日期选择
  renderPicker = () => {
    return <div className={style.dateSelect}>
      <div className={style.cell}  onClick={() => {this.chooseMonth(true)}}>
        {moment(this.state.startTime).format('YYYY-MM-DD')}&nbsp;<RightOutline color="#C7C7CC"  fontSize={14}/>
      </div>
      <div className={style.line}></div>
      <div className={style.cell} onClick={() => {this.chooseMonth(false)}}>
        {moment(this.state.endTiem).format('YYYY-MM-DD')}&nbsp;<RightOutline color="#C7C7CC"  fontSize={14}/>
      </div>
    </div>
  }

  // 碳积分展示
  renderIntegral() {
    return <div className={style.box}>
      <div className={style.parentBox}>
        <div className={style.lattice}>
          {/* 累计减排 */}
          <span>{this.t('special.k011')}</span>
          <div className={style.green_cell}>{this.state.titleSummay.yearCarbon}kg</div>
        </div>
        <div className={style.borderRight}></div>
        <div className={style.lattice}>
          {/* 当月减排 */}
          <span>{this.t('special.k012')}</span>
          <div className={style.orange_cell}>{this.state.titleSummay.monthCarbon}kg</div>
        </div>
      </div>

      <div className={style.parentBox}>
        <div className={style.lattice}>
          {/* 累计低碳出行订单数量 */}
          <span>{this.t('special.k013')}</span>
          <div className={style.blue_cell}>{this.state.titleSummay.yearOrderCount}单</div>
        </div>
        <div className={style.borderRight}></div>
        <div className={style.lattice}>
          {/* 当月低碳出行订单数量 */}
          <span>{this.t('special.k014')}</span>
          <div className={style.lightBlue_cell}>{this.state.titleSummay.monthOrderCount}单</div>
        </div>
      </div>
    </div>
  }

  // 部门减排
  renderTable = () => {
    return (
      <div className={style.card}>
        <Card title="部门减排数据">
          {
            (() => {
              if (this.state.departmentCarbonsList && this.state.departmentCarbonsList.length > 0) {
                return (
                  <table style={{'width':'-webkit-fill-available', 'borderCollapse': 'collapse', 'textAlign':'center'}}>
                    <tbody>
                      <tr>
                        {/* 排名 部门 减排量 订单占比 */}
                      <th>{this.t('special.k015')}</th>
                      <th>{this.t('special.k016')}</th>
                      <th>{this.t('special.k017')}</th>
                      <th>{this.t('special.k018')}</th>
                    </tr>
                    {
                      this.state.departmentCarbonsList.map((item, index) => {
                        return(
                          <tr key={index}>
                            {
                              item.rank <= 3 ? <td>TOP {index + 1}</td> : <td>{index + 1}</td>
                            }
                            
                            <td>{item.department}</td>
                            <td>{item.carbonCount}</td>
                            <td>{item.ratio}%</td>
                          </tr>
                        )
                      }) 
                    }
                    </tbody> 
                  </table>
                )
              } else {
                return <div>
                <Empty description="暂无内容" image={images.reportnodata} imageStyle={{width: '144px', height: '110px' }}/>
              </div>
              }
            })()
          }
        </Card>
      </div>
    )
  }

  render(){
    return <div className={style.wrap}>
      {this.renderPicker()}
      {this.renderIntegral()}
      {/* 月度碳减排图表  */}
      {<ComEcharts title="月度碳减排数据" option={this.state.monthCarbonOption}></ComEcharts>}
      {/* 城市碳减排图表 */}
      {<ComEcharts title="城市减排TOP10排行数据" option={this.state.cityCarbonOption}></ComEcharts>}
      {/* 城市碳减排图表 */}
      {<ComEcharts title="低碳出行场景分析" option={this.state.sceneSummaryOption}></ComEcharts>}
      {/* 减排订单对比图表 */}
      {<ComEcharts title="减排订单占比" option={this.state.monthCarbonRatioOption}></ComEcharts>}
      {/* 部门减排数据图表 */}
      {this.renderTable()}
      {/* 场景/低碳其他占比 */}
      {<ComEcharts title="场景/低碳其他占比" option={this.state.sceneRatioSummary}></ComEcharts>}
    </div>
  }

  chooseMonth = (isBeginMonth) => {
    let month = isBeginMonth ? new Date(this.state.startTime) : this.state.endTiem;
    let min = new Date(moment().add(-2, 'Y')) 
    DatePicker.prompt({
        defaultValue: month,
        precision: 'day',
        min: min,
        max: moment().toDate()
    }).then(d => {
        if (!isBeginMonth) {
          if (new Date(d) < new Date(this.state.startTime)) {
            Toast.show({
              // 开始时间不能小于结束时间
              content: this.t('special.k019')
            })
            return
          }
        }
        if (d) {
          isBeginMonth
              ? this.setState({
                  startTime: d
              })
              : this.setState({
                  endTiem: d
              });
              if (!isBeginMonth || new Date(this.state.startTime) < this.state.endTiem) {
                  this.getMenuCarbonQuery()
              }
        }
    })
  }

  // 月度碳减排数据
  monthCarbonSummary = (data) => {
    let month = []
    if(data.month) {
      month = data.month.map(item => {
        item = item.replace('0', '')
        return moment(item).format('M月')
      });
    }
    let carbonCount = data.carbonCount ? data.carbonCount : [];
    let orderCount = data.orderCount ? data.orderCount : [];
    let option = {
        title: {
          text: '',
          left: 15,
        },
        legend: {
          data: [
            {
              name: '减排量(KG)',
              itemStyle: {
                color: "#ffa82d"
              },
              textStyle: {
                fontSize: 13
              }
            },
            {
              name: '订单数(单)',
              itemStyle: {
                color: "#2a6df4"
              },
              textStyle: {
                fontSize: 13
              }
            }
          ],
          icon: 'roundRect',
          itemWidth: 10,
          itemHeight: 3,
          top: 10
        },
        grid: {
          left: '3%',
          right: '3%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [{
          type: 'category',
          boundaryGap: true,
          data: month,//['1月', '2月', '3月', '4月', '5月', '6月', '7月'],
        }],
        yAxis: [
          {
            type: 'value',
            name: '减排(KG)',
            nameTextStyle: {
                fontSize: 13,
                fontWeight: 600,
                color: '#000',
                padding: [0, 0, 0, 40]
            }
          },
          {
            type: 'value',
            name: '订单数(单)',
            nameTextStyle: {
              fontSize: 13,
              fontWeight: 600,
              color: '#000',
              padding: [0, 40, 0, 0]
            },
            axisLine: {
                show: false
            },
            //坐标轴刻度相关设置。
            axisTick: {
                show: false
            },
            //分割线
            splitLine: {
                show: false
            }
          }
        ],
        series: [
            {
              name: '减排量(KG)',
              type: 'line',
              yAxisIndex: 0,
              symbol: 'circle',
              symbolSize: 5,
              itemStyle: {
                color: '#ffa82d'
              },
              data: carbonCount,//[100, 132, 101, 134, 90, 230, 210]
            },
            {
              name: '订单数(单)',
              type: 'line',
              yAxisIndex: 1,
              symbol: 'circle',//实心圆
              symbolSize: 5,//实心圆大小
              itemStyle: {
                color: '#2a6df4'
              },
              data: orderCount,//[0, 182, 191, 234, 290, 310, 310]
            }
        ]
    };
    if (option.series && option.series[0] && option.series[0].data && option.series[0].data.length > 0) {
      this.setState({
        monthCarbonOption: option
      })
    } else {
      this.setState({
        monthCarbonOption: {
          ...this.state.monthCarbonOption,
          series: []
        }
      })
    }
  }

  // 城市减排排行数据
  cityCarbonSummary = (data) => {
    let cityName = data.city ? data.city : [];
    let emissionReductionNum = [];
    if (data.cityCarbon) {
      emissionReductionNum = data.cityCarbon.map(item => item);
    }
    let option = {
        title: {
          text: '',
          left: 15,
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
          top: 45
        },
        xAxis: {
          type: 'category',
          data: cityName,
          axisTick: { show: false },
          axisLabel: {
            color: "#A7A7A7",
            interval: 0,
            rotate: cityName && cityName.length > 6 ? 30 : 0
          },
        },
        yAxis: {
          type: 'value',
          name: '减排(KG)',
          nameTextStyle: {
              fontSize: 13,
              fontWeight: 600,
              color: '#000'
          }
        },
        series: {
          type: 'bar',
          datasetIndex: 1,
          data: emissionReductionNum,
          label: {
              show: true,
              position: 'top',
          },
          itemStyle: {
              normal: {
                //这里是颜色
                color: '#3F7CF6',
              }
          }
        }
    };
    if (option.series && option.series.data && option.series.data.length > 0) {
      this.setState({
        cityCarbonOption: option
      })
    } else {
      this.setState({
        cityCarbonOption: {
          ...this.state.cityCarbonOption,
          series: []
        }
      })
    }
  }

  // 低碳出行场景分析数据
  sceneSummary = (data) => {
    let sceneName = data.sceneName ? data.sceneName : [];
    let dataList = data.data ? data.data : [];
    let option = {
        title: {
          text: '',
          top: 25,
          left: 15
        },
        tooltip: {
          trigger: 'none',
          showContent: false,
          alwaysShowContent: false,
          axisPointer: {
            type: 'none',
            label: {
              show: false
            }
          }
        },
        grid: {
        },
        legend: {
          orient: 'vertical',
          top: 'middle',
          height: '40%',
          type: 'scroll',
          right: 0,
          data: sceneName,
          icon: "circle",
          formatter: function (name) {if (!name) return '';if (name.length > 8) {name =  name.slice(0,8) + '...';}return name},
          textStyle: {
            fontSize: 13,
            fontWeight: 'bolder',
            color: ['#ff8811', '#333']
          },
        },
        color:['#2a6df4','#42a737','#ffa82d','#ef3442','#97b8f9'],
        series: [
          {
            //name: 'Access From',
            type: 'pie',
            radius: ['35%', '70%'],
            center:['31%','50%'],
            avoidLabelOverlap: false,
            itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2,
                showAbove: true
            },
            label: {
                show: true,
                fontSize: 12,
                color: '#FFFFFF',
                normal:
                {
                    formatter: '{c}%',
                    position: 'inside',
                    color: '#FFFFFF',
                    show: true
                },
            },
            emphasis: {
                label: {
                    show: true,
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: '#FFFFFF'
                }
            },
            labelLine: {
                show: false
            },
            data: dataList
          }
        ]
    }

    if (option.series && option.series[0] && option.series[0].data && option.series[0].data.length > 0) {
      this.setState({
        sceneSummaryOption: option
      })
    } else {
      this.setState({
        sceneSummaryOption: {
          ...this.state.sceneSummaryOption,
          series: []
        }
      })
    }
  }

  // 减排订单对比图表
  monthCarbonRatioSummary = (data) => {
    let month = []
    if (data && data.month) {
      month = data.month.map(item => {
        item = item.replace('0', '')
        return moment(item).format('M月')
      });
    }
    let proportionCarbon = data.newPowerRatio ? data.newPowerRatio.map(item => item = item - 0) : [];
    let proportionOther = data.ohterRatio ? data.ohterRatio.map(item => item = item - 0) : [];
    let option = {
        title: {
          text: '',
          top: 25,
          left: 15,
        },
        // tooltip: {
        //     trigger: 'axis',
        //     axisPointer: {
        //         type: 'shadow'
        //     },
        //     formatter: function (params) {
        //         var str = '';
        //         //console.log(params);
        //         //对params进行操作
        //         params.forEach(item => {
        //             // eslint-disable-next-line
        //             str += item.marker + " " + item.seriesName + ":" + item.value + "%" + "</br>"
        //         })
        //         return str;
        //     }
        // },
        legend: {
          data: ['低碳订单', '其他订单'],
          textStyle: {
            fontSize: 13
          },
          top: 10,
          itemWidth: 8,
          itemHeight: 8,
          axisLabel: {
            color: "#A7A7A7",
            interval: 0,
            rotate: month && month.length > 6 ? 30 : 0
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [{
          type: 'category',
          data: month,
          axisTick: { show: false },
          axisLine: {
              lineStyle: {
                  type: 'solid',
                  color: '#f8f8f8',//左边线的颜色
                  width: '1.2'//坐标线的宽度
              },
          },
          axisLabel: {
              color: "#A7A7A7",
              interval: 0
          },
          axisPointer: {
              type: 'shadow'
          }
        }],
        yAxis: {
          type: 'value',
          name: '百分比(%)',
          min: 0,
          max: 100,
          inverse: true,
          axisLabel: {
            show: false,
            interval: 0,
            formatter: '{value} %'  // 给每个数值添加%
          },
          nameRotate: 90,
          nameGap: 10,
          nameLocation: "bottom",
          nameTextStyle: {  // y轴name的样式调整
            color: '#000',
            fontSize: 12,
          },
          show: true,
          axisTick:{
            show:false // 不显示坐标轴刻度线
          },
          axisLine: {
            show: false, // 不显示坐标轴线
          }
        },
        series: [
          {
            // data 需要算出百分比乘以100
            name: '低碳订单',
            data: proportionCarbon,
            type: 'bar',
            showBackground: true,
            stack: 'total',
            color: '#4CAC41',
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },
            label: {
                show: true,
                normal: {
                formatter: function (params) {
                  if (params.value > 0) {
                    return params.value + '%';
                  } else {
                    return '';
                  }
                },
                show: true,
                color: '#FFF',
                fontWeight: 200
                }
            }
          },
          {
            name: '其他订单',
            data: proportionOther,
            type: 'bar',
            showBackground: true,
            stack: 'total',
            color: '#3F7CF6',
            backgroundStyle: {
              color: '#3F7CF6'
            },
            label: {
              show: false,
              normal: {
                formatter: '{c}%',
                show: false
              }
            }
          }
        ]
    }
    if (option.series && option.series[0] && option.series[0].data && option.series[0].data.length > 0) {
      this.setState({
        monthCarbonRatioOption: option
      })
    } else {
      this.setState({
        monthCarbonRatioOption: {
          ...this.state.monthCarbonRatioOption,
          series: []
        }
      })
    }
  }

  // 场景/低碳其他占比
  sceneRatioSummary = (data) => {
    let sceneName = data.sceneName;
    let otherRatio = data.otherRatio.map(item => item = item - 0);
    let newPowerOrderRatio = data.newPowerOrderRatio.map(item => item = item - 0);
    let option = {
        title: {
          text: '',
          top: 25,
          left: 15
        },
        // tooltip: {
        //   trigger: 'axis',
        //   axisPointer: {
        //       type: 'shadow'
        //   },
        //   formatter: function (params) {
        //       var str = '';
        //       //console.log(params);
        //       //对params进行操作
        //       params.forEach(item => {
        //           // eslint-disable-next-line
        //           str += item.marker + " " + item.seriesName + ":" + item.value + "%" + "</br>"
        //       })
        //       return str;
        //   }
        // },
        legend: {
          data: ['低碳订单', '其他订单'],
          textStyle: {
            fontSize: 13
          },
          top: 10,
          itemWidth: 8,
          itemHeight: 8
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          data: sceneName,
          axisTick: { show: false },
          axisLine: {
              lineStyle: {
                  type: 'solid',
                  color: '#f8f8f8',//左边线的颜色
                  width: '1.2'//坐标线的宽度
              }
          },
          axisLabel: {
            color: "#A7A7A7",
            interval: 0,
            rotate: sceneName && sceneName.length > 4 ? 40 : 0,
          },
          axisPointer: {
              type: 'shadow'
          }
        }],
        yAxis: {
          type: 'value',
          name: '百分比(%)',
          min: 0,
          max: 100,
          inverse: true,
          axisLabel: {
              show: false,
              interval: 0,
              formatter: '{value} %'  // 给每个数值添加%
          },
          nameRotate: 90,
          nameGap: 10,
          nameLocation: "bottom",
          nameTextStyle: {  // y轴name的样式调整
              color: '#000',
              fontSize: 12,
          },
          show: true,
          axisTick:{
              show:false // 不显示坐标轴刻度线
          },
          axisLine: {
              show: false, // 不显示坐标轴线
          }
        },
        series: [
            {
              // data 需要算出百分比乘以100
              name: '低碳订单',
              data: newPowerOrderRatio,
              type: 'bar',
              stack: 'total',
              color: '#4CAC41',
              backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)'
              },
              label: {
                show: true,
                normal: {
                  formatter: function (params) {
                    if (params.value > 0) {
                      return params.value + '%';
                    } else {
                      return '';
                    }
                  },
                  show: true,
                  color: '#FFF',
                  fontWeight: 200,
                }
              }
            },
            {
              name: '其他订单',
              data: otherRatio,
              type: 'bar',
              showBackground: true,
              stack: 'total',
              color: '#3F7CF6',
              backgroundStyle: {
                color: '#3F7CF6'
              },
              label: {
                show: false,
                normal: {
                  formatter: '{c}%',
                  show: false
                }
              }
            }
        ]
    }
    if (option.series && option.series[0] && option.series[0].data && option.series[0].data.length > 0) {
      this.setState({
        sceneRatioSummary: option
      })
    } else {
      this.setState({
        sceneRatioSummary: {
          ...this.state.sceneRatioSummary,
          series: []
        }
      })
    }
  }

  // 碳积分查询
  getMenuCarbonQuery = async () => {
    let {data} = await webService.order.getMenuCarbonQuery({startTime: new Date(this.state.startTime), endTime: new Date(this.state.endTiem)});
    if (data.code === 0) {
      this.setState({
        titleSummay: {
          ...this.state.titleSummay,
          monthCarbon: data.data.titleSummay.monthCarbon,
          monthOrderCount: data.data.titleSummay.monthOrderCount,
          yearCarbon: data.data.titleSummay.yearCarbon,
          yearOrderCount: data.data.titleSummay.yearOrderCount
        },
        departmentCarbonsList: data.data.departmentCarbons
      })
      this.monthCarbonSummary(data.data.monthCarbonSummary);
      this.cityCarbonSummary(data.data.cityCarbonSummary);
      this.sceneSummary(data.data.sceneSummary);
      this.monthCarbonRatioSummary(data.data.monthRatioSummary);
      this.sceneRatioSummary(data.data.sceneRatioSummary)
    }
  }
}

export default withTranslation() (CarbonEmission)