import React from 'react';
import style from './smartChoose.scss';

class SmartChooseDetails extends React.Component {

    t = this.props.t

    render() {
        let {currentCarLevelDetails} = this.props;
        return <div className={style.remark}>
            <div className={style.title}>
                <div>{this.t('estimate.k015')}</div>
            </div>
            <div>
                <div className={style.body}>
                    <div className={style.ascending}>
                        
                        {
                          currentCarLevelDetails.leftSourceName !== currentCarLevelDetails.rightSourceName ? <>
                            <div className={style.cartype}>
                              <img src={currentCarLevelDetails.leftLogo} alt="" />
                              {currentCarLevelDetails.leftSourceName}
                            </div>
                            <div className={style.arrow}></div>
                            <div className={style.cartype}>
                                <img src={currentCarLevelDetails.rightLogo} alt="" />
                                {currentCarLevelDetails.rightSourceName}
                            </div>
                          </> : <>
                            <div className={style.cartype}>
                              <img src={currentCarLevelDetails.leftLogo} alt="" />
                              {currentCarLevelDetails.leftSourceName}
                            </div>
                          </>
                        }
                    </div>
                    <div className={style.prices}>
                      {
                        currentCarLevelDetails.leftPrice !== currentCarLevelDetails.rightPrice ? <>
                          <div><span>￥</span> {currentCarLevelDetails.leftPrice}</div>
                          <div><span>￥</span> {currentCarLevelDetails.rightPrice}</div>
                        </> : <div><span>￥</span> {currentCarLevelDetails.leftPrice}</div>
                      }
                    </div>
                    <div className={style.carleves}>
                      {
                        currentCarLevelDetails.leftLeveName !== currentCarLevelDetails.rightLeveName ? <>
                          <div>{currentCarLevelDetails.leftLeveName}</div>
                          <div>{currentCarLevelDetails.rightLeveName}</div>
                        </> : <div>{currentCarLevelDetails.leftLeveName}</div>
                      }
                    </div>
                    <div className={style.dynamic}>
                        {
                          currentCarLevelDetails.leftTip !== currentCarLevelDetails.rightTip ? <>
                            <div>{this.t('estimate.k016')}<span>{currentCarLevelDetails.leftTip}</span>{this.t('estimate.k020')}</div>
                            <div>{this.t('estimate.k016')}<span>{currentCarLevelDetails.rightTip}</span>{this.t('estimate.k020')}</div>
                          </> : <div>{this.t('estimate.k016')}<span>{currentCarLevelDetails.leftTip}</span>{this.t('estimate.k020')}</div>
                        }

                    </div>
                </div>
            </div>
            <div className={style.footbar} onClick={() => {this.props.onHide()}}>{this.t('estimate.k017')}</div>
        </div>
    }
}

export default SmartChooseDetails;