import React from 'react';
import style from './preApproval.scss';

import {inject, observer } from 'mobx-react'

import { Tabs, Checkbox, Button, Space, InfiniteScroll, Dialog, TextArea } from 'antd-mobile'

import ApplyRecord from './../component/perApproval/applyRecord'

import webService from '../util/webService';
import images from './../util/images'

import { withTranslation } from "react-i18next";

/**
 * 行前审批-审批
 */
@inject('globalStore')
@observer
class PreApproval extends React.Component {

    state = {
        tabKey: 'waiting',

        pageData: {
            list: [],
            pageNum: 1,
            pageSize: 10,
            total: 0
        },
        isData: null
    }

    lastLoadTimestamp = 0;

    globalStore = this.props.globalStore

    t = this.props.t

    get isCheckedAll() {
        return this.state.pageData.list.length && this.state.pageData.list.every(a => a.checked === true)
    }

    get listHashMore() {
        let pageData = this.state.pageData;
        return !(Number(pageData.total) !== 0 && Number(pageData.total) === pageData.list.length)
    }

    get isWaitingModel() {
        return this.state.tabKey === 'waiting'
    }

    renderList() {
        return <>
            {
                this.state.pageData.list.map(item => (
                    <ApplyRecord t={this.props.t} history={this.props.history} data={item} key={item.preDepartApplyId} 
                        showApproveStep={true} showCheckBox={this.isWaitingModel} checked={item.checked} onCheck={
                            () => {
                                this.onChecked(item.preDepartApplyId)
                            }} />
                ))
            }
            <InfiniteScroll loadMore={this.load} hasMore={this.listHashMore} threshold={20}></InfiniteScroll>
        </>
    }

    renderEmpty() {
        return <div className={style.nodata}>
            <div className={style.box}>
            <img alt="" src={images.reportnodata}></img>
            <span>{this.t('preApproval.k024')}</span>
            </div>
        </div>
    }

    render() {
        return <div className={style.container}>
            <Tabs onChange={(key) => {
                this.setState({
                    tabKey: key,
                    pageData: {
                        ...this.state.pageData,
                        list: [],
                        pageNum: 1,
                        total: 0
                    }
                }, () => {
                    this.load()
                })
            }}>
                <Tabs.Tab title={this.t('preApproval.k021')} key='waiting' />
                <Tabs.Tab title={this.t('preApproval.k025')} key='completed' />
            </Tabs>
            <div className={style.body}>
                <div>
                    {
                        this.state.pageData.list.length && this.state.isData  ? this.renderList() : <></>
                    }
                    {
                        !this.state.isData && this.state.isData !== null ? this.renderEmpty() : <></>
                    }
                </div>
                {
                  (() => {
                    if (this.state.tabKey === 'waiting') {
                      if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.preApproval_btn) {
                        return(
                          <div className={style.footbar}>
                            <div>
                                <Checkbox checked={this.isCheckedAll} onChange={this.onCheckedAll}>{this.t('preApproval.k026')}</Checkbox>
                            </div>
                            <Space>
                                <Button size='small' shape='rounded' onClick={() => {this.approvel(3)}}>{this.t('preApproval.k027')}</Button>
                                <Button color='primary' size='small' shape='rounded' onClick={() => {this.approvel(2)}}>{this.t('preApproval.k028')}</Button>
                            </Space>
                            
                            
                        </div>
                        )
                      }
                    } else {
                      return <></>
                    }
                  })()
                }
                
            </div>
        </div>
    }

    componentDidMount() {
        this.load();
    }

    onCheckedAll = (checked) => {
        let list = this.state.pageData.list;
        for (let i in list) {
            list[i].checked = checked
        }
        this.setState({
            pageData: this.state.pageData
        })
    }
    
    onChecked = (preDepartApplyId) => {
        let record = this.state.pageData.list.filter(a => a.preDepartApplyId === preDepartApplyId)[0];
        record.checked = !record.checked;
        this.setState({
            pageData: this.state.pageData
        })
    }

    load = async () => {
        let pageData = this.state.pageData;
        if (Number(pageData.total) !== 0 && Number(pageData.total) === pageData.list.length) {
            // total非0，表示已经加载过数据，total与list的长度相同，则表示已经加载过最后一页
            return;
        }

        let params = {
            approvalState: this.isWaitingModel ? 0 : 1,

            pageNum: pageData.pageNum,
            pageSize: pageData.pageSize
        };
        let currentTimestamp = new Date().getTime();
        this.lastLoadTimestamp = currentTimestamp;
        let { data } = await webService.preApproval.queryApplyListByApprover(params);
        if (this.lastLoadTimestamp !== currentTimestamp) {
            return;
        }
        if (data.code === 0) {
            if (Array.isArray(data.data)) {
                let rows = data.data.rows;
                data.data = {
                    rows: rows,
                    totalCounts: rows.length,
                    currentPage: 1,
                    pageSize: 10
                }
            }
            
        } else {
            Dialog.alert({
                content: data.message || this.t('preApproval.k029'),
                confirmText: this.t('common.k003')
            })
            return;
        }
        if (data.code === 0 && data.data && data.data.rows && data.data.rows.length) {
            this.setState({
                pageData: {
                    ...this.state.pageData,
                    list: [...this.state.pageData.list, ...data.data.rows],
                    total: data.data.totalCounts,
                    pageNum: data.data.currentPage + 1
                }
            }, () => {
                if (this.state.pageData.list && this.state.pageData.list.length > 0) {
                  this.setState({
                    isData: true
                  })
                } else {
                  this.setState({
                    isData: false
                  })
                }
            })
        } else {
          this.setState({
            isData: false
          })
        }

    }

    approvel = async (approvelState) => {
        let checkedRecord = this.state.pageData.list.filter(a => a.checked === true).map(a => a.preDepartApplyId);
        if (checkedRecord.length < 1) {
            Dialog.alert({
                content: this.t('preApproval.k030'),
                confirmText: this.t('common.k003')
            })
            return;
        }
        let rejectReason = '';
        if (approvelState === 3) {
            let res = await Dialog.confirm({
                content: <>
                    <TextArea placeholder={this.t('preApproval.k031')} maxLength={200} rows={3} onChange={(val) => {rejectReason = val}} />
                </>,
                cancelText: this.t('common.k001'),
                confirmText: this.t('common.k002')

            })
            if (!res) {
                return;
            }
            if (res && !rejectReason) {
                await Dialog.alert({
                    content: this.t('preApproval.k031'),
                    confirmText: this.t('common.k003')
                })
                return;
            }
        }
        let params = {
            preDepartApplyIds: checkedRecord,
            approvalState: approvelState,
            rejectReason: rejectReason
        }

        let {data} = await webService.preApproval.approvePreDepartApply(params);
        if (data.code === 0) {
            Dialog.alert({
                content: this.t('preApproval.k032'),
                confirmText: this.t('common.k003')
            })
            this.setState({
                pageData: {
                    ...this.state.pageData,
                    list: [],
                    pageNum: 1,
                    total: 0
                }
            }, () => {
                this.load()
            })
        } else {
            Dialog.alert({
                content: data.message || this.t('preApproval.k033'),
                confirmText: this.t('common.k003')
            })
        }
    }
}

export default withTranslation()(PreApproval);