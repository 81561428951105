import React from 'react';
import {inject, observer } from 'mobx-react'
import webService from '../../util/webService';
import style from './appendManualChoose.scss';
import { Dialog, DotLoading } from 'antd-mobile'

import LoadingMask from './../../component/utils/loadingMask';

import { debounce } from './../../util/utils';
import { findLogoSrc } from './../../util/logoCache'

@inject('estimateStore', 'runingOrderStore')
@observer
class AppendManualChoose extends React.Component {

    state = {
        cars: [],
        currenCheckedDetails: {
            count: 0,
            detail: '',
            leftPrice: 0,
            rightPrice: 0,
            list: [],
          },
        isShow: false,
        isValidationAccount: false,
        isCars: false,
        estimateId: '',
        prepaidAmount: 0
    }

    t = this.props.t

    estimateStore = this.props.estimateStore
    runingOrderStore = this.props.runingOrderStore

    render() {
        let cars = this.state.cars;
        return <div className={style.appendCars}>
          {

            cars.length > 0 ? <div className={style.cars}>
                {
                  cars.map(item => {
                        return <div className={style.carpannel} key={item.id}>
                            <div className={style.head}>
                                <div>{item.nameCn}{this.t('runing.k017')}</div>
                                <div>{this.t('runing.k020')}</div>
                                <div className={item.checkAll ? `${style.cbx} ${style.checked}` : style.cbx}>
                                <div onClick={() => {this.checkLevelAll(item)}}></div>
                                </div>
                            </div>

                            <div className={style.carlist}>
                                {
                                    item.list.map(car => {
                                        return <div className={style.car} key={car.dynamicCode}>
                                            <div>
                                                <img src={car.carSourceImg} alt="" />                
                                            </div>
                                            <div>{car.carSource}</div>

                                            <div>
                                              <div>{this.t('runing.k018')}<span>{car.estimatePrice}</span>{this.t('runing.k070')}</div>
                                              {
                                                    car.couponAmount
                                                        ? <div>{this.t('estimate.k021')}<span>{car.couponAmount}</span>{this.t('estimate.k018')}</div>
                                                        : <></>
                                                }
                                            </div>

                                            <div className={car.checked ? `${style.cbx} ${style.checked}` : style.cbx}>
                                                <div onClick={() => {this.checkCar(item, car)}}></div>
                                            </div>
                                        </div>
                                    })
                                }
                                
                                
                            </div>

                            
                        </div>
                    })
                }
            </div> : <div className={style.loading}>
                        {
                          this.state.isCars ? this.t('runing.k071') : <DotLoading style={{ fontSize: 20 }}/>
                        }
                     </div>
          }
            
            <div className={style.btncont}>
                <div className={this.state.currenCheckedDetails.count > 0 ? `${style.btn} ${style.active}` : style.btn} onClick={debounce(() => this.call(), 0)}>
                    {/* {
                        this.state.currenCheckedDetails.count > 0
                        ? <>
                            <div>
                                追加呼叫<span> (预估<span>{this.state.currenCheckedDetails.leftPrice}-{this.state.currenCheckedDetails.rightPrice}</span>元)</span>
                            </div>
                            <div>
                                已选择<span>{this.state.currenCheckedDetails.count}</span>种车型{this.state.currenCheckedDetails.detail}
                            </div>
                        </>
                        : <>
                            <div>同时呼叫</div>
                        </>
                    } */}
                    {
                        (() => {
                            if(this.state.currenCheckedDetails.count > 0 && this.state.prepaidAmount === 0) {
                                return(
                                    <>
                                    <div>
                                        {this.t('runing.k021')}<span> ({this.t('runing.k018')}<span>
                                        {this.state.currenCheckedDetails.leftPrice === this.state.currenCheckedDetails.rightPrice
                                        ? this.state.currenCheckedDetails.leftPrice
                                        : `${this.state.currenCheckedDetails.leftPrice}-${this.state.currenCheckedDetails.rightPrice}`
                                        }
                                          </span>{this.t('runing.k070')})</span>
                                    </div>
                                    <div>
                                        {this.t('runing.k022')}<span>{this.state.currenCheckedDetails.count}</span>{this.t('runing.k072')}{this.state.currenCheckedDetails.detail}
                                    </div>
                                    </>
                                )
                            } else if (this.state.prepaidAmount > 0 && this.state.currenCheckedDetails.rightPrice > 0) {
                                return (
                                    <>
                                        <div>
                                            {this.t('runing.k021')}<span> ({this.t('runing.k018')}<span>
                                            {this.state.currenCheckedDetails.leftPrice === this.state.currenCheckedDetails.rightPrice
                                        ? this.state.currenCheckedDetails.leftPrice
                                        : `${this.state.currenCheckedDetails.leftPrice}-${this.state.currenCheckedDetails.rightPrice}`
                                        }
                                              </span>{this.t('runing.k070')})</span>
                                        </div>
                                        <div>
                                            {this.t('runing.k073', {prepaidAmount: this.state.prepaidAmount})}
                                        </div>
                                    </>
                                )
                            } else {
                                return(
                                    <>
                                        <div>{this.t('runing.k023')}</div>
                                        <div>{this.t('runing.k022')}<span> {this.state.currenCheckedDetails.count} </span>{this.t('runing.k072')}</div>
                                    </>
                                )
                            }
                        })()
                    }
                </div>
            </div>
            {
            this.state.isShow ?
            <LoadingMask opacityNum={0.4} loadingType={'spin'} show={this.state.isShow}></LoadingMask> : <></>
            }
        </div>
    }

    componentDidMount() {
        webService.order.appendEstimate({orderId: this.props.orderId}).then(d => {
            
            // 判断是否全选某一个车型
            let checkedCars = this.estimateStore.checkedCars.filter((item) => {return item.checkAll === true})

            if (d.data.code === 0) {
              // console.log('this.runingOrderStore.cars', this.runingOrderStore.cars)
              // console.log('this.estimateStore.cars', this.estimateStore.checkedCars)
              for (let i in d.data.data.cars) {
                let car = d.data.data.cars[i];
                car.list.forEach((item) => {
                  item.carSourceImg = findLogoSrc(item.carSourceId);
                });
                car.list.sort((a, b) => a.estimatePrice - b.estimatePrice)
                car.checkCount = 0;
                // 之前如果单选过某个车型,则增加车型时单选的车型默认全部选中
                for(let j in this.runingOrderStore.cars) {
                  let runingCars = this.runingOrderStore.cars[j];
                  // 选择后一个高级车型让前一个车型全选
                  if (runingCars && runingCars.reimModel && car.reimModel.carLevel < runingCars.reimModel.carLevel) {
                    car.checkAll = true;
                    car.list.forEach((a) => {
                      a.checked = car.checkAll;
                    });
                    car.checkCount = car.list.filter((a) => {return a.checked === true}).length;
                  }
                  // 报销比例时
                  else if (runingCars && runingCars.reimModel && runingCars.reimModel.carLevel === car.reimModel.carLevel) {
                    car.checkAll = true;
                    car.list.forEach((a) => {
                      a.checked = car.checkAll;
                    });
                    car.checkCount = car.list.filter((a) => {return a.checked === true}).length;
                  } else {
                      // 报销全部时判断使用 id 报销全部时没有返回 reimModel

                      // 选择后一个高级车型让前一个车型全选
                      if (runingCars && runingCars.id && car.id < runingCars.id) {
                        car.checkAll = true;
                        car.list.forEach((a) => {
                          a.checked = car.checkAll;
                        });
                        car.checkCount = car.list.filter((a) => {return a.checked === true}).length;
                      }
                      // 选择某级单个车型让这级全选
                      else if (runingCars && runingCars.id && runingCars.id === car.id) {
                        car.checkAll = true;
                        car.list.forEach((a) => {
                          a.checked = car.checkAll;
                        });
                        car.checkCount = car.list.filter((a) => {return a.checked === true}).length;
                    }
                  }
                }
                // 如果全选某一个车型则增加车行时全选下一个更高车型
                checkedCars && checkedCars.forEach((item) => {
                  // 报销比例时
                  if (item && item.checkAll && item.reimModel) {
                    if (car.reimModel.carLevel < item.reimModel.carLevel) {
                      car.checkAll = true;
                      car.list.forEach((a) => {
                        a.checked = car.checkAll;
                      });
                      car.checkCount = car.list.filter((a) => {return a.checked === true}).length;
                    }  else if (item.reimModel.carLevel === 1 && car.reimModel.carLevel === 2) {
                      car.checkAll = true;
                      car.list.forEach((a) => {
                        a.checked = car.checkAll;
                      });
                      car.checkCount = car.list.filter((a) => {return a.checked === true}).length;
                    } else if (item.reimModel.carLevel === 2 && car.reimModel.carLevel === 3) {
                      car.checkAll = true;
                      car.list.forEach((a) => {
                        a.checked = car.checkAll;
                      });
                      car.checkCount = car.list.filter((a) => {return a.checked === true}).length;
                    } else if (item.reimModel.carLevel === 3 && car.reimModel.carLevel === 4) {
                      car.checkAll = true;
                      car.list.forEach((a) => {
                        a.checked = car.checkAll;
                      });
                      car.checkCount = car.list.filter((a) => {return a.checked === true}).length;
                    }
                  }
                  // 报销全部时 
                  else if (item && item.checkAll && item.id) {
                    if (car.id < item.id) {
                      car.checkAll = true;
                      car.list.forEach((a) => {
                        a.checked = car.checkAll;
                      });
                      car.checkCount = car.list.filter((a) => {return a.checked === true}).length;
                    } else if (item.id === 1 && car.id === 2) {
                      car.checkAll = true;
                      car.list.forEach((a) => {
                        a.checked = car.checkAll;
                      });
                      car.checkCount = car.list.filter((a) => {return a.checked === true}).length;
                    } else if (item.id === 2 && car.id === 3) {
                      car.checkAll = true;
                      car.list.forEach((a) => {
                        a.checked = car.checkAll;
                      });
                      car.checkCount = car.list.filter((a) => {return a.checked === true}).length;
                    } else if (item.id === 3 && car.id === 4) {
                      car.checkAll = true;
                      car.list.forEach((a) => {
                        a.checked = car.checkAll;
                      });
                      car.checkCount = car.list.filter((a) => {return a.checked === true}).length;
                    }
                  }
                })
              }
                this.setState({
                    cars: d.data.data.cars,
                    estimateId: d.data.data.estimateId,
                    isCars: d.data.data.cars.length > 0 ? false : true
                }, () => {
                  this.fillCurrenCheckedDetails()
                })
            } else {
              this.setState({
                isShow: false,
                isCars: true,
                cars: []
              })
            }
        }).catch(e => {
          this.setState({
            isShow: false,
            isCars: true,
            cars: []
          })
        })
    }

    componentWillUnmount() {
    }

    /**
     * 全选某个车等级
     * @param {*} carlevel 
     */
     checkLevelAll = (carlevel) => {
        carlevel.checkAll = !carlevel.checkAll;
        if (carlevel.list.length) {
          carlevel.list.forEach((a) => {
            a.checked = carlevel.checkAll;
          });
          carlevel.checkCount = carlevel.list.filter((a) => {return a.checked === true}).length;

        }

        this.setState({
            activeLevel: this.state.activeLevel
        })
        
        this.fillCurrenCheckedDetails();
    }

    /**
     * 选择某个运力平台
     * @param {*} carlevel 
     * @param {*} car 
     */
    checkCar = (carlevel, car) => {  
        car.checked = !car.checked;
        carlevel.checkCount = carlevel.list.filter((a) => {return a.checked === true}).length;
        carlevel.checkAll = carlevel.checkCount === carlevel.list.length;
      
   
        this.fillCurrenCheckedDetails();
    }

    /**
     * 填充选中运力平台数据
     */
    fillCurrenCheckedDetails = () => {
        let currenCheckedDetails = this.state.currenCheckedDetails;
        currenCheckedDetails.count = 0;
        currenCheckedDetails.detail = '';
        let _list = [];
        let _listChecked = [];
        this.state.cars.forEach((a) => {
            currenCheckedDetails.count += a.checkCount;
            if (a.checkCount > 0) {
            _list.push(`${a.checkCount}${this.t('runing.k074')}${a.nameCn}`);

            a.list.forEach((b) => {
                if (b.checked) {
                _listChecked.push(b);
                }
            });
            }
        });

        let carLevel = null

        if (currenCheckedDetails.count > 0) {
            currenCheckedDetails.detail = `(${_list.toString().replace(/,/g, '+')})`;

            _listChecked = _listChecked.sort((a, b) => {return a.estimatePrice - b.estimatePrice});

            currenCheckedDetails.list = _listChecked;
            currenCheckedDetails.leftPrice = _listChecked[0].estimatePrice;
            currenCheckedDetails.rightPrice = _listChecked[_listChecked.length - 1].estimatePrice;
            carLevel = currenCheckedDetails.list.filter(a => a.estimatePrice === currenCheckedDetails.rightPrice)[0].carLevel
        } else {
            // 则未选择增加车型
            currenCheckedDetails.rightPrice = 0
        }
  
        this.setState({
            currenCheckedDetails: currenCheckedDetails
        })


        if (currenCheckedDetails.count > 0) {
          let cars = this.state.cars;
          let _cars = [];
          
          for (let i in cars) {
              let car = cars[i];
              for (let j in car.list) {
                  let carSource = car.list[j];
                  if (carSource.checked) {
                      _cars.push({
                          carLevel: carSource.carLevel,
                          carSourceId: carSource.carSourceId,
                          estimatePrice: carSource.estimatePrice,
                          dynamicCode: carSource.dynamicCode,
                          carLevelName: carSource.carLevelName,
                          couponId: carSource.couponId,
                          couponAmount: carSource.couponAmount
                      }) 
                  }
              }
          }
          webService.order.getPrePayAmount({estimateId: this.state.estimateId, orderId: this.props.orderId, amount: currenCheckedDetails.rightPrice, carLevel: carLevel, cars: _cars, isValidationAccount: this.state.isValidationAccount}).then(d => {
              if (d.data.code === 0) {
                  if (d.data.data.amount > 0) {
                    let prepaidInfo = {
                      amount: d.data.data.amount,
                      orderId: d.data.data.orderId,
                      time: d.data.data.time
                    }
                    // 存储预支付返回金额、倒计时、等
                    localStorage.setItem('prepaidInfo', JSON.stringify(prepaidInfo));
                  }

                  this.setState({
                      prepaidAmount: d.data.data.amount
                  })
              } else {
                this.setState({
                  prepaidAmount: 0
                })
                Dialog.alert({
                  content: d.data.message,
                  confirmText: this.t('common.k003'),
                })
              }
          })
        }
        
    }

    /**
     * 追加下单
     */
    call = async () => {
        
        this.setState({
          isValidationAccount: true,
          isShow: true
        })

        let cars = this.state.cars;
        let _cars = [];
        
        for (let i in cars) {
            let car = cars[i];
            for (let j in car.list) {
                let carSource = car.list[j];
                if (carSource.checked) {
                    _cars.push({
                        carLevel: carSource.carLevel,
                        carSourceId: carSource.carSourceId,
                        estimatePrice: carSource.estimatePrice,
                        dynamicCode: carSource.dynamicCode,
                        carLevelName: carSource.carLevelName,
                        couponId: carSource.couponId,
                        couponAmount: carSource.couponAmount
                    }) 
                }
            }
        }

        if (_cars && _cars.length > 0) {
          let carLevel = this.state.currenCheckedDetails.list.filter(a => a.estimatePrice === this.state.currenCheckedDetails.rightPrice)[0].carLevel
          let d = await webService.order.getPrePayAmount({estimateId: this.state.estimateId, orderId: this.props.orderId, amount: this.state.currenCheckedDetails.rightPrice, carLevel: carLevel, cars: _cars, isValidationAccount: this.state.isValidationAccount})
          // 需要预支付
          if (d.data.code === 1) {
            this.setState({
              prepaidAmount: 0,
              isValidationAccount: false
            })
            this.setState({
              isShow: false
            })
            Dialog.alert({
              content: d.data.message,
              confirmText: this.t('common.k003'),
            })
          } else {
            if (this.state.prepaidAmount > 0) {
              this.setState({
                isShow: false
              })
              this.runingOrderStore.setCars(this.state.cars)
              this.estimateStore.setCheckedCars(this.state.cars)
                sessionStorage.setItem('appendCarTypePrePay', true);
                this.props.history.push('/prepaid')
                // 先把追加车型入参存储到 localStorage, 支付成功后进行追加下单
            } else {
                // 追加车型需要判断个人支付是否超过预支付金额如果没有直接追加，
                // 如果超出需要等个人支付后超出预支付的金额, 然后回到进行中页面后，查询超出预支付状态成功后回到订单行程中页面, 追加下单由后端调用接口
                webService.order.appendPlaceOrder({
                  cars: _cars,
                  orderId: this.props.orderId,
                  estimateId: this.state.estimateId
                }).then(d => {
                    if (d.data.code === 0) {
                      // this.runingOrderStore.setCars(this.state.cars)
                      this.estimateStore.setCheckedCars(this.state.cars)
                      this.props.changeAppend()
                      this.setState({
                        isShow: false
                      })
                    } else {
                      this.setState({
                        isShow: false
                      })
                        Dialog.alert({
                            content: d.data.message,
                            confirmText: this.t('common.k003'),
                        })
                    }
                })
            }
          }
        } else {
          this.setState({
            isShow: false
          })
          Dialog.alert({
            content: this.t('runing.k078'),
            confirmText: this.t('common.k003'),
          })
        }
        
    }
}

export default AppendManualChoose;