import React from 'react';
import { inject, observer } from 'mobx-react'
import style from './callcar.scss'

import { Popup } from 'antd-mobile'
import Address from './address'

import { ForgeRouter } from './../../util/utils'

@inject('mainStore', 'globalStore')
@observer
class Pathway extends React.Component {

    state = {
        show: false
    }
    mainStore = this.props.mainStore;
    globalStore = this.props.globalStore;
    needCallBack = false;

    t = this.props.t

    onChoosedPathWayAddr = undefined

    fr = undefined

    renderPathWay(item, index) {
        return <div className={style.line} key={index}>
            <div className={style.icon}>
                {/* <img src={images.end} alt='' /> */}
                <div className={style.index}>{index + 1}</div>
            </div>
            <div className={style.content} onClick={() => {
                this.onChoosedPathWayAddr = (_item) => {
                    this.mainStore.setPathways(_item, index)
                }
                this.setState({
                    show: true
                }, () => {
                    this.fr = new ForgeRouter((isManual) => {
                        let { hash } = document.location;
                        if (isManual || hash === '#/') {
                            this.setState({
                                show: false
                            }, () => {
                                if (this.needCallBack) {
                                    this.props.onChange();
                                }
                            })

                        }
                    });
                    this.fr.onpopstate();
                })
            }} >
                {
                    (() => {
                        if (!item.name) {
                            // 请输入途经点
                            return <div className={style.where}>{this.t('main.k0165')}</div>
                        }
                    })()
                }
                {item.name}
            </div>
            <div className={style.close} onClick={(event) => {
                event.preventDefault()
                this.mainStore.removePathways(index)
                this.props.onChange();
            }}></div>
        </div>
    }

    render() {
        return <>
            {
                this.mainStore.pathways.map((item, index) => {
                    return this.renderPathWay(item, index)
                })
            }

            <Popup
                position='bottom'
                visible={this.state.show}
                bodyStyle={{ height: '100%' }}
            >
                {/* 地址选择框 */}
                {this.state.show ? <Address t={this.props.t} onChange={this.choosedAddr}
                    isCommonlyAddress={true}
                    moveMap={this.props.moveMap}
                    onCancel={() => {
                        this.onChoosedPathWayAddr = undefined;
                        this.props.history.goBack();

                        if (window !== window.top) {
                            this.fr.manualOperation()
                        }
                    }}
                    isBegin={undefined} cityCode={this.mainStore.endAddr.cityCode || this.mainStore.beginAddr.cityCode} cityName={this.mainStore.endAddr.cityName || this.mainStore.beginAddr.cityName}
                    history={this.props.history}
                ></Address> : ''}
            </Popup>
        </>


    }

    componentDidMount() {
        this.needCallBack = false;
    }

    get showPathway() {
        return true
    }

    /**
     * 点选地址
     * @param {*} item 
     */
    choosedAddr = (item) => {
        this.needCallBack = true;
        if (this.onChoosedPathWayAddr) {
            this.onChoosedPathWayAddr(item)
            this.onChoosedPathWayAddr = undefined
        }
        this.props.history.goBack();

        if (window !== window.top) {
            this.fr.manualOperation()
        }
    }
}


export default Pathway;