/**
 * 企业管理员续费
 */
import React from 'react'
import style from './accountCharge.scss';
import {inject, observer } from 'mobx-react'

import { Grid, Button, Input, Checkbox, Dialog, Toast  } from 'antd-mobile'
import { ExclamationCircleFill } from 'antd-mobile-icons'

import webService from './../util/webService';

import { ipay, payType } from './../util/utils'
import { oauthType } from './../util/config' 

import { withTranslation } from "react-i18next";

@inject('globalStore')
@observer
class AccountCharge extends React.Component {
    state = {
        balance: 0,
        frozen: 0,
        amounts: [
            {
                amount: 1000,
                checked: false
            },
            {
                amount: 2000,
                checked: false
            },
            {
                amount: 5000,
                checked: false
            },
            {
                amount: 10000,
                checked: false
            },
            {
                amount: 50000,
                checked: false
            },
            {
                amount: 200000,
                checked: false
            }
        ],

        otherAmount: '',
        invitationCode: '',
       
        showInvitationTip: false,
        checkedAgreement: false
    }

    accountId = this.props.match.params.id;

    globalStore = this.props.globalStore;

    t = this.props.t

    

    render() {
        return <div className={style.container}>
            <div className={style.info}>
                <div className={style.balance}>
                    <div className={style.lab}>{this.t('accountCharge.k001')}</div>
                    <div>￥{this.state.balance}</div>
                </div>
            </div>
            <div>
                <div>
                    <div className={style.lab}>{this.t('accountCharge.k002')}</div>
                    <div className={style.amounts}>
                        <Grid columns={3} gap={10}>
                            {
                                this.state.amounts.map(item => {
                                    return <Grid.Item key={item.amount} >
                                        <div className={item.checked && !this.state.otherAmount ? `${style.amount} ${style.checked}`: style.amount} onClick={() => {
                                            
                                            let amounts = this.state.amounts;
                                            for(let i in amounts) {
                                                amounts[i].checked = false;                                              
                                            }
                                            item.checked = true
                                            this.setState({
                                                amounts: amounts
                                            })
                                        }}>{item.amount}{this.t('accountCharge.k003')}</div>
                                    </Grid.Item>
                                })
                            }
                        </Grid>
                    </div>
                    <div className={style.otherAmount}>
                        <div>{this.t('accountCharge.k004')}</div>
                        <Input placeholder={this.t('accountCharge.k015')} type='text' onkeyup="value=value.replace(/[^\d]/g,'')" style={{'--font-size': '14px'}} clearable onClear={(val) => {
                          this.setState({
                            otherAmount: ""
                          })
                        }} value={this.state.otherAmount} onChange={(val) => {
                            const reg = /^\d+$/;
                            if (reg.test(val)) {
                              this.setState({
                                otherAmount: val
                              })
                            }
                        }} />
                    </div>
                </div>
                <div>
                    <div className={style.lab}>{this.t('accountCharge.k005')}</div>
                    <div className={style.invitationCode }>
                        <Input
                            placeholder={this.t('accountCharge.k006')}                            
                            style={{'--font-size': '14px'}}
                            clearable 
                            value={this.state.invitationCode} 
                            onChange={(val) => {
                                this.setState({
                                    invitationCode: val,
                                    showInvitationTip: val.length < 10 ? this.state.showInvitationTip : false 
                                })
                            }}
                            />
                    </div>
                    {
                        this.state.showInvitationTip
                            ? <div className={style.tip}><ExclamationCircleFill/> {this.t('accountCharge.k007')} </div>
                            : <></>
                    }
                    
                </div>
                <div>
                    <Checkbox checked={this.state.checkedAgreement} onChange={(val) => {
                      this.setState({checkedAgreement: val})
                      if (val) {
                        if (!this.globalStore.userInfo.acceptBusinessAgreement) {
                            webService.user.setUsageProtocol();
                        }
                        this.globalStore.setUserProtocol(true);
                      }
                    }} /> {this.t('accountCharge.k008')}<span onClick={() => {
                      this.props.history.push('/clauseDetails')
                    }}>{this.t('accountCharge.k009')}</span>
                </div>
            </div>
            <div className={style.footbar}>
                <Button block color='primary' onClick={ this.charge }>{this.t('accountCharge.k010')}</Button>
            </div>
        </div>
    }

    componentDidMount() {

        this.setState({
          checkedAgreement: this.globalStore.userInfo.acceptBusinessAgreement ? true : false
        })

        webService.account.getBaseInfo().then(d => {
            
            if (d.data.code === 0 && d.data.data && d.data.data.list && d.data.data.list.length) {
                let current = d.data.data.list.filter(a => a.id === this.accountId);
                if (current && current.length) {
                    this.setState({
                        balance: current[0].balance,
                        frozen: current[0].frozen,
                    })
                }
            }
            
        })

    }

    charge = async () => {
        if (this.state.invitationCode) {
            if (this.state.invitationCode.length < 10) {
                this.setState({
                    showInvitationTip: true
                }, () => {
                    Dialog.alert({
                        content: this.t('accountCharge.k011'),
                        confirmText: this.t('common.k003')
                    })
                })
                
                return;
            }
        }

        let chargeAmount = 0;
        if (this.state.otherAmount) {
            chargeAmount = this.state.otherAmount * 1;
        } else {
            let amounts = this.state.amounts;
            let checkedAmount = amounts.filter(a => a.checked);
            if (checkedAmount && checkedAmount.length) {
                chargeAmount = checkedAmount[0].amount
            }
        }

        if (!chargeAmount) {
            Dialog.alert({
                content: this.t('accountCharge.k012'),
                confirmText: this.t('common.k003')
            })
            return;
        }

        if (!this.state.checkedAgreement) {
            Dialog.alert({
                content: this.t('accountCharge.k013'),
                confirmText: this.t('common.k003')
            })
            return;
        }

        Toast.show({
          icon: "loading",
          content: this.t('accountCharge.k016'),
          maskClickable: false,
          duration: 0
        })

        let url = window.location.href;

        let d = await webService.account.charge({amount: chargeAmount, id: this.accountId, redirectUri: url, paymentType: payType()});
        if (d.data.code === 0) {
            if (oauthType === '11' || oauthType === '1') {
              // 企业微信支付
              ipay('', d.data.data.payUrl);
            } else {
              ipay(d.data.data, '/account');
            }
        }

        Toast.clear()


    }
}

export default withTranslation()(AccountCharge)