import React from 'react'
import style from './approval.scss'
import {inject, observer } from 'mobx-react'

import { UpOutline } from 'antd-mobile-icons'
import { Tabs, Checkbox, Button, InfiniteScroll, Loading, Toast, Dialog, Empty, DatePicker, Popup, Collapse, TextArea, Input } from 'antd-mobile' 

import moment from 'moment'

import ApprovalCell from '../component/approval/approvalcell'

import images from '../util/images'

import webService from '../util/webService'

import { withTranslation } from "react-i18next";

@inject('approvalStore', 'globalStore')
@observer
class Approval extends React.Component {

  state = {
    activeKey: '1',

    isVisible: false, // 拒绝弹窗显隐

    hasMore: true, // 控制滚动加载

    isShow: false, // 控制合计金额详情显隐

    reasonVal: '',  // 拒绝弹窗 value

    isPicker: false, // 日期显隐

    isAllChecked: false,//全选按钮
    
    checkedList: [], // 选择保存数据

    searchPendingApproval: '',

    // 用户及使用场景
    approval: {
      scenes: [],
      users: [],
      totalAmount: ""
    },

    // 入参
    pageDate: {
      pageNum: 1,
      pageSize: 500,
      total: 0,
      approvalData: []
    },

    isData: null
  }

  timeoutId = null; 
  approvalStore = this.props.approvalStore
  globalStore = this.props.globalStore

  t = this.props.t

  /**
   * 滚动文案
   */
   renderScrollContent = () => {
    return (
      <>
        {this.state.hasMore ? (
          <>
            <div className={style.loadingbox}>
              <div className={style.loading}>{this.t('approval.k036')}</div>
              <Loading />
            </div>
          </>
        ) : (
          <div className={style.msg}>--- {this.t('approval.k020')} ---</div>
        )}
      </>
    )
  }

  /**
   *
   * 日期组件
   */
  renderPicker = () => {
    return <div className={style.popPicker}>
      <DatePicker cancelText={this.t('common.k001')} confirmText={this.t('common.k002')} defaultValue={new Date()} visible={this.state.isPicker} onClose={()=> {
        this.setState({
          isPicker: false
        })
      }} precision="month" max={new Date()} min={new Date(moment().add(-4, 'Y'))} onConfirm={val => {
        this.handleResetDate(val)
      }}></DatePicker>
    </div>
  }

  
  /**
   *
   * 合计金额详情弹窗
   */
  renderApprovalDetailsPop = () => {
    return(
      <Popup
        className={style.approvalDetailsPop}
        visible={this.state.isShow}
        getContainer={false}
        bodyClassName={style.popupApproval}
        onMaskClick={() => {
          this.setState({
            isShow: false
          })
        }}
        bodyStyle={{ minHeight: '50vh' }}
      >
      <div style={{ minHeight: '50vh' , overflowY: 'scroll' }}>
        <Collapse>
          <Collapse.Panel key='1' title={this.t('approval.k021')}>
              {
                this.state.approval.scenes.map((item,index) => {
                  return(
                    <div className={style.detail} key={index}>
                      <span>{item.sceneNameCn}</span>
                      <span>{item.amount}{this.t('approval.k008')}</span>
                    </div>
                  )
                })
              }
          </Collapse.Panel>
          <Collapse.Panel key='2' title={this.t('approval.k022')}>
              {
                this.state.approval.users.map((item,index) => {
                  return(
                    <div className={style.detail} key={index}>
                      <span>{item.name}</span>
                      <span>{item.amount}{this.t('approval.k008')}</span>
                    </div>
                  )
                })
              }
          </Collapse.Panel>
        </Collapse>
      </div>
      </Popup>
    )
  }

  /**
   *
   *待审核底部操作
   */
  renderApprovalBar = () => {
    const { isAllChecked } = this.state
    if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.useCarApproval_btn) {
      return(
        <div className={style.bar}>
          <div className={style.left}>
            <Checkbox checked={isAllChecked} onChange={(e) => {
              this.allCheckApproval(e)
            }} style={{'--icon-size': '20px'}} value='apple'>{this.t('approval.k009')}</Checkbox>
          </div>
          <div className={style.right}>
            <Button onClick={this.handleReject}>{this.t('approval.k010')}</Button>
            <Button onClick={() => {this.handleAffirm()}} style={{'--background-color':'#2F7DCD'}} color='primary'>{this.t('approval.k011')}</Button>
          </div>
        </div>
      )
    }
  }

  /**
   *
   *已审核底部操作
   */
  renderAlreadyApprovalBar = () => {
    return(
      <div className={style.alreadyApprovalBar}>
        <div onClick={() => {
          this.setState({
            isPicker: true,
            isShow: false
          })
        }}>
          {this.t('approval.k012')} {this.approvalStore.startTime}&nbsp;&nbsp;<UpOutline color="#C7C7CC"  fontSize={12}/>
        </div>
        <div onClick={() => {
          this.setState({
            isShow: true,
            isPicker: false
          })
        }}>
           {this.t('approval.k013')} {this.state.approval.totalAmount}&nbsp;&nbsp;<UpOutline color="#C7C7CC"  fontSize={12}/>
        </div>
      </div>
    )
  }

  
  /**
   *
   *风险提示
   */
  renderDialog = (val) => {
    return (
      Dialog.show({
        title: this.t('approval.k023'),
        closeOnAction: true,
        content: (
          <>
            <div>
              {val}
            </div>
          </>
        ),
        actions: [
          {
            key: 'cancel',
            text: this.t('approval.k024'),
          }
        ],
      })
    )
  }

  /**
   *
   *审核数据渲染
   */
   renderApprovalCell() {
    return(
      <>
        {
          this.state.pageDate.approvalData.map((item, index) => {
            return(
              <div className={style.cell_approval} key={item.orderId}>
                <ApprovalCell
                  key={item.orderId}
                  index={index}
                  orderId={item.orderId}
                  warning={item.warning}
                  warningRemind={item.warningRemind}
                  amount={String(item.amount)}
                  carSource={item.carSource}
                  checked={item.checked}
                  disabled={item.disabled}
                  cityName={item.cityName}
                  approvalState={item.approvalState}
                  departTime={item.departTime}
                  deparLocation={item.pickupLocationName}
                  destLocation={item.destLocationName}
                  distance={item.distance}
                  userNameCn={item.userNameCn}
                  duration={item.duration}
                  activeKey={this.state.activeKey}
                  isAbnormal={item.isAbnormal}
                  abnormalRules={item.abnormalRules}
                  serviceType={item.serviceType}
                  charteredBusType={item.charteredBusType}
                  // 异常行前审批转行后审批 行前申请单号
                  rulePreDepartApplyId={item.rulePreDepartApplyId}
                  // 施维雅自定义用车字段
                  customLabel={item.customLabel}
                  orderState={item.orderState}
                  abnormalTipFlag={item.abnormalTipFlag}
                  sceneNameCn={item.sceneNameCn}
                  tip={item.tip}
                  t={this.props.t}
                  onClick={(id) => {
                    this.approvalStore.setActiveKey(this.state.activeKey)
                    this.toApprovalDetails(id)
                  }}
                  onChange={(e, id) => {
                    this.addCheck(e, id)
                  }}
                  onClickRisk={(val) => {
                    this.renderDialog(val)
                  }}
                ></ApprovalCell>
              </div>
            )
          })
        }
        <InfiniteScroll loadMore={this.loadApproval} hasMore={this.state.hasMore} threshold={50}>
          {this.renderScrollContent()}
        </InfiniteScroll>
      </>
    )
  }

  /**
   *
   *切换
   */
  renderApprovalTabs = () => {
    return(
      <Tabs activeLineMode='fixed' defaultActiveKey={this.state.activeKey} style={{
        '--fixed-active-line-width': '20px',
      }} onChange={(val) => {
        if (this.state.activeKey === '1') {
          this.setState({
            isAllChecked: false,
            checkedList: [],
          })
          this.approvalStore.setApprovalTime(moment().format('YYYY-MM'))
        } else {
          this.setState({
            isAllChecked: false,
            checkedList: []
          })
          this.approvalStore.setApprovalTime('')
        }
        this.setState({
          activeKey: val,
          searchPendingApproval: '',
          pageDate: {
            ...this.state.pageDate,
            pageNum: 1,
            approvalData: []
          }
        }, () => {
          this.loadApproval()
          this.loadAlreadyApprovalDetails()
        })
        
      }}>
        <Tabs.Tab  title={this.t('approval.k006')} key='1'>
          {
            (() => {
              if(this.state.pageDate.approvalData && this.state.pageDate.approvalData.length > 0) {
                  return(
                    this.renderApprovalCell()
                  )
              }
              if (!this.state.isData && this.state.isData !== null) {
                return(
                  <Empty description={this.t('approval.k025')} image={images.noUnhandle}/>
                )
              }
            })()
          }
        </Tabs.Tab>
        <Tabs.Tab title={this.t('approval.k007')} key='2'>
          <div className={style.search_approval}>
            <img src={images.search_icon} alt=''/>
            <Input
              placeholder='请输入员工姓名'
              value={this.state.searchPendingApproval}
              onChange={val => {
                this.handleChange(val)
              }}
              onBlur={() => {
                this.setState({
                  hasMore: false,
                  isData: false
                })
              }}
            /> 
          </div>
          {
            (() => {
              if(this.state.pageDate.approvalData && this.state.pageDate.approvalData.length > 0) {
                return(
                  this.renderApprovalCell()
                )
              }
              if (!this.state.isData && this.state.isData !== null) {
                return(
                  <Empty description={this.t('approval.k026')} image={images.noUnhandle}/>
                )
              }
            })()
          }
        </Tabs.Tab>
      </Tabs>
    )
  }

  render() {
    return(
      <div className={style.approval}>
        {/* tabs 切换 */}
        {this.renderApprovalTabs()}
        {/* 拒绝通过 bar */}
        {this.state.activeKey === '1' ? this.renderApprovalBar():''}
        {/* 已审批 bar */}
        {this.state.activeKey === '2' ? this.renderAlreadyApprovalBar():''}
        {/* 拒绝弹窗 */}
        {this.state.isVisible ? this.renderReject() : ''}
        {/* 日期弹窗 */}
        {this.renderPicker()}
        {/* 合计金额弹窗 */}
        {this.renderApprovalDetailsPop()}
      </div>
    )
  }

  componentDidMount() {
    this.loadApproval()
  }

  componentWillUnmount() {
    Dialog.clear()
    // 组件卸载时清除 timeout  
    if (this.timeoutId) {  
      clearTimeout(this.timeoutId);  
    }  
  }

  currentHitId = 0;

  /**
   *
   *加载审批数据
   */
   loadApproval = async (val = '') => {
    const tab = this.state.activeKey;

    let _currentHitId = new Date().getTime()
    if (Number(this.state.pageDate.total) !== 0 && Number(this.state.pageDate.total) === this.state.pageDate.approvalData.length && !this.state.searchPendingApproval) {
      this.setState({
        hasMore: false
      })
      return
    }

    this.currentHitId = _currentHitId

    let d = await webService.approval.getApproval({
      type: Number(this.state.activeKey),
      pageNum: this.state.pageDate.pageNum,
      pageSize: this.state.pageDate.pageSize,
      dateDuration: this.approvalStore.startTime,
      beApprovedUserName: val
    });
    if (d.data.code === 0) {
      

      if (this.state.activeKey !== tab) {
        return
      }

      if (this.currentHitId !== _currentHitId) {
        console.log('过期请求', this.currentHitId, _currentHitId)
        return;
      }

      d.data.data.list && d.data.data.list.forEach(item => {
        item.checked = false
      })

      let data = []

      if (d.data.data.list && d.data.data.list.length > 0) {
        data = d.data.data.list
      } else {
        data = []
      }

      this.setState({
        pageDate: {
          ...this.state.pageDate,
          pageNum: !val ? d.data.data.pageNum+1 : 1,
          total: d.data.data.total,
          approvalData: val ? [...data] : [...this.state.pageDate.approvalData, ...data]
        },
        hasMore: val ? false : true
      }, () => {
        if (this.state.pageDate.approvalData && this.state.pageDate.approvalData.length > 0) {
          this.setState({
            isData: true
          })
        } else {
          this.setState({
            isData: false
          })
        }
        if (this.state.checkedList.length !== this.state.pageDate.approvalData.length) {
          this.setState({
            isAllChecked: false
          })
        }
      })
    } else {
      this.setState({
        isData: false
      })
    }
  }

  /**
   *
   *获取合计金额用户详情数据接口
   */
  loadAlreadyApprovalDetails = (val = '') => {
    let dateDuration = this.approvalStore.startTime
    webService.approval.getApprovalUserTotalAmount({dateDuration:dateDuration, beApprovedUserName: val}).then(d => {
      if (d.data.code === 0) {
        this.setState({
          approval: {
            ...this.state.approval,
            scenes: d.data.data ? (d.data.data.sceneDetails ? d.data.data.sceneDetails : []) : [],
            users: d.data.data ? (d.data.data.userDetails ? d.data.data.userDetails : []) : [],
            ...this.state.pageDate,
            totalAmount: d.data.data.totalAmount
          }
        })
      }
    })
  }

  /**
   *
   *审批全选
   */
  allCheckApproval = (e) => {

    if (this.state.pageDate.approvalData.length === 0) return

    const newData = JSON.parse(JSON.stringify(this.state.pageDate.approvalData));

    newData.map((item) => item.checked = e)

    const newCheckedList = newData.map(item => {
      return item.orderId
    })

    this.setState({
      isAllChecked: e,
      checkedList: e ? newCheckedList : [],
      pageDate: {
        ...this.state.pageDate,
        approvalData: newData
      }
    })
  }


  /**
   *
   *审批单选
   */
  addCheck = (e, id) => {
    const newData = JSON.parse(JSON.stringify(this.state.pageDate.approvalData));
    const newCheckedList = JSON.parse(JSON.stringify(this.state.checkedList));

    newData.forEach(item => {
      if (item.orderId === id && e) {
        item.checked = e
        newCheckedList.push(item.orderId)
      }

      if (item.orderId === id && !e) {
        item.checked = e
      }
    })

    newCheckedList.forEach((orderId, index) => {
      if (orderId === id && !e) {
        newCheckedList.splice(index, 1)
      }
    })

    this.setState({
      checkedList: newCheckedList,
      pageDate: {
        ...this.state.pageDate,
        approvalData: newData,
      }
    }, () => {
      if (this.state.pageDate.approvalData.length === this.state.checkedList.length) {
        this.setState({
          isAllChecked: true
        })
      } else {
        this.setState({
          isAllChecked: false
        })
      }
    })
  }

  /**
   *
   *拒绝原因
   */
  setInput = (val) => {
    this.setState({
      reasonVal: val
    })
  }

  /**
   *
   *确认拒绝审批
   */
  handleConfirmReject = () => {
    if (!this.state.reasonVal) {
      Toast.show({
        content: this.t('approval.k033'),
      })
    } else {
      let params = {
        orderIds: this.state.checkedList,
        reason: this.state.reasonVal
      }

      Toast.show({
        icon: "loading",
        content: this.t('approval.k036'),
        maskClickable: false,
        duration: 0
      })

      webService.approval.setApprovalReject(params).then(d => {
        if (d.data.code === 0) {
          this.setState({
            checkedList: [],
            reasonVal: '',
            isVisible: false,
            isAllChecked: false,
            pageDate: {
              ...this.state.pageDate,
              pageNum: 1,
              approvalData: []
            }
          })
          Toast.show({
            icon: 'success',
            content: this.t('approval.k028'),
            duration: 1000
          }, () => {
            Toast.clear()
          })
          this.loadApproval()
        } else {
          Toast.clear()
          Dialog.alert({
            content: this.t('approval.k043'),
            confirmText: this.t('common.k003')
          })
        }
      }) 
    }
  }

  /**
   *
   *取消
   */
  handleCancel = () => {
    this.setState({
      isVisible: false,
      reasonVal: ''
    })
  }

  /**
   *
   *拒绝通过
   */
  handleReject = () => {
    if (this.state.pageDate.approvalData.length === 0) {
      Toast.show({
        content: this.t('approval.k029'),
      })
      return
    } else if (this.state.checkedList.length === 0) {
      Toast.show({
        content: this.t('approval.k030'),
      })
    } else {
      Dialog.confirm({
        getContainer: false,
        bodyClassName: `${style.prompt}`,
        confirmText: this.t('approval.k031'),
        cancelText: this.t('approval.k032'),
        title: this.t('approval.k033'),
        content: (<>
            <div>
              <TextArea placeholder={this.t('approval.k033')} disabled={this.state.isVisible} maxLength={100} rows={3} defaultValue={this.state.reasonVal} 
                  onChange={(val) => {
                    this.setInput(val)
                  }} />
            </div>
        </>),
        onConfirm: ()=> {
            this.handleConfirmReject()
        },
        onCancel: () => {
          this.handleCancel()
        }
      })
    }
  }

  
  /**
   *
   *确认通过审核
   */
  handleAffirm = () => {
    if (this.state.pageDate.approvalData.length === 0) {
      Toast.show({
        content: this.t('approval.k029'),
      })
      return
    } else if (this.state.checkedList.length === 0) {
      Toast.show({
        content: this.t('approval.k030'),
      })
    } else {
      Dialog.confirm({
        content: (
          <>
            <div className={style.through}>{this.t('approval.k034')}</div>
          </>
        ),
        getContainer: false,
        maskClassName: `${style.maskApproval}`,
        cancelText: this.t('common.k001'),
        confirmText: this.t('common.k002'),
        onConfirm: async () => {
          let params = {
            orderIds: this.state.checkedList,
          }

          Toast.show({
            icon: "loading",
            content: this.t('approval.k036'),
            maskClickable: false,
            duration: 0
          })

          let d = await webService.approval.setApprovalVerify(params)
          if (d.data.code === 0) {
            this.setState({
              checkedList: [],
              isAllChecked: false,
              pageDate: {
                ...this.state.pageDate,
                pageNum: 1,
                approvalData: []
              }
            })

            Toast.show({
              icon: 'success',
              content: this.t('approval.k028'),
              duration: 1000
            }, () => {
              Toast.clear()
            })

            this.loadApproval()
          } else {
            Toast.clear()
            Dialog.alert({
              content: this.t('approval.k043'),
              confirmText: this.t('common.k003')
            })
          }
        },
      })
    }
  }

   /**
   *
   *日期选择获取数据
   */
  handleResetDate = (val) => {
    this.setState({
      pageDate: {
        ...this.state.pageDate,
        pageNum: 1,
        approvalData: []
      }
    }, () => {
      let time = moment(val).format('YYYY-MM')
      this.approvalStore.setApprovalTime(time)
      this.loadApproval(this.state.searchPendingApproval)
      this.loadAlreadyApprovalDetails(this.state.searchPendingApproval)
    })
  }

  /**
   *
   *审核详情跳转
   */
  toApprovalDetails(id) {
    this.props.history.push(`/approvalDetails/${id}`);
  }

  handleChange = (val) => {
    this.setState({
      searchPendingApproval: val,
      pageDate: {
        ...this.state.pageDate,
        pageNum: 1
      }
    });

    if (this.timeoutId) {  
      clearTimeout(this.timeoutId);  
    }

    this.timeoutId = setTimeout(() => {  
      this.setState({ 
        searchPendingApproval: val 
      });
      if (val === '') {
        this.setState({
          pageDate: {
          ...this.state.pageDate,
            pageNum: 1,
            approvalData: []
          }
        })
      }
      this.loadApproval(val)
      this.loadAlreadyApprovalDetails(val)
    }, 300); // 300ms 的防抖时间 
  }

}

export default withTranslation()(Approval)