import React from 'react'
import style from './writeInvoice.scss'
import {inject, observer } from 'mobx-react'

import { List, Input, TextArea, CascadePicker, Button, Toast, Popup, Dialog } from 'antd-mobile'
import { RightOutline, CloseOutline, CheckCircleFill, ExclamationCircleFill } from 'antd-mobile-icons'

import { debounce } from '../util/utils'

import webService from '../util/webService'

import { withTranslation } from "react-i18next";

@inject('invoiceStore', 'globalStore')
@observer
class WriteinVoice extends React.Component {

  state = {
    // 控制公司列表弹窗显隐
    isCompany: false,

    // 控制确认开票信息显隐
    isPopup: false,

    // 公司列表数据
    companyList: [],

    // 邮箱
    email: "",
    
    // 备注
    noteValue: "",
    
    // 控制联想列表展示
    isShow: false,

    // 控制税号 input 是否可编辑
    isDisabled: false,
    
    // 提交入参使用
    invoice: {
      // 税号
      companyValue: [],
      companyName: '',
      faxNumber: "",
      addAndTel: '',
      bankAccount: '',
      titleId: null
    },

    // 查询企业信息数据
    companyInvoiceList: []
  }
  
  invoiceStore = this.props.invoiceStore
  globalStore = this.props.globalStore

  t = this.props.t
  
  // 判断是否展示企业默认配置开票底部弹出
  get isInvoiceOpenDefault() {
    return this.state.companyList && this.state.companyList.length > 0 && this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.invoiceOpen_default
  }
  // 判断是否展示企业检索开票
  get isInvoiceOpenSearch() {
    return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.invoiceOpen_search
  }
  // 获取开票记录权限
  get isInvoiceOpenRecord() {
    return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.invoiceOpen_record
  }
  /**
   *
   *公司列表弹窗
   */
  renderCompanyPicker = () => {
    return(
      <CascadePicker 
        options={this.state.companyList} 
        visible={this.state.isCompany}
        defaultValue={this.state.invoice.companyValue}
        onClose={() => {
          this.setState({
            isCompany: false
          })
        }}
        onConfirm={(val) => {
          this.setState({
            isShow: true,
            invoice: {
              ...this.state.invoice,
              companyValue: val[0]
            }
          }, () => {
            this.state.companyList && this.state.companyList.forEach(options => {
              if (options.value === val[0]) {
                this.setState({
                  invoice: {
                    companyName: options.label,
                    faxNumber: options.faxNumber,
                    titleId: options.id,
                    addAndTel: options.addressAndTel ? options.addressAndTel : '',
                    bankAccount: options.bankAndAccount ? options.bankAndAccount : ''
                  }
                })
              }
            })
          })
        }}
      >
      </CascadePicker>
    )
  }

  /**
   *
   *公司信息内容
   */
  renderCompanyMessage = () => {
    return(
      <div className={style.up}>
        <List>
          <List.Item className={style.listCell}>
            <div>{this.t('writeInvoice.k001')}</div>
            <div>{this.t('writeInvoice.k002')}</div>
          </List.Item>
          <List.Item className={`${style.listCell} ${this.isInvoiceOpenDefault ? style.cellDefault:''}`}>
            <div>{this.t('writeInvoice.k003')}</div>
            {
              (() => {
                if (this.state.invoice.companyName && !this.state.isShow) {
                  return(
                    <div onClick={() => {
                      this.setState({
                        isShow: true
                      })
                    }} className={style.companyWrap}></div>
                  )
                }
              })()
            }
            {
              (() => {
                if (this.state.invoice.companyName && !this.state.isShow && this.state.companyInvoiceList && this.state.companyInvoiceList.length) {
                  return (
                    <div className={style.pop}>
                      {
                        this.state.companyInvoiceList.map((item, index) => {
                          return (
                            <div className={style.companyBox} key={index} onClick={() => {
                              this.setState({
                                invoice: {
                                  ...this.state.invoice,
                                  companyName: item.companyName,
                                  faxNumber: item.taxNumber,
                                  addAndTel: item.addAndTel,
                                  bankAccount: item.bankAccount
                                }
                              }, () => {
                                this.setState({
                                  isShow: true,
                                  isDisabled: true
                                })
                              })
                            }}>
                              <p className={style.companyName}>{item.companyName}</p>
                              <span className={style.taxNumber}>{item.taxNumber}</span>
                            </div>
                          )
                        })
                      }
                    </div>
                  )
                }
              })()
            }
            <div className={style.voiceTitle}>
              {
                (() => {
                  if (this.isInvoiceOpenSearch) {
                    return(
                      <Input
                        id='companyName'
                        value={this.state.invoice.companyName}
                        onChange={val => {
                          this.setState({
                            invoice: {
                              ...this.state.invoice,
                              companyName: val
                            }
                          }, () => {
                            if (!val) {
                              this.setState({
                                isShow: false,
                                isDisabled: false,
                                invoice: {
                                  ...this.state.invoice,
                                  faxNumber: '',
                                  bankAccount: '',
                                  addAndTel: ''
                                }
                              })
                            }
                          })
                        }}
                        placeholder={this.t('writeInvoice.k004')}
                        style={{ '--text-align': 'right', '--font-size': '13px'}}
                      />
                    )
                  }
                })()
              }
              {
                (() => {
                  if (this.isInvoiceOpenDefault && !this.isInvoiceOpenSearch) {
                    return <div className={style.defautBox} onClick={()=> {
                        this.setState({
                          isCompany: true
                        })
                      }}>{this.state.invoice.companyName}</div>
                  }
                })()
              }
              
              {
                (() => {
                  if(this.isInvoiceOpenDefault) {
                    return(
                      <RightOutline onClick={()=> {
                        this.setState({
                          isCompany: true
                        })
                      }} color='#cbcbd0'></RightOutline>
                    )
                  }
                })()
              }
            </div>
          </List.Item>
          <List.Item className={style.listCell}>
            <div>{this.t('writeInvoice.k005')}</div>
            {/* <div className={style.grayColor}>{this.state.invoice.faxNumber}</div> */}
            <Input
                className={style.translation}
                value={this.state.invoice.faxNumber}
                disabled={this.state.isDisabled}
                onChange={val => {
                  this.setState({
                    invoice: {
                      ...this.state.invoice,
                      faxNumber: val
                    }
                  })
                }}
                placeholder={this.t('writeInvoice.k006')}
                style={{ '--text-align': 'right', '--font-size': '13px' }}
              />
          </List.Item>
          <List.Item className={style.address}>
            <div>{this.t('writeInvoice.k030')}</div>
            <Input
                className={style.translation}
                value={this.state.invoice.bankAccount}
                onChange={val => {
                  this.setState({
                    invoice: {
                      ...this.state.invoice,
                      bankAccount: val
                    }
                  })
                }}
                placeholder={this.t('writeInvoice.k007')}
                style={{ '--text-align': 'right', '--font-size': '13px' }}
              />
          </List.Item>
          <List.Item className={style.address}>
            <div>{this.t('writeInvoice.k008')}</div>
            <Input
                className={style.translation}
                value={this.state.invoice.addAndTel}
                onChange={val => {
                  this.setState({
                    invoice: {
                      ...this.state.invoice,
                      addAndTel: val
                    }
                  })
                }}
                placeholder={this.t('writeInvoice.k009')}
                style={{ '--text-align': 'right', '--font-size': '13px' }}
              />
          </List.Item>
          <List.Item className={style.listCell}>
            <div>{this.t('writeInvoice.k010')}</div>
            <div className={style.grayColor}>{this.invoiceStore.invoiceAmount}元</div>
          </List.Item>
        </List>
        <List>
          <List.Item className={style.listCell}>
            <div><span>*</span>{this.t('writeInvoice.k011')}</div>
            <div>
              <Input
                value={this.state.email}
                placeholder={this.t('writeInvoice.k012')}
                style={{'--text-align': 'right','--font-size': '13px' }}
                onChange={val => {
                  this.setState({
                    email: val
                  })
                }}
              />
            </div>
          </List.Item>
        </List>
        <List className={style.note}>
          <TextArea
            placeholder={this.t('writeInvoice.k013')}
            style={{'--font-size': '14px' }}
            value={this.state.noteValue}
            onChange={val => {
              this.setState({
                noteValue: val
              })
            }}
          />
        </List>
      </div>
    )
  }

  /**
   * 
   * 确认发票弹窗
   */
  renderCompanyPopup = () => {
    return(
      <Popup
        visible={this.state.isPopup}
        onMaskClick={() => {
        }}
        bodyStyle={{padding: '0 15px 15px 15px' }}
      >
        <div>
          <div className={style.pop}>
            <div className={style.title}>
              <p className={style.confirmTitle}>{this.t('writeInvoice.k014')}</p>
              <CloseOutline onClick={() => {
                this.setState({
                  isPopup: false
                })
              }} fontSize={16}></CloseOutline>
            </div>
            <List>
              <List.Item className={style.popItem}>
                <div>{this.t('writeInvoice.k015')}</div>
                <div>{this.t('writeInvoice.k016')}</div>
              </List.Item>
              <List.Item className={style.popItem}>
                <div>{this.t('writeInvoice.k003')}</div>
                <div>{this.state.invoice.companyName}</div>
              </List.Item>
              <List.Item className={style.popItem}>
                <div>{this.t('writeInvoice.k005')}</div>
                <div>{this.state.invoice.faxNumber}</div>
              </List.Item>
              <List.Item className={style.popItem}>
                <div>{this.t('writeInvoice.k011')}</div>
                <div>{this.state.email}</div>
              </List.Item>
              <Button onClick={() => {
                this.sendInvoice()
              }} block color='primary' size='large'>
                {this.t('writeInvoice.k018')}
              </Button>
            </List>
          </div>
        </div>
      </Popup>
    )
  }

  render() {
    return(
      <div className={style.write}>
        {/* 公司信息内容 */}
        {this.renderCompanyMessage()}
        <Button onClick={() => {
          this.handleSubmit()
        }} block color='primary' size='large'>
          {this.t('writeInvoice.k019')}
        </Button>
        {/* 公司列表弹窗 */}
        {this.renderCompanyPicker()}
        {this.renderCompanyPopup()}
      </div>
    )
  }

  async componentDidMount() {
    await this.loadCompanyList()

    if(this.isInvoiceOpenRecord) {
      await this.getLastInvoiceApplyInfo()
    }
    if (this.isInvoiceOpenSearch) {
      document.getElementById("companyName").addEventListener("input", debounce(() => {
        if (this.state.invoice.companyName.length >= 2) {
          webService.user.getInvoiceTitle({companyName: this.state.invoice.companyName}).then(d => {
            if(d.data.code === 0 && d.data.data.length > 0) {
              this.setState({
                companyInvoiceList: d.data.data,
                isShow: false
              })
            }
          })
        }
      }, 500))
    }
  }

  componentWillUnmount() {
    Dialog.clear()
    this.invoiceStore.setInvoiceList([])
    this.invoiceStore.setInvoiceAmount('0.00')
  }

  /**
   * 公司名称列表
   */
  loadCompanyList = async () => {
    let {data} = await webService.invoice.getTitle();
    if (data.code === 0 && data.data && data.data.length > 0) {
      data.data.forEach(item => {
        item.label = item.name
        item.value = item.id
      })
      this.setState({
        companyList: data.data,
        invoice: {
          ...this.state.invoice,
          companyValue: data.data[0] && data.data[0].value,
        }
      })
    }
  }

  /**
   *
   * 开票提交
   */
  sendInvoice = () => {

    let parmas = {
        orders: this.invoiceStore.invoiceList,
        totalAmount: this.invoiceStore.invoiceAmount,
        titleId: this.state.invoice.titleId,
        email: this.state.email,
        remarks: this.state.noteValue,
        companyName: this.state.invoice.companyName,
        faxNumber: this.state.invoice.faxNumber,
        addAndTel: this.state.invoice.addAndTel,
        bankAccount: this.state.invoice.bankAccount
    }

    Toast.show({
      icon: "loading",
      content: this.t('writeInvoice.k020'),
      maskClickable: false,
      duration: 0
    })

    webService.invoice.sendWriteInvoice(parmas).then(d => {
      if (d.data.code === 0) {
        this.setState({
          isPopup: false
        })
        Dialog.alert({
          header: (
            <div className={style.prompt}>
              <p>{this.t('writeInvoice.k021')}</p>
              <CheckCircleFill
                style={{
                  fontSize: 64,
                  color: "#07c160",
                }}
              />
            </div>
          ),
          confirmText: this.t('common.k003'),
          getContainer: false,
          maskClassName: `${style.prompt}`,
          onConfirm: () => {
            this.props.history.go(-1)
          },
          content: (this.t('writeInvoice.k022')),
        })
      } else {
        this.setState({
          isPopup: false
        })
        Dialog.alert({
          header: (
            <div className={style.prompt}>
              <p>开具失败</p>
              <ExclamationCircleFill
                style={{
                  fontSize: 64,
                  color: "#fa5151",
                }}
              />
            </div>
          ),
          getContainer: false,
          maskClassName: `${style.prompt}`,
          closeOnAction: true,
          actions: [
            {
              key: 'cancel',
              text: this.t('writeInvoice.k024'),
            }
          ],
          content: (this.t('writeInvoice.k025')),
        })
      }
      Toast.clear()
    }).catch(e => {
      Toast.clear()
      Toast.show({
        content: this.t('writeInvoice.k026'),
      })
    })

  }

  /**
   * 
   * 提交发票 check
   */
  handleSubmit = () => {
    // eslint-disable-next-line
    let RegEmail = /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/ //邮箱格式
    if (!this.state.invoice.companyName) {
      Toast.show({
        content: this.t('writeInvoice.k027'),
      })
    } else if(!this.state.email) {
      Toast.show({
        content: this.t('writeInvoice.k028'),
      })
    } else if(!RegEmail.test(this.state.email)) {
      Toast.show({
        content: this.t('writeInvoice.k029'),
      })
    } else {
      this.setState({
        isPopup: true
      })
    }
  }

  getLastInvoiceApplyInfo = async () => {
    let { data } = await webService.invoice.getLastInvoiceApplyInfo();
    if (data.code === 0 && data.data) {
      this.state.companyList && this.state.companyList.forEach((item, index) => {
        if (item.id === data.data.titleId) {
          this.setState({
            invoice: {
              ...this.state.invoice,
              companyName: item.name,
              faxNumber: item.faxNumber,
              addAndTel: item.addressAndTel,
              bankAccount: item.bankAndAccount,
              titleId: item.id
            },
            email: data.data.email
          })
        }
      })
    }
  }
}

export default withTranslation()(WriteinVoice);