import React from 'react';
import { inject, observer } from 'mobx-react'
import style from './callcar.scss'

import { serviceStateToCn } from './../../util/paramsHelper'

@inject('mainStore', 'globalStore')
@observer
class ServerType extends React.Component {

    mainStore = this.props.mainStore;
    globalStore = this.props.globalStore;

    t = this.props.t

    get serverTypes() {
        let sceneServerTypes = this.mainStore.scene ? this.mainStore.scene.serverTypes : []
        if (sceneServerTypes && sceneServerTypes.length) {
            return this.globalStore.companyInfo.serverTypes.filter(a => sceneServerTypes.includes(a))
        } else {
            return this.globalStore.companyInfo.serverTypes
        }
    }

    render() {
        return <div className={style.head}>
            <div className={`${style.tabs}`}>
                {
                    this.serverTypes.map(item => {
                        return <div key={item} className={this.mainStore.serverType === item ? `${style.tab}  ${style.active}` : `${style.tab}`}
                            onClick={() => {
                                this.mainStore.chooseServerType(item);
                                this.props.changeServerType(item)
                            }}
                        >
                            {serviceStateToCn(item, this.t)}
                            <div className={style.underline}></div>
                        </div>
                    })
                }
            </div>
        </div>
    }

}

export default ServerType