import React from 'react'
import style from './coupons.scss';
import { Tabs } from 'antd-mobile';

import Coupon from './../component/callcar/coupon'
import webService from '../util/webService';
import images from '../util/images';

import { withTranslation } from "react-i18next";

class Coupons extends React.Component {

    state = {
        currentState: 1,
        sceneGroup: [
            {
                id: 1,
                title: this.props.t('coupons.k001'),
                count: 0,
                key: 'noused'
            },
            {
                id: 3,
                title: this.props.t('coupons.k002'),
                count: 0,
                key: 'used'
            },
            {
                id: 4,
                title: this.props.t('coupons.k003'),
                count: 0,
                key: 'timeout'
            }
        ],
        scenes: [

        ]
    }

    t = this.props.t

    render() {
        return <div className={style.container}>
            <Tabs onChange={(key) => {
                let id = this.state.sceneGroup.filter(a => a.key === key)[0].id;
                this.setState({
                    currentState: id
                })
            }}>
                {
                    this.state.sceneGroup.map(item => {
                        return <Tabs.Tab title={ item.count > 0 ? `${item.title}(${item.count})` : `${item.title}`} key={item.key} />
                    })
                }
                
            </Tabs>
            <div className={style.body}>
                {
                    (() => {
                        let list = this.state.scenes.filter(a => a.state === this.state.currentState);
                        if (list && list.length) {
                            return list.map(item => {
                                return <Coupon history={this.props.history} t={this.props.t} key={item.id} data={item} onClick={() => {}} />
                            })
                        } else {
                            return <div className={style.noneCoupon}>
                                <div>
                                    <img src={images.noneCoupon} alt={this.t('coupons.k004')} />
                                </div>
                                <div>{this.t('coupons.k004')}</div>
                            </div>
                        }
                    })()
                }
            </div>
        </div>
    }

    componentDidMount() {
        webService.user.getCouponList().then(d => {
            if (d.data.code === 0) {
                let scenes = d.data.data.rows;
                for (let i in scenes) {
                    let item = scenes[i];
                    if (item.state === 2) {
                        item.state = 1;
                        item.isValid = true;
                        item.isFrozen = true;
                    }
                }
                let sceneGroup = this.state.sceneGroup;
                sceneGroup[0].count = scenes.filter(a => a.state === 1).length;

                this.setState({
                    sceneGroup: sceneGroup,
                    scenes: scenes
                })
            }
        });
    }
}
 export default withTranslation()(Coupons);