import React from 'react';
import style from './searchResult.scss';

class SearchResult extends React.Component {
    state = {
        data: this.props.data,
        cityName: this.props.cityName,
        cityCount: this.props.cityCount,
        showAll: false
    }

    otherCityShowLength = 3

    t = this.props.t

    get showCityName() {
        // 查询结果只有一个分组，且该分组就是当前所在城市，不显示城市名，否则显示城市名
        return !(this.state.cityCount === 1 && this.state.cityName === this.state.data.city);
    }

    get isCurrentCity() {
        return this.state.cityName === this.state.data.city
    }

    get moreItemLength() {
        return this.state.data.items.length - this.items.length;
    }

    get items() {
        // 当前城市不限量
        if (this.state.data && this.state.data.items && this.state.data.items.length) {
            if (this.isCurrentCity || this.state.showAll) {
                return this.state.data.items;
            } else {
                return this.state.data.items.slice(0, this.otherCityShowLength);
            }
        } else {
            return []
        }
        
    }

    render() {
        return <div className={style.container}>
            {
                this.showCityName ?    
                    <div className={style.cityName}>{ this.state.data.city}</div> : <></>
            }
            <div className={style.ss}>
                {
                    this.items.map((item, index) => {
                        return (
                            <div className={style.city} onClick={(e) => {
                                e.stopPropagation();
                                this.props.onClick(item)
                                // this.props.onChange({
                                //     isBegin: this.state.isBegin,
                                //     cityCode: this.state.cityCode,
                                //     cityName: this.state.cityName,
                                //     name: item.name,
                                //     address: item.address,
                                //     lon: item.location.lng,
                                //     lat: item.location.lat,
                                //     poi: item.poi
                                // });

                            }} key={index}>
                                {item.name}
                                <div className={style.tip}>{item.address}</div>
                                {this.renderAddrSearchResDoor(item)}
                            </div>
                        )
                    })
                }
            </div>
            {
                this.moreItemLength > 0 ?
                    <div className={style.morebutton} onClick={() => {
                        this.setState({
                            showAll: true
                        })
                    }}>
                    {/* 展示其余{}条结果 */}
                    {this.t('main.k0154', {length:this.state.data.items.length - this.items.length})}
                    </div> : <></>
            }
        </div>


    }

    /**
     * 搜索结果乘降点
     * @param {*} item 
     * @returns 
     */
    renderAddrSearchResDoor = (item) => {
        if (item.children && item.children.length) {
            return (
                <div className={style.doors}>
                    {
                        item.children.map((door, index) => {
                            return (
                                <div className={style.door} onClick={(e) => {
                                    e.stopPropagation();
                                    this.props.onClick(door)
                                    // this.props.onChange({
                                    //     isBegin: this.state.isBegin,
                                    //     cityCode: this.state.cityCode,
                                    //     cityName: this.state.cityName,
                                    //     name: door.name,
                                    //     address: door.address,
                                    //     lon: door.location.lng,
                                    //     lat: door.location.lat,
                                    //     poi: door.poi
                                    // })
                                }} key={index}>
                                    <div>{door.show_name}</div>
                                </div>
                            )
                        })
                    }

                </div>
            )
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            data: nextProps.data,
            cityName: nextProps.cityName,
            cityCount: nextProps.cityCount
        })
    }
}

export default SearchResult



