import React from 'react';
import style from './anjinmetting.scss'

import { SearchBar } from 'antd-mobile'

class AnjinMetting extends React.Component {
    state = {
        keyword: '',
        placeholder: this.props.placeholder
    }

    t = this.props.t;
    searchRef = undefined;
    option = this.props.options;
    

    get sences() {
        if (this.option && this.option.length) {
            if (this.state.keyword) {
                return this.option.filter(a => (a.label && a.label.indexOf(this.state.keyword) !== -1) || (a.code && a.code.indexOf(this.state.keyword) !== -1));
            } else {
                return this.option;
            }
        }
        return [];
    }

    render() {
        return <div className={style.containerMetting}>
            <div className={style.searchBar}>
                <SearchBar ref={(dom) => {this.searchRef = dom;}} placeholder={this.state.placeholder}
                    style={{ '--background': '#ffffff', '--font-size': '13px' }}
                    showCancelButton={() => true}
                    onChange={(e) => {
                        this.setState({
                            keyword: e
                        });
                    }}
                    
                    onCancel={() => {
                        this.props.history.goBack();
                    }}
                />
            </div>
            <div className={style.renderSearchRes}>
            {
                this.sences.map((item) => {
                    return <div className={style.searchRes} key={item.value} onClick={() => {
                        this.props.onChecked({...item});
                    }}>
                        <div>{ item.label }</div>
                        <div>{ item.code }</div>
                    </div>
                })
            }
        </div>
        </div>
    }

}

export default AnjinMetting
