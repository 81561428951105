import en from "./en.js";
import zh from "./zh.js";

export const resources = {
	"en": {
		translation: en
	},
	"zh": {
		translation: zh
	}
}