import React from 'react';
import style from './santencostcenterAdd.scss'

import { SearchBar, Button, Dialog } from 'antd-mobile'

import webService from "../../util/webService";

class SantenCostCenterAdd extends React.Component {

    state = {
        project: {},
        showSearchRes: false,
        searchRes: []
    }

    keyword = '';
    t = this.props.t;
    searchRef = undefined;

    renderSearchRes = () => {
        return <div className={style.renderSearchRes}>
            {
                this.state.searchRes.map((item) => {
                    return <div className={style.searchRes} key={item.id} onClick={() => {
                        this.setState({
                            project: { ...item },
                            showSearchRes: false,
                            searchRes: []
                        })
                        
                        if (this.searchRef) {
                            this.searchRef.clear()
                        }
                        
                    }}>
                        <div>{ item.name }</div>
                        <div>{ item.code }</div>
                    </div>
                })
            }
        </div>
    }

    render() {
        return <div className={style.containerAdd}>
            <div className={style.searchBar}>
                {/* 请输入成本中心编号或名称 */}
                <SearchBar ref={(dom) => {this.searchRef = dom;}} placeholder={this.t('special.k001')}
                    style={{ '--background': '#ffffff', '--font-size': '13px' }}
                    showCancelButton={() => true}
                    onChange={(e) => {
                        this.keyword = e;
                        this.getCompanyProjects(e)
                    }}
                    onFocus={() => {
                        this.setState({
                            showSearchRes: true
                        })
                    }}
                    onCancel={() => {
                        if (this.state.showSearchRes) {
                            this.setState({
                                showSearchRes: false,
                                searchRes: []
                            })
                        } else {
                            this.props.onClose()
                        }
                        
                    }}
                />
            </div>
            {
                this.state.showSearchRes ? this.renderSearchRes() : <></>
            }
            <div className={style.project}>
                {/* 名称： */}
                {this.t('special.k003')}<span>{this.state.project.name}</span>
            </div>

            <div className={style.btn}>
                {/* 提交 */}
                <Button block color='primary' onClick={() => { this.onSubmit() }} disabled={!this.state.project.id}>{this.t('special.k004')}</Button>
            </div>
        </div>
    }

    async componentDidMount() {


    }

    getCompanyProjects = async (keyword) => {
        if (!keyword) {
            this.setState({
                searchRes: []
            })
            return
        }

        let { data } = await webService.special.costCenter.getCompanyProjects(keyword)
        if (data.code) {
            return;
        }

        this.setState({
            searchRes: [...data.data]
        })
        
        
    }

    onSubmit = async () => {

        if (!this.state.project.id) {
            return
        }

        const result = await Dialog.confirm({
            // 确认加入该成本中心?
            content: this.t('special.k002'),
        })
        if (result) {

            let { data } = await webService.special.costCenter.addUserProject(this.state.project.id)
            if (data.code) {
                await Dialog.alert({
                    content: data.message,
                })
                return
            }

            this.props.onClose()
            
        }
    }

}

export default SantenCostCenterAdd