import React from 'react'
import style from './settings.scss'
import { inject, observer } from 'mobx-react'

import { List, Button, Toast, CascadePicker, Dialog } from 'antd-mobile'
import { RightOutline } from 'antd-mobile-icons'

import webService from '../util/webService'

import i18n from 'i18next'

import { withTranslation } from "react-i18next";

@inject('settingsStore', 'globalStore')
@observer
class Settings extends React.Component {

  state = {
    // 用车模式数据
    basicColumns: [],

    // 用车默认选中值
    useCarMode: [],

    // 用车模式文本
    useCarModeText: "",

    // 语言
    languageColumns: [
      {
        value: 'zh',
        label: this.props.t('settings.k011')
      },
      {
        value: 'en',
        label: this.props.t('settings.k015')
      }
    ],

    // 语言类型
    languageMode: [],

    // 语言
    languageText: "",

    // 用户信息
    userInfo: {
      // 用车人
      userName: "",
      // 审批人
      lineManagerNameCn: "",
      // 用户手机
      phone: "",
      // 紧急联系人
      emergencyPhone: "",
      // 是否导入通讯录
      // isUserAddressBook: "",
    },
    showVisible: false,
    showLanguageVisible: false
  }

  settingsStore = this.props.settingsStore
  globalStore = this.props.globalStore

  t = this.props.t

  // // 用户手机号读写
  // get isReadWrite() {
  //   if (this.globalStore.userInfo.permissions) {
  //     if (this.globalStore.userInfo.permissions.setting_readPhone && this.globalStore.userInfo.permissions.setting_readWritePhone) {
  //       return true
  //     } else if (this.globalStore.userInfo.permissions.setting_readPhone) {
  //       return false
  //     }
  //   }
  //   return true
  // }

  /**
   *
   *用车人&审批人
   */
  renderUseCarApproval = () => {
    return (
      (() => {
        if (this.globalStore.userInfo.permissions) {
          if (this.globalStore.userInfo.permissions.setting_useCarPeople || this.globalStore.userInfo.permissions.setting_lineManager) {
            return (
              <List className={style.list}>
                {/* 用车人 */}
                {
                  (() => {
                    if (this.globalStore.userInfo.permissions.setting_useCarPeople) {
                      return (
                        <List.Item className={style.listCell}>
                          <div>{this.t('settings.k001')}</div>
                          <div>{this.state.userInfo.userName}</div>
                        </List.Item>
                      )
                    }
                  })()
                }
                {/* 审批人 */}
                {
                  (() => {
                    if (this.globalStore.userInfo.permissions.setting_lineManager) {
                      return (
                        <List.Item className={style.listCell}>
                          <div>{this.t('settings.k002')}</div>
                          <div>{this.state.userInfo.lineManagerNameCn}</div>
                        </List.Item>
                      )
                    }
                  })()
                }
              </List>
            )
          } else {
            return ''
          }
        }
      })()
    )
  }

  /**
   *
   *手机号&用车模式
   */
  renderPhoneAnduseCarMode = () => {
    return (
      (() => {
        if (this.globalStore.userInfo.permissions) {
          if (this.globalStore.userInfo.permissions.setting_phone || this.globalStore.userInfo.permissions.setting_useCarMode) {
            return (
              <List className={style.list}>
                {/* 手机号 */}
                {
                  (() => {
                    if (this.globalStore.userInfo.permissions.setting_phone) {
                      return (
                        <List.Item className={style.phone}>
                          <div>{this.t('settings.k003')}</div>
                          {/* 手机号 */}
                          {
                            (() => {
                              if (this.state.userInfo.phone) {
                                return (
                                  <div
                                    onClick={() => {
                                      if (this.globalStore.userInfo.permissions.setting_editPhone) {
                                        this.props.history.push('/setphone')
                                      } else {
                                        return
                                      }
                                    }}
                                  >{this.state.userInfo.phone}</div>
                                )
                              } else {
                                return (
                                  <div className={style.input} onClick={() => {
                                    this.props.history.push('/setphone')
                                  }}>
                                    <span>{this.t('settings.k016')}</span>
                                    <RightOutline color='#cbcbd0'></RightOutline>
                                  </div>
                                )
                              }
                            })()
                          }
                        </List.Item>
                      )
                    }
                  })()
                }
                {/* 用车模式 */}
                {
                  (() => {
                    if (this.globalStore.userInfo.permissions.setting_useCarMode) {
                      return (
                        <List.Item className={style.model}>
                          <div>{this.t('settings.k005')}</div>
                          <div className={style.box}
                            onClick={() => {
                              if (this.state.basicColumns.length > 1) {
                                this.setState({
                                  showVisible: true
                                })

                              }
                            }}
                          >
                            <div>{this.state.useCarMode[0] === 1 ? this.t('settings.k009') : this.t('settings.k010')}</div>
                            {
                              (() => {
                                if (this.state.basicColumns.length > 1) {
                                  return (<RightOutline color='#cbcbd0'></RightOutline>)
                                }
                              })()
                            }
                          </div>
                          <CascadePicker
                            options={this.state.basicColumns}
                            visible={this.state.showVisible}
                            value={this.state.useCarMode}
                            onClose={() => {
                              this.setState({
                                showVisible: false
                              })
                            }}
                            onConfirm={value => {
                              if (value) {
                                this.state.basicColumns.forEach(item => {
                                  if (value[0] === item.value) {
                                    this.setState({
                                      useCarModeText: item.label,
                                      useCarMode: [item.value]
                                    })
                                  }
                                })
                              }
                            }}

                          />
                        </List.Item>
                      )
                    }
                  })()
                }
              </List>
            )
          } else {
            return ''
          }
        }
      })()
    )
  }

  /**
   *
   *紧急联系人
   */
  renderurgentPhone = () => {
    return (
      (() => {
        if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.setting_emergencyPeople) {
          return (
            <List className={style.list}>
              <List.Item className={style.emergency}>
                <div>{this.t('settings.k006')}</div>
                <div className={style.box} onClick={() => {
                  if (this.state.userInfo.phone) {
                    this.props.history.push('/contact')
                  } else {
                    Toast.show({
                      content: this.t('settings.k017'),
                      duration: 1000
                    })
                  }
                }}>
                  <div>
                    {
                      (() => {
                        if (this.state.userInfo.emergencyPhone) {
                          return this.state.userInfo.emergencyPhone
                        } else {
                          return this.t('settings.k004')
                        }
                      })()
                    }
                  </div>
                  <RightOutline color='#cbcbd0'></RightOutline>
                </div>
              </List.Item>
            </List>
          )
        }
      })()

    )
  }

  /**
   *
   *滴滴录音录像权限
   */
  renderRecordVideoPermissions = () => {
    return (
      <List className={style.list}>
        <List.Item className={style.emergency}>
          <div>{this.t('settings.k026')}</div>
          <RightOutline fontSize={13} color='#cbcbd0'></RightOutline>
        </List.Item>
      </List>
    )
  }

  /**
   *
   *语言设置
   */
  renderLanguage = () => {
    return (
      (() => {
        if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.setting_language) {
          return (
            <List className={style.list}>
              <List.Item className={style.emergency}>
                <div>{this.t('settings.k007')}</div>
                <div className={style.box} onClick={() => {
                  this.setState({
                    showLanguageVisible: true
                  })
                }}>
                  <div>
                    {this.state.languageMode[0] === 'zh' ? this.t('settings.k011') : this.t('settings.k015')}
                  </div>
                  <RightOutline fontSize={13} color='#cbcbd0'></RightOutline>
                </div>
                <CascadePicker
                  options={this.state.languageColumns}
                  visible={this.state.showLanguageVisible}
                  value={this.state.languageMode}
                  cancelText={this.t('common.k001')}
                  confirmText={this.t('common.k002')}
                  onClose={() => {
                    this.setState({
                      showLanguageVisible: false
                    })
                  }}
                  onConfirm={value => {
                    if (value) {
                      this.state.languageColumns.forEach(item => {
                        if (value[0] === item.value) {
                          this.setState({
                            languageText: item.label,
                            languageMode: [item.value]
                          })
                          // i18n.changeLanguage(item.value)
                        }
                      })
                    }
                  }}

                />
              </List.Item>
              {
                this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.setting_uploadAddressBook
                  ? <List.Item className={`${style.userAddressBook} ${style.addressBook}`}>
                    {/* 导入通讯录 */}
                    <div>{this.t('settings.k020')}</div>
                    <div
                      className={style.box}>
                      <div>
                        {
                          <>
                            <form
                              className={style.uploadForm}
                              enctype="multipart/form-data"
                            >
                              <label>
                                <input
                                  className={style.uploadInput}
                                  type="file"
                                  name="fileInput"
                                  accept="text/x-vcard"
                                  capture={false}
                                  onChange={this.handleChangeFile.bind(this)}
                                ></input>
                              </label>
                            </form>
                            {/* 已导入 未导入 */}
                            {/* <span className={style.uploadText}> {this.state.userInfo.isUserAddressBook ? '' : this.t('settings.k022')}</span> */}
                          </>
                        }
                      </div>
                      <RightOutline color="#cbcbd0"></RightOutline>
                    </div>
                  </List.Item>
                  : <></>
              }

            </List>
          )
        }
      })()
    )
  }

  /**
   * 
   * 常用地址
   */
  renderAddress = () => {
    return (
      (() => {
        if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.setting_commonAddress) {
          return (
            <List className={style.list}>
              <List.Item className={style.listCell}>
                <div>{this.t('settings.k018')}</div>
                <div>
                  <div className={style.address} onClick={() => this.props.history.push('/habitAddress')}>{this.t('settings.k019')}</div>
                  <RightOutline color='#cbcbd0'></RightOutline>
                </div>
              </List.Item>
            </List>
          )
        }
      })()
    )
  }

  /**
   *
   *渲染
   */
  render() {
    return (
      <div className={style.settings}>
        {/* 用车人&审批人 */}
        {this.renderUseCarApproval()}
        {/* 手机号&用车模式 */}
        {this.renderPhoneAnduseCarMode()}
        {/* 紧急联系人 */}
        {this.renderurgentPhone()}
        {/* 滴滴录音录像权限 */}
        {/* {this.renderRecordVideoPermissions()} */}
        {/* 语言设置 */}
        {this.renderLanguage()}
        {this.renderAddress()}
        {
          (() => {
            if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.setting_save) {
              return (
                <Button style={
                  {
                    'background-image': 'var(--button_background_color)',
                    '--border-color': 'var(--button_border_color)',
                    'background-color': 'var(--button_border_color)',
                    'color': 'var(--button_color)'
                  }
                } onClick={() => {
                  this.handleSubmit()
                }} block color='primary' size='large'>
                  {this.t('settings.k008')}
                </Button>
              )
            }
          })()
        }
      </div>
    )
  }

  componentDidMount() {
    webService.user.getUserInfo().then(d => {
      if (d.data && d.data.code === 0) {
        let companyCfg = []
        if (d.data.data.companyCfg && d.data.data.companyCfg.length > 0) {
          companyCfg = JSON.parse(d.data.data.companyCfg[0].paraValue)
        }
        let chooseList = [];
        if (!companyCfg[0] && d.data.data.useCarMode === 2) {
          chooseList.push({ value: 2, label: this.t('settings.k010') })
        } else {
          if (companyCfg.indexOf('3') !== -1) {
            chooseList.push({ value: 2, label: this.t('settings.k010') }, { value: 1, label: this.t('settings.k009') })
          } else if (companyCfg.indexOf('1') !== -1 && companyCfg.indexOf('2') !== -1) {
            chooseList.push({ value: 2, label: this.t('settings.k010') }, { value: 1, label: this.t('settings.k009') })
          } else if (companyCfg.indexOf('1') !== -1) {
            chooseList.push({ value: 1, label: this.t('settings.k009') })
          } else if (companyCfg.indexOf('2') !== -1) {
            chooseList.push({ value: 1, label: this.t('settings.k010') })
          }
        }
        // if (this.globalStore.userInfo.permissions) {
        //   if (this.globalStore.userInfo.permissions.setting_useCarMode_smart) {
        //     chooseList.push({value:1, label: "智能模式"})
        //     this.setState({
        //       ...this.state.basicColumns,
        //       basicColumns: chooseList
        //     })
        //   }
        //   if (this.globalStore.userInfo.permissions.setting_useCarMode_optional) {
        //     chooseList.push({value:2, label: "自选模式"})
        //     this.setState({
        //       ...this.state.basicColumns,
        //       basicColumns: chooseList
        //     })
        //   }
        //   if (!this.globalStore.userInfo.permissions.setting_useCarMode_smart && !this.globalStore.userInfo.permissions.setting_useCarMode_optional) {
        //     chooseList.push({value:2, label: "自选模式"})
        //     this.setState({
        //       ...this.state.basicColumns,
        //       basicColumns: chooseList
        //     })
        //   }
        // }
        this.setState({
          basicColumns: chooseList,
          useCarModeText: d.data.data.useCarMode,
          useCarMode: [d.data.data.useCarMode],
          languageText: d.data.data.language,
          languageMode: [d.data.data.language === 1 || !d.data.data.language ? 'zh' : 'en'],
          userInfo: {
            ...this.state.userInfo,
            userName: d.data.data.nameCn,
            lineManagerNameCn: d.data.data.lineManagerNameCn,
            phone: d.data.data.phone ? d.data.data.phone : this.settingsStore.userPhone,
            emergencyPhone: d.data.data.emergencyPhone ? d.data.data.emergencyPhone : this.settingsStore.emergencyPhone,
            // isUserAddressBook: d.data.data.isUserAddressBook,
          }
        }, () => {
          if (d.data.data.language === 1) {
            i18n.changeLanguage('zh')
          } else if (d.data.data.language === 2) {
            i18n.changeLanguage('en')
          }

        })
        this.settingsStore.setUserPhone(d.data.data.phone)
      }
    })
  }

  componentWillUnmount() {
    this.setState({
      showVisible: false,
      showLanguageVisible: false
    })
  }

  /**
   *
   *用户信息接口
   */
  getUserInfo = async () => {

    let d = await webService.basCity.getUserInfo();

    let { data } = d;

    if (data.code === 0) {

      this.globalStore.setUserInfo(data.data);
      if (data.data.language === 1) {
        i18n.changeLanguage('zh')
      } else if (data.data.language === 2) {
        i18n.changeLanguage('en')
      }

      setTimeout(() => {
        this.props.history.go(-1)
      }, 1000)
    }
  }

  /**
   *
   *提交用户设置
   */
  handleSubmit = () => {
    if (!this.state.userInfo.phone) {
      Toast.show({
        content: this.t('settings.k012'),
      })
    } else {

      let language = undefined

      if (this.state.languageMode[0] === 'zh') {
        language = 1
      } else if (this.state.languageMode[0] === 'en') {
        language = 2
      } else {
        language = 1
      }

      let params = {
        useCarMode: this.state.useCarMode[0],
        language: language
      }

      Toast.show({
        icon: "loading",
        content: this.t('settings.k013'),
        maskClickable: false,
        duration: 0
      })

      webService.user.saveUserCenter(params).then(d => {
        if (d.data.code === 0) {
          Toast.show({
            icon: 'success',
            content: this.t('settings.k014'),
            duration: 500
          }, () => {
            Toast.clear()
          })
          this.getUserInfo()
        }
      })
    }
  }

  /**
 *
 *上传通讯录
 */
  handleChangeFile = async (event) => {
    const file = event.target.files[0];
    const fileArr = file.name.split('.');
    if (fileArr[fileArr.length - 1] !== 'vcf') {
      // 请导入通讯录类型文件
      Toast.show({
        content: this.t('settings.k023'),
        duration: 1000,
      });
      return
    }
    let formData = new FormData();
    formData.append("file", file);

    try {
      let res = await webService.user.uploadVcfFile(formData);
      // this.setState({
      //   userInfo: {
      //     ...this.state.userInfo,
      //     isUserAddressBook: true,
      //   }
      // })
      if (res.data.code === 0) {
        // 导入成功
        Toast.show({
          icon: 'success',
          content: this.t('settings.k024'),
          duration: 500,
        });
      } else {
        Dialog.alert({
          content: res.data.message,
          confirmText: this.t('common.k003'),
        })
      }
    } catch (exp) {
      console.log('通信录文件上传失败', exp)
      // 导入失败
      Toast.show({
        content: this.t('settings.k025'),
        duration: 1000,
      });
    }


    // webService.user.uploadVcfFile(formData).then(
    //   (d) => {
    //     this.setState({
    //       userInfo: {
    //         ...this.state.userInfo,
    //         isUserAddressBook: true,
    //       }
    //     })
    //     Toast.show({
    //       icon: 'success',
    //       content: "导入成功",
    //       duration: 500,
    //     });
    //   },
    //   (error) => {
    //     Toast.show({
    //       content: "导入失败",
    //       duration: 1000,
    //     });
    //   }
    // );
  };
}

export default withTranslation()(Settings);