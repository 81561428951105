
const BASE_CITYS = 'BASE_CITYS';

let setBaseCitys = (citys) => {
    localStorage.setItem(BASE_CITYS, JSON.stringify(citys));
}

let getBaseCitys = () => {
    let baseCitys = localStorage.getItem(BASE_CITYS);
    if (baseCitys) {
        let _baseCitys = JSON.parse(baseCitys);
        if (_baseCitys && typeof(_baseCitys) === 'object') {
            return _baseCitys;
        }
    }
    return undefined;
}



export {
    setBaseCitys,
    getBaseCitys
}