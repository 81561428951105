import React from 'react';
// import moment from 'moment'
import style from './scene.scss'
import {Checkbox} from 'antd-mobile'
// import images from './../../util/images'
import { RightOutline } from 'antd-mobile-icons'

class Scene extends React.Component {
    state = {
        hide: true,
    }

    t = this.props.t

    render() {
        return <div className={style.scene} >
            <div className={style.name} onClick={
                () => {
                    this.props.onClick()
                } 
            }><span>{this.props.scene.nameCn}</span>
            {
                this.props.showCheckBox?
                <div>
                  <Checkbox checked={this.props.isChecked} onChange={(e) => {
                 // this.allCheckApproval(e)
               }} style={{'--icon-size': '20px'}} value='apple'></Checkbox>
                </div>:''
            }
            </div>
            <div className={style.info} onClick={()=>{
                // this.props.history.push(`/scenesDetails/${this.props.scene.id}`)
                this.props.onScenesDetails()
            }}>
                <div>
                   <div><span>{this.t('orderdetails.k036')}</span><span>{this.props.scene.code}</span></div>
                   <div className={style.date}>
                        <div className={style.cell_date}>{this.t('scenes.k008')}</div>
                        <div className={style.datelist}>
                         {this.props.scene&&this.props.scene.allowDate&&this.props.scene.allowDate.map(item=>{
                                return <span key={this.props.scene.id}>{item}</span>
                            })}
                        </div>
                   </div>
                </div>
                <RightOutline className={style.arrow}></RightOutline>
            </div>
            {/* <div className={style.abstract} onClick={
                () => {
                    this.props.onClick()
                }
            }>
                <div>
                    {this.t('main.k034')}:{this.props.scene.availableAmount !== undefined && this.props.scene.availableAmount !== null ? this.props.scene.availableAmount + this.t('main.k035'): this.t('main.k036')}
                    {
                        (() => {
                            let _rule = this.getRuleByKey('3007');
                            if (_rule && _rule.value === '1') {
                                return this.t('main.k037');
                            }
                        })()
                        
                    }
                </div>
                <div>
                    {this.t('main.k038')}:
                    {
                        (() => {
                            let _rule = this.getRuleByKey('3002');
                            if (_rule) {
                                let carLevels = _rule.value;
                                if (carLevels) {
                                    return this.allowCarLevelToCn(carLevels);
                                }
                            }
                            return this.t('main.k036');
                            
                        })()
                    }
                </div>
            </div>

            {
                (() => {
                    if (this.state.hide) {
                        return <div className={style.watch} onClick={() => {
                            this.setState({
                                hide: false
                            })
                        }}>
                        <div>{this.t('main.k040')}</div>
                        <div>
                            <img src={images.down} alt="" />
                        </div>
                    </div>
                    }
                })()
            }
            
            {
                (() => {
                    if (!this.state.hide) {
                        return <div className={style.detail}>
                            <div className={style.up} onClick={() => {
                                this.setState({
                                    hide: true
                                })
                            }}>
                                <img src={images.up} alt="" />
                            </div>
                            <div className={style.content}>
                                <div className={style.line}>
                                    <div className={style.kname}>{this.t('main.k041')}</div>
                                    <div className={style.vtext}>
                                      {
                                        (() => {
                                          if (!this.props.scene.beginDate && !this.props.scene.endDate) {
                                            return this.t('main.k036')
                                          } else {
                                            return <>
                                            {this.props.scene.beginDate ? moment(this.props.scene.beginDate).format('YYYY-MM-DD') : this.t('main.k036')} - {this.props.scene.endDate ? moment(this.props.scene.endDate).format('YYYY-MM-DD') : this.t('main.k036')}
                                            </>
                                          }
                                        })()
                                      }
                                    </div>
                                </div>
                                <div className={style.line}>
                                    <div className={style.kname}>{this.t('main.k044')}</div>
                                    <div className={style.vtext}>
                                        {
                                            (() => {
                                                let weekdayTmpl = this.t('main.k045');
                                                let rule = this.getRuleByKey('3004');
                                                if (!rule || !rule.value) {
                                                    return this.t('main.k036');
                                                }
                                                let allowTime = JSON.parse(rule.value);
                                                if (allowTime.mode === 'weekday') {
                                                    // 周模式
                                                    return allowTime.rules.map((item) => {
                                                        return <div key={item.day}>{this.t('main.k077')}{weekdayTmpl[item.day]} {item.b}-{item.e}</div>
                                                    })
                                                } else if (allowTime.mode === 'holiday') {
                                                    return allowTime.rules.map((item) => {
                                                        return <div key={item.day}>{item.day === 1 ? this.t('main.k039') : this.t('main.k046')} {item.b}-{item.e}</div>
                                                    })
                                                }
                                            })()
                                        }
                                    </div> 
                                </div>
                                <div className={style.line}>
                                    <div className={style.kname}>{this.t('main.k078')}</div>
                                    <div className={style.vtext}>{(() => {
                                        let v = this.getRuleByKey('3005')?this.getRuleByKey('3005').value:'';
                                        return v === '-1' || v === undefined ||v ===''? this.t('main.k036') : v + this.t('main.k035')
                                    })()}</div> 
                                </div>                                
                                <div className={style.line}>
                                    <div className={style.kname}>{this.t('main.k079')}</div>
                                    <div className={style.vtext}>{(() => {
                                        let v = this.getRuleByKey('3009')?this.getRuleByKey('3009').value:'';
                                        return v ? v : this.t('main.k036')
                                    })()}</div> 
                                </div>
                                <div className={style.line}>
                                    <div className={style.kname}>{this.t('main.k080')}</div>
                                    <div className={style.vtext}>
                                    {
                                        (()=>{
                                            let rule = this.getRuleByKey('3011');
                                            if (!rule || !rule.value) {
                                                return this.t('main.k036');
                                            }
                                            let addrs = JSON.parse(rule.value);
                                            if (!addrs || !addrs.length) {
                                                return this.t('main.k036')
                                            }
                                            return addrs.map((item, index) => {
                                                return <div key={index}>
                                                    {item.mode === 1 ?　this.t('main.k047') : this.t('main.k048')} {item.beginAny ? this.t('main.k049') : `${item.beginCity}-${item.beginName}`} {this.t('main.k043')} {item.endAny ? this.t('main.k049') : `${item.endCity}-${item.endName}`}
                                                </div>
                                            })

                                        })()
                                    }
                                    </div>
                                </div>
                                <div className={style.line}>
                                    <div className={style.kname}>{this.t('main.k050')}</div>
                                    <div className={style.vtext}>{this.props.scene.managerNameCn}</div>
                                </div>
                            </div>
                        </div>
                    }
                })()
            } */}
        </div>
    }

    /**
     * 车型代码转中文
     * @param {*} value 
     * @returns 
     */
    allowCarLevelToCn = (value) => {
        let arr = value.split('|');
        let arr_cn = [];
        for (let i in arr) {
          switch (arr[i] * 1) {
            case 1:
              arr_cn.push(this.t('main.k051'));
              break;
            case 2:
              arr_cn.push(this.t('main.k052'));
              break;
            case 3:
              arr_cn.push(this.t('main.k053'));
              break;
            case 4:
              arr_cn.push(this.t('main.k054'));
              break;
            default:
                break;
          }
        }
        return arr_cn.toString();
    }

    /**
     * 获取规则
     * @param {*} key 
     */
    getRuleByKey = (key) => {
        if (this.props.scene.rule && this.props.scene.rule.length) {
            let _rules = this.props.scene.rule.filter(a => a.key === key);
            if (_rules && _rules.length) {
                return _rules[0];
            } else {
              return []
            }
        }
    }
}


export default Scene;