import React from 'react'
import style from './runingOrders.scss';

import moment from 'moment'

import webService from '../util/webService';
import images from '../util/images';
import {stateToCn} from './../util/paramsHelper'

import LoadingMask from './../component/utils/loadingMask';

import { withTranslation } from "react-i18next";

class RuningOrders extends React.Component {

    state = {
        orders: [],
        isShowMask: true,
        isData: null
    }

    t = this.props.t

    renderOrders = () => {
        return  <>
            <LoadingMask show={this.state.isShowMask} />
            {
                this.state.orders.map(item => {
                    return <div className={style.trip} key={item.id}>
                        <div className={style.title}>
                            <div>{item.cityName} {moment(item.time).format('YYYY-MM-DD HH:mm')} </div>
                            <div className={style.status} onClick={() => {
                                this.props.history.push(`/runing/${item.id}`)
                            }}>
                                {stateToCn(item.state, this.t)}
                                {/* <RightOutline/> */}
                                <img alt='' src={this.orderStateIcon(item)}/>
                            </div>
                            
                        </div>
                        <div className={style.body}>
                            {this.t('runingOrders.k001')}{item.pickupLocationName}<br />
                            {this.t('runingOrders.k002')}{item.destLocationName}<br />
                            {
                              item.passengerName && item.serviceType !== 20? <>
                                {this.t('runingOrders.k003')}{item.passengerName}<br />
                              </>:<></>
                            }
                            
                            {
                              item.passengerPhone ? <>
                                {this.t('runingOrders.k005')}{item.passengerPhone}<br />
                              </>:<></>
                            }
                            {
                              item.passengerPhone && item.serviceType !== 20 ? <>
                                {this.t('runingOrders.k006')}{item.vehicleNo}<br />
                              </>:<></>
                            }
                            
                        </div>
                    </div>
                })
            }
        </>
         
    }

    render() {
        return <div className={style.container}>
            {
              this.state.orders && this.state.orders.length && this.state.isData ? this.renderOrders() : <></>
            }
            {
              (() => {
                if (!this.state.isData && this.state.isData !== null) {
                  return(
                    <div className={style.noOrder}>
                        <div>
                            <img src={images.no_order} alt={this.t('runingOrders.k004')} />
                        </div>
                        <div>
                          {this.t('runingOrders.k004')}
                        </div>
                    </div>
                  )
                }
              })()
            }
            
        </div>
    }

    componentDidMount() {
        webService.order.getRuningOrders().then(d => {
            this.setState({
                isShowMask: false
            });
            if (d.data.code === 0) {
                this.setState({
                    orders: d.data.data.orders
                }, () => {
                  if (this.state.orders && this.state.orders.length > 0) {
                    this.setState({
                      isData: true
                    })
                  } else {
                    this.setState({
                      isData: false
                    })
                  }
                })
            } else {
              this.setState({
                isData: false
              })
            }
        }).catch(e => {
            this.setState({
                isShowMask: false
            });
        });
    }

    orderStateIcon(item) {
      if ([4].includes(item.state)) {
        return images.triangleRightBlue
      } else if ([1,2,3,5,6].includes(item.state)) {
        return images.triangleRightDarkGray
      } else {
        return images.triangleRightLightGray
      }
    }
}

export default withTranslation()(RuningOrders);