import React from 'react'
import style from './showWait5s.scss';

import images from '../../util/images';

import { ProgressCircle, Image } from 'antd-mobile'

class ShowWait5s extends React.Component {

    state = {
        waitCountDown: 5
    }

    t = this.props.t

    get carModeName() {
      switch(this.props.carMode) {
        case 1:
          return ''; // 智能派单
        case 2:
          return this.t('runing.k0127'); // 距离优先
        case 3:
          return this.t('runing.k0129'); // 距离优先
        case 4:
          return this.t('runing.k0128'); // 响应优先
        default:
          return ''; // 智能派单
      }
    }

    render() {
        return <div className={style.showWait5s}>
            {
                !this.props.isGreenTravel ? <div>{this.t('runing.k077')}<span className={style.msg}>{this.carModeName}</span>{this.t('runing.k0130')}</div> : <></>
            }
            <div className={style.countDown}>
                {
                    !this.props.isGreenTravel ? <ProgressCircle style={{ '--size': '40px','--track-width': '2px' }} percent={100}>
                      <span className={style.waitBlue}>{this.state.waitCountDown}s</span>
                    </ProgressCircle>: <Image width={30} height={30} src={images.environmental}></Image> 
                }
            </div>
            {/* 绿色出行展示 isGreenTravel 是否是绿色出行*/}
            {
                this.props.isGreenTravel ? 
                <div>
                    <div className={style.countdown}>{this.state.waitCountDown}s</div>
                    <div className={style.energy}>
                    {this.t('runing.k0124')}
                    </div>
                    <div className={style.wait}>
                    {this.t('runing.k0125')}
                    </div>
                </div> : <></>
            }   
            {/* {
              !this.props.isGreenTravel ? <span className={style.waitBlue}>{this.state.waitCountDown}s</span> : <></>
            }          */}
        </div>
    }

    componentDidMount() {
        
    }

    /**
     * 启动倒计时
     */
    start = (num) => {
        this.setState({
            waitCountDown: num
        })
        setTimeout(() => {
            this.countDown();
        }, 1000);
    }

    /**
     * 倒计时
     */
    countDown = () => {
        let waitCountDown = this.state.waitCountDown;
        waitCountDown-=1;
        this.setState({
            waitCountDown: waitCountDown
        })
        if (waitCountDown > 0) {
            setTimeout(() => {
                this.countDown();
            }, 1000);
        }
        
    }
}

export default ShowWait5s