import React from "react";
import style from './setphone.scss'
import {inject, observer } from 'mobx-react'

import { List, Input, Button, Toast } from 'antd-mobile'

import { checkPhoneNumber } from './../util/utils'

import webService from '../util/webService'

import { withTranslation } from "react-i18next";

@inject('settingsStore', 'globalStore')
@observer
class SetPhone extends React.Component {

  state = {
    // 验证码
    code: "",

    // 获取验证码 check
    liked: true,

    // 计数
    count: 60,

    // 手机号
    phoneVal: "",

    // 定时器
    timer: null,

    captcha: '',
    captchaUuid: '',
    captchaImage: ''
  }

  settingsStore = this.props.settingsStore
  globalStore = this.props.globalStore

  t = this.props.t

  /**
   *
   *手机号&验证部分
   */
  renderMobile = () => {
    const exceptThisSymbols = ["e", "E", "+", "-", ".", ','];
    return(
      <List className={style.list}>
        <List.Item className={style.phone}>
          <div className={style.num}>{this.t('setphone.k001')}</div>
          <Input
            type="tel"
            onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
            placeholder={this.t('setphone.k002')}
            style={{'--font-size': '14px','--text-align':'right' }}
            value={this.state.phoneVal}
            onChange={val => {
              this.setFormattedPhoneNum(val)
            }}
          />
        </List.Item>
        <List.Item className={style.phone}>
          <div className={style.captcha}>
            <img alt={this.t('setphone.k010')} src={'data:image/png;base64,' + this.state.captchaImage} onClick={() => {this.loadCaptchaImage()}} />
          </div>
          <Input
            type="text"
            placeholder={this.t('setphone.k010')}
            style={{'--font-size': '14px','--text-align':'right' }}
            value={this.state.captcha}
            onChange={val => {
              this.setState({
                captcha: val
              })
            }}
          />
        </List.Item>
        <List.Item className={style.code}>
          <Input value={this.state.code} 
            onChange={val => {
              this.setFormattedCode(val)
            }} type="number" placeholder={this.t('setphone.k003')} style={{'--font-size': '14px' }} clearable />
            <div className={this.state.liked ? style.count : style.lightColor} onClick={() => {
              this.handleClick()
            }}>
              {
                this.state.liked ? this.t('setphone.k004') : `${this.t('setphone.k005')}(${this.state.count}s)`
              }
            </div>
        </List.Item>
      </List>
    )
  }
  
  /**
   *
   *渲染
   */
  render() {
    return(
      <div className={style.wrap}>
        {/* 手机号&验证 */}
        {this.renderMobile()}
        <Button style={
            {
              'background-image': 'var(--button_background_color)',
              '--border-color': 'var(--button_border_color)',
              'background-color': 'var(--button_border_color)',
              'color': 'var(--button_color)'
            }
          } onClick={() => {
          this.handleSubmit()
        }} disabled={this.setDisabled()} block color='primary' size='large'>
          {this.t('setphone.k007')}
        </Button>
      </div>
    )
  }

  componentDidMount() {
    let count = window.localStorage.getItem('count')
    if (count > 0 && count < 60) {
      this.setState({
        count: count,
        liked: false
      }, () => {
        this.countDown()
      })
    } else {
      window.localStorage.removeItem('count')
    }

    this.loadCaptchaImage()
  }

  componentWillUnmount() {
    window.localStorage.removeItem('count')
    clearTimeout(this.state.timer)
  }

  /**
   * 加载反机器人验证码图片
   */
  loadCaptchaImage = async () => {
    let {data} = await webService.user.getCode()
    if (data.code === 0) {
        this.setState({
            captchaUuid: data.data.uuid,
            captchaImage: data.data.img
        })
    }
  }

  /**
   * 
   * 按钮禁用
   */
   setDisabled = () => {
    let flag = true
    if (this.state.phoneVal && this.state.code) {
      flag = false
    } else {
      flag = true
    }
    return flag
  }

  /**
   *
   *手机号输入 check
   */
   setFormattedPhoneNum = (text) => {
    let value = text.replace(/\D/g, '')
    value.length > 11 ? this.setState({phoneVal : value.slice(0, 11)}) : this.setState({phoneVal: value})
  }

  /**
   *
   *验证码 check
   */
   setFormattedCode = (text) => {
    text.length > 6 ? this.setState({code : text.slice(0, 6)}) : this.setState({code: text})
  }

  /**
   *
   *倒计时
   */
   countDown = () => {
    if(this.state.count <= 1) {
      window.localStorage.setItem("count", 60);
      this.setState({
        count: 60,
        liked: true,
      });
      clearTimeout(this.state.timer)
    } else {
      this.setState({
        count: this.state.count - 1,
      });
      window.localStorage.setItem("count", this.state.count);
      this.setState({
        timer: setTimeout(() => this.countDown(), 1000)
      })
    }
  }

  /**
   *
   *获取验证码
   */
   handleClick = () => {
    if (!this.state.phoneVal) {
      Toast.show({
        content: this.t('setphone.k008'),
      })
    } else if (!checkPhoneNumber(this.state.phoneVal)) {
      Toast.show({
        content: this.t('setphone.k009'),
      })
    } else if (!this.state.captcha) {
      Toast.show({
        content: this.t('setphone.k010'),
      })
    } else {
      if(!this.state.liked) {
        return false
      }
      this.sendCode()
    }
  }

  /**
   *
   * 发送验证请求
   * @memberof Contact
   */
   sendCode = () => {
    let params = {
      phoneNum: this.state.phoneVal,
      captchaId: this.state.captchaUuid,
      captcha: this.state.captcha,
      type: 1
    }

    this.setState({
      liked: false
    })

    webService.user.sendVerificationCode(params).then(d => {
      if (d.data.code === 0) {
        // 调用计时
        this.countDown()
      } else {
        this.setState({
          liked: true
        })
        Toast.show({
          content: d.data.message || this.t('setphone.k011'),
          duration: 1000
        })
      }
    })
  }

  /**
   *
   *提交 check
   */
   handleSubmit = () => {
    if (!this.state.phoneVal) {
      Toast.show({
        content: this.t('setphone.k008'),
      })
    } else if (!this.state.code) {
      Toast.show({
        content: this.t('setphone.k003'),
      })
    } else if (!checkPhoneNumber(this.state.phoneVal)) {
      Toast.show({
        content: this.t('setphone.k009'),
      })
    } else {
      this.setMobile()
    }
  }

  /**
   *
   *用户信息接口
   */
  getUserInfo = async () => {
    let d = await webService.basCity.getUserInfo();
    let {data} = d;
    if (data.code === 0) {
      this.globalStore.setUserInfo(data.data);
      this.props.history.go(-1);
    }
  }

  /**
   *
   * 提交
   */
  setMobile = () => {
    let params = {
      mobile: this.state.phoneVal,
      code: this.state.code,
      type: 1
    }
    webService.user.setMobile(params).then(d => {
      if (d.data.code === 0) {
        this.settingsStore.setUserPhone(this.state.phoneVal)
        this.getUserInfo()
      } else {
        Toast.show({
          content: d.data.message,
          duration: 1000
        })
      }
    })
  }
}

export default withTranslation()(SetPhone);