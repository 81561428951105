import webService from './webService'
import { latlngEq } from './utils'

class RecommendPoint {

    constructor() {
        this.listPoint = [];
    }

    async init() {
        // if (this.listPoint.length > 0) {
        //     return
        // }
        // let d = await webService.company.getRecommendLocations();
        // if (d && d.data.code === 0) {
        //     let listCp = d.data.data;
        //     if (listCp && listCp.length) {
        //     this.listPoint = listCp;
        //     }
        // }
    }

    /**
     * 添加推荐上车地点缓存
     * @param {*} recommend 
     */
    addRecommend(recommend) {
        let res = this.listPoint.filter(
            a => latlngEq(a.location, recommend.location))
        if (res && res.length) {
            return
        }
        this.listPoint.push(recommend)
    }

    /**
     * 查询缓存的推荐上车地点
     * @param {*} lng 
     * @param {*} lat 
     */
    getRecommend(lng, lat) {
        let res = this.listPoint.filter(
            a => latlngEq(a.location, {lng, lat}))
        if (res && res.length) {
            return res[0]
        }
        return undefined
    }

    /**
     * 搜索附近推荐上车地点
     * @param {*} lat 
     * @param {*} lon 
     * @param {*} userId 
     */
    async recommend(lat, lon, userId, recommendType, count) {
        let res = await webService.map.recommend(lat, lon, userId, recommendType, count);
        let _recommendPoints = res.data.data.sort((a,b) => {return b.adsorb - a.adsorb})
        for (let i in _recommendPoints) {
            this.addRecommend(_recommendPoints[i])
        }

        if(_recommendPoints && _recommendPoints.length > 0 && _recommendPoints[0].adsorb) {
          _recommendPoints[0].distance = 0
        }

        console.log(this.listPoint)
        return _recommendPoints
        
        // // 先查自定义的点
        // let listDistance = this.listPoint.map(a => {
        //     return {
        //         title: a.title,
        //         location: a.location,
        //         distance: this.getDistance(a.location, {lat: lat, lng: lon}),
        //         updateTime: a.createTime
        //     }
        // });
        // listDistance = listDistance.sort((a, b) => a.distance - b.distance);
        // listDistance = listDistance.filter(a => a.distance < 200);

        // listDistance = this.distinct(listDistance, 50);

        // if (listDistance.length < 3) {
        //     let res = await webService.map.recommend(lat, lon, userId);
        //     if (res.status === 200 && res.data.data && res.data.data.length) {
        //         // let _recommendPoints = res.data.data.slice(0, 3 - listDistance.length);
        //         let _recommendPoints = res.data.data.map(a => {
        //             return {
        //                 title: a.title,
        //                 location: a.location,
        //                 distance: a.distance,
        //                 updateTime: 0
        //             }
        //         })
        //         listDistance = listDistance.concat(_recommendPoints);
        //         listDistance = this.distinct(listDistance, 50);
        //         listDistance = listDistance.sort((a, b) => a.distance - b.distance);
        //     }
        // }

        // return listDistance.splice(0, 3);
    }

    /**
     * 过滤掉位置相近的点
     * @param {*} list 
     * @param {*} thresholdValue 
     */
    // distinct(list, thresholdValue) {
    //     if (list.length > 1) {
    //       for(let i  = 0; i < list.length ; i++) {
    //             let current = list[i];
    //             if (current.isRemove) {
    //                 continue;
    //             }
    //             for (let j = i + 1; j < list.length; j++) {
    //                 let other = list[j];
    //                 if (other.isRemove) {
    //                     continue;
    //                 }
    //                 if (this.getDistance(current.location, current.location) < thresholdValue) {
    //                     let removedItem = current.updateTime > other.updateTime ? other : current;
    //                     removedItem.isRemove = true;
    //                 }
    //             }
    //         }
    //     }

    //     return list.filter(a => a.isRemove !== true);
    // }

    // getRad(d) {
    //     var PI = Math.PI; 
    //     return d * PI / 180.0;
    // }

    // /**
    //  * 计算两坐标直线距离
    //  * @param {*} p1 
    //  * @param {*} p2 
    //  */
    // getDistance(p1, p2) {
    //     let lat1 = Number(p1.lat);
    //     let lat2 = Number(p2.lat);
    //     let lng1 = Number(p1.lng);
    //     let lng2 = Number(p2.lng);
    //     var f = this.getRad((lat1 + lat2) / 2);
    //     var g = this.getRad((lat1 - lat2) / 2);
    //     var l = this.getRad((lng1 - lng2) / 2);
    //     var sg = Math.sin(g);
    //     var sl = Math.sin(l);
    //     var sf = Math.sin(f);
    //     var s, c, w, r, d, h1, h2;
    //     var a = 6378137.0;
    //     var fl = 1 / 298.257;
    //     sg = sg * sg;
    //     sl = sl * sl;
    //     sf = sf * sf;
    //     s = sg * (1 - sl) + (1 - sf) * sl;
    //     c = (1 - sg) * (1 - sl) + sf * sl;
    //     w = Math.atan(Math.sqrt(s / c));
    //     r = Math.sqrt(s * c) / w;
    //     d = 2 * w * a;
    //     h1 = (3 * r - 1) / 2 / c; h2 = (3 * r + 1) / 2 / s; s = d * (1 + fl * (h1 * sf * (1 - sg) - h2 * (1 - sf) * sg)); 
    //     s = s.toFixed(2) * 1;
    //     return s ;
    // }

}

export default new RecommendPoint()
