import React from 'react';

import style from './letterAgreement.scss';

import webService from '../../util/webService';

import { inject, observer } from 'mobx-react';
import { Modal, Toast, Dialog } from 'antd-mobile';

@inject('globalStore')
@observer
class LetterAgreement extends React.Component {
  state = {
    visible: true,
    isLetter: false,
  };

  globalStore = this.props.globalStore;

  t = this.props.t;

  get isShowVerify() {
    return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.main_letter_agreement_hide_verify
  }

  componentDidMount = () => {
    if (this.isShowVerify) {
      this.setState({
        isLetter: true,
      });
    }
  };

  render() {
    return (
      <div>
        <Modal
          visible={this.state.visible}
          bodyClassName={style.failBody}
          title={this.t('main.k0156')}
          content={
            <div>
              <div
                style={{
                  whiteSpace: 'pre-wrap',
                  color: '#9d9d9d',
                  fontSize: '13px',
                  maxHeight: '30vh',
                  overflow: 'hidden',
                  overflowY: 'auto',
                }}
              >
                {this.globalStore.userInfo.letterContent}
              </div>
              {!this.isShowVerify ? 
                <div className={style.content}>
                  <label>
                    <input
                      type="checkbox"
                      value={this.state.isLetter}
                      onChange={(e) => {
                        this.setState({
                          isLetter: e.target.checked,
                        });
                      }}
                    />
                    <span className={style.agreement}>
                      {this.t('main.k0157')}
                    </span>
                  </label>
                </div>
              : <></>
              }
            </div>
          }
          closeOnAction
          onAction={async () => {
            if (!this.state.isLetter) {
              Toast.show({
                content: this.t('main.k0158'),
                duration: 1000,
              });
            } else {
              let { data } = await webService.user.setConsentLetter({
                isLetter: this.state.isLetter,
              });
              if (data.code === 0) {
                this.setState({
                  isLetter: false,
                });
                this.getUserInfo();
              }
            }
          }}
          actions={[
            {
              key: 'confirm',
              text: this.isShowVerify ? this.t('main.k0173'):this.t('common.k002'),
              primary: true,
              disabled: this.state.isLetter ? false : true,
            },
          ]}
        />
      </div>
    );
  }

  /**
   *
   *用户信息接口
   */
  getUserInfo = async () => {
    let d = await webService.basCity.getUserInfo();
    let { data } = d;
    if (data.code === 0) {
      this.globalStore.setUserInfo(data.data);
      if (!this.globalStore.userInfo.phone) {
        Dialog.show({
          title: this.t('main.k098'),
          content: this.t('main.k099'),
          actions: [[
            {
              key: 'go_set', text: this.t('main.k0100'), bold: true,
              onClick: () => {
                this.props.history.push('/settings')
              }
            }
          ]],
          closeOnAction: true
        })
      }
    }
  };
}

export default LetterAgreement;
