import React from 'react';
import {inject, observer } from 'mobx-react'
import style from './callcar.scss'
import style2 from './preApproval.scss'
import images from '../../util/images'

import { Popup, Toast, Button, InfiniteScroll } from 'antd-mobile'

import ApplyRecord from './../perApproval/applyRecord'

import webService from '../../util/webService'
import { ForgeRouter } from '../../util/utils'


@inject('mainStore', 'globalStore')
@observer
class PreApproval extends React.Component {

    state = {
        show: false,
        pageData: {
            list: [],
            pageNum: 1,
            pageSize: 10,
            total: 0
        }
    }

    mainStore = this.props.mainStore;
    globalStore = this.props.globalStore;
    needCallBack = false;
    t = this.props.t

    fr = undefined

    get listHashMore() {
        let pageData = this.state.pageData;
        return !(Number(pageData.total) !== 0 && Number(pageData.total) === pageData.list.length)
    }

    // 去申请按钮
    get isShowApplyBtn() {
      return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.preApproval_Apply ? true : false
    }

    renderList() {
        return <>
            {
                this.state.pageData.list.map(item => (
                    <ApplyRecord t={this.props.t} history={this.props.history} data={item} key={item.preDepartApplyId} showCheckSign={true} checked={item.preDepartApplyId === this.mainStore.preApply.preDepartApplyId} onClick={
                        () => {
                            this.mainStore.setPreApply({
                                preDepartApplyId: item.preDepartApplyId
                            })
                            this.needCallBack = true;
                            this.props.history.goBack();

                            if (window !== window.top) {
                                this.fr.manualOperation()
                            }
                        }
                    } />
                ))
            }
            <InfiniteScroll loadMore={this.load} hasMore={this.listHashMore}></InfiniteScroll>
        </>
    }

    renderEmpty() {
        return <div className={style2.noneData}>
            <div>
                <img src={images.account_nodata} alt={this.t('preApproval.k004')} />
            </div>
            <div>{this.t('preApproval.k004')}</div>
        </div>
    }

    /**
     * 渲染用户可选申请单
     * @returns 
     */
    renderApply() {
        return <div className={style2.preApply}>
            {
                this.state.pageData.list.length
                    ? this.renderList()
                    : this.renderEmpty()
                
            }
            {
              this.isShowApplyBtn ? <div className={style2.newApply}>
              <Button block color='primary' size='middle' onClick={() => {
                      this.props.history.replace('/perApply')
                  }}>{this.t('preApproval.k001')}</Button>
              </div> : <></>
            }
            
        </div>
    }

    render() {
        return <div className={style.line}>
            <div className={style.icon}>
                <img src={images.reasonOrder} alt="" />
            </div>
            <div className={`${style.content} ${style.sence}`} >
            <div>{this.t('preApproval.k005')}</div>
                <div className={style.name} onClick={() => {
                    this.showApply();
                }}>
                    {
                        (() => {
                            if (!this.mainStore.preApply.preDepartApplyId) {
                                return <div className={style.where}>{this.t('preApproval.k006')}</div>
                            }
                        })()
                    }
                    { this.mainStore.preApply.preDepartApplyId }
                </div>
                <div className={style.more} onClick={() => {
                    this.showApply();
                }}><img src={images.approveSee} alt="" /></div>
            </div>

            <Popup
                visible={this.state.show}
                position='right'
                bodyStyle={{ minWidth: '100vw' }}
                >
                    {/* 申请单 */}
                    { this.renderApply() }
            </Popup>
        </div>
    }

    /**
     * 显示可选申请单
     */
    showApply = () => {
        this.setState({
            pageData: {
                ...this.state.pageData,
                list: [],
                pageNum: 1,
                total: 0
            }
        }, () => {
            this.load()
        })
    }

    load = async () => {
        let pageData = this.state.pageData;
        if (Number(pageData.total) !== 0 && Number(pageData.total) === pageData.list.length) {
            // total非0，表示已经加载过数据，total与list的长度相同，则表示已经加载过最后一页
            return;
        }

        Toast.show({
            icon: 'loading',
            content: this.t('preApproval.k019'),
            duration: 0
        })

        try {
            let params = {
                scenePublishId: this.mainStore.scene.publishId,

                pageNum: pageData.pageNum,
                pageSize: pageData.pageSize
            }
            let { data } = await webService.preApproval.queryUsableApplyList(params);
            Toast.clear();
            let records = [];
            if (data.code === 0 && data.data.rows && data.data.rows.length) {
                records = [...data.data.rows]
            }
            this.needCallBack = false;
            this.setState({
                show: true,

                pageData: {
                    ...this.state.pageData,
                    list: [...this.state.pageData.list, ...records],
                    total: data.data.totalCounts,
                    pageNum: data.data.currentPage + 1
                }
            }, () => {
                this.fr = new ForgeRouter(() => {
                    let {hash} = document.location;
                    if (hash === '#/') {
                        this.setState({
                            show: false
                        }, () => {
                            if (this.needCallBack) {
                                this.props.onChange();
                            }
                        })
                        
                    } 
                });
                this.fr.onpopstate();
                
            })
        } catch(e) {
            Toast.show({
                icon: 'fail',
                content: this.t('preApproval.k020')
            })
        }


    }
}

export default PreApproval