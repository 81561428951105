import React from "react";
import style from './promotion.scss'
import { inject, observer } from 'mobx-react'

import { Mask, Swiper } from 'antd-mobile'
import { CloseCircleOutline } from 'antd-mobile-icons'

import webService from "../../util/webService";
import {getCurrentPosition} from './../../util/geo'

import images from './../../util/images'

@inject('globalStore')
@observer
class Ad extends React.Component {
  
  state = {
    adItem: "",
    isShow: false,
    ads: [],
    locationGeo : {
      country : '未知', 
      province : '未知', 
      city : '未知'
    }
  }

  globalStore = this.props.globalStore;

  t = this.props.t
  
  

  render() {
    return <>
      <Mask opacity={0.5} visible={this.state.isShow} onMaskClick={this.close}>
        <div className={style.activityContent}>
          <div className={style.box}>
            {
              this.state.ads && this.state.ads.length
                ? <Swiper autoplay style={{'--height': '100%'}} indicatorProps={{color: 'white'}} onIndexChange={(index) => {this.insertADRecord(index)}}>
                  {
                    this.state.ads.map((item, index) => {
                      return <Swiper.Item key={item.id}>
                        <img alt="" src={item.imgUri} onClick={() => {
                        this.updateADRecord(item, index)
                        }} onError={() => {
                          item.imgUri = images.noimage;
                          this.setState({
                            ads: this.state.ads
                          })
                        }} />
                      </Swiper.Item>
                      
                    })
                  }
                  
                </Swiper>
                : <></>
            }
            
          </div>
          <div className={style.close}>
            <CloseCircleOutline fontSize={25} onClick={this.close}/>
          </div>
        </div>
      </Mask>
    </>
  }

  componentDidMount = async () => {

    let {data} = await webService.promotion.queryUserAD();
    if (data.code!==0||data.data.length === 0) {
      return;
    }
    
    try {
      let locationGeo = await getCurrentPosition();
      if (locationGeo.latitude) {
        this.setState({
          locationGeo: {
            country: locationGeo.nation,
            province: locationGeo.province,
            city: locationGeo.cityName
          }
        })
        console.log('locationGeo', this.state.locationGeo)
      }
    } catch {}

    
    this.setState({
      ads: [...data.data],
      isShow: true
    }, () => {
      this.insertADRecord(0);
    })


  }

  getCurrentUserPhone = () => {
    return this.globalStore.userInfo.phone ? this.globalStore.userInfo.phone : '';
  }

  insertADRecord = (index) => {
    webService.promotion.insertADRecord({
      ...this.state.locationGeo,
      advertisingImgsId: this.state.ads[index].id,
      showState: 1,
      phone: this.getCurrentUserPhone()
    })
  }
  updateADRecord = (item, index) => {
    webService.promotion.updateADRecord({
      ...this.state.locationGeo,
      advertisingImgsId: this.state.ads[index].id,
      showState: 1,
      phone: this.getCurrentUserPhone()
    }).then((res)=> {
      let url = item.linkUrl;
      if (url) {
        if (url.indexOf('http') === 0) {
            window.location.href = url;
        } else {
            this.props.history.push(url)
        }
      }
    })
  }

  close = () => {
    this.setState({
      isShow: false
    })
  }

}

export default Ad
