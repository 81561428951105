import React from "react";

import {inject, observer } from 'mobx-react';
import { Dialog } from 'antd-mobile';

import LoadingMask from './../component/utils/loadingMask';

import {generateEstimate} from './../util/paramsHelper';

import webService from './../util/webService';

@inject('mainStore', 'estimateStore')
@observer
class AmapMiddleware extends React.Component {
    state = {
        beginAddr: {
            cityCode: '',
            cityName: '',
            name: '',
            address: '',
            lon: '',
            lat: '',
            poi: '',
            departureTime: "", // 出发时间
            flightNumber: "", // 航班号
            trainNumber:"", // 列车号
            departTime: "", // 起飞时间
            arrivalAirportCode: "", // 到达机场代码
            departAirportCode: "", // 出发机场代码
        },
        endAddr: {
            cityCode: '',
            cityName: '',
            name: '',
            address: '',
            lon: '',
            lat: '',
            poi: ''
        },
        scene: {
            id: '',
            publishId: '',
            nameCn: '',
            nameEn: ''
        },
        serverType: '',
        phone: '',
        preApply: {
            preDepartApplyId: ''
        },
        isShowMask: true,
        
    }
    
    mainStore = this.props.mainStore;
    estimateStore = this.props.estimateStore;
    des = {
        start: {
            lng: '121.522998',
            lat: '38.861654',
            name: '大连腾讯大厦-1号门',
            address: '大连腾讯大厦-1号门',
            poiid: '',
            departTime: '2022-10-09 17:19:43',
            departureTime: '',
            flightNumber: '',
            trainNumber: '',
            arrivalAirportCode: '',
            departAirportCode: '',
            cityCode: '52',
            cityName: '大连市'
        },
        end: {
            lng: '121.513878',
            lat: '38.84918',
            name: '山海一家',
            address: '辽宁省大连市甘井子区高新园区202路轻轨车站七贤岭站',
            poiid: '',
            cityCode: '52',
            cityName: '大连市'
        },
        scene: {
            id: '232519182164165',
            publishId: '323812444483781',
            nameCn: '出 差 Travel Transportation',
            nameEn: 'test3'
        },
        gdServiceId: 1,
        phone: '',
        preApply: {
            preDepartApplyId: ''
        }
    }
    render() {
        return <div>
            <LoadingMask show={this.state.isShowMask} />
        </div>
    }

    componentDidMount() {
        // let a = btoa(encodeURIComponent(JSON.stringify(this.des)))
        // console.log('a', `http://localhost:3000/#/amapMiddleware?des=${a}`)

        let params = this.getDecode(this.queryDecode());

        this.setState({
            beginAddr: {
                ...this.state.beginAddr,
                cityCode: params.start ? params.start.cityCode : '',
                cityName: params.start ? params.start.cityName : '',
                name: params.start ? params.start.name : '',
                address: params.start ? params.start.name : '',
                lon: params.start ? params.start.lng : '',
                lat: params.start ? params.start.lat : '',
                poi: params.start ? params.start.poiid : '',
                departureTime: params.start ? params.start.departureTime: '',
                flightNumber: params.flightInfo ? params.flightInfo.flightNumber : '',
                trainNumber: params.trainNumber ? params.trainNumber : '',
                departTime: params.departTime ? params.departTime : '', // 起飞时间
                arrivalAirportCode: params.arrivalAirportCode ? params.arrivalAirportCode : '',
                departAirportCode: params.departAirportCode ? params.departAirportCode : ''
            }
        }, () => {
            this.mainStore.choosedBeginAddr(this.state.beginAddr);
        });

        this.setState({
            endAddr: {
                ...this.state.endAddr,
                cityCode: params.end ? params.end.cityCode : '',
                cityName: params.end ? params.end.cityName : '',
                name: params.end ? params.end.name : '',
                address: params.end ? params.end.name : '',
                lon: params.end ? params.end.lng : '',
                lat: params.end ? params.end.lat : '',
                poi: params.end ? params.end.poiid : ''
            }
        }, () => {
            this.mainStore.choosedEndAddr(this.state.endAddr);
        });

        this.setState({
            scene: {
                ...this.state.scene,
                id: params.scene.id ? params.scene.id : '',
                publishId: params.scene.publishId ? params.scene.publishId : '',
                nameCn: params.scene.nameCn ? params.scene.nameCn : '',
                nameEn: params.scene.nameEn ? params.scene.nameEn : ''
            }
        }, () => {
            this.mainStore.setScene(this.state.scene);
        })
        
        this.setState({
            phone: params.phone ? params.phone : '',
            serverType: params.gdServiceId ? params.gdServiceId : ''
        }, () => {
            this.mainStore.chooseServerType(this.state.serverType);
        })

        this.mainStore.setPreApply({
            preDepartApplyId: this.state.preApply.preDepartApplyId
        }, () => {
            this.setState({
                preApply: {
                    ...this.state.preApply,
                    preDepartApplyId: params.preDepartApplyId ? params.preDepartApplyId : ''
                }
            })
        })

        setTimeout(() => {
            this.init();
        }, 500)
    }

    init = () => {
        let cep = generateEstimate(this.mainStore.serverType, this.mainStore.scene, this.mainStore.beginAddr, this.mainStore.endAddr, undefined, this.mainStore.preApply, this.mainStore.charteredBusType, this.mainStore.pathways);
        webService.order.preCheck(cep).then((d) => {
            if (d.data.code) {
                Dialog.alert({
                    content: d.data.message
                })
            } else {
                // 跳转到预约界面
                setTimeout(() => {
                    this.estimateStore.clear();
                    this.setState({
                        isShowMask: false
                    })
                    this.props.history.replace('/estimate');
                }, 500);
                
            }
        });
    }

    /**
     * 获取参数
     * @returns 
     */
    queryDecode = () => {
        let { search } = window.location;
        let query = {};
        if (search) {
            let _search = search.substr(1);
            if (_search) {
            let params = _search.split('&');
            params.forEach(element => {
                if (element.indexOf('=') > 0) {
                    let arr = element.split('=');
                    if (arr && arr.length === 2) {
                        query[arr[0]] = arr[1];
                    }
                }
            });
        }
        } else {
           let hash =  window.location.hash.substr(1);
           if (hash) {
            let params = hash.split('des=');
            params.forEach(element => {
                if (element.indexOf('amapMiddleware') === -1) {
                    query = element
                }
            });
            }
        }
        return query;
    }

    /**
     * base64转字符串
     * @param {*} str 
     */
    getDecode = (str) => {
        // 对base64转编码
        return JSON.parse(decodeURIComponent(atob(str)));
    }
}

export default AmapMiddleware