import React from "react";
import style from './notice.scss';
import { Swiper, Ellipsis } from 'antd-mobile'
import { MessageFill, RightOutline } from 'antd-mobile-icons'

import webService from "../../util/webService";

class Notice extends React.Component {
    state = {
        list: []
    }

    render() {
        return <>
            {this.state.list && this.state.list.length
                ? <div className={style.notice}>
                    <Swiper direction='vertical' autoplay='true' loop='true' style={{ '--height': '100%' }} indicator={() => { return <></> }}>
                        {
                            this.state.list.map(item => {
                                return <Swiper.Item key={item.id}>
                                    <div className={style.line} onClick={() => { this.noticeTitleOnClick(item) }} >
                                        <div>
                                            <MessageFill />
                                        </div>
                                        <Ellipsis direction='end' content={item.title} />
                                        <div>
                                            <RightOutline />
                                        </div>
                                    </div>
                                </Swiper.Item>
                            })
                        }
                    </Swiper>

                </div>
                : <></>}

        </>
    }

    componentDidMount = async () => {
        try {
          let { data } = await webService.promotion.getAnnouncements({lang: this.props.language === 1 ? 'zh':'en'});
          if (data.code === 0 && data.data && data.data.length) {
              let list = data.data.map((item, index) => {
                  return {
                      id: index,
                      title: item.content,
                      url: item.linkUrl,
                      body: ''
                  }
              });
              this.setState({
                  list: list
              })
          }
        } catch(e) {
          console.log(e)
        }
    }

    /**
     * 通知栏title被点击
     * @param {*} item 
     */
    noticeTitleOnClick = (item) => {
        if (item && item.url) {
            let url = item.url;
            if (url) {
                if (url.indexOf('http') === 0) {
                    window.location.href = url;
                } else {
                    this.props.history.push(url)
                }
            }
            
        }
    }

}

export default Notice