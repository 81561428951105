import React from "react";
import {inject, observer } from 'mobx-react'
import style from './recommendPath.scss'

import Address from './address'

import webService from './../../util/webService'

import { PickerView, Button, Popup } from 'antd-mobile'
import { ArrowDownCircleOutline } from 'antd-mobile-icons'

import { CloseOutline, RightOutline } from 'antd-mobile-icons'

import moment from 'moment'

import { ForgeRouter } from './../../util/utils'
@inject('mainStore', 'globalStore')
@observer
class RecommendPath extends React.Component {

  state = {
    isShow: true,
    // 一级围栏地址
    locationName: '',
    // 二级围栏地址
    addressName: '',
    // 二级围栏数据
    secondLevel: [],
    // 精度纬度
    location: {},
    isShowAddress: false,
    poi: ''
  }

  mainStore = this.props.mainStore;
  globalStore = this.props.globalStore

  t = this.props.t

  get mapCenter() {
    if (this.mainStore.mapCenter && (this.mainStore.mapCenter.slice()[1] && this.mainStore.mapCenter.slice()[0])) {
      return {
        lat: this.mainStore.mapCenter.slice()[1],
        lon: this.mainStore.mapCenter.slice()[0]
      }
    }
    return {
      lat: '',
      lon: ''
    }
  }

  render() {
    return(
      <div>
        {/* 一级围栏 */}
        <div className={`${style.container} ${this.state.isShow ? style.show : style.hide}`}>
          {
            this.mainStore.isBegin ? <div className={style.header}>
            <h2>{this.t('main.k087')}{this.props.stationName}</h2>
            <CloseOutline onClick={() => this.props.onClose(true)} fontSize={18} color='#9ea4ac' />
            </div> : <></>
          }
          {
            this.mainStore.isBegin ? <div className={style.header}>
            <h2>{this.t('main.k088')}</h2>
            </div> : <></>
          }
          {
            !this.mainStore.isBegin ? <div className={style.header}>
            <h2>{this.t('main.k0140')}</h2>
            <CloseOutline onClick={() => this.props.onClose(true)} fontSize={18} color='#9ea4ac' />
            </div> : <></>
          }
          
          <div className={style.place}>
            <span className={style.left}>{this.t('main.k089')}</span>
            <span className={style.rigth}>{this.mainStore.isBegin ? this.t('main.k090') : this.t('main.k0138')}</span>
          </div>
          <PickerView style={{ '--height': '105px', '--item-height': '30px' }} columns={this.props.levelOptions ? this.props.levelOptions : []} onChange={(val, extend) => {
            let secondLevel = []
            this.setState({
              locationName: val[0]
            }, () => {
              secondLevel = this.props.secondLevel.filter((item) => item.value === val[0])
              if (secondLevel && secondLevel[0]) {
                this.setState({
                  secondLevel: secondLevel
                }, () => {
                  this.props.startingPlace({location:this.state.secondLevel[0].data[0].location, addressName: this.state.secondLevel[0].data[0].label, poi: this.state.secondLevel[0].data[0].poi, 
                    name: this.combinationFun(secondLevel[0].label, secondLevel[0].data[0].label)
                  }, false, false)
                })
              } else {
                webService.map.gethitSecondLevelFences(val[0], this.mapCenter.lat, this.mapCenter.lon, this.globalStore.userInfo.id, moment().format('X')).then(res=> {
                  if (res.data.data.status === 0) {
                    secondLevel = res.data.data.detail.data.map(item => {
                      return {
                        data: [
                          {
                            label: item.title,
                            value: item.title,
                            location:item.location,
                            poi: item.id,
                            name: res.data.data.detail.name
                          }
                        ]
                      }
                    })
                    this.setState({
                      secondLevel: secondLevel
                    }, () => {
                      this.props.startingPlace({location:this.state.secondLevel[0].data[0].location, addressName: this.state.secondLevel[0].data[0].label, poi: this.state.secondLevel[0].data[0].poi, 
                        name: this.combinationFun(res.data.data.detail.name, secondLevel[0].data[0].label)
                      }, false, false)
                    })
                  }
                })
              }
            })
          }}>
            
          </PickerView>
          <Button block color='primary' size='large' style={
            {
              'background-image': 'var(--button_background_color)',
              '--border-color': 'var(--button_border_color)',
              'background-color': 'var(--button_border_color)',
              'color': 'var(--button_color)'
            }
          } onClick={() => {
            if (this.state.secondLevel && this.state.secondLevel[0].data && this.state.secondLevel[0].data.length > 1) {
              this.setState({
                isShow: false
              })
            } else {
              this.props.startingPlace({location:this.state.secondLevel[0].data[0].location, addressName: this.state.secondLevel[0].data[0].label, poi: this.state.secondLevel[0].data[0].poi, 
                name: this.combinationFun(this.state.secondLevel[0].label, this.state.secondLevel[0].data[0].label)
              }, true, true)
            }
          }}>
           {this.mainStore.isBegin ? this.t('main.k091') : this.t('main.k0135')}
          </Button>
          <div className={style.nill}>
            <span onClick={() => {
              this.setState({
                isShowAddress: true
              }, () => {
                  let fr = new ForgeRouter(() => {
                      let {hash} = document.location;
                      if (hash === '#/') {
                          this.setState({
                            isShowAddress: false
                          })
                          
                      } 
                  });
                  fr.onpopstate();
              })
            }}>{this.mainStore.isBegin ? this.t('main.k092'):this.t('main.k0137')}</span> <RightOutline />
          </div>
        </div>
        {/* 二级围栏 */}
       {
         (() => {
           if (!this.state.isShow) {
             return(
              <div className={`${style.container} ${this.state.isShow ? style.hide : style.show}`}>
              {
                this.mainStore.isBegin ? <div className={style.header}>
                <h2><ArrowDownCircleOutline className={style.goback} onClick={() => {
                  this.setState({
                    isShow: true
                  })
                }}/>&nbsp;&nbsp;{this.t('main.k087')}{this.props.stationName}</h2>
                <CloseOutline onClick={this.props.onClose} fontSize={18} color='#9ea4ac' />
                </div>:<></>
              }
              {
                this.mainStore.isBegin ? <div className={style.header}>
                <h2 className={style.secondTitle}>{this.t('main.k093')}&nbsp;<span>{this.state.addressName}</span></h2>
                </div>:<></>
              }
              {
                !this.mainStore.isBegin ? <div className={style.header}>
                  <h2 className={style.secondTitle}>
                    <ArrowDownCircleOutline className={style.goback} onClick={() => {
                      this.setState({
                        isShow: true
                      })
                    }}/>&nbsp;{this.t('main.k0141')}&nbsp;
                    <span>{this.state.addressName}</span>
                  </h2>
                  <CloseOutline onClick={this.props.onClose} fontSize={18} color='#9ea4ac' />
                </div>:<></>
              }
              
              <div className={style.place}>
                <span className={style.left}>{this.t('main.k094')}</span>
                <span className={style.rigth}>{this.mainStore.isBegin ? this.t('main.k095'):this.t('main.k0139')}</span>
              </div>
              <PickerView style={{ '--height': '105px', '--item-height': '30px' }} columns={this.state.secondLevel && this.state.secondLevel[0] ? [this.state.secondLevel[0].data] : []} onChange={(val, extend) => {
                this.setState({
                  addressName: val[0],
                  location:extend.items[0].location,
                  poi: extend.items[0].poi
                }, () => {
                  this.props.startingPlace({location:extend.items[0].location, addressName: `${this.state.addressName}`, poi: this.state.poi, 
                    name: this.combinationFun(this.state.secondLevel[0].label, this.state.addressName)
                  }, false)
                })
              }}>
                
              </PickerView>
              <Button block color='primary' size='large' style={
                {
                  'background-image': 'var(--button_background_color)',
                  '--border-color': 'var(--button_border_color)',
                  'background-color': 'var(--button_border_color)',
                  'color': 'var(--button_color)'
                }
              } onClick={() => {
                this.props.startingPlace({location:this.state.location, addressName: `${this.state.addressName}`, poi: this.state.poi, 
                  name: this.combinationFun(this.state.secondLevel[0].label, this.state.addressName)
                }, true, true)
              }}>
                {this.mainStore.isBegin ? this.t('main.k096') : this.t('main.k0136')}
              </Button>
              <div className={style.nill}>
                <span onClick={() => {
                  this.setState({
                    isShowAddress: true
                  }, () => {
                      let fr = new ForgeRouter(() => {
                          let {hash} = document.location;
                          if (hash === '#/') {
                              this.setState({
                                isShowAddress: false
                              })
                              
                          } 
                      });
                      fr.onpopstate();
                  })
                }}>{this.mainStore.isBegin ? this.t('main.k092'):this.t('main.k0137')}</span> <RightOutline />
              </div>
            </div>
             )
           }
         })()
       }
       <Popup
          visible={this.state.isShowAddress}
          bodyStyle={{ height: '100%' }}
          >
          {this.t('main.k097')}
          <Address t={this.props.t} onChange={this.choosedAddr}
              isCommonlyAddress={true}
              onCancel={() => {
                  this.props.history.goBack();
              }}
              isBegin={this.mainStore.isBegin} cityCode={this.mainStore.beginAddr.cityCode} cityName={this.mainStore.beginAddr.cityName}
              history={this.props.history}
          ></Address>
        </Popup>
      </div>
    )
  }

  componentDidMount() {
    
  }

  /**
     * 点选地址
     * @param {*} item 
     */
   choosedAddr = (item) => {
    this.props.onClose();
    this.props.history.goBack();
    if(this.mainStore.isBegin) {
      this.mainStore.choosedBeginAddr(item);
      this.props.moveMap([item.lon, item.lat], true, false);
    } else {
      this.mainStore.choosedEndAddr(item)
      if (this.mainStore.beginAddr.lat && this.mainStore.endAddr.lat) {
        this.props.endChange(item)
      }
    }
  }


  combinationFun = (locationName, addressName) => {
    locationName = locationName ? locationName.replace(/\s+/g, '') : '';
    addressName = addressName ? addressName.replace(/\s+/g, '') : '';
    let stationName = this.props.stationName;
    if (stationName.indexOf(locationName) === -1) {
      stationName += '　' + locationName
    }
    if (stationName.indexOf(addressName) === -1) {
      stationName += '　' + addressName
    }
    return stationName
  }


  componentWillUnmount = () => {  
    this.setState = (state,callback)=>{
      return;
    };
  }

}

export default RecommendPath
