import React from 'react'
import style from './mapdev.scss';
import { observer } from 'mobx-react'

import { withTranslation } from "react-i18next";

import IPathMap from './../component/utils/iPathMap'
import webService from '../util/webService';

import axios from 'axios'
import moment from 'moment';

// import { AMapTrack } from './../util/aMapTrack'

@observer
class MapDev extends React.Component {

    state = {
        depart: {
            lat: '',
            lng: ''
        },
        dest: {
            lat: '',
            lng: ''
        },
        distance: 0,
        duration: 0,
        time: 0,
        stationName: '',
        orderId: ''
    }

    // 偏航相关数据
    lastCountYaw = 0    // 最新累积偏航次数
    scores = []         // 历史数据有效性等级

    iPathMapRef = undefined

    render() {
        return <div className={style.container}>
            <div>
                <IPathMap t={this.props.t} ref={(dom) => { this.iPathMapRef = dom ? dom.getMapRef() : undefined }} ></IPathMap>
            </div>
            <div className={style.toolbar}>
                <div className={style.pathInfo}>
                距离：{this.state.distance} 时间：{this.state.time}
                </div>
                <div className={style.btns}>
                    <button onClick={() => {this.sd2()}}>鼠标模拟车辆坐标</button>

                    <button onClick={() => {this.start()}}>启动自动测试</button>
                    <button onClick={() => {this.startOne()}}>调试特定</button>
                    <div className={style.stationbar}>
                        <input type="text" value={this.state.stationName} onChange={(e) => {
                            this.setState({
                                stationName: e.target.value
                            });
                        }}  />
                        <button onClick={() => {this.checkPolygon()}}>验证</button>
                    </div>
                </div>
                <div>
                    <input type="text" value={this.state.orderId} onChange={(e) => {
                        this.setState({
                            orderId: e.target.value
                        });
                    }}  />
                    <button onClick={() => {this.rebuild()}}>重现轨迹</button>
                </div>
                
            </div>
        </div>
    }

    async componentDidMount() {
        await this.iPathMapRef.initMap()
        this.iPathMapRef.setMapOffset({ x: 0, y: -150 })
        window.openQQMapDebug()
        window.localStorage.setItem('ignorevisibilitychange', '1')

        window.openAutoUpdatePostion = false
        window.beginNavigation = async (depart, dest) => {
            await this.beginNavigation(depart, dest)
        }
        window.updatePostion = async (postion) => {
            await this.updatePostion(postion)
        }

        window.vm = new Vm()
        window.addFence = (p) => {this.iPathMapRef.addFence(p)}
    }

    sd2 = async () => {
        window.openAutoUpdatePostion = true
    }

    startOne = async () => {
        this.lastCountYaw = 0
        this.scores = []   

        let {data} = await axios.get('http://127.0.0.1:3001/find/1749882218702938112')
        console.log(data)
        if (!data?.data) {
            return
        }
        // data.data.list.slice(0,5),
        window.vm.init(data.data.depart, data.data.dest, data.data.list,
            async () => {
                console.log(this.scores)
            })
        setTimeout(() => {
            //window.vm.start()
        }, 1500);
    }

    start = async () => {
        this.lastCountYaw = 0
        this.scores = []   

        let {data} = await axios.get('http://127.0.0.1:3001/findNext')
        console.log(data)
        if (!data?.data) {
            return
        }
        // data.data.list.slice(0,5),
        window.vm.init(data.data.depart, data.data.dest, data.data.list,
            async () => {
                await this.save(data.data.coreOrderId)
                this.start()
            })
        setTimeout(() => {
            window.vm.start()
        }, 1500);
        
    }

    save = async (id) => {
        let {data} = await axios.post('http://127.0.0.1:3001/save', {
            coreOrderId: id,
            scores: this.scores && this.scores.length ? this.scores : [1]
        })
        console.log(data)
    }

    isYaw = (res) => {
        if (res) {
            if (res.code === 0) {
                if (this.lastCountYaw === 0) {
                    return
                } else if (this.lastCountYaw < 3) {
                    console.log(2, '两次内自动纠正')
                    this.scores.push(2)
                } else if (this.lastCountYaw < 12) {
                    if (res.newDirection) {
                        console.log(3, '12次内真偏航')
                        this.scores.push(3)
                    } else {
                        console.log(4, '12次内假偏航')
                        this.scores.push(4)
                    }
                } else {
                    console.log(5, '12次后强制重新导航')
                    this.scores.push(5)
                }

                this.lastCountYaw = 0
            } else {
                this.lastCountYaw = res.countYaw === undefined ? 0 : res.countYaw
            }
        }
    }

    updateAnimation = async () => {
        // this.iPathMapRef.addMapDevPoint(this.state.depart)
        let res = await this.iPathMapRef.addRuningAnimation(
            this.state.depart,
            this.state.dest, true, undefined)
        
        if (res && res.code === 0) {
            this.setState({
                distance: res.distance,
                duration: res.duration,
                time: Math.round(res.duration),
            })
        } else {
            console.log(res)
        }
        this.isYaw(res)        
    }

    beginNavigation = async (depart, dest) => {
        this.setState({
            depart: {
                ...depart
            },
            dest: {
                ...dest
            }
        }, async () => {
            await this.updateAnimation()
            this.iPathMapRef.addMapDev(depart, dest)
        })
        
    }

    updatePostion = async (postion) => {
        this.setState({
            depart: {
                ...postion
            }
        }, () => {
            this.updateAnimation()
        })
    }

    checkPolygon = async () => {
        

        let {data} = await axios.post('https://devplatformadminv2.imtpath.net/stationManager/station/find', {
            name: this.state.stationName
        })
        if (data.code === 0 && data.data) {
            console.log(data.data.name)
            this.iPathMapRef.addFence(data.data.polygon)
        }
    }

    rebuild = async () => {
        let orderId = this.state.orderId
        let orderInfo = await webService.order.getBaseInfo({
            orderId: orderId
        })
        let coreOrderId = orderInfo.data.data.baseInfo.coreOrderId
        let {data} = await webService.order.getLocusPoint({
            coreOrderId: coreOrderId,
            start: 0,
            stop: ''
        })
        let list = data.data
        for (let i in list) {
            let item = list[i]
            item.lat = Number(item.lat.substring(0,9))
            item.lng = Number(item.lng.substring(0,10))
            item.time = moment(item.time).unix()
        }
        window.vm.init(list[0], list[list.length - 1], list.slice(1, list.length - 1))
    }
}

class Vm {
    depart = {}
    dest = {}
    list = []
    stepIndex = 0
    ok = undefined
    intervalTime = 5000
    at = undefined
    init = (_depart, _dest, _list, _ok) => {
        this.depart = _depart;
        this.dest = _dest
        this.list = _list;
        this.stepIndex = 0;
        this.ok = _ok
        window.beginNavigation(_depart, _dest)
    }
    next = async () => {
        if (this.stepIndex < this.list.length) {
            let p1 = this.list[this.stepIndex]
            // if (!that.at) {
            //     that.at = new AMapTrack(`${new Date().getTime()}`)
            // }
            // let p2 = await this.at.mapmatch(this.list[this.stepIndex])
            // console.log(p1, p2)
            window.updatePostion(p1)
            
            this.stepIndex ++;
            return true
        } else {
            console.log('轨迹播放完成')
            return false
        }
    }
    start = () => {
        let that = this

        setTimeout(() => {
            if (that.next()) {
                that.start()
            } else {
                // 路径回放结束
                if (this.ok) {
                    this.ok()
                }
            }
        }, that.intervalTime);
    }
}

export default withTranslation()(MapDev);