import React from "react";
import {inject, observer } from 'mobx-react'
import style from "./scenesDetails.scss";
import { withTranslation } from "react-i18next";
import webService from '../util/webService';
// import moment from 'moment'

@inject('globalStore')
@observer
class ScenesDetails extends React.Component {
  state = {
    ReimbursementArr:[],
    scene:[],
    rules: [],
    groupName:''
  };
  t = this.props.t
  globalStore = this.props.globalStore
  
  render() {
    return (
      <div className={style.container}>
        <div className={style.details}>
          <div className={style.name}>{this.state.groupName}</div>
          {this.state.scene.map((item, index)=>{
                if(item.key!=='3012'){
                   return <div key={index}><span>{item.name}：</span><span>{item.value?item.value:this.t('scenes.k004')}</span></div>
                }else{
                    return <div className={style.timelimit} key={index}>
                     <div>
                       <span>{item.name}：</span>
                     </div>
                     <div className={style.timelist}>
                        {item.value.map(position=>{
                            return <span>{this.state.ReimbursementArr[position.carLevel]}：{position.value}{position.reimModel===1?this.t('scenes.k003'):'%'}</span>
                        })}
                     </div>
                    </div>
                }
          })}
          
        </div>
        <div>
          {
            this.state.rules && this.state.rules.length > 0 ? this.state.rules.map((item, index)=>{
              return <div className={style.bordertop} key={index}>
              <div className={style.title}>{item.groupName}</div>
              <div>
                <span>{this.t('scenes.k008')}</span>
                <span>{item.allowDate}</span>
              </div>
              <div className={style.timelimit}>
                <div>
                  <span>{this.t('scenes.k011')}</span>
                </div>
                <div className={style.timelist}>
                  {
                    typeof(item.allowTime) === 'string'?<span>{item.allowTime}</span>:this.renderTime(item.allowTime.rules)
                  }
                </div>
              </div>
              <div>
                {/* 可用城市 */}
                <span>{this.t('scenes.k027')}</span>
                <span>{item.allowCitys}</span>
              </div>
              <div className={style.timelimit}>
                <div>
                  {/* 可用地点 */}
                  <span>{this.t('scenes.k028')}</span>
                </div>
                <div className={style.timelist}>
                  {
                    typeof(item.allowPosition) === 'string'?<span>{item.allowPosition}</span>:this.renderplace(item.allowPosition)
                  }
                </div>
              </div>
              <div>
                {/* 可用模式 */}
                <span>{this.t('scenes.k029')}</span>
                <span>{item.carType}</span>
              </div>
              <div>
                {/* 可用车型 */}
                <span>{this.t('scenes.k030')}</span>
                <span>{item.carLevelNames}</span>
              </div>
              <div>
              {/* 跨城用车 */}
                <span>{this.t('scenes.k031')}</span>
                <span>{item.crossCity}</span>
              </div>
            </div>
        
          }):
            <div className={style.bordertop}>
              <div className={style.title}>{this.t('scenes.k036')}</div>
              <div>
                <span>{this.t('scenes.k008')}</span>
                <span>{this.t('scenes.k037')}</span>
              </div>
              <div className={style.timelimit}>
                <div>
                  <span>{this.t('scenes.k011')}</span>
                </div>
                <div className={style.timelist}>
                  <span>{this.t('scenes.k037')}</span>
                </div>
              </div>
              <div>
                {/* 可用城市 */}
                <span>{this.t('scenes.k027')}</span>
                <span>{this.t('scenes.k037')}</span>
              </div>
              <div className={style.timelimit}>
                <div>
                  {/* 可用地点 */}
                  <span>{this.t('scenes.k028')}</span>
                </div>
                <div className={style.timelist}>
                <span>{this.t('scenes.k037')}</span>
                </div>
              </div>
              <div>
                {/* 可用模式 */}
                <span>{this.t('scenes.k029')}</span>
                <span>{this.t('scenes.k037')}</span>
              </div>
              <div>
                {/* 可用车型 */}
                <span>{this.t('scenes.k030')}</span>
                <span>{this.t('scenes.k037')}</span>
              </div>
              <div>
              {/* 跨城用车 */}
                <span>{this.t('scenes.k031')}</span>
                <span>{this.t('scenes.k038')}</span>
              </div>
            </div>
          }
        </div>
        {
          this.state.rules && this.state.rules.length > 1 ? <div className={style.tips}>{this.t('scenes.k035')}</div> : ''
        }
      </div>
    );
  }
  renderTime(rules){
      return rules.map(time=>{
        return <span>{time.labelText}</span>
      })     
  }
  renderplace(allowPosition){
    return allowPosition.map(item=>{
      return <span>{item}</span>
    })
  } 
  componentDidMount() {
    let id = '';
    if (this.props.match && this.props.match.params && this.props.match.params.id) {
      id = this.props.match.params.id;
    } else {
      id = this.props.sceneId
    }
    this.getSceneDetails(id);
  }
  getSceneDetails(id) {
    webService.scene.getSceneDetails({ sceneId: id }).then(d => {
        if (d.data.code === 0) {
            if (d.data.data && d.data.data.length) {
                let scene=d.data.data[0].rules;
                // console.log(JSON.stringify(scene)+"====================1=");
                scene.forEach(item => {
                    if(item.key==='3012'){
                      item.value=JSON.parse(item.value);
                    }
                    if (item.name === '负责人' && !item.value) {
                      item.value = this.t('scenes.k039')
                    }
                });
                let ruleArr=d.data.data.slice(1,d.data.data.length);
                let rules = ruleArr.map((item) => {
                    // 可用日期
                    let allowDate = this.allowDate(item);
                    // 可用时间
                    let allowTime = this.allowTime(item.rules);
                    // 单次额度
                    let allowOnceMoney = this.allowOnceMoney(item.rules);
                    // 可用城市
                    let allowCitys = this.allowCitys(item.rules);
                    //可用地点
                    let allowPosition = this.allowPosition(item.rules);
                    //可用车型
                    let carLevelNames = this.carLevelNames(item.rules);
                    //可用模式
                    let carType = this.carType(item.rules);
                    //跨城市用车
                    let crossCity = this.crossCity(item.rules);
                    let limit = {
                      groupName: item.groupName,
                      allowDate,
                      allowTime,
                      allowOnceMoney,
                      allowCitys,
                      allowPosition,
                      carLevelNames,
                      crossCity,
                      carType,
                    };
                    return limit;
                  });
                  this.setState({
                    rules : rules,
                    scene : scene,
                    groupName:d.data.data[0].groupName,
                    ReimbursementArr:['',this.t('scenes.k022'),this.t('scenes.k023'),this.t('scenes.k024'),this.t('scenes.k024')]
                  })  
            }
        }
    })
   
  }
  // this.t('scenes.k004') 无限制
  allowDate = function (obj) {
    let rule = this.getRuleByKey(obj.rules, "3003");
    if (!rule || !rule.value) {
      return this.t('scenes.k004')
    }
    if (rule && rule.value && rule.value === '无限-无限') {
      return this.t('scenes.k004')
    }

    return rule.value

    // let beginDate = obj.beginDate? moment(obj.beginDate).format("YYYY-MM-DD"): "无限";
    // let endDate = obj.endDate
    //   ? moment(obj.endDate).format("YYYY-MM-DD")
    //   : "无限";
    // return `${beginDate}至${endDate}`;
  };
  allowTime = function (rules) {
    let weekdayTmpl = this.t('scenes.k012'); // k012: 周一二三四五六日
    let rule = this.getRuleByKey(rules, "3004");
    if (!rule || !rule.value) {
      return this.t('scenes.k004');
    }
    let allowTime = JSON.parse(rule.value);
    if (allowTime.rules.length === 0) {
      return this.t('scenes.k042') // 无可用时间段
    }
    if (allowTime.mode === "weekday") {
      // 周模式
      for (let i in allowTime.rules) {
        let item = allowTime.rules[i]; // k019: 周 k044：次日
        item.labelText = `${this.t('chooseScene.k019')}${weekdayTmpl[item.day]} ${item.b}-${item.b >= item.e ? this.t('scenes.k044') : ''}${item.e}`;
      }
    } else if (allowTime.mode === "holiday") {
      for (let i in allowTime.rules) {
        let item = allowTime.rules[i]; // 工作日 : 周末及法定节假日
        item.labelText = `${item.day === 1 ? this.t('scenes.k013') : this.t('scenes.k041')} ${item.b}-${
          item.e
        }`;
      }
    }
    return allowTime;
  };
  allowOnceMoney = function (rules) {
    let r = this.getRuleByKey(rules, "3005");
    let v = r ? r.value : "";
    return v ? (v === "-1" ? this.t('scenes.k004') : v + this.t('scenes.k003')) : this.t('scenes.k004');
  };
  allowCitys = function (rules) {
    let r = this.getRuleByKey(rules, "3009");
    let v = r ? r.value : "";
    return v ? v : this.t('scenes.k004');
  };
  allowPosition = function (rules) {
    let rule = this.getRuleByKey(rules, "3011");
    if (!rule || !rule.value) {
      return this.t('scenes.k004');
    }
    let addrs = JSON.parse(rule.value);
    if (!addrs || !addrs.length) {
      return this.t('scenes.k004');
    }

    let arr = [];
    for (let i in addrs) {
      let item = addrs[i]; // k018: 单程  k019: 往返 k020: 任意地点 k010: 至
      let labelText = `${item.mode === 1 ? this.t('scenes.k018') : this.t('scenes.k019')} ${
        item.beginAny ? this.t('scenes.k020') : `${item.beginCity}-${item.beginName}`
      } ${this.t('scenes.k010')} ${item.endAny ? this.t('scenes.k020') : `${item.endCity}-${item.endName}`}`;
      arr.push(labelText);
    }

    return arr;
  };
  carLevelNames = function (rules) {
    let _rule = this.getRuleByKey(rules, "3002");
    if (_rule) {
      let carLevels = _rule.value;
      if (carLevels) {
        return this.allowCarLevelToCn(carLevels);
      }
    }
    return this.t('scenes.k004');
  };
  crossCity = function (rules) {
    let _rule = this.getRuleByKey(rules, "3008");
    if (_rule && _rule.value) { // k038: 允许  k043: 禁止
      return JSON.parse(_rule.value) ? this.t('scenes.k038') : this.t('scenes.k043');
    }
    return this.t('scenes.k038');
  };
  carType = function (rules) {
    let _rule = this.getRuleByKey(rules, "3001");
    if (_rule&&_rule.value) {
        let type=_rule.value.split('|')
        type=type.join(',')
        .replace("1", this.t('main.k0175'))
        .replace("20", this.t('main.k0176'))
        .replace("2", this.t('main.k0177'))
        .replace("6", this.t('main.k0178'))
        .replace("7", this.t('main.k0179'))
        // let type = _rule.value?_rule.value
       return type;
    }
    return this.t('scenes.k004');
  };
  getRuleByKey = function (rules, key) {
    if (rules && rules.length) {
      let _rules = rules.filter((a) => a.key === key);
      if (_rules && _rules.length) {
        return _rules[0];
      }
    }
  };
  /**
     * 车型代码转中文
     * @param {*} value 
     * @returns 
     */
   allowCarLevelToCn = (value) => {
    let arr = value.split('|');
    let arr_cn = [];
    for (let i in arr) {
      switch (arr[i] * 1) {
        case 1:
          arr_cn.push(this.t('main.k051'));
          break;
        case 2:
          arr_cn.push(this.t('main.k052'));
          break;
        case 3:
          arr_cn.push(this.t('main.k053'));
          break;
        case 4:
          arr_cn.push(this.t('main.k054'));
          break;
        case 10:
          arr_cn.push(this.t('main.k0147'));
          break;
        default:
            break;
      }
    }
    return arr_cn.toString();
}
}
export default withTranslation()(ScenesDetails);
