import React from "react";

import { inject, observer } from 'mobx-react'

import { Button, Collapse, TextArea, Badge, Toast } from 'antd-mobile'

import {customerServiceTelephone} from './../util/config'
import { serviceStateToCn } from './../util/paramsHelper'
import webService from './../util/webService'

import gc from '../util/globalConfiguration'

import { withTranslation } from "react-i18next";

import images from "../util/images";

import style from './serviceCenter.scss'
@inject('serviceCenterStore')
@observer
class ServiceCenter extends React.Component {
  state = {
    feeList: [],
    attitudeList: [],
    orderInfo: {},
    feeValue: '', // 费用问题
    articleValue: '', // 服务问题
    attitudeValue: '', // 物品遗失
    lostKey: null,
    activeLostKey: '',

    expensesKey: null,
    activeExpensesKey: '',

    serviceKey: null,
    activeServiceKey: '',

    isOtherFee: false,
    isOtherAttitude: false,

    isShowOtherFee: false,
    isShowOtherAttitude: false
  }

  serviceCenterStore = this.props.serviceCenterStore

  t = this.props.t

  // 过滤费用选中
  get feeChecked() {
    return this.state.feeList.filter(item => item.checked)
  }

  // 过滤服务选中
  get attitudeChecked() {
    return this.state.attitudeList.filter(item => item.checked)
  }

  // 费用是否选择其他
  get otherFeeChecked() {
    return this.state.feeList.filter(item => (item.label === '其他' || item.label === 'other') && item.checked)
  }

  // 服务是否选择其他
  get otherAttitudeChecked() {
    return this.state.attitudeList.filter(item => (item.label === '其他' || item.label === 'other') && item.checked)
  }

  get badgeColor() {
    if (gc.process.isMeituan) {
      return '#FFDD00 '
    } else {
      return '#2F7DCD'
    }
  }

  renderFeedback() {
    return <div>
      <div className={style.content}>
        <Collapse activeKey={this.state.activeLostKey}>
          <Collapse.Panel onClick={() => {
            this.setState({
              lostKey: '1',
              activeLostKey: '1',
              expensesKey: null,
              activeExpensesKey: '',
              serviceKey: null,
              activeServiceKey: '',
              feeValue: '',
              articleValue: ''
            }, () => {
              this.claerFeeList()
              this.claerAttitudeList()
            })
          }} key={this.state.lostKey} title={
            <div className={style.article}>
              <img alt="" src={images.article}/>
              {/* 物品遗失 */}
              {this.t('serviceCenter.k001')}
            </div>
          }>
            <div className={style.fee_cell_bule}>{this.t('serviceCenter.k001')}</div>
            <div className={style.main}>
            <TextArea
              onChange={(val) => {
                this.setState({
                  attitudeValue: val
                })
              }}
              value={this.state.attitudeValue}
              showCount={false}
              placeholder={this.t('serviceCenter.k002')} // 请描述遗失物品情况。
              maxLength={100}
              rows={2}
            />
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>
      
      <div className={style.content}>
        <Collapse activeKey={this.state.activeExpensesKey}>
          <Collapse.Panel onClick={() => {
            this.setState({
              lostKey: null,
              activeLostKey: '',
              expensesKey: '2',
              activeExpensesKey: '2',
              serviceKey: null,
              activeServiceKey: '',
              articleValue: '',
              attitudeValue: '',
            }, () => {
              this.claerAttitudeList()
            })
          }} key={this.state.expensesKey} title={
            <div className={style.article}>
              <img alt="" src={images.costMenny}/>
              <span>{this.t('serviceCenter.k003')}</span>{this.feeChecked && this.feeChecked.length > 0 ? <Badge color={this.badgeColor} content={this.feeChecked.length} /> : ''}
            </div>
          }>
            <div>
              <div className={style.fee_list}>
                {
                  this.state.feeList.map((item, index) => {
                    return <div key={index} onClick={() => this.handleFee(item)} className={item.checked ? style.fee_cell_bule : style.fee_cell}>
                      {item.label}
                    </div>
                  })
                }
              </div>
              {
                this.state.isShowOtherFee ?
                <div className={this.state.isOtherFee ? style.show_red : ''}>
                  <TextArea
                    onChange={(val) => {
                      this.setState({
                        feeValue: val
                      })
                    }}
                    value={this.state.feeValue}
                    showCount={false}
                    placeholder={this.t('serviceCenter.k004')} // 请描述您遇到的问题
                    maxLength={100}
                    rows={2}
                  />
                </div> : <></>
              }
              
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>
      
      <div className={style.content}>
        <Collapse activeKey={this.state.activeServiceKey}>
          <Collapse.Panel onClick={() => {
            this.setState({
              lostKey: null,
              activeLostKey: '',
              expensesKey: null,
              activeExpensesKey: '',
              serviceKey: '3',
              activeServiceKey: '3',
              feeValue: '',
              attitudeValue: '',
            }, () => {
              this.claerFeeList()
            })
          }} key={this.state.serviceKey} title={
            <div className={style.article}>
              <img alt="" src={images.serviceScore}/>
              {/* 服务问题 */}
              <span>{this.t('serviceCenter.k005')}</span>{this.attitudeChecked && this.attitudeChecked.length > 0 ? <Badge color={this.badgeColor} content={this.attitudeChecked.length} /> : ''}
            </div>
          }>
            <div>
              <div className={style.fee_list}>
                {
                  this.state.attitudeList.map((item, index) => {
                    return <div key={index} onClick={() => this.handleAttitude(item)} className={item.checked ? style.fee_cell_bule : style.fee_cell}>
                      {item.label}
                    </div>
                  })
                }
              </div>
              {
                this.state.isShowOtherAttitude ? 
                <div className={this.state.isOtherAttitude ? style.show_red : ''}>
                  <TextArea
                    onChange={(val) => {
                      this.setState({
                        articleValue: val
                      })
                    }}
                    value={this.state.articleValue}
                    showCount={false}
                    placeholder={this.t('serviceCenter.k004')} // 请描述您遇到的问题
                    maxLength={100}
                    rows={2}
                  />
                </div> : <></>
              }
              
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>
    </div>
  }

  render(){
    return <div className={style.container}>
      <div className={style.top}>                     
        <div className={style.details}>
          {/* 用车信息 */}
          <p>{this.t('serviceCenter.k006')}</p>
          <div className={style.msg}>
            <span className={style.price}>¥ {this.state.orderInfo.totalAmount}</span>
            <span className={style.type}>{serviceStateToCn(this.state.orderInfo.orderType, this.props.t)}</span>
            {/* 待支付 */}
            {
              this.state.orderInfo.payStatus === false ? <span className={style.pay_type}>{this.t('serviceCenter.k007')}</span> : <></>
            }
          </div>
          <div className={style.tiem}>
            <img alt="" src={images.payTime} />
            <span>{this.state.orderInfo.orderTime}</span>
          </div>
          <div className={style.begin}>
            <span></span>
            <p>{this.state.orderInfo.pickupLocationName}</p>
          </div>
          <div className={style.end}>
            <span></span>
            <p>{this.state.orderInfo.destLocationName}</p>
          </div>
        </div>

        <div className={style.problem_type}>
          {/* 问题类型 */}
          <p><span>*</span>{this.t('serviceCenter.k008')}</p>
          {this.renderFeedback()}
        </div>

        <div className={style.submit}>
          <Button style={
              {
                'background-image': 'var(--button_background_color)',
                '--border-color': 'var(--button_border_color)',
                'background-color': 'var(--button_border_color)',
                'color': 'var(--button_color)'
              }
            } onClick={(e) => {
            e.stopPropagation()
            let complaints = [...this.feeChecked, ...this.attitudeChecked]
            if (complaints.length === 0) {
              // 默认物品遗失
              complaints = [{id: 40, label: '物品遗失', value: 40, level: 1, showInPhone: true}]
            }
            let feedback = this.state.feeValue + this.state.attitudeValue + this.state.articleValue
            if (complaints.length === 0 && !this.state.attitudeValue) {
                Toast.show({
                  content: this.t('serviceCenter.k004'), // 请描述您遇到的问题
                })
            } else if(!feedback && (this.feeChecked.length === 0 && this.attitudeChecked.length === 0)) {
                Toast.show({
                  content: this.t('serviceCenter.k012'), // 请描述您遇到的问题
                })
            } else if(this.otherFeeChecked.length > 0 && !this.state.feeValue) {
              this.setState({
                isOtherFee: true
              })
            } else if (this.otherAttitudeChecked.length > 0 && !this.state.articleValue) {
              this.setState({
                isOtherAttitude: true
              })
            } else {
              const {id} = this.props.match.params;
              // 走评价接口
              let params = {
                orderId: id,
                feedback: feedback,
                isConfirmAbnormal: this.serviceCenterStore.isConfirmAbnormal,
                complaints: complaints,
                type: 2
              }
              this.setFeedback(params)
            }
          }} disabled={false} block color='primary' size='large'>
            {/* 提交 */}
            {this.t('serviceCenter.k009')}
          </Button>
        </div>

      </div>
      <div className={style.contact}>
        <div onClick={()=> {
           window.top.location.href="tel://" + customerServiceTelephone
        }}>
          <img alt="" src={images.customerServiceTwo}/>
          {/* 联系客服： */}
          <span>{this.t('serviceCenter.k010')}{customerServiceTelephone}</span>
        </div>
      </div>
    </div>
  }

  componentDidMount() {
    this.loadOrderDetailsForCSC()
    this.loadFeedbackLabel()
  }

  componentWillUnmount() {
    this.serviceCenterStore.setConfirmAbnormal(false)
  }

  handleFee = (v) => {
    this.state.feeList.forEach(item => {
      if (v.id === item.id) {
        item.checked = !item.checked
      }
      item.show = true
      if ((v.label === '其他' || v.label === 'other') && (item.label === '其他' || item.label === 'other') && !v.checked) {
        this.setState({
          isOtherFee: false
        })
      }
    })

    let checked = this.state.feeList.filter((item) => item.checked);

    if (checked.length > 0) {
      this.setState({
        isShowOtherFee: true
      })
    } else {
      this.setState({
        isShowOtherFee: false
      })
    }

    this.setState({
      feeList: this.state.feeList,
    })
  }

  handleAttitude = (v) => {
    this.state.attitudeList.forEach(item => {
      if (v.id === item.id) {
        item.checked = !item.checked
      }
      item.show = true
      if ((v.label === '其他' || v.label === 'other') && (item.label === '其他' || item.label === 'other') && !v.checked) {
        this.setState({
          isOtherAttitude: false
        })
      }
    })

    let checked = this.state.attitudeList.filter((item) => item.checked);

    if (checked.length > 0) {
      this.setState({
        isShowOtherAttitude: true
      })
    } else {
      this.setState({
        isShowOtherAttitude: false
      })
    }

    this.setState({
      attitudeList: this.state.attitudeList,
    })
  }

  claerFeeList = () => {
    this.state.feeList.forEach(item => {
        item.checked = false
        item.show = false
    })
    this.setState({
      feeList: this.state.feeList,
      isOtherAttitude: false,
      isShowOtherFee: false,
      // isShowOtherAttitude:false,
      feeValue: ''
    })
  }

  claerAttitudeList = () => {
    this.state.attitudeList.forEach(item => {
        item.checked = false
        item.show = false
    })
    this.setState({
      attitudeList: this.state.attitudeList,
      isOtherFee: false,
      isShowOtherAttitude:false,
      // isShowOtherFee: false,
      articleValue: ''
    })
  }

  /**
   * 
   * 获取评价 label
   */
  loadFeedbackLabel = async () => {
    let res = await webService.order.getFeedbackLabels();
    if(res.data.code === 0) {
      res.data.data.complaint.forEach(item => {
        item.children.forEach(val => {
          val.value = val.id
          val.checked = false
        })
      })
      this.setState({
        feeList: res.data.data.complaint[1].children, // 费用问题
        attitudeList: res.data.data.complaint[2].children // 服务问题
      })
    }
  }

  /**
   * 
   * 获取订单信息
   */
  loadOrderDetailsForCSC = async () => {
    const {id} = this.props.match.params;
    let {data} = await webService.order.getOrderDetailsForCSC({orderId:id});
    if (data.code === 0) {
      this.setState({
        orderInfo: data.data
      })
    }
  }

  /**
   * 
   * 投诉
   */
  setFeedback = async (params) => {
    const {id} = this.props.match.params;
    let {data} = await webService.order.setFeedback(params)
    if (data.code === 0) {
      Toast.show({
        content: this.t('serviceCenter.k011'),
      })
      setTimeout(() => {
        this.props.history.replace(`/complaintsDetails/${id}`)
      }, 1000)
    } else {
      Toast.show({
        content: data.message,
      })
    }
  }
}

export default withTranslation()(ServiceCenter)
