import { observable, action, makeObservable, computed } from "mobx";

class OrderEvaluateStore {
  
  constructor() {
    makeObservable(this)
  }
  

  // 投诉进度
  @observable progress = ''
  // 收费相关投诉
  @observable chargeRelevantList = []
  // 服务相关投诉
  @observable serviceRelevantList = []
  // 其他相关投诉
  @observable otherList = []
  // 详情数据
  @observable feedbackInfo = {
    // 评价详情页 id
    orderId: null,
    // 头像
    avatarUrl: '',
    // 车牌号
    vehicleNo: '',
    // 车类型颜色
    carType: '',
    // 评分
    score: null,
    // 评价内容
    feedback: '',
    // 投诉内容
    complaintsMessage: '',
    // 投诉受理状态
    state: null
  }
  
  // 收费投诉
  @observable unreasonableList = [
  ]

  // 服务投诉
  @observable attitudeList = [
  ]

  // 司机信息
  @observable driverInfo = null

  // 判断是否展示投诉部分
  @computed get hasComplaint() {
    let flag = false
    if (this.feedbackInfo.score <= 3 && !this.feedbackInfo.feedback) {
      flag = true
    } else {
      flag = false
    }
    return flag
  }

  // 判断评分大于 3 分 或者选中投诉标签则按钮可点击
  @computed get dissatisfied() {
    let isDisabled = true
    if (this.feedbackInfo.score > 3 || this.chargeRelevantList.length > 0 || this.serviceRelevantList.length > 0 || this.otherList.length > 0) {
      isDisabled = false
    } else {
      isDisabled = true
    }
    return isDisabled
  }

  // 获取评价 id
  @action.bound setDetailsId(id) {
    this.feedbackInfo.orderId = id
  }

  // 获取评分
  @action.bound setGradeValue(val) {
    this.feedbackInfo.score = val
  }

  // 获取评价内容
  @action.bound setTextareaValue(val) {
    this.feedbackInfo.feedback = val
  }

  // 获取投诉相关
  @action.bound setChargeRelevant(item) {
    this.chargeRelevantList = item
  }

  // 获取服务相关投诉
  @action.bound setServiceRelevant(item) {
    this.serviceRelevantList = item
  }

  // 获取其他相关投诉
  @action.bound setOtherList(item) {
    this.otherList = item
  }

  // 获取评价数据
  @action.bound setFeedbackInfo(feedbackInfo) {
    this.feedbackInfo = feedbackInfo
  }
  
  // 设置收费不合理 label
  @action.bound setUnreasonableList(list) {
    this.unreasonableList = list
  }

  // 设置司机服务不好
  @action.bound setAttitudeList(list) {
    this.attitudeList = list
  }

  //设置司机信息
  @action.bound setDriverInfo(driverInfo) {
    this.driverInfo = driverInfo
  }
  
}

export default new OrderEvaluateStore()