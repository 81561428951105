import React from 'react';
import {inject, observer } from 'mobx-react'
import style from './callcar.scss'

import moment from 'moment';

import images from './../../util/images'

import { Popup, CascadePicker, Toast } from 'antd-mobile'

import webService from './../../util/webService'

@inject('mainStore', 'globalStore')
@observer
class Flight extends React.Component {
    state = {
        showFlightNumber: false,
        showFlights: false,
        flightKey: '',
        flights: []

    }
    mainStore = this.props.mainStore;

    globalStore = this.props.globalStore


    t = this.props.t

    render() {
        return <div className={style.line} >
            <div className={style.icon}>
            <img src={images.flightBtn} alt="" />
            </div>
            <div className={`${style.content} ${style.sence}`} onClick={() => {}} >
                <div className={style.time} onClick={() => {
                    this.setState({
                        showFlightNumber: true
                    });
                }}>
                    { this.mainStore.beginAddr.flightNumber }
                    {
                        (() => {
                            if (this.mainStore.beginAddr.airTip) {
                                return <div className={style.tip} >{ this.mainStore.beginAddr.airTip }</div>
                            }
                        })()
                    }
                    {
                        (() => {
                            if (!this.mainStore.beginAddr.flightNumber) {
                                return <div className={style.where} >{this.t('main.k066')}</div>
                            }
                        })()
                    }
                    
                </div>

                <div className={style.more}><img src={images.approveSee} alt="" /></div>
            </div>

            <Popup
                visible={this.state.showFlightNumber}
                onMaskClick={() => {
                    this.setState({
                        showFlightNumber: false
                    });
                }}
                bodyStyle={{ minHeight: '20vh' }}
            >
                {/* 航班搜索框 */}
                <div className={style.air}>
                    <div className={style.title}>{this.t('main.k067')}</div>
                    <div className={`${style.ok} ${!this.existFlightKey ? style.disable : ''}`} onClick={() => { this.loadFlight(this.state.flightKey); }}>{this.t('main.k068')}</div>
                    <div className={style.tip}>{this.t('main.k069')}</div>
                    <div className={style.input}>
                    <input type="text" value={this.state.flightKey} onChange={(e) => {
                        this.setState({
                            flightKey: e.target.value
                        });
                    }}  />
                    </div>
                </div>
            </Popup>
            <CascadePicker 
                cancelText={this.t('common.k001')} 
                confirmText={this.t('common.k002')}
                title={this.t('main.k070')}
                options={this.state.flights}
                visible={this.state.showFlights}
                getContainer={() =>
                  document.getElementsByClassName('_3_aZY1dZwn9eFUNIzWJpzH')[0]
                }
                onClose={() => {
                    this.setState({
                        showFlights: false
                    })
                }}
                onConfirm={val => {
                    let ori = val[1];
                    this.mainStore.choosedFlight(ori);
                    this.props.moveMap(ori.arrivalAirportCoord.split(','));                    
                    this.props.onChange();
                }}
                
            />
        </div>
    }

    get existFlightKey() {
        return this.state.flightKey ? true : false
    }

    loadFlight = (flightNumber) => {
        if (!flightNumber) {
            return
        }
        Toast.show({
            icon: 'loading',
            content: this.t('main.k076'),
            duration: 0,
            maskClickable: false
        })
        webService.basCity.getFlightInfo({ flightNumber: flightNumber, lang: this.globalStore.userInfo.language === 1 ? 'zh' : 'en' }).then(d => {
            Toast.clear();
            if (d.data.code === 0) {
                if (d.data.data && d.data.data.rows && d.data.data.rows.length) {
                    let list = [];
                    let rows = d.data.data.rows;
                    // 过滤不可用班次
                    for (let i in rows) {
                        let item = rows[i];
                        if (this.globalStore.userInfo.language === 1) {
                          item.day = moment(item.flights[0].planDepartTime).format('MM月DD日') + this.t('main.k071') + '日一二三四五六七'[moment(item.flights[0].planDepartTime).weekday()];
                        } else {
                          let month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][moment(item.flights[0].planDepartTime).month()]
                          let day = moment(item.flights[0].planDepartTime).format('DD').replace('0', '')
                          let week = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'][moment(item.flights[0].planDepartTime).weekday()]
                          // eslint-disable-next-line
                          item.day = month + ' ' + day + 'th' + ' ' + this.t('main.k071') + week;
                        }
                        item.flights = item.flights.filter(a => {return moment(a.planArrivalTime).valueOf() > moment().valueOf()});
                    }
                    rows = rows.filter(a => {return a.flights.length > 0});

                    let new_rows = [];
                    for(let i in rows) {
                        if (new_rows[rows[i].day]) {
                            new_rows[rows[i].day].flights = [
                                ...new_rows[rows[i].day].flights,
                                ...rows[i].flights
                            ]
                        } else {
                            new_rows[rows[i].day] = rows[i]
                        }
                    }
                    rows = [];
                    for(let i in new_rows) {
                        let item = new_rows[i];
                        if (item && item.day) {
                            rows.push(item)
                        }
                    }

                    for (let i in rows) {
                        let item = {
                            label: rows[i].day,
                            value: rows[i].day,
                            children: []
                        };
                        for (let j in rows[i].flights) {
                            item.children.push({
                                label: rows[i].flights[j].time,
                                value2: rows[i].flights[j].time,
                                value: rows[i].flights[j]
                            });
                        }                                        
                        list.push(item);                                        
                    }
                    this.setState({
                        showFlightNumber: false,
                        showFlights: true,
                        flights: list
                    })
                } else {
                    Toast.show({
                        icon: 'fail',
                        content: this.t('main.k073')
                    })
                }
                

            } else {
              Toast.show({
                icon: 'fail',
                content: this.t('main.k073')
              })
            }
        }).catch(() => {
            Toast.show({
                icon: 'fail',
                content: this.t('main.k073')
            })
        });
    }
}

export default Flight;