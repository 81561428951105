import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'mobx-react'
import "lib-flexible"

import store from './store'
import webService from './util/webService'
import { dingLogin } from './util/webService'
import { webBaseUrl, oauthType, appId } from './util/config'
import { decryptAES_ECB_PKCS7Padding } from './util/aesHelper'
// import { mountConsoleMonitor, unmountConsoleMonitor } from './util/h5logHelper'
import { cacheCitys } from './util/citys'
import { locationReplace } from './util/utils'
import loger from './util/loger'
import { envName } from './util/variable'

import gc from './util/globalConfiguration'

import "./react-i18next/i18n";

import eruda from 'eruda'

// mountConsoleMonitor();
// window.unmountConsoleMonitor = unmountConsoleMonitor
// 城市缓存一级
cacheCitys();

if (['uat', 'test'].includes(envName)) {
  eruda.init()
}


// import { dge } from './util/utils'
// let ua = dge();

const renderReact = () => {
  ReactDOM.render(
    <BrowserRouter forceRefresh={true}>
      <React.StrictMode>
        <Provider {...store}>
          <App />
        </Provider>

      </React.StrictMode>
    </BrowserRouter>
    ,
    document.getElementById('root')
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}

// 判断是否登录授权回调
(async () => {
  if (['17'].includes(oauthType)) {
    let _satoken = localStorage.getItem('satoken')
    if (_satoken) {
      sessionStorage.setItem('satoken', _satoken);
      window.satoken = sessionStorage.getItem('satoken');
    }
  }


  let { search } = window.location;
  let query = {};
  if (search) {
    let _search = search.substr(1);
    if (_search) {
      let params = _search.split('&');
      params.forEach(element => {
        if (element.indexOf('=') > 0) {
          let arr = element.split('=');
          if (arr && arr.length >= 2) {
            query[arr[0]] = element.substring(arr[0].length + 1);
          }
        }
      });
      if (query.log) {
        // 只有一个日志等级参数的时候
        loger.setLevel(Number(query.log))
      }
      if (params.length === 1 && query.hasOwnProperty('log')) {
        // 如果只有一个url参数，并且是日志等级参数，则清空query对象属性
        query = {}
      } else if (params.length === 1 && query.hasOwnProperty('pass_ticket')) {
        query = {}
      } else {
        // 如果get参数中没有，就从uri中获取
        query.oauth = query.oauth || oauthType
      }
      
      
    }
  }

  if (oauthType === '13') {
    query.oauth = oauthType
  }

  if (oauthType === '8' && query.from && !query.token) {
    query = {}
  }

  console.log(query)
  let ori_hash = window.location.hash;
  if (ori_hash) {
    if (ori_hash.indexOf('#/applink/') === 0) {
      let arr = ori_hash.split('/');
      let satoken = arr[2]
      let hash = '#/' + arr.slice(3).join('/')
      locationReplace(webBaseUrl + '?oauth=10000&satoken=' + satoken + hash)
      // window.location.replace(webBaseUrl + '?oauth=10000&satoken=' + satoken + hash);
      return;
    }
  }

  /**
   * oauth 枚举
   * 1：企业微信（商城/第三方应用）
   * 2：飞书（企业商城版）
   * 3：石油商旅
   * 4：中国移动
   * 5：其它open auth登录方式
   * 6：中电建（AMAP Auth）
   * 7：E多（参天）
   * 8：open api登录（曼斯特）
   * 11：企业微信（自建应用）
   * 12：微信服务号
   * 13：钉钉自建应用
   * 14：汇联易
   * 15: 京东小程序
   * 16: 武田
   * 17: 强生
   * 18: 辉瑞
   * 19: 赛诺菲
   * 10101：小程序
   * 20：强生有料
   */
  if (query.oauth) {
    let res = { data: { code: 1 } };

    if (['1', '2', '11', '12'].includes(query.oauth)) {
      // 飞书，企业微信登录
      loger.info('登录', '', `已得到授权码，即将获取用户登录授权信息 ==> code = ${query.code},`)
      res = await webService.user.login(query.code);
      if (gc.process.isJindu) {
        if(res.data.data.url) {
          window.location.replace(res.data.data.url)
          return
        }
      }
    } else if (query.oauth === '10101') {
      // 小程序
      loger.info('登录', '', `已得到授权码，即将获取用户登录授权信息 ==> code = ${query.code},`)
      res = await webService.user.loginMiniProgramWithCode(query.code);
    } else if (query.oauth === '3') {
      // 3：石油商旅
      loger.info('登录', '', `已得到授权码，即将获取用户登录授权信息 ==> token = ${query.token},`)
      res = await webService.user.loginByChinaPetro(query.token);
    } else if (query.oauth === '4') {
      // 4：中国移动
      loger.info('登录', '', `已得到授权码，即将获取用户登录授权信息 ==> token = ${query.token},`)
      res = await webService.user.loginByCMCC(query.token);
    } else if (query.oauth === '5') {
      // 5：其它open auth登录方式
      loger.info('登录', '', `已得到授权码，即将获取用户登录授权信息 ==> token = ${query.token},`)
      res = await webService.user.loginWithToken(query.token);
    } else if (query.oauth === '6') {
      // 6：中电建（AMAP Auth）
      loger.info('登录', '', `此渠道直接可以获取到授权信息 ==> satoken = ${query.token},`)
      res = {
        data: {
          code: 0,
          data: {
            satoken: query.token
          }
        }
      }
    } else if (query.oauth === '7') {
      // 7：E多（参天）
      if (!query.userid || !appId) {
        res.data.message = '登录失败！您不具备访问本项目的权限';
      } else {
        loger.info('登录', 'E多', `已经获取到加密后userid ==> userid = ${query.userid},`)
        let _userid = decodeURIComponent(query.userid);
        loger.info('登录', 'E多', `解密userid完成 ==> _userid = ${_userid},`)
        res = await webService.user.loginWithUserIdAndCompanyId(_userid, appId);
        if (res.data.code === 0 && res.data.data.aesKey) {
          loger.info('登录', 'E多', `获取企业解密密钥成功 ==> aesKey = ${res.data.data.aesKey}`)
          let originalUserId = decryptAES_ECB_PKCS7Padding(_userid, res.data.data.aesKey)
          loger.info('登录', 'E多', `解密originalUserId成功 ==> originalUserId = ${originalUserId}`)
          res = await webService.user.loginWithUserIdAndCompanyId(_userid, appId, originalUserId);
        }
      }
    } else if (query.oauth === '8') {
      // 8：open api登录（曼斯特）
      loger.info('登录', '', `已得到授权码，即将获取用户登录授权信息 ==> token = ${query.token},`)
      res = await webService.user.loginByOpenApiToken(query.token);
    } else if (query.oauth === '13') {
      // 钉钉登录
      try {
        res = await dingLogin()
        if (res.data.code === 0) {
          // 登录成功
          window.satoken = res.data.data.satoken
          loger.info('登录', '登录成功', `${window.navigator.userAgent}`)
          sessionStorage.clear();
          sessionStorage.setItem('satoken', res.data.data.satoken);
          // 重新设置日志等级
          loger.setLevel(loger.level)
          localStorage.setItem('satoken', res.data.data.satoken);
          // 钉钉直接登录不进行页面刷新，因为钉钉App7.0以后不支持 Fuck！
          renderReact();
          return
        }
        
      } catch (e) {
        res = {
          data: {
            code: 1,
            message: e
          }
        }
      }
    } else if (query.oauth === '14') {
      // 14：汇联易
      loger.info('登录', '', `已得到授权码，即将获取用户登录授权信息 ==> token = ${query.token},`)
      res = await webService.user.loginByOpenApiToken(query.token);
    } else if (query.oauth === '15') {
      // 15：京东小程序
      loger.info('登录', '', `此渠道直接可以获取到授权信息 ==> satoken = ${query.token},`)
      res = {
        data: {
          code: 0,
          data: {
            satoken: query.token,
            jd_scene: query.jd_scene
          }
        }
      }
    } else if (query.oauth === '16') {
      // 武田自建应用
      let _code = query.code;
      let _errcode = Number(query.errcode);
      let _errmsg = query.errmsg;
      if (_errcode === 0) {
        res = await webService.user.loginByTakeda(appId, _code);
      } else {
        alert(decodeURI(_errmsg))
        return
      }
    } else if (query.oauth === '17') {
      // 强生
      let _code = query.code;
      if (_code) {
        res = await webService.user.loginByJnj(appId, _code);
      } else {
        alert('认证失败')
        return
      }

    } else if (query.oauth === '18') {
      // 辉瑞
      let _ticket = query.ticket;
      if (_ticket) {
        res = await webService.user.login(_ticket);
      } else {
        console.log('辉瑞登录失败', query)
        alert('辉瑞登录失败')
      }
    } else if (query.oauth === '19') {
      // 赛诺菲
      res = await webService.user.login(query.code);
      
    } else if (query.oauth === '20') {
      // 强生有料
      let _accessToken = query.accessToken;
      if (_accessToken) {
        res = await webService.user.loginByJnjApp(appId, _accessToken);
      } else {
        alert('认证失败')
        return
      }
    } else {
      // 其它渠道（后门，直接用satoken登录）
      res = {
        data: {
          code: 0,
          data: {
            satoken: query.satoken
          }
        }
      }
    }

    loger.info('登录', '登录结果', `已得到用户登录授权信息`, res.data)
    if (res.data.code === 10101 && res.data.data.openID) {
      // 小程序未绑定，转到绑定页面
      sessionStorage.setItem('openId', res.data.data.openID);
      // 小程序未绑定时候，转入绑定页面之前，清空上次离开页面的标记
      sessionStorage.setItem('hash', '');
      let hash = '#/login/' + res.data.data.openID;
      locationReplace(webBaseUrl + hash);
      //window.location.replace(webBaseUrl + hash);
    } else if (res.data.code === 0) {
      // 登录成功，转到首页
      window.satoken = res.data.data.satoken
      if (query.oauth === '15') {
        localStorage.setItem('jd_scene', res.data.data.jd_scene);
      }
      loger.info('登录', '登录成功', `${window.navigator.userAgent}`)
      let hash = window.location.hash ? window.location.hash : sessionStorage.getItem('hash');
      let fromMeetingLogin = sessionStorage.getItem('fromMeetingLogin')
      sessionStorage.clear();
      sessionStorage.setItem('fromMeetingLogin', fromMeetingLogin || '0')
      sessionStorage.setItem('satoken', res.data.data.satoken);
      // 重新设置日志等级
      loger.setLevel(loger.level)
      localStorage.setItem('satoken', res.data.data.satoken);
      hash = hash ? hash : '#/';
      let replaceUrl = ''
      if (webBaseUrl.endsWith('/')) {
        replaceUrl = webBaseUrl
      } else {
        replaceUrl = webBaseUrl + '/'
      }
      let _report = {
        type: 2,
        path: window.location.hash
      }

      console.log('登陆成功', _report)
      webService.user.saveUserLog(_report);
      locationReplace(replaceUrl + hash);
      //window.location.replace(replaceUrl + hash);
      if (query.oauth === '8') {
        renderReact();
      }
      

    } else {
      alert(res.data.message)
    }
  } else {
    let hash = sessionStorage.getItem('hash');
    if (hash) {
      sessionStorage.setItem('hash', '');
      setTimeout(async () => {
        locationReplace(webBaseUrl + hash);
        //window.location.replace(webBaseUrl + hash);
        renderReact();
      }, 200)
    } else {
      // 渲染react页面
      renderReact();
    }
  }
})()
