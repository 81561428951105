import React from 'react'
import style from './ordernewdetails.scss'
import {inject, observer } from 'mobx-react'

import { Collapse, Toast, TextArea, Dialog } from 'antd-mobile'
import { RightOutline,ExclamationCircleFill } from 'antd-mobile-icons'

import moment from 'moment'
import images from '../util/images'
import LoadingMask from './../component/utils/loadingMask';
import webService from './../util/webService'
import { dge } from './../util/utils'
import { orderStateToCn, serviceStateToCn } from './../util/paramsHelper'

import {customerServiceTelephone} from './../util/config'
import IPathMap from './../component/utils/iPathMap'
// import QQMap from './../component/utils/qqMap'
import { withTranslation } from "react-i18next";
import { appId } from "./../util/globalConfiguration"

import { getPosition4Element } from './../util/geo'

@inject('globalStore')
@observer
class OrderDetails extends React.Component {

  state = {
    // 标记
    active: '',
    // 用车理由
    reasonVal: '',
    // 控制用车事由弹窗展示
    isVisible: false,
    // 详情数据
    orderInfo: {},
    // 费用明细
    costInfo: [],
    // 审批日志数据
    auditData: [],
    // 增值服务
    extraServices: [],
    // 预付费相关
    billMain: {},
    // 消费抵扣金
    deductionCn: {},
    coupon: {},
    // 控制用车事由弹窗是否可编辑
    isDisabled: false,
    // 判断是否为大额预付
    isPrepayment: false,
    // 判断是否退款
    isRefund: null,
    refundMoney: null,
    individualNeedPay: null,
    showLoding: true,
    abnormalRules:[], //异常规则
    isShow: false,
    polyline: [],
    locationGeo: {
      lat: '',
      lon: '',
      name: ''
    }

  }

  globalStore = this.props.globalStore

  t = this.props.t
  iPathMapRef = undefined
  // map = undefined

  // 真实路径
  get isPathReal() {
    return (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.orderdetails_real_path)
  }

  // 真实路径动画
  get isPathRealAnimation() {
    return this.globalStore.userInfo.permissions && (this.globalStore.userInfo.permissions.orderdetails_real_path && this.globalStore.userInfo.permissions.orderdetails_real_path_animation)
  }

  // 用车事由权限
  get isChangeReason() {
    return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.orderdetails_change_reason
  }

  // 判断如果真实位置有则显示真实位置终点名称没有则显示下单终点名称
  get destLocationName() {
    if (this.state.locationGeo.lat && this.state.locationGeo.lon) {
      return this.state.locationGeo.name
    } 
    return this.state.orderInfo.destLocationName
  }

  // 企业支付金额
  get isCompanyBearAmount() {
    return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.orderdetails_company_bear_amount ? false : true
  }

  // 个人支付金额
  get isUserBearAmount() {
    return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.orderdetails_user_bear_amount ? false : true
  }

  // 详情总金额展示
  get totalAmount() {
    if (['zhonghai', 'XCX032424'].includes(appId)) {
      return this.state.orderInfo.userBearAmount ? this.state.orderInfo.userBearAmount : 0
    }
    return this.state.orderInfo.totalAmount ? this.state.orderInfo.totalAmount : 0
  }

  // 用车场景展示隐藏
  get isSceneNameCn() {
    return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.orderdetails_hide_scene ? false : true
  }

  // 用车场景code展示隐藏
  get isSceneCode() {
    return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.orderdetails_hide_scene_code ? false : true
  }

  // 隐藏费用明细
  get isExpenseDetails() {
    return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.orderdetails_hide_expense_details ? false : true
  }

  /**
   *
   *初始化行程
   */
  renderMap() {
    return(
      <div className={style.map}>
        {/* 地图 */}
        <IPathMap ref={(dom) => { this.iPathMapRef = dom ? dom.getMapRef() : undefined }} className={style.amapContainer}></IPathMap>
        {/* <QQMap ref={(dom) => { this.qqMapRef = dom }} className={style.amapContainer}></QQMap> */}
        {/* <div className={style.amapContainer} id="amap_container"></div> */}
      </div>
    )
  }

  /**
   *
   *订单详情
   */
  renderOrderDetails = ()=> {
    return(
      <div className={style.orderdetails}>
        {/* 金额 */}
        <div className={style.pricebox}>
          <div className={style.cell}>
            <div><span>{this.totalAmount}</span>{this.t('orderdetails.k001')}</div>
            <div className={style.carSources}>{this.state.orderInfo.carSources}</div>
            {this.state.orderInfo.isAbnormal?<div className={style.warn} onClick={()=>{
              Dialog.alert({
                title:this.t('orderdetails.k037'),
                confirmText: this.t('common.k003'),
                content: (
                  <>
                  {
                    this.state.abnormalRules.map((item,idx)=>{
                     return <div key={idx}>{idx+=1}、{item}</div>
                    })
                  }
                  </>
                ),

              })
            }}>{this.t('orderdetails.k034')} <ExclamationCircleFill /></div>:''}
          </div>
        </div>
        { 
            (() => {
              if (this.state.billMain && this.state.billMain.individualPayState === 1 && this.state.isPrepayment) {
                return(
                  <div className={style.cellBtn}>
                    <div className={style.finish} onClick={() => {
                      this.props.history.push('/payments')
                    }}>
                      {/* 行程结束，点击支付剩余金额 */}
                      {this.t('orderdetails.k002')} <RightOutline />
                    </div>
                  </div>
                )
              }
              else if(this.state.billMain && (this.state.billMain.individualPayState === 2 || this.state.billMain.individualPayState ===3) && this.state.isPrepayment) {
                // 判断退款 && 个人部分已支付
                if (this.state.isRefund && !this.state.individualNeedPay) {
                  // 预付费剩余金额已发起退款
                  return(
                    <div className={style.cellBtn}>
                      <div className={style.prepaid} onClick={() => {
                        this.props.history.push(`/prepaidProgress/${this.props.match.params.id}`)
                      }}>
                        {/* 已成功支付预付费金额 */}
                        {this.t('orderdetails.k032')} <RightOutline />
                      </div>
                    </div>
                  )
                } 
                // （没退款完全抵扣 || 退款金额为 0) && 个人部分已支付
                else if ((!this.state.isRefund || this.state.refundMoney === 0) && !this.state.individualNeedPay) {
                  // 行程结束，已成功抵扣
                  return(
                    <div className={style.cellBtn}>
                      <div className={style.prepaid} onClick={() => {
                        this.props.history.push(`/prepaidProgress/${this.props.match.params.id}`)
                      }}>
                        {/* 已成功支付预付费金额 */}
                        {this.t('orderdetails.k033')} <RightOutline />
                      </div>
                    </div>
                  )
                } 
              } else if (!this.state.billMain && this.state.isPrepayment) {
                  if (this.state.orderInfo.state <= 4 ) {
                    return(
                      <div className={style.cellBtn}>
                        <div className={style.prepaid} onClick={() => {
                          this.props.history.push(`/prepaidProgress/${this.props.match.params.id}`)
                        }}>
                          {/* 已成功支付预付费金额 */}
                          {this.t('orderdetails.k003')} <RightOutline />
                        </div>
                      </div>
                    )
                  } else {

                    return(
                      <div className={style.cellBtn}>
                        <div className={style.prepaid} onClick={() => {
                          this.props.history.push(`/prepaidProgress/${this.props.match.params.id}`)
                        }}>
                          {/* 预付费剩余金额已发起退款 */}
                          {this.t('orderdetails.k032')} <RightOutline />
                        </div>
                      </div>
                    )
                  }
              }
            })()
          }
        {
          this.showUseCarReason || this.showOrderEvaluate 
          ? <div className={style.operation}>
            {
              this.showUseCarReason && !this.isChangeReason
                ? <span onClick={this.useCarReason}>
                  {this.t('orderdetails.k004')}&gt;
                </span> 
                : <></>

            }
            
            {
              this.showOrderEvaluate
                ? <span onClick={()=> {
                  if (this.state.orderInfo.state !== 7) {
                    this.props.history.push(`/orderevaluate/${this.state.orderInfo.orderId}`)
                  }
                }}>
                  {this.t('orderdetails.k005')}&gt;
                </span>
                : <></>
            }
            
          </div>
          : <></>
        }
        
        {/* 详情 */} 
        <div className={style.msg}>
          <p>{this.t('orderdetails.k006')}{this.state.orderInfo.id}</p>
          <p>{this.t('orderdetails.k007')}{this.state.orderInfo.departTime ? moment(this.state.orderInfo.departTime).format('YYYY-MM-DD HH:mm:ss') : ''}</p>
          {
            (() => {
              if(this.isSceneNameCn) {
                return <p>{this.t('orderdetails.k035')}{this.state.orderInfo.sceneNameCn}</p>
              }
            })()
          }
          {
            (() => {
              if(this.isSceneCode) {
                return <p>{this.t('orderdetails.k036')}{this.state.orderInfo.sceneCode}</p>
              }
            })()
          }
          {
            (() => {
              if (this.state.orderInfo.travelDistance) {
                if (this.state.orderInfo.travelDistance > 1) {
                  return <p>{this.t('orderdetails.k008')}{this.state.orderInfo.travelDistance}{this.t('orderdetails.k009')}</p>
                } else {
                  return <p>{this.t('orderdetails.k008')}{(this.state.orderInfo.travelDistance * 1000)}{this.t('orderdetails.k010')}</p>
                }
              }
            })()
          }
          {
            (() => {
              if(this.state.orderInfo.duration) {
                return <p>{this.t('orderdetails.k011')}{this.state.orderInfo.duration}</p>
              }  
            })()
          }
          <p>{this.t('orderdetails.k012')}{serviceStateToCn(this.state.orderInfo.serviceType, this.t, this.state.orderInfo.charteredBusType)}</p>
          {this.state.orderInfo.vehicleNo ? <p>{this.t('orderdetails.k013')}{this.state.orderInfo.vehicleNo}{this.state.orderInfo.vehicleModel}</p> : ''}
          
          {
            (() => {
              if (this.isCompanyBearAmount) {
                if(this.state.orderInfo.companyBearAmount) {
                  return <p>{this.t('orderdetails.k014')}{this.state.orderInfo.companyBearAmount ? this.state.orderInfo.companyBearAmount + this.t('orderdetails.k001') : ''}</p>
                }else if(this.state.orderInfo.state===1){
                  return <p>{this.t('orderdetails.k014')}{this.state.orderInfo.companyBearAmount ? this.state.orderInfo.companyBearAmount + this.t('orderdetails.k001') : '0'+ this.t('orderdetails.k001') }</p>
                }
              }
            })()
          }
          {
            (() => {
              if (this.isUserBearAmount) {
                if (this.state.orderInfo.userBearAmount) {
                  return(
                    <p>{this.t('orderdetails.k015')}{this.state.orderInfo.userBearAmount ? this.state.orderInfo.userBearAmount + this.t('orderdetails.k001') : ''}</p>
                  )
                }else if(this.state.orderInfo.state===1){
                   return <p>{this.t('orderdetails.k015')}{this.state.orderInfo.userBearAmount ? this.state.orderInfo.userBearAmount + this.t('orderdetails.k001') :  '0'+ this.t('orderdetails.k001') }</p>
                }
              }
            })()
          }
          {/* 动态数据：预算代码 */}
          {
            this.state.orderInfo.budgetCode.map(item=>{
              return <p key={item.name}><span>{item.name}：</span><span>{item.value}</span></p>
            })
          }
        </div>
      </div>
    )
  }

  /**
   *
   *费用企业相关
   */
  renderCollapseDetails = () => {
    return(
      <div className={style.collapse}>
        { 
        //eslint-disable-next-line
          this.state.costInfo && this.state.costInfo.length > 0  || this.state.auditData && this.state.auditData.list && this.state.auditData.list.length > 0 ? <Collapse defaultActiveKey={this.state.active}>
          {
            this.isExpenseDetails && this.state.costInfo && this.state.costInfo.length > 0 ? <Collapse.Panel key='1' title={this.t('orderdetails.k016')}>
              {
                <div className={style.service_box}>
                  {
                  this.state.costInfo && this.state.costInfo.map((item, index) => {
                    return (
                        <div className={style.box_expenses} key={index}>
                          <div>
                            <p key={index}>
                              {item.customfeesDisplayName ? item.customfeesDisplayName : item.feeName}: <em className={item.billType === 10011 ? style.marking : ''}>{item.consumeMoney ? item.consumeMoney + this.t('orderdetails.k001') : ''}</em>
                              </p>
                            {
                              item.billType === 10011 ? <div className={style.housekeeper}>{this.t('orderdetails.k040')}</div> : <></>
                            }
                          </div>
                          {
                            // (()=> {
                            //   if(item.billType === 10011) {
                            //     if (!this.state.isShow) {
                            //       return (
                            //       <div onClick={() => {
                            //         this.setState({
                            //           isShow: true
                            //         })
                            //       }}>
                            //         {/* k038 查看说明 */}
                            //         <em>{this.t('orderdetails.k038')}</em>
                            //         <Image
                            //           src={images.downArrowhead}
                            //           width={10}
                            //           height={7}
                            //           fit='cover'
                            //         />
                            //       </div>
                            //       )
                            //     } else {
                            //       return (
                            //       <div onClick={() => {
                            //         this.setState({
                            //           isShow: false
                            //         })
                            //       }}>
                            //         {/* k039 收起说明 */}
                            //         <em>{this.t('orderdetails.k039')}</em>
                            //         <Image
                            //           src={images.upArrowhead}
                            //           width={10}
                            //           height={7}
                            //           fit='cover'
                            //         />
                            //       </div>
                            //       )
                            //     }
                            //   }
                            // })()
                          }
                        </div>
                    )
                  })
                  }
                  {/* 消费抵扣金 */}
                  {
                    this.state.deductionCn && this.state.deductionCn.map((itme) => {
                      return(
                        <div className={style.deduction}>
                          <span className={style.key}>{itme.key.txt}:</span> <span style={{color: itme.value.color}}>{itme.value.txt}</span><span style={{color: itme.value.color}}>{itme.ext.txt}</span>
                        </div>
                      )
                    })
                  }
                  {/* k040 预约管家为您代叫不同平台车型的差价已直接扣除,无需有您支付 */}
                  {/* {
                    this.state.isShow ? <div className={style.housekeeper}>{this.t('orderdetails.k040')}</div> : <></>
                  }  */}
                </div>
              }
              <div className={style.extraServices}>
                {/* <p className={style.discounts}>{this.state.coupon.nameCn ? this.state.coupon.nameCn + ':' : ''}{this.state.coupon.amount ? this.state.coupon.amount + '元' : ''}</p> */}
                {
                  this.state.extraServices && this.state.extraServices.map((item, index) => {
                    return <p key={index} className={style.discounts}>{item ? item.nameCn + ':' : ''} {item ? item.amount + this.t('orderdetails.k001') : ''}</p>
                  })
                }
              </div>
              <span>
              {this.t('orderdetails.k017', {customerServiceTelephone:customerServiceTelephone})}
              </span>
            </Collapse.Panel> : ''
          }
          
          {
            (() => {
              if (this.globalStore.userInfo.permissions.orderdetails_approval && this.state.auditData && this.state.auditData.list && this.state.auditData.list.length > 0) {
                return(
                  <Collapse.Panel key='2' title={this.t('orderdetails.k018')}>
                    {
                      (() => {
                        if(this.state.auditData.list.length > 1) {
                          return(
                            <p className={style.lastApproveState}>{this.t('orderdetails.k019')}{orderStateToCn(this.state.auditData.lastApproveState || '', this.t)}</p>
                          )
                        }
                      })()
                    }
                    {
                      this.state.auditData.list && this.state.auditData.list.map((item, index)=> {
                        return(
                          <div key={index} className={index === this.state.auditData.list.length - 1 ? style.last : style.first}>
                            <p>{this.t('orderdetails.k020')}{item.createTime}</p>
                            <p>{this.t('orderdetails.k021')}{item.nameCn}</p>
                            <p>{this.t('orderdetails.k022')}{item.reason}</p>
                            {
                              (() => {
                                // 审核状态
                                if (item.state === 4) {
                                  if (this.globalStore.userInfo.language === 1) {
                                    return (
                                        <img className={style.refuse} alt="" src={images.refuse}></img>
                                      )
                                  } else {
                                    return (
                                      <img className={style.refuse} alt="" src={images.refuseEn}></img>
                                    )
                                  } 
                                } else if (item.state === 2) {
                                  if (this.globalStore.userInfo.language === 1) {
                                    return(
                                      <img className={style.pass} alt="" src={images.pass}></img>
                                    )
                                  } else {
                                    return(
                                      <img className={style.pass} alt="" src={images.passEn}></img>
                                    )
                                  }
                                } else if (item.state === 1) {
                                  return(
                                    <img className={style.pass} alt="" src={images.unhandle}></img>
                                  )
                                }
                              })()
                            }
                          </div>
                        )
                      })
                    }
                  </Collapse.Panel>
                )
              }
            })()
          }
        </Collapse> : ''
        }
        
      </div>
    )
  }

  get showUseCarReason() {
    return this.state.isDisabled;
  }

  get showOrderEvaluate() {
    if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.orderdetails_hide_evaluate) {
      return false
    }
    if (this.state.orderInfo.serviceType === 20 && this.state.orderInfo.state === 6) {
      return true
    }
    if (this.state.orderInfo.state !== 7 && this.state.orderInfo.serviceType !== 20) {
      return true
    }
    return false;
  }
  
  /**
     * 提交用车原因
     */
   useCarReason = () => {
      if (!this.state.isDisabled) {
        Dialog.show({
          getContainer: false,
          closeOnAction: true,
          bodyClassName: `${style.prompt}`,
          actions: [
            {
              key: 'cancel',
              text: this.t('orderdetails.k023'),
            }
          ],
          title: this.t('orderdetails.k024'),
          content: (<>
              <div>
                  <TextArea placeholder={this.t('orderdetails.k027')} disabled={this.state.isDisabled} maxLength={100} rows={3} defaultValue={this.state.reasonVal} 
                      onChange={(val) => {
                        this.setInput(val)
                      }} />
              </div>
          </>)
        })
      } else {
        Dialog.confirm({
            getContainer: false,
            bodyClassName: `${style.prompt}`,
            confirmText: this.t('orderdetails.k025'),
            cancelText: this.t('orderdetails.k026'),
            title: this.t('orderdetails.k024'),
            content: (<>
                <div>
                    <TextArea placeholder={this.t('orderdetails.k027')} disabled={!this.state.isDisabled} maxLength={100} rows={3} defaultValue={this.state.reasonVal} 
                        onChange={(val) => {
                          this.setInput(val)
                        }} />
                    {
                      (() => {
                        if (this.state.isDisabled && this.state.auditData.length > 0) {
                          return  <span className={style.approval}>{this.t('orderdetails.k028')}</span>
                        }
                      })()
                    }
                </div>
            </>),
            onConfirm: async (val)=> {
                await this.sleep(2000)
                this.confirm()
            }
        })
      }
    }
  
   /**
   *
   * 渲染
   */
  render() {
    return <>
      {
        this.state.showLoding ? <LoadingMask show={this.state.showLoding} /> :
        <div className={style.box}>
        {/* 行程 */}
        {this.renderMap()}
        {/* 详情 */}
        {this.renderOrderDetails()}
        {/* 折叠部分 */}
        {this.renderCollapseDetails()}
        {/* 用车事由弹窗 */}
        {this.state.isVisible ? this.useCarReason() : ''}

        </div>
      }
    </>
  }

  async componentDidMount() {
    try {

      let res = await this.loadOrderDetails()

      await this.loadTMap()
      
      if (res === 'REPLACR_TO_RUNING' || res === 'REPLACR_TO_PAY') {
        return;
      }

    } catch (e) {
      console.log(e)
    }
  }

  componentWillUnmount() {
    Dialog.clear()
    // if (this.map) {
    //   this.map.destroy();
    // }
    this.setState = (state,callback)=>{
      return;
    };
  }
  
  /**
   * 
   * 详情数据渲染
   */
  loadOrderDetails = async () => {
    const { id } = this.props.match.params
    let d;
    try {
      d = await webService.order.getOrderDetails({orderId:String(id)});
    } catch (err) {
      Toast.show({
        icon: 'fail',
        content: err.message ? err.message : this.t('orderdetails.k028')
      })
    }
    if (d.data.code === 0) {
      // 判断如果开启真实路径后加载采集点接口
      if(this.isPathReal && d.data.data.orderInfo.state === 6) {
        await this.getLocusPoint(d.data.data.orderInfo.coreOrderId)
      }
      // let runingState = [1,2,3,4];
      // if (d.data.data && d.data.data.orderInfo && runingState.indexOf(d.data.data.orderInfo.state) !== -1) {
      //   this.props.history.replace(`/runing/${id}`);
      //   this.setState({
      //     showLoding: false
      //   })    
      //   return 'REPLACR_TO_RUNING';
      // }
      // 判断是否需要支付
      const ua = dge()
      if (ua.indexOf('smartcomtrip') !== -1) {
        // 中油项目，额外查询本单是否需要支付
        let wpOrders = await webService.order.getWaitPayOrders();
        if (wpOrders.data.code === 0 && wpOrders.data.data.orders && wpOrders.data.data.orders.length) {
          // 存在未支付单
          // let wpOrderIds = wpOrders.data.data.orders.map(item => {return item.id})
          // if (wpOrderIds.indexOf(d.data.data.orderInfo.id) !== -1) {
          //   this.props.history.replace('/payments');
          //   this.setState({
          //     showLoding: false
          //   })
          //   return 'REPLACR_TO_PAY';
          // }
        }
      }

      this.setState({
        costInfo: d.data.data.bill && d.data.data.bill[0]? d.data.data.bill[0].billDetail : [],
        billMain: d.data.data.bill && d.data.data.bill[0] ? d.data.data.bill[0].billMain : {},
        deductionCn: d.data.data.bill && d.data.data.bill[0] ? d.data.data.bill[0].deductionCn : {},
        isPrepayment:d.data.data.bill && d.data.data.bill[0] ? d.data.data.bill[0].isPrepayment : false,
        auditData: d.data.data.approveHistory ? d.data.data.approveHistory : [],
        orderInfo: d.data.data.orderInfo,
        reasonVal: d.data.data.orderInfo.useCarReason,
        extraServices: d.data.data.extraServices,
        coupon: d.data.data.coupon ? d.data.data.coupon : {},
        isRefund: d.data.data.bill && d.data.data.bill[0] ? d.data.data.bill[0].isRefund : false,
        refundMoney: d.data.data.bill && d.data.data.bill[0] ? d.data.data.bill[0].refundMoney : null,
        individualNeedPay: d.data.data.bill && d.data.data.bill[0] ? d.data.data.bill[0].individualNeedPay : null,
        isDisabled: d.data.data.resubmit
      })
      
      // 获取最近审批状态
      // let recentlyInfo = d.data.data.approveHistory && d.data.data.approveHistory.list[d.data.data.approveHistory.list.length - 1]
      if(d.data.data.orderInfo&&d.data.data.orderInfo.abnormalRules){
        this.setState({
          abnormalRules: d.data.data.orderInfo.abnormalRules
        })
      }  
      // if (!d.data.data.orderInfo.useCarReason) {
      //   this.setState({
      //     isDisabled: false
      //   })
      // } else {
      //   if (d.data.data.orderInfo.useCarReason) {
      //     this.setState({
      //       isDisabled: true
      //     })
      //   }
      // }

      // if (d.data.data.approveHistory) {
      //   if (d.data.data.approveHistory.lastApproveState === 1) {
      //     this.setState({
      //       isDisabled: true
      //     })
      //   }
      //   else if (d.data.data.approveHistory.lastApproveState === 4 && d.data.data.orderInfo.isPay) {
      //     this.setState({
      //       isDisabled: true
      //     })
      //   } 
      //   else if (d.data.data.approveHistory.lastApproveState === 4 && recentlyInfo.state === 4) {
      //       this.setState({
      //         isDisabled: false
      //       })
      //   } 
      //   else {
      //     this.setState({
      //       isDisabled: true
      //     })
      //   }
      // } else {
      //   this.setState({
      //     isDisabled: true
      //   })
      // }

      this.setState({
        showLoding: false
      })

    } else {
      Toast.show({
        content: d.data.message,
        duration: 1000,
        afterClose: () => {
          this.props.history.push('/orders')
        }
      })
    }
    
  }

  /**
   * 
   * 真实采集点订单结束后才会显示
   */
  getLocusPoint = async (coreOrderId) => {
    const { id } = this.props.match.params
    let {data} = await webService.order.getLocusPoint({coreOrderId:coreOrderId, orderId: id, start: 0, stop: ''})
    if (data.data && data.data.length > 0) {
      let polyline = []
      let lastPolyline = data.data;
      for(let i in data.data) {
        polyline.push(Number(data.data[i].lat))
        polyline.push(Number(data.data[i].lng))
      }
      this.setState({
        polyline: polyline,
        locationGeo: {
          ...this.state.locationGeo,
          lat: lastPolyline[lastPolyline.length -1].lat,
          lon: lastPolyline[lastPolyline.length -1].lng
        }
      })
      await this.getPosition()
    }
  }

  /**
   * 地图实例
   */
  loadTMap = async () => {
    await this.iPathMapRef.initMap()
    //this.map = this.qqMapRef.getMap();
    this.iPathMapRef.setDraggable(false)
    this.iPathMapRef.setScrollable(false)

    await this.showMarker()
  }

  /**
   * 
   * 显示起点终点和连线 
   */
  showMarker = async () => {
    if (!this.state.orderInfo.departLng) {
      return
    }
    console.log(this.state.orderInfo.passingPoints)
    if (this.isPathReal && this.state.orderInfo.state === 6 && this.state.polyline.length > 1) {
      this.iPathMapRef.drawDrivingMoveAlong(this.state.polyline, 'begin_icon', 'end_icon', this.state.orderInfo.pickupLocationName, this.destLocationName, this.isPathRealAnimation, this.state.orderInfo.passingPoints)
    } else {
      this.iPathMapRef.addDriving({
        lat: this.state.orderInfo.departLat,
        lng: this.state.orderInfo.departLng,
        poi: this.state.orderInfo.departPoi,
        locationName: this.state.orderInfo.pickupLocationName
      }, {
        lat: this.state.orderInfo.destLat,
        lng: this.state.orderInfo.destLng,
        poi: this.state.orderInfo.destPoi,
        locationName: this.state.orderInfo.destLocationName
      }, 1, this.state.orderInfo.passingPoints)
    }
    

  }

  /**
   *获取输入用车原因
   * @param {*} val
   */
   setInput = (val) => {
    this.setState({
      reasonVal: val
    })
  }

  /**
   *
   *确认
   */
  confirm = () => {
    if (!this.state.reasonVal) {
      Toast.show({
        content: this.t('orderdetails.k030'),
      })
    } else {
      let params = {
        orderId: this.state.orderInfo.id,
        reason: this.state.reasonVal
      }
      webService.order.setReason(params).then(d => {
        if (d.data.code === 0) {
          this.setState({
            isVisible: false,
            isDisabled: true,
            reasonVal: this.state.reasonVal
          })
          Toast.show({
            content: this.t('orderdetails.k031'),
          })
        } else {
          Toast.show({
            content: d.data.message,
          })
        }
      })
    }
  }
  
  sleep = (time) => {
    return new Promise(resolve => setTimeout(resolve, time))
  }

  getPosition = async () => {
    await getPosition4Element({lon: this.state.locationGeo.lon, lat: this.state.locationGeo.lat}).then(c5 => {
      if (c5.address && c5.name && c5.lat && c5.lon) {
        let name = c5.name;
        this.setState({
          locationGeo: {
            ...this.state.locationGeo,
            name: name
          }
        })
      } else {
        this.mainStore.setNameAddrEmpty({name:'定位失败', address: '定位失败'})
      }
    }).catch(err => {
      console.log(err)
    })
  }

}

export default withTranslation()(OrderDetails);