import React from 'react';
import style from './verifyBaseLimitation.scss'
import { Dialog } from 'antd-mobile'

import webService from "../../../util/webService"
import loger from './../../../util/loger'

import Selector from './selector'

const VERIFY_BASE_ITEM = '1100'

class VerifyBaseLimitation extends React.Component {

    state = {
        showDialog: false,
        activeOption: undefined,
        data: {},
        options: [],
        retVal: {}
    }

    t = this.props.t

    onClose = undefined;

    // 确认按钮是否可以点击
    get confirmButtonDisabled() {
        let { options, activeOption } = this.state;
        return options && options.length ? activeOption && (activeOption.editing ? activeOption.text : true) : true
    }

    render() {
        let { showDialog, data, activeOption } = this.state;
        return <>
            {
                showDialog
                    ? <Dialog
                        visible={showDialog}
                        title={data.msg.title}
                        content={
                            <div>
                                <div className={style.content}>{data.msg.content}</div>
                                {
                                    data.msg.options && data.msg.options.length
                                        ? <div style={{ marginTop: '10px' }}>
                                            <Selector options={data.msg.options}
                                                onChange={(d) => {
                                                    this.setState({
                                                        activeOption: d
                                                    })
                                                }} t={this.t}></Selector>
                                        </div>
                                        : <></>
                                }
                            </div>
                        }
                        actions={
                            [[
                                {
                                    key: 'cancel',
                                    text: data.msg.cancel.text,
                                    style: {
                                        color: data.msg.cancel.color
                                    },
                                    onClick: () => {
                                        loger.debug('否', activeOption)
                                        this.setState({
                                            showDialog: false,
                                            activeOption: undefined,
                                            data: {},
                                            options: [],
                                            retVal: {}
                                        }, () => {
                                            this.onClose({
                                                action: 0
                                            })
                                        })
                                        
                                    }
                                },
                                {
                                    key: 'delete',
                                    text: data.msg.confirm.text,
                                    disabled: !this.confirmButtonDisabled,
                                    style: {
                                        color: data.msg.confirm.color
                                    },
                                    onClick: async () => {
                                        loger.debug('是，个人支付', activeOption)
                                        this.setState({
                                            showDialog: false,
                                            activeOption: undefined,
                                            data: {},
                                            options: [],
                                            retVal: {}
                                        }, () => {
                                            this.onClose({
                                                action: 1,
                                            data: activeOption
                                            })
                                        })
                                    }
                                },
                            ]]
                        }
                    ></Dialog>
                    : <></>
            }
        </>
    }

    check = async (addr) => {
        return new Promise(async (ok, fail) => {
            this.onClose = ok;

            this.setState({
                showDialog: false,
                activeOption: undefined,
                data: {},
                options: [],
                retVal: {}
            }, async () => {
                let { data } = await webService.order.checkOrderParam({
                    checkItems: [VERIFY_BASE_ITEM],
                    sceneId: addr.sceneId,
                    departCityCode: addr.begin ? addr.begin.cityCode : '',
                    departCityName: addr.begin ? addr.begin.cityName : '',
                    departLat: addr.begin ? addr.begin.lat : '',
                    departLng: addr.begin ? addr.begin.lon : '',
                    destCityCode: addr.end ? addr.end.cityCode : '',
                    destCityName: addr.end ? addr.end.cityName : '',
                    destLat: addr.end ? addr.end.lat : '',
                    destLng: addr.end ? addr.end.lon : ''
                })

                if (data.code) {
                    loger.debug(`[${data.code}] ${data.message}`)
                    ok()
                    return
                }

                if (data.data) {
                    let list = data.data.filter(a => a.code === VERIFY_BASE_ITEM)
                    if (list && list.length) {
                        let res = list[0]
                        if (res.show) {
                            this.setState({
                                showDialog: true,
                                data: res,
                                options: res.msg.options
                            })
                            return
                        }
                    }
                }

                ok()
            })


        })
    }


}

export default VerifyBaseLimitation;

// /**
//  * 
//  * @param {*} addr 
//  * @returns action: 0:否，清空  1:是，什么都不做
//  */
// const verifyBaseLimitation = (addr) => {
//     return new Promise(async (ok, fail) => {
//         let { data } = await webService.order.checkOrderParam({
//             checkItems: [VERIFY_BASE_ITEM],
//             sceneId: addr.sceneId,
//             departCityCode: addr.begin ? addr.begin.cityCode : '',
//             departCityName: addr.begin ? addr.begin.cityName : '',
//             departLat: addr.begin ? addr.begin.lat : '',
//             departLng: addr.begin ? addr.begin.lon : '',
//             destCityCode: addr.end ? addr.end.cityCode : '',
//             destCityName: addr.end ? addr.end.cityName : '',
//             destLat: addr.end ? addr.end.lat : '',
//             destLng: addr.end ? addr.end.lon : ''
//         })

//         if (data.code) {
//             loger.debug(`[${data.code}] ${data.message}`)
//             ok()
//             return
//         }

//         if (data.data) {
//             // 开发用，理想的数据结构
//             // data.data = [
//             //     {
//             //         code: '1100',
//             //         name: 'base地限制',
//             //         msg: {
//             //             title: '系统提示',
//             //             content: '用户您好!\n检测到您的用车可能已经违反了公司的费用报销政策，请您选择是否需要继续用车，若选择继续用车该笔行程由您个人支付。\n您可以通过“掌上AZ-工作台-中国合规部-文化政策-政策法规”查询《阿斯利康中国费用报销政策》中的相关要求。',
//             //             options: [
//             //                 {
//             //                     value: 'c001',
//             //                     label: '理由1'
//             //                 },
//             //                 {
//             //                     value: 'c002',
//             //                     label: '理由2'
//             //                 },
//             //                 {
//             //                     value: 'c003',
//             //                     label: '理由3'
//             //                 },
//             //                 {
//             //                     value: 'c004',
//             //                     label: '其他',
//             //                     editing: true,
//             //                     placeholder: '请输入其他原因'
//             //                 },
//             //             ],
//             //             cancel: {
//             //                 text: '否',
//             //                 color: '#000'
//             //             },
//             //             confirm: {
//             //                 text: '是，个人支付',
//             //                 color: '#68a064'
//             //             }
//             //         },
//             //         confirmText: '',
//             //         cancelText: '',
//             //         show: true
//             //     }
//             // ]
//             let list = data.data.filter(a => a.code === VERIFY_BASE_ITEM)
//             if (list && list.length) {
//                 let res = list[0]
//                 if (res.show) {
//                     let activeOption;
//                     Dialog.show({
//                         onClose: () => {
//                             console.log('onClose')
//                             return false
//                         },
//                         title: res.msg.title,
//                         content: <div>
//                             <div style={{ whiteSpace: 'pre-wrap' }}>{res.msg.content}</div>
//                             {
//                                 res.msg.options && res.msg.options.length
//                                     ? <div style={{ marginTop: '10px' }}>
//                                         {/* <Selector columns={1} options={res.msg.options} /> */}
//                                         <Selector options={res.msg.options}
//                                             onChange={(d) => {
//                                                 activeOption = d;
//                                             }} t={this.t}></Selector>
//                                     </div>
//                                     : <></>
//                             }
//                         </div>,
//                         closeOnAction: true,
//                         actions: [
//                             [
//                                 {
//                                     key: 'cancel',
//                                     text: res.msg.cancel.text,
//                                     style: {
//                                         color: res.msg.cancel.color
//                                     },
//                                     onClick: () => {
//                                         loger.debug('否', activeOption)
//                                         ok({
//                                             action: 0
//                                         })
//                                     }
//                                 },
//                                 {
//                                     key: 'delete',
//                                     disabled: !res.msg.options || (res.msg.options && res.msg.options.length && activeOption),
//                                     text: res.msg.confirm.text,
//                                     style: {
//                                         color: res.msg.confirm.color
//                                     },
//                                     onClick: async () => {
//                                         loger.debug('是，个人支付', activeOption, !res.msg.options || (res.msg.options && res.msg.options.length && activeOption))
//                                         if (!res.msg.options || (res.msg.options && res.msg.options.length && activeOption)) {
//                                             ok({
//                                                 action: 1,
//                                                 data: activeOption
//                                             })
//                                         }
//                                         else {
//                                             Toast.show({
//                                                 icon: 'fail',
//                                                 content: '请选原因',
//                                                 position: 'bottom',
//                                             })
//                                             throw new Error()
//                                         }

//                                     }
//                                 },
//                             ]
//                         ]
//                     })
//                     return
//                 }
//             }
//         }
//         ok()
//         return
//     })
// }

// export {
//     verifyBaseLimitation
// }