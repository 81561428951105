import React from 'react';
import { inject, observer } from 'mobx-react'
import style from './callcar.scss'
import images from './../../util/images'

import { Popup } from 'antd-mobile'
import Address from './address'

import { ForgeRouter } from './../../util/utils'
import gc from './../../util/globalConfiguration'

@inject('mainStore', 'globalStore')
@observer
class EndAddr extends React.Component {

    state = {
        show: false
    }
    mainStore = this.props.mainStore;
    globalStore = this.props.globalStore;
    needCallBack = false;
    t = this.props.t

    fr = undefined

    // 途径点置灰判断
    get isPathwaysCount() {
      if (this.mainStore.pathways.length === 2) {
        return true
      }
      return false
    }

    get endAddrName() {
        try {
            if (this.mainStore.endAddr.name) {
                let arr = this.mainStore.endAddr.name.split('　')
                if (arr && arr.length) {
                    return arr[arr.length - 1]
                }
            }
            
        } catch (exp) {
            console.error(exp)
        }
        return this.mainStore.endAddr.name
    }

    render() {
        return <div className={style.line}>
            <div className={style.icon}>
                <img src={images.end} alt='' />
            </div>
            <div className={style.content} onClick={() => {

                this.setState({
                    show: true
                }, () => {
                    this.fr = new ForgeRouter((isManual) => {
                        let { hash } = document.location;
                        if (isManual || hash === '#/') {
                            this.setState({
                                show: false
                            }, () => {
                                if (this.needCallBack) {
                                    // if (!this.props.isFinishEnd) {
                                    //   this.props.onChange();
                                    // }
                                }
                            })

                        }
                    });
                    this.fr.onpopstate();
                })
            }} >
                {
                    (() => {
                        if (!this.mainStore.endAddr.name) {
                            return <div className={style.where}>{this.t('main.k085')}</div>
                        }
                    })()
                }
                {this.endAddrName}
            </div>
            {
                (() => {
                    if (this.showPathway) {
                        return <div className={style.wrap} onClick={(event) => {
                            event.preventDefault()
                            this.mainStore.addPathways()
                        }}>
                            <div className={this.isPathwaysCount ? style.add_pathway_grey : style.add_pathway} >
                                <img src={images.pathwayAdd} alt='add icon' />
                                {/* 途经点 */}
                                <span>{this.t('main.k0164')}</span>
                            </div>
                        </div>
                    }
                })()
            }
            <Popup
                position='bottom'
                visible={this.state.show}
                bodyStyle={{ height: '100%' }}
            >
                {/* 地址选择框 */}
                {this.state.show ? <Address t={this.props.t} onChange={this.choosedAddr}
                    isCommonlyAddress={true}
                    moveMap={this.props.moveMap}
                    onCancel={() => {
                        this.props.history.goBack();

                        if (window !== window.top) {
                            this.fr.manualOperation()
                        }
                    }}
                    isBegin={false} cityCode={this.mainStore.endAddr.cityCode || this.mainStore.beginAddr.cityCode} cityName={this.mainStore.endAddr.cityName || this.mainStore.beginAddr.cityName}
                    history={this.props.history}
                ></Address> : ''}
            </Popup>
        </div>

    }

    componentDidMount() {
        this.needCallBack = false;
    }

    get showPathway() {
      if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.main_pathwayPoint && ![6, 7, 20].includes(this.mainStore.serverType)) {
        
        if (!gc.process.isMeituan) {
          return true
        }

        if (this.mainStore.showPathway) {
          return true
        } else {
          return false
        }
        
      }
      return false
    }

    /**
     * 点选地址
     * @param {*} item 
     */
    choosedAddr = (item) => {
        this.needCallBack = true;
        this.mainStore.choosedEndAddr(item);
        this.props.moveMap([item.lon, item.lat], false, true, item.recommendType);
        this.props.history.goBack();

        if (window !== window.top) {
            this.fr.manualOperation()
        }
    }
}


export default EndAddr;