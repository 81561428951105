import React from 'react';
import style from './address.scss'
import {inject, observer } from 'mobx-react'
import { Popup, IndexBar, List, Toast } from 'antd-mobile'
import { RightOutline } from 'antd-mobile-icons'

import CommonlyUsedAddress from './commonlyUsedAddress'

import webService from './../../util/webService'
import { setBaseCitys, getBaseCitys} from './../../util/localStorageHandler'

import { ForgeRouter } from './../../util/utils'

import CommonlyAddress from './commonlyAddress'
import SearchResult from './../addr/searchResult'

import images from '../../util/images'


@inject('mainStore', 'globalStore')
@observer
class Address extends React.Component {

    state = {
        addrKey: '',
        isBegin: true,
        cityCode: '',
        cityName: '',
        addrHisRes: [],
        addrSearchRes: [],

        cityKey: '',
        city: false,
        citys: [],
        show: false,
        addressType: '',
        address1: {
          address: '',
          addressType: '',
          cityCode: '',
          cityName: '',
          name: '',
          lon: '',
          lat: '',
          poi: ''
        },
        address2: {
          address: '',
          addressType: '',
          cityCode: '',
          cityName: '',
          name: '',
          lon: '',
          lat: '',
          poi: ''
        }
    }
    mainStore = this.props.mainStore;
    globalStore = this.props.globalStore;
    needCallBack = false; 
    t = this.props.t

    get addrPlaceholder() {
        // 请输入途经点
        return this.state.isBegin === undefined ? this.t('main.k0165') : this.state.isBegin ? this.t('main.k065') : this.t('main.k084')
    }

    get currentServerType() {
      if (this.mainStore.serverType === 0) {
        this.mainStore.serverType = this.globalStore.userInfo.defaultServiceType || this.globalStore.companyInfo.serverTypes[0]
      }
      return this.mainStore.serverType
    }

    /**
     * 搜索结果乘降点
     * @param {*} item 
     * @returns 
     */
     renderAddrSearchResDoor = (item) => {
        if (item.children && item.children.length) {
            return (
                <div className={style.doors}>
                    {
                        item.children.map((door, index) => {
                            return (
                                <div className={style.door} onClick={(e) => {
                                    e.stopPropagation();
                                    this.setState({
                                        addrKey: '',
                                        cityKey: ''
                                    })
                                    this.props.onChange({
                                        isBegin: this.state.isBegin,
                                        cityCode: this.state.cityCode,
                                        cityName: this.state.cityName,
                                        name: door.name,
                                        address: door.address,
                                        lon: door.location.lng,
                                        lat: door.location.lat,
                                        poi: door.poi,
                                        recommendType: 4
                                    })
                                }} key={index}>
                                    <div>{door.show_name}</div>                
                                </div>
                            )
                        })
                    }
                    
                </div>
            )
        }
    }

    /**
     * 搜索结果
     * @returns 
     */
     renderAddrSearchRes = () => {
        if (this.state.addrKey) {
            return <div className={style.sscontainer}>
                {
                    this.state.addrSearchRes.map((item, index) => {
                        return <SearchResult key={index} data={item}
                            t={this.props.t}
                            cityName={this.state.cityName} 
                            cityCount={this.state.addrSearchRes.length}
                            onClick={async (item) => {
                                let cityCode = this.state.cityCode;
                                let cityName = this.state.cityName;

                                if (item.city && item.city !== cityName) {
                                    let citys = this.mainStore.citys.filter(a => a.cityName === item.city)
                                    
                                    if (citys && citys.length) {
                                        let city = citys[0];
                                        cityCode = city.cityCode;
                                        cityName = city.cityName;
                                    } else {
                                        let {data} = await webService.basCity.getCityByName({cityName: item.city});
                                        cityCode = data.data.cityCode;
                                        cityName = item.city;
                                    }
                                    this.setState({
                                        cityCode: cityCode,
                                        cityName: cityName
                                    })
                                }
                                if (this.mainStore.serverType === 6 && this.state.isBegin) {
                                    this.mainStore.chooseServerType(this.currentServerType)
                                }
                                
                                this.props.onChange({
                                    isBegin: this.state.isBegin,
                                    cityCode: cityCode,
                                    cityName: cityName,
                                    name: item.name,
                                    address: item.address,
                                    lon: item.location.lng,
                                    lat: item.location.lat,
                                    poi: item.poi ? item.poi : item.id,
                                    recommendType: 4
                                });
                            }}></SearchResult>
                    })
                }
                {
                    this.state.addrSearchRes && this.state.addrSearchRes.length
                        ? <></>
                        : <div className={style.noneData}>
                            <div>
                                <img src={images.account_nodata} alt={this.t('main.k0149')} />
                            </div>
                            <div>{this.t('main.k0149')}</div>
                        </div>
                }
            </div>
        }
    }

    /**
     * 缓存的历史位置
     * @returns 
     */
    renderAddrHistory = () => {
        if (!this.state.addrKey) {
            return <div className={style.ss}>
                {
                    this.state.addrHisRes && this.state.addrHisRes.map((item, index) => {
                        return (
                            <div className={style.city} onClick={(e) => {
                                e.stopPropagation();
                                if (this.mainStore.serverType === 6 && this.state.isBegin) {
                                  this.mainStore.chooseServerType(this.currentServerType)
                                }
                                this.props.onChange({
                                    isBegin: this.state.isBegin,
                                    cityCode: this.state.cityCode,
                                    cityName: this.state.cityName,
                                    name: item.locationName,
                                    address: item.location,
                                    lon: item.lng,
                                    lat: item.lat,
                                    poi: item.poi ? item.poi : item.id,
                                    recommendType: 3
                                });
                            }} key={index}>
                                {item.locationName}
                                <div className={style.tip}>{item.location}</div>            
                            </div>
                        )
                    })
                }
                
            </div>
        }
    }


    /**
     * 城市选框
     * @returns 
     */
     renderChooseCity = () => {
        return <div className={style.citys}>
            <div className={style.heads}>
                <div className={style.head}>
                    <div className={style.input}>
                    <input type="text" placeholder={this.t('main.k061')} value={this.state.cityKey} onChange={(e) => {
                        this.setState({
                            cityKey: e.target.value
                        });
                    }} />
                    </div>
                    <div className={style.cancel} onClick={() => {
                        // this.setState({
                        //     city: false
                        // });
                        this.setState({
                          addrKey: ''
                        })
                        this.props.history.goBack();
                    }}>{this.t('main.k062')}</div>
                </div>
            </div> 
            <div className={style.body}>
                <div className={style.cs}>
                    <div className={style.currentcity}>{this.t('main.k063')}{ this.state.cityName }</div>
                </div>
                <IndexBar className={style.indexBar}>
                    {                        
                        this.generateCityTree(this.mainStore.citys.filter(a => {
                            return a.cityGroupName.indexOf(this.state.cityKey) !== -1
                                || a.cityName.indexOf(this.state.cityKey) !== -1
                                || a.cityPinYin.indexOf(this.state.cityKey) !== -1
                                || a.cityDistrict.indexOf(this.state.cityKey) !== -1;
                        })).map((item) => {
                            return <IndexBar.Panel
                                index={item.key}
                                title={item.key}
                                key={item.key}
                                >
                                    <List>
                                    {
                                        item.children.map((c) => {
                                            return <List.Item key={c.cityCode} clickable={true} arrow={false} onClick={() => {
                                                this.setState({
                                                    cityCode: c.cityCode,
                                                    cityName: c.cityName,
                                                    addrKey: this.state.cityCode === c.cityCode ? this.state.addrKey : ''
                                                }, () => {
                                                    if (this.state.onlyCity) {
                                                        this.props.onChange({
                                                            isBegin: this.state.isBegin,
                                                            cityCode: c.cityCode,
                                                            cityName: c.cityName
                                                        })
                                                    } else {
                                                        this.getHistoryLocationList();
                                                    }
                                                    
                                                });                                                
                                                this.props.history.goBack();
                                                
                                            }}>{c.cityName} </List.Item>
                                        })
                                    }
                                    </List>
                                                
                            </IndexBar.Panel>
                        })
                    }
                    
                </IndexBar>
                
            </div>
        </div>
    } 

    render() {
        return <div className={style.address}>
            <div className={style.heads}>
                <div className={style.head}>
                  <div className={style.cityCell}>
                    <div className={style.city} onClick={this.showCitys}>{this.state.cityName} </div>
                    <RightOutline color='#cccccc'/>
                  </div>
                    <div className={style.input}>
                    <input type="text" placeholder={this.addrPlaceholder} value={this.state.addrKey} 
                    onChange={(e) => {
                        let key = e.target.value;
                        this.setState({
                            addrKey: key
                        }, () => {
                            // webService.map.place(key, this.state.isBegin ? '' : this.state.cityName)
                            let latlon = ''
                            if (this.mainStore.currentAddr && this.mainStore.currentAddr.lat) {
                                latlon = `${this.mainStore.currentAddr.lat},${this.mainStore.currentAddr.lon}`;
                            }
                            if (!latlon && this.mainStore.beginAddr && this.mainStore.beginAddr.lat) {
                                latlon = `${this.mainStore.beginAddr.lat},${this.mainStore.beginAddr.lon}`;
                            }
                            webService.map.place(key, this.state.cityName, latlon)
                            .then((d) => {
                                if (key !== this.state.addrKey) {
                                    return;
                                }
                                if (d.data && d.data.data) {
                                    let results = d.data.data.filter(a => a.city).map((a) => {
                                        let children = undefined;
                                        if (d.data.sub_pois && d.data.sub_pois.length) {
                                            children = d.data.sub_pois.filter(e => e.parent_id === a.id);
                                        }
                                        if (children && children.length) {
                                            children = children.map((d) => {
                                                return {
                                                    poi: d.poi || (d.isFence ? 'i-' + d.id : d.id),
                                                    show_name: d.title,
                                                    name: a.title + '-' + d.title,
                                                    address: d.address,
                                                    location: d.location,
                                                    city: a.city
                                                }
                                            })
                                        }
                                        return {
                                            poi: a.poi || (a.isFence ? 'i-' + a.id : a.id),
                                            name: a.title,
                                            address: a.address,
                                            location: a.location,
                                            children: children,
                                            city: a.city
                                        }
                                    });

                                    // 按照城市分组
                                    let group = [];
                                    for (let i in results) {
                                        let item = results[i];
                                        let gs = group.filter(a => a.city === item.city);
                                        let g;
                                        if (gs && gs.length) {
                                            g = gs[0];
                                        }
                                        if (g) {
                                            g.items.push(item);
                                        } else {
                                            g = {
                                                city: item.city,
                                                items: [item]
                                            }
                                            group.push(g)
                                        }
                                    }
                                    // 给分组排序
                                    if (group && group.length > 1) {
                                        let currentCity = group.filter(a => a.city === this.state.cityName);
                                        let otherCity = group.filter(a => a.city !== this.state.cityName);
                                        console.log(currentCity)
                                        console.log(otherCity)
                                        group = [...currentCity, ...otherCity]
                                    }

                                    this.setState({
                                        addrSearchRes: group                                        
                                    });
                                }
                            });
                        })
                        
                    }} />
                    </div>
                    <div className={style.cancel} onClick={() => {
                        this.props.onCancel();
                    }}>{this.t('main.k062')}</div>
                </div>
            </div>

            <div className={style.addressBox}>
              <div className={style.container}>

                {
                  this.props.isCommonlyAddress ? <CommonlyUsedAddress address1={this.state.address1} address2={this.state.address2} t={this.props.t} useAddress1={(item) => this.useAddress1(item)} useAddress2={(item) => this.useAddress2(item)} to={this.toCommonlyAddress} history={this.props.history}></CommonlyUsedAddress> : ''
                }
                  

                <div className={style.body}>

                  {this.renderAddrSearchRes()}
                  
                  {this.renderAddrHistory()}

                  <Popup
                      position='bottom'
                      visible={this.state.city}
                      bodyStyle={{ height: '100%' }}
                  >
                      {/* 城市选择框 */}
                      { this.renderChooseCity() }
                  </Popup>
                </div>
              </div>
            </div>
            <Popup
                position='bottom'
                visible={this.state.show}
                bodyStyle={{ height: '100%' }}
                >
                {/* 地址选择框 */}
                <CommonlyAddress onChange={this.choosedAddr}
                    onCancel={() => {
                        this.setState({
                          show: false
                        })
                    }}
                    isShowCitys={false}
                    t={this.props.t}
                    isBegin={true} cityCode={this.state.cityCode} cityName={this.state.cityName}
                    history={this.props.history}
                ></CommonlyAddress>
            </Popup>
        </div>
    }

    componentDidMount = () => {
        this.needCallBack = false;

        let isBegin = this.props.isBegin;
        let cityCode = this.props.cityCode;
        let cityName = this.props.cityName;
        let onlyCity = this.props.onlyCity;
        this.mainStore.setIsBegin(isBegin)
        this.setState({
            isBegin: isBegin,
            cityCode: cityCode,
            cityName: cityName,
            onlyCity: onlyCity,
            addrKey: '',
            addrSearchRes: [],
            city: onlyCity
            
        }, () => {
            if (!cityCode) {
                this.showCitys();
            }
            this.getUserCommonAddress()
            this.getHistoryLocationList();
        });   
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.cityCode !== this.props.cityCode && nextProps.cityName !== this.props.cityName) {
            let cityCode = nextProps.cityCode;
            let cityName = nextProps.cityName;
    
            this.setState({
                cityCode: cityCode,
                cityName: cityName,
                addrKey: this.state.cityCode === cityCode ? this.state.addrKey : '',
            }, () => {
                this.getHistoryLocationList();
            });
        }
        if (nextProps.isBegin !== this.props.isBegin) {
            let isBegin = nextProps.isBegin
            this.setState({
                isBegin: isBegin
            })
        }
        if (nextProps.onlyCity !== this.props.onlyCity) {
            let onlyCity = this.props.onlyCity;
            this.setState({
                onlyCity: onlyCity
            })
        }

        // this.setState({
        //   addrKey: ''
        // })

        
    }
    
    componentWillUnmount = () => {
      this.setState = (state,callback)=>{
        return;
      };
    }

    showCitys = (e) => {
        if (e && e.stopPropagation) {
            e.stopPropagation()
        }
        
        this.setState({
            city: true,
            cityKey: ''
        }, () => {
            let fr = new ForgeRouter(() => {
                let {hash} = document.location;
                if (hash === '#/' || hash.indexOf('runing') !== -1 || hash.indexOf('perApply') !== -1 || hash === '#/estimate') {
                    this.setState({
                        city: false
                    }, () => {
                        if (this.needCallBack) {
                            this.props.onChange();
                        }
                    })
                } 
            });
            fr.onpopstate();
        });
        let baseCitys = getBaseCitys();
        if (baseCitys) {
            this.mainStore.setCitys(baseCitys);
        }
        if (!this.mainStore.citys || this.mainStore.citys.length < 1) {
            webService.basCity.getCityList().then((d) => {
                if (d.data.code === 0) {
                    d.data.data.rows = d.data.data.rows.sort((a,b) => {return a.cityPinYin > b.cityPinYin ? 1 : -1})
                    setBaseCitys(d.data.data.rows)
                    this.mainStore.setCitys(d.data.data.rows);
                    
                }
            });
        }
        
    }

    /**
     * 载入历史地址
     */
     getHistoryLocationList = () => {
        this.setState({
            addrHisRes: []
        });
        webService.basCity.getHistoryLocationList(this.state.cityCode).then((d) => {
            if (d.data.code === 0 && d.data.data && d.data.data.rows && d.data.data.rows.length) {
                this.setState({
                    addrHisRes: d.data.data.rows
                });
            }

        });
    }

    /**
     * 城市列表组装成树形结构
     * @param {*} citys 
     * @returns 
     */
     generateCityTree = (citys) => {
        let cityGroup = [];
        for (let i in citys) {
            let _tmp = cityGroup.filter(a => a.key === citys[i].cityGroupName);
            let g;
            if (_tmp.length < 1) {
                g = {
                    key: citys[i].cityGroupName,
                    children: []  
                }
                cityGroup.push(g);
            } else {
                g = _tmp[0];
            }

            g.children.push(citys[i]);
        }
        return cityGroup;
    }

    /**
     * 获取常用地址
     * @param {*} citys 
     * @returns 
     */
    getUserCommonAddress = () => {
      webService.habitAddress.getUserCommonAddress().then(res => {
        if(res.data.data && res.data.data.address1) {
          this.setState({
            address1: res.data.data.address1
          })
        } else {
          this.setState({
            address1: {}
          })
        }
        if(res.data.data && res.data.data.address2) {
          this.setState({
            address2: res.data.data.address2
          })
        } else {
          this.setState({
            address2: {}
          })
        }
      })
    }

    /**
     * 设置常用地址
     * @param {*} citys 
     * @returns 
     */
    setUserCommonAddress = (params) => {
      webService.habitAddress.setUserCommonAddress(params).then(res => {
        if(res.data.code !== 0) {
          Toast.show({
            content: this.t('main.k0124'),
            duration: 1000
          })
        }
      })
    }

    /**
     * 点选地址
     * @param {*} item 
     */
     choosedAddr = (item) => {
      item.address = item.name
      // 走接口设置常用地址1、2
      item.addressType = this.state.addressType
      item.empty = false
      if (this.state.addressType === 1) {
        this.setState({
          ...this.state.address1,
          address1: item
        })
        this.setUserCommonAddress(item)
      } else {
        this.setState({
          ...this.state.address2,
          address2: item
        })
        this.setUserCommonAddress(item)
      }
      this.setState({
        show: false
      })           
     }

     /**
     * 选择常用地址1
     * @param {*} item 
     */
     useAddress1 = (item) => {
      // 选择常用地址默认实时单
      if (this.props.isBegin && this.mainStore.serverType === 6) {
        this.mainStore.chooseServerType(this.currentServerType)
      }
      this.props.onChange({
        isBegin: this.props.isBegin,
        cityCode: item.cityCode,
        cityName: item.cityName,
        name: item.name,
        address: item.address,
        lon: item.lon,
        lat: item.lat,
        poi: item.poi,
        recommendType: 3
      });
     }

      /**
     * 选择常用地址2
     * @param {*} item 
     */
       useAddress2 = (item) => {
        // 选择常用地址默认实时单
        if (this.props.isBegin && this.mainStore.serverType === 6) {
          this.mainStore.chooseServerType(this.currentServerType)
        }
        this.props.onChange({
          isBegin: this.props.isBegin,
          cityCode: item.cityCode,
          cityName: item.cityName,
          name: item.name,
          address: item.address,
          lon: item.lon,
          lat: item.lat,
          poi: item.poi,
          recommendType: 3
        });
       }
     
     /**
     * 弹窗常用地址
     * @param {*} item 
     */
      toCommonlyAddress = (type) => {
        this.setState({
          show: true,
          addressType: type
        })
      }
}

export default Address;