import React from 'react';
import style from './messageBox.scss'
import { inject, observer } from 'mobx-react'

import { Popup } from 'antd-mobile'

@inject('mainStore', 'estimateStore', 'globalStore')
@observer
class MessageBox extends React.Component {

    state = {
        show: false,
        url: ''
    }

    render() {
        return <div>
            <Popup
                visible={this.state.show}
                onMaskClick={this.hide}
                position='bottom'
                bodyStyle={{ height: '90vh' }}
            >
                <div className={style.popupContainer}>
                    { this.state.show ?  <iframe title="' '" src={this.state.url}></iframe> : <></> }
                </div>
            </Popup>
        </div>
    }

    show = (url) => {
        this.setState({
            show: true,
            url: url
        })
    }

    hide = (backType) => {
        this.setState({
          show: false,
          url: ''
        })

        const { hash } = document.location;
        if (backType === 'messageBoxRef'){
            switch (hash) {
                case '#/estimate': {
                    this.props.history.go(-1)
                    break
                }
                case '#/confirm': {
                    this.props.history.go(-2)
                    break
                }
                default: {
                    break
                }
            }
            return
        }

        switch (hash) {
            case '#/estimate': {
                this.props.history.go(-2)
                break
            }
            case '#/confirm': {
                this.props.history.go(-3)
                break
            }
            default: {
                this.props.history.go(-1)
                break
            }
        }
        
    }

}

export default MessageBox;