import React from 'react';
import style from './customForm.scss'
import {List, Popup, Input, CheckList, TextArea,SearchBar, Image} from 'antd-mobile'
import {inject, observer } from 'mobx-react'

import images from '../../util/images'

import { debounce } from './../../util/utils';

@inject('confirmStore')
@observer
class CustomForm extends React.Component {
  state = {
  }
  confirmStore = this.props.confirmStore;
  customCarList = this.props.confirmStore

  t = this.props.t

  render() {
    return(
      <div className={style.customForm}> 
        {
          this.confirmStore.customCarList && this.confirmStore.customCarList.map((item, index) => {
            return(
                <List key={index} mode="card" style={{'--prefix-width': '6em'}}>
                  {
                    (() => {
                      if (item.type === 'phone' || item.type === 'number' || item.type === 'textArea') {
                        const exceptThisSymbols = ["e", "E", "+", "-", ".", ','];
                        return(
                            <div className={style.inputText}>
                              <List.Item prefix={<div><span className={style.required}>{item.rules[0].required ? '*':''}</span>{item.title}</div>} >
                              <div></div>          
                              <Input readOnly={item.rules[0].readOnly} onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} type={this.checkInputType(item.type)} placeholder={item.rules[0].message} style={{ '--text-align': 'right', '--placeholder-color': '#999999', '--font-size': '15px' }} value={item.value} onChange={(val) => {
                                if (item.type === 'phone') { 
                                  val.length > 11 ? val = val.slice(0, 11) : this.changeEvent(val, index, item.type)
                                } else if (item.type === 'number'){
                                  val.length > 20 ? val = val.slice(0, 20) : this.changeEvent(val, index, item.type)
                                } else {
                                  this.props.inputChange(val, index, item.type)
                                }
                                
                              }}/>
                              </List.Item>
                            </div>
                        )
                      }
                      if (item.type === 'textSearch') {
                        const exceptThisSymbols = ["e", "E", "+", "-", ".", ','];
                        return(
                            <div className={style.inputText}>
                              <List.Item prefix={<div><span className={style.required}>{item.rules[0].required ? '*':''}</span>{item.title}</div>} >
                                <div className={style.boxSearchList}>

                              <Input readOnly={item.rules[0].readOnly} onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} type={this.checkInputType(item.type)} placeholder={item.rules[0].message} style={{ '--text-align': 'right', '--placeholder-color': '#999999', '--font-size': '15px' }} value={item.value} onChange={(val) => {
                                this.props.inputChange(val, index, item.type)
                              }}/>
                              {/* <Space className={style.space} onClick={ async () => {
                                  this.props.showCheckList('checkListClick', index, item.type)
                              }}>
                                <RightOutline color="#c9c9ce" fontSize={15}/>
                              </Space> */}
                              <div className={style.project_search} onClick={() => {
                                this.props.showCheckList('checkListClick', index, item.type)
                              }}>
                                <div className={style.right_box}>
                                  <Image src={images.numberRetrieval} width={20} height={20} fit='cover' />
                                  {/* 编号检索 */}
                                  <span>{this.t('confirm.k057')}</span>
                                </div>
                              </div>
                                </div>
                              <Popup
                                bodyClassName={style.popupBox}
                                visible={item.visible}
                                onMaskClick={() => {
                                  this.props.closeCheckList('checkListClose', index, item.type)
                                }}
                              >
                                <div className={style.searchBox}><SearchBar placeholder='请输入内容' onChange={(val) => {this.debouncePrevent(val, index, item)}}
                                  /></div>                                                                          
                                <CheckList multiple={item.type === 'checkList' ? true : false} value={item.value} onChange={(val, e) => {
                                    this.props.checkListChange(val, index, 'checked', item.id, 'special')
                                }}>
                                  {
                                    (() => {
                                      if (item.dataSource) {
                                        return(
                                          item.items && item.items.map((val, idx) => {
                                            return(<CheckList.Item key={idx} value={val.value}>{val.label}</CheckList.Item>)
                                          })
                                        )
                                      } else {
                                        return(
                                          item.items && item.items.map((val, idx) => {
                                            return(<CheckList.Item key={idx} value={val.value}>{val.label}</CheckList.Item>)
                                          })
                                        )
                                      }
                                    })()
                                  }
                                </CheckList>
                              </Popup>
                              </List.Item>
                            </div>
                        )
                      }
                      if (item.type === 'email') {
                        return(
                            <div className={style.inputText}>
                              <List.Item prefix={<div><span className={style.required}>{item.rules[0].required ? '*':''}</span>{item.title}</div>} >
                              <div></div>          
                              <Input readOnly={item.rules[0].readOnly} type={this.checkInputType(item.type)} placeholder={item.rules[0].message} style={{ '--text-align': 'right', '--placeholder-color': '#999999', '--font-size': '15px' }} value={item.value} onChange={(val) => {
                                  this.props.inputChange(val, index, item.type)
                              }}/>
                              </List.Item>
                            </div>
                        )
                      }
                      else if (item.type === 'checkList'||item.type === 'radio') {
                        return(
                            <div className={style.radio}>
                                <List.Item prefix={<div><span className={style.required}>{item.rules[0].required ? '*':''}</span>{item.title}</div>}  >
                                    <div className={style.boxList} onClick={ async () => {
                                        this.props.showCheckList('checkListClick', index, item.type)
                                      }}>
                                      {
                                        item.labelValue ? <span onClick={ async () => {this.props.showCheckList('checkListClick', index, item.type, item.id)}} 
                                        className={style.checkListValue}>{item.labelValue}</span> : 
                                        <span onClick={ async () => {this.props.showCheckList('checkListClick', index, item.type)}} className={style.checkListDefaultValue}>{item.rules[0].message}</span>
                                      }
                                      {/* <Space className={style.space} onClick={ async () => {
                                        this.props.showCheckList('checkListClick', index, item.type)
                                      }}>
                                        <RightOutline style={{marginRight:'10px'}} color="#c9c9ce" fontSize={15}/>
                                      </Space> */}
                                      <div className={style.project_search} onClick={() => {
                                        this.props.showCheckList('checkListClick', index, item.type)
                                      }}>
                                        <div className={style.right_box}>
                                          <Image src={images.numberRetrieval} width={20} height={20} fit='cover' />
                                          {/* 编号检索 */}
                                          <span>{this.t('confirm.k057')}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <Popup
                                      bodyClassName={style.popupBox}
                                      visible={item.visible}
                                      onMaskClick={() => {
                                        this.props.closeCheckList('checkListClose', index, item.type)
                                      }}
                                    >
                                      <div className={style.searchBox}><SearchBar placeholder='请输入内容' onChange={(val) => {this.debouncePrevent(val, index, item)}}
                                        /></div>                                                                          
                                      <CheckList multiple={item.type === 'checkList'?true:false} value={item.value} onChange={(val, e) => {
                                          // 施维雅多选限制 limitCheck
                                          if ((val.length > item.rules[0].limitCheck) && item.rules[0].limitCheck !== null) {
                                            return
                                          }
                                          this.props.checkListChange(val, index, 'checked', item.id)
                                          if (item.type === 'radio') {
                                            this.props.closeCheckList('checkListClose', index, item.type)
                                          }
                                      }}>
                                        {
                                          (() => {
                                            if (item.dataSource) {
                                              return(
                                                item.items && item.items.map((val, idx) => {
                                                  return(<CheckList.Item key={idx} value={val.value}>{val.label}</CheckList.Item>)
                                                })
                                              )
                                            } else {
                                              return(
                                                item.items && item.items.map((val, idx) => {
                                                  return(<CheckList.Item key={idx} value={val.value}>{val.label}</CheckList.Item>)
                                                })
                                              )
                                            }
                                          })()
                                        }
                                      </CheckList>
                                    </Popup>
                                  </List.Item>
                            </div>
                        )
                      } else if (item.type === 'textArea') {
                        return(
                          <div className={style.textArea}>
                              <List.Item prefix={<div><span className={style.required}>{item.rules[0].required ? '*':''}</span>{item.title}</div>} > 
                                  <TextArea
                                    style={{ '--text-align': 'right', '--placeholder-color': '#999999', '--font-size': '15px' }}
                                    value={item.value}
                                    placeholder={item.rules[0].message}
                                    maxLength={100}
                                    readOnly={item.rules[0].readOnly}
                                    rows={2}
                                    onChange={(val) => {
                                      this.props.textAreaChange(val, index, item.type)
                                    }}
                                  />
                              </List.Item>
                          </div>
                        )
                      }
                    })()
                  }
                </List>
            )
          })
        }
        </div>
    )
  }

  checkInputType = (type) => {
      if (type === 'number') {
      return 'tel'
    } else if (type === 'phone') {
      return 'text'
    } else {
      return 'text'
    }
  }

  changeEvent=(val, index, type)=>{
    let value = val.replace(/\D/g, '')
    this.props.inputChange(value, index, type)
  }

  debouncePrevent = debounce((val, index, item) => {
    this.props.searchList(val, index, 'searchList', item.id, item.dataSource)
  }, 500)
}

export default CustomForm
