import { setBaseCitys} from './localStorageHandler'

const citys = {
    "code": 0,
    "message": "成功",
    "data": {
        "rows": [
            {
                "cityCode": "846",
                "cityName": "雄安新区",
                "cityPinYin": "xaxq",
                "cityGroupName": "X",
                "cityarea": "846",
                "cityDistrict": "0312"
            },
            {
              "cityCode": "847",
              "cityName": "阿里地区",
              "cityPinYin": "aldq",
              "cityGroupName": "A",
              "cityarea": "847",
              "cityDistrict": "0897"
            },
            {
                "cityCode": "329",
                "cityName": "宜春市",
                "cityPinYin": "yichun",
                "cityGroupName": "Y",
                "cityDistrict": "0795"
            },
            {
                "cityCode": "591",
                "cityName": "廉江市",
                "cityPinYin": "lianjiangshi",
                "cityGroupName": "L",
                "cityDistrict": "0759"
            },
            {
                "cityCode": "124",
                "cityName": "和田地区",
                "cityPinYin": "hetiandiqu",
                "cityGroupName": "H",
                "cityDistrict": "0903"
            },
            {
                "cityCode": "154",
                "cityName": "廊坊市",
                "cityPinYin": "langfang",
                "cityGroupName": "L",
                "cityDistrict": "0316"
            },
            {
                "cityCode": "276",
                "cityName": "威海市",
                "cityPinYin": "weihai",
                "cityGroupName": "W",
                "cityDistrict": "0631"
            },
            {
                "cityCode": "390",
                "cityName": "岑溪市",
                "cityPinYin": "cenxishi",
                "cityGroupName": "C",
                "cityDistrict": "0774"
            },
            {
                "cityCode": "45",
                "cityName": "潮州市",
                "cityPinYin": "chaozhou",
                "cityGroupName": "C",
                "cityDistrict": "0768"
            },
            {
                "cityCode": "767",
                "cityName": "信宜市",
                "cityPinYin": "xinyishi",
                "cityGroupName": "X",
                "cityDistrict": "0668"
            },
            {
                "cityCode": "775",
                "cityName": "牙克石市",
                "cityPinYin": "yakeshishi",
                "cityGroupName": "Y",
                "cityDistrict": "0470"
            },
            {
                "cityCode": "176",
                "cityName": "六盘水市",
                "cityPinYin": "liupanshui",
                "cityGroupName": "L",
                "cityDistrict": "0858"
            },
            {
                "cityCode": "642",
                "cityName": "宁国市",
                "cityPinYin": "ningguoshi",
                "cityGroupName": "N",
                "cityDistrict": "0563"
            },
            {
                "cityCode": "607",
                "cityName": "龙海市",
                "cityPinYin": "longhaishi",
                "cityGroupName": "L",
                "cityDistrict": "0596"
            },
            {
                "cityCode": "637",
                "cityName": "南宫市",
                "cityPinYin": "nangongshi",
                "cityGroupName": "N",
                "cityDistrict": "0319"
            },
            {
                "cityCode": "577",
                "cityName": "老河口市",
                "cityPinYin": "laohekoushi",
                "cityGroupName": "L",
                "cityDistrict": "0710"
            },
            {
                "cityCode": "383",
                "cityName": "北屯市",
                "cityPinYin": "beitunshi",
                "cityGroupName": "B",
                "cityDistrict": "1906"
            },
            {
                "cityCode": "131",
                "cityName": "嘉兴市",
                "cityPinYin": "jiaxing",
                "cityGroupName": "J",
                "cityDistrict": "0573"
            },
            {
                "cityCode": "445",
                "cityName": "鄂伦春自治旗",
                "cityPinYin": "donglunchunzizhiqi",
                "cityGroupName": "E",
                "cityDistrict": "0470"
            },
            {
                "cityCode": "470",
                "cityName": "高碑店市",
                "cityPinYin": "gaobeidianshi",
                "cityGroupName": "G",
                "cityDistrict": "0312"
            },
            {
                "cityCode": "326",
                "cityName": "榆林市",
                "cityPinYin": "yulin",
                "cityGroupName": "Y",
                "cityDistrict": "0912"
            },
            {
                "cityCode": "690",
                "cityName": "什邡市",
                "cityPinYin": "shifangshi",
                "cityGroupName": "S",
                "cityDistrict": "0838"
            },
            {
                "cityCode": "370",
                "cityName": "安达市",
                "cityPinYin": "andashi",
                "cityGroupName": "A",
                "cityDistrict": "0455"
            },
            {
                "cityCode": "506",
                "cityName": "和龙市",
                "cityPinYin": "helongshi",
                "cityGroupName": "H",
                "cityDistrict": "1433"
            },
            {
                "cityCode": "480",
                "cityName": "共青城市",
                "cityPinYin": "gongqingchengshi",
                "cityGroupName": "G",
                "cityDistrict": "0792"
            },
            {
                "cityCode": "661",
                "cityName": "黔西南布依族苗族自治州",
                "cityPinYin": "qianxinanbuyizumiaozuzizhizhou",
                "cityGroupName": "Q",
                "cityDistrict": "0859"
            },
            {
                "cityCode": "142",
                "cityName": "荆门市",
                "cityPinYin": "jingmen",
                "cityGroupName": "J",
                "cityDistrict": "0724"
            },
            {
                "cityCode": "183",
                "cityName": "绵阳市",
                "cityPinYin": "mianyang",
                "cityGroupName": "M",
                "cityDistrict": "0816"
            },
            {
                "cityCode": "170",
                "cityName": "娄底市",
                "cityPinYin": "loudi",
                "cityGroupName": "L",
                "cityDistrict": "0738"
            },
            {
                "cityCode": "556",
                "cityName": "开远市",
                "cityPinYin": "kaiyuanshi",
                "cityGroupName": "K",
                "cityDistrict": "0873"
            },
            {
                "cityCode": "398",
                "cityName": "长葛市",
                "cityPinYin": "changgeshi",
                "cityGroupName": "C",
                "cityDistrict": "0374"
            },
            {
                "cityCode": "335",
                "cityName": "玉溪市",
                "cityPinYin": "yuxi",
                "cityGroupName": "Y",
                "cityDistrict": "0877"
            },
            {
                "cityCode": "156",
                "cityName": "辽阳市",
                "cityPinYin": "liaoyang",
                "cityGroupName": "L",
                "cityDistrict": "0419"
            },
            {
                "cityCode": "615",
                "cityName": "陆丰市",
                "cityPinYin": "lufengshi",
                "cityGroupName": "L",
                "cityDistrict": "0660"
            },
            {
                "cityCode": "604",
                "cityName": "凌源市",
                "cityPinYin": "lingyuanshi",
                "cityGroupName": "L",
                "cityDistrict": "0421"
            },
            {
                "cityCode": "203",
                "cityName": "莆田市",
                "cityPinYin": "putian",
                "cityGroupName": "P",
                "cityDistrict": "0594"
            },
            {
                "cityCode": "734",
                "cityName": "乌拉特中旗",
                "cityPinYin": "wulatezhongqi",
                "cityGroupName": "W",
                "cityDistrict": "0478"
            },
            {
                "cityCode": "618",
                "cityName": "麻城市",
                "cityPinYin": "machengshi",
                "cityGroupName": "M",
                "cityDistrict": "0713"
            },
            {
                "cityCode": "565",
                "cityName": "克孜勒苏柯尔克孜自治州",
                "cityPinYin": "kezilesukedongkezizizhizhou",
                "cityGroupName": "K",
                "cityDistrict": "0908"
            },
            {
                "cityCode": "747",
                "cityName": "舞钢市",
                "cityPinYin": "wugangshi",
                "cityGroupName": "W",
                "cityDistrict": "0375"
            },
            {
                "cityCode": "64",
                "cityName": "定安县",
                "cityPinYin": "dinganxian",
                "cityGroupName": "D",
                "cityDistrict": "0118"
            },
            {
                "cityCode": "421",
                "cityName": "当阳市",
                "cityPinYin": "dangyangshi",
                "cityGroupName": "D",
                "cityDistrict": "0717"
            },
            {
                "cityCode": "740",
                "cityName": "五常市",
                "cityPinYin": "wuchangshi",
                "cityGroupName": "W",
                "cityDistrict": "0451"
            },
            {
                "cityCode": "731",
                "cityName": "翁牛特旗",
                "cityPinYin": "wengniuteqi",
                "cityGroupName": "W",
                "cityDistrict": "0476"
            },
            {
                "cityCode": "20",
                "cityName": "巴彦淖尔市",
                "cityPinYin": "bayannaoer",
                "cityGroupName": "B",
                "cityDistrict": "0478"
            },
            {
                "cityCode": "539",
                "cityName": "胶州市",
                "cityPinYin": "jiaozhoushi",
                "cityGroupName": "J",
                "cityDistrict": "0532"
            },
            {
                "cityCode": "574",
                "cityName": "莱州市",
                "cityPinYin": "laizhoushi",
                "cityGroupName": "L",
                "cityDistrict": "0535"
            },
            {
                "cityCode": "570",
                "cityName": "昆山市",
                "cityPinYin": "kunshanshi",
                "cityGroupName": "K",
                "cityDistrict": "0512"
            },
            {
                "cityCode": "822",
                "cityName": "准格尔旗",
                "cityPinYin": "zhungedongqi",
                "cityGroupName": "Z",
                "cityDistrict": "0477"
            },
            {
                "cityCode": "37",
                "cityName": "沧州市",
                "cityPinYin": "cangzhou",
                "cityGroupName": "C",
                "cityDistrict": "0317"
            },
            {
                "cityCode": "178",
                "cityName": "林芝地区",
                "cityPinYin": "linzhidiqu",
                "cityGroupName": "L",
                "cityDistrict": "0894"
            },
            {
                "cityCode": "793",
                "cityName": "永城市",
                "cityPinYin": "yongchengshi",
                "cityGroupName": "Y",
                "cityDistrict": "0370"
            },
            {
                "cityCode": "286",
                "cityName": "文昌市",
                "cityPinYin": "wenchang",
                "cityGroupName": "W",
                "cityDistrict": "1893"
            },
            {
                "cityCode": "451",
                "cityName": "恩施土家族苗族自治州",
                "cityPinYin": "dongshitujiazumiaozuzizhizhou",
                "cityGroupName": "E",
                "cityDistrict": "0718"
            },
            {
                "cityCode": "87",
                "cityName": "甘南州",
                "cityPinYin": "gannanzhou",
                "cityGroupName": "G",
                "cityDistrict": "0941"
            },
            {
                "cityCode": "620",
                "cityName": "满洲里市",
                "cityPinYin": "manzhoulishi",
                "cityGroupName": "M",
                "cityDistrict": "0470"
            },
            {
                "cityCode": "576",
                "cityName": "阆中市",
                "cityPinYin": "langzhongshi",
                "cityGroupName": "L",
                "cityDistrict": "0817"
            },
            {
                "cityCode": "733",
                "cityName": "乌拉特前旗",
                "cityPinYin": "wulateqianqi",
                "cityGroupName": "W",
                "cityDistrict": "0478"
            },
            {
                "cityCode": "592",
                "cityName": "凉山彝族自治州",
                "cityPinYin": "liangshanyizuzizhizhou",
                "cityGroupName": "L",
                "cityDistrict": "0834"
            },
            {
                "cityCode": "34",
                "cityName": "长沙市",
                "cityPinYin": "changsha",
                "cityGroupName": "C",
                "cityDistrict": "0731"
            },
            {
                "cityCode": "180",
                "cityName": "临夏州",
                "cityPinYin": "linxiazhou",
                "cityGroupName": "L",
                "cityDistrict": "0930"
            },
            {
                "cityCode": "254",
                "cityName": "山南地区",
                "cityPinYin": "shannandiqu",
                "cityGroupName": "S",
                "cityDistrict": "0893"
            },
            {
                "cityCode": "435",
                "cityName": "东乌珠穆沁旗",
                "cityPinYin": "dongwuzhumuqinqi",
                "cityGroupName": "D",
                "cityDistrict": "0479"
            },
            {
                "cityCode": "268",
                "cityName": "通化市",
                "cityPinYin": "tonghua",
                "cityGroupName": "T",
                "cityDistrict": "0435"
            },
            {
                "cityCode": "198",
                "cityName": "内江市",
                "cityPinYin": "neijiang",
                "cityGroupName": "N",
                "cityDistrict": "1832"
            },
            {
                "cityCode": "292",
                "cityName": "西安市",
                "cityPinYin": "xian",
                "cityGroupName": "X",
                "cityDistrict": "029"
            },
            {
                "cityCode": "248",
                "cityName": "神农架林区",
                "cityPinYin": "shennongjialinqu",
                "cityGroupName": "S",
                "cityDistrict": "1719"
            },
            {
                "cityCode": "281",
                "cityName": "芜湖市",
                "cityPinYin": "wuhu",
                "cityGroupName": "W",
                "cityDistrict": "0553"
            },
            {
                "cityCode": "518",
                "cityName": "华阴市",
                "cityPinYin": "huayinshi",
                "cityGroupName": "H",
                "cityDistrict": "0913"
            },
            {
                "cityCode": "682",
                "cityName": "尚志市",
                "cityPinYin": "shangzhishi",
                "cityGroupName": "S",
                "cityDistrict": "0451"
            },
            {
                "cityCode": "342",
                "cityName": "张家口市",
                "cityPinYin": "zhangjiakou",
                "cityGroupName": "Z",
                "cityDistrict": "0313"
            },
            {
                "cityCode": "655",
                "cityName": "栖霞市",
                "cityPinYin": "qixiashi",
                "cityGroupName": "Q",
                "cityDistrict": "0535"
            },
            {
                "cityCode": "720",
                "cityName": "图们市",
                "cityPinYin": "tumenshi",
                "cityGroupName": "T",
                "cityDistrict": "1433"
            },
            {
                "cityCode": "388",
                "cityName": "博尔塔拉蒙古自治州",
                "cityPinYin": "bodongtalamengguzizhizhou",
                "cityGroupName": "B",
                "cityDistrict": "0909"
            },
            {
                "cityCode": "496",
                "cityName": "海宁市",
                "cityPinYin": "hainingshi",
                "cityGroupName": "H",
                "cityDistrict": "0573"
            },
            {
                "cityCode": "585",
                "cityName": "醴陵市",
                "cityPinYin": "lilingshi",
                "cityGroupName": "L",
                "cityDistrict": "0733"
            },
            {
                "cityCode": "510",
                "cityName": "鹤山市",
                "cityPinYin": "heshanshi",
                "cityGroupName": "H",
                "cityDistrict": "0750"
            },
            {
                "cityCode": "621",
                "cityName": "芒市",
                "cityPinYin": "mangshi",
                "cityGroupName": "M",
                "cityDistrict": "0692"
            },
            {
                "cityCode": "699",
                "cityName": "四子王旗",
                "cityPinYin": "siziwangqi",
                "cityGroupName": "S",
                "cityDistrict": "0474"
            },
            {
                "cityCode": "114",
                "cityName": "河源市",
                "cityPinYin": "heyuan",
                "cityGroupName": "H",
                "cityDistrict": "0762"
            },
            {
                "cityCode": "213",
                "cityName": "曲靖市",
                "cityPinYin": "qujing",
                "cityGroupName": "Q",
                "cityDistrict": "0874"
            },
            {
                "cityCode": "318",
                "cityName": "扬州市",
                "cityPinYin": "yangzhou",
                "cityGroupName": "Y",
                "cityDistrict": "0514"
            },
            {
                "cityCode": "821",
                "cityName": "庄河市",
                "cityPinYin": "zhuangheshi",
                "cityGroupName": "Z",
                "cityDistrict": "0411"
            },
            {
                "cityCode": "792",
                "cityName": "永安市",
                "cityPinYin": "yonganshi",
                "cityGroupName": "Y",
                "cityDistrict": "0598"
            },
            {
                "cityCode": "139",
                "cityName": "鸡西市",
                "cityPinYin": "jixi",
                "cityGroupName": "J",
                "cityDistrict": "0467"
            },
            {
                "cityCode": "221",
                "cityName": "黔东南苗族侗族自治州",
                "cityPinYin": "qiandongnanzhou",
                "cityGroupName": "Q",
                "cityDistrict": "0855"
            },
            {
                "cityCode": "149",
                "cityName": "喀什地区",
                "cityPinYin": "kashidiqu",
                "cityGroupName": "K",
                "cityDistrict": "0998"
            },
            {
                "cityCode": "91",
                "cityName": "合肥市",
                "cityPinYin": "hefei",
                "cityGroupName": "H",
                "cityDistrict": "0551"
            },
            {
                "cityCode": "600",
                "cityName": "临湘市",
                "cityPinYin": "linxiangshi",
                "cityGroupName": "L",
                "cityDistrict": "0730"
            },
            {
                "cityCode": "320",
                "cityName": "运城市",
                "cityPinYin": "yuncheng",
                "cityGroupName": "Y",
                "cityDistrict": "0359"
            },
            {
                "cityCode": "756",
                "cityName": "项城市",
                "cityPinYin": "xiangchengshi",
                "cityGroupName": "X",
                "cityDistrict": "0394"
            },
            {
                "cityCode": "521",
                "cityName": "桦甸市",
                "cityPinYin": "huadianshi",
                "cityGroupName": "H",
                "cityDistrict": "0432"
            },
            {
                "cityCode": "810",
                "cityName": "张家港市",
                "cityPinYin": "zhangjiagangshi",
                "cityGroupName": "Z",
                "cityDistrict": "0512"
            },
            {
                "cityCode": "229",
                "cityName": "沈阳市",
                "cityPinYin": "shenyang",
                "cityGroupName": "S",
                "cityDistrict": "024"
            },
            {
                "cityCode": "488",
                "cityName": "哈密市",
                "cityPinYin": "hamishi",
                "cityGroupName": "H",
                "cityDistrict": "0902"
            },
            {
                "cityCode": "62",
                "cityName": "儋州市",
                "cityPinYin": "danzhou",
                "cityGroupName": "D",
                "cityDistrict": "0113"
            },
            {
                "cityCode": "43",
                "cityName": "池州市",
                "cityPinYin": "chizhou",
                "cityGroupName": "C",
                "cityDistrict": "0566"
            },
            {
                "cityCode": "364",
                "cityName": "阿拉善右旗",
                "cityPinYin": "alashanyouqi",
                "cityGroupName": "A",
                "cityDistrict": "0483"
            },
            {
                "cityCode": "341",
                "cityName": "淄博市",
                "cityPinYin": "zibo",
                "cityGroupName": "Z",
                "cityDistrict": "0533"
            },
            {
                "cityCode": "44",
                "cityName": "赤峰市",
                "cityPinYin": "chifeng",
                "cityGroupName": "C",
                "cityDistrict": "0476"
            },
            {
                "cityCode": "415",
                "cityName": "大理白族自治州",
                "cityPinYin": "dalibaizuzizhizhou",
                "cityGroupName": "D",
                "cityDistrict": "0872"
            },
            {
                "cityCode": "546",
                "cityName": "京山市",
                "cityPinYin": "jingshanshi",
                "cityGroupName": "J",
                "cityDistrict": "0724"
            },
            {
                "cityCode": "808",
                "cityName": "扎兰屯市",
                "cityPinYin": "zhalantunshi",
                "cityGroupName": "Z",
                "cityDistrict": "0470"
            },
            {
                "cityCode": "159",
                "cityName": "莱芜市",
                "cityPinYin": "laiwu",
                "cityGroupName": "L",
                "cityDistrict": "0531"
            },
            {
                "cityCode": "639",
                "cityName": "讷河市",
                "cityPinYin": "neheshi",
                "cityGroupName": "N",
                "cityDistrict": "0452"
            },
            {
                "cityCode": "525",
                "cityName": "珲春市",
                "cityPinYin": "hunchunshi",
                "cityGroupName": "H",
                "cityDistrict": "1433"
            },
            {
                "cityCode": "428",
                "cityName": "邓州市",
                "cityPinYin": "dengzhoushi",
                "cityGroupName": "D",
                "cityDistrict": "0377"
            },
            {
                "cityCode": "201",
                "cityName": "平顶山市",
                "cityPinYin": "pingdingshan",
                "cityGroupName": "P",
                "cityDistrict": "0375"
            },
            {
                "cityCode": "1",
                "cityName": "鞍山市",
                "cityPinYin": "anshan",
                "cityGroupName": "A",
                "cityDistrict": "0412"
            },
            {
                "cityCode": "356",
                "cityName": "昭通市",
                "cityPinYin": "zhaotong",
                "cityGroupName": "Z",
                "cityDistrict": "0870"
            },
            {
                "cityCode": "440",
                "cityName": "敦化市",
                "cityPinYin": "donghuashi",
                "cityGroupName": "D",
                "cityDistrict": "1433"
            },
            {
                "cityCode": "647",
                "cityName": "彭州市",
                "cityPinYin": "pengzhoushi",
                "cityGroupName": "P",
                "cityDistrict": "028"
            },
            {
                "cityCode": "566",
                "cityName": "库车市",
                "cityPinYin": "kucheshi",
                "cityGroupName": "K",
                "cityDistrict": "0997"
            },
            {
                "cityCode": "211",
                "cityName": "秦皇岛市",
                "cityPinYin": "qinhuangdao",
                "cityGroupName": "Q",
                "cityDistrict": "0335"
            },
            {
                "cityCode": "780",
                "cityName": "伊金霍洛旗",
                "cityPinYin": "yijinhuoluoqi",
                "cityGroupName": "Y",
                "cityDistrict": "0477"
            },
            {
                "cityCode": "167",
                "cityName": "吕梁市",
                "cityPinYin": "lvliang",
                "cityGroupName": "L",
                "cityDistrict": "0358"
            },
            {
                "cityCode": "477",
                "cityName": "根河市",
                "cityPinYin": "genheshi",
                "cityGroupName": "G",
                "cityDistrict": "0470"
            },
            {
                "cityCode": "444",
                "cityName": "额济纳旗",
                "cityPinYin": "dongjinaqi",
                "cityGroupName": "E",
                "cityDistrict": "0483"
            },
            {
                "cityCode": "68",
                "cityName": "定西市",
                "cityPinYin": "dingxi",
                "cityGroupName": "D",
                "cityDistrict": "0932"
            },
            {
                "cityCode": "796",
                "cityName": "余姚市",
                "cityPinYin": "yuyaoshi",
                "cityGroupName": "Y",
                "cityDistrict": "0574"
            },
            {
                "cityCode": "453",
                "cityName": "肥城市",
                "cityPinYin": "feichengshi",
                "cityGroupName": "F",
                "cityDistrict": "0538"
            },
            {
                "cityCode": "701",
                "cityName": "苏尼特右旗",
                "cityPinYin": "suniteyouqi",
                "cityGroupName": "S",
                "cityDistrict": "0479"
            },
            {
                "cityCode": "49",
                "cityName": "昌都地区",
                "cityPinYin": "changdudiqu",
                "cityGroupName": "C",
                "cityDistrict": "0895"
            },
            {
                "cityCode": "471",
                "cityName": "高密市",
                "cityPinYin": "gaomishi",
                "cityGroupName": "G",
                "cityDistrict": "0536"
            },
            {
                "cityCode": "476",
                "cityName": "个旧市",
                "cityPinYin": "gejiushi",
                "cityGroupName": "G",
                "cityDistrict": "0873"
            },
            {
                "cityCode": "490",
                "cityName": "海北藏族自治州",
                "cityPinYin": "haibeicangzuzizhizhou",
                "cityGroupName": "H",
                "cityDistrict": "0970"
            },
            {
                "cityCode": "711",
                "cityName": "滕州市",
                "cityPinYin": "tengzhoushi",
                "cityGroupName": "T",
                "cityDistrict": "0632"
            },
            {
                "cityCode": "196",
                "cityName": "宁德市",
                "cityPinYin": "ningde",
                "cityGroupName": "N",
                "cityDistrict": "0593"
            },
            {
                "cityCode": "256",
                "cityName": "石嘴山市",
                "cityPinYin": "shizuishan",
                "cityGroupName": "S",
                "cityDistrict": "0952"
            },
            {
                "cityCode": "50",
                "cityName": "昌吉州",
                "cityPinYin": "changjizhou",
                "cityGroupName": "C",
                "cityDistrict": "0994"
            },
            {
                "cityCode": "103",
                "cityName": "惠州市",
                "cityPinYin": "huizhou",
                "cityGroupName": "H",
                "cityDistrict": "0752"
            },
            {
                "cityCode": "569",
                "cityName": "奎屯市",
                "cityPinYin": "kuitunshi",
                "cityGroupName": "K",
                "cityDistrict": "0999"
            },
            {
                "cityCode": "308",
                "cityName": "咸宁市",
                "cityPinYin": "xianning",
                "cityGroupName": "X",
                "cityDistrict": "0715"
            },
            {
                "cityCode": "479",
                "cityName": "巩义市",
                "cityPinYin": "gongyishi",
                "cityGroupName": "G",
                "cityDistrict": "0371"
            },
            {
                "cityCode": "414",
                "cityName": "大安市",
                "cityPinYin": "daanshi",
                "cityGroupName": "D",
                "cityDistrict": "0436"
            },
            {
                "cityCode": "776",
                "cityName": "延边朝鲜族自治州",
                "cityPinYin": "yanbianchaoxianzuzizhizhou",
                "cityGroupName": "Y",
                "cityDistrict": "1433"
            },
            {
                "cityCode": "179",
                "cityName": "陇南市",
                "cityPinYin": "longnan",
                "cityGroupName": "L",
                "cityDistrict": "2935"
            },
            {
                "cityCode": "400",
                "cityName": "常宁市",
                "cityPinYin": "changningshi",
                "cityGroupName": "C",
                "cityDistrict": "0734"
            },
            {
                "cityCode": "296",
                "cityName": "湘潭市",
                "cityPinYin": "xiangtan",
                "cityGroupName": "X",
                "cityDistrict": "0732"
            },
            {
                "cityCode": "710",
                "cityName": "腾冲市",
                "cityPinYin": "tengchongshi",
                "cityGroupName": "T",
                "cityDistrict": "0875"
            },
            {
                "cityCode": "535",
                "cityName": "建瓯市",
                "cityPinYin": "jianoushi",
                "cityGroupName": "J",
                "cityDistrict": "0599"
            },
            {
                "cityCode": "558",
                "cityName": "康定市",
                "cityPinYin": "kangdingshi",
                "cityGroupName": "K",
                "cityDistrict": "0836"
            },
            {
                "cityCode": "758",
                "cityName": "辛集市",
                "cityPinYin": "xinjishi",
                "cityGroupName": "X",
                "cityDistrict": "0311"
            },
            {
                "cityCode": "736",
                "cityName": "乌审旗",
                "cityPinYin": "wushenqi",
                "cityGroupName": "W",
                "cityDistrict": "0477"
            },
            {
                "cityCode": "578",
                "cityName": "乐昌市",
                "cityPinYin": "lechangshi",
                "cityGroupName": "L",
                "cityDistrict": "0751"
            },
            {
                "cityCode": "541",
                "cityName": "介休市",
                "cityPinYin": "jiexiushi",
                "cityGroupName": "J",
                "cityDistrict": "0354"
            },
            {
                "cityCode": "275",
                "cityName": "武汉市",
                "cityPinYin": "wuhan",
                "cityGroupName": "W",
                "cityDistrict": "027"
            },
            {
                "cityCode": "804",
                "cityName": "沅江市",
                "cityPinYin": "yuanjiangshi",
                "cityGroupName": "Y",
                "cityDistrict": "0737"
            },
            {
                "cityCode": "713",
                "cityName": "铁力市",
                "cityPinYin": "tielishi",
                "cityGroupName": "T",
                "cityDistrict": "0458"
            },
            {
                "cityCode": "337",
                "cityName": "伊犁州",
                "cityPinYin": "yilizhou",
                "cityGroupName": "Y",
                "cityDistrict": "0999"
            },
            {
                "cityCode": "146",
                "cityName": "酒泉市",
                "cityPinYin": "jiuquan",
                "cityGroupName": "J",
                "cityDistrict": "0937"
            },
            {
                "cityCode": "716",
                "cityName": "同仁市",
                "cityPinYin": "tongrenshi",
                "cityGroupName": "T",
                "cityDistrict": "0973"
            },
            {
                "cityCode": "423",
                "cityName": "德惠市",
                "cityPinYin": "dehuishi",
                "cityGroupName": "D",
                "cityDistrict": "0431"
            },
            {
                "cityCode": "116",
                "cityName": "河池市",
                "cityPinYin": "hechi",
                "cityGroupName": "H",
                "cityDistrict": "0778"
            },
            {
                "cityCode": "789",
                "cityName": "应城市",
                "cityPinYin": "yingchengshi",
                "cityGroupName": "Y",
                "cityDistrict": "0712"
            },
            {
                "cityCode": "802",
                "cityName": "玉树藏族自治州",
                "cityPinYin": "yushucangzuzizhizhou",
                "cityGroupName": "Y",
                "cityDistrict": "0976"
            },
            {
                "cityCode": "238",
                "cityName": "商丘市",
                "cityPinYin": "shangqiu",
                "cityGroupName": "S",
                "cityDistrict": "0370"
            },
            {
                "cityCode": "550",
                "cityName": "靖西市",
                "cityPinYin": "jingxishi",
                "cityGroupName": "J",
                "cityDistrict": "0776"
            },
            {
                "cityCode": "729",
                "cityName": "文山市",
                "cityPinYin": "wenshanshi",
                "cityGroupName": "W",
                "cityDistrict": "0876"
            },
            {
                "cityCode": "512",
                "cityName": "洪湖市",
                "cityPinYin": "honghushi",
                "cityGroupName": "H",
                "cityDistrict": "0716"
            },
            {
                "cityCode": "96",
                "cityName": "衡水市",
                "cityPinYin": "hengshui",
                "cityGroupName": "H",
                "cityDistrict": "0318"
            },
            {
                "cityCode": "814",
                "cityName": "肇东市",
                "cityPinYin": "zhaodongshi",
                "cityGroupName": "Z",
                "cityDistrict": "0455"
            },
            {
                "cityCode": "319",
                "cityName": "盐城市",
                "cityPinYin": "yancheng",
                "cityGroupName": "Y",
                "cityDistrict": "0515"
            },
            {
                "cityCode": "302",
                "cityName": "新余市",
                "cityPinYin": "xinyu",
                "cityGroupName": "X",
                "cityDistrict": "0790"
            },
            {
                "cityCode": "685",
                "cityName": "邵武市",
                "cityPinYin": "shaowushi",
                "cityGroupName": "S",
                "cityDistrict": "0599"
            },
            {
                "cityCode": "73",
                "cityName": "佛山市",
                "cityPinYin": "foshan",
                "cityGroupName": "F",
                "cityDistrict": "0757"
            },
            {
                "cityCode": "186",
                "cityName": "茂名市",
                "cityPinYin": "maoming",
                "cityGroupName": "M",
                "cityDistrict": "0668"
            },
            {
                "cityCode": "643",
                "cityName": "宁乡市",
                "cityPinYin": "ningxiangshi",
                "cityGroupName": "N",
                "cityDistrict": "0731"
            },
            {
                "cityCode": "328",
                "cityName": "鹰潭市",
                "cityPinYin": "yingtan",
                "cityGroupName": "Y",
                "cityDistrict": "0701"
            },
            {
                "cityCode": "59",
                "cityName": "丹东市",
                "cityPinYin": "dandong",
                "cityGroupName": "D",
                "cityDistrict": "0415"
            },
            {
                "cityCode": "242",
                "cityName": "松原市",
                "cityPinYin": "songyuan",
                "cityGroupName": "S",
                "cityDistrict": "0438"
            },
            {
                "cityCode": "422",
                "cityName": "德宏傣族景颇族自治州",
                "cityPinYin": "dehongdaizujingpozuzizhizhou",
                "cityGroupName": "D",
                "cityDistrict": "0692"
            },
            {
                "cityCode": "82",
                "cityName": "桂林市",
                "cityPinYin": "guilin",
                "cityGroupName": "G",
                "cityDistrict": "0773"
            },
            {
                "cityCode": "108",
                "cityName": "鹤岗市",
                "cityPinYin": "hegang",
                "cityGroupName": "H",
                "cityDistrict": "0468"
            },
            {
                "cityCode": "289",
                "cityName": "渭南市",
                "cityPinYin": "weinan",
                "cityGroupName": "W",
                "cityDistrict": "0913"
            },
            {
                "cityCode": "307",
                "cityName": "孝感市",
                "cityPinYin": "xiaogan",
                "cityGroupName": "X",
                "cityDistrict": "0712"
            },
            {
                "cityCode": "795",
                "cityName": "永康市",
                "cityPinYin": "yongkangshi",
                "cityGroupName": "Y",
                "cityDistrict": "0579"
            },
            {
                "cityCode": "449",
                "cityName": "恩平市",
                "cityPinYin": "dongpingshi",
                "cityGroupName": "E",
                "cityDistrict": "0750"
            },
            {
                "cityCode": "828",
                "cityName": "遵化市",
                "cityPinYin": "zunhuashi",
                "cityGroupName": "Z",
                "cityDistrict": "0315"
            },
            {
                "cityCode": "579",
                "cityName": "乐陵市",
                "cityPinYin": "lelingshi",
                "cityGroupName": "L",
                "cityDistrict": "0534"
            },
            {
                "cityCode": "158",
                "cityName": "泸州市",
                "cityPinYin": "luzhou",
                "cityGroupName": "L",
                "cityDistrict": "0830"
            },
            {
                "cityCode": "563",
                "cityName": "可克达拉市",
                "cityPinYin": "kekedalashi",
                "cityGroupName": "K",
                "cityDistrict": "1999"
            },
            {
                "cityCode": "120",
                "cityName": "黄南州",
                "cityPinYin": "huangnanzhou",
                "cityGroupName": "H",
                "cityDistrict": "0973"
            },
            {
                "cityCode": "441",
                "cityName": "敦煌市",
                "cityPinYin": "donghuangshi",
                "cityGroupName": "D",
                "cityDistrict": "0937"
            },
            {
                "cityCode": "261",
                "cityName": "铁岭市",
                "cityPinYin": "tieling",
                "cityGroupName": "T",
                "cityDistrict": "0410"
            },
            {
                "cityCode": "339",
                "cityName": "遵义市",
                "cityPinYin": "zunyi",
                "cityGroupName": "Z",
                "cityDistrict": "0852"
            },
            {
                "cityCode": "152",
                "cityName": "洛阳市",
                "cityPinYin": "luoyang",
                "cityGroupName": "L",
                "cityDistrict": "0379"
            },
            {
                "cityCode": "778",
                "cityName": "扬中市",
                "cityPinYin": "yangzhongshi",
                "cityGroupName": "Y",
                "cityDistrict": "0511"
            },
            {
                "cityCode": "545",
                "cityName": "晋州市",
                "cityPinYin": "jinzhoushi",
                "cityGroupName": "J",
                "cityDistrict": "0311"
            },
            {
                "cityCode": "205",
                "cityName": "濮阳市",
                "cityPinYin": "puyang",
                "cityGroupName": "P",
                "cityDistrict": "0393"
            },
            {
                "cityCode": "805",
                "cityName": "原平市",
                "cityPinYin": "yuanpingshi",
                "cityGroupName": "Y",
                "cityDistrict": "0350"
            },
            {
                "cityCode": "367",
                "cityName": "阿鲁科尔沁旗",
                "cityPinYin": "alukedongqinqi",
                "cityGroupName": "A",
                "cityDistrict": "0476"
            },
            {
                "cityCode": "482",
                "cityName": "广德市",
                "cityPinYin": "guangdeshi",
                "cityGroupName": "G",
                "cityDistrict": "0563"
            },
            {
                "cityCode": "818",
                "cityName": "钟祥市",
                "cityPinYin": "zhongxiangshi",
                "cityGroupName": "Z",
                "cityDistrict": "0724"
            },
            {
                "cityCode": "334",
                "cityName": "雅安市",
                "cityPinYin": "yaan",
                "cityGroupName": "Y",
                "cityDistrict": "0835"
            },
            {
                "cityCode": "536",
                "cityName": "江山市",
                "cityPinYin": "jiangshanshi",
                "cityGroupName": "J",
                "cityDistrict": "0570"
            },
            {
                "cityCode": "325",
                "cityName": "延安市",
                "cityPinYin": "yanan",
                "cityGroupName": "Y",
                "cityDistrict": "0911"
            },
            {
                "cityCode": "588",
                "cityName": "溧阳市",
                "cityPinYin": "liyangshi",
                "cityGroupName": "L",
                "cityDistrict": "0519"
            },
            {
                "cityCode": "13",
                "cityName": "包头市",
                "cityPinYin": "baotou",
                "cityGroupName": "B",
                "cityDistrict": "0472"
            },
            {
                "cityCode": "330",
                "cityName": "益阳市",
                "cityPinYin": "yiyang",
                "cityGroupName": "Y",
                "cityDistrict": "0737"
            },
            {
                "cityCode": "282",
                "cityName": "乌海市",
                "cityPinYin": "wuhai",
                "cityGroupName": "W",
                "cityDistrict": "0473"
            },
            {
                "cityCode": "346",
                "cityName": "中山市",
                "cityPinYin": "zhongshan",
                "cityGroupName": "Z",
                "cityDistrict": "0760"
            },
            {
                "cityCode": "264",
                "cityName": "泰安市",
                "cityPinYin": "taian",
                "cityGroupName": "T",
                "cityDistrict": "0538"
            },
            {
                "cityCode": "349",
                "cityName": "湛江市",
                "cityPinYin": "zhanjiang",
                "cityGroupName": "Z",
                "cityDistrict": "0759"
            },
            {
                "cityCode": "478",
                "cityName": "公主岭市",
                "cityPinYin": "gongzhulingshi",
                "cityGroupName": "G",
                "cityDistrict": "0434"
            },
            {
                "cityCode": "324",
                "cityName": "玉林市",
                "cityPinYin": "yulin",
                "cityGroupName": "Y",
                "cityDistrict": "0775"
            },
            {
                "cityCode": "259",
                "cityName": "太原市",
                "cityPinYin": "taiyuan",
                "cityGroupName": "T",
                "cityDistrict": "0351"
            },
            {
                "cityCode": "698",
                "cityName": "四会市",
                "cityPinYin": "sihuishi",
                "cityGroupName": "S",
                "cityDistrict": "0758"
            },
            {
                "cityCode": "402",
                "cityName": "巢湖市",
                "cityPinYin": "chaohushi",
                "cityGroupName": "C",
                "cityDistrict": "0551"
            },
            {
                "cityCode": "426",
                "cityName": "灯塔市",
                "cityPinYin": "dengtashi",
                "cityGroupName": "D",
                "cityDistrict": "0419"
            },
            {
                "cityCode": "274",
                "cityName": "塔城地区",
                "cityPinYin": "tachengdiqu",
                "cityGroupName": "T",
                "cityDistrict": "0901"
            },
            {
                "cityCode": "123",
                "cityName": "哈密地区",
                "cityPinYin": "hamidiqu",
                "cityGroupName": "H",
                "cityDistrict": "0902"
            },
            {
                "cityCode": "366",
                "cityName": "阿勒泰市",
                "cityPinYin": "aletaishi",
                "cityGroupName": "A",
                "cityDistrict": "0906"
            },
            {
                "cityCode": "529",
                "cityName": "霍州市",
                "cityPinYin": "huozhoushi",
                "cityGroupName": "H",
                "cityDistrict": "0357"
            },
            {
                "cityCode": "816",
                "cityName": "正镶白旗",
                "cityPinYin": "zhengxiangbaiqi",
                "cityGroupName": "Z",
                "cityDistrict": "0479"
            },
            {
                "cityCode": "407",
                "cityName": "赤水市",
                "cityPinYin": "chishuishi",
                "cityGroupName": "C",
                "cityDistrict": "0852"
            },
            {
                "cityCode": "391",
                "cityName": "察哈尔右翼后旗",
                "cityPinYin": "chahadongyouyihouqi",
                "cityGroupName": "C",
                "cityDistrict": "0474"
            },
            {
                "cityCode": "411",
                "cityName": "慈溪市",
                "cityPinYin": "cixishi",
                "cityGroupName": "C",
                "cityDistrict": "0574"
            },
            {
                "cityCode": "703",
                "cityName": "绥芬河市",
                "cityPinYin": "suifenheshi",
                "cityGroupName": "S",
                "cityDistrict": "0453"
            },
            {
                "cityCode": "820",
                "cityName": "诸暨市",
                "cityPinYin": "zhujishi",
                "cityGroupName": "Z",
                "cityDistrict": "0575"
            },
            {
                "cityCode": "432",
                "cityName": "东港市",
                "cityPinYin": "donggangshi",
                "cityGroupName": "D",
                "cityDistrict": "0415"
            },
            {
                "cityCode": "162",
                "cityName": "丽江市",
                "cityPinYin": "lijiang",
                "cityGroupName": "L",
                "cityDistrict": "0888"
            },
            {
                "cityCode": "425",
                "cityName": "德兴市",
                "cityPinYin": "dexingshi",
                "cityGroupName": "D",
                "cityDistrict": "0793"
            },
            {
                "cityCode": "809",
                "cityName": "扎鲁特旗",
                "cityPinYin": "zhaluteqi",
                "cityGroupName": "Z",
                "cityDistrict": "0475"
            },
            {
                "cityCode": "70",
                "cityName": "鄂州市",
                "cityPinYin": "ezhou",
                "cityGroupName": "E",
                "cityDistrict": "0711"
            },
            {
                "cityCode": "632",
                "cityName": "漠河市",
                "cityPinYin": "moheshi",
                "cityGroupName": "M",
                "cityDistrict": "0457"
            },
            {
                "cityCode": "395",
                "cityName": "昌吉回族自治州",
                "cityPinYin": "changjihuizuzizhizhou",
                "cityGroupName": "C",
                "cityDistrict": "0994"
            },
            {
                "cityCode": "287",
                "cityName": "万宁市",
                "cityPinYin": "wanning",
                "cityGroupName": "W",
                "cityDistrict": "1898"
            },
            {
                "cityCode": "657",
                "cityName": "迁安市",
                "cityPinYin": "qiananshi",
                "cityGroupName": "Q",
                "cityDistrict": "0315"
            },
            {
                "cityCode": "636",
                "cityName": "南安市",
                "cityPinYin": "nananshi",
                "cityGroupName": "N",
                "cityDistrict": "0595"
            },
            {
                "cityCode": "737",
                "cityName": "乌苏市",
                "cityPinYin": "wusushi",
                "cityGroupName": "W",
                "cityDistrict": "0901"
            },
            {
                "cityCode": "379",
                "cityName": "霸州市",
                "cityPinYin": "bazhoushi",
                "cityGroupName": "B",
                "cityDistrict": "0316"
            },
            {
                "cityCode": "533",
                "cityName": "简阳市",
                "cityPinYin": "jianyangshi",
                "cityGroupName": "J",
                "cityDistrict": "028"
            },
            {
                "cityCode": "338",
                "cityName": "郑州市",
                "cityPinYin": "zhengzhou",
                "cityGroupName": "Z",
                "cityDistrict": "0371"
            },
            {
                "cityCode": "194",
                "cityName": "南通市",
                "cityPinYin": "nantong",
                "cityGroupName": "N",
                "cityDistrict": "0513"
            },
            {
                "cityCode": "303",
                "cityName": "宣城市",
                "cityPinYin": "xuancheng",
                "cityGroupName": "X",
                "cityDistrict": "0563"
            },
            {
                "cityCode": "224",
                "cityName": "琼中黎族苗族自治县",
                "cityPinYin": "qiongzhonglizumiaozuzizhixian",
                "cityGroupName": "Q",
                "cityDistrict": "1899"
            },
            {
                "cityCode": "143",
                "cityName": "济源市",
                "cityPinYin": "jiyuan",
                "cityGroupName": "J",
                "cityDistrict": "1391"
            },
            {
                "cityCode": "260",
                "cityName": "唐山市",
                "cityPinYin": "tangshan",
                "cityGroupName": "T",
                "cityDistrict": "0315"
            },
            {
                "cityCode": "230",
                "cityName": "石家庄市",
                "cityPinYin": "shijiazhuang",
                "cityGroupName": "S",
                "cityDistrict": "0311"
            },
            {
                "cityCode": "228",
                "cityName": "上海市",
                "cityPinYin": "shanghai",
                "cityGroupName": "S",
                "cityDistrict": "021"
            },
            {
                "cityCode": "429",
                "cityName": "迪庆藏族自治州",
                "cityPinYin": "diqingcangzuzizhizhou",
                "cityGroupName": "D",
                "cityDistrict": "0887"
            },
            {
                "cityCode": "500",
                "cityName": "韩城市",
                "cityPinYin": "hanchengshi",
                "cityGroupName": "H",
                "cityDistrict": "0913"
            },
            {
                "cityCode": "672",
                "cityName": "如皋市",
                "cityPinYin": "rugaoshi",
                "cityGroupName": "R",
                "cityDistrict": "0513"
            },
            {
                "cityCode": "614",
                "cityName": "泸水市",
                "cityPinYin": "lushuishi",
                "cityGroupName": "L",
                "cityDistrict": "0886"
            },
            {
                "cityCode": "503",
                "cityName": "杭锦旗",
                "cityPinYin": "hangjinqi",
                "cityGroupName": "H",
                "cityDistrict": "0477"
            },
            {
                "cityCode": "492",
                "cityName": "海东市",
                "cityPinYin": "haidongshi",
                "cityGroupName": "H",
                "cityDistrict": "0972"
            },
            {
                "cityCode": "317",
                "cityName": "营口市",
                "cityPinYin": "yingkou",
                "cityGroupName": "Y",
                "cityDistrict": "0417"
            },
            {
                "cityCode": "294",
                "cityName": "厦门市",
                "cityPinYin": "xiamen",
                "cityGroupName": "X",
                "cityDistrict": "0592"
            },
            {
                "cityCode": "94",
                "cityName": "邯郸市",
                "cityPinYin": "handan",
                "cityGroupName": "H",
                "cityDistrict": "0310"
            },
            {
                "cityCode": "75",
                "cityName": "阜新市",
                "cityPinYin": "fuxin",
                "cityGroupName": "F",
                "cityDistrict": "0418"
            },
            {
                "cityCode": "680",
                "cityName": "沙河市",
                "cityPinYin": "shaheshi",
                "cityGroupName": "S",
                "cityDistrict": "0319"
            },
            {
                "cityCode": "634",
                "cityName": "那曲市",
                "cityPinYin": "naqushi",
                "cityGroupName": "N",
                "cityDistrict": "0896"
            },
            {
                "cityCode": "696",
                "cityName": "双辽市",
                "cityPinYin": "shuangliaoshi",
                "cityGroupName": "S",
                "cityDistrict": "0434"
            },
            {
                "cityCode": "493",
                "cityName": "海林市",
                "cityPinYin": "hailinshi",
                "cityGroupName": "H",
                "cityDistrict": "0453"
            },
            {
                "cityCode": "513",
                "cityName": "洪江市",
                "cityPinYin": "hongjiangshi",
                "cityGroupName": "H",
                "cityDistrict": "0745"
            },
            {
                "cityCode": "128",
                "cityName": "吉林市",
                "cityPinYin": "jilin",
                "cityGroupName": "J",
                "cityDistrict": "0432"
            },
            {
                "cityCode": "112",
                "cityName": "怀化市",
                "cityPinYin": "huaihua",
                "cityGroupName": "H",
                "cityDistrict": "0745"
            },
            {
                "cityCode": "516",
                "cityName": "虎林市",
                "cityPinYin": "hulinshi",
                "cityGroupName": "H",
                "cityDistrict": "0467"
            },
            {
                "cityCode": "443",
                "cityName": "额尔古纳市",
                "cityPinYin": "dongdonggunashi",
                "cityGroupName": "E",
                "cityDistrict": "0470"
            },
            {
                "cityCode": "280",
                "cityName": "温州市",
                "cityPinYin": "wenzhou",
                "cityGroupName": "W",
                "cityDistrict": "0577"
            },
            {
                "cityCode": "85",
                "cityName": "广安市",
                "cityPinYin": "guangan",
                "cityGroupName": "G",
                "cityDistrict": "0826"
            },
            {
                "cityCode": "549",
                "cityName": "靖江市",
                "cityPinYin": "jingjiangshi",
                "cityGroupName": "J",
                "cityDistrict": "0523"
            },
            {
                "cityCode": "348",
                "cityName": "舟山市",
                "cityPinYin": "zhoushan",
                "cityGroupName": "Z",
                "cityDistrict": "0580"
            },
            {
                "cityCode": "227",
                "cityName": "深圳市",
                "cityPinYin": "shenzhen",
                "cityGroupName": "S",
                "cityDistrict": "0755"
            },
            {
                "cityCode": "555",
                "cityName": "开原市",
                "cityPinYin": "kaiyuanshi",
                "cityGroupName": "K",
                "cityDistrict": "0410"
            },
            {
                "cityCode": "199",
                "cityName": "怒江州",
                "cityPinYin": "nujiangzhou",
                "cityGroupName": "N",
                "cityDistrict": "0886"
            },
            {
                "cityCode": "674",
                "cityName": "乳山市",
                "cityPinYin": "rushanshi",
                "cityGroupName": "R",
                "cityDistrict": "0631"
            },
            {
                "cityCode": "125",
                "cityName": "济南市",
                "cityPinYin": "jinan",
                "cityGroupName": "J",
                "cityDistrict": "0531"
            },
            {
                "cityCode": "306",
                "cityName": "兴安盟",
                "cityPinYin": "xinganmeng",
                "cityGroupName": "X",
                "cityDistrict": "0482"
            },
            {
                "cityCode": "739",
                "cityName": "吴川市",
                "cityPinYin": "wuchuanshi",
                "cityGroupName": "W",
                "cityDistrict": "0759"
            },
            {
                "cityCode": "582",
                "cityName": "雷州市",
                "cityPinYin": "leizhoushi",
                "cityGroupName": "L",
                "cityDistrict": "0759"
            },
            {
                "cityCode": "656",
                "cityName": "启东市",
                "cityPinYin": "qidongshi",
                "cityGroupName": "Q",
                "cityDistrict": "0513"
            },
            {
                "cityCode": "61",
                "cityName": "大兴安岭地区",
                "cityPinYin": "daxinganlingdiqu",
                "cityGroupName": "D",
                "cityDistrict": "0457"
            },
            {
                "cityCode": "14",
                "cityName": "本溪市",
                "cityPinYin": "benxi",
                "cityGroupName": "B",
                "cityDistrict": "0414"
            },
            {
                "cityCode": "817",
                "cityName": "枝江市",
                "cityPinYin": "zhijiangshi",
                "cityGroupName": "Z",
                "cityDistrict": "0717"
            },
            {
                "cityCode": "304",
                "cityName": "忻州市",
                "cityPinYin": "xinzhou",
                "cityGroupName": "X",
                "cityDistrict": "0350"
            },
            {
                "cityCode": "418",
                "cityName": "大冶市",
                "cityPinYin": "dayeshi",
                "cityGroupName": "D",
                "cityDistrict": "0714"
            },
            {
                "cityCode": "543",
                "cityName": "津市市",
                "cityPinYin": "jinshishi",
                "cityGroupName": "J",
                "cityDistrict": "0736"
            },
            {
                "cityCode": "340",
                "cityName": "株洲市",
                "cityPinYin": "zhuzhou",
                "cityGroupName": "Z",
                "cityDistrict": "0733"
            },
            {
                "cityCode": "218",
                "cityName": "钦州市",
                "cityPinYin": "qinzhou",
                "cityGroupName": "Q",
                "cityDistrict": "0777"
            },
            {
                "cityCode": "163",
                "cityName": "丽水市",
                "cityPinYin": "lishui",
                "cityGroupName": "L",
                "cityDistrict": "0578"
            },
            {
                "cityCode": "749",
                "cityName": "西双版纳傣族自治州",
                "cityPinYin": "xishuangbannadaizuzizhizhou",
                "cityGroupName": "X",
                "cityDistrict": "0691"
            },
            {
                "cityCode": "88",
                "cityName": "固原市",
                "cityPinYin": "guyuan",
                "cityGroupName": "G",
                "cityDistrict": "0954"
            },
            {
                "cityCode": "376",
                "cityName": "巴林右旗",
                "cityPinYin": "balinyouqi",
                "cityGroupName": "B",
                "cityDistrict": "0476"
            },
            {
                "cityCode": "301",
                "cityName": "咸阳市",
                "cityPinYin": "xianyang",
                "cityGroupName": "X",
                "cityDistrict": "0910"
            },
            {
                "cityCode": "777",
                "cityName": "偃师市",
                "cityPinYin": "yanshishi",
                "cityGroupName": "Y",
                "cityDistrict": "0379"
            },
            {
                "cityCode": "249",
                "cityName": "邵阳市",
                "cityPinYin": "shaoyang",
                "cityGroupName": "S",
                "cityDistrict": "0739"
            },
            {
                "cityCode": "571",
                "cityName": "昆玉市",
                "cityPinYin": "kunyushi",
                "cityGroupName": "K",
                "cityDistrict": "1903"
            },
            {
                "cityCode": "133",
                "cityName": "荆州市",
                "cityPinYin": "jingzhou",
                "cityGroupName": "J",
                "cityDistrict": "0716"
            },
            {
                "cityCode": "104",
                "cityName": "黄山市",
                "cityPinYin": "huangshan",
                "cityGroupName": "H",
                "cityDistrict": "0559"
            },
            {
                "cityCode": "743",
                "cityName": "武安市",
                "cityPinYin": "wuanshi",
                "cityGroupName": "W",
                "cityDistrict": "0310"
            },
            {
                "cityCode": "86",
                "cityName": "甘孜州",
                "cityPinYin": "ganzizhou",
                "cityGroupName": "G",
                "cityDistrict": "0836"
            },
            {
                "cityCode": "298",
                "cityName": "襄阳市",
                "cityPinYin": "xiangyang",
                "cityGroupName": "X",
                "cityDistrict": "0710"
            },
            {
                "cityCode": "24",
                "cityName": "白沙黎族自治县",
                "cityPinYin": "baishalizuzizhixian",
                "cityGroupName": "B",
                "cityDistrict": "0802"
            },
            {
                "cityCode": "469",
                "cityName": "高安市",
                "cityPinYin": "gaoanshi",
                "cityGroupName": "G",
                "cityDistrict": "0795"
            },
            {
                "cityCode": "519",
                "cityName": "华蓥市",
                "cityPinYin": "huayingshi",
                "cityGroupName": "H",
                "cityDistrict": "0826"
            },
            {
                "cityCode": "189",
                "cityName": "南京市",
                "cityPinYin": "nanjing",
                "cityGroupName": "N",
                "cityDistrict": "025"
            },
            {
                "cityCode": "117",
                "cityName": "红河哈尼族彝族自治州",
                "cityPinYin": "honghezhou",
                "cityGroupName": "H",
                "cityDistrict": "0873"
            },
            {
                "cityCode": "707",
                "cityName": "太仆寺旗",
                "cityPinYin": "taipusiqi",
                "cityGroupName": "T",
                "cityDistrict": "0479"
            },
            {
                "cityCode": "54",
                "cityName": "大庆市",
                "cityPinYin": "daqing",
                "cityGroupName": "D",
                "cityDistrict": "0459"
            },
            {
                "cityCode": "77",
                "cityName": "抚州市",
                "cityPinYin": "fuzhou",
                "cityGroupName": "F",
                "cityDistrict": "0794"
            },
            {
                "cityCode": "631",
                "cityName": "莫力达瓦达斡尔族自治旗",
                "cityPinYin": "molidawadawodongzuzizhiqi",
                "cityGroupName": "M",
                "cityDistrict": "0470"
            },
            {
                "cityCode": "15",
                "cityName": "蚌埠市",
                "cityPinYin": "bangbu",
                "cityGroupName": "B",
                "cityDistrict": "0552"
            },
            {
                "cityCode": "764",
                "cityName": "新泰市",
                "cityPinYin": "xintaishi",
                "cityGroupName": "X",
                "cityDistrict": "0538"
            },
            {
                "cityCode": "610",
                "cityName": "龙南市",
                "cityPinYin": "longnanshi",
                "cityGroupName": "L",
                "cityDistrict": "0797"
            },
            {
                "cityCode": "678",
                "cityName": "瑞丽市",
                "cityPinYin": "ruilishi",
                "cityGroupName": "R",
                "cityDistrict": "0692"
            },
            {
                "cityCode": "404",
                "cityName": "陈巴尔虎旗",
                "cityPinYin": "chenbadonghuqi",
                "cityGroupName": "C",
                "cityDistrict": "0470"
            },
            {
                "cityCode": "823",
                "cityName": "涿州市",
                "cityPinYin": "zhuozhoushi",
                "cityGroupName": "Z",
                "cityDistrict": "0312"
            },
            {
                "cityCode": "514",
                "cityName": "侯马市",
                "cityPinYin": "houmashi",
                "cityGroupName": "H",
                "cityDistrict": "0357"
            },
            {
                "cityCode": "347",
                "cityName": "漳州市",
                "cityPinYin": "zhangzhou",
                "cityGroupName": "Z",
                "cityDistrict": "0596"
            },
            {
                "cityCode": "714",
                "cityName": "铁门关市",
                "cityPinYin": "tiemenguanshi",
                "cityGroupName": "T",
                "cityDistrict": "1996"
            },
            {
                "cityCode": "299",
                "cityName": "新乡市",
                "cityPinYin": "xinxiang",
                "cityGroupName": "X",
                "cityDistrict": "0373"
            },
            {
                "cityCode": "803",
                "cityName": "玉树市",
                "cityPinYin": "yushushi",
                "cityGroupName": "Y",
                "cityDistrict": "0976"
            },
            {
                "cityCode": "515",
                "cityName": "胡杨河市",
                "cityPinYin": "huyangheshi",
                "cityGroupName": "H",
                "cityDistrict": "0992"
            },
            {
                "cityCode": "463",
                "cityName": "抚远市",
                "cityPinYin": "fuyuanshi",
                "cityGroupName": "F",
                "cityDistrict": "0454"
            },
            {
                "cityCode": "688",
                "cityName": "神木市",
                "cityPinYin": "shenmushi",
                "cityGroupName": "S",
                "cityDistrict": "0912"
            },
            {
                "cityCode": "311",
                "cityName": "信阳市",
                "cityPinYin": "xinyang",
                "cityGroupName": "X",
                "cityDistrict": "0376"
            },
            {
                "cityCode": "53",
                "cityName": "东莞市",
                "cityPinYin": "dongguan",
                "cityGroupName": "D",
                "cityDistrict": "0769"
            },
            {
                "cityCode": "47",
                "cityName": "澄迈县",
                "cityPinYin": "chengmaixian",
                "cityGroupName": "C",
                "cityDistrict": "0117"
            },
            {
                "cityCode": "601",
                "cityName": "灵宝市",
                "cityPinYin": "lingbaoshi",
                "cityGroupName": "L",
                "cityDistrict": "0398"
            },
            {
                "cityCode": "800",
                "cityName": "玉环市",
                "cityPinYin": "yuhuanshi",
                "cityGroupName": "Y",
                "cityDistrict": "0576"
            },
            {
                "cityCode": "147",
                "cityName": "昆明市",
                "cityPinYin": "kunming",
                "cityGroupName": "K",
                "cityDistrict": "0871"
            },
            {
                "cityCode": "616",
                "cityName": "滦州市",
                "cityPinYin": "luanzhoushi",
                "cityGroupName": "L",
                "cityDistrict": "0315"
            },
            {
                "cityCode": "727",
                "cityName": "卫辉市",
                "cityPinYin": "weihuishi",
                "cityGroupName": "W",
                "cityDistrict": "0373"
            },
            {
                "cityCode": "797",
                "cityName": "榆树市",
                "cityPinYin": "yushushi",
                "cityGroupName": "Y",
                "cityDistrict": "0431"
            },
            {
                "cityCode": "560",
                "cityName": "科尔沁右翼中旗",
                "cityPinYin": "kedongqinyouyizhongqi",
                "cityGroupName": "K",
                "cityDistrict": "0482"
            },
            {
                "cityCode": "197",
                "cityName": "南平市",
                "cityPinYin": "nanping",
                "cityGroupName": "N",
                "cityDistrict": "0599"
            },
            {
                "cityCode": "29",
                "cityName": "巴音郭楞州",
                "cityPinYin": "bayinguolengzhou",
                "cityGroupName": "B",
                "cityDistrict": "0996"
            },
            {
                "cityCode": "581",
                "cityName": "乐清市",
                "cityPinYin": "leqingshi",
                "cityGroupName": "L",
                "cityDistrict": "0577"
            },
            {
                "cityCode": "217",
                "cityName": "潜江市",
                "cityPinYin": "qianjiang",
                "cityGroupName": "Q",
                "cityDistrict": "2728"
            },
            {
                "cityCode": "754",
                "cityName": "湘乡市",
                "cityPinYin": "xiangxiangshi",
                "cityGroupName": "X",
                "cityDistrict": "0732"
            },
            {
                "cityCode": "501",
                "cityName": "汉川市",
                "cityPinYin": "hanchuanshi",
                "cityGroupName": "H",
                "cityDistrict": "0712"
            },
            {
                "cityCode": "725",
                "cityName": "瓦房店市",
                "cityPinYin": "wafangdianshi",
                "cityGroupName": "W",
                "cityDistrict": "0411"
            },
            {
                "cityCode": "689",
                "cityName": "嵊州市",
                "cityPinYin": "shengzhoushi",
                "cityGroupName": "S",
                "cityDistrict": "0575"
            },
            {
                "cityCode": "452",
                "cityName": "二连浩特市",
                "cityPinYin": "donglianhaoteshi",
                "cityGroupName": "E",
                "cityDistrict": "0479"
            },
            {
                "cityCode": "295",
                "cityName": "徐州市",
                "cityPinYin": "xuzhou",
                "cityGroupName": "X",
                "cityDistrict": "0516"
            },
            {
                "cityCode": "448",
                "cityName": "鄂温克族自治旗",
                "cityPinYin": "dongwenkezuzizhiqi",
                "cityGroupName": "E",
                "cityDistrict": "0470"
            },
            {
                "cityCode": "658",
                "cityName": "潜山市",
                "cityPinYin": "qianshanshi",
                "cityGroupName": "Q",
                "cityDistrict": "0556"
            },
            {
                "cityCode": "373",
                "cityName": "安宁市",
                "cityPinYin": "anningshi",
                "cityGroupName": "A",
                "cityDistrict": "0871"
            },
            {
                "cityCode": "333",
                "cityName": "云浮市",
                "cityPinYin": "yunfu",
                "cityGroupName": "Y",
                "cityDistrict": "0766"
            },
            {
                "cityCode": "25",
                "cityName": "巴中市",
                "cityPinYin": "bazhong",
                "cityGroupName": "B",
                "cityDistrict": "0827"
            },
            {
                "cityCode": "663",
                "cityName": "青铜峡市",
                "cityPinYin": "qingtongxiashi",
                "cityGroupName": "Q",
                "cityDistrict": "0953"
            },
            {
                "cityCode": "706",
                "cityName": "太仓市",
                "cityPinYin": "taicangshi",
                "cityGroupName": "T",
                "cityDistrict": "0512"
            },
            {
                "cityCode": "293",
                "cityName": "西宁市",
                "cityPinYin": "xining",
                "cityGroupName": "X",
                "cityDistrict": "0971"
            },
            {
                "cityCode": "107",
                "cityName": "呼伦贝尔市",
                "cityPinYin": "hulunbeier",
                "cityGroupName": "H",
                "cityDistrict": "0470"
            },
            {
                "cityCode": "745",
                "cityName": "武穴市",
                "cityPinYin": "wuxueshi",
                "cityGroupName": "W",
                "cityDistrict": "0713"
            },
            {
                "cityCode": "232",
                "cityName": "三亚市",
                "cityPinYin": "sanya",
                "cityGroupName": "S",
                "cityDistrict": "0899"
            },
            {
                "cityCode": "5",
                "cityName": "阿坝州",
                "cityPinYin": "abazhou",
                "cityGroupName": "A",
                "cityDistrict": "0837"
            },
            {
                "cityCode": "599",
                "cityName": "临夏市",
                "cityPinYin": "linxiashi",
                "cityGroupName": "L",
                "cityDistrict": "0930"
            },
            {
                "cityCode": "723",
                "cityName": "土默特左旗",
                "cityPinYin": "tumote左qi",
                "cityGroupName": "T",
                "cityDistrict": "0471"
            },
            {
                "cityCode": "765",
                "cityName": "新沂市",
                "cityPinYin": "xinyishi",
                "cityGroupName": "X",
                "cityDistrict": "0516"
            },
            {
                "cityCode": "679",
                "cityName": "三河市",
                "cityPinYin": "sanheshi",
                "cityGroupName": "S",
                "cityDistrict": "0316"
            },
            {
                "cityCode": "135",
                "cityName": "江门市",
                "cityPinYin": "jiangmen",
                "cityGroupName": "J",
                "cityDistrict": "0750"
            },
            {
                "cityCode": "115",
                "cityName": "贺州市",
                "cityPinYin": "hezhou",
                "cityGroupName": "H",
                "cityDistrict": "1774"
            },
            {
                "cityCode": "622",
                "cityName": "茫崖市",
                "cityPinYin": "mangyashi",
                "cityGroupName": "M",
                "cityDistrict": "0977"
            },
            {
                "cityCode": "52",
                "cityName": "大连市",
                "cityPinYin": "dalian",
                "cityGroupName": "D",
                "cityDistrict": "0411"
            },
            {
                "cityCode": "89",
                "cityName": "果洛州",
                "cityPinYin": "guoluozhou",
                "cityGroupName": "G",
                "cityDistrict": "0975"
            },
            {
                "cityCode": "210",
                "cityName": "泉州市",
                "cityPinYin": "quanzhou",
                "cityGroupName": "Q",
                "cityDistrict": "0595"
            },
            {
                "cityCode": "784",
                "cityName": "宜城市",
                "cityPinYin": "yichengshi",
                "cityGroupName": "Y",
                "cityDistrict": "0710"
            },
            {
                "cityCode": "497",
                "cityName": "海西蒙古族藏族自治州",
                "cityPinYin": "haiximengguzucangzuzizhizhou",
                "cityGroupName": "H",
                "cityDistrict": "0977"
            },
            {
                "cityCode": "233",
                "cityName": "绍兴市",
                "cityPinYin": "shaoxing",
                "cityGroupName": "S",
                "cityDistrict": "0575"
            },
            {
                "cityCode": "332",
                "cityName": "阳江市",
                "cityPinYin": "yangjiang",
                "cityGroupName": "Y",
                "cityDistrict": "0662"
            },
            {
                "cityCode": "397",
                "cityName": "昌邑市",
                "cityPinYin": "changyishi",
                "cityGroupName": "C",
                "cityDistrict": "0536"
            },
            {
                "cityCode": "270",
                "cityName": "屯昌县",
                "cityPinYin": "tunchangxian",
                "cityGroupName": "T",
                "cityDistrict": "1892"
            },
            {
                "cityCode": "624",
                "cityName": "蒙自市",
                "cityPinYin": "mengzishi",
                "cityGroupName": "M",
                "cityDistrict": "0873"
            },
            {
                "cityCode": "724",
                "cityName": "吐鲁番市",
                "cityPinYin": "tulufanshi",
                "cityGroupName": "T",
                "cityDistrict": "0995"
            },
            {
                "cityCode": "481",
                "cityName": "古交市",
                "cityPinYin": "gujiaoshi",
                "cityGroupName": "G",
                "cityDistrict": "0351"
            },
            {
                "cityCode": "769",
                "cityName": "兴化市",
                "cityPinYin": "xinghuashi",
                "cityGroupName": "X",
                "cityDistrict": "0523"
            },
            {
                "cityCode": "226",
                "cityName": "日喀则地区",
                "cityPinYin": "rikazediqu",
                "cityGroupName": "R",
                "cityDistrict": "0892"
            },
            {
                "cityCode": "355",
                "cityName": "资阳市",
                "cityPinYin": "ziyang",
                "cityGroupName": "Z",
                "cityDistrict": "0832"
            },
            {
                "cityCode": "371",
                "cityName": "安国市",
                "cityPinYin": "anguoshi",
                "cityGroupName": "A",
                "cityDistrict": "0312"
            },
            {
                "cityCode": "36",
                "cityName": "常州市",
                "cityPinYin": "changzhou",
                "cityGroupName": "C",
                "cityDistrict": "0519"
            },
            {
                "cityCode": "234",
                "cityName": "绥化市",
                "cityPinYin": "suihua",
                "cityGroupName": "S",
                "cityDistrict": "0455"
            },
            {
                "cityCode": "181",
                "cityName": "陵水黎族自治县",
                "cityPinYin": "lingshuilizuzizhixian",
                "cityGroupName": "L",
                "cityDistrict": "0809"
            },
            {
                "cityCode": "472",
                "cityName": "高平市",
                "cityPinYin": "gaopingshi",
                "cityGroupName": "G",
                "cityDistrict": "0356"
            },
            {
                "cityCode": "21",
                "cityName": "白山市",
                "cityPinYin": "baishan",
                "cityGroupName": "B",
                "cityDistrict": "0439"
            },
            {
                "cityCode": "78",
                "cityName": "防城港市",
                "cityPinYin": "fangchenggang",
                "cityGroupName": "F",
                "cityDistrict": "0770"
            },
            {
                "cityCode": "313",
                "cityName": "烟台市",
                "cityPinYin": "yantai",
                "cityGroupName": "Y",
                "cityDistrict": "0535"
            },
            {
                "cityCode": "540",
                "cityName": "蛟河市",
                "cityPinYin": "jiaoheshi",
                "cityGroupName": "J",
                "cityDistrict": "0432"
            },
            {
                "cityCode": "458",
                "cityName": "扶余市",
                "cityPinYin": "fuyushi",
                "cityGroupName": "F",
                "cityDistrict": "0438"
            },
            {
                "cityCode": "772",
                "cityName": "兴仁市",
                "cityPinYin": "xingrenshi",
                "cityGroupName": "X",
                "cityDistrict": "0859"
            },
            {
                "cityCode": "671",
                "cityName": "荣成市",
                "cityPinYin": "rongchengshi",
                "cityGroupName": "R",
                "cityDistrict": "0631"
            },
            {
                "cityCode": "562",
                "cityName": "科尔沁左翼中旗",
                "cityPinYin": "kedongqin左yizhongqi",
                "cityGroupName": "K",
                "cityDistrict": "0475"
            },
            {
                "cityCode": "252",
                "cityName": "汕尾市",
                "cityPinYin": "shanwei",
                "cityGroupName": "S",
                "cityDistrict": "0660"
            },
            {
                "cityCode": "507",
                "cityName": "和田市",
                "cityPinYin": "hetianshi",
                "cityGroupName": "H",
                "cityDistrict": "0903"
            },
            {
                "cityCode": "18",
                "cityName": "宝鸡市",
                "cityPinYin": "baoji",
                "cityGroupName": "B",
                "cityDistrict": "0917"
            },
            {
                "cityCode": "494",
                "cityName": "海伦市",
                "cityPinYin": "hailunshi",
                "cityGroupName": "H",
                "cityDistrict": "0455"
            },
            {
                "cityCode": "768",
                "cityName": "兴城市",
                "cityPinYin": "xingchengshi",
                "cityGroupName": "X",
                "cityDistrict": "0429"
            },
            {
                "cityCode": "206",
                "cityName": "萍乡市",
                "cityPinYin": "pingxiang",
                "cityGroupName": "P",
                "cityDistrict": "0799"
            },
            {
                "cityCode": "594",
                "cityName": "林州市",
                "cityPinYin": "linzhoushi",
                "cityGroupName": "L",
                "cityDistrict": "0372"
            },
            {
                "cityCode": "79",
                "cityName": "广州市",
                "cityPinYin": "guangzhou",
                "cityGroupName": "G",
                "cityDistrict": "020"
            },
            {
                "cityCode": "297",
                "cityName": "邢台市",
                "cityPinYin": "xingtai",
                "cityGroupName": "X",
                "cityDistrict": "0319"
            },
            {
                "cityCode": "241",
                "cityName": "朔州市",
                "cityPinYin": "shuozhou",
                "cityGroupName": "S",
                "cityDistrict": "0349"
            },
            {
                "cityCode": "357",
                "cityName": "张掖市",
                "cityPinYin": "zhangye",
                "cityGroupName": "Z",
                "cityDistrict": "0936"
            },
            {
                "cityCode": "537",
                "cityName": "江阴市",
                "cityPinYin": "jiangyinshi",
                "cityGroupName": "J",
                "cityDistrict": "0510"
            },
            {
                "cityCode": "315",
                "cityName": "宜昌市",
                "cityPinYin": "yichang",
                "cityGroupName": "Y",
                "cityDistrict": "0717"
            },
            {
                "cityCode": "119",
                "cityName": "海北州",
                "cityPinYin": "haibeizhou",
                "cityGroupName": "H",
                "cityDistrict": "0970"
            },
            {
                "cityCode": "526",
                "cityName": "辉县市",
                "cityPinYin": "huixianshi",
                "cityGroupName": "H",
                "cityDistrict": "0373"
            },
            {
                "cityCode": "801",
                "cityName": "玉门市",
                "cityPinYin": "yumenshi",
                "cityGroupName": "Y",
                "cityDistrict": "0937"
            },
            {
                "cityCode": "410",
                "cityName": "楚雄彝族自治州",
                "cityPinYin": "chuxiongyizuzizhizhou",
                "cityGroupName": "C",
                "cityDistrict": "0878"
            },
            {
                "cityCode": "235",
                "cityName": "四平市",
                "cityPinYin": "siping",
                "cityGroupName": "S",
                "cityDistrict": "0434"
            },
            {
                "cityCode": "127",
                "cityName": "晋中市",
                "cityPinYin": "jinzhong",
                "cityGroupName": "J",
                "cityDistrict": "0354"
            },
            {
                "cityCode": "138",
                "cityName": "晋城市",
                "cityPinYin": "jincheng",
                "cityGroupName": "J",
                "cityDistrict": "0356"
            },
            {
                "cityCode": "305",
                "cityName": "锡林郭勒盟",
                "cityPinYin": "xilinguolemeng",
                "cityGroupName": "X",
                "cityDistrict": "0479"
            },
            {
                "cityCode": "369",
                "cityName": "阿图什市",
                "cityPinYin": "atushishi",
                "cityGroupName": "A",
                "cityDistrict": "0908"
            },
            {
                "cityCode": "819",
                "cityName": "诸城市",
                "cityPinYin": "zhuchengshi",
                "cityGroupName": "Z",
                "cityDistrict": "0536"
            },
            {
                "cityCode": "750",
                "cityName": "西乌珠穆沁旗",
                "cityPinYin": "xiwuzhumuqinqi",
                "cityGroupName": "X",
                "cityDistrict": "0479"
            },
            {
                "cityCode": "702",
                "cityName": "苏尼特左旗",
                "cityPinYin": "sunite左qi",
                "cityGroupName": "S",
                "cityDistrict": "0479"
            },
            {
                "cityCode": "212",
                "cityName": "齐齐哈尔市",
                "cityPinYin": "qiqihaer",
                "cityGroupName": "Q",
                "cityDistrict": "0452"
            },
            {
                "cityCode": "309",
                "cityName": "仙桃市",
                "cityPinYin": "xiantao",
                "cityGroupName": "X",
                "cityDistrict": "0728"
            },
            {
                "cityCode": "144",
                "cityName": "金昌市",
                "cityPinYin": "jinchang",
                "cityGroupName": "J",
                "cityDistrict": "0935"
            },
            {
                "cityCode": "538",
                "cityName": "江油市",
                "cityPinYin": "jiangyoushi",
                "cityGroupName": "J",
                "cityDistrict": "0816"
            },
            {
                "cityCode": "251",
                "cityName": "韶关市",
                "cityPinYin": "shaoguan",
                "cityGroupName": "S",
                "cityDistrict": "0751"
            },
            {
                "cityCode": "509",
                "cityName": "河津市",
                "cityPinYin": "hejinshi",
                "cityGroupName": "H",
                "cityDistrict": "0359"
            },
            {
                "cityCode": "136",
                "cityName": "揭阳市",
                "cityPinYin": "jieyang",
                "cityGroupName": "J",
                "cityDistrict": "0663"
            },
            {
                "cityCode": "175",
                "cityName": "凉山州",
                "cityPinYin": "liangshanzhou",
                "cityGroupName": "L",
                "cityDistrict": "0834"
            },
            {
                "cityCode": "694",
                "cityName": "舒兰市",
                "cityPinYin": "shulanshi",
                "cityGroupName": "S",
                "cityDistrict": "0432"
            },
            {
                "cityCode": "28",
                "cityName": "白银市",
                "cityPinYin": "baiyin",
                "cityGroupName": "B",
                "cityDistrict": "0943"
            },
            {
                "cityCode": "465",
                "cityName": "富锦市",
                "cityPinYin": "fujinshi",
                "cityGroupName": "F",
                "cityDistrict": "0454"
            },
            {
                "cityCode": "718",
                "cityName": "桐乡市",
                "cityPinYin": "tongxiangshi",
                "cityGroupName": "T",
                "cityDistrict": "0573"
            },
            {
                "cityCode": "553",
                "cityName": "喀什市",
                "cityPinYin": "kashishi",
                "cityGroupName": "K",
                "cityDistrict": "0998"
            },
            {
                "cityCode": "757",
                "cityName": "孝义市",
                "cityPinYin": "xiaoyishi",
                "cityGroupName": "X",
                "cityDistrict": "0358"
            },
            {
                "cityCode": "10",
                "cityName": "阿拉尔市",
                "cityPinYin": "alaer",
                "cityGroupName": "A",
                "cityDistrict": "0997"
            },
            {
                "cityCode": "164",
                "cityName": "拉萨市",
                "cityPinYin": "lasa",
                "cityGroupName": "L",
                "cityDistrict": "0891"
            },
            {
                "cityCode": "609",
                "cityName": "龙口市",
                "cityPinYin": "longkoushi",
                "cityGroupName": "L",
                "cityDistrict": "0535"
            },
            {
                "cityCode": "109",
                "cityName": "黑河市",
                "cityPinYin": "heihe",
                "cityGroupName": "H",
                "cityDistrict": "0456"
            },
            {
                "cityCode": "157",
                "cityName": "连云港市",
                "cityPinYin": "lianyungang",
                "cityGroupName": "L",
                "cityDistrict": "0518"
            },
            {
                "cityCode": "118",
                "cityName": "海东地区",
                "cityPinYin": "haidongdiqu",
                "cityGroupName": "H",
                "cityDistrict": "0972"
            },
            {
                "cityCode": "717",
                "cityName": "桐城市",
                "cityPinYin": "tongchengshi",
                "cityGroupName": "T",
                "cityDistrict": "0556"
            },
            {
                "cityCode": "215",
                "cityName": "清远市",
                "cityPinYin": "qingyuan",
                "cityGroupName": "Q",
                "cityDistrict": "0763"
            },
            {
                "cityCode": "798",
                "cityName": "禹城市",
                "cityPinYin": "yuchengshi",
                "cityGroupName": "Y",
                "cityDistrict": "0534"
            },
            {
                "cityCode": "171",
                "cityName": "漯河市",
                "cityPinYin": "luohe",
                "cityGroupName": "L",
                "cityDistrict": "0395"
            },
            {
                "cityCode": "137",
                "cityName": "焦作市",
                "cityPinYin": "jiaozuo",
                "cityGroupName": "J",
                "cityDistrict": "0391"
            },
            {
                "cityCode": "271",
                "cityName": "铜仁地区",
                "cityPinYin": "tongrendiqu",
                "cityGroupName": "T",
                "cityDistrict": "0856"
            },
            {
                "cityCode": "709",
                "cityName": "洮南市",
                "cityPinYin": "taonanshi",
                "cityGroupName": "T",
                "cityDistrict": "0436"
            },
            {
                "cityCode": "522",
                "cityName": "怀仁市",
                "cityPinYin": "huairenshi",
                "cityGroupName": "H",
                "cityDistrict": "0349"
            },
            {
                "cityCode": "779",
                "cityName": "阳春市",
                "cityPinYin": "yangchunshi",
                "cityGroupName": "Y",
                "cityDistrict": "0662"
            },
            {
                "cityCode": "629",
                "cityName": "绵竹市",
                "cityPinYin": "mianzhushi",
                "cityGroupName": "M",
                "cityDistrict": "0838"
            },
            {
                "cityCode": "826",
                "cityName": "邹城市",
                "cityPinYin": "zouchengshi",
                "cityGroupName": "Z",
                "cityDistrict": "0537"
            },
            {
                "cityCode": "258",
                "cityName": "天津市",
                "cityPinYin": "tianjin",
                "cityGroupName": "T",
                "cityDistrict": "022"
            },
            {
                "cityCode": "65",
                "cityName": "达州市",
                "cityPinYin": "dazhou",
                "cityGroupName": "D",
                "cityDistrict": "0818"
            },
            {
                "cityCode": "76",
                "cityName": "阜阳市",
                "cityPinYin": "fuyang",
                "cityGroupName": "F",
                "cityDistrict": "1558"
            },
            {
                "cityCode": "613",
                "cityName": "庐山市",
                "cityPinYin": "lushanshi",
                "cityGroupName": "L",
                "cityDistrict": "0792"
            },
            {
                "cityCode": "99",
                "cityName": "淮安市",
                "cityPinYin": "huaian",
                "cityGroupName": "H",
                "cityDistrict": "0517"
            },
            {
                "cityCode": "225",
                "cityName": "日照市",
                "cityPinYin": "rizhao",
                "cityGroupName": "R",
                "cityDistrict": "0633"
            },
            {
                "cityCode": "200",
                "cityName": "那曲地区",
                "cityPinYin": "naqudiqu",
                "cityGroupName": "N",
                "cityDistrict": "0896"
            },
            {
                "cityCode": "783",
                "cityName": "仪征市",
                "cityPinYin": "yizhengshi",
                "cityGroupName": "Y",
                "cityDistrict": "0514"
            },
            {
                "cityCode": "148",
                "cityName": "开封市",
                "cityPinYin": "kaifeng",
                "cityGroupName": "K",
                "cityDistrict": "0378"
            },
            {
                "cityCode": "498",
                "cityName": "海西蒙古族藏族自治州直辖",
                "cityPinYin": "haiximengguzucangzuzizhizhouzhixia",
                "cityGroupName": "H",
                "cityDistrict": "0977"
            },
            {
                "cityCode": "312",
                "cityName": "西双版纳州",
                "cityPinYin": "xishuangbannazhou",
                "cityGroupName": "X",
                "cityDistrict": "0691"
            },
            {
                "cityCode": "190",
                "cityName": "宁波市",
                "cityPinYin": "ningbo",
                "cityGroupName": "N",
                "cityDistrict": "0574"
            },
            {
                "cityCode": "762",
                "cityName": "新密市",
                "cityPinYin": "xinmishi",
                "cityGroupName": "X",
                "cityDistrict": "0371"
            },
            {
                "cityCode": "434",
                "cityName": "东台市",
                "cityPinYin": "dongtaishi",
                "cityGroupName": "D",
                "cityDistrict": "0515"
            },
            {
                "cityCode": "645",
                "cityName": "盘州市",
                "cityPinYin": "panzhoushi",
                "cityGroupName": "P",
                "cityDistrict": "0858"
            },
            {
                "cityCode": "140",
                "cityName": "佳木斯市",
                "cityPinYin": "jiamusi",
                "cityGroupName": "J",
                "cityDistrict": "0454"
            },
            {
                "cityCode": "387",
                "cityName": "泊头市",
                "cityPinYin": "botoushi",
                "cityGroupName": "B",
                "cityDistrict": "0317"
            },
            {
                "cityCode": "27",
                "cityName": "保山市",
                "cityPinYin": "baoshan",
                "cityGroupName": "B",
                "cityDistrict": "0875"
            },
            {
                "cityCode": "368",
                "cityName": "阿荣旗",
                "cityPinYin": "arongqi",
                "cityGroupName": "A",
                "cityDistrict": "0470"
            },
            {
                "cityCode": "744",
                "cityName": "武冈市",
                "cityPinYin": "wugangshi",
                "cityGroupName": "W",
                "cityDistrict": "0739"
            },
            {
                "cityCode": "240",
                "cityName": "宿州市",
                "cityPinYin": "suzhou",
                "cityGroupName": "S",
                "cityDistrict": "0557"
            },
            {
                "cityCode": "499",
                "cityName": "海阳市",
                "cityPinYin": "haiyangshi",
                "cityGroupName": "H",
                "cityDistrict": "0535"
            },
            {
                "cityCode": "187",
                "cityName": "梅州市",
                "cityPinYin": "meizhou",
                "cityGroupName": "M",
                "cityDistrict": "0753"
            },
            {
                "cityCode": "393",
                "cityName": "察哈尔右翼中旗",
                "cityPinYin": "chahadongyouyizhongqi",
                "cityGroupName": "C",
                "cityDistrict": "0474"
            },
            {
                "cityCode": "649",
                "cityName": "平度市",
                "cityPinYin": "pingdongshi",
                "cityGroupName": "P",
                "cityDistrict": "0532"
            },
            {
                "cityCode": "16",
                "cityName": "北海市",
                "cityPinYin": "beihai",
                "cityGroupName": "B",
                "cityDistrict": "0779"
            },
            {
                "cityCode": "466",
                "cityName": "盖州市",
                "cityPinYin": "gaizhoushi",
                "cityGroupName": "G",
                "cityDistrict": "0417"
            },
            {
                "cityCode": "182",
                "cityName": "乐东黎族自治县",
                "cityPinYin": "ledonglizuzizhixian",
                "cityGroupName": "L",
                "cityDistrict": "2802"
            },
            {
                "cityCode": "195",
                "cityName": "南阳市",
                "cityPinYin": "nanyang",
                "cityGroupName": "N",
                "cityDistrict": "0377"
            },
            {
                "cityCode": "239",
                "cityName": "石河子市",
                "cityPinYin": "shihezi",
                "cityGroupName": "S",
                "cityDistrict": "0993"
            },
            {
                "cityCode": "192",
                "cityName": "南昌市",
                "cityPinYin": "nanchang",
                "cityGroupName": "N",
                "cityDistrict": "0791"
            },
            {
                "cityCode": "589",
                "cityName": "连州市",
                "cityPinYin": "lianzhoushi",
                "cityGroupName": "L",
                "cityDistrict": "0763"
            },
            {
                "cityCode": "134",
                "cityName": "景德镇市",
                "cityPinYin": "jingdezhen",
                "cityGroupName": "J",
                "cityDistrict": "0798"
            },
            {
                "cityCode": "627",
                "cityName": "汨罗市",
                "cityPinYin": "miluoshi",
                "cityGroupName": "M",
                "cityDistrict": "0730"
            },
            {
                "cityCode": "408",
                "cityName": "崇州市",
                "cityPinYin": "chongzhoushi",
                "cityGroupName": "C",
                "cityDistrict": "028"
            },
            {
                "cityCode": "354",
                "cityName": "自贡市",
                "cityPinYin": "zigong",
                "cityGroupName": "Z",
                "cityDistrict": "0813"
            },
            {
                "cityCode": "726",
                "cityName": "万源市",
                "cityPinYin": "wanyuanshi",
                "cityGroupName": "W",
                "cityDistrict": "0818"
            },
            {
                "cityCode": "608",
                "cityName": "龙井市",
                "cityPinYin": "longjingshi",
                "cityGroupName": "L",
                "cityDistrict": "1433"
            },
            {
                "cityCode": "244",
                "cityName": "上饶市",
                "cityPinYin": "shangrao",
                "cityGroupName": "S",
                "cityDistrict": "0793"
            },
            {
                "cityCode": "551",
                "cityName": "句容市",
                "cityPinYin": "jurongshi",
                "cityGroupName": "J",
                "cityDistrict": "0511"
            },
            {
                "cityCode": "257",
                "cityName": "三沙市",
                "cityPinYin": "sansha",
                "cityGroupName": "S",
                "cityDistrict": "1891"
            },
            {
                "cityCode": "130",
                "cityName": "金华市",
                "cityPinYin": "jinhua",
                "cityGroupName": "J",
                "cityDistrict": "0579"
            },
            {
                "cityCode": "110",
                "cityName": "黄石市",
                "cityPinYin": "huangshi",
                "cityGroupName": "H",
                "cityDistrict": "0714"
            },
            {
                "cityCode": "774",
                "cityName": "宣威市",
                "cityPinYin": "xuanweishi",
                "cityGroupName": "X",
                "cityDistrict": "0874"
            },
            {
                "cityCode": "531",
                "cityName": "集安市",
                "cityPinYin": "jianshi",
                "cityGroupName": "J",
                "cityDistrict": "0435"
            },
            {
                "cityCode": "806",
                "cityName": "枣阳市",
                "cityPinYin": "zaoyangshi",
                "cityGroupName": "Z",
                "cityDistrict": "0710"
            },
            {
                "cityCode": "524",
                "cityName": "黄南藏族自治州",
                "cityPinYin": "huangnancangzuzizhizhou",
                "cityGroupName": "H",
                "cityDistrict": "0973"
            },
            {
                "cityCode": "81",
                "cityName": "赣州市",
                "cityPinYin": "ganzhou",
                "cityGroupName": "G",
                "cityDistrict": "0797"
            },
            {
                "cityCode": "456",
                "cityName": "丰镇市",
                "cityPinYin": "fengzhenshi",
                "cityGroupName": "F",
                "cityDistrict": "0474"
            },
            {
                "cityCode": "219",
                "cityName": "琼海市",
                "cityPinYin": "qionghai",
                "cityGroupName": "Q",
                "cityDistrict": "1894"
            },
            {
                "cityCode": "475",
                "cityName": "格尔木市",
                "cityPinYin": "gedongmushi",
                "cityGroupName": "G",
                "cityDistrict": "0977"
            },
            {
                "cityCode": "583",
                "cityName": "耒阳市",
                "cityPinYin": "leiyangshi",
                "cityGroupName": "L",
                "cityDistrict": "0734"
            },
            {
                "cityCode": "361",
                "cityName": "阿尔山市",
                "cityPinYin": "adongshanshi",
                "cityGroupName": "A",
                "cityDistrict": "0482"
            },
            {
                "cityCode": "84",
                "cityName": "广元市",
                "cityPinYin": "guangyuan",
                "cityGroupName": "G",
                "cityDistrict": "0839"
            },
            {
                "cityCode": "464",
                "cityName": "阜康市",
                "cityPinYin": "fukangshi",
                "cityGroupName": "F",
                "cityDistrict": "0994"
            },
            {
                "cityCode": "266",
                "cityName": "铜陵市",
                "cityPinYin": "tongling",
                "cityGroupName": "T",
                "cityDistrict": "0562"
            },
            {
                "cityCode": "403",
                "cityName": "朝阳市",
                "cityPinYin": "chaoyangshi",
                "cityGroupName": "C",
                "cityDistrict": "0421"
            },
            {
                "cityCode": "173",
                "cityName": "临高县",
                "cityPinYin": "lingaoxian",
                "cityGroupName": "L",
                "cityDistrict": "1896"
            },
            {
                "cityCode": "377",
                "cityName": "巴林左旗",
                "cityPinYin": "balinzuoqi",
                "cityGroupName": "B",
                "cityDistrict": "0476"
            },
            {
                "cityCode": "626",
                "cityName": "弥勒市",
                "cityPinYin": "mileshi",
                "cityGroupName": "M",
                "cityDistrict": "0873"
            },
            {
                "cityCode": "30",
                "cityName": "博尔塔拉州",
                "cityPinYin": "boertalazhou",
                "cityGroupName": "B",
                "cityDistrict": "0909"
            },
            {
                "cityCode": "321",
                "cityName": "宜宾市",
                "cityPinYin": "yibin",
                "cityGroupName": "Y",
                "cityDistrict": "0831"
            },
            {
                "cityCode": "670",
                "cityName": "日喀则市",
                "cityPinYin": "rikazeshi",
                "cityGroupName": "R",
                "cityDistrict": "0892"
            },
            {
                "cityCode": "177",
                "cityName": "临沧市",
                "cityPinYin": "lincang",
                "cityGroupName": "L",
                "cityDistrict": "0883"
            },
            {
                "cityCode": "382",
                "cityName": "北票市",
                "cityPinYin": "beipiaoshi",
                "cityGroupName": "B",
                "cityDistrict": "0421"
            },
            {
                "cityCode": "660",
                "cityName": "黔南布依族苗族自治州",
                "cityPinYin": "qiannanbuyizumiaozuzizhizhou",
                "cityGroupName": "Q",
                "cityDistrict": "0854"
            },
            {
                "cityCode": "72",
                "cityName": "福州市",
                "cityPinYin": "fuzhou",
                "cityGroupName": "F",
                "cityDistrict": "0591"
            },
            {
                "cityCode": "216",
                "cityName": "七台河市",
                "cityPinYin": "qitaihe",
                "cityGroupName": "Q",
                "cityDistrict": "0464"
            },
            {
                "cityCode": "813",
                "cityName": "招远市",
                "cityPinYin": "zhaoyuanshi",
                "cityGroupName": "Z",
                "cityDistrict": "0535"
            },
            {
                "cityCode": "523",
                "cityName": "黄骅市",
                "cityPinYin": "huanghuashi",
                "cityGroupName": "H",
                "cityDistrict": "0317"
            },
            {
                "cityCode": "362",
                "cityName": "阿克苏市",
                "cityPinYin": "akesushi",
                "cityGroupName": "A",
                "cityDistrict": "0997"
            },
            {
                "cityCode": "705",
                "cityName": "台山市",
                "cityPinYin": "taishanshi",
                "cityGroupName": "T",
                "cityDistrict": "0750"
            },
            {
                "cityCode": "664",
                "cityName": "青州市",
                "cityPinYin": "qingzhoushi",
                "cityGroupName": "Q",
                "cityDistrict": "0536"
            },
            {
                "cityCode": "760",
                "cityName": "新巴尔虎左旗",
                "cityPinYin": "xinbadonghu左qi",
                "cityGroupName": "X",
                "cityDistrict": "0470"
            },
            {
                "cityCode": "207",
                "cityName": "普洱市",
                "cityPinYin": "puer",
                "cityGroupName": "P",
                "cityDistrict": "0879"
            },
            {
                "cityCode": "782",
                "cityName": "伊宁市",
                "cityPinYin": "yiningshi",
                "cityGroupName": "Y",
                "cityDistrict": "0999"
            },
            {
                "cityCode": "12",
                "cityName": "保定市",
                "cityPinYin": "baoding",
                "cityGroupName": "B",
                "cityDistrict": "0312"
            },
            {
                "cityCode": "675",
                "cityName": "瑞安市",
                "cityPinYin": "ruianshi",
                "cityGroupName": "R",
                "cityDistrict": "0577"
            },
            {
                "cityCode": "262",
                "cityName": "台州市",
                "cityPinYin": "taizhou",
                "cityGroupName": "T",
                "cityDistrict": "0576"
            },
            {
                "cityCode": "715",
                "cityName": "同江市",
                "cityPinYin": "tongjiangshi",
                "cityGroupName": "T",
                "cityDistrict": "0454"
            },
            {
                "cityCode": "547",
                "cityName": "井冈山市",
                "cityPinYin": "jinggangshanshi",
                "cityGroupName": "J",
                "cityDistrict": "0796"
            },
            {
                "cityCode": "668",
                "cityName": "仁怀市",
                "cityPinYin": "renhuaishi",
                "cityGroupName": "R",
                "cityDistrict": "0852"
            },
            {
                "cityCode": "561",
                "cityName": "科尔沁左翼后旗",
                "cityPinYin": "kedongqin左yihouqi",
                "cityGroupName": "K",
                "cityDistrict": "0475"
            },
            {
                "cityCode": "630",
                "cityName": "明光市",
                "cityPinYin": "mingguangshi",
                "cityGroupName": "M",
                "cityDistrict": "0550"
            },
            {
                "cityCode": "799",
                "cityName": "禹州市",
                "cityPinYin": "yuzhoushi",
                "cityGroupName": "Y",
                "cityDistrict": "0374"
            },
            {
                "cityCode": "378",
                "cityName": "巴音郭楞蒙古自治州",
                "cityPinYin": "bayinguolengmengguzizhizhou",
                "cityGroupName": "B",
                "cityDistrict": "0996"
            },
            {
                "cityCode": "595",
                "cityName": "临海市",
                "cityPinYin": "linhaishi",
                "cityGroupName": "L",
                "cityDistrict": "0576"
            },
            {
                "cityCode": "439",
                "cityName": "都匀市",
                "cityPinYin": "dongyunshi",
                "cityGroupName": "D",
                "cityDistrict": "0854"
            },
            {
                "cityCode": "365",
                "cityName": "阿拉善左旗",
                "cityPinYin": "alashan左qi",
                "cityGroupName": "A",
                "cityDistrict": "0483"
            },
            {
                "cityCode": "644",
                "cityName": "怒江傈僳族自治州",
                "cityPinYin": "nujianglisuzuzizhizhou",
                "cityGroupName": "N",
                "cityDistrict": "0886"
            },
            {
                "cityCode": "412",
                "cityName": "达尔罕茂明安联合旗",
                "cityPinYin": "dadonghanmaominganlianheqi",
                "cityGroupName": "D",
                "cityDistrict": "0472"
            },
            {
                "cityCode": "42",
                "cityName": "滁州市",
                "cityPinYin": "chuzhou",
                "cityGroupName": "C",
                "cityDistrict": "0550"
            },
            {
                "cityCode": "431",
                "cityName": "定州市",
                "cityPinYin": "dingzhoushi",
                "cityGroupName": "D",
                "cityDistrict": "0312"
            },
            {
                "cityCode": "648",
                "cityName": "邳州市",
                "cityPinYin": "pizhoushi",
                "cityGroupName": "P",
                "cityDistrict": "0516"
            },
            {
                "cityCode": "126",
                "cityName": "锦州市",
                "cityPinYin": "jinzhou",
                "cityGroupName": "J",
                "cityDistrict": "0416"
            },
            {
                "cityCode": "455",
                "cityName": "丰城市",
                "cityPinYin": "fengchengshi",
                "cityGroupName": "F",
                "cityDistrict": "0795"
            },
            {
                "cityCode": "237",
                "cityName": "汕头市",
                "cityPinYin": "shantou",
                "cityGroupName": "S",
                "cityDistrict": "0754"
            },
            {
                "cityCode": "161",
                "cityName": "柳州市",
                "cityPinYin": "liuzhou",
                "cityGroupName": "L",
                "cityDistrict": "0772"
            },
            {
                "cityCode": "39",
                "cityName": "常德市",
                "cityPinYin": "changde",
                "cityGroupName": "C",
                "cityDistrict": "0736"
            },
            {
                "cityCode": "389",
                "cityName": "博乐市",
                "cityPinYin": "boleshi",
                "cityGroupName": "B",
                "cityDistrict": "0909"
            },
            {
                "cityCode": "105",
                "cityName": "淮南市",
                "cityPinYin": "huainan",
                "cityGroupName": "H",
                "cityDistrict": "0554"
            },
            {
                "cityCode": "437",
                "cityName": "东阳市",
                "cityPinYin": "dongyangshi",
                "cityGroupName": "D",
                "cityDistrict": "0579"
            },
            {
                "cityCode": "92",
                "cityName": "哈尔滨市",
                "cityPinYin": "haerbin",
                "cityGroupName": "H",
                "cityDistrict": "0451"
            },
            {
                "cityCode": "101",
                "cityName": "汉中市",
                "cityPinYin": "hanzhong",
                "cityGroupName": "H",
                "cityDistrict": "0916"
            },
            {
                "cityCode": "460",
                "cityName": "福鼎市",
                "cityPinYin": "fudingshi",
                "cityGroupName": "F",
                "cityDistrict": "0593"
            },
            {
                "cityCode": "807",
                "cityName": "扎赉特旗",
                "cityPinYin": "zhalaiteqi",
                "cityGroupName": "Z",
                "cityDistrict": "0482"
            },
            {
                "cityCode": "38",
                "cityName": "承德市",
                "cityPinYin": "chengde",
                "cityGroupName": "C",
                "cityDistrict": "0314"
            },
            {
                "cityCode": "26",
                "cityName": "毕节地区",
                "cityPinYin": "bijiediqu",
                "cityGroupName": "B",
                "cityDistrict": "0857"
            },
            {
                "cityCode": "430",
                "cityName": "调兵山市",
                "cityPinYin": "diaobingshanshi",
                "cityGroupName": "D",
                "cityDistrict": "0410"
            },
            {
                "cityCode": "209",
                "cityName": "青岛市",
                "cityPinYin": "qingdao",
                "cityGroupName": "Q",
                "cityDistrict": "0532"
            },
            {
                "cityCode": "454",
                "cityName": "汾阳市",
                "cityPinYin": "fenyangshi",
                "cityGroupName": "F",
                "cityDistrict": "0358"
            },
            {
                "cityCode": "413",
                "cityName": "达拉特旗",
                "cityPinYin": "dalateqi",
                "cityGroupName": "D",
                "cityDistrict": "0477"
            },
            {
                "cityCode": "263",
                "cityName": "泰州市",
                "cityPinYin": "taizhou",
                "cityGroupName": "T",
                "cityDistrict": "0523"
            },
            {
                "cityCode": "785",
                "cityName": "宜都市",
                "cityPinYin": "yidongshi",
                "cityGroupName": "Y",
                "cityDistrict": "0717"
            },
            {
                "cityCode": "17",
                "cityName": "滨州市",
                "cityPinYin": "binzhou",
                "cityGroupName": "B",
                "cityDistrict": "0543"
            },
            {
                "cityCode": "141",
                "cityName": "吉安市",
                "cityPinYin": "jian",
                "cityGroupName": "J",
                "cityDistrict": "0796"
            },
            {
                "cityCode": "401",
                "cityName": "常熟市",
                "cityPinYin": "changshushi",
                "cityGroupName": "C",
                "cityDistrict": "0512"
            },
            {
                "cityCode": "597",
                "cityName": "临清市",
                "cityPinYin": "linqingshi",
                "cityGroupName": "L",
                "cityDistrict": "0635"
            },
            {
                "cityCode": "111",
                "cityName": "黄冈市",
                "cityPinYin": "huanggang",
                "cityGroupName": "H",
                "cityDistrict": "0713"
            },
            {
                "cityCode": "485",
                "cityName": "贵溪市",
                "cityPinYin": "guixishi",
                "cityGroupName": "G",
                "cityDistrict": "0701"
            },
            {
                "cityCode": "121",
                "cityName": "海南州",
                "cityPinYin": "hainanzhou",
                "cityGroupName": "H",
                "cityDistrict": "0974"
            },
            {
                "cityCode": "191",
                "cityName": "南宁市",
                "cityPinYin": "nanning",
                "cityGroupName": "N",
                "cityDistrict": "0771"
            },
            {
                "cityCode": "742",
                "cityName": "五家渠市",
                "cityPinYin": "wujiaqushi",
                "cityGroupName": "W",
                "cityDistrict": "1994"
            },
            {
                "cityCode": "399",
                "cityName": "长垣市",
                "cityPinYin": "changyuanshi",
                "cityGroupName": "C",
                "cityDistrict": "0373"
            },
            {
                "cityCode": "67",
                "cityName": "迪庆州",
                "cityPinYin": "diqingzhou",
                "cityGroupName": "D",
                "cityDistrict": "0887"
            },
            {
                "cityCode": "667",
                "cityName": "曲阜市",
                "cityPinYin": "qufushi",
                "cityGroupName": "Q",
                "cityDistrict": "0537"
            },
            {
                "cityCode": "385",
                "cityName": "毕节市",
                "cityPinYin": "bijieshi",
                "cityGroupName": "B",
                "cityDistrict": "0857"
            },
            {
                "cityCode": "640",
                "cityName": "嫩江市",
                "cityPinYin": "nenjiangshi",
                "cityGroupName": "N",
                "cityDistrict": "0456"
            },
            {
                "cityCode": "360",
                "cityName": "阿坝藏族羌族自治州",
                "cityPinYin": "abazangzuqiangzuzizhizhou",
                "cityGroupName": "A",
                "cityDistrict": "0837"
            },
            {
                "cityCode": "247",
                "cityName": "随州市",
                "cityPinYin": "suizhou",
                "cityGroupName": "S",
                "cityDistrict": "0722"
            },
            {
                "cityCode": "97",
                "cityName": "海口市",
                "cityPinYin": "haikou",
                "cityGroupName": "H",
                "cityDistrict": "0898"
            },
            {
                "cityCode": "351",
                "cityName": "枣庄市",
                "cityPinYin": "zaozhuang",
                "cityGroupName": "Z",
                "cityDistrict": "0632"
            },
            {
                "cityCode": "392",
                "cityName": "察哈尔右翼前旗",
                "cityPinYin": "chahadongyouyiqianqi",
                "cityGroupName": "C",
                "cityDistrict": "0474"
            },
            {
                "cityCode": "350",
                "cityName": "肇庆市",
                "cityPinYin": "zhaoqing",
                "cityGroupName": "Z",
                "cityDistrict": "0758"
            },
            {
                "cityCode": "165",
                "cityName": "六安市",
                "cityPinYin": "liuan",
                "cityGroupName": "L",
                "cityDistrict": "0564"
            },
            {
                "cityCode": "746",
                "cityName": "武夷山市",
                "cityPinYin": "wuyishanshi",
                "cityGroupName": "W",
                "cityDistrict": "0599"
            },
            {
                "cityCode": "794",
                "cityName": "永济市",
                "cityPinYin": "yongjishi",
                "cityGroupName": "Y",
                "cityDistrict": "0359"
            },
            {
                "cityCode": "277",
                "cityName": "无锡市",
                "cityPinYin": "wuxi",
                "cityGroupName": "W",
                "cityDistrict": "0510"
            },
            {
                "cityCode": "352",
                "cityName": "张家界市",
                "cityPinYin": "zhangjiajie",
                "cityGroupName": "Z",
                "cityDistrict": "0744"
            },
            {
                "cityCode": "687",
                "cityName": "深州市",
                "cityPinYin": "shenzhoushi",
                "cityGroupName": "S",
                "cityDistrict": "0318"
            },
            {
                "cityCode": "66",
                "cityName": "德宏州",
                "cityPinYin": "dehongzhou",
                "cityGroupName": "D",
                "cityDistrict": "0692"
            },
            {
                "cityCode": "773",
                "cityName": "兴义市",
                "cityPinYin": "xingyishi",
                "cityGroupName": "X",
                "cityDistrict": "0859"
            },
            {
                "cityCode": "409",
                "cityName": "楚雄市",
                "cityPinYin": "chuxiongshi",
                "cityGroupName": "C",
                "cityDistrict": "0878"
            },
            {
                "cityCode": "214",
                "cityName": "衢州市",
                "cityPinYin": "quzhou",
                "cityGroupName": "Q",
                "cityDistrict": "0570"
            },
            {
                "cityCode": "153",
                "cityName": "兰州市",
                "cityPinYin": "lanzhou",
                "cityGroupName": "L",
                "cityDistrict": "0931"
            },
            {
                "cityCode": "695",
                "cityName": "双河市",
                "cityPinYin": "shuangheshi",
                "cityGroupName": "S",
                "cityDistrict": "1909"
            },
            {
                "cityCode": "676",
                "cityName": "瑞昌市",
                "cityPinYin": "ruichangshi",
                "cityGroupName": "R",
                "cityDistrict": "0792"
            },
            {
                "cityCode": "336",
                "cityName": "玉树州",
                "cityPinYin": "yushuzhou",
                "cityGroupName": "Y",
                "cityDistrict": "0976"
            },
            {
                "cityCode": "273",
                "cityName": "吐鲁番地区",
                "cityPinYin": "tulufandiqu",
                "cityGroupName": "T",
                "cityDistrict": "0995"
            },
            {
                "cityCode": "735",
                "cityName": "乌兰浩特市",
                "cityPinYin": "wulanhaoteshi",
                "cityGroupName": "W",
                "cityDistrict": "0482"
            },
            {
                "cityCode": "665",
                "cityName": "清镇市",
                "cityPinYin": "qingzhenshi",
                "cityGroupName": "Q",
                "cityDistrict": "0851"
            },
            {
                "cityCode": "279",
                "cityName": "潍坊市",
                "cityPinYin": "weifang",
                "cityGroupName": "W",
                "cityDistrict": "0536"
            },
            {
                "cityCode": "651",
                "cityName": "平湖市",
                "cityPinYin": "pinghushi",
                "cityGroupName": "P",
                "cityDistrict": "0573"
            },
            {
                "cityCode": "552",
                "cityName": "喀喇沁旗",
                "cityPinYin": "kalaqinqi",
                "cityGroupName": "K",
                "cityDistrict": "0476"
            },
            {
                "cityCode": "188",
                "cityName": "眉山市",
                "cityPinYin": "meishan",
                "cityGroupName": "M",
                "cityDistrict": "1833"
            },
            {
                "cityCode": "129",
                "cityName": "济宁市",
                "cityPinYin": "jining",
                "cityGroupName": "J",
                "cityDistrict": "0537"
            },
            {
                "cityCode": "625",
                "cityName": "孟州市",
                "cityPinYin": "mengzhoushi",
                "cityGroupName": "M",
                "cityDistrict": "0391"
            },
            {
                "cityCode": "160",
                "cityName": "聊城市",
                "cityPinYin": "liaocheng",
                "cityGroupName": "L",
                "cityDistrict": "0635"
            },
            {
                "cityCode": "635",
                "cityName": "奈曼旗",
                "cityPinYin": "naimanqi",
                "cityGroupName": "N",
                "cityDistrict": "0475"
            },
            {
                "cityCode": "790",
                "cityName": "英德市",
                "cityPinYin": "yingdeshi",
                "cityGroupName": "Y",
                "cityDistrict": "0763"
            },
            {
                "cityCode": "791",
                "cityName": "荥阳市",
                "cityPinYin": "xingyangshi",
                "cityGroupName": "X",
                "cityDistrict": "0371"
            },
            {
                "cityCode": "6",
                "cityName": "安顺市",
                "cityPinYin": "anshun",
                "cityGroupName": "A",
                "cityDistrict": "0853"
            },
            {
                "cityCode": "60",
                "cityName": "德阳市",
                "cityPinYin": "deyang",
                "cityGroupName": "D",
                "cityDistrict": "0838"
            },
            {
                "cityCode": "467",
                "cityName": "甘南藏族自治州",
                "cityPinYin": "gannancangzuzizhizhou",
                "cityGroupName": "G",
                "cityDistrict": "0941"
            },
            {
                "cityCode": "386",
                "cityName": "彬州市",
                "cityPinYin": "binzhoushi",
                "cityGroupName": "B",
                "cityDistrict": "0910"
            },
            {
                "cityCode": "222",
                "cityName": "黔南州",
                "cityPinYin": "qiannanzhou",
                "cityGroupName": "Q",
                "cityDistrict": "0854"
            },
            {
                "cityCode": "584",
                "cityName": "冷水江市",
                "cityPinYin": "lengshuijiangshi",
                "cityGroupName": "L",
                "cityDistrict": "0738"
            },
            {
                "cityCode": "766",
                "cityName": "新郑市",
                "cityPinYin": "xinzhengshi",
                "cityGroupName": "X",
                "cityDistrict": "0371"
            },
            {
                "cityCode": "462",
                "cityName": "福泉市",
                "cityPinYin": "fuquanshi",
                "cityGroupName": "F",
                "cityDistrict": "0854"
            },
            {
                "cityCode": "484",
                "cityName": "广水市",
                "cityPinYin": "guangshuishi",
                "cityGroupName": "G",
                "cityDistrict": "0722"
            },
            {
                "cityCode": "55",
                "cityName": "东营市",
                "cityPinYin": "dongying",
                "cityGroupName": "D",
                "cityDistrict": "0546"
            },
            {
                "cityCode": "40",
                "cityName": "郴州市",
                "cityPinYin": "chenzhou",
                "cityGroupName": "C",
                "cityDistrict": "0735"
            },
            {
                "cityCode": "751",
                "cityName": "锡林浩特市",
                "cityPinYin": "xilinhaoteshi",
                "cityGroupName": "X",
                "cityDistrict": "0479"
            },
            {
                "cityCode": "420",
                "cityName": "丹阳市",
                "cityPinYin": "danyangshi",
                "cityGroupName": "D",
                "cityDistrict": "0511"
            },
            {
                "cityCode": "787",
                "cityName": "义马市",
                "cityPinYin": "yimashi",
                "cityGroupName": "Y",
                "cityDistrict": "0398"
            },
            {
                "cityCode": "265",
                "cityName": "天水市",
                "cityPinYin": "tianshui",
                "cityGroupName": "T",
                "cityDistrict": "0938"
            },
            {
                "cityCode": "633",
                "cityName": "穆棱市",
                "cityPinYin": "mulengshi",
                "cityGroupName": "M",
                "cityDistrict": "0453"
            },
            {
                "cityCode": "63",
                "cityName": "东方市",
                "cityPinYin": "dongfang",
                "cityGroupName": "D",
                "cityDistrict": "0807"
            },
            {
                "cityCode": "459",
                "cityName": "福安市",
                "cityPinYin": "fuanshi",
                "cityGroupName": "F",
                "cityDistrict": "0593"
            },
            {
                "cityCode": "102",
                "cityName": "菏泽市",
                "cityPinYin": "heze",
                "cityGroupName": "H",
                "cityDistrict": "0530"
            },
            {
                "cityCode": "662",
                "cityName": "沁阳市",
                "cityPinYin": "qinyangshi",
                "cityGroupName": "Q",
                "cityDistrict": "0391"
            },
            {
                "cityCode": "528",
                "cityName": "霍林郭勒市",
                "cityPinYin": "huolinguoleshi",
                "cityGroupName": "H",
                "cityDistrict": "0475"
            },
            {
                "cityCode": "2",
                "cityName": "安庆市",
                "cityPinYin": "anqing",
                "cityGroupName": "A",
                "cityDistrict": "0556"
            },
            {
                "cityCode": "761",
                "cityName": "新乐市",
                "cityPinYin": "xinleshi",
                "cityGroupName": "X",
                "cityDistrict": "0311"
            },
            {
                "cityCode": "33",
                "cityName": "重庆市",
                "cityPinYin": "chongqing",
                "cityGroupName": "C",
                "cityDistrict": "023"
            },
            {
                "cityCode": "231",
                "cityName": "苏州市",
                "cityPinYin": "suzhou",
                "cityGroupName": "S",
                "cityDistrict": "0512"
            },
            {
                "cityCode": "374",
                "cityName": "安丘市",
                "cityPinYin": "anqiushi",
                "cityGroupName": "A",
                "cityDistrict": "0536"
            },
            {
                "cityCode": "145",
                "cityName": "嘉峪关市",
                "cityPinYin": "jiayuguan",
                "cityGroupName": "J",
                "cityDistrict": "0937"
            },
            {
                "cityCode": "825",
                "cityName": "子长市",
                "cityPinYin": "zichangshi",
                "cityGroupName": "Z",
                "cityDistrict": "0911"
            },
            {
                "cityCode": "380",
                "cityName": "北安市",
                "cityPinYin": "beianshi",
                "cityGroupName": "B",
                "cityDistrict": "0456"
            },
            {
                "cityCode": "288",
                "cityName": "文山壮族苗族自治州",
                "cityPinYin": "wenshanzhou",
                "cityGroupName": "W",
                "cityDistrict": "0876"
            },
            {
                "cityCode": "100",
                "cityName": "衡阳市",
                "cityPinYin": "hengyang",
                "cityGroupName": "H",
                "cityDistrict": "0734"
            },
            {
                "cityCode": "788",
                "cityName": "义乌市",
                "cityPinYin": "yiwushi",
                "cityGroupName": "Y",
                "cityDistrict": "0579"
            },
            {
                "cityCode": "345",
                "cityName": "周口市",
                "cityPinYin": "zhoukou",
                "cityGroupName": "Z",
                "cityDistrict": "0394"
            },
            {
                "cityCode": "353",
                "cityName": "驻马店市",
                "cityPinYin": "zhumadian",
                "cityGroupName": "Z",
                "cityDistrict": "0396"
            },
            {
                "cityCode": "394",
                "cityName": "昌都市",
                "cityPinYin": "changdongshi",
                "cityGroupName": "C",
                "cityDistrict": "0895"
            },
            {
                "cityCode": "433",
                "cityName": "东宁市",
                "cityPinYin": "dongningshi",
                "cityGroupName": "D",
                "cityDistrict": "0453"
            },
            {
                "cityCode": "544",
                "cityName": "晋江市",
                "cityPinYin": "jinjiangshi",
                "cityGroupName": "J",
                "cityDistrict": "0595"
            },
            {
                "cityCode": "700",
                "cityName": "松滋市",
                "cityPinYin": "songzishi",
                "cityGroupName": "S",
                "cityDistrict": "0716"
            },
            {
                "cityCode": "372",
                "cityName": "安陆市",
                "cityPinYin": "anlushi",
                "cityGroupName": "A",
                "cityDistrict": "0712"
            },
            {
                "cityCode": "32",
                "cityName": "成都市",
                "cityPinYin": "chengdu",
                "cityGroupName": "C",
                "cityDistrict": "028"
            },
            {
                "cityCode": "436",
                "cityName": "东兴市",
                "cityPinYin": "dongxingshi",
                "cityGroupName": "D",
                "cityDistrict": "0770"
            },
            {
                "cityCode": "310",
                "cityName": "湘西土家族苗族自治州",
                "cityPinYin": "xiangxizhou",
                "cityGroupName": "X",
                "cityDistrict": "0743"
            },
            {
                "cityCode": "593",
                "cityName": "林芝市",
                "cityPinYin": "linzhishi",
                "cityGroupName": "L",
                "cityDistrict": "0894"
            },
            {
                "cityCode": "554",
                "cityName": "开平市",
                "cityPinYin": "kaipingshi",
                "cityGroupName": "K",
                "cityDistrict": "0750"
            },
            {
                "cityCode": "752",
                "cityName": "香格里拉市",
                "cityPinYin": "xianggelilashi",
                "cityGroupName": "X",
                "cityDistrict": "0887"
            },
            {
                "cityCode": "669",
                "cityName": "任丘市",
                "cityPinYin": "renqiushi",
                "cityGroupName": "R",
                "cityDistrict": "0317"
            },
            {
                "cityCode": "7",
                "cityName": "安康市",
                "cityPinYin": "ankang",
                "cityGroupName": "A",
                "cityDistrict": "0915"
            },
            {
                "cityCode": "505",
                "cityName": "合作市",
                "cityPinYin": "he作shi",
                "cityGroupName": "H",
                "cityDistrict": "0941"
            },
            {
                "cityCode": "322",
                "cityName": "阳泉市",
                "cityPinYin": "yangquan",
                "cityGroupName": "Y",
                "cityDistrict": "0353"
            },
            {
                "cityCode": "646",
                "cityName": "磐石市",
                "cityPinYin": "panshishi",
                "cityGroupName": "P",
                "cityDistrict": "0432"
            },
            {
                "cityCode": "93",
                "cityName": "呼和浩特市",
                "cityPinYin": "huhehaote",
                "cityGroupName": "H",
                "cityDistrict": "0471"
            },
            {
                "cityCode": "596",
                "cityName": "临江市",
                "cityPinYin": "linjiangshi",
                "cityGroupName": "L",
                "cityDistrict": "0439"
            },
            {
                "cityCode": "446",
                "cityName": "鄂托克旗",
                "cityPinYin": "dongtuokeqi",
                "cityGroupName": "E",
                "cityDistrict": "0477"
            },
            {
                "cityCode": "405",
                "cityName": "澄江市",
                "cityPinYin": "chengjiangshi",
                "cityGroupName": "C",
                "cityDistrict": "0877"
            },
            {
                "cityCode": "253",
                "cityName": "遂宁市",
                "cityPinYin": "suining",
                "cityGroupName": "S",
                "cityDistrict": "0825"
            },
            {
                "cityCode": "559",
                "cityName": "科尔沁右翼前旗",
                "cityPinYin": "kedongqinyouyiqianqi",
                "cityGroupName": "K",
                "cityDistrict": "0482"
            },
            {
                "cityCode": "721",
                "cityName": "图木舒克市",
                "cityPinYin": "tumushukeshi",
                "cityGroupName": "T",
                "cityDistrict": "1998"
            },
            {
                "cityCode": "375",
                "cityName": "敖汉旗",
                "cityPinYin": "aohanqi",
                "cityGroupName": "A",
                "cityDistrict": "0476"
            },
            {
                "cityCode": "517",
                "cityName": "华亭市",
                "cityPinYin": "huatingshi",
                "cityGroupName": "H",
                "cityDistrict": "0933"
            },
            {
                "cityCode": "363",
                "cityName": "阿拉山口市",
                "cityPinYin": "alashankoushi",
                "cityGroupName": "A",
                "cityDistrict": "0909"
            },
            {
                "cityCode": "575",
                "cityName": "兰溪市",
                "cityPinYin": "lanxishi",
                "cityGroupName": "L",
                "cityDistrict": "0579"
            },
            {
                "cityCode": "473",
                "cityName": "高邮市",
                "cityPinYin": "gaoyoushi",
                "cityGroupName": "G",
                "cityDistrict": "0514"
            },
            {
                "cityCode": "508",
                "cityName": "河间市",
                "cityPinYin": "hejianshi",
                "cityGroupName": "H",
                "cityDistrict": "0317"
            },
            {
                "cityCode": "283",
                "cityName": "乌兰察布市",
                "cityPinYin": "wulanchabu",
                "cityGroupName": "W",
                "cityDistrict": "0474"
            },
            {
                "cityCode": "300",
                "cityName": "许昌市",
                "cityPinYin": "xuchang",
                "cityGroupName": "X",
                "cityDistrict": "0374"
            },
            {
                "cityCode": "95",
                "cityName": "葫芦岛市",
                "cityPinYin": "huludao",
                "cityGroupName": "H",
                "cityDistrict": "0429"
            },
            {
                "cityCode": "243",
                "cityName": "双鸭山市",
                "cityPinYin": "shuangyashan",
                "cityGroupName": "S",
                "cityDistrict": "0469"
            },
            {
                "cityCode": "223",
                "cityName": "庆阳市",
                "cityPinYin": "qingyang",
                "cityGroupName": "Q",
                "cityDistrict": "0934"
            },
            {
                "cityCode": "74",
                "cityName": "抚顺市",
                "cityPinYin": "fushun",
                "cityGroupName": "F",
                "cityDistrict": "0413"
            },
            {
                "cityCode": "770",
                "cityName": "兴宁市",
                "cityPinYin": "xingningshi",
                "cityGroupName": "X",
                "cityDistrict": "0753"
            },
            {
                "cityCode": "122",
                "cityName": "海西州",
                "cityPinYin": "haixizhou",
                "cityGroupName": "H",
                "cityDistrict": "0977"
            },
            {
                "cityCode": "166",
                "cityName": "临汾市",
                "cityPinYin": "linfen",
                "cityGroupName": "L",
                "cityDistrict": "0357"
            },
            {
                "cityCode": "323",
                "cityName": "延吉市",
                "cityPinYin": "yanji",
                "cityGroupName": "Y",
                "cityDistrict": "1433"
            },
            {
                "cityCode": "381",
                "cityName": "北流市",
                "cityPinYin": "beiliushi",
                "cityGroupName": "B",
                "cityDistrict": "0775"
            },
            {
                "cityCode": "202",
                "cityName": "攀枝花市",
                "cityPinYin": "panzhihua",
                "cityGroupName": "P",
                "cityDistrict": "0812"
            },
            {
                "cityCode": "396",
                "cityName": "昌吉市",
                "cityPinYin": "changjishi",
                "cityGroupName": "C",
                "cityDistrict": "0994"
            },
            {
                "cityCode": "654",
                "cityName": "普宁市",
                "cityPinYin": "puningshi",
                "cityGroupName": "P",
                "cityDistrict": "0663"
            },
            {
                "cityCode": "236",
                "cityName": "宿迁市",
                "cityPinYin": "suqian",
                "cityGroupName": "S",
                "cityDistrict": "0527"
            },
            {
                "cityCode": "732",
                "cityName": "乌拉特后旗",
                "cityPinYin": "wulatehouqi",
                "cityGroupName": "W",
                "cityDistrict": "0478"
            },
            {
                "cityCode": "31",
                "cityName": "保亭黎族苗族自治县",
                "cityPinYin": "baotinglizumiaozuzizhixian",
                "cityGroupName": "B",
                "cityDistrict": "0801"
            },
            {
                "cityCode": "450",
                "cityName": "恩施市",
                "cityPinYin": "dongshishi",
                "cityGroupName": "E",
                "cityDistrict": "0718"
            },
            {
                "cityCode": "504",
                "cityName": "合山市",
                "cityPinYin": "heshanshi",
                "cityGroupName": "H",
                "cityDistrict": "1772"
            },
            {
                "cityCode": "185",
                "cityName": "牡丹江市",
                "cityPinYin": "mudanjiang",
                "cityGroupName": "M",
                "cityDistrict": "0453"
            },
            {
                "cityCode": "184",
                "cityName": "马鞍山市",
                "cityPinYin": "maanshan",
                "cityGroupName": "M",
                "cityDistrict": "0555"
            },
            {
                "cityCode": "611",
                "cityName": "龙泉市",
                "cityPinYin": "longquanshi",
                "cityGroupName": "L",
                "cityDistrict": "0578"
            },
            {
                "cityCode": "683",
                "cityName": "韶山市",
                "cityPinYin": "shaoshanshi",
                "cityGroupName": "S",
                "cityDistrict": "0732"
            },
            {
                "cityCode": "267",
                "cityName": "通辽市",
                "cityPinYin": "tongliao",
                "cityGroupName": "T",
                "cityDistrict": "0475"
            },
            {
                "cityCode": "41",
                "cityName": "长治市",
                "cityPinYin": "changzhi",
                "cityGroupName": "C",
                "cityDistrict": "0355"
            },
            {
                "cityCode": "278",
                "cityName": "乌鲁木齐市",
                "cityPinYin": "wulumuqi",
                "cityGroupName": "W",
                "cityDistrict": "0991"
            },
            {
                "cityCode": "57",
                "cityName": "大同市",
                "cityPinYin": "datong",
                "cityGroupName": "D",
                "cityDistrict": "0352"
            },
            {
                "cityCode": "48",
                "cityName": "楚雄州",
                "cityPinYin": "chuxiongzhou",
                "cityGroupName": "C",
                "cityDistrict": "0878"
            },
            {
                "cityCode": "564",
                "cityName": "克什克腾旗",
                "cityPinYin": "keshiketengqi",
                "cityGroupName": "K",
                "cityDistrict": "0476"
            },
            {
                "cityCode": "106",
                "cityName": "淮北市",
                "cityPinYin": "huaibei",
                "cityGroupName": "H",
                "cityDistrict": "0561"
            },
            {
                "cityCode": "691",
                "cityName": "石狮市",
                "cityPinYin": "shishishi",
                "cityGroupName": "S",
                "cityDistrict": "0595"
            },
            {
                "cityCode": "534",
                "cityName": "建德市",
                "cityPinYin": "jiandeshi",
                "cityGroupName": "J",
                "cityDistrict": "0571"
            },
            {
                "cityCode": "132",
                "cityName": "九江市",
                "cityPinYin": "jiujiang",
                "cityGroupName": "J",
                "cityDistrict": "0792"
            },
            {
                "cityCode": "728",
                "cityName": "温岭市",
                "cityPinYin": "wenlingshi",
                "cityGroupName": "W",
                "cityDistrict": "0576"
            },
            {
                "cityCode": "677",
                "cityName": "瑞金市",
                "cityPinYin": "ruijinshi",
                "cityGroupName": "R",
                "cityDistrict": "0797"
            },
            {
                "cityCode": "69",
                "cityName": "鄂尔多斯市",
                "cityPinYin": "eerduosi",
                "cityGroupName": "E",
                "cityDistrict": "0477"
            },
            {
                "cityCode": "771",
                "cityName": "兴平市",
                "cityPinYin": "xingpingshi",
                "cityGroupName": "X",
                "cityDistrict": "0910"
            },
            {
                "cityCode": "520",
                "cityName": "化州市",
                "cityPinYin": "huazhoushi",
                "cityGroupName": "H",
                "cityDistrict": "0668"
            },
            {
                "cityCode": "641",
                "cityName": "宁安市",
                "cityPinYin": "ninganshi",
                "cityGroupName": "N",
                "cityDistrict": "0453"
            },
            {
                "cityCode": "204",
                "cityName": "盘锦市",
                "cityPinYin": "panjin",
                "cityGroupName": "P",
                "cityDistrict": "0427"
            },
            {
                "cityCode": "673",
                "cityName": "汝州市",
                "cityPinYin": "ruzhoushi",
                "cityGroupName": "R",
                "cityDistrict": "0375"
            },
            {
                "cityCode": "220",
                "cityName": "黔西南州",
                "cityPinYin": "qianxinanzhou",
                "cityGroupName": "Q",
                "cityDistrict": "0859"
            },
            {
                "cityCode": "495",
                "cityName": "海南藏族自治州",
                "cityPinYin": "hainancangzuzizhizhou",
                "cityGroupName": "H",
                "cityDistrict": "0974"
            },
            {
                "cityCode": "573",
                "cityName": "莱阳市",
                "cityPinYin": "laiyangshi",
                "cityGroupName": "L",
                "cityDistrict": "0535"
            },
            {
                "cityCode": "603",
                "cityName": "凌海市",
                "cityPinYin": "linghaishi",
                "cityGroupName": "L",
                "cityDistrict": "0416"
            },
            {
                "cityCode": "245",
                "cityName": "三明市",
                "cityPinYin": "sanming",
                "cityGroupName": "S",
                "cityDistrict": "0598"
            },
            {
                "cityCode": "486",
                "cityName": "桂平市",
                "cityPinYin": "guipingshi",
                "cityGroupName": "G",
                "cityDistrict": "1755"
            },
            {
                "cityCode": "3",
                "cityName": "安阳市",
                "cityPinYin": "anyang",
                "cityGroupName": "A",
                "cityDistrict": "0372"
            },
            {
                "cityCode": "327",
                "cityName": "伊春市",
                "cityPinYin": "yichun",
                "cityGroupName": "Y",
                "cityDistrict": "0458"
            },
            {
                "cityCode": "250",
                "cityName": "三门峡市",
                "cityPinYin": "sanmenxia",
                "cityGroupName": "S",
                "cityDistrict": "0398"
            },
            {
                "cityCode": "786",
                "cityName": "宜兴市",
                "cityPinYin": "yixingshi",
                "cityGroupName": "Y",
                "cityDistrict": "0510"
            },
            {
                "cityCode": "9",
                "cityName": "阿勒泰地区",
                "cityPinYin": "aletaidiqu",
                "cityGroupName": "A",
                "cityDistrict": "0906"
            },
            {
                "cityCode": "580",
                "cityName": "乐平市",
                "cityPinYin": "lepingshi",
                "cityGroupName": "L",
                "cityDistrict": "0798"
            },
            {
                "cityCode": "468",
                "cityName": "甘孜藏族自治州",
                "cityPinYin": "ganzicangzuzizhizhou",
                "cityGroupName": "G",
                "cityDistrict": "0836"
            },
            {
                "cityCode": "572",
                "cityName": "莱西市",
                "cityPinYin": "laixishi",
                "cityGroupName": "L",
                "cityDistrict": "0532"
            },
            {
                "cityCode": "619",
                "cityName": "马尔康市",
                "cityPinYin": "madongkangshi",
                "cityGroupName": "M",
                "cityDistrict": "0837"
            },
            {
                "cityCode": "487",
                "cityName": "果洛藏族自治州",
                "cityPinYin": "guoluocangzuzizhizhou",
                "cityGroupName": "G",
                "cityDistrict": "0975"
            },
            {
                "cityCode": "606",
                "cityName": "龙港市",
                "cityPinYin": "longgangshi",
                "cityGroupName": "L",
                "cityDistrict": "0577"
            },
            {
                "cityCode": "686",
                "cityName": "射洪市",
                "cityPinYin": "shehongshi",
                "cityGroupName": "S",
                "cityDistrict": "0825"
            },
            {
                "cityCode": "612",
                "cityName": "隆昌市",
                "cityPinYin": "longchangshi",
                "cityGroupName": "L",
                "cityDistrict": "1832"
            },
            {
                "cityCode": "417",
                "cityName": "大石桥市",
                "cityPinYin": "dashiqiaoshi",
                "cityGroupName": "D",
                "cityDistrict": "0417"
            },
            {
                "cityCode": "384",
                "cityName": "北镇市",
                "cityPinYin": "beizhenshi",
                "cityGroupName": "B",
                "cityDistrict": "0416"
            },
            {
                "cityCode": "491",
                "cityName": "海城市",
                "cityPinYin": "haichengshi",
                "cityGroupName": "H",
                "cityDistrict": "0412"
            },
            {
                "cityCode": "58",
                "cityName": "大理州",
                "cityPinYin": "dalizhou",
                "cityGroupName": "D",
                "cityDistrict": "0872"
            },
            {
                "cityCode": "8",
                "cityName": "阿克苏地区",
                "cityPinYin": "ankang",
                "cityGroupName": "A",
                "cityDistrict": "0997"
            },
            {
                "cityCode": "98",
                "cityName": "湖州市",
                "cityPinYin": "huzhou",
                "cityGroupName": "H",
                "cityDistrict": "0572"
            },
            {
                "cityCode": "815",
                "cityName": "正蓝旗",
                "cityPinYin": "zhenglanqi",
                "cityGroupName": "Z",
                "cityDistrict": "0479"
            },
            {
                "cityCode": "548",
                "cityName": "景洪市",
                "cityPinYin": "jinghongshi",
                "cityGroupName": "J",
                "cityDistrict": "0691"
            },
            {
                "cityCode": "416",
                "cityName": "大理市",
                "cityPinYin": "dalishi",
                "cityGroupName": "D",
                "cityDistrict": "0872"
            },
            {
                "cityCode": "22",
                "cityName": "白城市",
                "cityPinYin": "baicheng",
                "cityGroupName": "B",
                "cityDistrict": "0436"
            },
            {
                "cityCode": "748",
                "cityName": "西昌市",
                "cityPinYin": "xichangshi",
                "cityGroupName": "X",
                "cityDistrict": "0834"
            },
            {
                "cityCode": "587",
                "cityName": "荔浦市",
                "cityPinYin": "lipushi",
                "cityGroupName": "L",
                "cityDistrict": "0773"
            },
            {
                "cityCode": "174",
                "cityName": "乐山市",
                "cityPinYin": "leshan",
                "cityGroupName": "L",
                "cityDistrict": "0833"
            },
            {
                "cityCode": "4",
                "cityName": "阿拉善盟",
                "cityPinYin": "alashanmeng",
                "cityGroupName": "A",
                "cityDistrict": "0483"
            },
            {
                "cityCode": "602",
                "cityName": "灵武市",
                "cityPinYin": "lingwushi",
                "cityGroupName": "L",
                "cityDistrict": "0951"
            },
            {
                "cityCode": "693",
                "cityName": "寿光市",
                "cityPinYin": "shouguangshi",
                "cityGroupName": "S",
                "cityDistrict": "0536"
            },
            {
                "cityCode": "623",
                "cityName": "梅河口市",
                "cityPinYin": "meihekoushi",
                "cityGroupName": "M",
                "cityDistrict": "0435"
            },
            {
                "cityCode": "284",
                "cityName": "梧州市",
                "cityPinYin": "wuzhou",
                "cityGroupName": "W",
                "cityDistrict": "0774"
            },
            {
                "cityCode": "208",
                "cityName": "平凉市",
                "cityPinYin": "pingliang",
                "cityGroupName": "P",
                "cityDistrict": "0933"
            },
            {
                "cityCode": "692",
                "cityName": "石首市",
                "cityPinYin": "shishoushi",
                "cityGroupName": "S",
                "cityDistrict": "0716"
            },
            {
                "cityCode": "272",
                "cityName": "铜川市",
                "cityPinYin": "tongchuan",
                "cityGroupName": "T",
                "cityDistrict": "0919"
            },
            {
                "cityCode": "811",
                "cityName": "漳平市",
                "cityPinYin": "zhangpingshi",
                "cityGroupName": "Z",
                "cityDistrict": "0597"
            },
            {
                "cityCode": "568",
                "cityName": "库伦旗",
                "cityPinYin": "kulunqi",
                "cityGroupName": "K",
                "cityDistrict": "0475"
            },
            {
                "cityCode": "46",
                "cityName": "崇左市",
                "cityPinYin": "chongzuo",
                "cityGroupName": "C",
                "cityDistrict": "1771"
            },
            {
                "cityCode": "113",
                "cityName": "鹤壁市",
                "cityPinYin": "hebi",
                "cityGroupName": "H",
                "cityDistrict": "0392"
            },
            {
                "cityCode": "542",
                "cityName": "界首市",
                "cityPinYin": "jieshoushi",
                "cityGroupName": "J",
                "cityDistrict": "1558"
            },
            {
                "cityCode": "719",
                "cityName": "铜仁市",
                "cityPinYin": "tongrenshi",
                "cityGroupName": "T",
                "cityDistrict": "0856"
            },
            {
                "cityCode": "438",
                "cityName": "都江堰市",
                "cityPinYin": "dongjiangyanshi",
                "cityGroupName": "D",
                "cityDistrict": "028"
            },
            {
                "cityCode": "722",
                "cityName": "土默特右旗",
                "cityPinYin": "tumoteyouqi",
                "cityGroupName": "T",
                "cityDistrict": "0472"
            },
            {
                "cityCode": "269",
                "cityName": "天门市",
                "cityPinYin": "tianmen",
                "cityGroupName": "T",
                "cityDistrict": "1728"
            },
            {
                "cityCode": "738",
                "cityName": "无为市",
                "cityPinYin": "wuweishi",
                "cityGroupName": "W",
                "cityDistrict": "0553"
            },
            {
                "cityCode": "704",
                "cityName": "塔城市",
                "cityPinYin": "tachengshi",
                "cityGroupName": "T",
                "cityDistrict": "0901"
            },
            {
                "cityCode": "567",
                "cityName": "库尔勒市",
                "cityPinYin": "kudongleshi",
                "cityGroupName": "K",
                "cityDistrict": "0996"
            },
            {
                "cityCode": "628",
                "cityName": "密山市",
                "cityPinYin": "mishanshi",
                "cityGroupName": "M",
                "cityDistrict": "0467"
            },
            {
                "cityCode": "530",
                "cityName": "吉首市",
                "cityPinYin": "jishoushi",
                "cityGroupName": "J",
                "cityDistrict": "0743"
            },
            {
                "cityCode": "290",
                "cityName": "武威市",
                "cityPinYin": "wuwei",
                "cityGroupName": "W",
                "cityDistrict": "1935"
            },
            {
                "cityCode": "527",
                "cityName": "霍尔果斯市",
                "cityPinYin": "huodongguosishi",
                "cityGroupName": "H",
                "cityDistrict": "0999"
            },
            {
                "cityCode": "684",
                "cityName": "邵东市",
                "cityPinYin": "shaodongshi",
                "cityGroupName": "S",
                "cityDistrict": "0739"
            },
            {
                "cityCode": "168",
                "cityName": "辽源市",
                "cityPinYin": "liaoyuan",
                "cityGroupName": "L",
                "cityDistrict": "0437"
            },
            {
                "cityCode": "827",
                "cityName": "邹平市",
                "cityPinYin": "zoupingshi",
                "cityGroupName": "Z",
                "cityDistrict": "0543"
            },
            {
                "cityCode": "759",
                "cityName": "新巴尔虎右旗",
                "cityPinYin": "xinbadonghuyouqi",
                "cityGroupName": "X",
                "cityDistrict": "0470"
            },
            {
                "cityCode": "291",
                "cityName": "吴忠市",
                "cityPinYin": "wuzhong",
                "cityGroupName": "W",
                "cityDistrict": "0953"
            },
            {
                "cityCode": "557",
                "cityName": "凯里市",
                "cityPinYin": "kailishi",
                "cityGroupName": "K",
                "cityDistrict": "0855"
            },
            {
                "cityCode": "343",
                "cityName": "珠海市",
                "cityPinYin": "zhuhai",
                "cityGroupName": "Z",
                "cityDistrict": "0756"
            },
            {
                "cityCode": "419",
                "cityName": "丹江口市",
                "cityPinYin": "danjiangkoushi",
                "cityGroupName": "D",
                "cityDistrict": "0719"
            },
            {
                "cityCode": "193",
                "cityName": "南充市",
                "cityPinYin": "nanchong",
                "cityGroupName": "N",
                "cityDistrict": "0817"
            },
            {
                "cityCode": "741",
                "cityName": "五大连池市",
                "cityPinYin": "wudalianchishi",
                "cityGroupName": "W",
                "cityDistrict": "0456"
            },
            {
                "cityCode": "617",
                "cityName": "罗定市",
                "cityPinYin": "luodingshi",
                "cityGroupName": "L",
                "cityDistrict": "0766"
            },
            {
                "cityCode": "697",
                "cityName": "水富市",
                "cityPinYin": "shuifushi",
                "cityGroupName": "S",
                "cityDistrict": "0870"
            },
            {
                "cityCode": "812",
                "cityName": "樟树市",
                "cityPinYin": "zhangshushi",
                "cityGroupName": "Z",
                "cityDistrict": "0795"
            },
            {
                "cityCode": "83",
                "cityName": "贵港市",
                "cityPinYin": "guigang",
                "cityGroupName": "G",
                "cityDistrict": "1755"
            },
            {
                "cityCode": "532",
                "cityName": "监利市",
                "cityPinYin": "jianlishi",
                "cityGroupName": "J",
                "cityDistrict": "0716"
            },
            {
                "cityCode": "781",
                "cityName": "伊犁哈萨克自治州",
                "cityPinYin": "yilihasakezizhizhou",
                "cityGroupName": "Y",
                "cityDistrict": "0999"
            },
            {
                "cityCode": "708",
                "cityName": "泰兴市",
                "cityPinYin": "taixingshi",
                "cityGroupName": "T",
                "cityDistrict": "0523"
            },
            {
                "cityCode": "457",
                "cityName": "凤城市",
                "cityPinYin": "fengchengshi",
                "cityGroupName": "F",
                "cityDistrict": "0415"
            },
            {
                "cityCode": "151",
                "cityName": "克孜勒苏柯州",
                "cityPinYin": "kezilesukezhou",
                "cityGroupName": "K",
                "cityDistrict": "0908"
            },
            {
                "cityCode": "359",
                "cityName": "阿巴嘎旗",
                "cityPinYin": "abagaqi",
                "cityGroupName": "A",
                "cityDistrict": "0479"
            },
            {
                "cityCode": "80",
                "cityName": "贵阳市",
                "cityPinYin": "guiyang",
                "cityGroupName": "G",
                "cityDistrict": "0851"
            },
            {
                "cityCode": "23",
                "cityName": "百色市",
                "cityPinYin": "baise",
                "cityGroupName": "B",
                "cityDistrict": "0776"
            },
            {
                "cityCode": "489",
                "cityName": "海安市",
                "cityPinYin": "haianshi",
                "cityGroupName": "H",
                "cityDistrict": "0513"
            },
            {
                "cityCode": "35",
                "cityName": "长春市",
                "cityPinYin": "changchun",
                "cityGroupName": "C",
                "cityDistrict": "0431"
            },
            {
                "cityCode": "150",
                "cityName": "克拉玛依市",
                "cityPinYin": "kelamayi",
                "cityGroupName": "K",
                "cityDistrict": "0990"
            },
            {
                "cityCode": "344",
                "cityName": "镇江市",
                "cityPinYin": "zhenjiang",
                "cityGroupName": "Z",
                "cityDistrict": "0511"
            },
            {
                "cityCode": "590",
                "cityName": "涟源市",
                "cityPinYin": "lianyuanshi",
                "cityGroupName": "L",
                "cityDistrict": "0738"
            },
            {
                "cityCode": "824",
                "cityName": "资兴市",
                "cityPinYin": "zixingshi",
                "cityGroupName": "Z",
                "cityDistrict": "0735"
            },
            {
                "cityCode": "19",
                "cityName": "亳州市",
                "cityPinYin": "bozhou",
                "cityGroupName": "B",
                "cityDistrict": "0558"
            },
            {
                "cityCode": "11",
                "cityName": "北京市",
                "cityPinYin": "beijing",
                "cityGroupName": "B",
                "cityDistrict": "010"
            },
            {
                "cityCode": "638",
                "cityName": "南雄市",
                "cityPinYin": "nanxiongshi",
                "cityGroupName": "N",
                "cityDistrict": "0751"
            },
            {
                "cityCode": "652",
                "cityName": "平泉市",
                "cityPinYin": "pingquanshi",
                "cityGroupName": "P",
                "cityDistrict": "0314"
            },
            {
                "cityCode": "763",
                "cityName": "新民市",
                "cityPinYin": "xinminshi",
                "cityGroupName": "X",
                "cityDistrict": "024"
            },
            {
                "cityCode": "605",
                "cityName": "浏阳市",
                "cityPinYin": "liuyangshi",
                "cityGroupName": "L",
                "cityDistrict": "0731"
            },
            {
                "cityCode": "172",
                "cityName": "来宾市",
                "cityPinYin": "laibin",
                "cityGroupName": "L",
                "cityDistrict": "1772"
            },
            {
                "cityCode": "155",
                "cityName": "临沂市",
                "cityPinYin": "linyi",
                "cityGroupName": "L",
                "cityDistrict": "0539"
            },
            {
                "cityCode": "666",
                "cityName": "邛崃市",
                "cityPinYin": "qionglaishi",
                "cityGroupName": "Q",
                "cityDistrict": "028"
            },
            {
                "cityCode": "246",
                "cityName": "十堰市",
                "cityPinYin": "shiyan",
                "cityGroupName": "S",
                "cityDistrict": "0719"
            },
            {
                "cityCode": "598",
                "cityName": "临夏回族自治州",
                "cityPinYin": "linxiahuizuzizhizhou",
                "cityGroupName": "L",
                "cityDistrict": "0930"
            },
            {
                "cityCode": "483",
                "cityName": "广汉市",
                "cityPinYin": "guanghanshi",
                "cityGroupName": "G",
                "cityDistrict": "0838"
            },
            {
                "cityCode": "681",
                "cityName": "山南市",
                "cityPinYin": "shannanshi",
                "cityGroupName": "S",
                "cityDistrict": "0893"
            },
            {
                "cityCode": "285",
                "cityName": "五指山市",
                "cityPinYin": "wuzhishan",
                "cityGroupName": "W",
                "cityDistrict": "1897"
            },
            {
                "cityCode": "90",
                "cityName": "杭州市",
                "cityPinYin": "hangzhou",
                "cityGroupName": "H",
                "cityDistrict": "0571"
            },
            {
                "cityCode": "712",
                "cityName": "天长市",
                "cityPinYin": "tianchangshi",
                "cityGroupName": "T",
                "cityDistrict": "0550"
            },
            {
                "cityCode": "71",
                "cityName": "恩施州",
                "cityPinYin": "enshizhou",
                "cityGroupName": "E",
                "cityDistrict": "0718"
            },
            {
                "cityCode": "650",
                "cityName": "平果市",
                "cityPinYin": "pingguoshi",
                "cityGroupName": "P",
                "cityDistrict": "0776"
            },
            {
                "cityCode": "424",
                "cityName": "德令哈市",
                "cityPinYin": "delinghashi",
                "cityGroupName": "D",
                "cityDistrict": "0977"
            },
            {
                "cityCode": "653",
                "cityName": "凭祥市",
                "cityPinYin": "pingxiangshi",
                "cityGroupName": "P",
                "cityDistrict": "1771"
            },
            {
                "cityCode": "406",
                "cityName": "赤壁市",
                "cityPinYin": "chibishi",
                "cityGroupName": "C",
                "cityDistrict": "0715"
            },
            {
                "cityCode": "586",
                "cityName": "利川市",
                "cityPinYin": "lichuanshi",
                "cityGroupName": "L",
                "cityDistrict": "0718"
            },
            {
                "cityCode": "358",
                "cityName": "中卫市",
                "cityPinYin": "zhongwei",
                "cityGroupName": "Z",
                "cityDistrict": "1953"
            },
            {
                "cityCode": "169",
                "cityName": "龙岩市",
                "cityPinYin": "longyan",
                "cityGroupName": "L",
                "cityDistrict": "0597"
            },
            {
                "cityCode": "461",
                "cityName": "福清市",
                "cityPinYin": "fuqingshi",
                "cityGroupName": "F",
                "cityDistrict": "0591"
            },
            {
                "cityCode": "427",
                "cityName": "登封市",
                "cityPinYin": "dengfengshi",
                "cityGroupName": "D",
                "cityDistrict": "0371"
            },
            {
                "cityCode": "442",
                "cityName": "峨眉山市",
                "cityPinYin": "dongmeishanshi",
                "cityGroupName": "E",
                "cityDistrict": "0833"
            },
            {
                "cityCode": "314",
                "cityName": "银川市",
                "cityPinYin": "yinchuan",
                "cityGroupName": "Y",
                "cityDistrict": "0951"
            },
            {
                "cityCode": "502",
                "cityName": "杭锦后旗",
                "cityPinYin": "hangjinhouqi",
                "cityGroupName": "H",
                "cityDistrict": "0478"
            },
            {
                "cityCode": "447",
                "cityName": "鄂托克前旗",
                "cityPinYin": "dongtuokeqianqi",
                "cityGroupName": "E",
                "cityDistrict": "0477"
            },
            {
                "cityCode": "316",
                "cityName": "岳阳市",
                "cityPinYin": "yueyang",
                "cityGroupName": "Y",
                "cityDistrict": "0730"
            },
            {
                "cityCode": "56",
                "cityName": "德州市",
                "cityPinYin": "dezhou",
                "cityGroupName": "D",
                "cityDistrict": "0534"
            },
            {
                "cityCode": "51",
                "cityName": "昌江黎族自治县",
                "cityPinYin": "changjianglizuzizhixian",
                "cityGroupName": "C",
                "cityDistrict": "0803"
            },
            {
                "cityCode": "755",
                "cityName": "镶黄旗",
                "cityPinYin": "xianghuangqi",
                "cityGroupName": "X",
                "cityDistrict": "0479"
            },
            {
                "cityCode": "474",
                "cityName": "高州市",
                "cityPinYin": "gaozhoushi",
                "cityGroupName": "G",
                "cityDistrict": "0668"
            },
            {
                "cityCode": "331",
                "cityName": "永州市",
                "cityPinYin": "yongzhou",
                "cityGroupName": "Y",
                "cityDistrict": "0746"
            },
            {
                "cityCode": "255",
                "cityName": "商洛市",
                "cityPinYin": "shangluo",
                "cityGroupName": "S",
                "cityDistrict": "0914"
            }
        ]
    }
}

const cacheCitys = () => {

    citys.data.rows = citys.data.rows.sort((a, b) => {return a.cityPinYin < b.cityPinYin ? -1 : 0})
    
    setBaseCitys(citys.data.rows)
}

export {
    cacheCitys
}
