
import React from "react"
import style from './sanofiMeeting.scss'
import webService from "../../util/webService"
import images from "../../util/images";
import { withTranslation } from "react-i18next";
import { Card, Dialog, Toast } from 'antd-mobile'
import { DeleteOutline } from 'antd-mobile-icons'

class SanofiMeeting extends React.Component {

  state = {
    meetingList: []
  }

  t = this.props.t

  renderList() {
    return <>
      {
        this.state.meetingList.map((item, index) => {
          return <Card key={index} className={style.card}>
            <div className={style.header}>
              <span>{item.eventCode}</span>
              <span>{item.eventName}</span>
              <span className={style.icCode}>IOCode：{item.ioCode}</span>
            </div>
            {this.renderCardFooter(item)}
            <DeleteOutline className={style.deleteIcon} fontSize={16} onClick={() => this.deleteById(item)} />
          </Card>
        })
      }
    </>
  }

  renderCardFooter(data) {
    return <div className={style.cardfooter}>
      {
        (() => {
          if (data?.detailStatus) {
            return <>
              {/* 截止日期： */}
              <span>{this.t('sanofiMeeting.k004')}：{data.validTo}</span>
              <span>Department_Name_En：{data.departmentNameEn}</span>
              <span>Area_Name_En：{data.areaNameEn}</span>
              <span>EventType：{data.eventType}</span>
              <span>StartDate：{data.startDate}</span>
              <span>EndDate：{data.endDate}</span>
              <span>EventStatus：{data.eventStatus}</span>
              {/* 收起 */}
              <span className={style.detailDescribe} onClick={() => { data.detailStatus = false; this.setState({ meetingList: this.state.meetingList }) }}>{'<<'}{this.t('sanofiMeeting.k006')}</span>
            </>
          } else {
            // 展开更多
            return <div>{this.t('sanofiMeeting.k004')}：{data.validTo} <span style={{ marginLeft: data?.detailStatus ? '0' : '10px' }} className={style.detailDescribe} onClick={() => { data.detailStatus = true; this.setState({ meetingList: this.state.meetingList }) }}>{'>>'}{this.t('sanofiMeeting.k005')}</span></div>
          }
        })()
      }
    </div>
  }

  renderEmpty() {
    return <>
      <div className={style.nodata}>
        <div className={style.box}>
          <img alt="" src={images.noSanofiMeeting}></img>
          {/* 暂无会议 */}
          <span>{this.t('sanofiMeeting.k003')}</span>
        </div>
      </div>
    </>
  }

  render() {
    return <>
      <div className={style.sanofiMeeting}>
        <div className={style.container}>
          <div>
            {
              this.state.meetingList.length ? this.renderList() : this.renderEmpty()
            }
          </div>
        </div>

        <div className={style.footer}>
          <div onClick={() => { this.props.history.push('/special/addSanofiMeeting') }}>
            {/* 添加IO */}
            {this.t('sanofiMeeting.k001')}
          </div>
          <div onClick={this.handleDelete}>
            {/* 一键删除 */}
            {this.t('sanofiMeeting.k002')}
          </div>
        </div>
      </div>
    </>
  }

  async componentDidMount() {
    Toast.show({
      icon: 'loading',
      content: this.t('confirm.k029'),
      duration: 0,
      maskClickable: false
    })
    await this.getPersonalMeetingList();
    Toast.clear();
  }

  handleDelete = async () => {
    if (!this.state.meetingList.length) {
      Dialog.alert({
        // 暂无会议
        content: this.t('sanofiMeeting.k003'),
        confirmText: this.t('common.k003'),
      })
      return
    }

    const canDelete = this.state.meetingList.every(item => item.type === 0)
    if (canDelete) {
      Dialog.alert({
        // 会议不可删除
        content: this.t('sanofiMeeting.k018'),
        confirmText: this.t('common.k003'),
      })
      return
    }
    
    Dialog.confirm({
      // 是否删除所有会议？
      content: this.t('sanofiMeeting.k007'),
      cancelText: this.t('common.k001'),
      confirmText: this.t('common.k002'),
      onConfirm: async () => {
        const { data } = await webService.special.sanofiMeeting.oneClickDeletion();
        this.checkDelete(data);
        this.getPersonalMeetingList();
      },
    })
  }

  checkDelete = (res) => {
    if (res.code === 0) {
      Toast.show({
        icon: 'success',
        // 删除成功
        content: this.t('sanofiMeeting.k008'),
      })
    } else {
      Toast.show({
        icon: 'fail',
        content: res.message,
      })
    }
  }

  deleteById = async (meeting) => {
    if (meeting.type === 0) {
      Dialog.alert({
        // 该会议不可删除
        content: this.t('sanofiMeeting.k009'),
        confirmText: this.t('common.k003'),
      })
      return
    }
    Dialog.confirm({
      // 是否删除该会议？
      content: this.t('sanofiMeeting.k010'),
      onConfirm: async () => {
        await this.getPersonalMeetingList();
        const { data } = await webService.special.sanofiMeeting.deletePersonalMeeting({ meetingId: meeting.meetingId });
        this.checkDelete(data);
        this.getPersonalMeetingList();
      },
    })

  }

  getPersonalMeetingList = async () => {
    const { data } = await webService.special.sanofiMeeting.getPersonalMeetingList();
    this.setState({
      meetingList: data.data,
    })
  }



}

export default withTranslation()(SanofiMeeting)