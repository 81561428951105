import React from 'react';
import {inject, observer } from 'mobx-react'
import style from './perApply.scss';

import { List, Selector, Input, Button, TextArea, DatePicker, Popup, Dialog, SearchBar, Toast } from 'antd-mobile'
import {
    RightOutline, CheckOutline 
} from 'antd-mobile-icons'

import Address from '../component/callcar/address';

import webService from '../util/webService';
import {ForgeRouter} from '../util/utils'
import {getCurrentPosition} from '../util/geo'

import moment from 'moment';

import { withTranslation } from "react-i18next";


/**
 * 行前审批-申请用车
 */
@inject('mainStore')
@observer
class PerApply extends React.Component {

    state = {
        listScene: [],
        currentSetList: [],
        apply: {
            beginAddr: {
                cityName: "",
                cityCode: "",
                address: "",
                name: "",
                lat: "",
                lon: ""
            },
            endAddr: {
                cityName: "",
                cityCode: "",
                address: "",
                name: "",
                lat: "",
                lon: "",
            },
            beginDate: '',
            endDate: '',
            scene: {
                id: '',
                publishId: '',
                nameCn: '',
                nameEn: ''
            },
            usageCount: '',
            usageMoney: '',
            reason: ''
        },

        addr: {
            isBegin: true,
        },
        show: false,
        showScene: false,
        sceneKey: '',

        setList: [],
        beginAddrModel: 0,
        endAddrModel: 0,
        df: {}
    }
    id = undefined;
    addrModels = [{label: this.props.t('perApply.k001'), value: 1}, {label: this.props.t('perApply.k002'), value: 2}];

    t = this.props.t

    get currentSceneId() {
        return this.state.apply.scene.id
            ? [this.state.apply.scene.id]
            : [];
    }

    get listScene() {
        let { sceneKey, listScene } = this.state;
        return sceneKey
            ? listScene.filter(a => a.nameCn.indexOf(sceneKey) !== -1)
            : listScene
    }

    get currentCity() {
        let {apply, addr} = this.state;
        if (addr.isBegin) {
            if (apply.beginAddr.cityCode) {
                return {
                    cityCode: apply.beginAddr.cityCode,
                    cityName: apply.beginAddr.cityName
                }
            } else {
                return addr
            }
        } else {
            if (apply.endAddr.cityCode) {
                return {
                    cityCode: apply.endAddr.cityCode,
                    cityName: apply.endAddr.cityName
                }
            } else {
                return addr
            }
        }
    }
    
    get addressIsOnlyCity() {
        if (this.state.addr.isBegin) {
            return this.state.df.beginAddrModel === 2
        } else {
            return this.state.df.endAddrModel === 2
        }
    }

    get beginDistance() {
        let currentSetList = this.state.currentSetList;
        if (currentSetList && currentSetList.length) {
            let beginSet = currentSetList.filter(a => a.paraCode === '6001')
            if (beginSet && beginSet.length) {
                return beginSet[0].paraValue
            }
        }
        return 0
    }

    get endDistance() {
        let currentSetList = this.state.currentSetList;
        if (currentSetList && currentSetList.length) {
            let endSet = currentSetList.filter(a => a.paraCode === '6002')
            if (endSet && endSet.length) {
                return endSet[0].paraValue
            }
        }
        return 0
    }

    get useCarTimeSpan() {
        let currentSetList = this.state.currentSetList;
        if (currentSetList && currentSetList.length) {
            let useCarTimeSet = currentSetList.filter(a => a.paraCode === '6003');
            if (useCarTimeSet && useCarTimeSet.length) {
                return useCarTimeSet[0].paraValue
            }
        }
        
        return '7';
    }

    // 用车最大金额
    get useCarMaxMoney() {
      let currentSetList = this.state.currentSetList;
      if (currentSetList && currentSetList.length) {
          let useCarMaxMoneySet = currentSetList.filter(a => a.paraCode === '6007');
          if (useCarMaxMoneySet && useCarMaxMoneySet.length) {
              return useCarMaxMoneySet[0].paraValue
          }
      }
      return 0;
    }

    // 用车最大次数
    get useCarMaxTimes() {
      let currentSetList = this.state.currentSetList;
      if (currentSetList && currentSetList.length) {
          let useCarMaxTimeSet = currentSetList.filter(a => a.paraCode === '6006');
          if (useCarMaxTimeSet && useCarMaxTimeSet.length) {
              return useCarMaxTimeSet[0].paraValue
          }
      }
      return 0;
    }

    mainStore = this.props.mainStore;
    allowApply = true;

    render() {
        return <div className={style.container}>
            <div className={style.head}>
                <div>
                    <div>{this.t('perApply.k003')}</div>
                    <div>
                        <div>
                            {
                                (() => {
                                    let str = this.t('perApply.k004_1')
                                    if (this.beginDistance) {
                                        str += this.t('perApply.k004_2', {beginDistance: this.beginDistance})
                                    } else {
                                        str += this.t('perApply.k004_3')
                                    }
                                    if (this.endDistance) {
                                        str += this.t('perApply.k004_4', {endDistance: this.endDistance})
                                    } else {
                                        str += this.t('perApply.k004_5')
                                    }
                                    return str;
                                })()
                            }
                        </div>
                        <div>{this.t('perApply.k005', {useCarTimeSpan: this.useCarTimeSpan})}</div>
                        {/* TODO 金额和次数 */}
                        {/* k040 3.单次申请单用车金额上限为不限。 */}
                        {/* k041 4.单次申请单使用次数上限为不限。 */}
                        <div>{this.useCarMaxMoney ? this.t('perApply.k040_1', {useCarMaxMoney: this.useCarMaxMoney}) : this.t('perApply.k040')}</div>
                        <div>{this.useCarMaxTimes ? this.t('perApply.k041_1', {useCarMaxTimes: this.useCarMaxTimes}) : this.t('perApply.k041')}</div>
                        <div>{this.t('perApply.k006')}</div>
                    </div>
                </div>

                <div onClick={() => {
                    this.props.history.push('/perApplyRecord')
                }}>
                    {this.t('perApply.k007')} <RightOutline />
                </div>
            </div>

            <div className={style.body}>
                <div>
                    <List style={{'--prefix-width': '120px', '--padding-left': '0'}}>
                        <List.Item prefix={this.t('perApply.k008')} extra={<div className={style.ellipsis} onClick={() => {
                            this.setState({
                                showScene: true
                            })
                        }}>
                        {
                            this.state.apply.scene.id  
                                ? <div className={style.text}>{this.state.apply.scene.nameCn}</div>
                                : <div>{this.t('perApply.k009')}</div>
                        }
                        </div>}>
                        </List.Item>
                        {
                            this.state.beginAddrModel === 3
                                ? <List.Item prefix={this.t('perApply.k010')} extra={<div className={style.ellipsis}>
                                        <Selector options={this.addrModels} value={[this.state.df.beginAddrModel]} onChange={(v) => {
                                            if (v.length < 1) {
                                                return;
                                            }
                                            this.setState({
                                                df: {
                                                    ...this.state.df,
                                                    beginAddrModel: v[0]
                                                }
                                            }, () => {})
                                        }} />
                                    </div>}>                    
                                </List.Item>
                                : <></>
                        }
                        {
                            this.state.df.beginAddrModel === 1
                                ? <List.Item prefix={this.t('perApply.k011')} extra={<div className={style.ellipsis} onClick={this.onBeginAddrClick}>
                                    {
                                        this.state.apply.beginAddr.name
                                            ? <div className={style.text}>{this.state.apply.beginAddr.name}</div>
                                            : <div>{this.t('perApply.k012')}</div>
                                    }
                                </div>}>
                                </List.Item>
                                : <></>
                        }
                        {
                            this.state.df.beginAddrModel === 2
                                ? <List.Item prefix={this.t('perApply.k013')} extra={<div className={style.ellipsis} onClick={this.onBeginAddrClick}>
                                    {
                                        this.state.apply.beginAddr.cityName
                                            ? <div className={style.text}>{this.state.apply.beginAddr.cityName}</div>
                                            : <div>{this.t('perApply.k014')}</div>
                                    }
                                </div>}>
                                </List.Item>
                                : <></>
                        }
                        {
                            this.state.endAddrModel === 3
                                ? <List.Item prefix={this.t('perApply.k015')} extra={<div className={style.ellipsis}>
                                        <Selector options={this.addrModels} value={[this.state.df.endAddrModel]} onChange={(v) => {
                                            if (v.length < 1) {
                                                return;
                                            }
                                            this.setState({
                                                df: {
                                                    ...this.state.df,
                                                    endAddrModel: v[0]
                                                }
                                            }, () => {console.log(this.state.df)})
                                        }} />
                                    </div>}>                    
                                </List.Item>
                                : <></>
                        }
                        {
                            this.state.df.endAddrModel === 1
                                ? <List.Item prefix={this.t('perApply.k016')} extra={<div className={style.ellipsis} onClick={this.onEndAddrClick}>
                                    {
                                        this.state.apply.endAddr.name  
                                            ? <div className={style.text}>{this.state.apply.endAddr.name}</div>
                                            : <div>{this.t('perApply.k017')}</div>
                                    }
                                </div>}>                    
                                </List.Item>
                                : <></>
                        }
                        {
                            this.state.df.endAddrModel === 2
                                ? <List.Item prefix={this.t('perApply.k018')} extra={<div className={style.ellipsis} onClick={this.onEndAddrClick}>
                                    {
                                        this.state.apply.endAddr.cityName  
                                            ? <div className={style.text}>{this.state.apply.endAddr.cityName}</div>
                                            : <div>{this.t('perApply.k019')}</div>
                                    }
                                </div>}>                    
                                </List.Item>
                                : <></>
                        }
                        {
                            this.state.df.useCarTime
                                ? <>
                                    <List.Item prefix={this.t('perApply.k020')} extra={<div className={style.ellipsis} onClick={() => {
                                        let min = moment(new Date());
                                        let max = moment(min).add(3, 'y');
                                        let value = this.state.apply.beginDate ?  this.state.apply.beginDate : undefined;
                                        
                                        if (value) {
                                            value = moment(value)._d.getTime() > min._d.getTime() ? value : min._d
                                        }
                                        min = min._d;
                                        max = max._d;
                                        DatePicker.prompt({
                                            title: this.t('perApply.k021'),
                                            precision: 'minute',
                                            value: value,
                                            min: min,
                                            max: max
                                        }).then((value) => {
                                            this.setState({
                                                apply: {
                                                    ...this.state.apply,
                                                    beginDate: value
                                                }
                                                
                                            })
                                            if(moment(this.state.apply.endDate) < moment(this.state.apply.beginDate)) {
                                              this.setState({
                                                apply: {
                                                    ...this.state.apply,
                                                    endDate: ''
                                                }
                                                
                                            })
                                            }
                                        })
                                    }}>
                                        {
                                            this.state.apply.beginDate  
                                                ? <div className={style.text}>{moment(this.state.apply.beginDate).format('YYYY/MM/DD HH:mm')}</div>
                                                : <div>{this.t('perApply.k022')}</div>
                                        }
                                    </div>}>                    
                                    </List.Item>
                                    <List.Item prefix={this.t('perApply.k023')} extra={<div className={style.ellipsis} onClick={() => {
                                        if (!this.state.apply.beginDate) {
                                            Dialog.confirm({
                                                content: this.t('perApply.k024'),
                                                cancelText: this.t('common.k001'),
                                                confirmText: this.t('common.k002')
                                            })
                                            return;
                                        }
                                        let min = moment(this.state.apply.beginDate);
                                        let max = moment(min).add(this.useCarTimeSpan, 'd');
                                        max = max.add(-1, 'm');
                                        min = min._d;
                                        max = max._d;
                                        DatePicker.prompt({
                                            title: this.t('perApply.k021'),
                                            precision: 'minute',
                                            value: this.state.apply.endDate ?  this.state.apply.endDate : undefined,
                                            min: min,
                                            max: max
                                        }).then((value) => {
                                            this.setState({
                                                apply: {
                                                    ...this.state.apply,
                                                    endDate: value
                                                }
                                                
                                            })
                                        })
                                    }}>
                                        {
                                            this.state.apply.endDate  
                                                ? <div className={style.text}>{moment(this.state.apply.endDate).format('YYYY/MM/DD HH:mm')}</div>
                                                : <div>{this.t('perApply.k025')}</div>
                                        }
                                    </div>}>                    
                                    </List.Item>  
                                </>
                                :<></>
                        }
                        
                        {
                            // 用车次数
                            this.state.df.useTimes
                                ? <List.Item prefix={this.t('perApply.k026')} extra={<div className={style.ellipsis}>
                                        <Input placeholder={this.t('perApply.k027')} style={{ '--text-align': 'right', '--placeholder-color': '#999999', '--font-size': '15px' }} clearable type="number" min={1} max={500} 
                                            value={this.state.apply.usageCount} onChange={(v) => {
                                                this.setState({
                                                    apply: {
                                                        ...this.state.apply,
                                                        usageCount: v
                                                    }
                                                })
                                            }} />
                                    </div>}>                    
                                </List.Item>
                                :<></>
                        }

                        {
                            // 用车金额
                            this.state.df.useMoney
                                ? <List.Item prefix={this.t('perApply.k028')} extra={<div className={style.ellipsis}>
                                        <Input placeholder={this.t('perApply.k029')} style={{ '--text-align': 'right', '--placeholder-color': '#999999', '--font-size': '15px' }} clearable type="number" min={1} max={5000} 
                                            value={this.state.apply.usageMoney} onChange={(v) => {
                                                this.setState({
                                                    apply: {
                                                        ...this.state.apply,
                                                        usageMoney: v
                                                    }
                                                })
                                            }} />
                                    </div>}>                    
                                </List.Item>
                                :<></>
                        }                  
                    </List>
                </div>
                <div>
                    <div>{this.t('perApply.k030')}</div>
                    <div>
                        <TextArea placeholder={this.t('perApply.k031')} maxLength={200} rows={3} value={this.state.apply.reason} onChange={(val) => this.setState({
                            apply: {
                                ...this.state.apply,
                                reason: val
                            }
                        })} />
                    </div>
                </div>
            </div>

            <div className={style.foot}>
                <Button block color='primary' size='middle' onClick={this.apply}>{this.t('perApply.k032')}</Button>
                <Button block size='middle' onClick={this.backPage}>{this.t('perApply.k033')}</Button>
            </div>

            <Popup
                position='bottom'
                visible={this.state.show}
                bodyStyle={{ height: '100%' }}
                >
                {/* 地址选择框 */}
                {this.state.show ? <Address t={this.t} onChange={this.choosedAddr}
                    isCommonlyAddress={true}
                    onCancel={() => {
                        this.props.history.goBack();
                    }}
                    isBegin={this.state.addr.isBegin} cityCode={this.currentCity.cityCode} cityName={this.currentCity.cityName}
                    onlyCity={this.addressIsOnlyCity} history={this.props.history}
                ></Address>:''}
            </Popup>
            <Popup
                position='bottom'
                visible={this.state.showScene}
                onMaskClick={() => {
                    this.setState({
                        showScene: false
                    })
                }}
                >
                    <div className={style.searchBarContainer}>
                        <SearchBar value={this.state.sceneKey} 
                            onChange={v => {
                                this.setState({
                                    sceneKey: v
                                })
                            }}
                        />
                    </div>
                    <div className={style.checkListContainer}>
                        {
                            this.listScene.map(item => (
                                <div className={style.myCheckList} key={item.id} onClick={() => {
                                    let value = [item.id];
                                    let scene = this.state.listScene.filter(a => a.id === value[0]).map(a => {
                                        return {
                                            id: a.id,
                                            publishId: a.publishId,
                                            nameCn: a.nameCn,
                                            nameEn: a.nameEn
                                        }
                                    })[0];
                                    this.setState({
                                        apply: {
                                            ...this.state.apply,
                                            scene: scene
                                        },
                                        showScene: false
                                    }, () => {
                                        this.loadSetListByScene()
                                    })
                                    

                                }}>
                                    <div>{item.nameCn}</div>
                                    {
                                        item.id === this.state.apply.scene.id
                                            ? <div><CheckOutline fontSize={17} color='#247fff' /></div>
                                            : <></>
                                    }
                                    
                                </div>
                            ))
                        }
                    </div>
            </Popup>
        </div>
    }

    async componentDidMount() {
        this.id = this.props.match.params.id;
        if (this.id) {
            this.loadApply();
        }

        let {data} = await webService.scene.getSceneList({});
        
        if (data.code === 0) {
            // 兼容残缺的后端返回json格式
            if (!data.data.rows && data.data && data.data.length) {
                data.data = {
                    rows: data.data
                }
            }

            let listScene = data.data.rows?data.data.rows.filter(a => a.needPreApproval):[]
            if (listScene.length && !this.id) {
                // 新建流程，默认选中第一个用车场景
                
                this.setState({
                    listScene: listScene,
                    apply: {
                        ...this.state.apply,
                        // 2023-01-05 关闭默认用车场景功能
                        // scene: {
                        //     id: listScene[0].id,
                        //     publishId: listScene[0].publishId,
                        //     nameCn: listScene[0].nameCn,
                        //     nameEn: listScene[0].nameEn,
                        //     needPreApproval: listScene[0].needPreApproval
                        // }
                    }
                    
                }, () => {
                    this.loadSetListByScene();
                })
            } else {
                this.setState({
                    listScene: listScene
                })
            }

            
        }

        if (!this.id) {
            try {
                let res = await getCurrentPosition();
                if (res.cityCode && res.cityName) {
                    this.setState({
                        addr: {
                            ...this.state.addr,
                            cityCode: res.cityCode,
                            cityName: res.cityName
                        },
                        apply: {
                            ...this.state.apply,
                            beginAddr: {
                                cityName: res.cityName,
                                cityCode: res.cityCode,
                                address: res.address,
                                name: res.name,
                                lat: res.lat,
                                lon: res.lon
                            }

                        }
                    })
                    this.mainStore.setCurrentAddr(res)
                }
            } catch (e) {
                console.log(e)
            }
        }

        
    }

    componentWillUnmount() {
      Dialog.clear()
    }

    /**
     * 载入旧数据
     */
    loadApply = async () => {
        let {data} = await webService.preApproval.queryPreDepartApplyList({preDepartApplyId: this.id});
        if (data.code === 0 && data.data.rows && data.data.rows.length) {
            let record = data.data.rows.filter(a => a.preDepartApplyId === this.id)[0]
            if (record) {
                let apply = this.state.apply;
                apply.beginAddr.cityCode = record.departCityCode;
                apply.beginAddr.cityName = record.departCityName;
                apply.beginAddr.name = record.pickupLocationName;
                apply.endAddr.cityCode = record.destCityCode;
                apply.endAddr.cityName = record.destCityName;
                apply.endAddr.name = record.destLocationName;
                apply.beginDate = record.beginTime ? moment(record.beginTime)._d : '';
                apply.endDate = record.endTime ? moment(record.endTime)._d : '';
                apply.scene.id = record.sceneId;
                apply.scene.publishId = record.scenePublishId;
                apply.scene.nameCn = record.sceneName;
                apply.reason = record.applyReason;
                this.setState({
                    apply: {
                        ...apply
                    }
                }, () => {this.loadSetListByScene();})
            }
        }
    }

    onBeginAddrClick = () => {
        this.setState({
            show: true,
            addr: {
                ...this.state.addr,
                isBegin: true
            }
        }, () => {
            let fr = new ForgeRouter(() => {
                let {hash} = document.location;
                if (hash.indexOf('perApply') !== -1) {
                    this.setState({
                        show: false,
                    })
                    
                } 
            });
            fr.onpopstate();
        })
    }

    onEndAddrClick = () => {
        this.setState({
            show: true,
            addr: {
                ...this.state.addr,
                isBegin: false
            }
        }, () => {
            let fr = new ForgeRouter(() => {
                let {hash} = document.location;
                if (hash.indexOf('perApply') !== -1) {
                    this.setState({
                        show: false,
                    })
                    
                } 
            });
            fr.onpopstate();
        })
    }

    choosedAddr = (item) => {
        this.props.history.goBack();
        let {apply} = this.state;
        if (item.isBegin) {
            this.setState({
                apply: {
                    ...apply,
                    beginAddr: {
                        cityName: item.cityName,
                        cityCode: item.cityCode,
                        address: item.address,
                        name: item.name,
                        lat: item.lat,
                        lon: item.lon
                    }
                }
            })
        } else {
            this.setState({
                apply: {
                    ...apply,
                    endAddr: {
                        cityName: item.cityName,
                        cityCode: item.cityCode,
                        address: item.address,
                        name: item.name,
                        lat: item.lat,
                        lon: item.lon
                    }
                }
            })
        }
    }

    /**
     * 加载场景限制
     */
    loadSetListByScene = async () => {
        let {data} = await webService.preApproval.queryPreApprovalSetList({scenePublishId: this.state.apply.scene.publishId})
        if (data.code === 0) {
            if (!data.data) {
                data.data = []
            }
            this.setDynamicForm(data.data)
        }
    }

    /** 
     * 动态表单码表：
     * 6001： 起点地址
     * 6002： 终点地址
     * 6003： 用车时段
     * 6004： 起点城市
     * 6005： 终点城市
     * 6006： 使用次数
     * 6007： 单次金额
     */
     setDynamicForm(list) {
        this.setState({
            currentSetList: list
        })
        let setList = [...list.map(a => a.paraCode)];
        let beginAddrModel = (setList.indexOf('6001') !== -1 ? 1 : 0) + (setList.indexOf('6004') !== -1 ? 2 : 0);
        let endAddrModel = (setList.indexOf('6002') !== -1 ? 1 : 0) + (setList.indexOf('6005') !== -1 ? 2 : 0);
        let df = {
            beginAddrModel: beginAddrModel === 3 ? 1 : beginAddrModel,
            endAddrModel: endAddrModel === 3 ? 1 : endAddrModel,
            useCarTime: setList.indexOf('6003') !== -1,
            // useTimes: setList.indexOf('6006') !== -1,
            // useMoney: setList.indexOf('6007') !== -1
        };

        this.setState({
            setList: setList,
            beginAddrModel: beginAddrModel,
            endAddrModel: endAddrModel,
            df: df
        })
    }

    /**
     * 提交申请
     */
    apply = async () => {
        if (!this.allowApply) {
            return;
        }
        let {apply, df} = this.state;
        let verification = true;

        if (!apply.scene.id) {
            Dialog.alert({
              content: this.t('perApply.k039'),
              confirmText: this.t('common.k003')
          })
          return
        }
        
        if (df.beginAddrModel === 1) {
            if (!apply.beginAddr.name) {
                verification = false;
            }
        }
        if (df.beginAddrModel === 2) {
            if (!apply.beginAddr.cityName) {
                verification = false;
            }
        }
        if (df.endAddrModel === 1) {
            if (!apply.endAddr.name) {
                verification = false;
            }
        }
        if (df.endAddrModel === 2) {
            if (!apply.endAddr.cityName) {
                verification = false;
            }
        }
        if (df.useCarTime) {
            if (!apply.beginDate || !apply.endDate) {
                verification = false;
            }
        }
        if (df.useTimes) {
            if (!apply.usageCount) {
                verification = false;
            }
        }
        if (df.useTimes) {
            if (!apply.usageMoney) {
                verification = false;
            }
        }
        if (!apply.reason) {
            // verification = false;
            Dialog.alert({
              content: this.t('perApply.k038'),
              confirmText: this.t('common.k003')
          })
          return
        }

        if (verification) {
            let params = {
                preDepartApplyId: this.id,
                departLng: apply.beginAddr.lon,
                departLat: apply.beginAddr.lat,
                pickupLocationName: apply.beginAddr.name,
                departCityCode: apply.beginAddr.cityCode,
                departCityName: apply.beginAddr.cityName,
                destLng: apply.endAddr.lon,
                destLat: apply.endAddr.lat,
                destLocationName: apply.endAddr.name,
                destCityCode: apply.endAddr.cityCode,
                destCityName: apply.endAddr.cityName,
                beginTime: apply.beginDate ? moment(apply.beginDate).format('YYYY-MM-DD HH:mm') : null,
                endTime: apply.endDate ? moment(apply.endDate).format('YYYY-MM-DD HH:mm') : null,
                scenePublishId: apply.scene.publishId,
                applyReason: apply.reason,
                usageCount: apply.usageCount,
                useMoney: apply.useMoney
            }

            // 如果不需要设置起点，应删除起点参数
            if (df.beginAddrModel === 0) {
                // 无起点和起点城市要求，需要过滤掉起点五要素
                params.departLng = undefined;
                params.departLat = undefined;
                params.pickupLocationName = undefined;
                params.departCityCode = undefined;
                params.departCityName = undefined;
            }
            if (df.beginAddrModel === 2) {
                // 只有起点城市而无起点要求，需要过滤掉起点坐标和名称
                params.departLng = undefined;
                params.departLat = undefined;
                params.pickupLocationName = undefined;
            }
            
            

            this.allowApply = false;
            let {data} = await webService.preApproval.preDepartApply(params);
            if (data.code === 0) {
                Toast.show({
                    icon: 'success',
                    content: this.t('perApply.k034')
                })
                setTimeout(() => {
                    this.props.history.push('/perApplyRecord')
                }, 500);
            } else {
                Dialog.alert({
                    content: data.message || this.t('k035'),
                    confirmText: this.t('common.k003')
                })
                this.allowApply = true;
            }
        } else {
            Dialog.alert({
                content: this.t('perApply.k036'),
                confirmText: this.t('common.k003')
            })
            
        }
        
    }

    /**
     * 返回
     */
    backPage = async () => {
        let res = await Dialog.confirm({
            content: this.t('perApply.k037'),
            cancelText: this.t('common.k001'),
            confirmText: this.t('common.k002')
        })
        if (res) {
            this.props.history.goBack();
        }
    }

}


export default withTranslation()(PerApply);