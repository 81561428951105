
// 网关域名
let gatewayDomain = process.env.REACT_APP_gatewayDomain;
//websocket
let gatewaycore = process.env.REACT_APP_gatewaycore;

// 运行环境
let envName = process.env.REACT_APP_env_name;

// ==================以下几乎不变==================

const qqmapBaseUrl = 'https://map.imtpath.net';
const amapBaseUrl = 'https://restapi.amap.com/v3';
// const qqKey = 'OPCBZ-BPEC6-LHVSS-EXTQO-LNHQF-SUBED';
// const amapKey = 'bc7ceeb432a8108be14251ab3032a943';
// const amapUIKey = 'efac72d66ce052bd2357dc297b193f42';
const qqKey = process.env.REACT_APP_qqKey;
const amapKey = process.env.REACT_APP_amapKey;
const amapUIKey = process.env.REACT_APP_amapUiKey;

const versionNumber = '2.1.1'

export {
    qqmapBaseUrl,
    amapBaseUrl,
    qqKey,
    amapKey,
    amapUIKey,
    gatewayDomain,
    gatewaycore,
    envName,
    versionNumber
}