import React from 'react'
import style from './trip.scss'

import { List } from 'antd-mobile'
import webService from '../util/webService'

import { withTranslation } from "react-i18next";

class Trip extends React.Component {

  state = {
    // 行程列表数据
    tripData: []
  }

  t = this.props.t

  /**
   *
   *行程列表
   */
  renderTripCell = () => {
    return(
      <div>
        {
          this.state.tripData.map((item, index) => {
            return(
              <List key={index}>
                <List.Item className={style.cell}>
                  <div className={style.time}>
                    {item.carChannels}&nbsp;&nbsp;
                    {item.createTime}
                  </div>
                  <div className={style.box}>
                    <div>
                      <p>{this.t('trip.k001')}{item.pickupLocation}</p>
                      <p>{this.t('trip.k002')}{item.destLocation}</p>
                    </div>
                    <div>
                      {item.amount}<span>{this.t('trip.k003')}</span>
                    </div>
                  </div>
                </List.Item>
              </List>
            )
          })
        }
      </div>
    )
  }

  render() {
    return(
      <div className={style.trip}>
        {/* 行程列表 */}
        {this.renderTripCell()}
      </div>
    )
  }

  componentDidMount() {
    const { id } = this.props.match.params
    webService.invoice.getInvoiceTripList({invoiceId: String(id)}).then(d => {
      if (d.data.code === 0) {
        this.setState({
          tripData: d.data.data.list
        })
      }
    })
  }
}


export default withTranslation()(Trip);
