import React from 'react';

import { inject, observer } from 'mobx-react'

import { withTranslation } from 'react-i18next';

import { TextArea, Button, Toast } from 'antd-mobile';

import images from '../util/images';

import style from './orderAbnormal.scss';

import webService from './../util/webService';

@inject('serviceCenterStore')
@observer
class OrderAbnormal extends React.Component {
  state = {
    reason: [], // 异常信息
    showComplaintBtn: null, // 是否显示投诉中心按钮
    comment: '',
    abnormalTipFlag: false,
    tip: ''
  };

  serviceCenterStore = this.props.serviceCenterStore

  t = this.props.t;

  render() {
    return (
      <div className={style.container}>
        <div className={style.box}>
          <div className={style.msg}>
            <img src={images.bellWhite} alt="" />
            {/* 本次行程需确认异常情况，请在下方填写说明并提交。 */}
            <span>{this.t('orderAbnormal.k001')}</span>
          </div>

          <div className={style.standard}>
            {/* 本次行程超出以下标准 */}
            <div>{this.t('orderAbnormal.k002')}</div>
            {this.state.reason.map((item, index) => {
              return (
                <p key={index}>
                  {index + 1}、{item}
                </p>
              );
            })}
            {
              this.state.abnormalTipFlag ? <div className={style.tip_message}>{this.state.tip}</div> : <></>
            }
          </div>

          <div className={style.checked}>
            <div className={style.checked_state}>
              <div>
                <img src={images.abnormalChecked} alt="" />
              </div>
              {/* 正常 */}
              <span>{this.t('orderAbnormal.k003')}</span>
            </div>
            {/* 请填写产生异常的原因， 方便审批人了解情况。（200字内） */}
            <TextArea
              onChange={(val) => {
                this.setState({
                  comment: val,
                });
              }}
              value={this.state.comment}
              placeholder={this.t('orderAbnormal.k004')}
              disabled={false}
              maxLength={200}
              rows={3}
            />
          </div>
          {this.state.showComplaintBtn ? (
            <div className={style.doubt}>
              {/* 对行程异常有疑问？ */}
              <span>{this.t('orderAbnormal.k005')}</span>
              <span
                onClick={() => {
                  const { id } = this.props.match.params;
                  this.serviceCenterStore.setConfirmAbnormal(true)
                  this.props.history.push(`/serviceCenter/${id}`);
                  // 点击投诉
                }}
              >
                {this.t('orderAbnormal.k006')}
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className={style.submit}>
          {/* 提交 */}
          <Button
            onClick={() => {
              this.setConfirmAbnormal();
            }}
            block
            color="primary"
            shape="rectangular"
          >
            {this.t('orderAbnormal.k007')}
          </Button>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.loadOrderAbnormal();
  }

  loadOrderAbnormal = async () => {
    const { id } = this.props.match.params;
    let { data } = await webService.order.getOrderAbnormal({ orderId: id });
    if (data.code === 0) {
      this.setState({
        reason: data.data.reason,
        showComplaintBtn: data.data.showComplaintBtn,
        abnormalTipFlag: data.data.abnormalTipFlag,
        tip: data.data.tip
      });
    }
  };

  setConfirmAbnormal = async () => {
    const { id } = this.props.match.params;
    let { data } = await webService.order.setConfirmAbnormal({
      orderId: id,
      comment: this.state.comment,
    });
    if (data.code === 0) {
      Toast.show({
        content: this.t('orderAbnormal.k008'),
      });
      setTimeout(() => {
        this.props.history.goBack();
      }, 1000);
    }
  };
}

export default withTranslation()(OrderAbnormal);
