import React from 'react';
import style from './agreement.scss';

class Agreement extends React.Component {

    render() {
        return <div className={style.body}>
    <h2>软件使用协议</h2>
    <h3>1、定义</h3>
    <p>
    1.1 【企业出行】平台：以下简称“【企业出行】”，是针对企业客户提供出行服务，企业客户方的用车人员可通过【企业出行】平台自主发送出行需求订单，并在订单完成后实现快捷支付的企业用车服务平台。
    </p>
    <p>
    1.2订单：【企业出行】平台上开通企业账户的用户方，在该平台上实际发起并完成的有效订单。
    </p>
    <p>
    1.3用户：使用【企业出行】的企业客户方的员工或其他相关人员，通过该平台自主发送出行需求订单，具有完全民事权利能力和行为能力的自然人或其他符合本条款规定的实际乘车人。
    </p>
    <h3>2、法律声明</h3>
    <p>
    本《软件使用协议》适用于【企业出行】平台。您在点击同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体标识， 您应重点阅读。如您对协议有任何疑问，可向【企业出行】平台客户咨询（咨询电话：）。当您点击同意本协议后，即表示您已充分阅读、理解本协议条款，并接受本协议的全部内容，且成为【企业出行】平台的“企业用户”，本协议对贵司及平台均具有法律约束力。
    【企业出行】平台有权在必要时修改本协议条款，并以提前公示、推送通知或弹窗等方式通知到您。您可以随时在本平台查阅修改后的最新版本协议。如您不同意修改后的协议，您有权停止使用本平台服务。 本协议更新后，如果您继续使用本平台服务，即视为您已接受修改后的协议。
    </p>
    <h3>3、账号使用</h3>
    <p>
    用户在使用过程中需支付因个人行为产生的费用，如果出现拖欠，【企业出行】平台有权对账号进行封禁，并追究可能产生的法律责任。
    </p>
    <h3>4、权利说明</h3>
    <p>
    【企业出行】对其发行的或与合作公司共同发行的包括但不限于提供的软件及相关产品或服务的全部内容，享有知识产权，受法律保护。如果相关内容未含权利声明，并不代表【企业出行】平台对其不享有权利和不主张权利，您应根据法律、法规及诚信原则尊重权利人的合法权益并合法使用该内容。
    未经【企业出行】平台书面许可，任何单位及个人不得以任何方式或理由对上述软件、产品、服务、信息、文字的任何部分进行使用、复制、修改、抄录、传播或与其它产品捆绑使用、销售，或以超级链路连接或传送、存储于信息检索系统或者其他任何商业目的的使用，但对于非商业目的的，个人使用的下载或打印（未作修改，且须保留该内容中的版权说明或其他所有权的说明）除外。
    上述软件中使用和显示的商标和标识（以下统称“商标”）是【企业出行】平台及其关联公司在出行服务及其他相关领域内注册和未注册的有关商标，受法律保护。该等软件中所载的任何内容，未经【企业出行】平台书面许可，任何人不得以任何方式使用【企业出行】及近似名称和相关商标、标识。
    </p>
    <h3>5、责任限制声明</h3>
    <p>
    5.1【企业出行】平台可能为不同的终端设备开发了不同的应用程序版本，用户应根据实际情况选择下载适合的版本进行安装。平台不保证所有的版本与相关设备的适配性和可用性，亦不保证所有的版本提供的服务的一致性。
    </p>
    <p>
    5.2为了完善平台服务内容，【企业出行】平台将不断努力开发新的服务，并为用户提供应用程序的更新。应用程序新版本发布后，旧版本的应用程序可能无法使用，平台不保证旧版本应用程序可继续使用及相应服务，因此请用户随时核对并下载最新版本的应用程序。
    </p>
    <p>
    5.3请用户理解并同意：在使用本平台服务的过程中，可能会遇到不可抗力、技术风险等因素，对于下述原因导致的合同履行障碍、履行瑕疵、履行延后或履行内容变更、数据丢失等情形，平台在法律允许的最大范围内免责：
    </p>
    <p>
    A、因自然灾害、罢工、劳动争议、暴乱、骚乱、爆炸、战争、政府行为、司法行政命令或其他不可抗力因素；
    </p>
    <p>
    B、因电力供应故障、通讯网络故障（包括但不限于电子通讯传达失败或延时、用于电子通讯的计算机程序对电子通讯的拦截或操作）等公共服务因素或用户自身因素（包括但不限于用户操作不当、通过非平台授权的方式使用本服务）或第三人因素（包括但不限于受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏、顾客的错误下单等错误操作）；
    </p>
    <p>
    C、在平台已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、西缺陷、网络信息与数据安全、技术风险等因素；
    </p>
    <p>
    D、其他平台无法控制或合理预见的情形。
    </p>
    <p>
    5.4如果平台发现了因系统故障或其他原因导致的处理错误，无论有利于平台还是有利于用户，平台都有权在根据本协议规定通知用户后纠正该错误、回转/回档相关交易或数据。用户理解并同意，因前述处理错误而多付或少付的款项均不计利息，平台不承担因前述处理错误而导致的任何损失或责任（包括用户可能因前述错误导致的利息、汇率等损失），但因平台恶意而导致的处理错误除外。
    </p>
    <p>
    5.5平台仅对因平台原因给您造成的直接、实际损失依法承担相应的赔偿责任，不对任何间接损失、惩罚性赔偿承担责任。
    </p>
    <p>
    5.6用户在实际使用【企业出行】的服务时，应当具备中华人民共和国法律规定的完全民事行为能力。若您不具备完全民事行为能力，请您务必在家长和其他法定监护人的同意及全程陪同下使用打车服务。否则您及您的监护人应依照法律规定承担因此而导致的一切后果。
    </p>
    <p>
    5.7用户同意【企业出行】拥有通过消息推送、平台公示、短信、电话、邮件等形式向您通知告知信息的权利。此外，用户允许【企业出行】平台发送商业性电子信息或广告促销信息。
    </p>
    <h3>6、 授权及许可</h3>
    <p>
    6.1在用户遵守本协议的前提下，平台授予用户有限的、非排他性的、不可转让的如下许可：以将一份平台应用程序副本下载并安装到用户拥有或控制的但的单台移动设备上，并仅出于用户自身用途运行此应用程序副本。用户不得：
    </p>
    <p>
    （1）以任何方式许可、再许可、出售、转售、转让、分配、分发服务或应用程序，或以其他方式进行商业开发或提供给任何第三方；
    </p>
    <p>
    （2）修改平台服务功能或应用程序，或据此创建衍生产品；
    </p>
    <p>
    （3）创建指向平台服务的互联网“链接”，或在任何其他服务器或基于无线或互联网的设备上“设计”或“镜像”任何应用程序；
    </p>
    <p>
    （4）反向工程或访问应用程序设计或构建竞争产品或服务、使用类似于平台服务或应用程序的设想或图形来设计或构建产品，或抄袭平台服务或应用程序的任何设想、特点、功能或图形；
    </p>
    <p>
    （5）启动自动程序或脚本，每秒发送多个服务器请求或过度加重平台服务或应用程序负担或妨碍其工作和/或性能的程序；
    </p>
    <p>
    6.2在法律允许的最大范围内，平台将有权调查并起诉任何上述违法违规行为。本平台可参与并协助执法部门起诉违反本协议的用户。如果本平台认为任何内容违反本协议或以其他方式危害网站、平台及/或其中的服务或应用程序，本平台保留在不另行通知的情况下随时删除或禁用对这些内容的访问权限的权利。
    </p>
    <h3>
    7、知识产权
    </h3>
    <p>
    7.1【企业出行】平台所展示的各运营系统由【企业出行】自主开发、运营及提供技术支持，并对【企业出行】服务的开发和运营等过程中产生的所有数据和信息等享有全部权利。【企业出行】提供各项服务时所依托软件的著作权、专利权，所使用的各项商标、商业形象、商业标识、技术诀窍，其著作权、商标权及其他各项相关权利均归【企业出行】平台所有。
    </p>
    <p>
    7.2用户理解并同意：用户在使用【企业出行】过程中所产生、上传的内容等所有信息的知识产权及所有权，归【企业出行】平台所有。
    </p>
    <h3>
    8、知识产权
    </h3>
    <p>
    【企业出行】平台上可能保留有第三方网站（或应用程序）或网址的链接及第三方服务，是否访问这些链接或接受相应服务将由用户自己做出决定，平台并不就这些链接所提供的任何信息、数据、观点、图片、陈述或建议的准确性、完整性、充分性和可靠性提供承诺或保证。如果用户决定访问任何与本平台链接的第三方网站（或应用程序），其可能带来的结果和风险全部由用户自己承担。
    </p>
    <h3>9、适用法律和管辖权</h3>
    <p>
    通过访问【企业出行】平台提供的或其合作服务提供商提供的服务，即表示用户同意该访问或服务受中华人民共和国法律的约束，且用户同意受中华人民共和国法院的管辖。访问或接受服务过程中发生的争议应当协商解决，协商不成的，应向协议签署地有管辖权的人民法院提起诉讼，本协议签订地为中华人民共和国上海市宝山区。
    </p>
    </div>
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }
}

export default Agreement;
