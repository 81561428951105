import React from 'react';
import style from './aMap.scss';

import { amapUIKey } from './../../util/variable'
// eslint-disable-next-line
import { clientMapZoom, getPointsArea, yawThreshold } from './../../util/utils'
import images from './../../util/images'
import direction from './../../util/direction';
import { getRemainingSegment, totalDistance } from './../../util/pointOnSegment'

//import webService from './../../util/webService'

let AMap = undefined;

const AMAP_ZOOM_DEFAULT = 17.4;

class GdMap extends React.Component {
    state = {

    }

    loadTask = undefined;
    map = undefined;

    t = this.props.t;

    render() {
        const { children } = this.props
        return <div className={style.container}>
            <div id="amap_container" className={style.amap}>{children}</div>
        </div>
    }

    async componentDidMount() {
        this.loadTask = this.GdMap();
        AMap = await this.loadTask;    
    }

    componentWillUnmount() {
        if (this.map) {
            this.map.destroy()
        }
        this.clearWatchPosition()
    }

    GdMap = () => {
        return new Promise((resolve, reject) => {
            if (AMap || window.AMap) {
                resolve(AMap || window.AMap);
                return
            }


            let script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://webapi.amap.com/maps?v=1.4.15&key=' + amapUIKey;
            script.onerror = reject;

            script.onload = () => {
                resolve(window.AMap);
            };
            document.getElementsByTagName('head')[0].appendChild(script);
        });
    }

    // 当前定位点
    current_point = undefined;
    // 推荐上车地点
    rm_points = [];
    // 场站
    fencePolygon = undefined;

    mapStyles = {
        begin_icon: {
            size: [25, 30],
            image: images.beginAddr
        },
        end_icon: {
            size: [25, 30],
            image: images.endAddr
        },
        perEnd_icon: {
            size: [30, 30],
            image: images.perEndIcon
        },
        user_icon: {
            size: [30, 30],
            image: images.userIcon
        },
        driver_icon: {
            size: [40, 40],
            image: images.driverIcon
        },
        car_down: {
            size: [40, 40],
            image: images.carMove
        },


        style_driving: {
            isOutline: true,
            borderWeight: 2,
            outlineColor: '#ffffff',
            strokeWeight: 6,
            strokeColor: '#1bac2e',
            lineJoin: 'round',
            showDir: true
        }
    }

    /**
     * 初始化地图
     */
    initMap = async (geo) => {
        console.log('加载高德地图')
        if (this.loadTask) {
            await this.loadTask;
        }

        let clientMapZooms = clientMapZoom()
        let center = undefined;
        let initParams = {
            zoom: AMAP_ZOOM_DEFAULT,
            zooms: clientMapZooms,
            showControl: false,
            //mapZoomType: _AMap.constants.MAP_ZOOM_TYPE.CENTER,
            viewMode: '2D',
            mapStyle: 'amap://styles/ffe4ad2a36d1b0f5c7048c5ad8d3f2e0',
        }
        if (geo && geo.lat) {
            center = [geo.lon, geo.lat];
            initParams.center = center
        }
        if (this.t('main.k000') === 'en') {
            initParams.lang = 'en'
        }
        // 地图
        this.map = new AMap.Map('amap_container', initParams);

        this.markerLayer = {
            map: this.map,
            _data_list: [],
    
            getAll() {
                return this._data_list.map(a => a.value)
            },

            getOriData() {
                return this._data_list
            },
    
            _add(point) {
                let _old = this._data_list.filter(a => a.id === point.id)
                if (_old.length === 0) {
                    // 新建
                    let market = new AMap.Marker({
                        position: point.position,
                        icon: new AMap.Icon({
                            size: point.styleId.size,
                            image: point.styleId.image,
                            imageSize: point.styleId.size
                        }),
                        anchor: 'center',
                        offset: new AMap.Pixel(0, 0)
                    })
                    if (point.content) {
                        market.setLabel({
                            offset: new AMap.Pixel(0, 15),
                            content: point.content,
                            direction: 'center'
                        })
                    }
                    market.setMap(this.map)
                    
                    market.show()
                    this._data_list.push({
                        id: point.id,
                        content: point.content,
                        value: market
                    })
                } else if (_old.length === 1) {
                    // 修改
                    let market = _old[0].value
                    if (point.position) {
                        market.setPosition(point.position)
                    }                
                    if (point.content) {
                        market.setLabel({
                            offset: new AMap.Pixel(0, 15),
                            content: point.content,
                            direction: 'center'
                        })
                    }
                }
            },
    
            /**
             * 添加标记点
             * @param {*} point {id, styleId, position, content}
             */
            add(points) {
                if (points && points.length) {
                    for (let i in points) {
                        this._add(points[i])
                    }
                }
            },
    
            remove(ids) {
                if (ids && ids.length) {
                    let _del = this._data_list.filter(a => ids.includes(a.id))
                    if (_del && _del.length) {
                        for (let i in _del) {
                            _del[i].value.hide()
                            this.map.remove(_del[i].value)
                        }

                        this._data_list = this._data_list.filter(a => !ids.includes(a.id))
                    }
                }
            },
    
            clear() {
                if (this._data_list && this._data_list.length) {
                    for (let i in this._data_list) {
                        this._data_list[i].value.hide()
                        this.map.remove(this._data_list[i].value)
                    }
                    this._data_list = []
                }
            }
        }
    
        this.polylineLayer = {
            map: this.map,
            _data_list: [],
    
            getAll() {
                return this._data_list.map(a => a.value)
            },
    
            _add(item) {
                let _old = this._data_list.filter(a => a.id === item.id)
                if (_old.length === 0) {
                    // 新建
                    let polyline = new AMap.Polyline({
                        ...item.styleId,
                        path : item.paths
                    })
                    this.map.add(polyline)

                    this._data_list.push({
                        id: item.id,
                        value: polyline
                    })
                } else if (_old.length === 1) {
                    // 更新
                    let old = _old[0].value
                    old.setPath(item.polylinePoints)
                }
            },
    
            /**
             * 
             * @param {*} item {id, styleId, polylinePoints}
             */
            add(items) {
                if (items && items.length) {
                    for (let i in items) {
                        this._add(items[i])
                    }
                }
            },
    
            remove(ids) {
                if (ids && ids.length) {
                    let _del = this._data_list.filter(a => ids.includes(a.id))
                    if (_del && _del.length) {
                        for (let i in _del) {
                            this.map.remove(_del[i].value)
                        }

                        this._data_list = this._data_list.filter(a => !ids.includes(a.id))
                    }
                }
            },
    
            clear() {
                if (this._data_list && this._data_list.length) {
                    for (let i in this._data_list) {
                        this.map.remove(this._data_list[i].value)
                    }

                    this._data_list = []
                }
            }
        }

        // 实时地位
        this.current_point = new AMap.Marker({
            icon: new AMap.Icon({
                size: [36, 36],
                image: images.orientation,
                imageSize: [36, 36]
            }),
            anchor: 'center',
            offset: new AMap.Pixel(0, 0)
        });

        // 推荐上车地点
        for (let i = 0; i < 3; i++) {
            let _rm = new AMap.Marker({
                icon: new AMap.Icon({
                    size: [14, 14],
                    image: images.mr_point,
                    imageSize: [14, 14]
                }),
                anchor: 'center',
                offset: new AMap.Pixel(0, 0)
            })
            _rm.on('click', (d) => {
                if (this.props.onMarkerClick && this.recommendMarkers[i]) {                    
                    this.props.onMarkerClick({
                        id: `rm${i}`,
                        styleId: 'point',
                        position: {
                            lat: this.recommendMarkers[i].location.lat,
                            lng: this.recommendMarkers[i].location.lng,
                            height: 0
                        },
                        content: this.recommendMarkers[i].title,
                        rank: 0
                    })
                    
                }
            })
            this.rm_points.push(_rm)
        }

        // 场站围栏
        this.fencePolygon = new AMap.Polygon({
            path: [],
            fillColor: 'rgba(173, 212, 226, 0.5)',
            borderWeight: 2,
            strokeColor: 'rgba(47, 125, 205, 0.9)',
            strokeStyle: 'dashed'
        })
        this.fencePolygon.hide()
        this.map.add(this.fencePolygon);
    }

    getMap() {
        return this.map;
    }

    // map对象常用方法
    on(eventName, fn) {
        switch(eventName) {
            case 'zoom':
                this.map.on('zoomchange', fn)
                break;
            case 'dblclick':
                this.map.on('dblclick', fn)
                break;
            case 'pan':
                this.map.on('movestart', fn)
                break;
            case 'panend':
                this.map.on('moveend', fn)
                break;
            case 'touchstart':
                this.map.on('touchstart', fn)
                break;
            case 'touchmove':
                this.map.on('touchmove', fn)
                break;
            case 'touchend':
                this.map.on('touchend', fn)
                break;
            default:
                break;
        }
        this.map.on(eventName, fn)
    }

    zoomTo(value) {
        this.map.setZoom(value)
    }

    setZoom(zoom) {
        this.map.setZoom(zoom)
    }

    getZoom() {
        return this.map.getZoom()
    }

    getCenter() {
        return this.map.getCenter()
    }

    setDoubleClickZoom(value) {
        let res = this.map.getStatus()
        res.doubleClickZoom = value
        this.map.setStatus(res)
    }

    panTo(geo) {
        this.map.panTo(new AMap.LngLat(geo[0], geo[1]))
    }

    setDraggable(value) {
        let res = this.map.getStatus()
        res.dragEnable = value
        this.map.setStatus(res) 
    }

    setScrollable(value) {
        let res = this.map.getStatus()
        res.scrollWheel = value
        this.map.setStatus(res)
    }

    fencePoints = [];
    /**
     * 清除围栏
     */
    removeFence() {
        if (this.fencePolygon && this.fencePoints && this.fencePoints.length) {
            this.fencePolygon.hide()
            this.map.setZoom(AMAP_ZOOM_DEFAULT)
        }
        this.fencePoints = [];
    }
    /**
     * 绘制围栏
     * @param {*} path 
     */
    addFence(path) {
        let _path = path.split(';').map(a => {
            let _tmp = a.split(',')
            return {
                lat: Number(_tmp[0]),
                lng: Number(_tmp[1])
            }
        })


        this.removeFence();
        this.removeRecommendMarkers();

        this.fencePoints = _path.map(a => {
            return new AMap.LngLat(a.lng, a.lat)
        })

        this.fencePolygon.setPath(this.fencePoints)
        this.fencePolygon.show()

        let { sw, ne } = getPointsArea(_path)
        this.map.setBounds(new AMap.Bounds(new AMap.LngLat(sw.lng, sw.lat), new AMap.LngLat(ne.lng, ne.lat)))
    }

    /**
     * 判断点是否在围栏内
     * @param {*} point 
     */
    isPointInFence(point) {
        let res = AMap.GeometryUtil.isPointInRing(point, this.fencePoints);
        return res
    }

    /**
     * 清理导航线条
     */
    clearDriving() {
        // try {
        //     this.markerLayer.stopMove()
        // } catch (exp) {
        //     console.error('阻止动画失败', exp)
        // }
        this.markerLayer.clear()
        this.polylineLayer.clear()
    }

    /**
     * 清理包车
     */
    clearDrivingBus() {
        // try {
        //     this.charteredBusMarker.stopMove()
        // } catch (exp) {
        //     console.error('阻止动画失败', exp)
        // }
        this.markerLayer.clear()
    }


    // 推荐上车地点
    recommendMarkers = [];
    currentrecommendMarkerIndex = 0;

    /**
     * 删除推荐上车地点
     */
    removeRecommendMarkers() {
        for (let i in this.rm_points) {
            this.rm_points[i].setLabel(undefined);
            this.rm_points[i].setMap(null);
        }
        this.recommendMarkers = []
    }

    /**
     * 添加推荐上车地点
     * @param {*} list 
     */
    addRecommendMarkers(list) {
        this.removeRecommendMarkers()
        this.recommendMarkers = list

        for (let i in this.recommendMarkers) {
            this.rm_points[i].setLabel({
                offset: new AMap.Pixel(0, 15),
                content: this.recommendMarkers[i].title,
                direction: 'center'
            })
            this.rm_points[i].setPosition(new AMap.LngLat(this.recommendMarkers[i].location.lng, this.recommendMarkers[i].location.lat))
            this.rm_points[i].setMap(this.map);
            this.rm_points[i].show()
        }
    }

    /**
     * 隐藏推荐上车地点文字描述
     * @param {*} index 
     */
    hideRecommendMarkerLabel(index) {
        this.currentrecommendMarkerIndex = index;
        if (this.recommendMarkers.length > index) {
            for (let i in this.recommendMarkers) {
                this.rm_points[i].setLabel({
                    offset: new AMap.Pixel(0, 15),
                    content: this.recommendMarkers[i].title,
                    direction: 'center'
                });
            }
            this.rm_points[index].setLabel(undefined);
        }
    }

    /**
     * 显示推荐上车地点文字描述
     * @param {*} index 
     */
    showRecommendMarkerLabel(index) {
        let current = this.recommendMarkers[index]
        if (current) {
            this.rm_points[index].setLabel({
                offset: new AMap.Pixel(0, 15),
                content: this.recommendMarkers[index].title,
                direction: 'center'
            })
        }
    }

    /**
     * 隐藏推荐上车地点
     */
    hideRecommendMarker() {
        for (let i in this.rm_points) {
            this.rm_points[i].hide();
        }
    }

    /**
     * 显示推荐上车地点(如果有)
     */
    showRecommendMarker() {
        if (this.recommendMarkers && this.recommendMarkers.length) {
            for (let i in this.recommendMarkers) {
                this.rm_points[i].show();
            }
        }
    }
    
    removePoint(pid) {
        switch(pid) {
            case 'showCurrentPoint':
                if (this.current_point) {
                    this.current_point.setMap(null);
                }
                break;
            default:
                break;
        }
        
    }

    /**
     * 展示当前定位点
     * @param {*} currentGeo 
     */
    showCurrentPoint(currentGeo) {
        
        this.removePoint('current_point')
        if (this.current_point) {
            this.current_point.setPosition(new AMap.LngLat(currentGeo.lon, currentGeo.lat))
        }

        this.current_point.setMap(this.map);
    }

    watchPosition = () => {
        // try {
        //     this.watchLocationId = navigator.geolocation.watchPosition(async (res) => {
        //         if (res.coords.latitude && res.coords.longitude) {
        //             let _res = await webService.map.convert(res.coords.longitude.toFixed(6), res.coords.latitude.toFixed(6));
        //             if (_res.data.status === '1') {
        //                 let _coords = _res.data.locations.split(',');
        //                 let cacheGPS = {
        //                     timestamp: new Date().getTime(),
        //                     longitude: _coords[0],
        //                     latitude: _coords[1]
        //                 };
        //                 this.showCurrentPoint({ lat: cacheGPS.latitude, lon: cacheGPS.longitude })
        //             } else {
        //                 console.error('watch方法H5原生定位成功后，进行GPS与JCG坐标系转换失败', _res.data)
        //             }
        //         }
        //     }, async (err) => {
        //         console.error(err)
        //         this.clearWatchPosition()
        //     })
        // } catch (e) {
        //     console.error('实时定位失败', e)
        // }
    }

    clearWatchPosition = () => {
        // try {
        //     navigator.geolocation.clearWatch(this.watchLocationId);
        // } catch (err) {
        //     console.error(err)
        // } finally {
        //     this.removePoint('current_point')
        // }
    }

    // 设置地图逻辑中心点偏移量
    setMapOffset = (offset) => {
        // this.map.setOffset({
        //     x: offset.x ? offset.x : 0,
        //     y: offset.y ? offset.y : 0
        // })
    }

    resetMapOffset = (offset) => {
        // this.map.setOffset({
        //     x: offset.x ? offset.x : 0,
        //     y: offset.y ? offset.y : 0
        // })
    }


    /**
     * 绘制两点间导航路径
     * @param {*} begin 
     * @param {*} end 
     * @param {*} serviceType 
     * @param {*} pathways 途径点 
     */
    async addDriving(begin, end, serviceType, pathways, positionBottom) {
        direction.Enable = true
        let res = await direction.driving(begin, end, true, pathways)

        if (res && res.code !== 0) {
            return
        }

        let arr = res.polyline;
        if (serviceType !== 20) {
            this.drawDriving(arr, this.mapStyles.begin_icon, this.mapStyles.end_icon, begin.locationName, end.locationName, positionBottom)
        } else {
            this.drawDrivingBus(arr, this.mapStyles.begin_icon, this.mapStyles.end_icon, begin.locationName, end.locationName, serviceType)
        }

        if (pathways && pathways.length) {
            this.markerLayer.add(
                pathways.map((item, index) => {
                    return {
                        id: 'pathway' + index,
                        styleId: this.mapStyles.perEnd_icon,
                        position: new AMap.LngLat(item.lng, item.lat),
                        content: item.name
                    }
                }))
        }
        return res

        // if (pathways && pathways.length) {
        //     for (let i in pathways) {
        //         let _tmp = pathways[i]
        //         let _pw = new AMap.Marker({
        //             icon: new AMap.Icon({
        //                 size: [30, 30],
        //                 image: images.perEndIcon,
        //                 imageSize: [30, 30]
        //             }),
        //             anchor: 'center',
        //             offset: new AMap.Pixel(0, 0)
        //         })
        //         _pw.setLabel({
        //             offset: new AMap.Pixel(0, 15),
        //             content: _tmp.name,
        //             direction: 'center'
        //         })
        //         _pw.setPosition(new AMap.LngLat(_tmp.lng, _tmp.lat))
        //         _pw.setMap(this.map);
        //         _pw.show()
        //     }
        // }

        // return res
    }

    /**
     * 绘制起点到终点导航路线
     * @param {*} paths 
     * @param {*} beginStyleId 
     * @param {*} endStyleId 
     * @param {*} beginLocationName 
     * @param {*} endLocationName 
     */
    drawDriving(paths, beginStyleId, endStyleId, beginLocationName, endLocationName, positionBottom = 30) {
        this.clearDriving();
        let _path = []
        for (let i = 0; i < paths.length; i += 2) {
            _path.push({
                lat: paths[i],
                lng: paths[i + 1]
            })
        }

        let polylinePoints = _path.map(a => new AMap.LngLat(a.lng, a.lat))

        this.markerLayer.add([
            {
                id: 'rm_begin',
                styleId: beginStyleId,
                position: polylinePoints[0],
                content: beginLocationName
            },
            {
                id: 'rm_end',
                styleId: endStyleId,
                position: polylinePoints[polylinePoints.length - 1],
                content: endLocationName
            }
        ])

        this.polylineLayer.add([
            {
                id: 'pl_0',
                styleId: this.mapStyles.style_driving,
                paths: polylinePoints
            }
        ])

        this.map.setFitView(this.polylineLayer.getAll(), true, [10, positionBottom, 10, 10])
    }

    /**
     * 包车绘制起点到终点
     * @param {*} paths 
     * @param {*} beginStyleId 
     * @param {*} endStyleId 
     * @param {*} beginLocationName 
     * @param {*} endLocationName 
     */
    drawDrivingBus(paths, beginStyleId, endStyleId, beginLocationName, endLocationName, serviceType) {
        this.clearDrivingBus()
        let _path = []
        for (let i = 0; i < paths.length; i += 2) {
            _path.push({
                lat: paths[i],
                lng: paths[i + 1]
            })
        }

        let polylinePoints = _path.map(a => new AMap.LngLat(a.lng, a.lat))
        if (this.map && this.markerLayer) {
            this.markerLayer.add([
                {
                    id: 'begin_icon',
                    styleId: beginStyleId,
                    position: polylinePoints[0],
                    content: beginLocationName
                },
                {
                    id: 'end_icon',
                    styleId: endStyleId,
                    position: polylinePoints[polylinePoints.length - 1],
                    content: endLocationName
                }
            ])
        }
        
        this.map.setFitView(undefined, true, [345, 100, 30, 30])
    }

    /**
     * 添加起点图标（进行中页面用到）
     * @param {*} begin 
     */
    addBeginMarker(begin) {

        this.markerLayer.clear()
        this.markerLayer.add([{
            id: 'rm_begin',
            styleId: this.mapStyles.begin_icon,
            position: new AMap.LngLat(begin.lng, begin.lat),
            content: begin.locationName
        }])

        this.map.setZoom(AMAP_ZOOM_DEFAULT)
        this.map.panTo(new AMap.LngLat(begin.lng, begin.lat), { duration: 10 })
    }

    /**
     * 进行中路径缓存
     */
    runingPaths = {
        path: [],
        distance: 0,
        duration: 0,
        isPickup: true,
        end: undefined,
        // 最新动画播放轨迹位置
        passedLatLngs: undefined,

        countYaw: 0,

        // 判断终点是否发生变化
        isSameEnd(_end) {
            return (this.end !== undefined && this.end.lat === _end.lat && this.end.lng === _end.lng)
        },

        clear() {
            this.path = [];
            this.distance = 0;
            this.duration = 0;
            this.isPickup = true;
            this.passedLatLngs = undefined;
            this.end = undefined;
            this.countYaw = 0;
        }
    }

    /**
     * 添加路径导航动画
     * @param {*} begin 
     * @param {*} end 
     * @param {*} isPickup 
     * @param {*} perEnd 前序订单下车地点
     * @param {*} pathways
     */
    async addRuningAnimation(begin, end, isPickup, perEnd, pathways) {

        // 前序订单目的地图标
        if (perEnd) {
            this.markerLayer.add([
                {
                    id: 'per_end',
                    styleId: this.mapStyles.perEnd_icon,
                    position: new AMap.LngLat(perEnd.lng, perEnd.lat),
                    content: ''
                }
            ])
        } else {
            this.markerLayer.remove(['per_end'])
        }

        // 途径点
        // 地图上应该显示的途径点
        let map_pathway = []
        if (pathways && pathways.length) {
            pathways = pathways.filter(a => !a.passed)
            // 设置最新点
            if (pathways && pathways.length) {
                map_pathway = pathways.map((item, index) => {
                    return {
                        id: 'pathway' + index,
                        styleId: this.mapStyles.perEnd_icon,
                        position: new AMap.LngLat(item.lng, item.lat),
                        content: item.name
                    }
                })
            }
        }
        // 取得旧点，与新点对比，删除多余的，补充新增的
        let _allpathway = this.markerLayer.getOriData().filter(a => a.id.indexOf('pathway') === 0)
        // 需要删除的点
        let _new_pathwayName = map_pathway.map(a => a.content)
        let _del = _allpathway.filter(a => !_new_pathwayName.includes(a.content))
        // 删除旧点
        if (_del && _del.length) {
            this.markerLayer.remove(_del.map(a => a.id))
        }
        // 需要新增的点
        let _old_pathwaryName = _allpathway.map(a => a.content)
        let _new = map_pathway.filter(a => !_old_pathwaryName.includes(a.content))
        if (_new && _new.length) {
            this.markerLayer.add(_new)
        }

        if (this.runingPaths.isPickup !== isPickup || this.runingPaths.path.length === 0 || !this.runingPaths.isSameEnd(end)) {
            // 无未完成路径,或者终点改变，此时需要预估一条路径
            this.runingPaths.clear()
            direction.Enable = true
            let _waypoints = []
            if (perEnd) {
                _waypoints.push(perEnd)
            }
            if (pathways && pathways.length) {
                _waypoints = [..._waypoints, ...pathways]
            }

            let res = await direction.driving(begin, end, isPickup, _waypoints)

            if (res && res.code !== 0) {
                return {
                    code: 1
                }
            }
            if (res && !res.polyline) {
                return {
                    code: 1
                }
            }

            this.runingPaths.path = res.polyline;
            this.runingPaths.distance = res.distance;
            this.runingPaths.duration = res.duration;
            this.runingPaths.isPickup = isPickup;
            this.runingPaths.end = end
            this.runingPaths.perEnd = perEnd

            this.drawDriving(this.runingPaths.path, this.mapStyles.driver_icon, isPickup ? this.mapStyles.user_icon : this.mapStyles.end_icon)
            //this.moveAlongStart(isPickup)
            return res
        } else {
            // 已有路径，判定司机是否偏航
            let resultSegment = getRemainingSegment(this.runingPaths.path, [Number(begin.lat), Number(begin.lng)], yawThreshold)
            if (resultSegment.code !== 0 ) {
                this.runingPaths.countYaw++;
                if (this.runingPaths.countYaw > 2) {
                    // 偏航，自调用一次，重新规划路径
                    this.runingPaths.clear()
                    return await this.addRuningAnimation(begin, end, isPickup, perEnd, pathways)
                } else {
                    return {
                        code: 1,
                        message: `第${this.runingPaths.countYaw}次偏航`
                    }
                }
            } else {
                // 未偏航，绘制未完成路径
                this.drawDriving(resultSegment.path, this.mapStyles.driver_icon, isPickup ? this.mapStyles.user_icon : this.mapStyles.end_icon)
                //this.moveAlongUpdate(begin, 5)

                let _distanceto = totalDistance(resultSegment.path)
                let _duration = _distanceto * this.runingPaths.duration / this.runingPaths.distance
                // 判断时速是否低于25Km/h，如果低于这个速度，需要改成这个速度
                let _v25_duration = _distanceto / 7
                if (_duration > _v25_duration) {
                    _duration = _v25_duration
                }
                return {
                    code: 0,
                    distance: Math.ceil(_distanceto),
                    duration: Math.ceil(_duration),
                    polyline: resultSegment.path
                }
            }
        }
    }

    /**
     * 绘制司机和乘客图标
     * @param {*} passenger 
     * @param {*} driver 
     */
    drawdriverAndPassengerPosition(passenger, driver) {

        this.clearDriving();
        this.markerLayer.add([
            {
                id: 'rm_passenger',
                styleId: this.mapStyles.user_icon,
                position: new AMap.LngLat(passenger.lng, passenger.lat)
            },
            {
                id: 'rm_driver',
                //styleId: 'driver_icon',
                styleId: this.mapStyles.driver_icon,
                position: new AMap.LngLat(driver.lng, driver.lat),
            }
        ])

        this.map.setFitView(undefined, true, [10, 30, 10, 10])
    }

}

export default GdMap;
