import React from "react"
import style from './meeting.scss'

import { List, Button, Toast, Popup } from 'antd-mobile'
import webService from "../../util/webService"
import { ForgeRouter } from './../../util/utils'
import AnjinMetting from './../../component/special/anjinmetting';

import { withTranslation } from "react-i18next";

class Meeting extends React.Component {

    state = {
      poList: [],
      costCentersList: [],
      sceneList:[],
      poListShow: false,
      costCentersShow: false,
      sceneShow: false,
      budgetAmount: '',
      poTitle: '',
      costCenterTitle: '',
      sceneTitle: '',
      meetingInfo: {
        poId: '',
        costCenterCode: '',
        sceneId: ''
      }
    }

    t = this.props.t

    render() {
        return <div className={style.container}>
            <div className={style.bg_meeting}>
              {/* 会议信息补充 */}
              <span>{this.t('title.k047')}</span>
            </div>
            <div className={style.box}>
                <List style={{'--prefix-width': '6em', '--font-size': '13px'}} mode="card">
                  {/* 点击选择会议信息 */}
                  <List.Item description={<span className={style.description}>{this.state.sceneTitle ? this.state.sceneTitle : this.t('meeting.k002')}</span>} 
                    onClick={() => { 
                      this.setState({
                        sceneShow: true
                      }, () => {
                        let fr = new ForgeRouter(() => {
                            let {hash} = document.location;
                            if (hash === '#/special/meeting') {
                                this.setState({
                                  sceneShow: false
                                }, () => {
                                    if (this.needCallBack) {
                                        this.props.onChange();
                                    }
                                })
                                
                            } 
                        });
                        fr.onpopstate();
                      })
                    }}>
                        {/* 会议信息 */}
                        {this.t('meeting.k001')}
                    </List.Item>
                    {/* 点击选择成本中心信息 */}
                    <List.Item arrow={false} description={<span className={style.description}>{this.state.costCenterTitle ? this.state.costCenterTitle : this.t('meeting.k004')}</span>}>
                        {/* 成本中心信息 */}
                        {this.t('meeting.k003')}
                    </List.Item>
                    <List.Item description={
                      // 点击选择PO信息
                      <span className={style.description}>{this.state.poTitle ? this.state.poTitle : this.t('meeting.k005')}</span>
                    } onClick={() => { 
                      this.setState({
                        poListShow: true
                      }, () => {
                        let fr = new ForgeRouter(() => {
                          let {hash} = document.location;
                          if (hash === '#/special/meeting') {
                              this.setState({
                                poListShow: false
                              }, () => {
                                
                              })
                              
                          } 
                        });
                        fr.onpopstate();
                      })
                    }}>
                        {/* PO信息 */}
                        {this.t('meeting.k006')}
                    </List.Item>
                    <List.Item description={<span className={style.money}>{this.state.budgetAmount}</span>} >
                        {/* 预算金额 */}
                        {this.t('meeting.k007')}
                    </List.Item>
                </List>
                {/* 页面显示为会议RAE对应的成本中心，打车费用会结算在对应区域安进打车PO，请知晓。 */}
                <div className={style.msg}>{this.t('meeting.k008')}</div>
            </div>
            <div className={style.btn}>
                <Button block color='primary' onClick={() => {
                  this.setSceneBindPo()
                  // 确定
                 }}>{this.t('settings.k008')}</Button>
            </div>
            <Popup
                position='bottom'
                visible={this.state.poListShow}
                bodyStyle={{ height: '100%' }}
                >
                {/* 地址选择框 */}
                {
                    this.state.poListShow 
                    ? <AnjinMetting t={this.props.t} placeholder={this.t('meeting.k009')} onChecked={(val) => {
                      this.props.history.goBack();
                      this.setState({
                        meetingInfo: {
                          ...this.state.meetingInfo,
                          poId: val.value,
                        }
                      }, () => {
                        this.state.poList.forEach(options => {
                          if (options.value === val.value) {
                            this.setState({
                              budgetAmount: options.poBudget,
                              poTitle: options.label
                            })
                          }
                        })
                      })

                    }} history={this.props.history} options={this.state.poList}></AnjinMetting> 
                    : <></>}
            </Popup>
            {/* <CascadePicker 
                options={this.state.poList}
                visible={this.state.poListShow}
                onClose={() => {
                    this.setState({
                      poListShow: false
                    })
                }}
                onConfirm={val => {
                  console.log(val)
                  this.setState({
                    meetingInfo: {
                      ...this.state.meetingInfo,
                      poId: val[0],
                    }
                  }, () => {
                    this.state.poList.forEach(options => {
                      if (options.value === val[0]) {
                        this.setState({
                          budgetAmount: options.poBudget,
                          poTitle: options.label
                        })
                      }
                    })
                  })
                }}
                
            /> */}
            <Popup
                position='bottom'
                visible={this.state.costCentersShow}
                bodyStyle={{ height: '100%' }}
                >
                {/* 地址选择框 */}
                {
                    this.state.costCentersShow 
                    // 请输入成本中心信息代码
                    ? <AnjinMetting t={this.props.t} placeholder={this.t('meeting.k010')} onChecked={(val) => {
                      this.props.history.goBack();
                      this.setState({
                        meetingInfo: {
                          ...this.state.meetingInfo,
                          costCenterCode: val.value,
                        }
                      }, () => {
                        this.state.costCentersList.forEach(options => {
                          if (options.value === val.value) {
                            this.setState({
                              costCenterTitle: options.label
                            })
                          }
                        })
                      })

                    }} history={this.props.history} options={this.state.costCentersList}></AnjinMetting> 
                    : <></>}
            </Popup>
            {/* <CascadePicker 
                options={this.state.costCentersList}
                visible={this.state.costCentersShow}
                onClose={() => {
                    this.setState({
                      costCentersShow: false
                    })
                }}
                onConfirm={val => {
                  console.log(val)
                  this.setState({
                    meetingInfo: {
                      ...this.state.meetingInfo,
                      costCenterCode: val[0],
                    }
                  }, () => {
                    this.state.costCentersList.forEach(options => {
                      if (options.value === val[0]) {
                        this.setState({
                          costCenterTitle: options.label
                        })
                      }
                    })
                  })
                }}
                
            /> */}
            <Popup
                position='bottom'
                visible={this.state.sceneShow}
                bodyStyle={{ height: '100%' }}
                >
                {/* 地址选择框 */}
                {
                    this.state.sceneShow 
                    // '请输入会议信息编号或名称'
                    ? <AnjinMetting t={this.props.t} placeholder={this.t('meeting.k011')} onChecked={(val) => {
                      this.props.history.goBack();
                      this.setState({
                        meetingInfo: {
                          ...this.state.meetingInfo,
                          sceneId: val.value,
                        }
                      }, async () => {
                        this.state.sceneList.forEach(options => {
                          if (options.value === val.value) {
                            this.setState({
                              sceneTitle: options.label
                            })
                          }
                        })
                        await this.getPoCostByScene()
                      })

                    }} history={this.props.history} options={this.state.sceneList}></AnjinMetting> 
                    : <></>}
            </Popup>
            {/* <CascadePicker 
                options={this.state.sceneList}
                visible={this.state.sceneShow}
                onClose={() => {
                    this.setState({
                      sceneShow: false
                    })
                }}
                onConfirm={val => {
                  this.setState({
                    meetingInfo: {
                      ...this.state.meetingInfo,
                      sceneId: val[0],
                    }
                  }, () => {
                    this.state.sceneList.forEach(options => {
                      if (options.value === val[0]) {
                        this.setState({
                          sceneTitle: options.label
                        })
                      }
                    })
                  })
                }}
                
            /> */}
        </div>
    }

    async componentDidMount() {
      await this.getPoBindInfo()
    }

    getPoBindInfo = async () =>{
      let { data } = await webService.special.meeting.getPoBindInfo()

      if (data.code === 0) {
        // let poList = data.data.poList.map((item) => {
        //   return{
        //     value: item.poId,
        //     label: item.poCode,
        //     poBudget: item.poBudget
        //   }
        // })
        // let costCentersList = data.data.costCenters.map((item) => {
        //   return{
        //     value: item.costCenterCode,
        //     label: item.costCenterName,
        //   }
        // })
        let sceneList = data.data.sceneList.map((item) => {
          return{
            value: item.sceneId,
            label: item.sceneName,
            code: item.sceneCode
          }
        })
        this.setState({
          // poList: poList,
          // costCentersList:costCentersList,
          sceneList: sceneList
        })
      }
    }

    setSceneBindPo = async () => {
      if (!this.state.costCenterTitle) {
        Toast.show({
          // 请选择成本中心
          content: this.t('meeting.k012'),
          duration: 1000
        })
      } else if (!this.state.poTitle) {
        Toast.show({
          // 请选择PO信息
          content: this.t('meeting.k013'),
          duration: 1000
        })
      } else {
        let { data } = await webService.special.meeting.setSceneBindPo({poId: this.state.meetingInfo.poId, costCenterCode: this.state.meetingInfo.costCenterCode, sceneId: this.state.meetingInfo.sceneId })
        if (data.code !== 0) {
          Toast.show({
            content: data.message,
            duration: 1000
          })
        } else {
          Toast.show({
            // 保存成功
            content: this.t('settings.k014'),
            duration: 1000
          })
        }
      }
    }

    getPoCostByScene = async () => {
      let { data } = await webService.special.meeting.getPoCostByScene({sceneId: this.state.meetingInfo.sceneId })
      if (data.code === 0 && data.data.poInfo && data.data.poInfo.length > 0) {
        let poList = data.data.poInfo.map((item) => {
          return{
            value: item.poId,
            label: item.poCode,
            poBudget: item.poBudget
          }
        })
        if (poList && poList.length === 1) {
          this.setState({
            poTitle: data.data.poInfo[0].poCode,
            budgetAmount: data.data.poInfo[0].poBudget,
          })
        } else {
          this.setState({
            poTitle:'',
            budgetAmount: ''
          })
        }
        this.setState({
          poList: poList,
          costCenterTitle: data.data.costCenter.costCenterName,
          meetingInfo: {
            ...this.state.meetingInfo,
            poId: data.data.poInfo[0].poId,
            costCenterCode: data.data.costCenter.costCenterCode
          }
        })
      } else {
        this.setState({
          poList: [],
          costCenterTitle: '',
          budgetAmount: '',
          poTitle: '',
          meetingInfo: {
            ...this.state.meetingInfo,
            poId: '',
            costCenterCode: ''
          }
        })
      }
    }

    // updateScenePo = async () => {
    //   let { data } = await webService.special.meeting.updateScenePo({poId: this.state.meetingInfo.poId, costCenterCode: this.state.meetingInfo.costCenterCode, sceneId: this.state.meetingInfo.sceneId })
    //   if (data.code !== 0) {
    //     Toast.show({
    //       content: data.message,
    //       duration: 1000
    //     })
    //   } else {
    //     Toast.show({
    //       content: '保存成功',
    //       duration: 1000
    //     })
    //   }
    // }

    // isPoSceneExist = async () => {
    //   if (this.state.meetingInfo && this.state.meetingInfo.sceneId) {
    //     let { data } = await webService.special.meeting.isPoSceneExist({sceneId: this.state.meetingInfo.sceneId})
    //     if (!data.data.isExist) {
    //       this.setSceneBindPo()
    //     } else {
    //       this.updateScenePo()
    //     }
    //   } else {
    //     Toast.show({
    //       content: '请选择会议信息',
    //       duration: 1000
    //     })
    //   }
    // }
}

export default withTranslation()(Meeting);