import { isClient, execBridge } from './bridgeSmartcomTrip'
import { oauthType } from './../util/config'
import { dge } from './../util/utils'
import * as dd from 'dingtalk-jsapi';

let isTripClient = isClient()



const setWebViewTitle = (title) => {
    if (isTripClient) {
      execBridge('bridgeSetTitle', title);
    } else if((oauthType === '13' || dge() === 'ding-ding') && dd.env.platform !== "notInDingTalk") {
      dd.ready(function () {
        dd.biz.navigation.setTitle({
          title : title,//控制标题文本，空字符串表示显示默认文本
          onSuccess : function(result) {
          },
          onFail : function(err) {
            console.log('err', err)
          }
        });
      })
    } else {
        document.title = title;
    }
}

export {
    setWebViewTitle
}
