import React from 'react'
import {inject, observer } from 'mobx-react'
import style from './makeAppointment.scss'

import moment from 'moment'

import { Button, Space, Image } from 'antd-mobile'

import CycleText from './cycleText'

import images from './../../util/images'

import { customerServiceTelephone } from './../../util/config'

@inject('mainStore')
@observer
class MakeAppointment extends React.Component {

  state = {
    
  }

  t = this.props.t

  mainStore = this.props.mainStore

  get monthDayEn() {
    let month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][moment(this.props.departTime ? this.props.departTime : '').month()]
    let day = moment(this.props.departTime ? this.props.departTime : '').format('DD').replace('0', '')
    return {
      month:month,
      day: day
    }
  }

  render() {
    return(
      <div className={style.box}>
        <div className={style.box_call}>
          <div>
            <div className={style.cell}>
              <CycleText t={this.props.t}/>
            </div>
            {/* k095 如有任何疑问请拨打服务热线 */}
            <div className={style.service}><span>{this.t('runing.k095')}<span className={style.servicePhone} onClick={() => {
                        window.top.location.href="tel://" + customerServiceTelephone
                    }}>{customerServiceTelephone}</span></span></div>
          </div>
          <Space wrap>
            {/* k088 取消预约 */}
            <Button block shape='rounded' color='primary' onClick={() => this.props.cancelOrder()}>
              {this.t('runing.k088')}
            </Button>
          </Space>
        </div>
        <div>
          <div className={style.box_time}>
            <Image
              src={images.waitForTime}
              width={17}
              height={17}
              fit='cover'
              style={{ borderRadius: 32 }}
            />
            {/* k096 出发 */}
            <span className={style.waitForTime}>{this.t('runing.k096', {timeZh:moment(this.props.departTime).format('Y年M月DD日 HH:mm'), hourEn:moment(this.props.departTime).format('HH:mm'), timeEn: this.monthDayEn.month + ' ' + this.monthDayEn.day + 'th'})}</span>
          </div>
          <div className={style.box_time}>
            <Image
              src={images.smallBell}
              width={20}
              height={20}
              fit='cover'
            />
            {/* k097 预计将在 {{time}}，前完成预约 */}
            <span className={style.bell}>{this.t('runing.k097')}</span>
          </div>
        </div>
      </div>
    )
  }


}

export default MakeAppointment