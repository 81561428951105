import React from "react";
import { inject, observer } from 'mobx-react'
import { Picker } from 'antd-mobile'
import style from './callcar.scss'
import images from "../../util/images";

@inject('mainStore', 'globalStore')
@observer
class CharteredBus extends React.Component {
  state = {
    show: false,
    charteredBusState: ''
  }

  mainStore = this.props.mainStore;
  globalStore = this.props.globalStore;
  t = this.props.t

  get rentalCarTypes() {
    return this.globalStore.companyInfo.rentalCarTypes ? [this.globalStore.companyInfo.rentalCarTypes] : []
  }

  get charteredBusValue() {
    let arr = [];
    if (this.rentalCarTypes && this.rentalCarTypes[0]) {
      arr = this.rentalCarTypes && this.rentalCarTypes[0].filter(a => a.value === this.state.charteredBusState);
    }
    // 半日包(4小时)
    return arr && arr.length ? arr[0].label : this.t('estimate.k034');
  }

  render() {
    return <div className={style.line}>
    <div className={style.icon}>
        <img src={images.charteredBus} alt="" />
    </div>
    <div className={`${style.content} ${style.sence}`} onClick={() => {
      this.setState({
        show: true
      })
    }}>
        <div>{this.charteredBusValue}</div>
        <div className={style.name}></div>
        <div className={style.more}><img src={images.approveSee} alt="" /></div>
    </div>
    <Picker cancelText={this.t('common.k001')} confirmText={this.t('common.k002')} columns={this.rentalCarTypes} visible={this.state.show} value={[this.state.charteredBusState]} onConfirm={(value) => {
        if (!value || value.length === 0) {
            return
        }
        this.setState({
          charteredBusState: value[0],
        })
        this.mainStore.setCharteredBusType(value[0])
        this.props.onChange();
      }}
      onClose={() => {
        this.setState({
          show:false
        })
      }}>
    </Picker>
  </div>
  }
}

export default CharteredBus