import React from "react";
import { Card, Empty } from 'antd-mobile'

import ReactEcharts from 'echarts-for-react';

import style from './comEcharts.scss'
import images from '../../util/images'

class ComEcharts extends React.Component {
  state = {

  }

  get isShowEcharts() {
    if (this.props.option && this.props.option.series && this.props.option.series[0] && this.props.option.series[0].data && this.props.option.series[0].data.length > 0) {
      return true
    }
    else if (this.props.option && this.props.option.series && this.props.option.series.data && this.props.option.series.data.length > 0) {
      return true
    }
    return false
  }

  render() {
    return <div className={style.card}>
      <Card title={this.props.title}>
        {
           (() => {
            if (this.isShowEcharts) {
              return <div className={style.echarts}>
                <ReactEcharts notMerge={true} lazyUpdate={true} renderer="svg" option={this.props.option}></ReactEcharts>
              </div> 
            }
           })()
        }
        {
          (() => {
            if (this.props.option && this.props.option.series && this.props.option.series.length === 0) {
              return <div>
              <Empty description="暂无内容" image={images.reportnodata} imageStyle={{width: '144px', height: '110px' }}/>
            </div>
            }
          })()
        }
      </Card>
    </div>
  }
}

export default ComEcharts