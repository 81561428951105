import loger from './loger'
import {
    oauthType,
    appId    
} from './globalConfiguration'
import { loadJavascript } from './utils'
import { envName } from './variable'

const init = async () => {
    if (oauthType === '8' && appId === 'meituan') {
        // 美团
        if (!window.MTSQT) {
            let mtjs_url = ''
            switch (envName) {
                case 'test':
                    mtjs_url = 'https://sqt.waimai.test.meituan.com/c/cfeapi/app/adapter';
                    break;
                case 'prod21':
                    mtjs_url = 'https://sqt.meituan.com/c/cfeapi/app/adapter';
                    break;
                default : {
                    mtjs_url = 'https://sqt.waimai.test.meituan.com/c/cfeapi/app/adapter';
                }
            }

            try {
                await loadJavascript(mtjs_url)
            } catch (exp) {
                loger.error('定位', 'MTJSBridge', '加载美团js失败', exp)
            }
        }
    }
}
// 业务按场景替换
const config = {
    key: '47f127c2ef2c5884e41f2a672b80b72f',
    project: 'tujing',
    htmlTimeout: 5000,
    city: true,
    geotype: 'GCJ02',
    cityType: 'meituan',
    uuid: '123456',
    userid: '123456',
    KNBSceneToken: 'dj-a3f4d6c3978d25ac'
};

const getCurrentLocation_MT = async () => {
    await init()
    return new Promise((resolve, reject) => {
        try {
            console.log(window.MTSQT, window.MTSQT ? true : false)
            if (window.MTSQT) {
                window.MTSQT.ready(() => {
                    window.MTSQT.getLocation(config, (error, result) => {
                        console.log('定位____', result)
                        if (result && result.latitude && result.longitude) {
                            resolve({
                                code: 0,
                                longitude: result.longitude,
                                latitude: result.latitude,
                                coordsType: 'gcj02'
                            })
                        } else {
                            loger.error('定位', 'MTJSBridge', '定位失败', error)
                            resolve({
                                code: 2,
                                message: '定位失败' + error
                            })
                        }
                    })
                })
            } else {
                loger.error('定位', 'MTJSBridge', '未取得MTSQT对象')
                resolve({
                    code: 2,
                    message: '未取得MTSQT对象'
                })
            }
        } catch (exp) {
            console.log('定位', 'MTJSBridge', '宿主机提供JSBridge调用异常', exp)
            loger.error('定位', 'MTJSBridge', '宿主机提供JSBridge调用异常', exp)
            reject({
                code: 100,
                message: '宿主机提供JSBridge调用异常：' + exp
            })
        }
    })
    
}

export {
    getCurrentLocation_MT
}