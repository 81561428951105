import React from 'react'
import style from './chooseScene.scss';
import {inject, observer } from 'mobx-react'

import Scene from './../component/callcar/scene'

import { withTranslation } from "react-i18next";

@inject('mainStore')
@observer
class ChooseScene extends React.Component {

    state = {
        scenes: []
    }
    mainStore = this.props.mainStore;
    onChange = undefined;

    t = this.props.t

    render() {
        return <div className={style.container}>
            <div className={style.scene}>
                {
                    this.state.scenes.map((item) => {
                        return <Scene t={this.props.t} key={item.publishId} scene={item} onClick={
                            () => {
                                this.mainStore.setScene(item);
                                this.onChange();
                                this.props.history.goBack();
                            }

                        }></Scene>
                    })
                }
            </div>
        </div>
    }

    componentDidMount() {
        this.setState({
            scenes: this.props.location.state.scenes
        });
        this.onChange = this.props.location.state.onChange;
    }
}

export default withTranslation()(ChooseScene);