import React from 'react';
import style from './agreement.scss';

class AgreementEn extends React.Component {

    render() {
        return <div className={style.body}>
    <h2>Software Usage Agreement</h2>
    <h3>1. Definition</h3>
    <p>
    1.1 [Enterprise Travel] Platform: hereinafter referred to as "Enterprise Travel", it is an enterprise travel service platform that provides travel services for enterprise customers. Vehicle personnel of enterprise customers can independently send travel demand orders through the [Enterprise Travel] platform, and achieve quick payment after the orders are completed.
    </p>
    <p>
    1.2 Order: A valid order actually initiated and completed by the user who opens a corporate account on the Enterprise Travel platform.
    </p>
    <p>
    1.3 Users: Employees or other relevant personnel of the enterprise client who use [Enterprise Travel], who independently send travel demand orders through the platform, are natural persons with full civil rights and behavioral capacity, or other actual passengers who comply with the provisions of this clause.
    </p>
    <h3>2. Legal Declaration</h3>
    <p>
    This Software Use Agreement is applicable to the Enterprise Travel platform. You should carefully read this agreement before clicking to agree to it. Please carefully read and fully understand the content of each clause, especially the clauses exempting or limiting liability, legal application, and dispute resolution clauses. The clauses exempting or limiting liability will be marked in bold, and you should focus on reading them. If you have any questions about the agreement, you can consult with customers on the Enterprise Travel platform (consultation hotline:). When you click to agree to this agreement, it means that you have fully read and understood the terms of this agreement, accepted all the contents of this agreement, and become a "corporate user" of the [Enterprise Travel] platform. This agreement is legally binding on your company and the platform. The [Enterprise Travel] platform has the right to modify the terms of this agreement if necessary, and notify you in advance through publicity, push notifications, or pop-up notifications. You can check the latest version of the modified agreement on this platform at any time. If you do not agree to the modified agreement, you have the right to stop using the services on this platform. After the update of this agreement, if you continue to use the services of this platform, it is deemed that you have accepted the modified agreement.
    </p>
    <h3>3. Account usage</h3>
    <p>
    Users are required to pay fees incurred due to personal actions during the use process. If there is a default, the Enterprise Travel platform has the right to ban the account and pursue possible legal responsibilities.
    </p>
    <h3>4. Rights Statement</h3>
    <p>
    [Enterprise Travel] shall enjoy intellectual property rights and be protected by law for all content of software and related products or services issued by it or jointly issued with cooperative companies, including but not limited to the provided software and related products or services. If the relevant content does not contain a rights statement, it does not mean that the [Enterprise Travel] platform does not have any rights or claims against it. You should respect the legitimate rights and interests of the rights holder and use the content legally in accordance with laws, regulations, and principles of integrity. Without the written permission of the Enterprise Travel platform, no unit or individual shall use, copy, modify, transcribe, disseminate, or bundle with other products, sell, or use any part of the aforementioned software, products, services, information, or text in any way or for any reason, or connect or transmit them through a hyperlink, store them in an information retrieval system, or use them for any other commercial purpose. However, for non commercial purposes, Except for downloading or printing for personal use (without modification and retaining copyright or other proprietary information in the content). The trademarks and logos used and displayed in the above software (hereinafter collectively referred to as "trademarks") are related trademarks registered and unregistered by the Enterprise Travel platform and its affiliated companies in the field of travel services and other related fields, and are protected by law. Any content contained in such software shall not be used in any way by anyone without the written permission of the Enterprise Travel platform.
    </p>
    <h3>5. Limitation of Liability Statement</h3>
    <p>
    5.1 The Enterprise Travel platform may have developed different application versions for different terminal devices, and users should choose to download and install the appropriate version based on their actual situation. The platform does not guarantee the compatibility and availability of all versions with related devices, nor does it guarantee the consistency of the services provided by all versions.
    </p>
    <p>
    5.2 In order to improve the platform's service content, the Enterprise Travel platform will continuously strive to develop new services and provide users with application updates. After the release of a new version of the application, the old version of the application may not be usable. The platform does not guarantee that the old version of the application can continue to be used and corresponding services. Therefore, users are requested to check and download the latest version of the application at any time.
    </p>
    <p>
    5.3 Please understand and agree that in the process of using the services of this platform, factors such as force majeure and technical risks may be encountered. The platform is exempt from liability to the maximum extent permitted by law for contract performance obstacles, performance defects, performance delays or changes in performance content, data loss, and other situations caused by the following reasons:
    </p>
    <p>
    A. Due to natural disasters, strikes, labor disputes, riots, riots, explosions, wars, government actions, judicial administrative orders, or other force majeure factors;
    </p>
    <p>
    B. Due to power supply failure Communication network failures (including but not limited to failure or delay in electronic communication transmission, interception or operation of electronic communication by computer programs used for electronic communication) or other public service factors or user factors (including but not limited to improper user operation, unauthorized use of this service through non platform means) or third-party factors (including but not limited to Computer virus, trojans or other malicious programs, hacker attacks, customers' wrong orders and other wrong operations);
    </p>
    <p>
    C. Due to routine or emergency equipment and system maintenance, equipment and system failures, defects, network information and data security, technical risks, and other factors, while the platform has done its utmost in good faith management;
    </p>
    <p>
    D. Situations that cannot be controlled or reasonably foreseen by other platforms.
    </p>
    <p>
    5.4 If the platform discovers processing errors caused by system failures or other reasons, whether beneficial to the platform or the user, the platform has the right to correct the error, reverse/reverse related transactions or data after notifying the user in accordance with the provisions of this agreement. The user understands and agrees that any overpayment or underpayment due to the aforementioned processing error shall not be subject to interest, and the platform shall not be liable for any losses or liabilities (including interest, exchange rate, and other losses that the user may incur due to the aforementioned processing error), except for processing errors caused by malicious intent on the platform.
    </p>
    <p>
    5.5 The platform will only be liable for the direct and actual losses caused to you by the platform, and will not be liable for any indirect losses or Punitive damages.
    </p>
    <p>
    5.6 Users should have full civil capacity as stipulated by the laws of the China when actually using the [Enterprise Travel] service. If you do not have full civil capacity, you must use the taxi service with the consent of your parents and other Legal guardian and accompanied throughout the journey. Otherwise, you and your guardian shall bear all consequences arising from this in accordance with legal provisions.
    </p>
    <p>
    5.7 The user agrees that [Enterprise Travel] has the right to notify and inform you of information through message push, platform announcement, SMS, phone, email, and other forms. In addition, users are allowed to send commercial electronic information or advertising promotion information on the Enterprise Travel platform.
    </p>
    <h3>6. Authorization and licensing</h3>
    <p>
    6.1 Subject to the user's compliance with this agreement, the platform grants the user a limited, non-exclusive, and non-transferable license to download and install a copy of the platform application onto a single mobile device owned or controlled by the user, and to run this copy of the application solely for the user's own use. Users are not allowed to:
    </p>
    <p>
    (1) License, sublicense, sell, resell, transfer, distribute, distribute services or applications in any way, or otherwise engage in commercial development or provide them to any third party;
    </p>
    <p>
    (2) Modify platform service functions or applications, or create derivative products based on them;
    </p>
    <p>
    (3) Create an internet "link" to platform services, or "design" or "mirror" any application on any other server or wireless or internet based device;
    </p>
    <p>
    (4) Reverse engineering or accessing application design or building competing products or services, using ideas or graphics similar to platform services or applications to design or build products, or plagiarizing any ideas, features, functions or graphics of platform services or applications;
    </p>
    <p>
    (5) Initiating automatic programs or scripts that send multiple server requests per second or excessively burden platform services or applications or hinder their work and/or performance;
    </p>
    <p>
    6.2 To the maximum extent permitted by law, the platform will have the right to investigate and prosecute any of the aforementioned illegal and irregular behaviors. This platform can participate in and assist law enforcement agencies in prosecuting users who violate this agreement. If the platform believes that any content violates this agreement or otherwise harms the website, platform, and/or its services or applications, the platform reserves the right to delete or disable access to such content at any time without prior notice.
    </p>
    <h3>
    7. Intellectual Property
    </h3>
    <p>
    7.1 The various operating systems displayed on the Enterprise Travel platform are independently developed, operated, and provided with technical support by Enterprise Travel, and all data and information generated during the development and operation of Enterprise Travel services are entitled to full rights. The copyright and patent rights of the software used for providing various services, as well as the trademarks, commercial images, commercial logos, technical know-how used, and their copyrights, trademark rights, and other related rights belong to the Enterprise Travel platform.
    </p>
    <p>
    7.2 The user understands and agrees that the intellectual property and ownership of all information generated and uploaded by the user during the use of Enterprise Travel belong to the Enterprise Travel platform
    </p>
    <h3>
    8. Intellectual Property
    </h3>
    <p>
    [Enterprise Travel] The platform may retain links to third-party websites (or applications) or websites, as well as third-party services. Whether to access these links or accept corresponding services will be up to users to make their own decisions. The platform does not promise or guarantee the accuracy, completeness, adequacy, and reliability of any information, data, viewpoints, images, statements or suggestions provided by these links. If the user decides to visit any third-party website (or application) linked to this platform, the possible consequences and risks arising from it shall be borne by the user themselves.
    </p>
    <h3>9. Applicable Law and Jurisdiction</h3>
    <p>
    By accessing the services provided by the [Enterprise Travel] platform or its cooperative service providers, the user agrees that the access or service is subject to the laws of the China, and the user agrees to be subject to the jurisdiction of the courts of the China. Disputes arising in the process of visiting or receiving services shall be settled through consultation. If consultation fails, a lawsuit shall be filed with the competent people's court in the place where the agreement is signed. The place where the agreement is signed is Baoshan District, Shanghai, China
    </p>
    </div>
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }
}

export default AgreementEn;
