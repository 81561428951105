import React from 'react';
import { inject, observer } from 'mobx-react'
import style from './callcar.scss'
import images from './../../util/images'

import { Popup, Toast, Dialog, SearchBar, Image } from 'antd-mobile'

import Scene from './scene'
import ScenesDetails from './../../pages/scenesDetails'

import webService from './../../util/webService'
import { ForgeRouter } from './../../util/utils'

import loger from './../../util/loger'

import moment from 'moment'

@inject('globalStore', 'mainStore', 'confirmStore')
@observer
class CarSence extends React.Component {

    state = {
        show: false,
        scenes: [],
        Keyword: '',
        recoveryMode: '',
        remainQuota: '',

        showDetails: false,
        sceneId: ''
    }

    globalStore = this.props.globalStore;
    mainStore = this.props.mainStore;
    confirmStore = this.props.confirmStore;
    needCallBack = false;
    getSceneListTimeStamp = 0;
    t = this.props.t

    get serverTypes() {
        let sceneServerTypes = this.mainStore.scene ? this.mainStore.scene.serverTypes : []
        if (sceneServerTypes && sceneServerTypes.length) {
            return this.globalStore.companyInfo.serverTypes.filter(a => sceneServerTypes.includes(a))
        } else {
            return this.globalStore.companyInfo.serverTypes
        }
    }

    /**
     * 渲染用户可选场景
     * @returns 
     */
    renderSence = () => {
        return <div className={style.scene}>
            <div className={style.search}>
                <SearchBar placeholder={this.t('scenes.k034')} value={this.state.Keyword} style={{ '--background': '#ffffff', '--border-radius': '8px' }}
                    onChange={v => {
                        this.setState({
                            Keyword: v
                        }, () => {
                            this.showScene()
                        })

                    }}
                ></SearchBar>
                {/* <div className={style.searchbtn} onClick={()=>{this.showScene()}}>{this.t('scenes.k032')}</div> */}
            </div>
            {
                this.state.Keyword || (!this.state.remainQuota && this.state.remainQuota !== 0) 
                    ? '' 
                    : <div className={style.totalamount}>
                        <span>{this.t('scenes.k033')}{this.state.recoveryMode ? '(' + this.state.recoveryMode + ')' : ''}</span>
                        <span>{this.state.remainQuota ? this.state.remainQuota : this.state.remainQuota === 0 ? 0 : ''}{this.t('scenes.k003')}</span>
                    </div>
            }

            {
                this.state.scenes && this.state.scenes.length > 0 ? this.state.scenes.map((item, index) => {
                    return <Scene t={this.props.t} key={item.id} history={this.props.history} isChecked={this.mainStore.scene.id === item.id} scene={item} showCheckBox={true} onClick={
                        () => {
                            if(this.mainStore.scene && this.mainStore.scene.id !== item.id) {
                              this.confirmStore.setCustomCarList([])
                            }
                            this.mainStore.setScene({
                                id: item.id,
                                code: item.code,
                                nameCn: item.nameCn,
                                nameEn: item.nameEn,
                                isNeedApprove: item.isNeedApprove,
                                publishId: item.publishId,
                                needPreApproval: item.needPreApproval,
                                serverTypes: item.serverTypes
                            });

                            if (!this.serverTypes.includes(this.mainStore.serverType)) {
                                this.mainStore.chooseServerType(this.serverTypes[0]);
                            }
                            this.needCallBack = true;
                            this.props.history.goBack();
                            this.setState({
                              show: false
                            })
                            if (item.tips) {
                                Dialog.alert({
                                    content: (
                                      <>
                                      <div style={{whiteSpace: 'pre-wrap'}}>{item.tips}</div>
                                      </>
                                    ),
                                    confirmText: this.t('common.k003')
                                })
                            }
                        }

                    } onScenesDetails={
                        () => {
                            this.setState({
                                showDetails: true,
                                sceneId: item.id
                            }, () => {
                                let fr = new ForgeRouter(() => {
                                    let { hash } = document.location;
                                    if (hash === '#/') {
                                        this.setState({
                                            showDetails: false
                                        }, () => {
                                            
                                        })
        
                                    }
                                });
                                fr.onpopstate();
        
                            });
                        }
                    }></Scene>
                }) : <div className={style.null_bg}>
                    <Image src={images.null_bg} width={200} height={150} fit='cover' />
                    <span>{this.t('main.k0132')}</span>
                </div>
            }

            <Popup
                visible={this.state.showDetails}
                position='right'
                bodyStyle={{ minWidth: '100vw' }}
                >
                    {/* 场景详情 */}
                    {
                        this.state.showDetails 
                            ? <ScenesDetails t={this.props.t} history={this.props.history} sceneId={this.state.sceneId} />
                            : <></>
                    }
                    
            </Popup>
        </div>

    }

    render() {
        return <div className={style.line}>
            <div className={style.icon}>
                <img src={images.carSence} alt="" />
            </div>
            <div className={`${style.content} ${style.sence}`} onClick={() => {
                if (this.mainStore.scene.readonly) {
                    loger.debug('首页', '用车场景', '只读场景，不允许切换')
                    return;
                }
                this.showScene();
            }}>
                <div>{this.t('main.k013')}&nbsp;</div>
                <div className={style.name}>{this.mainStore.scene.nameCn}</div>
                <div className={style.more}>
                    {
                        this.mainStore.scene.readonly
                        ? <></>
                        : <img src={images.approveSee} alt="" />
                    }
                    
                </div>
            </div>

            <Popup
                visible={this.state.show}
                position='right'
                bodyStyle={{ minWidth: '100vw' }}
            >
                {/* 场景选择 */}
                {this.renderSence()}
            </Popup>
        </div>
    }

    /**
     * 显示可选用车场景
     */
    showScene = () => {
        // Toast.show({
        //     icon: 'loading',
        //     content: this.t('main.k076'),
        //     duration: 0
        // })

        let params = {
            serviceType: this.mainStore.serverType,
            departTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            Keyword: this.state.Keyword
        }
        let now = new Date().getTime();
        this.getSceneListTimeStamp = now;
        webService.scene.getSceneList(params).then((d) => {
            if (now !== this.getSceneListTimeStamp) {
                return
              }
            if (d.data.code === 0) {
                // 兼容残缺的后端返回json格式
                if (!d.data.data.rows && d.data.data && d.data.data.length) {
                    d.data.data = {
                        rows: d.data.data
                    }
                }
                if (d.data.data.rows && d.data.data.rows.length) {
                    Toast.clear();
                    this.needCallBack = false;
                    this.setState({
                        show: true,
                        scenes: d.data.data.rows
                    }, () => {
                        let fr = new ForgeRouter(() => {
                            let { hash } = document.location;
                            if (hash === '#/') {
                                this.setState({
                                    show: false,
                                    Keyword: ''
                                }, () => {
                                    if (this.needCallBack) {
                                        this.props.onChange();
                                        
                                    }
                                })

                            }
                        });
                        fr.onpopstate();

                    });

                    //this.props.history.push({ pathname: '/chooseScene', state: { scenes: d.data.data.rows, onChange: this.props.onChange }});

                } else {
                    this.setState({
                        scenes: []
                    })
                    // Toast.show({
                    //     icon: 'fail',
                    //     content: this.t('main.k032')
                    // })
                }


            } else {
                // Toast.show({
                //     icon: 'fail',
                //     content: this.t('main.k032')
                // })
            }
            Toast.clear();
        }, () => {
            Toast.show({
                icon: 'fail',
                content: this.t('main.k033')
            })
        })
    }
    componentDidMount() {
        this.getPersonalQuota()
    }
    async getPersonalQuota() {
        let { data } = await webService.scene.getPersonalQuota();
        if (data.data) {
            this.setState({
                recoveryMode: data.data.recoveryMode,
                remainQuota: data.data.remainQuota
            })
        }
    }
}

export default CarSence