// import { unzip } from './zip'


const convterToQqFormat = (res) => {
    if (res.status === 200 && res.data.code !== undefined && res.data.ver === 2014) {
        let data = res.data
        let hit_hub_of_traffic = data.code === 0 ? 1 : 0
        let flg = res.data.data?.fences.filter(a => a.isdest)
        let _data = undefined
        if (hit_hub_of_traffic) {
            data = data.data
            console.log(data.fences.length)
            _data = {
                status: 0,
                message: 'query ok',
                data: {
                    status: 0,
                    message: 'successful',
                    request_id: new Date().getTime() + '',
                    detail: {
                        hit_hub_of_traffic: hit_hub_of_traffic,
                        flg: flg.length > 0? true : false,
                        data: {
                            name: data.name,
                            polygon: data.polygon,
                            sub_fence: data.fences.map(a => {
                                return {
                                    id: a.id,
                                    name: a.name,
                                    isdest: a.isdest
                                }
                            }),
                            hit_sub_fence: data.fences.map(a => {
                                return {
                                    id: a.id,
                                    name: a.name,
                                    data: a.childrens.map(b => {
                                        return {
                                            id: b.id,
                                            title: b.name,
                                            location: {
                                                lat: b.lat,
                                                lng: b.lng
                                            }
                                        }
                                    })
                                }
                            }),
                        }
                    }
                }
            }
        } else {
            _data = {
                status: 0,
                message: 'query ok',
                data: {
                    status: 0,
                    message: 'successful',
                    request_id: new Date().getTime() + '',
                    detail: {
                        hit_hub_of_traffic: hit_hub_of_traffic
                    }
                }
            }
        }

        

        res.data = _data
    }

    return res
}


export {
    convterToQqFormat
}