/**
 * 等待接单页-神州
 */
import React from 'react'
import style from './waitingShenzhou.scss';
import {inject, observer } from 'mobx-react'
import { Button, Loading, Popup, Dialog } from 'antd-mobile'
import {RightOutline} from 'antd-mobile-icons'

import {customerServiceTelephone} from './../util/config'
import images from '../util/images';
import webService from '../util/webService';
import WebSocketHelper from '../util/wss';
import {airportPickupToCn} from '../util/paramsHelper';


import Concierge from './../component/callcar/concierge'

import { withTranslation } from "react-i18next";

@inject('globalStore')
@observer
class WaitingShenzhou extends React.Component {

    state = {
        showConcierge: false,
        baseInfo: {},
        scene: {},
        cars: [],
        airportPickup: {state: 0}
    }

    globalStore = this.props.globalStore;
    orderId = this.props.match.params.id;
    wss = undefined;

    t = this.props.t

    render() {
        let { baseInfo, cars, airportPickup } = this.state;

        return <div className={style.container}>
            <div className={style.loading}>
                <Loading color='currentColor' />
            </div>
            <div className={style.tip}>
                {this.t('waitingShenzhou.k001')}
            </div>

            <div className={style.orderInfo}>
                <div className={style.title}>{this.t('waitingShenzhou.k002')}</div>
                <div className={style.body}>
                    <div>
                        <div>{this.t('waitingShenzhou.k003')}</div>
                        <div>{baseInfo.pickupLocationName}</div>
                    </div>
                    <div>
                        <div>{this.t('waitingShenzhou.k004')}</div>
                        <div>{baseInfo.destLocationName}</div>
                    </div>
                    <div>
                        <div>{this.t('waitingShenzhou.k005')}</div>
                        <div>{baseInfo.passengerPhone}</div>
                    </div>
                    <div>
                        <div>{this.t('waitingShenzhou.k006')}</div>
                        <div>{cars && cars.length ? cars[0].nameCn : ''}</div>
                    </div>
                    <div>
                        <div>{this.t('waitingShenzhou.k007')}</div>
                        <div>{baseInfo.passengerName}</div>
                    </div>
                </div>
            </div>

            <div className={style.pickup}>
                <div>{this.t('waitingShenzhou.k008')}</div>
                <div onClick={() => {
                    this.setState({
                        showConcierge: true
                    })
                }}>
                    {airportPickupToCn(airportPickup.state, this.t)} <RightOutline />                    
                </div>
            </div>
            <Popup visible={this.state.showConcierge} onMaskClick={() => {
                this.setState({
                    showConcierge: false
                })
            }}>
                <Concierge airportPickup={airportPickup} orderId={this.orderId} onClose={() => {
                    this.setState({
                        showConcierge: false
                    })
                }} />
            </Popup>

            <div className={style.toolbar}>
                <div>
                    <img src={images.phone3} alt={this.t('waitingShenzhou.k011')} /> {this.t('waitingShenzhou.k009')} <span onClick={() => {
                        window.top.location.href="tel://" + customerServiceTelephone
                    }}>{customerServiceTelephone}</span>
                </div>
                <div>
                    <Button block color='primary' onClick={this.cancelOrder}>{this.t('waitingShenzhou.k010')}</Button>
                </div>
            </div>
        </div>
    }

    componentDidMount() {
        this.wss = new WebSocketHelper(this.globalStore.userInfo.satoken, this.orderId, this.receiveWss)
        this.loadOrderBaseInfo()
    }

    /**
     * 接受websokcet推送消息
     * @param {*} data 
     */
     receiveWss = (resp) => {
        if (resp.code === 0) {
            if (resp.data.orderState) {
                if (resp.data.orderState > 1) {
                    this.props.history.replace(`/runing/${this.orderId}`)
                }
            }
            if (resp.data.airportPickup) {
                this.setState({
                    airportPickup: resp.data.airportPickup
                })
            }
        }
    }

    /**
     * 载入订单基本信息
     */
     loadOrderBaseInfo = () => {
        webService.order.getBaseInfo({orderId: this.orderId}).then(d => {
            if (d.data.code === 0) {
                
                this.setState({
                    baseInfo: d.data.data.baseInfo,
                    scene: d.data.data.scene,
                    cars: d.data.data.cars
                });
                if (d.data.data.baseInfo.state < 5) {
                    if (!this.wss.isOpenState()) {
                        this.wss.open();
                    }
                    
                }
                
            }
            
        });
    }

    /**
     * 取消订单
     */
     cancelOrder = async (reason) => {
        if (this.state.baseInfo.state > 3) {
            // 行程开始后不可取消订单
            return;
        }
        let res =  await Dialog.confirm({
            content: (<>
                <div style={{fontSize: '16px', textAlign: 'center'}}>{this.t('waitingShenzhou.k012')}</div>
                <div>{this.t('waitingShenzhou.k013')}</div>
            </>),
            confirmText: this.t('waitingShenzhou.k010'),
            cancelText: this.t('waitingShenzhou.k014')
        })
        if (res) {
            webService.order.cancelOrder({orderId: this.orderId, reason: reason ? reason : '用户取消用车'}).then(d => {
                if (d.data.code === 0) {
                    this.props.history.replace('/')
                } else {
                    Dialog.alert({
                        content: d.data.message,
                        confirmText: this.t('common.k003')
                    })
                }
            });
        }
    }
}

export default withTranslation()(WaitingShenzhou);