import React from "react";
import style from './login.scss'
import { Form, Input, Button, Checkbox } from 'antd-mobile'

import images from "../util/images";

import { withTranslation } from "react-i18next";

class Register extends React.Component {

    state = {
        agreement: false,
        countDown: 60,
        isAllowGetCode: true,

        companyName: '',
        adminName: '',
        adminPhone: '',
        verificationCode: ''
    }

    form = undefined;

    t = this.props.t;

    render() {
        return <div className={style.container}>
            <div className={style.logo}>
                <img alt="" src={images.logo} />
            </div>
            <div className={style.form}>
            <Form style={{
                    '--border-bottom': 'none',
                    '--border-top': 'none',
                    '--border-inner': 'none'
                }} ref={(dom) => {this.form = dom;}}>
                    <Form.Item
                        name='companyName'
                        rules={[{ required: true, message: this.t('register.k001') }]}
                    >
                        <Input value={this.state.companyName} onChange={(value) => {
                            this.setState({
                                companyName: value
                            })
                        }} placeholder={this.t('register.k001')} clearable="true" />
                    </Form.Item>
                    <Form.Item
                        name='adminName'
                        rules={[{ required: true, message: this.t('register.k002') }]}
                    >
                        <Input value={this.state.adminName} onChange={(value) => {
                            this.setState({
                                adminName: value
                            })
                        }} placeholder={this.t('register.k002')} clearable="true" />
                    </Form.Item>
                    <Form.Item
                        name='adminPhone'
                        rules={[{ required: true, message: this.t('register.k003') }, {pattern: /^1\d{10}$/, message: '请输入正确的手机号'}]}
                    >
                        <Input value={this.state.adminPhone} onChange={(value) => {
                            this.setState({
                                adminPhone: value
                            })
                        }} placeholder={this.t('register.k003')} clearable="true" />
                    </Form.Item>
                    <Form.Item
                        name='verificationCode'
                        rules={[{ required: true, message: this.t('register.k004') }]}
                    >
                        <div className={style.verificationCode}>
                            <div>
                            
                                    <Input onChange={(value) => {
                                        this.setState({
                                            verificationCode: value
                                        })
                                    }} placeholder={this.t('register.k004')} clearable="true" />
                                
                            </div>
                            <div>
                                <Button block color='primary' size='middle' onClick={() => { this.getVerificationCode() }}>
                                    {
                                        this.state.isAllowGetCode
                                            ? this.t('register.k008')
                                            : `${this.state.countDown}s`
                                    }
                                </Button>
                            </div>
                        </div>
                    </Form.Item>
                    
                </Form>
            </div>
            <div className={style.btn}>
                <Button block color='primary' size='middle' onClick={() => {this.register()}}>{this.t('register.k009')}</Button>
            </div>
            <div className={style.agreement}>
                <Checkbox checked={this.state.agreement} onChange={(val) => {
                    this.setState({
                        agreement: val
                    })
                }} style={{
                    '--icon-size': '12px',
                    '--font-size': '12px'
                }}>
                    {this.t('register.k005')}<span onClick={this.goAgreement}>{this.t('register.k006')}</span>{this.t('register.k007')}
                </Checkbox>
            </div>
        </div>
    }

    /**
     * 获取验证码
     */
     getVerificationCode = () => {
        if (this.state.isAllowGetCode) {
            this.beginCountDown();
        }
        
    }

    /**
     * 开始倒计时
     */
    beginCountDown = () => {
        this.setState({
            countDown: 60,
            isAllowGetCode: false
        }, () => {
            setTimeout(() => {
                this.countDownTimer();
            }, 1000);
        });
    }

    /**
     * 倒计时
     */
    countDownTimer = () => {
        let {countDown} = this.state;
        countDown -= 1;
        this.setState({
            countDown: countDown
        }, () => {
            if (countDown > 0) {
                setTimeout(() => {
                    this.countDownTimer();
                }, 1000);
            } else {
                this.setState({
                    isAllowGetCode: true
                })
            }
        });
    }

    goAgreement = () => {
        //this.props.history.push('https://www.baidu.com');
    }

    register = async () => {
        try {
            await this.form.validateFields();
            let { agreement /*, companyName, adminName, adminPhone, verificationCode*/ } = this.state;
            if (agreement) {
            }
        } catch (exp) {
            console.log('表单不可提交', exp)
        }
        
        
        
    }
}

export default withTranslation()(Register);