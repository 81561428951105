import axios from 'axios'

import {
  serverBaseUrl,
  qqmapBaseUrl,
  amapBaseUrl,
  qqKey,
  amapKey,
  // ua,
  webBaseUrl,
  oatuth2_url,
  oauthType,
  appId
} from './config'

import { envName } from './variable'

import { getBaseCitys } from './localStorageHandler'
import { locationReplace, isFences } from './utils'
import loger from './loger'
import gc from './globalConfiguration'
import { CoordHelper } from './coordHelper'
import { downloadStationData, hitStation } from './../util/stationHepler'

import { convterToQqFormat } from './hitFenceHelper'

import { gatewayDomain } from './variable';
import * as dd from 'dingtalk-jsapi';

import AMapAdapter from './aMapAdapter'
import { findLogoSrc } from './logoCache'

window.satoken = sessionStorage.getItem('satoken');

let timestamp = new Date().getTime();

let relogin = (hash) => {
  let fromMeetingLogin = sessionStorage.getItem('fromMeetingLogin') 
  sessionStorage.clear();
  sessionStorage.setItem('fromMeetingLogin', fromMeetingLogin || '0')

  if (oauthType === '13') {
    let corpId = appId.split('@')[1]
    dd.ready(function () {
      dd.runtime.permission.requestAuthCode({
        corpId: corpId, // 企业id
        onSuccess: function (info) {
          let code = info.code // 通过该免登授权码可以获取用户身份
          let authurl = `${webBaseUrl}/?oauth=13&code=${code}${hash}`
          console.log(authurl);
          locationReplace(authurl);
          // alert(authurl)
          // window.location.replace(authurl);
        },
        onFail: function(err) {
          alert('授权失败，请向管理员确认您是否具备此应用的使用权限' + JSON.stringify(err))
          console.log(`渠道${oauthType} appId${appId} 调用 dd.runtime.permission.requestAuthCode 失败 ${JSON.stringify(err)}`) 
        }
      });
    });
  } else {
    if (hash !== '#' && hash !== '#/' && hash !== '/#/') {
      sessionStorage.setItem('hash', hash);
    }

    if (oatuth2_url) {
      locationReplace(oatuth2_url);
      // window.location.replace(oatuth2_url);
    } else {
      alert('未找到匹配的登录方式，登录超时，请重新打开页面。');
    }
  }

}

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  if (config.url.indexOf(qqmapBaseUrl) === 0) {
    return config;
  }
  if (config.url.indexOf(amapBaseUrl) === 0) {
    return config;
  }
  if (!config.headers) {
    config.headers = {
      satoken: window.satoken,
    }
  }
  config.headers.satoken = window.satoken;
  config.headers.platform = 3
  if (window.lang) {
    config.headers.lang = window.lang
  }
  if (sessionStorage.getItem('clientId')) {
    config.headers['X-ICar-ClientId'] = sessionStorage.getItem('clientId');
  }
  
  if (sessionStorage.getItem('grayscale')) {
    config.headers['pre-env'] = 'grayscale_environment';
    // console.log(sessionStorage.getItem('grayscale'))
  }

  if (config.headers.common['Cookie']) {
    delete config.headers.common['Cookie']
  }
  

  // 默克、金杜，是否会议用车
  if (gc.process.isMoke || gc.process.isJindu) {
    config.headers.fromMeetingLogin = sessionStorage.getItem('fromMeetingLogin') || '0'
  }

  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

/**
 * 判断是否排除登陆判断
 */
const excludeLoginStatus = (url) => {
  let urls = ['/frontEndLoger/logger/save']
  for (let i in urls) {
    if (url.indexOf(urls[i]) !== -1) {
      return true
    }
  }

  return false
}

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  let data = response.data;

  if (data.code === 100 && data.data.authUrl) {
    // token超时,判断是否需要重新登陆
    if (excludeLoginStatus(response.request.responseURL)) {
      return
    }
    // 需要重新oauth2
    let { hash } = window.location;
    relogin(hash);
  }
  else {
    if (data.code) {
      try {
        if (response.request.responseURL.indexOf('/logger/report') === -1) {
          loger.error('webSweviceErrRelease', response.request.responseURL, response.request.responseText)
          // webService.logger.report(
          //   window.location.hash, 'webSweviceErrRelease', response.request.responseURL, response.request.responseText)
        }
      } catch(e) {
        console.error(e)
      }      
    }
    return response;
  }


}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});

let aMapAdapter = new AMapAdapter()
aMapAdapter.init()

// 推荐上车地点缓存
let cacheRecommend = []
const addCacheRecommend = (item) => {
  cacheRecommend.push(item)
}
const findCacheRecommend = (lon, lat) => {
  let res = cacheRecommend.filter(a => a.location.lat === lat && a.location.lon === lon)
  let result = res && res.length ? res[0] : undefined
  return result
}

// let convertGpsJcg = []
// const addCoordinate = (item) => {
//   convertGpsJcg.push(item)
// }

// const findCoordinate = (lon, lat) => {
//   let res = convertGpsJcg.filter(a => a.location.lat === lat && a.location.lon === lon)
//   let result = res && res.length ? res[0] : undefined
//   return result
// }

// 坐标与poi缓存
let cachePois = []
const addCachePoi = (poi) => {
  cachePois.push(poi)
}
const findCachePoi = (gps) => {
  let res = cachePois.filter(a => a.location.lat === gps.lat && a.location.lng === gps.lon)
  let result = res && res.length ? res[0].poi : ''
  return result
}

// 坐标反差结果缓存
let cacheGeocoder = []
const addCacheGeocoder = (item) => {
  cacheGeocoder.push(item)
}
const findCacheGeocoder = (lon, lat) => {
  let res = cacheGeocoder.filter(a => a.location.lat === lat && a.location.lon === lon)
  let result = res && res.length ? res[0] : undefined
  return result
}

// 城市查询结果缓存
let cacheCityByName = []
const addCacheCityByName = (item) => {
  cacheCityByName.push(item)
}
const findCacheCityByName = (key) => {
  let citys = getBaseCitys()
  // 在citys 对象里面查
  let citys_res = citys.filter(a => a.cityName === key.cityName)
  if (citys_res && citys_res.length) {
    return {
      data: {
        data: {
          code: 0,
          data: {
            cityCode: citys_res[0] && citys_res[0].cityCode ? citys_res[0].cityCode : '',
            cityName: citys_res[0] && citys_res[0].cityName ? citys_res[0].cityName : '',
            cityPinYin: citys_res[0] && citys_res[0].cityPinYin ? citys_res[0].cityPinYin : '',
            cityGroupName: citys_res[0] && citys_res[0].cityGroupName ? citys_res[0].cityGroupName : '',
            cityDistrict: citys_res[0] && citys_res[0].cityDistrict ? citys_res[0].cityDistrict : ''
          }
        }
      }
    }
  }

  let res = cacheCityByName.filter(a => a.key.cityName === key.cityName)
  let result = res && res.length ? res[0] : undefined
  return result
}

const webService = {

  map: {
    /**
     * 坐标系转换
     * @param {*} longitude 
     * @param {*} lat 
     * @returns 
     */
    convert: async (longitude, latitude) => {
      latitude = Number(latitude)
      longitude = Number(longitude)
      let res = CoordHelper.wgs84togcj02({lat: latitude, lng: longitude})
      return {
        longitude: Number(res.lng),
        latitude: Number(res.lat)
      }

      // let cacheData = findCoordinate(longitude, latitude)
      // if (cacheData) {
      //   return cacheData.d
      // }
      // let d = await axios.get(amapBaseUrl + `/assistant/coordinate/convert?key=${amapKey}&locations=${longitude},${latitude}&coordsys=gps`);
      // if (d.data.status === '1') {
      //   addCoordinate({
      //     location: {
      //       lon: longitude,
      //       lat: latitude
      //     },
      //     d: d
      //   })
      // }
      // return d
    },
    /**
     * 高德地理位置反查
     * @param {*} lon 
     * @param {*} lat 
     * @returns 
     */
    geocode: (lon, lat) => {
      return axios.get(amapBaseUrl + '/geocode/regeo?extensions=all&location=' + lon + ',' + lat + '&poitype=&radius=1000&extensions=base&batch=false&roadlevel=0&key=' + amapKey);
    },
    // 腾讯坐标系转换接口
    translate: async (longitude, latitude) => {

      let res = CoordHelper.wgs84togcj02({lat: latitude, lng: longitude})
      return {
        longitude: Number(res.lng),
        latitude: Number(res.lat)
      }


      // longitude = Number(longitude)
      // latitude = Number(latitude)
      // let cacheData = findCoordinate(longitude, latitude)
      // if (cacheData) {
      //   return cacheData.d
      // }

      // let {data} = await axios.get(qqmapBaseUrl + `/ws/coord/v1/translate?locations=${latitude},${longitude}&type=1&key=${qqKey}`)
      // if (data.status === 0 && data.locations && data.locations.length) {
      //   let gcj02 = data.locations[0]
      //   let d = {
      //     longitude: gcj02.lng,
      //     latitude: gcj02.lat
      //   }

      //   addCoordinate({
      //     location: {
      //       lon: longitude,
      //       lat: latitude
      //     },
      //     d: d
      //   })
        
      //   try {
      //     console.log(`开始本地坐标转换${latitude},${longitude}`)
      //     let res = CoordHelper.wgs84togcj02({lat: latitude, lng: longitude})
      //     console.log(`==>${res.lat},${res.lng}`)
      //     // if (Number(res.lat) === Number(d.latitude) && Number(res.lng) === Number(d.longitude)) {
      //     let distance = CoordHelper.getDistanceFromLatLonInKm(
      //       {lat: Number(res.lat), lng: Number(res.lng)}, 
      //       {lat: Number(d.latitude), lng: Number(d.longitude)})
      //     if (distance < 0.2) {
      //       loger.info('坐标转换', '相同', `${longitude},${latitude} ==> ${d.longitude},${d.latitude}`)
      //     } else {
      //       loger.info('坐标转换', '不同', `${longitude},${latitude} ==> ${d.longitude},${d.latitude} ==> ${res.lat},${res.lng} ==> ${distance}`)
      //     }
      //   } catch (exp) {
      //     console.error('上报坐标转换对比出现异常', exp)
      //   }
        
      //   return d
      // }
      // return undefined
    },
    /**
     * 腾讯地理位置反查
     * @param {*} lon 
     * @param {*} lat 
     * @returns 
     */
    geocoder: async (lon, lat) => {
       if (window.lang === 1) {
        let cacheData = findCacheGeocoder(lon, lat)
        if (cacheData) {
          return cacheData.data
        }
        let d = await axios.get(qqmapBaseUrl + `/ws/geocoder/v1/?location=${lat},${lon}&get_poi=1&key=${qqKey}&poi_options=radius=100;policy=3`);
        if (d.status === 200 && d.data.status === 0) {
          addCacheGeocoder({
            location: {
              lat,
              lon
            },
            data: d
          })
        }
        return d
      } else {
        let res = await aMapAdapter.geocoder(lon, lat);
        return res;
      }
    },
    /**
     * 地理位置正查
     * @param {*} key 
     * @param {*} cityName 
     * @returns 
     */
    place: async (key, cityName, location) => {
      if (window.lang === 1) {
         let url = qqmapBaseUrl + `/ws/place/v1/suggestion?keyword=${key}&region=${cityName}&region_fix=0&get_subpois=1&policy=11&key=${qqKey}`
        if (location) {
          url += '&location=' + location
        }
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.status === 0) {
          for (let i in res.data.data) {
            res.data.data[i].isFence = isFences(res.data.data[i].category)
          }
          for (let i in res.data.sub_pois) {
            res.data.sub_pois[i].isFence = isFences(res.data.sub_pois[i].category)
          }
        }
        return res
      } else {
        let res = await aMapAdapter.place(key, cityName, 'en')
        return res
      }
    },
    /**
     * 路径规划
     * @param {*} from 
     * @param {*} from_poi 
     * @param {*} to 
     * @param {*} to_poi 
     * @param {*} policy 
     * @param {*} waypoints 途径点
     * @returns 
     */
    driving: (from, from_poi, to, to_poi, policy, waypoints) => {
      from_poi = ''
      to_poi = ''
      let _waypoints = ''
      if (waypoints && waypoints.length) {
        _waypoints = waypoints.map(a => `${a.lat},${a.lng}`).join(';')
      }
      let url = qqmapBaseUrl + `/ws/direction/v1/driving/?no_step=1&from=${from}&to=${to}&output=json&key=${qqKey}&policy=${policy},REAL_TRAFFIC,NAV_POINT_FIRST`;
      if (from_poi) {
        url += `&from_poi=${from_poi}`;
      }
      if (to_poi) {
        url += `&to_poi=${to_poi}`;
      }
      if (_waypoints) {
        url += `&waypoints=${_waypoints}`;
      }
      return axios.get(url);
    },
    /**
     * 推荐上车地点
     * @param {*} key 
     * @param {*} lat
     * @param {*} lon
     * @param {*} user_id 
     * @returns 
     */
    recommend: async (lat, lon, userId, recommendType, count) => {
      if (window.lang === 2) {
        return {
          data: {
            code: 0,
            data: []
          }
        }
      }
      let cacheData = findCacheRecommend(lon, lat)
      if (cacheData) {
        return cacheData.data
      }
      // 优先查询后端汇总后的推荐上车地点，如果无有效返回，则使用地图提供的原始数据
      let uris = []
      uris.push(serverBaseUrl + `/order/user/getRecommendedLocation?lat=${lat}&lng=${lon}&recommendType=${recommendType}&count=${count}`)
      uris.push(qqmapBaseUrl + `/ws/tpp/v1/search?key=${qqKey}&location=${lat},${lon}&user_id=${userId}`)
  
      let res;
      for (let i in uris) {
        let uri = uris[i]
        try {
          res = await axios.get(uri)
        } catch (exp) {
          continue
        }
        
        if (res.status === 200 && (res.data.status === 0 || res.data.code === 0)) {
          addCacheRecommend({
            location: {
              lat: lat,
              lon: lon
            },
            data: res
          })
  
          let list = res.data.data.map(a => {
            return {
              location: a.location,
              poi: a.id
            }
          })
          list.forEach(a => addCachePoi(a))
          break
        }
      }
      return res
    },

    
    /**
     * 获取命中的一级围栏和二级围栏信息
     * @param {*} lat 
     * @param {*} lon 
     * @param {*} reqid 
     * @param {*} reqtime 
     * @returns 
     */
    gethitFences: async (lat, lon, name) => {
      const getEnvironment = () => {
        if (['test'].includes(envName)) {
          return 1
        } else if (sessionStorage.getItem('grayscale')){
          return 2
        } else {
          return 1
        }        
      }
      if (getEnvironment() === 3) {
        return webService.map.gethitFences2024(lat, lon, name)
      } else {
        let res = undefined;
        if (window.lang === 1 || window.lang === undefined) {
          res = await axios.get(serverBaseUrl + `/ws/tls/v1/tpp/fences?ver=1&key=${qqKey}&lat=${lat}&lng=${lon}`)
          // return axios.get(qqmapBaseUrl + `/ws/tls/v1/tpp/fences?ver=1&key=${qqKey}&location=${lat},${lon}&reqid=${reqid}&reqtime=${reqtime}`)
  
          // res = await axios.post('https://devplatformadminv2.imtpath.net/stationManager/station/isHub2', {lat: lat, lng: lon})
  
        } else {
          res = {
            status: 200,
            data: {
              code: 0,
              message: '成功',
              data: {
                status: 0,
                message: 'successful',
                request_id: '0f9b4b44b38c8c9a6109476d860eda',
                detail: {
                  hit_hub_of_traffic: 0,
                  flg: false
                }
              }
            }
          }
        }
        // // 兼容2024版本新场站数据结构
        if (window.useNewStation === 1) {
          downloadStationData('https://ipath-dev-v2-files-1309103551.cos.ap-shanghai.myqcloud.com/station/publish/2024_07_25_04_53_51.bin')
          let station = await hitStation({lat: lat, lng: lon})
          res = convterToQqFormat({
            status: 200,
            data: {
              code: 0,
              ver: 2014,
              data: station
            }
          })
        }
        console.log(res)
        return res
      }

      
    },
    /**
     * 获取二级围栏推荐上车点信息
     * @param {*} lat 
     * @param {*} lon 
     * @param {*} reqid 
     * @param {*} reqtime 
     * @returns 
     */
    gethitSecondLevelFences: (fence_id, lat, lon, reqid, reqtime) => {
      return axios.get(qqmapBaseUrl + `/ws/tls/v1/tpp/search?ver=1&fence_id=${fence_id}&key=${qqKey}&location=${lat},${lon}&reqid=${reqid}&reqtime=${reqtime}`)
    },

    gethitFences2024: async (lat, lng, name = '') => {
      const getEnvironment = () => {
        if (envName.indexOf('test') > -1) {
          return 1
        } else if (sessionStorage.getItem('grayscale')){
          return 2
        } else {
          return 1
        }        
      }
      
      let {data} = await axios.post(`https://devplatformadminv2.imtpath.net/stationManager/api/v3/station/hitStation`, {
        lat: lat,
        lng: lng,
        name: name,
        environment:getEnvironment()
      });


      let station = data.data
      let res = convterToQqFormat({
        status: 200,
        data: {
          code: station?.fences.length > 0 ? 0 : 1,
          ver: 2014,
          data: station
        }
        })
      return res
    }

  },
  basCity: {
    /**
     * 获取用户基本信息
     * @returns 
     */
    getUserInfo: () => {
      return axios.post(serverBaseUrl + '/user/getUserInfo');
    },
    /**
     * 获取全部支持城市
     * @returns 
     */
    getCityList: () => {
      return axios.post(serverBaseUrl + '/basicInfo/getCityList');
    },
    /**
     * 城市代码查城市
     * @param {*} d 
     * @returns 
     */
    getCityByName: async (d) => {
      let cacheData = findCacheCityByName(d)
      if (cacheData) {
        return cacheData.data
      }
      let res = await axios.post(serverBaseUrl + '/basicInfo/getCityByName', d);
      if (res.status === 200 && res.data.code === 0) {
        addCacheCityByName({
          key: d,
          data: res
        })
      }
      return res
    },
    /**
     * 获取指定城市历史用车地点
     * @param {*} cityCode 
     * @returns 
     */
    getHistoryLocationList: (cityCode) => {
      return axios.post(serverBaseUrl + '/basicInfo/getHistoryLocationList', { cityCode: cityCode });
    },
    /**
     * 搜索航班号
     * @param {*} flightNumber 
     * @returns 
     */
    getFlightInfo: (d) => {
      return axios.post(serverBaseUrl + '/agent/extra/VariFlight/GetFlight', d);
    },
    /**
     * 火车时刻表
     * @param {*} 
     * @returns 
     */
    getTrainTimetable: (d) => {
      return axios.post(serverBaseUrl + '/agent/extra/VariFlight/GetTrainTimetable', d);
    },
    /**
     * 美团判断是否该城市支持途经点
     * @param {*} 
     * @returns 
     */
    supportPassingPoint: (d) => {
      return axios.post(serverBaseUrl + '/order/check/supportPassingPoint', d);
    }
  },
  company: {
    /**
     * 查询企业基本信息
     * @returns 
     */
    getBasicInfo: () => {
      return axios.post(serverBaseUrl + '/system/company/getBasicInfo');
    },
    /**
     * 查询企业自定义上车地点
     * @returns 
     */
    getRecommendLocations: () => {
      return axios.post(serverBaseUrl + '/system/custom/getRecommendLocations');
    },
    /**
     * 查询KA企业提示
     * @returns 
     */
    getCompanyHintText: () => {
      return axios.post(serverBaseUrl + '/system/GetCompanyHintText');
    },
    /**
     * 查询企业派车模式配置
     * @returns 
     */
    getDispatchMode: () => {
      return axios.post(serverBaseUrl + '/basicInfo/getDispatchMode')
    }
  },
  order: {
    /**
     * 取得待处理任务数
     * @returns 
     */
    getBadgeCount: () => {
      return axios.post(serverBaseUrl + '/order/getBadgeCount');
    },
    /**
     * 获取前次使用场景和进行中订单
     * @returns 
     */
    getRunningOrdersAndLastScene: () => {
      return axios.post(serverBaseUrl + '/order/getRunningOrdersAndLastScene');
    },
    /**
     * 获取前次使用场景和进行中订单
     * @returns 
     */
    getRunningOrders: () => {
      return axios.post(serverBaseUrl + '/order/getRunningOrders');
    },
    /**
     * base地判定
     * @param {*} d 
     * @returns 
     */
    checkOrderParam: (d) => {
      return axios.post(serverBaseUrl + '/order/check/orderParam', d);
    },
    /**
     * 预估前判断能否进入下一步
     * @param {*} d 
     * @returns 
     */
    preCheck: (d) => {
      return axios.post(serverBaseUrl + '/order/preCheck', d);
    },
    /**
     * 预估前判断能否进入下一步
     * @param {*} d 
     * @returns 
     */
    preCheck2: (d) => {
      return axios.post(serverBaseUrl + '/order/preCheck2', d);
    },
    /**
     * 预估价格
     * @param {*} d 
     * @returns 
     */
    estimate: async (d) => {
      let res = await axios.post(serverBaseUrl + '/order/estimate', d);
      if (res.data.code === 0) {
        let data = res.data.data
        if (data.cars && data.cars.length) {
          for (let i in data.cars) {
            let car = data.cars[i]
            if (car.list && car.list.length) {
              for (let j in car.list) {
                let item = car.list[j]
                item.carSourceImg = findLogoSrc(item.carSourceId)
              }
            }
          }
        }
        
        if (data.labels && data.labels.length) {
          for (let i in data.labels) {
            let item = data.labels[i]
            for(let j in item.data) {
              let data = item.data[j];
              for(let x in data.list) {
                let car = data.list[x]
                car.carSourceImg = findLogoSrc(car.carSourceId);
              }
            }
          }
        }
      }
      return res
    },
    /**
     * 确认页面依赖项数据
     * @param {*} d 
     * @returns 
     */
    getConfirmDependency: (d) => {
      return axios.post(serverBaseUrl + '/configuration/getConfirmDependency', d);
    },

    /**
     * 上次下单选择的车型
     * @returns 
     */
    getLastChooseCar: () => {
      return axios.post(serverBaseUrl + '/order/getLastChooseCar');
    },
    /**
     * 获取订单列表
     * @param {*} d 
     * @returns 
     */
    getOrder: (d) => {
      return axios.post(serverBaseUrl + '/order/getOrderList', d);
    },
    /**
     * 获取订单详情
     * @param {*} d 
     * @returns 
     */
    getOrderDetails: (d) => {
      return axios.post(serverBaseUrl + '/order/getOrderDetails', d);
    },
    /**
     * 设置订单用车事由
     * @param {*} d 
     * @returns 
     */
    setReason: (d) => {
      return axios.post(serverBaseUrl + '/order/setOrderReason', d)
    },
    /**
     * 获取评价详情
     * @param {*} d 
     * @returns 
     */
    getOrderFeedback: (d) => {
      return axios.post(serverBaseUrl + '/order/getOrderFeedback', d)
    },
    /**
     * 获取评价 label
     * @param {*} d 
     * @returns 
     */
    getFeedbackLabel: (d) => {
      return axios.post(serverBaseUrl + '/configuration/order/getFeedbackLabel', d)
    },
    /**
     * 评价
     * @param {*} d 
     * @returns 
     */
    setFeedback: (d) => {
      return axios.post(serverBaseUrl + '/order/setOrderFeedback', d)
    },

    /**
     * 下单
     * @param {*} d 
     * @returns 
     */
    placeOrder: (d) => {
      return axios.post(serverBaseUrl + '/order/placeOrder', d);
    },
    /**
     * 获取订单基本信息
     * @param {*} d 
     * @returns 
     */
    getBaseInfo: (d) => {
      return axios.post(serverBaseUrl + '/order/getBaseInfo', d);
    },
    /**
     * 获取接单司机信息
     * @param {*} d 
     * @returns 
     */
    getDriverInfo: (d) => {
      return axios.post(serverBaseUrl + '/order/getDriverInfo', d);
    },
    /**
     * 追加车型预估
     * @param {*} d 
     * @returns 
     */
    appendEstimate: (d) => {
      return axios.post(serverBaseUrl + '/order/appendEstimate', d);
    },
    /**
     * 追加车型下单
     * @param {*} d 
     * @returns 
     */
    appendPlaceOrder: (d) => {
      return axios.post(serverBaseUrl + '/order/appendPlaceOrder', d);
    },
    /**
     * 取消订单
     * @param {*} d 
     * @returns 
     */
    cancelOrder: (d) => {
      return axios.post(serverBaseUrl + '/order/cancelOrder', d);
    },
    /**
     * 包车取消订单
     * @param {*} d 
     * @returns 
     */
    cancelOrderForRental: (d) => {
      return axios.post(serverBaseUrl + '/order/cancelOrderForRental', d);
    },
    /**
     * 获取进行中订单
     * @returns 
     */
    getRuningOrders: () => {
      return axios.post(serverBaseUrl + '/order/getRuningOrders');
    },
    /**
     * 获取分享用定单信息
     * @param {*} d 
     * @returns 
     */
    getShareOrder: (d) => {
      return axios.post(serverBaseUrl + '/order/getShareOrder', d);
    },
    /**
     * 修改目的地预估价
     * @param {*} d 
     * @returns 
     */
    changeDestEstimate: (d) => {
      return axios.post(serverBaseUrl + '/order/changeDestEstimate', d);
    },
    /**
     * 修改目的地
     * @param {*} d 
     * @returns 
     */
    changeDest: (d) => {
      return axios.post(serverBaseUrl + '/order/changeDest', d);
    },
    /**
     * 取消接机礼宾服务
     * @param {*} d 
     * @returns 
     */
    cancelAirportPickup: (d) => {
      return axios.post(serverBaseUrl + '/order/cancelAirportPickup', d);
    },
    /**
     * 获取未支付订单
     * @returns 
     */
    getWaitPayOrders: () => {
      return axios.post(serverBaseUrl + '/order/getWaitPayOrders');
    },
    /**
     * 获取未支付订单
     * @param {*} d 
     * @returns 
     */
    pay: (d) => {
      return axios.post(serverBaseUrl + '/bill/UserPayments/Pay', d);
    },
    /**
     * 获取订单支付状态
     * @param {*} d 
     * @returns 
     */
    getOrderPayState: (d) => {
      return axios.post(serverBaseUrl + '/bill/UserPayments/PMOrderByPayOrderid', d);
    },
    /**
     * 获取未支付订单
     * @param {*} d 
     * @returns 
     */
    getPayedOrders: (d) => {
      return axios.post(serverBaseUrl + '/order/getPayedOrders', d);
    },

    /**
     * 报警接口
     * @param {*} d
     */
    callPolice: (d) => {
      return axios.post(serverBaseUrl + '/configuration/sms/callPolice', d);
    },

    /**
     * 测试接口
     * @param {*} d 
     */
    callTest: (d) => {
      return axios.post(serverBaseUrl + '/configuration/sms/callPolice', d)
    },

    /**
     * 获取自定义用车
     * @param {*} d 
     */
    getMobileCustomCarInfo: (d) => {
      return axios.post(serverBaseUrl + '/system/custom/getMobileCustomCarInfo', d)
    },
    /**
     * 获取预付费支付单号
     * @param {*} d 
     */
    getPrepaidPrePay: (d) => {
      return axios.post(serverBaseUrl + '/order/prePay', d)
    },
    /**
     * 查询目前订单大额预付费已经支付的信息
     * @param {*} d 
     */
    getPrepaidStatePay: (d) => {
      return axios.post(serverBaseUrl + '/bill/UserPayments/PMOrderByPayOrderid', d)
    },
    /**
     * 增加车型返回预估价格
     * @param {*} d 
     */
    getPrePayAmount: (d) => {
      return axios.post(serverBaseUrl + '/order/getPrePayAmount', d)
    },
    /**
    * 获取大额预付费进度
    * @param {*} d 
    */
    getPrepaymentOrderInfo: (d) => {
      return axios.post(serverBaseUrl + '/bill/UserPayments/PrepaymentOrderInfo', d)
    },
    /**
    * 获取大额预付费支付订单是否生成
    * @param {*} d 
    */
    getPrepaidCheckPlaceOrder: (d) => {
      return axios.post(serverBaseUrl + '/order/checkPlaceOrder', d)
    },
    /**
    * 自动重新派单接口
    * @param {*} d 
    */
    getAgainPlaceOrder: (d) => {
      return axios.post(serverBaseUrl + '/order/reorder', d)
     },
     /**
     * 获取预约管家订单详细信息
     * @param {*} d 
     */
    getBookingOrderInfo: (d) => {
      return axios.post(serverBaseUrl + '/agent/booking/admin/GetBookingInfo', d)
    },
    /**
     * 订单采集点接口
     * @param {*} d 
     */
    getLocusPoint: (d) => {
      return axios.post(serverBaseUrl + '/order/track/selectDriverPosition', d)
    },
    /**
     * 修改绿色出行状态接口
     * @param {*} d 
     */
    updateUserLowCarbon: (d) => {
      return axios.post(serverBaseUrl + '/configuration/user/updateUserLowCarbon', d)
    },
    /**
     * 获取状态展示绿色出行弹窗提示首页返回 true
     */
    getShowGreenTravel: () => {
      return axios.get(serverBaseUrl + '/order/user/isShowGreenTravel')
    },
    /**
     * 获取首页默认起终点参数
     */
    getOrderLimitDefAddress: () => {
      return axios.get(serverBaseUrl + '/order/user/getOrderLimitDefAddress')
    },
    /**
     * 获取首页默认起终点参数
     */
    getOrderLimitInfo: () => {
      return axios.get(serverBaseUrl + '/order/user/getOrderLimitInfo')
    },
    /**
     * 我方单号绑定京东商城单号
     * @param {*} orderId 
     * @param {*} partnerOrderId 
     */
    relatedOrder: (orderId, partnerOrderId) => {
      return axios.post(serverBaseUrl + '/order/relatedOrder', {
        orderId: orderId,
        partnerOrderId: partnerOrderId
      })
    },

    /**
     * 使用京东单号查我们的单号和订单状态
     * @param {*} partnerOrderId 
     */
    getOrderByPartnerOrderId: (partnerOrderId) => {
      return axios.post(serverBaseUrl + '/order/getOrderByPartnerOrderId', {
        partnerOrderId: partnerOrderId
      })
    },
    /**
     * 碳积分查询
     * @param {*} partnerOrderId 
     */
    getMenuCarbonQuery: (d) => {
      return axios.post(serverBaseUrl + '/system/Carbon/MenuCarbonQuery', d)
    },
    /**
     * 获取评价 label
     * @param {*} d 
     * @returns 
     */
    getFeedbackLabels: (d) => {
      return axios.post(serverBaseUrl + '/system-config/getFeedbackLabel', d)
    },
    /**
     * 获取订单详情审批记录
     * @param {*} 
     */
    getProcessRecords: (d) => {
      return axios.post(serverBaseUrl + '/wf/process/records', d)
    },
    /**
     * 获取订单详情费用明细接口
     * @param {*} 
     */
    getOrderFeeDetails: (d) => {
      return axios.post(serverBaseUrl + '/order/getOrderFeeDetails', d)
    },
    /**
     * 获取(客服中心-投诉中心)订单信息
     * @param {*} 
     */
    getOrderDetailsForCSC: (d) => {
      return axios.post(serverBaseUrl + '/order/getOrderDetailsForCSC', d)
    },
    /**
     * 获取投诉进度
     * @param {*} 
     */
    getComplaintProgress: (d) => {
      return axios.post(serverBaseUrl + '/system-config/getComplaintProgress', d)
    },
    /**
     * 获取异常订单确认
     * @param {*} 
     */
    getOrderAbnormal: (d) => {
      return axios.post(serverBaseUrl + '/order/getOrderAbnormal', d)
    },
    /**
     * 项目编号检索
     * @param {*} 
     */
    getProjects: (d) => {
      return axios.post(serverBaseUrl + '/system-config/getProjects', d)
    },
    /**
     * 异常确认
     * @param {*} 
     */
    setConfirmAbnormal: (d) => {
      return axios.post(serverBaseUrl + '/order/confirmAbnormal', d)
    },
    /**
     * 测试起点终点是否包含敏感字
     * @param {*} d 
     * @returns 
     */
    checkSensitiveWords: (d) => {
      return axios.post(serverBaseUrl + '/configuration/checkSensitiveWords', d)
    },
    /**
     * 金杜成本中心
     * @param {*} d 
     * @returns 
     */
    getJinDuCostCenter: (d) => {
      return axios.post(serverBaseUrl + '/cds-se/jindu/getCostCenter', d)
    },
    /**
     * 金杜审批人
     * @param {*} d 
     * @returns 
     */
    getJinDuApprover: (d) => {
      return axios.post(serverBaseUrl + '/cds-se/jindu/getApprover', d)
    },
    /**
     * 金杜获取案件
     * @param {*} d 
     * @returns 
     */
    getJinDuProject: (d) => {
      return axios.post(serverBaseUrl + '/cds-se/jindu/getProject', d)
    },
    /**
     * 金杜最后一次使用历史
     * @param {*} d 
     * @returns 
     */
    getLastSelect: (d) => {
      return axios.post(serverBaseUrl + '/cds-se/jindu/getLastSelect', d)
    },
    /**
     * 校验是否有支付订单
     * @param {*} d 
     * @returns 
     */
    useCarCheck: (d) => {
      return axios.post(serverBaseUrl + '/configuration/useCarCheck', d)
    },
    /**
     * 订单详情位置上报
     * @param {*} d 
     * @returns 
     */
    uploadLocationFeedback: (d) => {
      return axios.post(serverBaseUrl + '/order/uploadLocationFeedback', d)
    },
    /**
     * 获取用车理由
     * @param {*} d 
     * @returns 
     */
    getCancelReasons: (d) => {
      return axios.post(serverBaseUrl + '/configuration/getCancelReasons', d)
    },
    /**
     * 是否加价调度
     * @param {*} d 
     * @returns 
     */
    submitDispatchParam: (d) => {
      return axios.post(serverBaseUrl + '/order/submitDispatchParam', d)
    }
  },
  invoice: {
    /**
     * 待开票列表
     * @returns 
     */
    getInvoiceList: (d) => {
      return axios.post(serverBaseUrl + '/bill/Interactive/getInvoiceList', d);
    },
    /**
     * 已开票列表
     * @returns 
     */
    getHasInvoiceList: (d) => {
      return axios.post(serverBaseUrl + '/bill/Interactive/getHasInvoiceList', d);
    },
    /**
     * 发票公司列表
     * @returns 
     */
    getTitle: (d) => {
      return axios.post(serverBaseUrl + '/bill/Interactive/getTitle', { d });
    },
    /**
     * 提交发票信息
     * @returns 
     */
    sendWriteInvoice: (d) => {
      return axios.post(serverBaseUrl + '/bill/Interactive/submitInvoiceInfo', d);
    },
    /**
     * 获取发票详情
     * @returns 
     */
    getInvoiceDetails: (d) => {
      return axios.post(serverBaseUrl + '/bill/Interactive/getInvoiceDetails', d);
    },
    /**
     * 重新开票
     * @returns 
     */
    resendInvoice: (d) => {
      return axios.post(serverBaseUrl + '/bill/Interactive/resendInvoice', d);
    },
    /**
     * 获取开票行程列表
     * @returns 
     */
    getInvoiceTripList: (d) => {
      return axios.post(serverBaseUrl + '/bill/Interactive/getInvoiceTripList', d);
    },
    /**
     * 获取用户开票记录抬头
     * @returns 
     */
    getLastInvoiceApplyInfo: (d) => {
      return axios.post(serverBaseUrl + '/bill/interactive/getLastInvoiceApplyInfo', d);
    }
  },
  scene: {
    /**
     * 取得默认用车场景
     * @returns 
     */
    getDefaultScene: () => {
      return axios.post(serverBaseUrl + '/configuration/scene/getDefaultScene');
    },
    /**
     * 取得可用场景
     * @returns 
     */
    getSceneList: (d) => {
      return axios.post(serverBaseUrl + '/configuration/scene/getSceneList', d);
    },
    /**
         * 
         * 获取场景详情 
         * @returns 
         */
    getSceneDetails: (d) => {
      return axios.post(serverBaseUrl + '/configuration/scene/getSceneDetail', d);
    },
    /**
     * 获取用车报告场景
     * @returns 
     */
    getSceneEnableList: (d) => {
      return axios.post(serverBaseUrl + '/configuration/scene/getScene', d);
    },
    /**
     * 获取用车报告城市列表
     * @returns 
     */
    getCityList: (d) => {
      return axios.post(serverBaseUrl + '/configuration/carReport/getCityList', d);
    },
    /**
     * 获取车型列表
     * @returns 
     */
    getCarLevelList: () => {
      return axios.post(serverBaseUrl + '/configuration/scene/getCarLevelList');
    },
    /**
         * 获取可用总额度
         */
    getPersonalQuota: () => {
      return axios.post(serverBaseUrl + '/system/user/getPersonalQuota');
    }
  },
  approval: {
    /**
     * 审批列表待审核
     * @returns 
     */
    getApproval: (d) => {
      return axios.post(serverBaseUrl + '/order/getApplyList', d)
    },
    /**
     * 审批列表合计金额详情
     * @returns 
     */
    getApprovalUserTotalAmount: (d) => {
      return axios.post(serverBaseUrl + '/order/getApprovalSummary', d)
    },
    /**
     * 待审批拒绝
     * @returns 
     */
    setApprovalReject: (d) => {
      return axios.post(serverBaseUrl + '/order/rejectApply', d)
    },
    /**
     * 待审批通过
     * @returns 
     */
    setApprovalVerify: (d) => {
      return axios.post(serverBaseUrl + '/order/approveApply', d)
    }
  },
  user: {
    /**
     * 登录
     * @param {*} code 
     */
    login: (code) => {
      let uri = '';
      switch (oauthType) {
        case '1': {
          // 企业微信（第三方应用）
          uri = `/authentication/user/loginQyWeixinWithCode?appId=${appId}&code=${code}`;
          break;
        }
        case '2': {
          // 飞书商城
          uri = `/authentication/user/loginFeishuWithCode?appId=${appId}&code=${code}`;
          break;
        }
        case '11': {
          // 企业微信（自建应用）
          uri = `/authentication/user/loginQyWeixinWithCode?appId=${appId}&code=${code}`;
          break;
        }
        case '12': {
          // 微信服务号
          // uri = `/authentication/user/loginWeixinWithCode?appId=${appId}&code=${code}`;
          uri = `/authentication/user/loginFuWeixinWithCode?appId=${appId}&code=${code}`;
          break;
        }
        case '13': {
          // 钉钉自建应用
          uri = `/authentication/user/loginDingWithCode?appId=${appId}&code=${code}`;
          break;
        }
        case '18': {
          // 辉瑞
          uri = `/authentication/user/loginByPfizer?appId=${appId}&code=${code}`;
          break;
        }
        case '19': {
          // 赛诺菲
          uri = `/user/loginBySanofi?appId=${appId}&code=${code}`;
          break;
        }
        default: {
          uri = ''
        }
      }
      if (uri) {
        return axios.get(serverBaseUrl + uri)
      } else {
        return { data: { code: 1, message: '登录失败,无匹配的登录方式' } }
      }


    },
    /**
     * 石油商旅登录
     * @param {*} token 
     */
    loginByChinaPetro: (token) => {
      return axios.get(serverBaseUrl + '/authentication/user/loginWithToken?token=' + token)
    },
    /**
     * 中国移动登录
     * @param {*} token 
     */
    loginByCMCC: (token) => {
      return axios.get(serverBaseUrl + '/authentication/user/loginWithToken?token=' + token)
    },
    /**
     * token换satoken
     * @param {*} token 
     * @returns 
     */
    loginWithToken: (token) => {
      return axios.get(serverBaseUrl + '/authentication/user/loginWithToken?token=' + token)
    },
    /**
     * 小程序登录
     * @param {*} code 
     */
    loginMiniProgramWithCode: (code) => {
      return axios.get(serverBaseUrl + '/authentication/user/loginMiniProgramWithCode?code=' + code)
    },
    /**
     * 参天（E多）登录
     * @param {*} code 
     */
    loginWithUserIdAndCompanyId: (userId, companyId, originalUserId) => {
      let uri = `/authentication/user/loginWithUserIdAndCompanyId?userId=${userId}&companyId=${companyId}`;
      if (originalUserId) {
        uri += '&originalUserId=' + originalUserId
      }
      return axios.get(serverBaseUrl + uri)
    },
    /**
     * 加载EDoctor配置（jssdk用到）
     * @param {*} code 
     */
    loadEDoctorConfig: (companyId) => {
      return axios.get(serverBaseUrl + '/authentication/user/loadEDoctorConfig?companyId=' + companyId)
    },
    /**
     * iPath OpenApi 登录
     * @param {*} code 
     */
    loginByOpenApiToken: (token) => {
      return axios.get(serverBaseUrl + '/authentication/user/loginByOpenApiToken?token=' + token)
    },
    /**
     * 武田自建应用登录
     * @param {*} appId 
     * @param {*} code 
     * @returns 
     */
    loginByTakeda: (appId, code) => {
      return axios.get(serverBaseUrl + `/authentication/user/loginByTakeda?appId=${appId}&code=${code}`)
    },
    /**
     * 强生登录
     * @param {*} appId 
     * @param {*} code 
     */
    loginByJnj: (appId, code) => {
      return axios.get(serverBaseUrl + `/user/loginByJnj?appId=${appId}&code=${code}`)
    },
    /**
     * 强生有料
     * @param {*} appId 
     * @param {*} accessToken 
     * @returns 
     */
    loginByJnjApp: (appId, accessToken) => {
      return axios.post(serverBaseUrl + `/user/loginByJnjV2`, 
        {
          appId: appId, 
          accesstoken: accessToken
        })
    },
    getCode: () => {
      return axios.get(serverBaseUrl + '/user/getCode')
    },
    /**
     * 小程序登录
     * @param {*} d 
     */
    sendSMS: (d) => {
      return axios.post(serverBaseUrl + '/configuration/sms/sendCode', d)
    },
    /**
     * 发送邮箱验证码
     * @param {*} d 
     */
    sendEmailSMS: (email, captcha, captchaUuid) => {
      return axios.get(serverBaseUrl + `/user/getMsgCode?email=${email}&captcha=${captcha}&captchaUuid=${captchaUuid}`)
    },
    /**
     * 小程序首次登录
     * @param {*} d 
     */
    saveOpenIDBindPhone: (d) => {
      return axios.post(serverBaseUrl + '/authentication/user/saveOpenIDBindPhone', d)
    },
    /**
     * 邮箱首次登录
     * @param {*} d 
     */
    loginByEmail: (d) => {
      return axios.post(serverBaseUrl + '/user/loginByEmail', d)
    },
    /**
     * 个人设置获取
     * @returns 
     */
    getUserInfo: (d) => {
      return axios.post(serverBaseUrl + '/user/getUserInfo', d)
    },
    /**
     * 设置用户信息
     * @returns 
     */
    saveUserCenter: (d) => {
      return axios.post(serverBaseUrl + '/user/setUserInfo', d)
    },
    /**
     * 发送手机号、紧急联系人验证码
     * @returns 
     */
    sendVerificationCode: (d) => {
      return axios.post(serverBaseUrl + '/user/sendSMSCode', d)
    },
    /**
     * 验证手机号、紧急联系人验证码
     * @returns 
     */
    getCheckCode: (d => {
      return axios.post(serverBaseUrl + '/configuration/sms/checkCode', d)
    }),
    /**
     * 设置用户手机号、紧急联系人手机号
     * @returns 
     */
    setMobile: (d) => {
      return axios.post(serverBaseUrl + '/user/setMobile', d)
    },
    /**
     * 查询优惠券
     * @returns 
     */
    getCouponList: () => {
      return axios.post(serverBaseUrl + '/user/getCouponList');
    },
    /**
     * 接受用户协议
     * @returns 
     */
    setAgreement: (d) => {
      return axios.post(serverBaseUrl + '/user/setAgreement', d);
    },
    /**
     * 获取jssdk签名
     * @param {*} d 
     * @returns 
     */
    getConfigParams: (d) => {
      return axios.post(serverBaseUrl + '/user/getConfigParams', d);
    },
    /**
     * 获取企业抬头
     * @param {*} d 
     * @returns 
     */
    getInvoiceTitle: (d) => {
      return axios.post(serverBaseUrl + '/user/getInvoiceTitle', d);
    },
    /**
     * 企业账户充值保存协议
     * @param {*} d 
     * @returns 
     */
    setUsageProtocol: () => {
      return axios.post(serverBaseUrl + '/configuration/account/editDefaultUsageProtocol');
    },
    /**
     * 设置辉瑞用户知情同意书
     * @param {*} d 
     * @returns 
     */
    setConsentLetter: (d) => {
      return axios.post(serverBaseUrl + '/user/setConsentLetter', d);
    },
    /**
     * 设置强生软件使用协议
     * @param {*} d 
     * @returns 
     */
    setReadPrivacyAgreement: (d) => {
      return axios.post(serverBaseUrl + '/user/readPrivacyAgreement', d)
    },
    /**
     * 设置版本号
     * @param {*} d 
     * @returns 
     */
    setVersionNumber: (d) => {
      return axios.post(serverBaseUrl + '/user/versionNumber', d)
    },
    /**
     * 上传通讯录文件
     * @param {*} d 
     * @returns 
     */
    uploadVcfFile: (d) =>{
      return axios.post(serverBaseUrl + '/user/uploadVcfFile', d)
    },
  /**
     * 获取通讯录
     * @param {*} d 
     * @returns 
     */
    getUserAddressBook: (d) => {
      return axios.post(serverBaseUrl + '/user/getUserAddressBook', d)
    },
    /**
     * 获取火车票跳转地址
     * @param {*} d 
     * @returns 
     */
    getTravelTrainUrl: (d) => {
      return axios.get(serverBaseUrl + '/user/redirectUrlInfoByZh');
    },
    /**
     * 上报前端埋点数据
     * @param {*} d 
     */
    saveUserLog: (d) => {
      return axios.post(serverBaseUrl + '/user/saveUserLog', d);
    },
    /**
     * 查询京东校园用户认证
     * @param {*} d 
     */
    getJdStudents: () => {
      return axios.get(serverBaseUrl + '/authentication/user/jdStudents');
    },
  },
  report: {
    /**
     * 订单及消费金额用车报告
     * @returns 
     */
    getCompanyOrderReport: (d) => {
      return axios.post(serverBaseUrl + '/configuration/carReport/getCompanyOrderReport', d)
    },
    /**
     * 用车场景的单量及金额分布
     * @returns 
     */
    getSceneOrderCountAmountReport: (d) => {
      return axios.post(serverBaseUrl + '/configuration/carReport/getSceneOrderCountAmountReport', d)
    },
    /**
     * 车型单量及金额分布
     * @returns 
     */
    getCompanyCarLevelsOrderQtyAmountReport: (d) => {
      return axios.post(serverBaseUrl + '/configuration/carReport/getCompanyCarLevelsOrderCountAmountReport', d)
    }
  },
  account: {
    /**
     * 获取账户基本信息
     * @returns 
     */
    getBaseInfo: () => {
      return axios.post(serverBaseUrl + '/configuration/account/getBaseInfo');
    },
    /**
     * 获取企业账户交易记录
     * @param {*} d 
     * @returns 
     */
    getAccount: (d) => {
      return axios.post(serverBaseUrl + '/configuration/account/getAccount', d);
    },
    /**
     * 企业充值
     * @param {*} d 
     * @returns 
     */
    charge: (d) => {
      return axios.post(serverBaseUrl + '/bill/Interactive/Charge', d);
    }
  },
  promotion: {

    /**
     * 新用户活动
     * @returns 
     */
    searchNewUser: () => {
      return axios.post(serverBaseUrl + '/couponconsume/promotion/searchNewUser')
    },

    /**
     * 查询活动
     * @returns 
     */
    getPromotion: (d) => {
      return axios.post(serverBaseUrl + '/couponconsume/promotion/search', d)
    },

    /**
     * 执行活动请求
     * @param {*} serverPath 
     * @param {*} data
     * @returns 
     */
    exec: (serverPath, data) => {
      let isAbs = serverPath.indexOf('http') === 0;
      if (isAbs) {
        return axios.post(serverPath, data)
      } else {
        return axios.post(`https://${gatewayDomain}${serverPath}`, data)
      }

    },

    /**
     * 获取广告位
     * @returns 
     */
    queryUserAD: () => {
      return axios.post(serverBaseUrl + '/agent/ad/advert/queryuserad')
    },
    /**
     * 上报广告位展示
     * @returns 
     */
    insertADRecord: (d) => {
      return axios.post(serverBaseUrl + '/agent/ad/advert/insertadrecord', d)
    },
    /**
     * 上报广告位点击
     * @returns 
     */
    updateADRecord: (d) => {
      return axios.post(serverBaseUrl + '/agent/ad/advert/updateadrecord', d)
    },
    /**
     * 获取公告栏
     * @returns 
     */
    getAnnouncements: (d) => {
      return axios.post(serverBaseUrl + '/agent/ad/an/getannouncementslang', d)
    },

  },
  preApproval: {
    /**
     * 用户端获取企业场景配置项
     * @param {*} d 
     * @returns 
     */
    queryPreApprovalSetList: (d) => {
      return axios.post(serverBaseUrl + '/system/preApproval/queryPreApprovalSetList', d)
    },
    /**
     * 提交行前申请
     * @param {*} d 
     * @returns 
     */
    preDepartApply: (d) => {
      let uri = d.preDepartApplyId ? '/system/preApproval/resubmit' : '/system/preApproval/submit'
      return axios.post(serverBaseUrl + uri, d)
    },
    /**
     * 重新提交申请
     * @param {*} d 
     * @returns 
     */
    updatePreDepartApply: (d) => {
      return axios.post(serverBaseUrl + '/system/preApproval/resubmit', d)
    },
    /**
     * 用户获取所有行前申请列表
     * @param {*} d 
     * @returns 
     */
    queryPreDepartApplyList: (d) => {
      return axios.post(serverBaseUrl + '/system/preApproval/queryPreDepartApplyList', d)
    },
    /**
     * 用户叫车获取行前可用申请列表
     * @param {*} d 
     * @returns 
     */
    queryUsableApplyList: (d) => {
      return axios.post(serverBaseUrl + '/system/preApproval/queryUsableApplyList', d)
    },
    /**
     * 审批人获取自己审批的行前申请列表
     * @param {*} d 
     */
    queryApplyListByApprover: (d) => {
      return axios.post(serverBaseUrl + '/system/preApproval/queryApplyListByApprover', d)
    },
    /**
     * 审批人审批行前申请
     * @param {*} d 
     */
    approvePreDepartApply: (d) => {
      return axios.post(serverBaseUrl + '/system/preApproval/approve', d)
    }
  },
  habitAddress: {
    /**
     * 用户常用地址
     * @param {*} d 
     * @returns 
     */
    getUserCommonAddress: () => {
      return axios.post(serverBaseUrl + '/user/getUserCommonAddress', {})
    },
    /**
     * 设置用户常用地址
     * @param {*} d 
     * @returns 
     */
    setUserCommonAddress: (d) => {
      return axios.post(serverBaseUrl + '/user/insertUserCommonAddress', d)
    },
    /**
    * 清除用户常用地址
    * @param {*} d 
    * @returns 
    */
    deleteUserCommonAddress: (d) => {
      return axios.post(serverBaseUrl + '/user/deleteUserCommonAddress', d)
    }
  },
  special: {
    costCenter: {
      getUserProjects: () => {
        return axios.post(serverBaseUrl + '/system/project/getUserProjects')
      },
      getCompanyProjects: (key) => {
        return axios.post(serverBaseUrl + '/system/project/getCompanyProjects', {
          key
        })
      },
      addUserProject: (projectId) => {
        return axios.post(serverBaseUrl + '/system/project/addUserProject', {
          projectId
        })
      },
      setDefaultProject: (projectId) => {
        return axios.post(serverBaseUrl + '/system/project/setDefaultProject', {
          projectId
        })
      },
      deleteUserProject: (projectId) => {
        return axios.post(serverBaseUrl + '/system/project/deleteUserProject', {
          projectId
        })
      },
    },
    meeting: {
      getPoBindInfo: () => {
        return axios.post(serverBaseUrl + '/system/amgen/getPoBindInfo')
      },
      setSceneBindPo: (d) => {
        return axios.post(serverBaseUrl + '/system/amgen/sceneBindPo', d)
      },
      getPoCostByScene: (d) => {
        return axios.post(serverBaseUrl + '/system/amgen/getPoCostByScene', d)
      }
      // updateScenePo: (d) => {
      //   return axios.post(serverBaseUrl + '/system/amgen/updateScenePo', d)
      // },
      // isPoSceneExist: (d) => {
      //   return axios.post(serverBaseUrl + '/system/amgen/isPoSceneExist', d)
      // }
    },
    sanofiMeeting: {
      // 获取个人会议列表信息
      getPersonalMeetingList: () => {
        return axios.get(serverBaseUrl + '/order/meeting/getPersonalMeetingList')
      },
      // 一键删除
      oneClickDeletion: () => {
        return axios.post(serverBaseUrl + '/order/meeting/oneClickDeletion')
      },
      /**
      * 删除个人会议
      * @param {meetingId} d 
      * @returns 
      */
      deletePersonalMeeting: (d) => {
        return axios.post(serverBaseUrl + '/order/meeting/delete', d)
      },
      /**
      * 添加个人会议
      * @param {meetingId} d 
      * @returns 
      */
      addPersonalMeeting: (d) => {
        return axios.post(serverBaseUrl + '/order/meeting/saveMeetingPersonal', d)
      },
      /**
      * 获取常用会议
      * @param {param} d 
      * @returns 
      */
      getCommonlyUsedMeeting: (d) => {
        return axios.post(serverBaseUrl + '/order/meeting/getCommonlyUsedMeeting', d)
      },
      /**
      * 添加io-获取会议列表
      * @param {param} d 
      * @returns 
      */
      getIOMeetingList: (d) => {
        return axios.post(serverBaseUrl + '/order/meeting/meetingList', d)
      },
    }
  },
  logger: {
    report: (keyword, category, subCategory, content) => {
      let req = {
        source: `mobile-h5-${timestamp}-${window.satoken}`,
        keyword,
        category,
        subCategory,
        content,
        datetime: new Date()
      };
      return axios.post(serverBaseUrl + '/authentication/logger/report', req)
    },
    /**
     * 上报日志
     * @param {*} level 
     * @param {*} category 
     * @param {*} subCategory 
     * @param {*} content 
     * @returns 
     */
    save: (level, category, subCategory, content) => {
      let req = {
        source: `mobile-h5`,
        sessionId: timestamp,
        satoken: window.satoken,
        uri: window.location.hash,
        level: level,
        category,
        subCategory,
        content,
        datetime: new Date()
      };

      return axios.post(serverBaseUrl + '/frontEndLoger/logger/save', req)
    },

    /**
    * @param {isDevice} 宿主手机
    * @param {dge} 应用环境
    * @param {timestamp} 当前时间
    * @param {appId} 应用appid
    * @param {oauthType} 企业
    * @param {satoken} 用户身份
    * @param {level} 日志级别 
    * @param {logType} 日志分类
    * @param {page} 页面
    * @param {category} 功能项
    * @param {subCategory} 功能点
    * @param {content} 记录内容
    */
    reportLog: (uri, level, category, subCategory, content) => {
      let req = {
        source: `mobile-h5`,
        sessionId: timestamp,
        satoken: window.satoken,
        uri,
        level,
        category,
        subCategory,
        content,
        datetime: new Date()
      };
      return axios.post(serverBaseUrl + '/authentication/logger/report', req)
    }
  },
  ziru: {
    getPayUrl: (orderId) => {
      return axios.post(serverBaseUrl + '/order/pay/getCashDeskUrl', {orderId: orderId})
    }
  }
}

const dingLogin = async () => {
  let corpId = appId.split('@')[1]
  return new Promise((ok, onerr) => {
    loger.info('登录', '钉钉登录', 'dingLogin') 
    dd.ready(() => {
      loger.info('登录', '钉钉登录', 'ready') 
      dd.runtime.permission.requestAuthCode({
        corpId: corpId, // 企业id
        onSuccess: async (info) => {
          let code = info.code // 通过该免登授权码可以获取用户身份
          let res = await webService.user.login(code);
          ok(res)
        },
        onFail: (err) => {
          alert('授权失败，请向管理员确认您是否具备此应用的使用权限' + JSON.stringify(err))
          let errMsg = `渠道${oauthType} appId${appId} 调用 dd.runtime.permission.requestAuthCode 失败 ${JSON.stringify(err)}`
          console.log(errMsg)
          loger.error('登录', '钉钉登录失败', errMsg, err) 
          onerr('授权失败，请向管理员确认您是否具备此应用的使用权限')
        }
      });
    });
  })
  
}

export { relogin, axios, addCachePoi, findCachePoi, dingLogin };
export default webService;

