import React from 'react';
import style from './qqmaptest.scss';

import QQMap from './../../component/utils/qqMap'

class QQMapTest extends React.Component {

    state = {

    }

    qqMapRef = undefined;


    render() {
        return <div className={style.container}>
            <QQMap className={style.qqmap} ref={(dom) => {this.qqMapRef = dom;}}/>
        </div>
    }

    componentDidMount() {
        this.qqMapRef.initMap([121.523175, 38.861572])
    }

}

export default QQMapTest;