
import webService from "./webService"

const CRITICAL = 5
const ERROR = 4
const WARNING = 3
const TRACK = 2.5
const INFO = 2
const DEBUG = 1

const DEFAULT_LEVEL = 0
let level = DEFAULT_LEVEL

const getLevel = () => {
  let l = sessionStorage.getItem('LOGLEVEL')
  if (l && Number(l) > 0) {
    return Number(l)
  }

  return 0
}

const getLevelName = (level) => {

  switch (level) {
    case CRITICAL:
      return 'CRITICAL'
    case ERROR:
      return 'ERROR'
    case WARNING:
      return 'WARNING'
    case TRACK:
      return 'TRACK'
    case INFO:
      return 'INFO'
    case DEBUG:
      return 'INFO'
    default:
      return 'UNKNOWN'
  }

}

const setLevel = (l) => {
  console.log(`设置日志等级${l}`)
  sessionStorage.setItem('LOGLEVEL', l)
  level = getLevel() || DEFAULT_LEVEL
}

/**
 * 将前端日志传到服务端
 * @param {*} level 日志级别
 * @param {*} category 功能一级分类
 * @param {*} subCategory 功能二级分类
 * @param  {...any} message 日志内容，支持段数据
 */
const saveToServer = async (level, category, subCategory, ...message) => {
  try {
    let content = ''
    const levelName = getLevelName(level)
    if (message && message.length) {
      if (message.length === 1 && typeof (message[0]) === 'string') {
        content = message[0]
      } else {
        content = JSON.stringify(message)
      }
    }
    await webService.logger.save(levelName, category, subCategory, content)
  } catch (exp) {
    console.log('%cERROR', 'background-color:#000;color:#FFF', '上报异常失败', exp)
  }
}

const debug = (...message) => {
  if (level > DEBUG) {
    return
  }
  console.log('%cDEBUG', 'background-color:green;color:#FFF', ...message)
  saveToServer(DEBUG, 'DEBUG', '', ...message)
}

/**
 * 写info级别日志
 * @param {*} category 功能一级分类
 * @param {*} subCategory 功能二级分类
 * @param  {...any} message 日志内容，支持段数据
 * @returns 
 */
const track = (category, subCategory, ...message) => {
  console.log('%cTRACK', 'background-color:green;color:#FFF', ...message)
  saveToServer(TRACK, category, subCategory, ...message)
}

/**
 * 写info级别日志
 * @param {*} category 功能一级分类
 * @param {*} subCategory 功能二级分类
 * @param  {...any} message 日志内容，支持段数据
 * @returns 
 */
const info = (category, subCategory, ...message) => {
  if (level > INFO) {
    return
  }
  console.log('%cINFO', 'background-color:green;color:#FFF', ...message)
  saveToServer(INFO, category, subCategory, ...message)
}

const warning = (category, subCategory, ...message) => {
  if (level > WARNING) {
    return
  }
  console.log('%cWARNING', 'background-color:#ffc000;color:#FFF', ...message)
  saveToServer(WARNING, category, subCategory, ...message)
}

const error = (category, subCategory, ...message) => {
  if (level > ERROR) {
    return
  }
  console.log('%cERROR', 'background-color:red;color:#FFF', ...message)
  saveToServer(ERROR, category, subCategory, ...message)
}

const critical = (category, subCategory, ...message) => {
  if (level > CRITICAL) {
    return
  }
  console.log('%cCRITICAL', 'background-color:#000;color:#FFF', ...message)
  saveToServer(CRITICAL, category, subCategory, ...message)
}

level = getLevel() || DEFAULT_LEVEL

const _export = {
  setLevel,
  debug,
  info,
  track,
  warning,
  error,
  critical,
  
  get level() {
    return level
  }
}

export default _export