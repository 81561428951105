import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom'
import {inject, observer } from 'mobx-react'

import { setWebViewTitle } from './../../util/webViewHelper'

import {relogin} from './../../util/webService'
import loger from './../../util/loger'

@inject('globalStore')
@observer
class AppRouter extends React.Component {

    routes = this.props.routes;
    globalStore = this.props.globalStore;

    render() {
        return <>
            <Switch>
                {
                    this.routes.map(item => {
                        return <Route key={item.path} render={props => {                            
                            if (!item.isPub) {
                                if (this.globalStore.loginState === 0) {

                                    // 如果存在自定义重定向地址，则重定向否则触发登录逻辑
                                    if (item.path.startsWith('/share/')) {
                                        let _params = window.location.hash.split('/')
                                        let newUri = '/watchShare/' + _params[_params.length - 1]
                                        return <Redirect to={newUri} />
                                    }
                                    // 未登录，重定向登录
                                    let hash = window.location.hash;
                                    if (window.startRelogin) {
                                        // 避免重复触发重新登录
                                        return
                                    }
                                    setTimeout(() => {
                                        relogin(hash);
                                    }, 500);
                                    window.startRelogin = true
                                    loger.debug('relogin')
                                    return;
                                } else if (this.globalStore.loginState === 2) {
                                    // 登录失败-无用车权限
                                    return <Redirect to='/noauth' />
                                } else if (this.globalStore.loginState === 4) {
                                    // 登录超时-网络或服务接口故障
                                    return <Redirect to='/404' />
                                } else {
                                    if (item.path === '/' && this.globalStore.userInfo.disable) {
                                        // 限制用车
                                        return <Redirect to='/limitedTimeDisable' />
                                    }
                                }
                            } 
                            
                            setWebViewTitle(item.title || '')                            
                            return <item.component {...props} router={item} ></item.component>
                        }} path={`${this.props.baseUri ? this.props.baseUri : ''}${item.path}`} ></Route>
                    })
                }
            </Switch>
        </>
    }
}

export default AppRouter;