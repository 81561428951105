import React from 'react';
import style from './callcar.scss'
import { inject, observer } from 'mobx-react'

import { Button, Dialog } from 'antd-mobile'

import ServerType from './servertype'
import RuningComp from './runingcomp'
import CarSence from './carsence'
import PreApproval from './preApproval'
import DepartureTime from './departuretime'
import BeginAddr from './beginaddr'
import Pathway from './pathway'
import EndAddr from './endaddr'
import Flight from './flight'
import Train from './Train'
import CharteredBus from './charteredBus'

import gc from './../../util/globalConfiguration'
import loger from './../../util/loger'

import { generateEstimate } from './../../util/paramsHelper'

// import { withTranslation } from "react-i18next";

import webService from './../../util/webService'
import { getPosition4Element } from './../../util/geo'

import VerifyBaseLimitation from './util/verifyBaseLimitation'
import { checkSensitiveWords } from './util/checkSensitiveWords'

import MessageBox from './messageBox'
// import moment from 'moment';

@inject('mainStore', 'estimateStore', 'globalStore')
@observer
class CallCar extends React.Component {

    state = {}
    globalStore = this.props.globalStore;
    mainStore = this.props.mainStore;
    estimateStore = this.props.estimateStore;

    addressRef = undefined;
    verifyBaseLimitationRef = undefined;

    messageBoxRef = undefined
    t = this.props.t

    // base 地 check 开关
    get isVerifyBaseLimitation() {
        return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.main_base_check ? true : false
    }

    // 验证起点终点敏感字
    get isCheckSensitiveWords() {
        return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.main_base_check ? true : false
    }

    get serverTypes() {
        let sceneServerTypes = this.mainStore.scene ? this.mainStore.scene.serverTypes : []
        if (sceneServerTypes && sceneServerTypes.length) {
            return this.globalStore.companyInfo.serverTypes.filter(a => sceneServerTypes.includes(a))
        } else {
            return this.globalStore.companyInfo.serverTypes
        }
    }

    render() {
        return <div className={style.container}>
            <MessageBox ref={(dom) => { this.messageBoxRef = dom; }}></MessageBox>
            {
                (() => {
                    if (this.mainStore.activeOrder.length > 0) {
                        return <RuningComp t={this.props.t} history={this.props.history} activeOrder={this.mainStore.activeOrder}></RuningComp>
                    }
                })()
            }

            <ServerType t={this.props.t} changeServerType={this.props.changeServerType} />
            <div className={style.body}>
                {/* 用车场景 */}
                {
                    (() => {
                        if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.main_scene) {
                            return <CarSence t={this.props.t} history={this.props.history} onChange={this.ready} />
                        }
                    })()
                }
                {/* 包车 */}
                {
                    (() => {
                        if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.main_chartered_bus && this.mainStore.serverType === 20) {
                            return <CharteredBus t={this.props.t} history={this.props.history} onChange={this.ready}></CharteredBus>
                        }
                    })()
                }
                {/* 申请单 */}
                {
                    (() => {
                        if (this.mainStore.scene && this.mainStore.scene.needPreApproval) {
                            return <PreApproval t={this.props.t} history={this.props.history} onChange={this.ready} />
                        }
                    })()
                }
                {/* 预约时间 */}
                {
                    this.mainStore.serverType === 2 || this.mainStore.serverType === 20 ?
                        <DepartureTime language={this.globalStore.userInfo.language} t={this.props.t} onChange={this.ready} /> : undefined
                }
                {/* 起点 */}
                {
                    (() => {
                        if (this.mainStore.serverType !== 6 && this.mainStore.serverType !== 7) {
                            return <BeginAddr t={this.props.t} ref={(dom) => { this.addressRef = dom; }} moveMap={this.props.moveMap} onChange={() => { }} history={this.props.history} />
                            // onChange={this.ready}
                        }
                    })()
                }
                {/* 航班号 */}
                {
                    (() => {
                        if (this.mainStore.serverType === 6) {
                            return <Flight t={this.props.t} history={this.props.history} moveMap={this.props.moveMap} onChange={() => { }} />
                            // onChange={this.ready}
                        }
                    })()
                }
                {/**列车号 */}
                {
                    (() => {
                        if (this.mainStore.serverType === 7) {
                            return <Train t={this.props.t} history={this.props.history} moveMap={this.props.moveMap} onChange={() => { }} />
                        }
                    })()
                }
                {/* 途径点 */}
                {
                    (() => {
                        return <Pathway t={this.props.t} isFinishEnd={undefined} history={this.props.history} onChange={this.ready} />
                    })()
                }
                {/* 终点 */}
                {
                    (() => {
                        if (this.mainStore.serverType !== 5 && (this.mainStore.serverType !== 6 || (this.mainStore.serverType === 6 && this.mainStore.beginAddr.flightNumber))) {
                            return <EndAddr moveMap={this.props.moveMap} t={this.props.t} isFinishEnd={this.props.isFinishEnd} history={this.props.history} />
                        }
                        // onChange={this.ready}
                    })()
                }
                {/* 确认下单 */}
                {
                    (() => {
                        if (this.showOrderButton) {
                            return <div>
                                <Button block color='primary' shape='rectangular' onClick={() => { this.ready(true) }}>去打车</Button>
                            </div>
                        }
                    })()
                }

                {/* <div>
                    <button type='button' onClick={() => {this.messageBoxRef.show('https://www.baidu.com')}}>测试按钮</button>
                </div> */}
            </div>
            <VerifyBaseLimitation ref={(dom) => { this.verifyBaseLimitationRef = dom }} t={this.t}></VerifyBaseLimitation>
        </div>
    }

    get showOrderButton() {
        let mainParameter = this.mainStore.mainParameter
        if (mainParameter) {
            return gc.process.showOrderButton && mainParameter.isConfirm
        } else {
            return false;
        }
        // return mainParameter 
        //     ? gc.process.showOrderButton && mainParameter.isConfirm
        //     : gc.process.showOrderButton
        // 只要是这类企业，不论行程参数是什么，都显示打车按钮
        // return gc.process.showOrderButton
    }

    componentDidMount = async () => {
        let defScene = undefined;

        let scene = this.globalStore.userInfo.scene
        if (scene && scene.id) {
            defScene = scene
        } else {
            let {data} = await webService.scene.getDefaultScene()
            if (data.data && data.data.scene && data.data.scene.id) {
                defScene = data.data.scene
            }
        }
        
        if (defScene) {
            this.mainStore.setDefaultScene(defScene)
        }

        // 无默认场景值&切换页面清空场景值
        if (this.globalStore.userInfo.permissions?.main_no_def_scene) {
            this.mainStore.setScene({})
        }

        this.mainStore.chooseServerType(this.serverTypes[0]);

        // webService.scene.getDefaultScene().then((d) => {
        //     if (d.data.data && d.data.data.scene && d.data.data.scene.id) {
        //         // d.data.data.scene.readonly = true  // 只读场景，不允许用户切换
        //         this.mainStore.setDefaultScene(d.data.data.scene)
        //     }

        // });

        webService.order.getRuningOrders().then((d) => {
            if (d.data && d.data.data && d.data.data.orders) {
                this.mainStore.setActivityInfo(d.data.data.orders)
            }
        });

        this.mainStore.init();
    }

    latlngEq = (p1, p2, threshold = 0.000001) => {
      let res = Math.abs(Number(p1?.lat) - Number(p2?.lat)) <= threshold
        && Math.abs(Number(p1?.lon) - Number(p2?.lon)) <= threshold
      return res
    }

    /**
     * 供父层组件调用初始化方法
     * @param {*} locationGeo 
     */
    init = async (locationGeo) => {
        let c5;
        try {
            c5 = await getPosition4Element({ lon: locationGeo.lon, lat: locationGeo.lat })
        } catch (err) {
            console.log(err)
        }
        // TODO: 泛嘉四期设置起点地址名称
        if (this.latlngEq(locationGeo, this.mainStore?.mainParameter?.depart) && this.mainStore?.mainParameter?.depart?.name) {
          c5.name = this.mainStore.mainParameter.depart.name;
          c5.address = this.mainStore.mainParameter.depart.name;
        }
        if (c5.address && c5.name && c5.lat && c5.lon) {
            let cityCode = c5.cityCode;
            let cityName = c5.cityName;
            let name = locationGeo.name || c5.name;
            let address = locationGeo.address || c5.address;
            let poi = locationGeo.poi || c5.poi;

            this.mainStore.setParking({
                isFinishEnd: this.props.isFinishEnd,
                name: name || address,
                lon: locationGeo.lon,
                lat: locationGeo.lat,
                poi: poi
            });
            this.mainStore.setBeginValidationed(false)

            this.mainStore.setCurrentCity({
                address: address,
                cityCode: cityCode,
                cityName: cityName
            });
        } else {
            this.mainStore.setNameAddrEmpty({ name: this.t('main.k0155'), address: this.t('main.k0155') })
        }
    }

    /**
     * 判断用车参数是否集齐
     * 是则进入下一步
     * 否则酌情提示
     */
    ready = async (isManualOperated) => {
        loger.debug('ready')

        // let isConfirm = this.mainStore.mainParameter ? this.mainStore.mainParameter.isConfirm : true

        if (this.showOrderButton && !isManualOperated /*&& isConfirm*/) {
            // 显示去下单按钮的企业，自动触发时候，如果手动确认标记是真，则阻止后续逻辑
            return
        }

        if (!this.mainStore.endAddr.name) {
            // 未选择终点,不做判定
            return;
        }

        // 途径点是否填写完整
        let completePathWay = this.mainStore.pathways && (this.mainStore.pathways.length === 0 || (this.mainStore.pathways.length && this.mainStore.pathways.every(a => a.lat)))
        if (!completePathWay) {
            // 途径点填写不完整,不做判定
            return;
        }

        if (!this.mainStore.beginAddr.lon && !this.mainStore.beginAddr.lat) {
            return;
        }

        if (!this.mainStore.endAddr.lon && !this.mainStore.endAddr.lat) {
            return;
        }

        if (!this.mainStore.scene.id) {
            Dialog.alert({
                content: this.t('main.k019'),
                confirmText: this.t('common.k003')
            })
            return;
        }

        if (this.mainStore.scene.needPreApproval && !this.mainStore.preApply.preDepartApplyId) {
            Dialog.alert({
                content: this.t('preApproval.k002'), // 请选择申请单
                confirmText: this.t('common.k003')
            })
            return;
        }
        switch (this.mainStore.serverType) {
            case 1:
                // 实时订单
                if (!this.mainStore.beginAddr.name) {
                    Dialog.alert({
                        content: this.t('main.k020'),
                        confirmText: this.t('common.k003')
                    })
                    return;
                }
                break;
            case 2:
                // 预约
                if (!this.mainStore.beginAddr.departureTime) {
                    Dialog.alert({
                        content: this.t('main.k021'),
                        confirmText: this.t('common.k003')
                    })
                    return;
                }
                if (!this.mainStore.beginAddr.name) {
                    Dialog.alert({
                        content: this.t('main.k020'),
                        confirmText: this.t('common.k003')
                    })
                    return;
                }
                break;
            case 6:
                if (!this.mainStore.beginAddr.flightNumber) {
                    Dialog.alert({
                        content: this.t('main.k022'),
                        confirmText: this.t('common.k003')
                    })
                    return;
                }
                break;
            case 7:
                if (!this.mainStore.beginAddr.trainNumber) {
                    Dialog.alert({
                        // 请选择车次
                        content: this.t('main.k0170'),
                        confirmText: this.t('common.k003')
                    })
                    return;
                }
                break;
            case 20:
                // 预约
                if (!this.mainStore.beginAddr.departureTime) {
                    Dialog.alert({
                        content: this.t('main.k021'),
                        confirmText: this.t('common.k003')
                    })
                    return;
                }
                if (!this.mainStore.charteredBusType) {
                    Dialog.alert({
                        content: this.t('main.k0143'),
                        confirmText: this.t('common.k003')
                    })
                    return;
                }
                break;
            default:
                Dialog.alert({
                    content: this.t('main.k023'),
                    confirmText: this.t('common.k003')
                })
                return;
        }

        if (this.globalStore.companyInfo.needUseCarToken && !this.globalStore.allowUseCar) {
            Dialog.alert({
                // 当前无用车权限
                content: this.t('main.k0133'),
                confirmText: this.t('common.k003')
            })
            return;
        }

        loger.debug('isVerifyBaseLimitation', this.isVerifyBaseLimitation)
        // 判断是否需要进行base地判定
        if (this.isVerifyBaseLimitation) {
            let addr = {
                begin: {
                    cityCode: this.mainStore.beginAddr.cityCode,
                    cityName: this.mainStore.beginAddr.cityName,
                    lat: this.mainStore.beginAddr.lat,
                    lon: this.mainStore.beginAddr.lon
                },
                end: {
                    cityCode: this.mainStore.endAddr.cityCode,
                    cityName: this.mainStore.endAddr.cityName,
                    lat: this.mainStore.endAddr.lat,
                    lon: this.mainStore.endAddr.lon
                }
            }

            //let res = await verifyBaseLimitation({ sceneId: this.mainStore.scene.id, begin: addr.begin, end: addr.end })
            let res = await this.verifyBaseLimitationRef.check({ sceneId: this.mainStore.scene.id, begin: addr.begin, end: addr.end })
            loger.debug('base地验证结果', res)
            if (res) {
                if (res.action === 0) {
                    this.mainStore.clearBegin('请选择出发地')
                    this.mainStore.clearEnd()
                    return
                } else if (res.action === 1) {
                    this.mainStore.setBeginValidationed(true)
                    this.mainStore.setEndValidationed(true)
                    if (res.data) {
                        loger.debug(res.data)
                        this.mainStore.setBaseLimitationConfirm(res.data)
                    }
                }
            }
        }

        loger.debug('isCheckSensitiveWords')
        // 关键字判断
        if (this.isCheckSensitiveWords) {
            let res = await checkSensitiveWords({
                sceneId: this.mainStore.scene.id,
                pickupLocationName: this.mainStore.beginAddr.name,
                destLocationName: this.mainStore.endAddr.name
            })

            if (res && res.length) {
                if (res.includes('pickup')) {
                    this.mainStore.clearBegin('请选择出发地')
                }
                if (res.includes('dest')) {
                    this.mainStore.clearEnd()
                }
                return
            }
        }

        // // 判断是否在不能叫车的位置
        // let listDisable = ['上海浦东机场']
        // let fencesRes = await webService.map.gethitFences(this.mainStore.beginAddr.lat, this.mainStore.beginAddr.lon, this.globalStore.userInfo.id, moment().format('X'));
        // if (fencesRes.status === 200)
        // {
        //     let hit_hub_of_traffic = fencesRes.data && fencesRes.data.data && fencesRes.data.data.detail ? fencesRes.data.data.detail.hit_hub_of_traffic : 0
        //     if (hit_hub_of_traffic === 1)
        //     {
        //         let fencesName = fencesRes.data.data.detail.data.name
        //         if (listDisable.includes(fencesName))
        //         {
        //             Dialog.alert({
        //                 content: '尊贵的用户，非常抱歉，为落实上海市相关规定要求，维护春运期间浦东机场周边交通秩序，即日起暂不支持在上海浦东国际机场呼叫网约车，抵达上海浦东国际机场的乘客请乘坐其他交通工具出行，给您带来不便深表歉意。',
        //                 confirmText: '我知道了',
        //                 onClose: () => {
        //                   this.mainStore.clearEnd()
        //                 }
        //             })
        //             return;
        //         }
        //     }
        // }


        // 服务端校验能否进行预估价格
        let cep = generateEstimate(this.mainStore.serverType, this.mainStore.scene, this.mainStore.beginAddr, this.mainStore.endAddr, undefined, this.mainStore.preApply, this.mainStore.charteredBusType, this.mainStore.pathways);
        webService.order.preCheck(cep).then((d) => {
          if (d.data.data?.isAbnormalTip) {
              Dialog.alert({
                  // 您有异常行程待确认，请确认后再用车。
                  content: this.t('main.k0171'),
                  // 前往确认
                  confirmText: this.t('main.k0172'),
                  onClose: () => {
                      this.props.history.push(`/orderAbnormal/${d.data.data.orderId}`)
                  }
              })
          } else if (d.data.code && !d.data.data?.isAbnormalTip) {
              Dialog.alert({
                  content: d.data.message,
                  confirmText: this.t('common.k003'),
                  onClose: () => {
                    this.mainStore.clearEnd()
                  }
              })
          } else {
                // 跳转到预约界面
                setTimeout(() => {
                    this.estimateStore.clear();
                    this.props.history.push('/estimate');
                }, gc.process.isFanJia ? 2000 : 500);

            }
        });
    }

}

export default CallCar;