import React from 'react';

import QQMap from './qqMap'
import AMap from './aMap'

class IPathMap extends React.Component {

    mapRef = undefined;

    t = this.props.t;

    render() {
        const { children } = this.props;
        const lang = this.t('main.k000')
        return lang !== 'en' ? <QQMap ref={(dom) => { this.mapRef = dom }} {...this.props}>{children}</QQMap>
            : <AMap ref={(dom) => { this.mapRef = dom }} {...this.props} t={this.props.t}>{children}</AMap>
    }

    getMapRef() {
        return this.mapRef;
    }

}

export default IPathMap;
