
class CoordHelper {
    static x_pi = 3.14159265358979324 * 3000.0 / 180.0
    static pi = 3.1415926535897932384626                // π
    static a = 6378245.0                                // 长半轴
    static ee = 0.00669342162296594323                  // 扁率
    static g2w_precision = 0.1 / 111391.0               // 精度控制（最大误差）


    static wgs84togcj02(wgs) {
        let { lng, lat } = wgs
        lng = Number(lng)
        lat = Number(lat)
        let dlat = this.transformlat(lng - 105.0, lat - 35.0)
        let dlng = this.transformlng(lng - 105.0, lat - 35.0)
        let radlat = lat / 180.0 * this.pi
        let magic = Math.sin(radlat)
        magic = 1 - this.ee * magic * magic
        let sqrtmagic = Math.sqrt(magic)
        dlat = (dlat * 180.0) / ((this.a * (1 - this.ee)) / (magic * sqrtmagic) * this.pi)
        dlng = (dlng * 180.0) / (this.a / sqrtmagic * Math.cos(radlat) * this.pi)
        let mglat = lat + dlat
        let mglng = lng + dlng

        return { 
            lat: this.toFixed(mglat),
            lng: this.toFixed(mglng)
        }
    }

    static gcj02towgs84_rough(gcj) {
        let { lng, lat } = gcj
        lng = Number(lng)
        lat = Number(lat)
        let dlat = this.transformlat(lng - 105.0, lat - 35.0)
        let dlng = this.transformlng(lng - 105.0, lat - 35.0)
        let radlat = lat / 180.0 * this.pi
        let magic = Math.sin(radlat)
        magic = 1 - this.ee * magic * magic
        let sqrtmagic = Math.sqrt(magic)
        dlat = (dlat * 180.0) / ((this.a * (1 - this.ee)) / (magic * sqrtmagic) * this.pi)
        dlng = (dlng * 180.0) / (this.a / sqrtmagic * Math.cos(radlat) * this.pi)
        let mglat = lat + dlat
        let mglng = lng + dlng

        return {
            lat: gcj.lat * 2 - mglat,
            lng: gcj.lng  * 2 - mglng
        }
    }

    static diffCoord(a, b) {
        return {
            lat: a.lat - b.lat,
            lng: a.lng - b.lng
        }
    }

    static gcj02towgs84(gcj) {
        gcj.lat = Number(gcj.lat)
        gcj.lng = Number(gcj.lng)
        let wgs = this.gcj02towgs84_rough(gcj)
        let d = this.diffCoord(gcj, wgs)

        let maxIterations = 10
        for ( ; (maxIterations-- > 0) && (Math.abs(d.lng) > this.g2w_precision || Math.abs(d.lat) > this.g2w_precision);) {
            wgs.lng += d.lng;
            wgs.lat += d.lat;
            d = this.diffCoord(gcj, this.wgs84togcj02(wgs));
        }

        return {
            lat: this.toFixed(wgs.lat),
            lng: this.toFixed(wgs.lng)
        }
    }

    static transformlat(lng, lat) {
        let ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(lng))
        ret += (20.0 * Math.sin(6.0 * lng * this.pi) + 20.0 * Math.sin(2.0 * lng * this.pi)) * 2.0 / 3.0
        ret += (20.0 * Math.sin(lat * this.pi) + 40.0 * Math.sin(lat / 3.0 * this.pi)) * 2.0 / 3.0
        ret += (160.0 * Math.sin(lat / 12.0 * this.pi) + 320 * Math.sin(lat * this.pi / 30.0)) * 2.0 / 3.0
        return ret
    }

    static transformlng(lng, lat) {
        let ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng))
        ret += (20.0 * Math.sin(6.0 * lng * this.pi) + 20.0 *
            Math.sin(2.0 * lng * this.pi)) * 2.0 / 3.0
        ret += (20.0 * Math.sin(lng * this.pi) + 40.0 *
            Math.sin(lng / 3.0 * this.pi)) * 2.0 / 3.0
        ret += (150.0 * Math.sin(lng / 12.0 * this.pi) + 300.0 *
            Math.sin(lng / 30.0 * this.pi)) * 2.0 / 3.0
        return ret
    }

    static toFixed(value) {
        return Number(value.toFixed(6))
    }

    static getDistanceFromLatLonInKm(x, y) {
        let lat1 = x.lat
        let lon1 = x.lng
        let lat2 = y.lat
        let lon2 = y.lng
        
        const R = 6371; // 地球平均半径，单位为公里
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLon = (lon2 - lon1) * (Math.PI / 180);
        const lat1Rad = lat1 * (Math.PI / 180);
        const lat2Rad = lat2 * (Math.PI / 180);
    
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1Rad) * Math.cos(lat2Rad);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c;
        return d * 1000
        // return Number((d * 1000).toFixed(2));
    }
}

window.CoordHelper = CoordHelper
export {
    CoordHelper
}
