import React from 'react'
import style from './contact.scss'
import {inject, observer } from 'mobx-react'

import { List, Input, Button, Toast } from 'antd-mobile'

import { checkPhoneNumber } from './../util/utils'

import { dge } from './../util/utils';

import webService from '../util/webService'

import { withTranslation } from "react-i18next";

@inject('settingsStore', 'globalStore')
@observer
class Contact extends React.Component {

  state = {
    // 手机号
    phoneVal: "",

    // 验证码
    code: "",

    // 计数
    count: 60,

    // 控制验证码点击
    liked: true,
    
    // 定时器
    timer: null,

    captcha: '',
    captchaUuid: '',
    captchaImage: ''
  }

  settingsStore = this.props.settingsStore
  globalStore = this.props.globalStore

  t = this.props.t

  /**
   *
   *紧急联系人&验证码
   */
  renderEmergency = () => {
    return(
      <List className={style.list}>
        <List.Item className={style.listCell}>
          <div>{this.t('contact.k012')}</div>
          <Input
            type="number"
            clearable
            placeholder={this.t('contact.k001')}
            style={{'--font-size': '14px' }}
            value={this.state.phoneVal}
            onChange={val => {
              this.setFormattedPhoneNum(val)
            }}
          />
        </List.Item>
        <List.Item className={style.listCell}>
          <div className={style.captcha}>
            <img alt={this.t('contact.k014')} src={'data:image/png;base64,' + this.state.captchaImage} onClick={() => {this.loadCaptchaImage()}} />
          </div>
          <Input
            type="text"
            placeholder={this.t('contact.k014')}
            style={{'--font-size': '14px','--text-align':'right' }}
            value={this.state.captcha}
            onChange={val => {
              this.setState({
                captcha: val
              })
            }}
          />
        </List.Item>
        <List.Item className={style.validation}>
          <div>{this.t('contact.k013')}</div>
          <div className={style.box}>
            <Input value={this.state.code} 
            onChange={val => {
              this.setFormattedCode(val)
            }} type="number" style={{'--font-size': '14px' }} placeholder={this.t('contact.k002')} clearable />
            <span onClick={() => {
              this.handleClick()
            }}>
              {
                this.state.liked ? this.t('contact.k003') : this.t('contact.k004', {seconds: this.state.count})
              }
            </span>
          </div>
        </List.Item>
      </List>
    )
  }

  /**
   *
   *提醒文案
   */
  renderRemind = () => {
    return(
      <ul className={style.msg}>
        <li>{this.t('contact.k005')}</li>
        {
          (() => {
            let ua = dge()
            if (ua !== 'smartcomtrip-mobile') {
              return (
                <>
                  <li>{this.t('contact.k006')}</li>
                  <li>{this.t('contact.k007')}</li>
                </>
              )
            }
          })()
        }
        
      </ul>
    )
  }

  /**
   *
   *渲染
   */
  render() {
    return(
      <div className={style.contact}>
        {/* 紧急联系人 */}
        {this.renderEmergency()}
        {/* 提醒文案 */}
        {this.renderRemind()}
        <Button style={
            {
              'background-image': 'var(--button_background_color)',
              '--border-color': 'var(--button_border_color)',
              'background-color': 'var(--button_border_color)',
              'color': 'var(--button_color)'
            }
          }  disabled={this.setDisabled()} onClick={() => {
          this.handleSubmit()
        }} block color='primary' size='large'>
          {this.t('contact.k008')}
        </Button>
      </div>
    )
  }

  componentDidMount() {
    let count = window.localStorage.getItem('countPhone')
    if (count > 0 && count < 60) {
      this.setState({
        count: count,
        liked: false
      }, () => {
        this.countDown()
      })
    } else {
      window.localStorage.removeItem('countPhone')
    }

    this.loadCaptchaImage()
  }

  componentWillUnmount() {
    window.localStorage.removeItem('countPhone')
    clearTimeout(this.state.timer)
  }

  /**
   * 加载反机器人验证码图片
   */
  loadCaptchaImage = async () => {
    let {data} = await webService.user.getCode()
    if (data.code === 0) {
        this.setState({
            captchaUuid: data.data.uuid,
            captchaImage: data.data.img
        })
    }
  }

  /**
   * 
   * 按钮禁用
   */
   setDisabled = () => {
    let flag = true
    if (this.state.phoneVal && this.state.code) {
      flag = false
    } else {
      flag = true
    }
    return flag
  }

  /**
   *
   *手机号输入 check
   */
   setFormattedPhoneNum = (text) => {
    text.length > 11 ? this.setState({phoneVal : text.slice(0, 11)}) : this.setState({phoneVal: text})
  }

  /**
   *
   *验证码 check
   */
   setFormattedCode = (text) => {
    text.length > 6 ? this.setState({code : text.slice(0, 6)}) : this.setState({code: text})
  }

  /**
   *
   *倒计时
   */
  countDown = () => {
    if(this.state.count <= 1) {
      window.localStorage.setItem("countPhone", 60);
      this.setState({
        count: 60,
        liked: true,
      });
      clearTimeout(this.state.timer)
    } else {
      this.setState({
        count: this.state.count - 1,
        liked: false,
      });
      window.localStorage.setItem("countPhone", this.state.count);
      this.setState({
        timer: setTimeout(() => this.countDown(), 1000)
      })
    }
  }

  /**
   *
   *获取验证码
   */
  handleClick = () => {
    if (!this.state.phoneVal) {
      Toast.show({
        content: this.t('contact.k009'),
        duration: 1000
      })
    } else if(!checkPhoneNumber(this.state.phoneVal)) {
      Toast.show({
        content: this.t('contact.k010'),
        duration: 1000
      })
    } else if (this.settingsStore.userPhone === this.state.phoneVal) {
      Toast.show({
        content: this.t('contact.k011'),
        duration: 1000
      })
    } else if (!this.state.captcha) {
      Toast.show({
        content: this.t('contact.k014'),
      })
    } else {
      if(!this.state.liked) {
        return false
      }
      this.sendCode()
    }
  }

  /**
   *
   * 发送验证请求
   */
  sendCode = () => {
    let params = {
      phoneNum: this.state.phoneVal,
      captchaId: this.state.captchaUuid,
      captcha: this.state.captcha,
      type: 2
    }

    this.setState({
      liked: false
    })

    webService.user.sendVerificationCode(params).then(d => {
      if (d.data.code === 0) {
        // 调用计时
        this.countDown()
      } else {
        this.setState({
          liked: true
        })
        Toast.show({
          content: d.data.message || this.t('contact.k015'),
          duration: 1000
        })
      }
    })
  }

  /**
   *
   *提交 check
   */
  handleSubmit = () => {
    if (!this.state.phoneVal) {
      Toast.show({
        content: this.t('contact.k009'),
        duration: 1000
      })
    } else if (!this.state.code) {
      Toast.show({
        content: this.t('contact.k002'),
        duration: 1000
      })
    } else if(!checkPhoneNumber(this.state.phoneVal)) {
      Toast.show({
        content: this.t('contact.k010'),
        duration: 1000
      })
    } else {
      this.setMobile()
    }
  }

  /**
   *
   *用户信息接口
   */
   getUserInfo = async () => {
    let d = await webService.basCity.getUserInfo();
    let {data} = d;
    if (data.code === 0) {
      this.globalStore.setUserInfo(data.data);
      setTimeout(() => {
        this.props.history.go(-1);
      }, 500)
    }
  }

  setMobile = () => {
    // 走接口
    let params = {
      mobile: this.state.phoneVal,
      code: this.state.code,
      type: 2
    }
    webService.user.setMobile(params).then(d => {
      if (d.data.code === 0) {
        this.settingsStore.setUrgencyPhone(this.state.phoneVal)
        this.getUserInfo()
      } else {
        Toast.show({
          content: d.data.message,
          duration: 1000
        })
      }
    })
  }
}

export default withTranslation()(Contact);