import React from "react";
import { DotLoading } from 'antd-mobile'
import style from './runingCharterCar.scss'

import { Button } from 'antd-mobile'

import { customerServiceTelephone } from './../../util/config'

import images from './../../util/images'

import { inject, observer } from 'mobx-react'

@inject('mainStore')
@observer
class runingCharterCar extends React.Component {
  state = {
    cirportAddress: '',
    address: '',
    phone: '',
    bar: '',
    type: '',
    tiem: ''
  }

  mainStore = this.props.mainStore

  t = this.props.t

  render() {
    return(
      <div>
        <div className={style.loading}>
          <span style={{ fontSize: 28}}>
            <DotLoading style={{ color: '#2f7dcd' }}/>
          </span>
        </div>
        <div className={style.content}>
          <div className={style.msg}>
            {this.t('runing.k0114')}
            {/* 客服人员正在处理，预约成功后，系统将自动推送车辆司机等信息，请留意查收! */}
          </div>
        </div>
        <div className={style.cell}>
          {/* 订单详情 */}
          <div className={style.dlt}>{this.t('runing.k0115')}</div>
          <p>
            <span>{this.t('waitingShenzhou.k003')}</span>
            <span>{this.props.baseInfo.pickupLocationName}</span>
          </p>
          <p>
            <span>{this.t('waitingShenzhou.k004')}</span>
            <span>{this.props.baseInfo.destLocationName}</span>
          </p>
          <p>
            {/* 联系电话 */}
            <span>{this.t('runing.k0118')}</span>
            <span>{this.props.baseInfo.passengerPhone}</span>
          </p>
          <p>
            <span>{this.t('waitingShenzhou.k006')}</span>
            <span>{this.props.baseInfo.carLevelName}</span>
          </p>
          <p>
            {/* 订车类型 */}
            <span>{this.t('runing.k0120')}</span>
            {/* 半日包 全日 */}
            <span>{JSON.parse(this.props.baseInfo.customInfo).charteredBusType === 1 ? this.t('runing.k0122') : this.t('runing.k0123')}</span>
          </p>
          <p>
            {/* 用车时间 */}
            <span>{this.t('runing.k0121')}</span>
            <span>{this.props.baseInfo.departTime}</span>
          </p>
        </div>
        <div className={style.toolbar}>
            <div>
                <img src={images.phone3} alt={this.t('waitingShenzhou.k011')} /> {this.t('waitingShenzhou.k009')} <span onClick={() => {
                    window.top.location.href="tel://" + customerServiceTelephone
                }}>{customerServiceTelephone}</span>
            </div>
            <div>
                <Button block color='primary' onClick={() => this.props.cancelOrder()}>{this.t('waitingShenzhou.k010')}</Button>
            </div>
        </div>
      </div>
    )
  }
}

export default runingCharterCar