import React from 'react'
import style from './share.scss';
import moment from 'moment'

import images from '../util/images';
import webService from '../util/webService';

import { withTranslation } from "react-i18next";


class Share extends React.Component {

    state = {
        orderInfo: {},
        driverInfo: {}
    }

    orderId = this.props.match.params.id;

    t = this.props.t

    render() {
        let {orderInfo, driverInfo} = this.state;

        return <div className={style.container}>
            <div className={style.head}>
                <div className={style.share}>
                    <img src={images.share} alt={this.t('share.k001')} />
                </div>
                
                <div className={style.tip}>{this.t('share.k002')}</div>
            </div>

            <div className={style.order}>
                <div className={style.title}>
                    <div>
                        <div>{this.t('share.k003')}</div>
                        <div>{driverInfo.vehicleNo}</div>
                    </div>
                    <div>
                        <div>{this.t('share.k003')}</div>
                        <div>{driverInfo.vehicleModel}</div>
                    </div>
                </div>
                <div className={style.body}>
                    <div>
                        <div>
                            <img src={images.begin} alt="" />
                        </div>
                        <div>{this.t('share.k004')}</div>
                        <div>{orderInfo.pickupLocationName}</div>
                    </div>
                    <div>
                        <div>
                            <img src={images.end} alt="" />
                        </div>
                        <div>{this.t('share.k005')}</div>
                        <div>{orderInfo.destLocationName}</div>
                    </div>
                    <div>
                        <div>
                            <img src={images.szbooking_time} alt="" />
                        </div>
                        <div>{this.t('share.k006')}</div>
                        <div>{moment(orderInfo.time).format('YYYY-MM-DD HH:mm')}</div>
                    </div>
                </div>
            </div>
            
        </div>
    }

    async componentDidMount() {
        let {data} = await webService.order.getShareOrder({orderId: this.orderId});
        if (data.code === 0) {
            this.setState({
                orderInfo: data.data.orderInfo,
                driverInfo: data.data.driverInfo
            })
        }
        if (window.wx) {
            let shareData = {
                link: `${window.location.origin}${window.location.pathname}#/watchShare/${this.orderId}`,
                title: this.t('runing.k051'),
                imgUrl: `${window.location.origin}${window.location.pathname}logo192.png`,
                desc: `${this.state.orderInfo.pickupLocationName} ${this.t('runing.k052')} ${this.state.orderInfo.destLocationName}`
            }

            // shareData.link = 'https://www.baidu.com';
            // shareData.title = '分享测试';
            // shareData.imgUrl = 'https://icar2.santen-china.cn/logo192.png';
            // shareData.desc = '分享测试：此为详细说明信息';

            window.wx.ready(() => {
                console.log('wx对象存在,挂载微信App监听,分享数据==>', shareData)
                window.wx.onMenuShareAppMessage({
                    ...shareData,
                    success: function () {
                        // 用户确认分享后执行的回调函数
                        console.log('分享成功', `${window.location.origin}${window.location.pathname}#/watchShare/${this.orderId}`)
                    },
                    error: function(res) {
                        console.log('分享失败', res)
                    },
                    cancel: function () {
                        // 用户取消分享后执行的回调函数
                        console.log('用户取消分享', `${window.location.origin}${window.location.pathname}#/watchShare/${this.orderId}`)
                    }
                })
            })
            

            

            
        }
        
    }

    componentWillUnmount() {
        if (window.wx) {
            window.wx.onMenuShareAppMessage({})
        }
    }
}
export default withTranslation()(Share);