import React from 'react'
import style from './orderevaluate.scss'
import {inject, observer} from 'mobx-react'

import { Card, Image, Space, Rate, TextArea, Button, Toast, Divider, Selector } from 'antd-mobile'

import images from './../util/images'

import webService from './../util/webService'

import { withTranslation } from "react-i18next";

@inject('orderEvaluateStore')
@observer
class OrderEvaluate extends React.Component {
  
  state = {
    // 评价内容
    value: '',
    feedbackLabels: [],
    feedbackType: 1
  }

  orderEvaluateStore = this.props.orderEvaluateStore
  t = this.props.t

  /**
   *
   *评价部分
   */
  renderCard = () => {
    return (
      <Card className={style.comment}>
          <div className={style.box}>
            <Space wrap>
              <Image src={
                this.orderEvaluateStore.driverInfo && this.orderEvaluateStore.driverInfo.avatar ? this.orderEvaluateStore.driverInfo.avatar : images.oval
              } width={50} height={50} fit='cover' style={{ borderRadius: 32 }}/>
            </Space>
            <div className={style.right}>
              <p>{this.orderEvaluateStore.driverInfo ? this.orderEvaluateStore.driverInfo.vehicleNo:''}</p>
              <span>{this.orderEvaluateStore.driverInfo ? this.orderEvaluateStore.driverInfo.vehicleModel:''}&nbsp;{this.orderEvaluateStore.driverInfo ? this.orderEvaluateStore.driverInfo.vehicleColor:''}</span>
            </div>
          </div>
          <div className={style.score}>
            {(()=> {
              // 产生评价则显示评价
              if (this.orderEvaluateStore.feedbackInfo.feedback) {
                return(
                  <Rate readOnly style={{'--active-color': '#f5ae37'}} value={this.orderEvaluateStore.feedbackInfo.score} />
                )
              } else {
                // 未产生可进行评论
                return(
                  <Rate style={{'--active-color': '#f5ae37'}} onChange={(val)=> {
                    this.setGradeValue(val)
                  }} />
                )
              }
            })()}
            {(()=> {
              if (this.orderEvaluateStore.feedbackInfo.score) {
                if (this.orderEvaluateStore.feedbackInfo.score > 3) {
                  this.orderEvaluateStore.setChargeRelevant([])
                  this.orderEvaluateStore.setServiceRelevant([])
                  this.orderEvaluateStore.setOtherList([])
                  return <span className={this.orderEvaluateStore.feedbackInfo.score > 3 ? style.show : style.hide}>{this.t('orderevaluate.k001')}</span>
                } else {
                  return <span className={this.orderEvaluateStore.feedbackInfo.score <= 3 ? style.show : style.hide}>{this.t('orderevaluate.k002')}</span>
                }
              } else {
                return ''
              }
            })()}
            {(()=> {
              return(
                this.orderEvaluateStore.feedbackInfo.complaint && this.orderEvaluateStore.feedbackInfo.complaint.tpyes && this.orderEvaluateStore.feedbackInfo.complaint.tpyes.map(item => {
                  return (<p key={item.label}>{item.label}</p>)
                })
              )
            })()}
          </div>
          {(()=> {
            // 判断是否产生评价
            if (!this.orderEvaluateStore.feedbackInfo.feedback) {
              return(
                <div className={style.text}>
                  <TextArea maxLength={50} placeholder={this.t('orderevaluate.k003')} value={this.state.value} onChange={(val) => this.handleValue(val)} autoSize={{ minRows: 3, maxRows: 5 }}/>
                </div>
              )
            }
          })()}
      </Card>
    )
  }

  /**
   *
   *显示已评价内容
   */
  renderComment = () => {
    return (
      (()=> {
        if (this.orderEvaluateStore.feedbackInfo.feedback) {
          return (
            <Card title={this.t('orderevaluate.k004')} className={style.content}>
              <p>{this.orderEvaluateStore.feedbackInfo.feedback}</p>
            </Card>
          )
        } else {
          return ''
        }
      })()
    )
  }

  /**
   *
   *投诉订单部分
   */
  renderComplaint = () => {
    return(
      (() => {
        if(this.orderEvaluateStore.hasComplaint) {
          return(
            <Card title={this.t('orderevaluate.k005')}>
              {
                this.state.feedbackLabels && this.state.feedbackLabels.map((item,index) => {
                  return(
                    <div key={index}>
                    <Divider style={{color: '#000000', borderColor: '##e9ecf0', borderStyle: 'dashed', fontSize: '13px', fontWeight: '500'}}>
                      {item.groupName}
                    </Divider>
                    <div className={style.selector}>
                      {
                        <Selector 
                            options={item.children} 
                            onChange={(arr, item) => {
                              // this.setState({
                              //   defaultUnreasonableOptions: arr
                              // })item.items[0]
                              if (index === 0) {
                                if (item.items[0]) {
                                  this.orderEvaluateStore.setChargeRelevant([{label: item.items[0].label, id: item.items[0].id}])
                                } else {
                                  this.orderEvaluateStore.setChargeRelevant([])
                                }
                              } else if (index === 1) {
                                if (item.items[0]) {
                                  this.orderEvaluateStore.setServiceRelevant([{label: item.items[0].label, id: item.items[0].id}])
                                } else {
                                  this.orderEvaluateStore.setServiceRelevant([])
                                }
                              } else if (index === 2) {
                                if(item.items[0]) {
                                  this.orderEvaluateStore.setOtherList([{label: item.items[0].label, id: item.items[0].id}])
                                } else {
                                  this.orderEvaluateStore.setOtherList([])
                                }
                              }
                            }}
                            >
                        </Selector>
                      }
                    </div>
                    </div>
                  )
                })
              }
            </Card>
          )
        }
      })()
    )
  }

  /**
   *
   *投诉进度
   */
  renderComplaintProgress = () => {
    return (() => {
          if(this.orderEvaluateStore.feedbackInfo.complaint && this.orderEvaluateStore.feedbackInfo && this.orderEvaluateStore.feedbackInfo.complaint && this.orderEvaluateStore.feedbackInfo.complaint.tpyes && this.orderEvaluateStore.feedbackInfo.complaint.tpyes.length > 0 && this.orderEvaluateStore.feedbackInfo.feedback) {
            return (
              <div className={style.processingBox}>  
                <Card title={this.t('orderevaluate.k006')}>
                  <div className={style.complaint}>
                    <div className={style.title}>
                      <span className={style.processing}>{this.t('orderevaluate.k007')}</span>
                      <span className={this.orderEvaluateStore.feedbackInfo.complaint && this.orderEvaluateStore.feedbackInfo.complaint.state === 2 ? style.processing : style.colorgrey}>{this.t('orderevaluate.k008')}</span>
                    </div>
                    <div className={style.strip}>
                      <div className={style.progress}>
                        {(() => {
                          if(this.orderEvaluateStore.feedbackInfo.complaint && this.orderEvaluateStore.feedbackInfo.complaint.state === 2) {
                            return(
                              <img alt="" src={images.handled}></img>
                            )
                          } else {
                            return(
                              <img alt="" src={images.handling}></img>
                            )
                          }
                        })()}
                      </div>
                    </div>
                    {(() => {
                      if (this.orderEvaluateStore.feedbackInfo && this.orderEvaluateStore.feedbackInfo.complaint.state === 2 ) {
                        return(<span className={style.msg}>{this.orderEvaluateStore.feedbackInfo.reply}</span>)
                      } else {
                        return(<span className={style.verb}>{this.t('orderevaluate.k011')}</span>)
                      }
                    })()}
                  </div>
                </Card>
              </div>
            )
          }
      })()
  }
  
   /**
   *
   * 渲染
   */
  render() {
    return(
      <div className={style.card}>
        {/* 投诉进度 */}
        {this.renderComplaintProgress()}
        {/* 评价部分 */}
        {this.renderCard()}
        {/* 显示已评价 */}
        {this.renderComment()}
        {/* 投诉部分 */}
        {this.renderComplaint()}
        {/* 评价按钮 */}
        <div className={style.btn}>
          {(()=> {
            if (!this.orderEvaluateStore.feedbackInfo.feedback) {
              return(
                <Button disabled={this.orderEvaluateStore.dissatisfied} block color='primary' onClick={() => {
                  this.handleClickEvaluate()
                }} size='middle'>
                  {this.t('orderevaluate.k012')}
                </Button>
              )
            } else {
              return false
            }
          })()}
        </div>
      </div>
    )
  }

  componentDidMount() {
    const {id} = this.props.match.params
    this.orderEvaluateStore.setDetailsId(id)
    this.loadFeedback()
    webService.order.getFeedbackLabel().then(d => {
      if(d.data.code === 0) {
        d.data.data.feedbackLabels.forEach(item => {
          item.children.forEach(val => {
            val.value = val.id
          })
        })
        this.setState({
          feedbackLabels: d.data.data.feedbackLabels
        })
      }
    })
  }
  
  componentWillUnmount() {
    this.orderEvaluateStore.setGradeValue(null)
  }

  /**
   * 评价数据获取
   */
  loadFeedback() {
    let orderId = this.orderEvaluateStore.feedbackInfo.orderId
    webService.order.getOrderFeedback({orderId:orderId}).then(d => {
      if (d.data.code === 0) {
        this.orderEvaluateStore.setDriverInfo(d.data.data.driverInfo)
        this.orderEvaluateStore.setFeedbackInfo(d.data.data.feedbackInfo)
        this.setState({
          value: this.orderEvaluateStore.feedbackInfo.feedback,
        }, () => {
        })
      }
    })
  }

  /**
   *
   * 设置评价分
   *@param {*} val
   *
   */
   setGradeValue(val) {
    this.orderEvaluateStore.setGradeValue(val)
  }

   /**
   *
   *获取评价内容
   *@param {*} val
   */
   handleValue(val) {
    this.setState({
      value: val
    })
  }

  /**
   *
   *匿名评价
   */
   handleClickEvaluate() {
    if (!this.orderEvaluateStore.feedbackInfo.score) {
      Toast.show({
        content: this.t('orderevaluate.k013'),
      })
    } else if(!this.state.value) {
      Toast.show({
        content: this.t('orderevaluate.k014'),
      })
    } else {
      let complaint = [...this.orderEvaluateStore.chargeRelevantList, ...this.orderEvaluateStore.serviceRelevantList, ...this.orderEvaluateStore.otherList]
      // 走评价接口
      let params = {
        orderId: this.orderEvaluateStore.feedbackInfo.orderId,
        feedback: this.state.value,
        score: this.orderEvaluateStore.feedbackInfo.score,
        complaints: complaint,
        type: this.state.feedbackType
      }
      // webService.logger.report(this.orderEvaluateStore.feedbackInfo.orderId, 'oldDetails', '提交评价参数 => type = ', this.state.feedbackType)
      webService.order.setFeedback(params).then(d=> {
        if (d.data.code === 0) {
          this.orderEvaluateStore.setTextareaValue(this.state.value)
          Toast.show({
            content: this.t('orderevaluate.k015'),
          })
          this.loadFeedback()
        }
      })
    }
  }

}

export default withTranslation()(OrderEvaluate);