import React from 'react';
import { withRouter } from 'react-router';
import style from './menu.scss'
import {inject, observer } from 'mobx-react'

import { List, Badge, Dialog, Image, Button, Toast  } from 'antd-mobile'
import {
    FileOutline,
    CheckShieldOutline,
    PayCircleOutline,
    TruckOutline,
    BankcardOutline,
    SetOutline,
    QuestionCircleOutline,
    BillOutline,
    AppOutline,
    TextOutline,
    ReceivePaymentOutline,
    TeamOutline,
    FillinOutline

} from 'antd-mobile-icons'

// import { envName } from './../../util/variable';
import { dge } from './../../util/utils';

import { customerServiceTelephone, customerServiceTelephone_ziru } from './../../util/config';

import gc from './../../util/globalConfiguration'
import images from './../../util/images';
import webService from './../../util/webService'

import eruda from 'eruda'


@withRouter
@inject('mainStore', 'globalStore')
@observer
class Menu extends React.Component {

    state = {
        isDebug: false
    }
    mainStore = this.props.mainStore;
    globalStore = this.props.globalStore;
    t = this.props.t

    homeIcon() {
        return <svg t="1686278054725" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2376" width="16" height="16">
            <path d="M1014.613212 543.119765c-3.970428 3.806699-9.076726 5.699816-14.172791 5.699816-5.372358 0-10.744715-2.108011-14.766308-6.293333l-64.539922-67.24145 0 523.543917c0 11.307533-9.15859 20.466124-20.466124 20.466124l-265.261433 0c-11.307533 0-20.466124-9.15859-20.466124-20.466124 0-11.2973 9.15859-20.466124 20.466124-20.466124l244.795309 0 0-545.729196-367.233895-382.614188-367.223662 382.593721 0 545.749662 224.329185 0L370.07357 672.353105c0-11.307533 9.168824-20.466124 20.466124-20.466124l244.86694 0c11.2973 0 20.466124 9.15859 20.466124 20.466124 0 11.2973-9.168824 20.466124-20.466124 20.466124l-224.400816 0 0 306.009486c0 11.307533-9.15859 20.466124-20.466124 20.466124l-265.261433 0c-11.2973 0-20.466124-9.15859-20.466124-20.466124L104.812137 475.264331l-64.550155 67.251683c-7.828292 8.15575-20.783349 8.42181-28.939099 0.593518s-8.42181-20.783349-0.593518-28.939099L498.201739 6.293333c3.857864-4.021593 9.18929-6.293333 14.766308-6.293333 5.566786 0 10.898211 2.27174 14.756075 6.293333l487.482607 507.887332C1023.035022 522.336416 1022.768963 535.291472 1014.613212 543.119765z" p-id="2377"></path>
        </svg>
    }

    render() {
        return <div className={style.menu} onClick={() => this.debugMenu()}>
            <List>
                {
                  (() => {
                    if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.main_menu) {
                        return <List.Item prefix={this.homeIcon()} arrow={false} onClick={() => {
                              this.props.hideMenu()
                              setTimeout(() => {
                                this.props.history.push('/')
                              }, 100)
                            }}>
                              {this.t('main.k0146')}
                            </List.Item>
                    }
                })()
                }
                {
                    (() => {
                        if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.runing) {
                            return <List.Item prefix={<FileOutline fontSize={17} />} arrow={false} onClick={() => {
                                    this.props.hideMenu()
                                    setTimeout(() => {
                                      this.props.history.push('/runingOrders')
                                    }, 100)
                                }}>
                                    <Badge content={this.mainStore.badgeCount.runningOrderCount !==0 ? this.mainStore.badgeCount.runningOrderCount: null}>
                                    {this.t('main.k008')}
                                    </Badge>
                                </List.Item>
                        }
                    })()
                }
                
                {
                    (() => {
                        if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.approve) {
                            return <List.Item prefix={<CheckShieldOutline fontSize={17} />} arrow={false} onClick={() => {
                                        this.props.hideMenu()
                                        setTimeout(() => {
                                          this.props.history.push('/approval')
                                        }, 100)
                                    }}>
                                    <Badge content={this.mainStore.badgeCount.waitApproveOrderCount !== 0 ? this.mainStore.badgeCount.waitApproveOrderCount : null}>
                                    {this.t('main.k009')}
                                    </Badge>
                                </List.Item>
                        }
                    })()
                }
                {
                    (() => {
                        if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.preApproval) {
                            return <List.Item prefix={<CheckShieldOutline fontSize={17} />} arrow={false} onClick={() => {
                                        this.props.hideMenu()
                                        setTimeout(() => {
                                          this.props.history.push('/preApproval')
                                        }, 100)
                                    }}>
                                    <Badge content={this.mainStore.badgeCount.waitPreApprovalCount !== 0 ? this.mainStore.badgeCount.waitPreApprovalCount : null}>
                                    {this.t('main.k082')}
                                    </Badge>
                                </List.Item>
                        }                        
                    })()
                }
                {
                    (() => {
                        if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.pay) {
                            return <List.Item prefix={<PayCircleOutline fontSize={17} />} arrow={false} onClick={() => {
                                    this.props.hideMenu()
                                    setTimeout(() => {
                                      this.props.history.push('/payments')
                                    }, 100)
                                }}>
                                    <Badge content={this.mainStore.badgeCount.waitPayOrderCount !== 0 ? this.mainStore.badgeCount.waitPayOrderCount : null}>
                                    {gc.process.payMenuName ? this.t('main.k0162') : this.t('main.k010')}
                                    </Badge>
                                </List.Item>
                        }
                    })()
                }
                {
                    (() => {
                        if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.trip) {
                            return <List.Item prefix={<TruckOutline fontSize={17} />} arrow={false} onClick={() => {
                                    this.props.hideMenu()
                                    setTimeout(() => {
                                      this.props.history.push('/orders')
                                    }, 100)
                                }}>
                                    {this.t('main.k011')}
                                </List.Item>
                        }
                    })()
                }
                {
                    (() => {
                        if (gc.process.isShowSanofiMeeting) {
                            return <List.Item prefix={<Image src={images.iconSanofiMeeting} width={18} height={18} fit='cover' />} arrow={false} onClick={() => {
                                    this.props.hideMenu()
                                    setTimeout(() => {
                                      this.props.history.push('/special/sanofiMeeting')
                                    }, 100)
                                }}>
                                    <Badge>
                                    {/* 我的会议 */}
                                    {this.t('menu.k003')}
                                    </Badge>
                                </List.Item>
                        }
                    })()
                }
                {
                    (() => {
                        if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.invoice) {
                            return <List.Item prefix={<ReceivePaymentOutline fontSize={17} />} arrow={false} onClick={() => {
                                    if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.invoiceOpen_prompt) {
                                      Dialog.show({
                                        // 请联系平台在线客服申请开票
                                        content: this.t('menu.k001'),
                                        actions: [
                                          {
                                            key: 'cancel',
                                            // 确定
                                            text: this.t('common.k002'),
                                          }
                                        ],
                                        closeOnAction: true
                                      })
                                    } else {
                                      this.props.hideMenu()
                                      setTimeout(() => {
                                        this.props.history.push('/invoice')
                                      }, 100)
                                    }
                                }}>
                                    {this.t('main.k012')}
                                </List.Item>
                        }
                    })()
                }
                {
                    (() => {
                        if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.scene) {
                            return <List.Item prefix={<BankcardOutline fontSize={17} />} arrow={false} onClick={() => {
                                    this.props.hideMenu()
                                    setTimeout(() => {
                                      this.props.history.push('/scenes')
                                    }, 100)
                                }}>
                                    {this.t('main.k013')}
                                </List.Item>
                        }
                    })()
                }
                {
                    (() => {
                        if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.preApply) {
                            return <List.Item prefix={<FillinOutline fontSize={17} />} arrow={false} onClick={() => {
                                    this.props.hideMenu()
                                    setTimeout(() => {
                                      this.props.history.push('/perApply')
                                    }, 100)
                                }}>
                                    {this.t('main.k081')}
                                </List.Item>
                        }
                    })()
                }
                {
                    (() => {
                        if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.setting) {
                            return <List.Item prefix={<SetOutline fontSize={17} />} arrow={false} onClick={() => {
                                    this.props.hideMenu()
                                    setTimeout(() => {
                                      this.props.history.push('/settings')
                                    }, 100)
                                }}>
                                    {this.t('main.k014')}
                                </List.Item>
                        }
                    })()
                }
                {
                    (() => {
                        if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.guide) {
                            return <List.Item prefix={<QuestionCircleOutline fontSize={17} />} arrow={false} onClick={() => {
                                    // 默克
                                    if (gc.process.isMoke) {
                                        window.location.href = 'https://ipath-prod-public-1309103551.cos.ap-shanghai.myqcloud.com/images%2F%E9%BB%98%E5%85%8B%E7%94%A8%E6%88%B7%E6%89%8B%E5%86%8C.png'
                                        return
                                    }
                                    
                                    if (this.globalStore.companyInfo.manualUrl) {
                                      this.props.hideMenu()
                                      setTimeout(() => {
                                        window.location.href = this.globalStore.companyInfo.manualUrl
                                      }, 100)
                                    }
                                }}>
                                    {this.t('main.k015')}
                                </List.Item>
                        }
                    })()
                }
                {
                    (() => {
                        if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.coupon) {
                            return <List.Item prefix={<BillOutline fontSize={17} />} arrow={false} onClick={() => {
                                    this.props.hideMenu()
                                    setTimeout(() => {
                                      this.props.history.push('/coupons')
                                    }, 100)
                                }}>
                                  <Badge content={this.mainStore.badgeCount.availableCouponCount !== 0 ? this.mainStore.badgeCount.availableCouponCount : null}>
                                    {this.t('main.k016')}
                                  </Badge>
                                </List.Item>
                        }
                    })()
                }
                {
                    (() => {
                        if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.account) {
                            return <List.Item prefix={<AppOutline fontSize={17} />} arrow={false} onClick={() => {
                                        this.props.hideMenu()
                                        setTimeout(() => {
                                          this.props.history.push('/account')
                                        }, 100)
                                }}>
                                    {this.t('main.k017')}
                                </List.Item>
                        }
                    })()
                }
                {   
                    (() => {
                        if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.report) {
                            return <List.Item prefix={<TextOutline fontSize={17} />} arrow={false} onClick={() => {
                                    this.props.hideMenu()
                                    setTimeout(() => {
                                      this.props.history.push('/report')
                                    }, 100)
                                }}>
                                    {this.t('main.k018')}
                                </List.Item>
                        }
                    })()
                }
                {   
                    (() => {
                        if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.carbon_emission) {
                            return <List.Item prefix={<TextOutline fontSize={17} />} arrow={false} onClick={() => {
                                    this.props.hideMenu()
                                    setTimeout(() => {
                                      this.props.history.push('/special/carbonEmission')
                                    }, 100)
                                }}>
                                    {/* 碳积分面板 */}
                                    {this.t('title.k049')}
                                </List.Item>
                        }
                    })()
                }
                {
                    (() => {
                        if (this.state.isDebug) {
                            return <List.Item prefix={<TextOutline fontSize={17} />} arrow={false} onClick={() => {
                                this.props.hideMenu()
                                setTimeout(() => {
                                  this.props.history.push('/debug')
                                }, 100)
                            }}>
                                {/* 调试信息 */}
                                {this.t('title.k032')}
                            </List.Item>
                        }
                    })()
                }
                {
                    (() => {
                        if (this.state.isDebug) {
                            return <List.Item prefix={<TextOutline fontSize={17} />} arrow={false} onClick={() => {
                                // let script = document.createElement('script');
                                // script.type = 'text/javascript';
                                // script.onload = () => { 
                                //     // eslint-disable-next-line
                                //     var vConsole = new VConsole();
                                // };
                                // script.src = "https://cdn.bootcdn.net/ajax/libs/vConsole/3.9.0/vconsole.min.js";
                                // document.getElementsByTagName('head')[0].appendChild(script); 
                                eruda.init()
                            }}>
                                {/* 控制台 */}
                                {this.t('menu.k002')}
                            </List.Item>
                        }
                    })()
                }
                {
                    (() => {
                        if (this.state.isDebug && this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.testLink) {
                            return <List.Item prefix={<TextOutline fontSize={17} />} arrow={false} onClick={() => {
                                window.location.href = 'https://student-certi.jd.com/?source=YQCX&ext_tag=YY-YQCX-DC'
                            }}>
                                跳转测试
                            </List.Item>
                        }
                    })()
                }
                {
                  (() => {
                    if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.hotline) {
                      return(
                          <List.Item prefix={<TeamOutline fontSize={17} />} arrow={false} onClick={() => {
                              setTimeout(() => {
                                  window.location.href = `tel:${customerServiceTelephone}`;
                              }, 200);
                          }}>
                              {this.t('main.k074')}
                          </List.Item>
                      )
                    }
                  })()
                }
                {   
                    (() => {
                        if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.feedback) {
                            return <List.Item prefix={<TextOutline fontSize={17} />} arrow={false} onClick={() => {
                                    this.props.hideMenu()
                                    setTimeout(() => {
                                      window.location.href = 'https://survey.jjmc.cn/Backend/H5/Index?surveyID=0cc9614e-919e-4ed7-948a-cc335ff77717'
                                    }, 100)
                                }}>
                                    {this.t('main.k0163')}
                                </List.Item>
                        }
                    })()
                }
                {   
                    (() => {
                        if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.ziruhotline) {
                            return <List.Item prefix={<TeamOutline fontSize={17} />} arrow={false} onClick={() => {
                                setTimeout(() => {
                                    window.location.href = `tel:${customerServiceTelephone_ziru}`;
                                }, 200);
                            }}>
                                {this.t('main.k074')}
                            </List.Item>
                        }
                    })()
                }
                {/* 预订火车票 */}
                {   
                    (() => {
                        if (this.globalStore.userInfo.permissions?.businessTravel) {
                            return <List.Item arrow={false}>
                              <Button fill='none' className={style.trainContainer} onClick={() => { this.handleTravelTrain() }}>
                                  <Image height={20} width={20} src={images.iconTrain} />
                                  <span>{this.t('menu.k005')}</span>
                              </Button>
                            </List.Item>
                        }
                    })()
                }
            </List>
        </div>
    }

    componentDidMount() {
        this.loadBadgeCount();
    }

    componentWillUnmount() {
      Dialog.clear()
      Toast.clear()
    }

    /**
     * 刷新代办任务数
     */
    loadBadgeCount = () => {
        this.mainStore.refreshBadgeCount();
    }

    firstClickTime = 0;
    totleClick = 0;
    /**
     * 启动调试模式
     */
    debugMenu = () => {
        if (new Date().getTime() - this.firstClickTime > 2000) {
            this.firstClickTime = new Date().getTime()
            this.totleClick = 0
        } else {
            this.totleClick = this.totleClick + 1;
            if (this.totleClick >= 12) {
                this.setState({
                    isDebug: true
                })
                setTimeout(() => {
                    this.setState({
                        isDebug: false
                    })
                }, 5000);
                console.log('成功触发', this.totleClick)
                console.log(dge())
            }
        }
    }

    // 预订火车票
    handleTravelTrain = async () => {
        if(!this.globalStore.userInfo.permissions?.order_ticket) {
            Dialog.alert({
              title: this.t('common.k004'),
              content: this.t('menu.k004'),
              confirmText: this.t('common.k003'),
            });
            return;
        }
        // this.props.hideMenu();
        Toast.show({
            icon: 'loading',
            content: this.t('main.k076'),
            duration: 0,
            maskClickable: false
        })
        const { data } = await webService.user.getTravelTrainUrl();
        if (data.code !== 0) {
            Dialog.alert({
              title: this.t('common.k004'),
              content: data.message,
              confirmText: this.t('common.k003'),
            });
            return;
        }
        // window.location.href = data.data.webH5
        window.location.href = data.data.urlH5
    }
}


export default Menu;
