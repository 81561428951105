import React from 'react'
import style from './commonlyUsedAddress.scss'
import {inject, observer } from 'mobx-react'

import { RightOutline } from 'antd-mobile-icons'

@inject('mainStore', 'globalStore')
@observer
class CommonlyUsedAddress extends React.Component {
  state = {

  }

  t = this.props.t

  render() {
    return(
      <div className={style.box}>
        <div className={style.address} onClick={() => {
            if (this.props.address1.address) {
              this.props.useAddress1(this.props.address1)
            } else {
              this.props.to(1)
            }
        }}>
          <div>
            <span>{this.t('main.k0121')}</span>
            {
              this.props.address1.address ? <p>{this.props.address1.address}</p> : <p>{this.t('main.k0123')}</p>
            }
            
          </div>
          <RightOutline />
        </div>
        <div className={style.address} onClick={() => {
          if (this.props.address2.address) {
            this.props.useAddress2(this.props.address2)
          } else {
            this.props.to(2)
          }
        }}> 
          <div>
            <span>{this.t('main.k0122')}</span>
            {
              this.props.address2.address ? <p>{this.props.address2.address}</p> : <p>{this.t('main.k0123')}</p>
            }
            
          </div>
          <RightOutline />
        </div>
      </div>
    )
  }

}

export default CommonlyUsedAddress