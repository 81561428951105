import React from 'react';
import { RightOutline } from 'antd-mobile-icons'

import {inject, observer } from 'mobx-react'

import webService from './../util/webService'

import { withTranslation } from "react-i18next";

import style from './expenseDetails.scss'

@inject('globalStore')
@observer
class expenseDetails extends React.Component {
  state = {
    orderInfo: {
      sourceIconUrl: '',
      sourceName: '',
      totalMoney: '',
      changePrice: false
    },
    feeDetails: [] // 费用明细
  }

  globalStore = this.props.globalStore

  t = this.props.t

  // 是否显示投诉中心入口
  get showComplaintCenter() {
    return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.orderdetails_complaint
  }

  render() {
    return <div className={style.wrap}>
      <div className={style.content}>
        <div>
          <div className={style.car_source}>
            <div>
              <img alt='' src={this.state.orderInfo.sourceIconUrl}/>
              {/* k003 约车*/}
              <span>{this.state.orderInfo.sourceName}</span>
            </div>
            <div>
              {/* k001 总金额  k002 元*/}
              {this.t('expenseDetails.k001')}<span>&nbsp;{this.state.orderInfo.totalMoney}&nbsp;</span>{this.t('expenseDetails.k002')}
            </div>
          </div>

          {
            this.state.feeDetails ?
            <div className={style.content_list}>
            {
              this.state.feeDetails.map((item,index) => {
                  return <div className={style.box} key={index}>
                      <div className={style.expenses}>
                        <span>{item.feeName}</span>
                        <span className={item.billType === '10011' ? style.red_color : ''}>{item.amount} {this.t('expenseDetails.k002')}</span>
                      </div>
                      {/* {
                        item.billType === '222' ? <div className={style.dimension}>普通时段</div> : <></>
                      } */}
                      {/* 预约管家为您代叫不同平台车型的差价已直接扣除，无需由您支付。 */}
                      {
                        item.billType === 10011 ? <div className={style.dimension}>{this.t('expenseDetails.k004')}</div> : <></>
                      }
                  </div>
                })
              }
            </div>:<></>
          }

          {
            this.state.orderInfo.changePrice? <div className={style.refund_box}>
              {this.t('expenseDetails.k007')}
            </div> : <></>

          }
          
        </div>
      </div>
      {/* k005 对费用有疑问？ k006 点击反馈*/}
      {
        this.showComplaintCenter ? 
        <div className={style.feedback}>{this.t('expenseDetails.k005')}<div onClick={() => {
          const {id} = this.props.match.params;
          this.props.history.push(`/serviceCenter/${id}`)
        }}>{this.t('expenseDetails.k006')} <RightOutline color={'#333333'} fontSize={15}/></div></div> : <></>
      }
    </div>
  }

  componentDidMount() {
    this.loadOrderFeeDetails()
  }

  /**
   * 
   * 获取费用明细
   */
  loadOrderFeeDetails = async () => {
    const {id} = this.props.match.params;
    let {data} = await webService.order.getOrderFeeDetails({orderId:id});
    if (data.code === 0) {
      this.setState({
        orderInfo: {
          ...this.state.orderInfo,
          sourceIconUrl: data.data.sourceIconUrl,
          sourceName: data.data.sourceName,
          totalMoney: data.data.totalMoney,
          changePrice: data.data.changePrice
        },
        feeDetails: data.data.feeDetails
      })
    }
  }
}

export default withTranslation()(expenseDetails)