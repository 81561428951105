import React from 'react';
import style from './dispatchResponseOrder.scss'


import { Modal, Button } from 'antd-mobile'
import { CloseOutline } from 'antd-mobile-icons'
import webService from '../../util/webService';

class DispatchResponseOrder extends React.Component {

    state = {
        showPop: false,
        outTime: 5
    }

    intervalId = undefined

    t = this.props.t

    
    render() {
        return (
            <div className={style.modal_wrap}>
                <Modal
                  visible={this.state.showPop}
                  getContainer={() => document.getElementsByClassName('_2klcr6dFDBTq3tPQbEJI9t')[0]}
                  content={
                    <div className={style.modal_box}>
                      <div className={style.modal_title}>
                        <span>{this.t('runing.k0148')}</span>
                        <CloseOutline onClick={()=> {
                          this.setState({
                            showPop: false
                          })
                          clearInterval(this.intervalId)
                        }}/>
                      </div>
                      <div className={style.modal_prompt}>{this.t('runing.k0149')}</div>
                      <div className={style.message}>
                      {this.t('runing.k0150')}<span>&nbsp;{this.props.dispatchFeeAmount}&nbsp;</span>{this.t('runing.k0151')}
                      </div>
                      <Button block style={
                        {
                          'background-image': 'var(--button_background_color)',
                          '--border-color': 'var(--button_border_color)',
                          'background-color': 'var(--button_border_color)',
                          'color': 'var(--button_color)'
                        }
                      } size='middle' onClick={() => {
                        clearInterval(this.intervalId)
                        this.setState({
                          showPop: false
                        })
                        this.submitDispatchParam(1)
                      }}>{this.t('runing.k0152')}</Button>
                      <Button block size='middle' onClick={() => {
                        clearInterval(this.intervalId)
                        this.setState({
                          showPop: false
                        })
                        this.submitDispatchParam(0)
                      }}>{this.t('runing.k0153')}<span>({this.state.outTime})</span></Button>
                    </div>
                  }
                  closeOnAction
                  onClose={() => {
                    this.setState({
                      showPop: false
                    })
                  }}
                  actions={[
                  ]}
                />
            </div>
        )
    }

    componentDidMount = () => {
      if (this.props.popupTime > 0) {
        setTimeout(() => {
          this.setState({
            showPop: true,
            outTime: this.props.outTime
          }, () => {
            this.intervalId = setInterval(() => {
              if (this.state.outTime <= 0) {
                this.submitDispatchParam(0)
                this.setState({
                  showPop: false
                })
                clearInterval(this.intervalId)
              } else {
                this.setState({
                  outTime: this.state.outTime - 1,
                });
              }
            }, 1000)
          })
        }, this.props.popupTime * 1000)
      }
    }

    componentWillUnmount = () => {
      clearInterval(this.intervalId);
    }

    submitDispatchParam = async (submitType) => {
      await webService.order.submitDispatchParam({
        orderId: this.props.orderId,
        submitType: submitType
      })
    }
}

export default DispatchResponseOrder;