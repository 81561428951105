import React from "react";
import style from './orders.scss'
import {inject, observer } from 'mobx-react'

import { DatePicker, Picker, InfiniteScroll, Loading, Toast, Button, Dialog } from 'antd-mobile'

import moment from 'moment'

import OrdersCell from './../component/orders/orderscell'
import images from './../util/images'
import webService from './../util/webService'

import { withTranslation } from "react-i18next";

@inject('ordersStore', 'globalStore')
@observer
class Orders extends React.Component {
  
  state = {
    // 控制日期显隐
    visible: false,
    visible1:false,
    // 控制默认加载数据
    hasMore: false,
    isData: null,
    // 入参
    pageData: {
      pageNum: 1,
      pageSize: 15,
      total: 0,
      totalPrice: 0,
      orders: [],
      approvalState:''
    },

  }
  
  ordersStore = this.props.ordersStore
  globalStore = this.props.globalStore
  t = this.props.t
  approvalStates = [[{label: this.props.t('perApplyRecord.k001'), value: -1}, {label: this.props.t('perApplyRecord.k006'), value: 1}, {label: this.props.t('perApplyRecord.k008'), value: 2}, {label: this.props.t('perApplyRecord.k009'), value: 4}]];
  get approvalState() {
    let arr = this.approvalStates[0].filter(a => a.value === this.state.approvalState);
    return arr.length ? arr[0].label : this.props.t('perApplyRecord.k001');
  }

  // 行程订单金额展示
  get order_amountTrip() {
    if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.order_HideAmountTrip) {
      return false
    }
    return true
  }

  /**
   * 滚动文案
   */
  renderScrollContent = () => {
    return (
      <>
        {this.state.hasMore ? (
          <>
            <div className={style.loadingbox}>
              <div className={style.loading}>{this.t('orders.k023')}</div>
              <Loading />
            </div>
          </>
        ) : (
          <div className={style.msg}>--- {this.t('orders.k019')} ---</div>
        )}
      </>
    )
  }

  /**
   *
   * 日期组件
   */
  renderPicker = () => {
    return <div className={style.popPicker}>
      <DatePicker cancelText={this.t('common.k001')} confirmText={this.t('common.k002')} defaultValue={new Date()} visible={this.state.visible} onClose={()=> {
        this.setState({
          visible: false
        })
      }} precision="month" max={new Date()} min={new Date(moment().add(-4, 'Y'))} onConfirm={val => {
        this.reset(val)
      }}></DatePicker>
    </div>
  }
  /**
   * 审批状态
   */ 
   renderApprovalState=()=>{
    return <Picker columns={this.approvalStates} cancelText={this.t('common.k001')} confirmText={this.t('common.k002')} visible={this.state.visible1} value={[this.state.approvalState]} onConfirm={(value) => {
      if (!value || value.length === 0) {
          return
      }
      this.setState({
        approvalState: value[0],
        pageData: {
            ...this.state.pageData,
            orders: [],
            pageNum: 1,
            pageSize:15,
            hasMore: false,
            total: 0,
          }
      }, () => {
        // Toast.show({
        //   icon: 'loading',
        //   content: this.t('main.k076'),
        //   duration: 0,
        //   maskClickable: false
        // })
        setTimeout(() => {
          this.loadOrder()
        }, 400)
        this.setState({
          visible1:false
        })
      }
      )
      }} 
      onClose={() => {
        this.setState({
          visible1:false
        })
      }}>
    </Picker>
   }
  renderOrder = () => { 
    return <>
      <div className={style.orderlist}>
        {
          this.state.pageData.orders.map(item => {
            return (
              <OrdersCell
                onClick={() => {
                  this.toOrderDetails(item)
                }}
                toNext={(e) => {
                  e.stopPropagation();
                  this.props.history.push('/invoice')
                }}
                t={this.props.t}
                key={item.orderId}
                orderId={item.orderId}
                customInfo={item.customInfo ? JSON.parse(item.customInfo) : ''}
                frozenAmount={item.frozenAmount ? String(item.frozenAmount) : '0'}
                totalAmount={item.totalAmount ? String(item.totalAmount) : '0'}
                serviceType={item.serviceType} 
                orderState={item.orderState}
                invoiceState={item.invoiceState}
                departCityName={item.departCityName}
                departTime={item.departTime} 
                pickupLocation={item.pickupLocationName}
                destLocation={item.destLocationName}
                approvalState={item.approvalState}
                payState={item.payState}
                userBearAmount={item.userBearAmount}
                isAbnormal={item.isAbnormal}
              ></OrdersCell>
            )
          })
        }
      </div>
      <InfiniteScroll loadMore={this.loadOrder} hasMore={this.state.hasMore} threshold={10}>
        {this.renderScrollContent()}
      </InfiniteScroll>
    </>
  }

  /**
   * 暂无数据
   */
  renderEmpty = () => {
    return <div className={style.nodata}>
      <div className={style.box}>
        <img alt="" src={images.reportnodata}></img>
        <span>{this.t('orders.k020')}</span>
      </div>
    </div>
  }

  /**
   *
   * 渲染
   */
  render() {
    return (
      <div className={`${style.orders} ${this.state.pageData.orders.length > 0 ? style.isScroll : ""}`}>
        {/* 日期 + 金额 */}
        <div className={style.dateSelect}>
          <div onClick={() => {
            this.setState({
              visible: true
            })
          }}>
            {this.t('orders.k021')}{this.ordersStore.startTime ? this.ordersStore.startTime : '--'}&nbsp;<img alt="" src={images.triangleDown} />
          </div>
          <span></span>
          {
            (() => {
              if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.order_approval_state) {
                return(
                  <div onClick={() => {
                    this.setState({
                      visible1: true
                    })
                  }}>
                    {this.t('orders.k026')}{this.approvalState}&nbsp;<img alt="" src={images.triangleDown} />
                  </div>
                )
              }
            })()
          }
          {/* <div onClick={() => {
          }}>
            {this.t('orders.k022')}{this.state.pageData.totalPrice || '0'}
          </div> */}
        </div>
        {}
        {this.renderPicker()}
        {this.renderApprovalState()}
        {/* 主体列表 */}
        {this.state.isData && this.renderOrder()}
        {!this.state.isData && this.state.isData !== null && this.renderEmpty()}
        
        <div className={style.foot}>
            {
              (() => {
                if (this.order_amountTrip) {
                  return(
                    <div className={style.foot_statistics}>
                      {this.t('orders.k035')}<span>{this.state.pageData.totalPrice}</span>{this.t('orders.k036')}
                    </div>
                  )
                }
              })()
            }
            {
              (() => {
                if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.order_invoice) {
                  return(
                    <div className={this.order_amountTrip ? style.order_btn : style.order_invoice_btn}>
                      <Button style={
                          {
                            'background-image': 'var(--button_background_color)',
                            '--border-color': 'var(--button_border_color)',
                            'background-color': 'var(--button_border_color)',
                            'color': 'var(--button_color)'
                          }
                        } block color='primary' size='large' onClick={()=>{
                        if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.invoiceOpen_prompt) {
                          Dialog.show({
                            // 请联系平台在线客服申请开票
                            content: this.t('orders.k032'),
                            actions: [
                              {
                                key: 'cancel',
                                // 确定
                                text: this.t('common.k002'),
                              }
                            ],
                            closeOnAction: true
                          })
                        } else {
                          this.props.history.push('/invoice')
                        }
                      }}>{this.t('orders.k028')}</Button>
                    </div>
                  )
                }
              })()
            }
        </div>
        
        {/* {this.state.pageData.orders.length > 0 ? this.renderOrder(): this.renderEmpty()} */}
      </div>
    )
  }

  componentDidMount = () => {
    // Toast.show({
    //   icon: 'loading',
    //   content: this.t('main.k076'),
    //   duration: 0,
    //   maskClickable: false
    // })
    this.ordersStore.setOrderTime(moment().format('YYYY-MM'))
    this.loadOrder()
  }
  

  /**
   *
   * 卸载
   */
  componentWillUnmount = () => {
    Dialog.clear()
    this.setState = (state,callback)=>{
      return;
    };
  }

  /**
   * @param {*} props
   * 详情跳转
   */
  toOrderDetails = (item) => {
    // if (item.orderState === 5) {
    //   this.props.history.push(`/orderdetails/${item.orderId}`);
    // } else if (item.orderState === 6) {
    //   this.props.history.push(`/orderdetails/${item.orderId}`);
    // } else if (item.orderState === 7 && item.totalAmount) {
    //   this.props.history.push(`/orderdetails/${item.orderId}`);
    // }

    // if ([5, 6, 7].indexOf(item.orderState) !== -1) {
      this.props.history.push(`/orderdetails/${item.orderId}`);
    // }
  }

   /**
   *
   *日期选择
   */
  reset = (val) => {
    this.setState({
      pageData: {
        ...this.state.pageData,
        pageNum: 1,
        hasMore: false,
        pageSize: 15,
        orders: []
      }
    }, () => {
      let time = moment(val).format('YYYY-MM')
      this.ordersStore.setOrderTime(time)
      setTimeout(() => {
        this.loadOrder()
      }, 400)
    })
  }

  currentHitId = 0;

  loadOrder = async () => {
    let _currentHitId = new Date().getTime()

    if (Number(this.state.pageData.total) !== 0 && Number(this.state.pageData.total) === this.state.pageData.orders.length) {
      this.setState({
        hasMore: false
      })
      return
    }

    this.currentHitId = _currentHitId
    
    let startTime = this.ordersStore.startTime;
    Toast.show({
      icon: 'loading',
      content: this.t('main.k076'),
      duration: 0,
      maskClickable: false
    })
    let d;
    try {
      d = await webService.order.getOrder({
        dateDuration: startTime,
        pageNum: this.state.pageData.pageNum,
        pageSize: this.state.pageData.pageSize,
        approvalState:this.state.approvalState===-1?'':this.state.approvalState
      });
    } finally {
      Toast.clear();
    }
    
    if (d.data.code === 0) {
      if (this.currentHitId !== _currentHitId) {
        console.log('过期请求', this.currentHitId, _currentHitId)
        return;
      }

      let data = []
      if (d.data.data.list && d.data.data.list.length > 0) {
        data = d.data.data.list
      } else {
        data = []
      }
      this.setState({
        pageData: {
          ...this.state.pageData,
          pageNum: d.data.data.pageNum+1,
          total: d.data.data.total,
          totalPrice: d.data.data.dateTotalAmount,
          orders: [...this.state.pageData.orders, ...data]
        }
      }, () => {
        this.setState({
          hasMore: d.data.data.list.length < this.state.pageData.pageSize ? false : true
        })
        if (this.state.pageData.orders.length > 0) {
          this.setState({
            isData: true
          })
        } else {
          this.setState({
            isData: false
          })
        }
      })
    } else {
      this.setState({
        isData: false
      })
    }
    
  }
}

export default withTranslation()(Orders);