import React from 'react'

import Runing from './runing';

import { withTranslation } from "react-i18next";

class WatchShare extends React.Component {

    t = this.props.t

    render() {
        return <Runing t={this.props.t} {...this.props} isReadOnly={true}></Runing>
    }
}

export default withTranslation()(WatchShare);