import webService from './webService';
import { findCachePoi } from './webService';
import { isClient, execBridge } from './bridgeSmartcomTrip'
import { getCurrentLocation } from './jsBridge'

import { oauthType, GEO_TIMEOUT } from './config'
import { WeChatWorkJsSdk, DingJsSdk } from './utils'
import { getBaseCitys } from './localStorageHandler'

/**
 * 根据坐标补全地理位置其余四要素
 * 2022-04-01 增加poi
 * @param {*} locationGeo 
 */
let getPosition4Element = async (locationGeo) => {

    // 腾讯实现
    return new Promise((resolve, reject) => {
        let geocode = webService.map.geocoder(locationGeo.lon, locationGeo.lat);
        geocode.then(d => {
            if (d.data.status !== 0) {
                reject('反查地址失败')
                return;
            }

            let cachePoi = findCachePoi(locationGeo)

            let cityName = d.data.result.address_component.city;
            if (!cityName.length) {
                cityName = d.data.result.address_component.province;
            }
            let name = '';
            let address = d.data.result.address;
            let poi = '';
            // console.log([...d.data.result.pois])
            if (d.data.result.pois && d.data.result.pois.length) {
                d.data.result.pois = d.data.result.pois.sort((a, b) => {
                    return a._distance - b._distance
                })

                // console.log([...d.data.result.pois])
                // d.data.result.pois = d.data.result.pois.sort((a, b) => {
                //     if (a.category === '房产小区:商务楼宇' && a.category !== b.category) {
                //         return -1
                //     } else {
                //         return 0
                //     }
                // })

                // console.log([...d.data.result.pois])
                
                let firstPoi = d.data.result.pois[0];
                poi = firstPoi.id;
                name = firstPoi.title;
                address = firstPoi.address;
            }
            locationGeo.cityName = cityName.toLowerCase();
            locationGeo.address = address;
            locationGeo.name = name || address;
            locationGeo.poi = cachePoi || poi;

            let baseCitys = getBaseCitys();
            let ct = baseCitys.filter(a => a.cityName === locationGeo.cityName || a.cityPinYin === locationGeo.cityName)
            if (ct && ct.length) {
                let _ct = ct[0]
                locationGeo.cityCode = _ct.cityCode
                resolve(locationGeo)
            } else {
                webService.basCity.getCityByName({cityName: locationGeo.cityName}).then(c => {
                    if (c.data.code !== 0) {
                        reject('城市代码查询失败' + c.data.message)
                        return;
                    }
                    locationGeo.cityCode = c.data.data.cityCode;
                    resolve(locationGeo)
                })
            }
            

        })
    });

    // 高德实现
    // return new Promise((resolve, reject) => {
    //     let geocode = webService.map.geocode(locationGeo.lon, locationGeo.lat);
    //     geocode.then(d => {
    //         if (d.data.infocode !== '10000') {
    //             console.error('高德实现坐标反差地理位置失败', d.data)
    //             reject('反查地址失败')
    //             return;
    //         }
    //         let nation = d.data.regeocode.addressComponent.nation;
    //         let province = d.data.regeocode.addressComponent.province;
    //         let cityName = d.data.regeocode.addressComponent.city;
    //         if (!cityName.length) {
    //             cityName = province;
    //         }
    //         let address = d.data.regeocode.formatted_address;
    //         //let name = d.data.regeocode.pois && d.data.regeocode.pois.length ? d.data.regeocode.pois[0].name : '';
    //         let name = '';
    //         if (d.data.regeocode.pois && d.data.regeocode.pois.length) {
    //             let pois = d.data.regeocode.pois;
    //             pois = pois.sort((a, b) => { return a.distance - b.distance });
    //             name = pois[0].name;
    //         }
    //         locationGeo.cityName = cityName;
    //         locationGeo.address = address;
    //         locationGeo.name = name || address;

    //         locationGeo.nation = nation;
    //         locationGeo.province = province;

    //         webService.basCity.getCityByName({ cityName: locationGeo.cityName }).then(c => {
    //             if (c.data.code !== 0) {
    //                 reject('城市代码查询失败' + c.data.message)
    //                 return;
    //             }
    //             locationGeo.cityCode = c.data.data.cityCode;
    //             console.log(locationGeo)
    //             resolve(locationGeo)
    //         })

    //     })
    // });


}

let getCurrentPositionCount = 0
/**
 * 获取当前位置六要素
 * @returns 
 */
let getCurrentPosition = async () => {
    getCurrentPositionCount++
    return new Promise((resolve, reject) => {
        let isReturned = false;
        console.log('开始获取当前位置六要素', getCurrentPositionCount, isReturned)
        getCoords().then((res) => {
            console.log('定位超时后才返回', getCurrentPositionCount, isReturned)
            if (isReturned) {
                return
            }
            if (res.code !== 0) {
                console.log('定位失败', getCurrentPositionCount, isReturned)
                reject('定位失败');
                return;
            }
            let locationGeo = {
                lon: res.longitude + '',
                lat: res.latitude + '',
            }
            getPosition4Element(locationGeo).then(res => {
                resolve(res);
            }, res => {
                reject(res);
            });

        }).catch(() => {
            console.log('定位异常', getCurrentPositionCount, isReturned)
            if (isReturned) {
                return
            }
            reject('定位失败');
            return;
        })
        
        setTimeout(() => {
            if (!isReturned) {
                console.log('定位超时导致失败', getCurrentPositionCount, isReturned)
                reject('定位超时失败');
            }
        }, GEO_TIMEOUT);
        
    });
}

let isTripClient = isClient();
let cacheGPS = {
    timestamp: 0,
    longitude: '',
    latitude: ''
}
let sdk = new WeChatWorkJsSdk();
let dd_sdk = new DingJsSdk();

/**
 * 获得坐标
 */
const getCoords = () => {
    return new Promise(async (resolve, reject) => {
        if ((new Date().getTime() - cacheGPS.timestamp) / 1000 <= 5) {
            console.log('距离上一次定位时间小于5s，使用缓存')
            // 距离上一次定位时间小于5s
            resolve({
                code: 0,
                longitude: cacheGPS.longitude,
                latitude: cacheGPS.latitude,
            })
        } else {
            if (isTripClient) {
                // 石油商旅
                let isBackCall = false;
                console.log('204')
                execBridge('bridgeGetCurrentLocation', null, (res) => {
                    console.log('205')
                    if (isBackCall) {
                        return;
                    }
                    isBackCall = true;
                    res = JSON.parse(res)
                    if (res && res.longitude) {
                        resolve({
                            code: 0,
                            longitude: res.longitude,
                            latitude: res.latitude
                        })
                    } else {
                        reject({ code: -1, message: '定位失败' })
                    }
                }, 'bridgeGetCurrentLocation')
                setTimeout(() => {
                    if (!isBackCall) {
                        isBackCall = true;
                        reject({ code: -1, message: '定位失败' })
                    }
                }, 10000);
            } else {
                let gpsCoords = {}
                switch(oauthType) {
                    case '1': {                    
                        await sdk.init()
                        let res = await sdk.getLocation()
                        console.log('JSSDK定位返回', res)
                        if (res.longitude) {
                            gpsCoords = {
                                longitude: res.longitude,
                                latitude: res.latitude
                            }
                        } else {
                            console.error('JSSDK定位失败,转用H5定位')
                        }
                        break
                    }
                    // case '8': {
                    //     let _jsbridgeres = await getCurrentLocation()
                    //     console.log(_jsbridgeres)
                    //     if (_jsbridgeres.code === 0) {
                    //         gpsCoords = {
                    //             longitude: _jsbridgeres.longitude,
                    //             latitude: _jsbridgeres.latitude,
                    //             coordsType: _jsbridgeres.coordsType
                    //         }
                    //     }
                    //     break
                    // }
                    case '13': {
                        // 钉钉自建应用
                        await dd_sdk.init()
                        let res = await dd_sdk.getLocation()
                        console.log('JSSDK定位返回', res)
                        if (res.longitude) {
                            gpsCoords = {
                                longitude: res.longitude,
                                latitude: res.latitude,
                                coordsType: 'gcj02'
                            }
                        } else {
                            console.error('JSSDK定位失败,转用H5定位')
                        }
                        break
                    }
                    // 2024-01-30 强生放弃使用jssdk定位
                    // case '17': {
                    //     // 强生
                    //     if (dge() !== 'com-wx-mobile') {
                    //        break
                    //     }
                    //     console.log('jnj_sdk.init()')
                    //     await sdk.init(true)
                    //     console.log('jnj_sdk初始化完成，开始获取地理位置')
                    //     let res = await sdk.getLocation()
                    //     console.log('JSSDK定位返回', res)
                    //     if (res.longitude) {
                    //         gpsCoords = {
                    //             longitude: res.longitude,
                    //             latitude: res.latitude
                    //         }
                    //     } else {
                    //         console.error('JSSDK定位失败,转用H5定位')
                    //     }
                    //     break
                    // }
                    default: {
                        let _jsbridgeres = await getCurrentLocation()
                        if (_jsbridgeres.code === 0) {
                            gpsCoords = {
                                longitude: _jsbridgeres.longitude,
                                latitude: _jsbridgeres.latitude,
                                coordsType: _jsbridgeres.coordsType
                            }
                        }
                    }
                }

                if (!gpsCoords.longitude) {
                    // h5获取坐标
                    console.log('开始h5获取坐标')
                    if (navigator && navigator.geolocation) {
                        let pres = new Promise((ok, err) => {
                            console.log('navigator.geolocation 存在')
                            navigator.geolocation.getCurrentPosition(async (res) => {
                                console.log('navigator.geolocation.getCurrentPosition 返回', res)
                                gpsCoords = {
                                    longitude: res.coords.longitude,
                                    latitude: res.coords.latitude
                                }
                                ok()

                            }, async (err) => {
                                console.error('navigator.geolocation.getCurrentPosition 异常', err)
                                // localStorage.setItem('my_gcj02_location', '{"latitude": 38.861572, "longitude": 121.523175}')
                                let gcj02 = window.localStorage.getItem('my_gcj02_location')
                                if (gcj02) {
                                    gcj02 = JSON.parse(gcj02)
                                    resolve({
                                        code: 0,
                                        ...gcj02
                                    })
                                    
                                } else {
                                    console.error('H5原生定位失败', err)
                                    reject({ code: -1, message: '定位失败' })
                                }
                                ok()
                            }, {
                                enableHighAccuracy: true,
                                timeout: 2000,
                                maximumAge: 2000,
                                coordsType: 'wgs84',
                                geocode: false
                            })
                            console.log('navigator.geolocation 调用完成，等待返回')
                        })

                        await pres
                        
                    }
                }
                
                let _coords = []
                if (gpsCoords.coordsType === 'gcj02') {
                    _coords = [gpsCoords.longitude, gpsCoords.latitude]
                } else if (gpsCoords.longitude && gpsCoords.latitude) {                    
                    // try {
                    //     let _res = await webService.map.convert(gpsCoords.longitude, gpsCoords.latitude);
                    //     if (_res.data.status === '1') {
                    //         _coords = _res.data.locations.split(',');
                    //     } else {
                    //         console.error('H5原生定位成功后，进行GPS与JCG坐标系转换失败', _res.data)
                    //         reject({ code: -1, message: '定位失败' })
                    //     }
                    // } catch (e) {
                    //     console.error('H5原生定位成功后，进行GPS与JCG坐标系转换异常', e)
                    //     reject({ code: -1, message: '定位失败' })
                    //     return
                    // }

                    try {
                        let _res = await webService.map.translate(gpsCoords.longitude, gpsCoords.latitude);
                        if (_res && _res.longitude && _res.latitude) {
                            _coords = [_res.longitude, _res.latitude];
                        } else {
                            console.error('H5原生定位成功后，进行GPS与JCG坐标系转换失败', _res.data)
                            reject({ code: -1, message: '定位失败' })
                        }
                    } catch (e) {
                        console.error('H5原生定位成功后，进行GPS与JCG坐标系转换异常', e)
                        reject({ code: -1, message: '定位失败' })
                        return
                    }
                
                } else {
                    console.error('H5原生定位失败')
                    reject({ code: -1, message: '定位失败' })
                    return
                }
                
                cacheGPS = {
                    timestamp: new Date().getTime(),
                    longitude: _coords[0],
                    latitude: _coords[1]
                };
                resolve({
                    code: 0,
                    longitude: _coords[0],
                    latitude: _coords[1]
                })
                
            }
        }
    });
}


export {
    getCurrentPosition,
    getPosition4Element,
    getCoords
}