import React from "react";
import style from './tripAgreement.scss'


class TripAgreement extends React.Component {


  render() {
    return <div className={style.body}>
      <div className={style.title}>
          <strong>知情同意书</strong>
      </div>
      <div>
      本知情同意书适用于商旅所有用车用户，请您务必认真阅读。如果您/您代为叫车的乘客不同意本协议的任何内容，请您立即停止使用。当您开始使用平台，即表示您/您代为叫车的乘客已经同意我们按照本协议来收集、保存、使用、共享、保护您的个人信息。
      本协议主要包括以下内容：
      </div>
      <div>
        1 人信息的定义与范围
      </div>
      <div>
        1.1 个人信息，是指以电子或者其他方式记录的能够单独或者与其他信息结合识别自然人个人身份的各种信息，在本协议中包括：姓名、身份证件号码、通信通讯联系方式、行踪轨迹等。
      </div>
      <div>
        1.2 个人敏感信息，是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，在本协议中包括：个人身份信息（身份证、护照等）、网络身份标识信息（系统账号、邮箱地址等）、个人电话号码、通记记录和内容、行踪轨迹、精准定位、交易和消费记录等。
      </div>
      <div>
        1.3 匿名化处理后的信息，即通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后不能被复原的信息，匿名化处理后的信息不属于个人信息。
      </div>
      <div>
        2 个人信息的收集
      </div>
      <div>
        2.1 平台业务功能分为核心业务功能、附加业务功能，我们将详细向您说明各业务功能所收集的个人信息以及收集方法、目的，拒绝提供的影响等。业务功能具体包括：
      </div>
      <div>
        2.1.1 核心业务功能，包括订单发布，交易支付，开具发票，数据分析。
      </div>
      <div>
        2.1.2 附加业务功能，包括用户评价，客服服务及运营活动。
      </div>
      <div>
        2.2 您使用平台服务前，我们会收集您的以下信息：
      </div>
      <div>
        2.2.1 我们需要收集您的员工ID、姓名、手机号码、邮箱地址、部门。我们以此识别您在平台的网络身份，并提供平台服务。
      </div>
      <div>
        2.3 您使用平台相关服务时，我们会收集您的以下信息：
      </div>
      <div>
        2.3.1 位置信息，以便您不需要手动输入地理坐标即可获得相关的服务。在您首次使用平台前，我们会提示您选择是否允许平台获取以及何种情况下可以获取您的位置信息。在您使用平台的过程中，您可以随时通过您的设备设置功能，选择是否允许平台获取以及何种情况下可以获取您的位置信息。获得您的允许后，我们会通过IP地址、GPS以及能够提供相关信息的其他传感器（如会为平台提供附近设备、Wi-Fi接入点和基站信息）来获取您的位置信息。如果您拒绝平台获取位置信息，您将需要手动定位您的位置。
      </div>
      <div>
        2.3.2 行程信息，我们将通过记录用户行为收集您的上车地址、下车地址、行踪轨迹、时长信息。我们收集上述信息，是基于法律法规要求以及保护您的人身财产安全、依照平台规则处理用户纠纷之需要。
      </div>
      <div>
        2.3.3 行程信息及状态。我们将记录您的行程信息及状态。我们收集上述信息是基于法律法规要求以及保护您的人身财产安全、依照平台规则处理用户纠纷之需要。
      </div>
      <div>
        2.4 您在平台进行交易支付时，如果您使用第三方支付功能，平台除获取您的支付工具及订单支付状态外，不会获取其他个人信息。我们收集上述信息以核实订单支付费用，维护平台交易秩序，并按照平台规则处理用户纠纷。
      </div>
      <div>
        2.5 您可以匿名在平台对其他用户进行星级和文字评价，在此过程中请勿留存个人信息。
      </div>
      <div>
        2.6 当您与平台客服团队联系时，我们会以通话录音及人工录入的方式记录您的通话信息、您提出的意见或建议、您提供的姓名、联系方式，以便我们处理纠纷、解决问题或向您反馈结果。如果您拒绝我们收集此类信息，会导致您无法拨打我们的客服电话或客服团队无法将处理结果反馈到您，但不影响您使用平台核心业务功能。
      </div>
      <div>
        3 个人信息的保存
      </div>
      <div>
        3.1 信息保存期限
      </div>
      <div>
        3.1.1 在用户使用平台服务期间，我们会持续保存用户的个人信息。
      </div>
      <div>
        3.1.2 当用户被移除到企业以外，或者被商旅管理员注销账户，我们将按照法律、法规规定的最短期限保留您的现有个人信息，在法律法规要求的最短保存期限内，我们将不再对您的个人信息进行商业化使用。当您的个人信息超出上述保存期限，我们将会对其进行匿名化处理。
      </div>
      <div>
        3.2 信息存放地域
      </div>
      <div>
        3.2.1 我们收集的您的个人信息，将在中国内地存储和使用。
      </div>
      <div>
        3.2.2 目前平台不存在向境外提供个人信息的场景。如将来涉及向境外传输个人信息，我们将明确向您告知个人信息出境的目的、接收方、安全保障措施等情况，并另行征得您的同意。
      </div>
      <div>
        3.3 安全保护措施
      </div>
      <div>
        3.3.1 本公司将采用严格的安全制度以及行业通行的安全技术和程序来确保您的个人信息不丢失、泄露、毁损或被未经授权的访问、使用。
      </div>
      <div>
        3.3.2 平台采取以下安全技术措施保护您的个人信息：
        <ul>
          <li>用户个人信息被加密储存在服务器中，并通过数据隔离技术进行存储。</li>
          <li>数据传输过程中使用加密传输协议。</li>
          <li>严格控制数据访问权限，设立完善的敏感数据访问权限申请、审批制度。</li>
          <li>建立数据安全监控和审计制度，进行全面数据安全控制。</li>
        </ul>
      </div>
      <div>
        3.4 停止运营
      </div>
      <div>
        3.4.1 如平台停止运营，我们将至少提前30日在平台发布公告，并及时停止收集个人信息。
      </div>
      <div>
        3.4.2 停止运营后，我们将停止对个人信息的商业化使用，并在满足法律法规规定的最短保存期后，对收集的个人信息进行匿名化处理。
      </div>
      <div>
        4 个人信息的使用
      </div>
      <div>
        4.1 您的个人信息会被用于“个人信息的收集”条款明确列明的使用场景。
      </div>
      <div>
        4.2 您的个人信息、行程信息等会被用于对商旅的汇报及溯源依据。
      </div>
      <div>
        4.3 您的个人信息可能被用于以下与“个人信息的收集”条款所声称的目的具有直接或合理关联的场景：
      </div>
      <div>
        4.3.1 我们可能将来自平台某项服务的个人信息与来自其他项服务的信息结合起来，作出特征模型并进行用户画像，以为您提供更加个性化、便捷的服务。
      </div>
      <div>
        4.3.2 我们可能以用户个人信息统计数据为基础设计、开发、推广全新的产品及服务。
      </div>
      <div>
        4.4 凡是超出与“个人信息的收集”条款声称目的具有直接或合理关联的范围使用您的个人信息，我们会再次向您告知并征得您的明示同意。
      </div>
      <div>
        5 告知义务。
      </div>
      <div>
        5.1 告知义务的场景
      </div>
      <div>
        5.1.1 任何情况下，代替他人叫车，若您使用其手机、姓名创建行程，那么您将有义务告知该信息会由平台收集，最终回传给出行公司。
      </div>
      <div>
        6 用户权利
      </div>
      <div>
        6.1 用户若想修改、删除个人信息，以及撤回同意，请联系打车服务专线：400-075-7666。
      </div>
    </div>
  }
}

export default TripAgreement