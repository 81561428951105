import React from 'react'
import style from './stationDev.scss';
import { inject, observer } from 'mobx-react'

import { withTranslation } from "react-i18next";

import { Button, Form, Input, Dialog, TextArea } from 'antd-mobile'

import { downloadStationData, hitStation } from './../util/stationHepler'

@inject('globalStore')
@observer
class StationDev extends React.Component {
    state = {
        lat: 19.942415,
        lng: 110.460744,
        name: '站',

        station: undefined
    }

    globalStore = this.props.globalStore;


    render() {
        return <div className={style.container}>
            <div>
                <Form layout='horizontal' mode='card'>
                    <Form.Header>验证坐标是否命中场站</Form.Header>
                    <Form.Item label='经度(lat)'>
                        <Input placeholder='请输入' value={this.state.lat} onChange={(val) => {
                            this.setState({
                                lat: val
                            })
                        }} />
                    </Form.Item>
                    <Form.Item label='纬度(lng)'>
                        <Input placeholder='请输入' value={this.state.lng} onChange={(val) => {
                            this.setState({
                                lng: val
                            })
                        }} />
                    </Form.Item>
                    <Form.Item label='场站名称'>
                        <Input placeholder='请输入' value={this.state.name} onChange={(val) => {
                            this.setState({
                                name: val
                            })
                        }} />
                    </Form.Item>
                    <Form.Header />
                    <div>
                        <Button block color='primary' onClick={() => { this.loadStation() }}>验证场站</Button>
                    </div>
                    <Form.Header />
                    {
                        this.state.station ? <div>
                            <TextArea rows={10} value={ JSON.stringify(this.state.station, null, 4) } />                        
                        </div>
                        : <></>
                    }
                    
                </Form>
            </div>
        </div>
    }

    async componentDidMount() {
        let url = 'https://ipath-dev-v2-files-1309103551.cos.ap-shanghai.myqcloud.com/station/publish/2024_07_25_04_53_51.bin'
        downloadStationData(url)
    }

    async loadStation() {
        this.setState({
            station: undefined
        })
        let res = await hitStation(this.state)
        if (!res) {
            Dialog.alert({
                content: '此坐标未命中任何场站',
            })
            return
        }

        this.setState({
            station: res
        })
    }

}

export default withTranslation()(StationDev);