import React from 'react'
import style from './runingView.scss';
import { inject, observer } from 'mobx-react'
import { StarFill } from 'antd-mobile-icons'
import { TextArea, Dialog, Popup, Image } from 'antd-mobile'

import moment from 'moment'

import images from '../../util/images';
import DriverWaitingCountDown from './driverWaitingCountDown';
import CancelReason from './cancelReason';
import Concierge from './concierge'
import Address from './address';
import ShareBar from './shareBar';
import webService from './../../util/webService';
import { airportPickupToCn } from './../../util/paramsHelper';

import { ForgeRouter } from './../../util/utils'
import gc from './../../util/globalConfiguration'

import { getPosition4Element } from './../../util/geo';
import { customerServiceTelephone } from './../../util/config'
import loger from './../../util/loger'

import VerifyBaseLimitation from './../callcar/util/verifyBaseLimitation'
import { checkSensitiveWords } from './../callcar/util/checkSensitiveWords'


@inject('runingOrderStore', 'globalStore')
@observer
class RuningView extends React.Component {

    state = {
        orderDetail: this.props.orderDetail,
        baseInfo: this.props.baseInfo,
        driverArrivedTime: this.props.driverArrivedTime,
        routeInfo: this.props.routeInfo,
        scene: this.props.scene,
        airportPickup: this.props.airportPickup,
        useCarReason: this.props.baseInfo.useCarReason,
        showCancelReason: false,
        showChangeDest: false,
        showConcierge: false,
        isCancelOrder: false,
        canceReasonList: [],
        showShareBar: false // 现实分享条
    }
    runingOrderStore = this.props.runingOrderStore;
    globalStore = this.props.globalStore;
    isReadOnly = this.props.isReadOnly;

    t = this.props.t

    verifyBaseLimitationRef = undefined;

    // 司机头像
    get avatar() {
      let { orderDetail, baseInfo } = this.state;
      if (baseInfo.serviceType === 20) {
        if (orderDetail.driverInfo && orderDetail.driverInfo.avatar) {
          return orderDetail.driverInfo.avatar
        } else if (baseInfo.avatar) {
          return baseInfo.driver.avatar
        } else {
          return images.oval
        }
      }
      return orderDetail.driverInfo && orderDetail.driverInfo.avatar ? orderDetail.driverInfo.avatar : images.oval
    }

    // 车牌号
    get vehicleNo() {
      let { orderDetail, baseInfo } = this.state;
      if (baseInfo.serviceType === 20) {
        if (orderDetail.driverInfo && orderDetail.driverInfo.vehicleNo) {
          return orderDetail.driverInfo.vehicleNo
        } else if (baseInfo.driver.vehicleNo) {
          return baseInfo.driver.vehicleNo
        }
      }
      return orderDetail.driverInfo ? orderDetail.driverInfo.vehicleNo : ''
    }

    // 评分
    get level() {
      let { orderDetail, baseInfo } = this.state;
      if (baseInfo.serviceType === 20) {
        return '5.0'
      }
      return orderDetail.driverInfo && orderDetail.driverInfo.level && ['0.0'].includes(orderDetail.driverInfo.level) ? '5.0' : orderDetail.driverInfo &&  orderDetail.driverInfo.level ? orderDetail.driverInfo.level : '5.0'
    }

    // 车型号
    get vehicleModel() {
      let { orderDetail, baseInfo } = this.state;
      if (baseInfo.serviceType === 20) {
        if (orderDetail.driverInfo && orderDetail.driverInfo.vehicleModel) {
          return orderDetail.driverInfo.vehicleModel
        } else if (baseInfo.driver.vehicleModel) {
          return baseInfo.driver.vehicleModel
        }
      }
      return orderDetail.driverInfo && orderDetail.driverInfo.vehicleModel ? orderDetail.driverInfo.vehicleModel : ''
    }

    // 车颜色
    get vehicleColor() {
      let { orderDetail, baseInfo } = this.state;
      if (baseInfo.serviceType === 20) {
        if (orderDetail.driverInfo && orderDetail.driverInfo.vehicleColor) {
          return orderDetail.driverInfo.vehicleColor ? orderDetail.driverInfo.vehicleColor : ''
        } else if (baseInfo.driver && baseInfo.driver.vehicleColor) {
          return baseInfo.driver.vehicleColor ? baseInfo.driver.vehicleColor : ''
        }
      }
      return orderDetail.driverInfo && orderDetail.driverInfo.vehicleColor ? orderDetail.driverInfo.vehicleColor : ''
    }

    /**
     * 司机手机号
     */
    get driverPhoneNumber() {
        let { orderDetail, baseInfo } = this.state;

        // 已登录用户取得当前手机号，未登录则手机号为空字符串
        let currentUserPhone = (this.globalStore.userInfo && this.globalStore.userInfo.phone) || ''

        // 乘车人用手机号
        let driverPhoneVirtual = ((orderDetail.driverInfo && orderDetail.driverInfo.replaceCallPhone) || ((orderDetail.driverInfo && orderDetail.driverInfo.phoneVirtual) || (orderDetail.driverInfo && orderDetail.driverInfo.phone) || (baseInfo.driver && baseInfo.driver.driverPhoneVirtual)))
        // 叫车人用手机号
        let phoneForUser = (orderDetail.driverInfo && orderDetail.driverInfo.phoneForUser) || ''

        if (!currentUserPhone) {
            // 当前未登录，返回乘车人用手机号
            return driverPhoneVirtual
        } else if (currentUserPhone === baseInfo.passengerPhone) {
            // 当前已登录，且叫车人就是乘车人，返回乘车人用手机号
            return driverPhoneVirtual
        } else {
            // 否则当成代叫车
            return phoneForUser || driverPhoneVirtual
        }
    }

    get carSourcecarLevelName() {
      let { orderDetail, baseInfo } = this.state;
      if (baseInfo.serviceType === 20) {
        if (orderDetail.driverInfo && orderDetail.source && orderDetail.source.carLevelName) {
          return `${orderDetail.source.carSource} ${orderDetail.source.carLevelName}${orderDetail.source && orderDetail.source.carLevelName && orderDetail.source.carLevelName.indexOf('型') === orderDetail.source.carLevelName.length - 1 ? '' : '型'}`
        } else if (baseInfo.carRentSource && baseInfo.carRentLevelName) {
          return `${baseInfo.carRentSource ? baseInfo.carRentSource : ''} ${baseInfo.carRentLevelName ? baseInfo.carRentLevelName : ''}${baseInfo.carRentLevelName && baseInfo.carRentLevelName && baseInfo.carRentLevelName.indexOf('型') === baseInfo.carRentLevelName.length - 1 ? '' : '型'}` 
        }
      }
      return `${orderDetail.source && orderDetail.source.carSource ? orderDetail.source.carSource : ''} ${orderDetail.source && orderDetail.source.carLevelName ? orderDetail.source.carLevelName : ''}${orderDetail.source && orderDetail.source.carLevelName && orderDetail.source.carLevelName.indexOf('型') === orderDetail.source.carLevelName.length - 1 ? '' : orderDetail.source && orderDetail.source.carLevelName ? '型' : ''}`
    }

    get isShowBar() {
      if (this.state.baseInfo.serviceType === 20 && [6, 7].includes(this.state.baseInfo.state)) {
        return false
      }
      return true
    }

    get monthDayEn() {
      let month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][moment(this.state.baseInfo.departTime ? this.state.baseInfo.departTime : '').month()]
      let day = moment(this.state.baseInfo.departTime ? this.state.baseInfo.departTime : '').format('DD').replace('0', '')
      return {
        month:month,
        day: day
      }
    }

    // 隐藏一键报警
    get isShowCallPolice() {
      return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.ongoing_hide_call_police ? false : true
    }

    // 隐藏呼叫客服按钮
    get isShowService() {
      return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.ongoing_hide_service ? false : true
    }


    // 新旧司机一样提示
    get isSameMsg() {
      if (this.props.isSameMsg && this.state.baseInfo.state === 2) {
        return true
      }
      return false
    }

    // base 地 check 开关
    get isVerifyBaseLimitation() {
      return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.main_base_check ? true : false
    }

    // 敏感词开关
    get isCheckSensitiveWords() {
        return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.main_base_check ? true : false
    }

    // 取消时限
    get cancel_timeline() {
        if (this.props.driverTakedTime) {
          return moment(this.props.driverTakedTime).add(3, 'm').format('HH:mm')
        }
        return ''
    }

    // 是否开启取消费用提示
    get isOpenCancelTip() {
        let { baseInfo } = this.state;
        return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.ongoing_cancellation_fee && [2, 3].includes(baseInfo.state);
    }

    get starColor() {
        if (gc.process.isMeituan) {
            return '#FF6A00'
        } else {
            return !this.props.isGreenTravel ? '#F5A623' : '#42a737'
        }
        
    }
    /**
     * 获取表示更改目的地的图标
     * 如果允许更改目的地且非绿色旅行模式，返回黄色icon
     * 如果允许更改目的地且为绿色旅行模式，返回绿色icon
     * 如果不允许更改目的地，返回更改目的地禁用图标
     */
    get changeDestIcon() {
      if (this.state.scene.isAllowChangeDest) {
        if (!this.props.isGreenTravel) {
          return images.changeDest
        } else {
          return images.changeDestGreen
        }
      } else {
        return images.changeDestTaboo
      }
    }

    render() {
        let { orderDetail, baseInfo, driverArrivedTime, routeInfo, airportPickup, scene } = this.state;
        return <div className={style.runing}>
          {
            this.props.isGreenTravel ? <div className={style.displacement}>
              <div className={style.cell}>
                <Image
                  src={images.smallBellGreen}
                  width={14}
                  height={14}
                  fit='cover'
                />
                {this.t('runing.k0109', {carbonEmission: this.state.baseInfo.carbonEmission})}
              </div>
            </div> : <></>
          }
            
            {
                (() => {
                    return (
                        <div className={style.departure_base}>
                            <div className={style.departure}>
                                {
                                    (() => {
                                        if ((baseInfo.serviceType === 2 || baseInfo.serviceType === 7 || baseInfo.serviceType === 6 || baseInfo.serviceType === 20) && baseInfo.state <= 3) {
                                            return (
                                                <>
                                                    {/* k096 出发 */}
                                                    <div className={style.set_out}>
                                                        {this.t('runing.k096', {timeZh:moment(baseInfo.departTime).format('M月DD日 HH:mm'), hourEn:moment(baseInfo.departTime).format('HH:mm'), timeEn: this.monthDayEn.month + ' ' + this.monthDayEn.day + 'th'})} {this.props.flightNumber ? '(' + this.props.flightNumber + ')' : ''}
                                                    </div>
                                                    {/* 临近出发时间展示 */}
                                                    {
                                                    this.props.isScheduledApproaching ? <div className={style.address}>
                                                        {this.t('runing.k009')}
                                                    </div> : <></>
                                                    }
                                                    {/* 判断如果是代价车并且是客服手机小号代叫车 */}
                                                    {/* k0102 请在上车后告知司机尾号为 */}
                                                    {
                                                    baseInfo.serviceType === 2 && this.props.proxyPhone && baseInfo.state <= 3 ?
                                                            <div className={style.remind}>
                                                                <Image
                                                                    src={!this.props.isGreenTravel ? images.smallBell : images.smallBellGreen}
                                                                    width={14}
                                                                    height={14}
                                                                    fit='cover'
                                                                />
                                                                {this.t('runing.k0102')}{this.props.proxyPhone}
                                                            </div> : <></>
                                                    }
                                                </>
                                            )
                                        }
                                    })()
                                }
                                {
                                    (() => {
                                        if (baseInfo.state === 2 || baseInfo.state === 4) {
                                            if (routeInfo.distance !== -1 && routeInfo.time !== -1) {
                                                return (<div className={style.message}>{this.t('runing.k024')}<span className={style.distance}>{this.distanceUnitValue}</span>{this.distanceUnitName} {this.t('runing.k025')}<span className={style.time}>{this.time}</span>{this.t('runing.k026')}</div>)
                                            }
                                        } else if (baseInfo.state === 3 && driverArrivedTime && baseInfo.serviceType !== 20) {
                                            return <DriverWaitingCountDown t={this.props.t} driverArrivedTime={driverArrivedTime} />
                                        }
                                    })()
                                }
                                {
                                    (() => {
                                        if (this.isSameMsg) {
                                            return <div className={`${style.message} ${style.small_bell_one}`}>
                                            {/* 当前附近司机较少，已为您匹配最优司机 */}
                                                {this.t('runing.k0137')}
                                            </div>
                                        }
                                    })()
                                }
                                {
                                    (() => {
                                        if (orderDetail.isRelay && orderDetail.preOrder && !this.props.isScheduledApproaching) {
                                            // 显示临近出发时间可见司机位置的时候，不显示这个接力单提示
                                            return <div className={`${style.message} ${style.small_bell}`}>
                                            {/* 当前订单为接力单，司机即将完成上一单，请耐心等待 */}
                                            {this.t('runing.k0136')}
                                            </div>
                                        }
                                    })()
                                }
                            </div>
                        </div>
                    )
                })()
            }

            {
                airportPickup ?
                <div className={style.tip}>
                    <div>
                        {
                            (() => {
                                if (airportPickup) {
                                    return (<div className={style.airportPickup} onClick={() => {
                                        if (airportPickup.state < 2) {
                                            this.setState({
                                                showConcierge: true
                                            })
                                        }

                                    }}>
                                        {this.t('runing.k027')}<span> ▪ {airportPickupToCn(airportPickup.state, this.t)}</span>
                                    </div>)
                                }
                            })()
                        }
                        <Popup visible={this.state.showConcierge} onMaskClick={() => {
                            this.setState({
                                showConcierge: false
                            })
                        }}>
                            <Concierge t={this.props.t} airportPickup={airportPickup} orderId={this.props.orderId} onClose={() => {
                                this.setState({
                                    showConcierge: false
                                })
                            }} />
                        </Popup>
                    </div>

                </div>
                : <></>
            }
            

            <div className={style.driver}>
                <div className={style.avatar}>
                    <img src={this.avatar} alt={this.t('runing.k028')} />
                </div>
                <div className={style.info}>
                    <div>
                        <div>{this.vehicleNo}</div>
                        <div className={!this.props.isGreenTravel ? style.star : style.starGreen}>
                            &nbsp;<StarFill  color={this.starColor}/>{this.level ? (this.level * 1.0).toFixed(1) : '0.0'}
                        </div>
                    </div>
                    <div>{this.vehicleModel} {this.vehicleColor}</div>
                    {/* {
                        (() => {
                            if (orderDetail.source && orderDetail.source.carLevelName) {
                                return <div>{orderDetail.source.carSource} {orderDetail.source.carLevelName}{orderDetail.source.carLevelName.indexOf('型') === orderDetail.source.carLevelName.length - 1 ? '' : '型'}</div>
                            }
                        })()
                    } */}
                    <div>{this.carSourcecarLevelName}</div>

                </div>
                <div className={style.phone}>
                    {
                        this.driverPhoneNumber
                            ? <img src={!this.props.isGreenTravel ? images.callDriver : images.callDriverGreen} alt={this.t('runing.k029')} onClick={() => {
                              loger.debug('拨打电话', this.driverPhoneNumber)
                              window.top.location.href = "tel://" + this.driverPhoneNumber
                            }} />
                            : <></>
                    /* <img src={images.callDriver} alt='拉起拨号盘' onClick={() => {window.top.location.href="tel://" + orderDetail.driverInfo.phoneVirtual}} /> */}
                </div>
            </div>
            {
                (() => {
                    if (this.isOpenCancelTip) {
                        return baseInfo.serviceType === 1
                        ? <div className={style.cancel_tip}>
                          {
                           this.globalStore.userInfo.language === 1 ? <div>
                              感谢耐心等待，若您的行程有变，您可在<span>{this.cancel_timeline}</span>前免费取消。
                            </div> : <div>Thank you for your patience, if your itinerary changes, you can cancel for free until <span>{this.cancel_timeline}</span></div>
                          }
                        </div>
                        : <div className={style.cancel_tip}>
                            {this.t('runing.k0141')}
                        </div>
                    }
                })()
            }
            {
                !this.isReadOnly 
                    ? <div className={style.toolbar}>
                      {
                        this.isShowBar ? 
                        <div className={`${style.tools} ${this.isOpenCancelTip ? style.no_border : ''}`}>
                            {
                                (() => {
                                    if (baseInfo.state < 4 || baseInfo.state === 7) {
                                        return (<div>
                                          {
                                            baseInfo.serviceType === 20 && (this.state.isCancelOrder || baseInfo.charterCarCancel) && baseInfo.state !== 7?
                                            <div className={style.grey_color}>
                                              <div>
                                              <img src={images.cancelOrder} alt={this.t('runing.k007')}/>
                                              </div>
                                              <div>
                                                  取消中
                                              </div>
                                            </div> :
                                            <>
                                              <div>
                                                  <img src={!this.props.isGreenTravel ? images.cancelOrder : images.cancelOrderGreen} alt={this.t('runing.k007')} onClick={baseInfo.serviceType === 20 ? this.props.cancelOrder : this.cancelOrder} />
                                              </div>
                                              <div onClick={() => baseInfo.serviceType === 20 ? this.props.cancelOrder() : this.cancelOrder}>
                                                  {this.t('runing.k007')}
                                              </div>
                                            </>
                                          }
                                            <Popup
                                                visible={this.state.showCancelReason}
                                                getContainer={() => document.getElementsByClassName('_35igKtyh8Z_Ydxur-tmrM_')[0]}
                                                onMaskClick={() => {
                                                    this.setState({
                                                        showCancelReason: false
                                                    })
                                                }}
                                            >
                                                <CancelReason t={this.props.t} onClick={(confirm, reason) => {
                                                    if (confirm && reason) {
                                                        this.props.cancelOrder(reason)
                                                    } else {
                                                      let canceReasonList = this.state.canceReasonList
                                                      for(let i in canceReasonList) {
                                                        let item = canceReasonList[i]
                                                        for(let j in item.children) {
                                                          if (item.children[j].label === reason) {
                                                            item.children[j].checked = true
                                                          } else {
                                                            item.children[j].checked = false
                                                          }
                                                        }
                                                      }
                                                      this.setState({
                                                        canceReasonList: canceReasonList
                                                      })
                                                    }
                                                    if (confirm && !reason) {
                                                      this.setState({
                                                        showCancelReason: false
                                                      })
                                                    }
                                                }} list={this.state.canceReasonList} cancelText={this.t('runing.k034')} />
                                            </Popup>
                                        </div>)
                                    }
                                    if (baseInfo.state === 4 && baseInfo.serviceType !== 20) {
                                        if (baseInfo.allowChangeDestScene) {
                                            return (<div>
                                                <div className={style.changeDest}>
                                                    <img src={this.changeDestIcon} alt={this.t('runing.k035')} onClick={this.changeDest} />
                                                </div>
                                                <div onClick={this.changeDest} className={scene.isAllowChangeDest ? style.changeBlack : style.changeGrey}>
                                                    {this.t('runing.k035')}
                                                </div>
                                                <Popup
                                                    visible={this.state.showChangeDest}
                                                    bodyStyle={{ height: '100%' }}
                                                    onMaskClick={() => {
                                                        this.setState({
                                                            showChangeDest: false
                                                        })
                                                    }}
                                                >
                                                    {
                                                        this.state.showChangeDest
                                                            ? (<Address t={this.props.t} onChange={(addr) => { this.postChangeDest(addr) }}
                                                                isCommonlyAddress={true}
                                                                onCancel={() => {
                                                                    // this.setState({
                                                                    //     showChangeDest: false
                                                                    // })
                                                                    this.props.history.goBack();
                                                                }}
                                                                isBegin={false} history={this.props.history} cityCode={baseInfo.destCityCode} cityName={baseInfo.destCityName}
                                                            ></Address>)
                                                            : (<></>)
                                                    }

                                                </Popup>

                                            </div>)
                                        }
                                    }
                                })()
                            }

                            {
                              (() => {
                                if (baseInfo.serviceType !== 20 && this.isShowCallPolice) {
                                  return(
                                    <div>
                                      <div>
                                          <img src={!this.props.isGreenTravel ? images.alarmOrder : images.alarmOrderGreen} alt={this.t('runing.k036')} onClick={this.callPolice} />
                                      </div>
                                      <div onClick={this.callPolice}>
                                          {this.t('runing.k036')}
                                      </div>
                                    </div>
                                  )
                                }
                              })()
                            }
                            {
                                (() => {
                                    if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.ongoing_share) {
                                        // 非石油商旅，则显示分享
                                        if (baseInfo.serviceType !== 20) {
                                          return <div>
                                              <div>
                                                  <img src={!this.props.isGreenTravel ? images.shareOrder : images.shareOrderGreen} alt={this.t('runing.k037')} onClick={this.share} />
                                              </div>
                                              <div onClick={this.share} >
                                                  {this.t('runing.k037')}
                                              </div>
                                          </div>
                                        }
                                    }
                                })()
                            }

                            {
                                (() => {
                                    if (baseInfo.state !== 7 && baseInfo.serviceType !== 20) {

                                        // if(baseInfo.state <= 4) {
                                        //   return(
                                        //     <div>
                                        //         <div onClick={this.useCarReason}>
                                        //             <img src={images.reasonOrder} alt='用车原因' />
                                        //         </div>
                                        //         <div onClick={this.useCarReason}>
                                        //             用车原因
                                        //         </div>
                                        //     </div>
                                        //   )
                                        // }

                                        if (baseInfo.state > 4) {
                                          if (baseInfo.serviceType !== 20) {
                                            return (
                                                <div>
                                                    <div onClick={this.ordereValuate}>
                                                        <img src={!this.props.isGreenTravel ? images.reasonOrder : images.reasonOrderGreen} alt={this.t('runing.k039')} />
                                                    </div>
                                                    <div onClick={this.ordereValuate}>
                                                        {this.t('runing.k039')}
                                                    </div>
                                                </div>
                                            )
                                          }
                                        }
                                        // return baseInfo.state < 5
                                        //     ? <div>
                                        //         <div onClick={this.useCarReason}>
                                        //             <img src={images.reasonOrder} alt='用车原因' />
                                        //         </div>
                                        //         <div onClick={this.useCarReason}>
                                        //             用车原因
                                        //         </div>
                                        //     </div>
                                        //     : <div>
                                        //         <div onClick={this.ordereValuate}>
                                        //             <img src={images.reasonOrder} alt='订单评价' />
                                        //         </div>
                                        //         <div onClick={this.ordereValuate}>
                                        //             订单评价
                                        //         </div>
                                        //     </div>
                                    }
                                })()

                            }
                            {
                              (() => {
                                if (baseInfo.serviceType !== 20 && this.isShowService) {
                                  return(
                                  <div>
                                    <div onClick={() => { this.call(customerServiceTelephone); }}>
                                        <img src={!this.props.isGreenTravel ? images.kefu : images.kefuGreen} alt={this.t('runing.k076')} />
                                    </div>
                                    <div onClick={() => { this.call(customerServiceTelephone); }}>
                                        {this.t('runing.k076')}
                                    </div>
                                  </div>
                                  )
                                }
                              })()
                            }
                            <Popup
                              visible={this.state.showShareBar}
                              bodyStyle={{ height: 'auto', backgroundColor: 'rgba(0, 0, 0, 0)' }}
                              onMaskClick={() => {
                                  this.setState({
                                      showShareBar: false
                                  })
                              }}
                            >
                              <ShareBar
                                  baseInfo={this.state.baseInfo}
                                  t={this.props.t}
                                  orderId={this.props.orderId} 
                                  isGreenTravel={this.props.isGreenTravel} 
                                  history={this.props.history} 
                                  onClose={() => {
                                      this.setState({
                                          showShareBar: false
                                      })
                                  }}
                              />
                            </Popup>

                        </div> : <></>
                      }
                    </div>
                    : <></>
            }

            <VerifyBaseLimitation ref={(dom) => { this.verifyBaseLimitationRef = dom }} t={this.t}></VerifyBaseLimitation>
        </div>
    }

    componentWillReceiveProps(props) {
        this.setState({
            orderDetail: props.orderDetail,
            baseInfo: props.baseInfo,
            driverArrivedTime: props.driverArrivedTime,
            routeInfo: props.routeInfo,
            airportPickup: props.airportPickup,
            scene: props.scene
        })
    }

    componentDidMount() {
      if (!this.isReadOnly) {
        this.getCancelReasons()
      }
    }


    /**
     * 距离米
     */
    get distance() {
        let distance = 0
        // if (gc.process.isJnjOauthType && this.state.baseInfo.state === 2) {
        //   distance = gc.computed.segmentedDiscount(this.state.routeInfo.distance, 
        //       [
        //           {
        //               limit: 1000,
        //               ratio: 0.7
        //           }
        //       ])
        // }
        //console.log('前', this.state.routeInfo.distance)
        if (this.props.distanceList && this.props.distanceList.length > 0 && this.state.baseInfo.state === 2) {
          distance = gc.computed.segmentedDiscount(this.state.routeInfo.distance, this.props.distanceList)
          //console.log('后', this.state.routeInfo.distance)
        }  else {
          distance = this.state.routeInfo.distance
        }
        // 四舍五入，干掉小数
        distance = Number(distance).toFixed()
        return distance
    }

    /**
     * 距离，适合人类理解之单位数值
     */
    get distanceUnitValue() {
        let distance = this.distance
        if (distance >= 1000) {
            let _distance = distance / 1000
            return Number(_distance.toFixed(1))
        }
        return distance
    }

    /**
     * 距离，适合人类理解之单位名称
     */
    get distanceUnitName() {
        let distance = this.distance
        if (distance >= 1000) {
            return this.t('runing.k010');
        }
        return this.t('runing.k011');
    }

    get time() {
        // if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.ongoing_arriveTimeShorten) {
        //     if (this.state.routeInfo.time > 1) {
        //         return Math.round(this.state.routeInfo.time * 0.8)
        //     } else {
        //         return 1
        //     }
        // } else if (gc.process.isJnjOauthType && this.state.baseInfo.state === 2) {
        //     return gc.computed.segmentedDiscount(this.state.routeInfo.time, 
        //         [
        //             {
        //                 limit: 5,
        //                 ratio: 0.8
        //             }
        //         ])

        // }
        // console.log('前time', this.state.routeInfo.time)
        if (this.props.timeList && this.props.timeList.length > 0 && this.state.baseInfo.state === 2) {
          //console.log('后time', gc.computed.segmentedDiscount(this.state.routeInfo.time, this.props.timeList))
          return gc.computed.segmentedDiscount(this.state.routeInfo.time, this.props.timeList)
        } else {
            return this.state.routeInfo.time
        }
    }

    /**
     * 取消订单
     */
    cancelOrder = () => {
        if (this.state.baseInfo.state > 3) {
            // 行程开始后不可取消订单
            return;
        }

        this.setState({
            showCancelReason: true
        })
    }

    /**
     * 修改目的地
     */
    changeDest = () => {
        if (!this.state.scene.isAllowChangeDest) {
            return;
        }
        this.setState({
            showChangeDest: true
        }, () => {
            let fr = new ForgeRouter(() => {
                let { hash } = document.location;
                if (hash.indexOf('runing') !== -1) {
                    this.setState({
                        showChangeDest: false
                    })

                }
            });
            fr.onpopstate();
        })
    }

    /**
     * 发起修改目的地请求
     * @param {*} address 
     */
    postChangeDest = async (address) => {

        //   this.setState({
        //     showChangeDest: false
        //   });

        this.props.history.goBack();

        let a = {};
        try {
            // 使用司机坐标为新路径起点,无需获取叫车人手机位置
            //a = await getCurrentPosition();
            let _geo = await webService.order.getDriverInfo({ orderId: this.props.orderId });
            if (_geo.data.code === 0 && _geo.data.data.driverPosition) {
                a = await getPosition4Element({
                    lon: _geo.data.data.driverPosition.lng,
                    lat: _geo.data.data.driverPosition.lat
                });
            } else {
                Dialog.alert({
                    title: this.t('runing.k040'),
                    content: this.t('runing.k041'),
                    confirmText: this.t('common.k003')
                });
                return;
            }

        } catch (e) {
            Dialog.alert({
                content: e,
                confirmText: this.t('common.k003')
            });
            return;
        }

        let cdest = {
            orderId: this.props.orderId,
            departLat: a.lat,
            departLng: a.lon,
            departCityCode: a.cityCode,
            departCityName: a.cityName,
            pickupLocationName: a.name,
            pickupLocation: a.address || a.name,
            destLat: address.lat,
            destLng: address.lon,
            destCityCode: address.cityCode,
            destCityName: address.cityName,
            destLocationName: address.name,
            destLocation: address.address || address.name,
            sceneId: this.state.baseInfo.sceneId,
            scenePublishId: this.state.baseInfo.scenePublishId,
            serviceType: this.state.baseInfo.serviceType
        };

        if (this.isVerifyBaseLimitation) {
            let { baseInfo } = this.state
            // 需要判断base地限制
            let addr = {
                begin: {
                    cityCode: baseInfo.departCityCode,
                    cityName: baseInfo.departCityName,
                    lat: baseInfo.departLat,
                    lon: baseInfo.departLng
                },
                end: {
                    cityCode: cdest.destCityCode,
                    cityName: cdest.destCityName,
                    lat: cdest.destLat,
                    lon: cdest.destLng,
                }
            }

            //let res = await verifyBaseLimitation({sceneId: this.state.baseInfo.sceneId, ...addr})
            let res = await this.verifyBaseLimitationRef.check({sceneId: this.state.baseInfo.sceneId, ...addr})
            loger.debug('终点base验证结果', res)
            if (res) {
                if (res.action === 0) {
                    // 不合规,用户选择取消，则放弃修改目的地
                    return
                } else if (res.action === 1) {
                    if (res.data) {
                        loger.debug(res.data)
                        cdest.isUserPay = res.data.isUserPay;
                        cdest.isNotUserPayReason = res.data.text || res.data.label;
                    }
                }
            }
        }

        if (this.isCheckSensitiveWords) {
            let res = await checkSensitiveWords({
                sceneId: this.state.baseInfo.sceneId,
                pickupLocationName: this.state.baseInfo.pickupLocationName,
                destLocationName: address.name
            })
            if (res && res.length) {
                return
            }
        }

        let d = await webService.order.changeDestEstimate(cdest);

        if (d.data.code !== 0) {
            Dialog.confirm({
                cancelText: this.t('runing.k042'),
                confirmText: this.t('runing.k043'),
                content: d.data.message,
                onConfirm: () => {
                    this.changeDest();
                }
            })
            return;
        }

        Dialog.confirm({
            cancelText: this.t('runing.k042'),
            confirmText: this.t('runing.k044'),
            title: this.t('runing.k045'),
            content: `${this.t('runing.k046')}[${address.name}]${this.t('runing.k047')}${d.data.data.estimatePrice}${this.t('runing.k070')}`,
            onConfirm: async () => {
                cdest.dynamicCode = d.data.data.dynamicCode;
                let dd = await webService.order.changeDest(cdest);
                if (dd.data.code === 1004001) {
                    Dialog.alert({
                        title: this.t('runing.k0142'),
                        content: this.t('runing.k0143'),
                        confirmText: this.t('common.k002')
                    })
                } else if (dd.data.code !== 0) {
                    Dialog.alert({
                        content: dd.data.message,
                        confirmText: this.t('common.k003')
                    })
                } else {
                    this.props.loadOrderBaseInfo();
                    Dialog.alert({
                        content: this.t('runing.k048'),
                        confirmText: this.t('common.k003')
                    })
                }
            }
        })
    }

    /**
     * 报警
     */
    callPolice = () => {
        this.props.history.push(`/callPolice/${this.props.orderId}`)
    }

    /**
     * 提交用车原因
     */
    useCarReason = () => {

        Dialog.confirm({
            confirmText: this.t('runing.k049'),
            cancelText: this.t('runing.k042'),
            title: this.t('runing.k050'),
            bodyClassName: `${style.prompt}`,
            content: (<>
                <div>
                    <TextArea placeholder={this.state.scene.usedReasonTips} maxLength={100} rows={3} defaultValue={this.state.useCarReason}
                        onChange={(val) => {
                            this.setState({
                                useCarReason: val
                            })
                        }} />
                </div>
            </>),
            onConfirm: () => {
                webService.order.setReason({
                    orderId: this.props.orderId,
                    reason: this.state.useCarReason
                }).then(d => {
                    if (d.data.code !== 0) {
                        Dialog.alert({
                            content: d.data.message ? d.data.message : this.t('runing.k040'),
                            confirmText: this.t('common.k003')
                        })
                    }
                })
            }
        })
    }

    /**
     * 跳到分享页
     */
    share = async () => {
      this.setState({
        showShareBar: true
      })
    }

    /**
     * 订单评价
     */
    ordereValuate = () => {
        this.props.history.push(`/orderevaluate/${this.props.orderId}`)
    }

    /**
     * 呼叫客服
     */
    call = (tel) => {
        setTimeout(() => {
            window.top.location.href = `tel://${tel}`;
        }, 200);

    }

    setCancelOrder = () => {
      this.setState({
        isCancelOrder: true
      })
    }

    getCancelReasons = async () => {
      let {data} = await webService.order.getCancelReasons()
      if (data.code === 0) {
        let list = data.data.cancelReasons
        for(let i in list) {
          for(let j in list[i].children) {
            list[i].children[j].checked = false
          }
        }
        this.setState({
          canceReasonList: list
        })
      }
    }
}

export default RuningView;