import { observable, action, makeObservable } from "mobx";

class EstimateStore {

    constructor() {
        makeObservable(this)
    }

    @observable choosedEstimateCarLevel = 0;
    @observable choosedEstimates = [];
    @observable estimateId = '';
    @observable checkedCars = []
    @observable carMode = 0

    /**
     * 预估价格存储初始化
     */
    @action.bound init() {
        this.choosedEstimateCarLevel = 0;
        this.choosedEstimates = [];
        this.estimateId = '';
    }

    @action.bound clear() {
        this.init()
    }

    /**
     * 设置股价序列号
     * @param {*} estimateId 
     */
    @action.bound setEstimateId(estimateId) {
        this.estimateId = estimateId;
    }

    /**
     * 设置选中的车型(仅对只能模式有效)
     * @param {*} choosedEstimateCarLevel 
     */
    @action.bound setChoosedEstimateCarLevel(choosedEstimateCarLevel) {
        this.choosedEstimateCarLevel = choosedEstimateCarLevel;
    }
    
    /**
     * 设置选中的运力平台
     * @param {*} choosedEstimates 
     */
    @action.bound setChoosedEstimates(choosedEstimates) {
        this.choosedEstimates = choosedEstimates;
    }

    /**
     * 设置之前全选过某个车型
     * @param {*} info 
     */
     @action.bound setCheckedCars(cars) {
      this.checkedCars = cars
  }

    /**
     * 复位下单参数
     * @param {*} data 
     */
    @action.bound reset(data) {
        this.choosedEstimateCarLevel = data.choosedEstimateCarLevel;
        this.choosedEstimates = data.choosedEstimates;
    }

    /**
     * 设置下单模式
     * @param {*} data 
     */
    @action.bound setCarMode(mode) {
      this.carMode = mode
    }

}

export default new EstimateStore();

