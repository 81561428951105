import React from "react";
import {inject, observer } from 'mobx-react'

import style from './dispatchMode.scss'
@inject('estimateStore')
@observer
class DispatchMode extends React.Component {
  state = {
    modeList: []
  }
  
  t = this.props.t

  estimateStore = this.props.estimateStore

  componentDidMount() {
    this.setState({
      modeList: this.props.dispatchModeList
    })
  }

  render() {
    return(
      <div className={style.set_mode}>
          <div className={style.title}>{this.t('runing.k0135')}</div>
          <div className={style.mode_box}>
            {
              this.state.modeList.map(t => {
                return (
                  <div className={style.car_mode} key={t.value}>
                    <div className={style.message}>
                      <img alt="" src={t.url} />
                      <div className={style.illustrate}>
                        <span>{t.text}</span>
                        <p>{t.description}</p>
                      </div>
                    </div>
                    <div onClick={() => {this.checkModeCar(t)}} className={t.checked ? style.checked : style.default_checked}></div>
                  </div>
                )
              })
            }
            

          </div>
      </div>
    )
  }
  /**
   * 选择派车模式
   * @param {*} c 
   */
  checkModeCar = (c) => {
    this.state.modeList.forEach(item => {
      item.checked = false
      if (item.value === c.value) {
        item.checked = true;
      }
    })
    this.setState({
      modeList: this.state.modeList
    })
    this.props.onChange()
    this.estimateStore.setCarMode(c.value)
  }
}

export default DispatchMode