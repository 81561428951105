import React from "react";
import style from './cycleText.scss';

import images from '../../util/images'


import { Swiper } from 'antd-mobile'

class cycleText extends React.Component {

    t = this.props.t

    state = {
        list: [
          {
            name: this.t('runing.k091'), // '预约管家开始为您服务...'
            id:1
          },
          {
            name: this.t('runing.k092'), // 正在全力为您呼叫司机...
            id:2
          },
          {
            name: this.t('runing.k093'), // 预计很快完成本次预约...
            id:3
          },
          {
            name: this.t('runing.k094'), // 请您保持耐心继续等待...
            id:4
          }
        ]
    }


    render() {
        return <>
            {this.state.list && this.state.list.length
                ? <div className={style.notice}>
                    <Swiper direction='vertical' autoplay='true' loop='true' allowTouchMove={false} autoplayInterval={3000} style={{ '--height': '100%' }} indicator={() => { return <></> }}>
                        {
                            this.state.list.map(item => {
                                return <Swiper.Item key={item.id}>
                                  <span className={style.message}>{item.name}</span>
                                </Swiper.Item>
                            })
                        }
                    </Swiper>
                    <img alt="" className={style.loopImg} src={images.loopLoading} />
                </div>
                : <></>}

        </>
    }

    componentDidMount = async () => {

    }

}

export default cycleText