import React from 'react';
import style from './main.scss';
import { inject, observer } from 'mobx-react'
import { Popup, Badge, Toast, Modal, Dialog, NoticeBar } from 'antd-mobile'
import { ExclamationCircleOutline, LeftOutline } from 'antd-mobile-icons'

// import AppRouter from './../component/router/index'

import { getCurrentPosition, getPosition4Element } from './../util/geo';

import Menu from './../component/callcar/menu'
import CallCar from './../component/callcar/callcar'
import PromotionNewUser from '../component/promotion/promotionNewUser';
import Promotion from '../component/promotion/promotion';
import Notice from '../component/promotion/notice'
import Ad from '../component/promotion/ad'
import LetterAgreement from '../component/promotion/letterAgreement'
import PrivacyAgreement from '../component/promotion/privacyAgreement'
import RecommendPath from './../component/callcar/recommendPath'

import images from './../util/images'

import webService from './../util/webService'
import recommendPoint from './../util/recommendPoint'
import { setWebViewTitle } from './../util/webViewHelper'

import { oauthType, versionNumber, GEO_TIMEOUT } from './../util/config'
import gc from './../util/globalConfiguration'

import { matchParameter, latlngEq, vmIsIOS } from './../util/utils'
import IPathMap from './../component/utils/iPathMap'
//import QQMap from './../component/utils/qqMap'
import loger from './../util/loger'
import { distance } from './../util/pointOnSegmentByTurf'


import { withTranslation } from "react-i18next";

import moment from 'moment';

const AMAP_ZOOM_SHOW_RECOMMENDPOINT_LIMIT = 16.5;
const AMAP_ZOOM_DEFAULT = 17.5;

// const DRAG_SORPTION_LIMIT = 5

@inject('mainStore', 'globalStore', 'confirmStore')
@observer
class Main extends React.Component {

  state = {
    isShowMenu: false,
    amapMoveing: false,
    mapMoveControl: true,
    isShowRecommended: false,
    isShowFenceTitle: false,
    locationFail: false,
    isFenceName: false,
    fenceName: '',
    // 一级围栏
    levelOptions: [],
    // 二级围栏
    secondLevel: [],
    // 车站
    stationName: '',
    mapMove: false,
    // 是否显示围栏内上车点图标
    showFencePoint: false,
    isFinishEnd: false,
    // loading层
    isShowMask: false,
    searchState: false
  }
  mainStore = this.props.mainStore;
  globalStore = this.props.globalStore;
  confirmStore = this.props.confirmStore;
  currentAmapZoom = 17;

  iPathMapRef = undefined;
  callCarRef = undefined;
  lazyTitle = ''
  t = this.props.t;

  lastMaprestorationTime = 0;

  // 移动计数大于 1 时不吸附上车地址
  threshoCount = 0

  changeShowMenu = (isShow) => {
    this.setState(() => {
      return {
        isShowMenu: isShow
      }
    });
  }

  showMenu = () => {
    this.changeShowMenu(true);
  }

  hideMenu = () => {
    this.changeShowMenu(false);
  }

  get letterState() {
    return this.globalStore.userInfo.letterContent && !this.globalStore.userInfo.isLetter && this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.main_letter_agreement
  }

  // 强生软件使用协议
  get isPrivacyAgreement() {
    return this.globalStore.userInfo.privacyAgreement && !this.globalStore.userInfo.isPrivacy
  }

  get isOauthType() {
    if (oauthType === '15') {
      return true
    } else if (oauthType === '13' && vmIsIOS()) {
      return true
    } else if((oauthType === '11' || oauthType === '1') && vmIsIOS()) {
      return true
    }
    return false
  }

  get serverTypes() {
    let sceneServerTypes = this.mainStore.scene ? this.mainStore.scene.serverTypes : []
    if (sceneServerTypes && sceneServerTypes.length) {
      return this.globalStore.companyInfo.serverTypes.filter(a => sceneServerTypes.includes(a))
    } else {
      return this.globalStore.companyInfo.serverTypes
    }
  }

  get currentServerType() {
    if (this.mainStore.serverType === 0) {
      this.mainStore.serverType = this.globalStore.userInfo.defaultServiceType || this.serverTypes[0]
    }
    return this.mainStore.serverType
  }

  // 判断是否只有一个接机
  get isCheckServerTypes() {
    if (this.globalStore.companyInfo?.serverTypes && this.globalStore.companyInfo.serverTypes?.length === 1 && this.mainStore.serverType === 6) {
      return true
    }
    return false
  }

  // 最大吸附距离
  get DRAG_SORPTION_LIMIT() {
    if (this.globalStore.userInfo.distance) {
      return this.globalStore.userInfo.distance
    }
    return 5
  }

  // 最大移动次数
  get MAX_THRESHO_COUNT() {
    if (this.globalStore.userInfo.adsorptionTimes) {
      return this.globalStore.userInfo.adsorptionTimes
    }
    return 1
  }

  // 相同围栏最大显示次数
  get fenceShowCount() {
    if (Number(this.globalStore.companyInfo.fenceShowCount)) {
      return Number(this.globalStore.companyInfo.fenceShowCount)
    }
    return 1
  }

  render() {
    return <div className={this.isOauthType ? style.main_jd : style.main}>
      <div className={this.isOauthType ? style.amapContainer_jd : style.amapContainer} id="amap_container">

        {
          (() => {
            if (this.state.mapMoveControl) {
              if (this.isShowRecommendPointName()) {
                return (
                  <div>
                    <div className={style.case}>
                      <div className={style.address}>{this.mainStore.beginAddr.name}</div>
                      <div className={style.place}>{this.t('main.k001')}</div>
                    </div>
                    <div className={style.triangle}></div>
                  </div>
                )
              } else {
                return (
                  <div>
                    <div className={style.separate}>
                      <div className={style.aboard}>{this.t('main.k001')}</div>
                    </div>
                    <div className={style.shape}></div>
                  </div>
                )
              }
            }
            if (this.state.isShowMask) {
              return (
                <div>
                  <div className={style.carryon}>
                    <div className={style.aboard}>{this.t('main.k0148')}</div>
                  </div>
                  <div className={style.shape}></div>
                </div>
              )
            }
            if (this.state.locationFail) {
              return (
                <div>
                  <div className={style.separate}>
                    <div className={style.aboard}>{this.t('main.k002')}...</div>
                  </div>
                  <div className={style.shape}></div>
                </div>
              )
            }
            if (this.state.isShowFenceTitle) {
              return (
                <div>
                  <div className={style.station}>
                    <div className={style.title}>{this.t('main.k001')}</div>
                    <div className={style.fixed}>{this.state.isFenceName ? this.state.fenceName : this.t('main.k003')}</div>
                  </div>
                  <div className={style.bottomShape}></div>
                </div>
              )
            }
          })()
        }

        {
          this.state.showFencePoint ? <div className={`${style.circle} ${style.fencePoint}`}>
            <div className={style.greenCircle}></div>
          </div>
            : <></>
        }

        <img src={images.markerFrom} className={`${style.markerFrom} ${this.state.mapMove ? style.pinAnima : ''}`} alt="起点标记" />
        <IPathMap ref={(dom) => { this.iPathMapRef = dom ? dom.getMapRef() : undefined }} 
          className={style.amapContainer} 
          onMarkerClick={(d) => {
          this.onMarkerClick(d)
        }} t={this.props.t} ></IPathMap>
      </div>

      <div className={style.menuWarp}>
        {
          this.globalStore.companyInfo.notApplicableMessage && this.globalStore.companyInfo.notApplicableMessage.value ? <div className={style.hcp}>
            <NoticeBar style={{ '--background-color': '#ffffff', '--font-size': '12px', '--border-color': '#ffffff', 'borderRadius': '5px' }} content={this.globalStore.companyInfo.notApplicableMessage.value} color='alert' />
          </div> : <></>
        }
        <div className={style.topBox}>
          <div className={style.menuIcon} >
            {
              gc.process.showBackHost
                ? <div className={style.box} onClick={() => {
                  window.location.replace(gc.process.showBackHost)
                }}>
                  <LeftOutline fontSize={20} />
                </div>
                : <></>
            }
            <Badge content={this.mainStore.badgeSum !== 0 ? this.mainStore.badgeSum : null} style={{ '--top': '22%', '--right': '16%' }}>
              <div className={`${style.box} ${style.micon}`} onClick={this.showMenu}></div>
            </Badge>
          </div>
          {
            !this.letterState ? <Notice language={this.globalStore.userInfo.language} history={this.props.history} /> : ''
          }
        </div>
      </div>

      {
        this.state.isShowMenu ? <div>
          <Popup
            visible={this.state.isShowMenu}
            onMaskClick={this.hideMenu}
            position='left'
            bodyStyle={{ minWidth: '50vw' }}
          >

            <Menu hideMenu={this.hideMenu} t={this.props.t}></Menu>
          </Popup>
        </div> : <></>
      }

      {/* 实时、预约、接机弹窗 */}
      <div className={style.callCar}>

        <div className={style.maprestoration} onClick={
          async () => {
            this.setState({
              mapMove: true
            })
            if ((new Date().getTime() - this.lastMaprestorationTime) > GEO_TIMEOUT) {
              //this.lastMaprestorationTime = new Date().getTime();
              let currentMaprestorationTime = new Date().getTime();
              this.lastMaprestorationTime = currentMaprestorationTime;
              let res = await this.getCurrentPosition(null, 1, 0)
              if (currentMaprestorationTime >= this.lastMaprestorationTime) {
                if (res && this.mainStore.serverType === 6 && !this.isCheckServerTypes) {
                  this.mainStore.chooseServerType(this.currentServerType)
                  this.changeServerType(1);
                  this.iPathMapRef.setZoom(AMAP_ZOOM_DEFAULT)
                }
              }
            } else {
              console.log(`${GEO_TIMEOUT / 1000}s之内不再进行重复定位`)
            }
            setTimeout(() => {
              this.setState({
                mapMove: false
              })
            }, 1000)
          }
        }>
          <img src={images.maprestoration} alt="" />

        </div>

        {
          this.currentServerType
            ? <CallCar t={this.props.t} ref={(dom) => { this.callCarRef = dom; }} moveMap={(l, noMoveFence, isEndDecide, addrData) => {
              // noMoveFence 占位参数没用到
              // isEndDecide 从终点地址栏返回 传 true
              this.manualWriteLocationRecommend(l, noMoveFence, isEndDecide, addrData)
            }} history={this.props.history} changeServerType={this.changeServerType} isFinishEnd={this.state.isFinishEnd}></CallCar>
            : <></>
        }

      </div>
      {/* 适配底部滑动触发问题 */}
      <div className={style.bottom_replace}></div>
      {/* 围栏弹窗 */}
      <div className={style.fence}>
        {
          this.state.isShowRecommended ? <RecommendPath t={this.props.t} onClose={(isFirstLevel) => {

            this.setState({
              isShowRecommended: false,
              // isShowFenceTitle: false,
              // 一级围栏
              levelOptions: [],
              // 车站
              stationName: ''
            })
            if (isFirstLevel) {
              this.mainStore.choosedEndAddr({
                cityName: this.mainStore.beginAddr.cityName,
                cityCode: this.mainStore.beginAddr.cityCode
              })
              this.iPathMapRef.addRecommendMarkers(this.recommendMarkers)
              this.setState({
                isShowFenceTitle: false,
                showFencePoint: false,
                mapMoveControl: true,
                isFinishEnd: false
              })
              this.iPathMapRef.setDraggable(true)
              this.iPathMapRef.setScrollable(true)
            }

            this.iPathMapRef.removeFence()
          }}
            endChange={(item) => {
              this.manualWriteLocationRecommend([item.lon, item.lat], false, true)
            }}
            moveMap={(l, bool, isEndDecide) => {
              this.manualWriteLocationRecommend(l, bool, isEndDecide)
            }}
            endCall={() => this.callCarRef.ready()}
            levelOptions={this.state.levelOptions}
            secondLevel={this.state.secondLevel}
            stationName={this.state.stationName}
            isShowRecommended={this.state.isShowRecommended}
            history={this.props.history}
            startingPlace={this.getStartingPlace}
          >
          </RecommendPath> : ''
        }
      </div>

      {/* 新人活动弹出层 */}
      {
        !this.letterState ? <PromotionNewUser history={this.props.history} /> : ''
      }
      {/* 活动弹出层 */}
      {
        !this.letterState ? <Promotion refreshBadge={() => {
          this.mainStore.refreshBadgeCount();
        }}  history={this.props.history} /> : ''
      }
      {/* 广告弹出层 */}
      {
        !this.letterState ? <Ad t={this.props.t} history={this.props.history} /> : ''
      }
      {/* 知情同意书 */}
      {
        this.letterState ? <LetterAgreement t={this.props.t} history={this.props.history}></LetterAgreement> : ''
      }
      { /* 强生、诺华隐私协议 */}
      {this.isPrivacyAgreement ? <PrivacyAgreement t={this.props.t}></PrivacyAgreement> : ''}
      {/* <AppRouter baseUri={''} routes={this.props.router.childrens} /> */}

      {/* <LoadingMask opacityNum={0} loadingType={'move'} show={this.state.isShowMask} /> */}
    </div>
  }

  componentDidMount = async () => {

    window.showMainStore = () => {
      console.log(this.mainStore)
    }

    if (this.globalStore.userInfo.commonAddressValidation) {
      let commonAddressValidation = this.globalStore.userInfo.commonAddressValidation
      if (commonAddressValidation.needToSetCommonlyAddress) {
        // 需要设置常用地址
        // 先给出一个提示
        await Dialog.show({
          content: commonAddressValidation.addressMustBeSetTip || '根据公司规定，加班场景只能在公司与家庭住址间使用，请前往设置常用地址',
          actions: [
            {
              key: 'close',
              text: '前往设置',
              onClick: () => {
                this.props.history.push('/habitAddress')
              }
            }
          ]
        })
        return
      }
    }

    try {
      let { data } = await webService.user.setVersionNumber({ versionNumber: versionNumber })
      if (data.data && data.data.status === 0) {
        window.location.reload()
      }
    } catch (e) {
      console.log(e)
    }

    let allowUseCar = this.props.location.search;
    if (allowUseCar) {
      let result = matchParameter(allowUseCar);
      this.globalStore.allowUseCar = result.allowUseCar === 'true';
      this.props.history.replace('/');
    }

    this.lazyTitle = setTimeout(() => {
      setWebViewTitle(this.t('title.k041'))
    }, 250);

    this.confirmStore.clear()

    this.getCompanyHintText();

    if (this.globalStore.companyInfo.needUseCarToken && !this.globalStore.allowUseCar) {
      Dialog.alert({
        // 当前无用车权限
        content: this.t('main.k0133'),
        confirmText: this.t('common.k003')
      })
      return;
    }

    if (!this.globalStore.userInfo.phone) {
      if (this.isPrivacyAgreement || this.letterState) {
        // 如果是存在需要同意，且还未同意的隐私声明，则暂缓跳转到设置页面
      } else {
        Dialog.show({
          title: this.t('main.k098'),
          content: this.t('main.k099'),
          actions: [[
            {
              key: 'go_set', text: this.t('main.k0100'), bold: true,
              onClick: () => {
                this.props.history.push('/settings')
              }
            }
          ]],
          closeOnAction: true
        })
      }
      
    }

    // 支付标识回首页清空
    let isPay = localStorage.getItem('isPay')
    if (isPay) {
      localStorage.setItem('isPay', '');
    }

    recommendPoint.init();

    // 载入企业基本信息
    webService.company.getBasicInfo().then(d => {
      const { data } = d;
      if (data.code === 0) {
        this.globalStore.setCompanyInfo(data.data);
        //this.mainStore.chooseServerType(data.data.serverTypes && data.data.serverTypes.length ? data.data.serverTypes[0] : 0)
      } else {
        Toast.show({
          content: data.message ? data.message : this.t('main.k004')
        })
      }
    })

    await this.mainStore.refreshBadgeCount();
    if (this.mainStore.badgeCount.waitPayOrderCount && gc.process.showPayFirst) {
      Dialog.show({
        title: this.t('main.k0150'),
        content: this.t('main.k0151'),
        actions: [[
          {
            key: 'go_set', text: this.t('main.k0152'), bold: true,
            onClick: () => {
              this.props.history.push('/payments')
            }
          }
        ]],
        closeOnAction: true
      })
    }

    this.initMapAndgetCurrentPosition();

    this.getUseCarCheck();
    
  }

  initMapAndgetCurrentPosition = async () => {
    if (this.iPathMapRef.getMap()) {
      return;
    }

    let mainParameter = undefined
    // 判断是否需要跳过交通枢纽验证
    this.isIgnoreHitFences = (lat, lon) => {
      if (mainParameter) {
        // 对比是否与起点坐标相同
        if (mainParameter.depart) {
          if (mainParameter.depart.lat === lat && mainParameter.depart.lon === lon) {
            return true
          }
        }
        // 对比是否与终点坐标相同
        if (mainParameter.dest) {
          if (mainParameter.dest.lat === lat && mainParameter.dest.lon === lon) {
            return true
          }
        }
      }
      return false
    }
    try {
      let { data } = await webService.order.getOrderLimitInfo()
      if (data.code === 0 && (data.data.defAddress || data.data.departTime)) {
        mainParameter = {
          ...data.data.defAddress,
          departTime: data.data.departTime ? moment(data.data.departTime).format('yyyy年MM月DD日 HH:mm') : ''
        }
        loger.info('首页', '默认起终点参数', mainParameter)
      }
    } catch {

    }
    this.mainStore.setMainParameter(mainParameter)

    let coords;
    try {
      Toast.show({
        icon: "loading",
        content: this.t('main.k0153'),
        maskClickable: false,
        duration: 0
      })
      if (mainParameter && mainParameter.depart && mainParameter.depart.lon !== '0') {
        coords = mainParameter.depart;
      } else {
        coords = await getCurrentPosition();
        let _report = {
          type: 1,
          value: 1,
          path: window.location.hash
        }
        console.log('定位成功', coords, _report)
        webService.user.saveUserLog(_report);
      }
      await this.generateMap(coords)
      this.mainStore.setCurrentAddr(coords)

    } catch (exp) {
      await this.generateMap(coords)
      this.mainStore.setCurrentAddr(coords)
      let _report = {
        type: 1,
        value: 0,
        path: window.location.hash
      }
      console.log('失败定位', coords, _report, exp)
      webService.user.saveUserLog(_report);
    } finally {
      Toast.clear()
      if (mainParameter) {
        if (mainParameter.dest && mainParameter.dest.lon !== '0') {
          let dest = await getPosition4Element(mainParameter.dest)
          // TODO: 泛嘉四期设置终点地址名称
          if (this.mainStore?.mainParameter?.dest?.name) {
            dest.name = this.mainStore.mainParameter.dest.name;
            dest.address = this.mainStore.mainParameter.dest.name;
          }
          this.mainStore.choosedEndAddr({
            ...dest
          })
          console.log('设置终点地址', dest)
        }
        if (mainParameter.departTime) {
          this.mainStore.setDepartureTime(mainParameter.departTime)
          console.log('设置用车时间', mainParameter.departTime)
        }
        
      }
    }
    // 判断是否可拖动
    if(this.isCheckServerTypes) {
      this.iPathMapRef.setDraggable(false)
      this.iPathMapRef.setScrollable(false)
    }
  }

  /**
   * 查询KA企业提示文本
   */
  getCompanyHintText = async () => {
    let { data } = await webService.company.getCompanyHintText();
    if (data.code === 0 && data.data) {
      let hintText = data.data.hintText
      if (hintText) {
        Dialog.alert({
          content: (
            <>
              <div style={{ whiteSpace: 'pre-line' }}>{hintText}</div>
            </>
          ),
          confirmText: this.t('common.k003')
        })
      }
    }
  }

  /**
   * 生成地图
   * @param {*} coords 
   */
  generateMap = async (coords) => {
    try {
      if (coords && coords.lon) {
        await this.iPathMapRef.initMap(coords)
      } else {
        await this.iPathMapRef.initMap()
      }
      await this.iPathMapRef.watchPosition()
    } catch (exp) {
      console.error(exp)
      return;
    }

    //this.map = this.qqMapRef.getMap();
    // 缩放事件
    this.iPathMapRef.on('zoom', () => {
      let zoom = this.iPathMapRef.getZoom()
      if (zoom > AMAP_ZOOM_SHOW_RECOMMENDPOINT_LIMIT) {
        this.iPathMapRef.showRecommendMarker()
      } else {
        this.iPathMapRef.hideRecommendMarker()
      }
    })
    // 阻止默认的双击放大
    this.iPathMapRef.setDoubleClickZoom(false)
    // 设置双击事件
    this.iPathMapRef.on('dblclick', () => {
      this.ignoreTouch = true
      if (this.state.isShowRecommended) {
        return
      }
      let z = this.iPathMapRef.getZoom()
      this.iPathMapRef.zoomTo(z + 0.5)
    })

    // pan事件控制定位图标动画
    // 地图移动中
    this.iPathMapRef.on('pan', () => {
      this.setState({
        mapMove: false
      })
    })
    // 地图移动结束
    this.iPathMapRef.on('panend', this.debounce(async (d) => {
      this.setState({
        mapMove: true
      })
    }, 500))

    let touchstartPoint = undefined;
    // 地图开始拖动
    this.iPathMapRef.on('touchstart', (d) => {
      touchstartPoint = this.iPathMapRef.getCenter()
    });

    // 地图拖动事件
    this.iPathMapRef.on('touchmove', (d) => {
      
      if(this.isCheckServerTypes) {
        return
      }
      // if (this.mainStore.serverType === 6) {
      //   return
      // }
      // 接机服务地图不可拖拽
      if (!this.state.isShowRecommended) {
        this.setState({
          isShowMask: true
        })
      }

      // 如果当前在推荐地点上屏幕缩放也会触发移动事件故此加了一个判断，如果在推荐上车地点上则移动不清空之前地址
      let l = {
        lat: this.mainStore.beginAddr.lat,
        lng: this.mainStore.beginAddr.lon
      }
      if (this.recommendPoints.filter(a => latlngEq(a.location, l)).length !== 1) {
        this.mainStore.setNameAddrEmpty({ name: '', address: '' })
      }


      this.setState({
        amapMoveing: false,
        mapMoveControl: false,
        locationFail: false
      })

      // this.qqMapRef.showRecommendMarkerLabel(0)

    })

    // 地图拖动结束
    this.iPathMapRef.on('touchend', this.debounce(async (d) => {
      if(this.isCheckServerTypes) {
        return
      }

      // this.threshoCount++

      // 拖动地图结束
      let l = this.iPathMapRef.getCenter();
      // 判断是否接近推荐上车点
      console.log(l)
      if (this.recommendPoints && this.recommendPoints.length) {
        let rps = JSON.parse(JSON.stringify(this.recommendPoints))
        for (let i in rps) {
          let item = rps[i]
          item.lineIndex = i
          item.lineDistance = distance(l, item.location)
        }
        rps = rps.sort((a, b) =>  a.lineDistance - b.lineDistance)
        console.log(rps)
        if (rps[0].lineDistance <= this.DRAG_SORPTION_LIMIT && this.threshoCount === 1) {
          ++this.threshoCount
          this.onMarkerClick({id: 'rm' + rps[0].lineIndex})
        }
      }

      this.setState({
        showFencePoint: false
      })

      if (this.ignoreTouch || this.recommendPoints.filter(a => latlngEq(a.location, l)).length) {
        this.ignoreTouch = false
        this.setState({
          mapMoveControl: true
        })
        return
      }

      if (this.state.isShowRecommended) {
        // 如果当前在交通枢纽里
        let rr = this.iPathMapRef.isPointInFence(l)
        if (rr && touchstartPoint) {
          this.iPathMapRef.panTo([touchstartPoint.lng, touchstartPoint.lat])
          return;
        }
      }


      await this.hitFencesAndRecommend({
        lat: l.lat,
        lon: l.lng
      }, this.DRAG_SORPTION_LIMIT, true, false, false, null, 2, this.threshoCount); // 首次拖动 5米 内吸附, 二次拖动不吸附

    }, 500))

    // 无历史遗留之表单信息则重新定位
    if (!this.mainStore.beginAddr.lat) {
      this.getCurrentPosition(coords, 1, 0);
    } else {
      this.moveMap([this.mainStore.beginAddr.lon, this.mainStore.beginAddr.lat])
      this.iPathMapRef.showCurrentPoint({
        lat: this.mainStore.mapCenter.slice()[1],
        lon: this.mainStore.mapCenter.slice()[0]
      });
    }

  }

  /**
   * 控制接机时不能拖动地图
   * @param {*} item 
   */
  changeServerType = (item) => {
    if (this.mainStore.serverType === 20) {
      this.mainStore.setCharteredBusType(1)
    }
  }

  /**
   * 判断是否需要在地图中心点显示推荐上车地点名称
   */
  isShowRecommendPointName = () => {
    if (this.recommendPoints.length < 1) {
      return false;
    }
    //eslint-disable-next-line
    if (this.recommendPoints.filter(a => { return a.location.lat == this.mainStore.beginAddr.lat && a.location.lng == this.mainStore.beginAddr.lon }).length < 1) {
      return false;
    }

    if (this.state.amapMoveing) {
      return false;
    }

    if (this.state.searchState) {
      return false
    }

    return true;
  }

  /**
   * 获取推荐上车地点成功回调
   * @param {*} recommendPoint successRecommendPointCallBack
   * @param {*} mapCenter 
   * @param {*} noMoveMapCenter 为 false 时用户搜索栏过来的
   * @returns 
   */
  successRecommendPointCallBack = async (recommendPoint, mapCenter, noMoveMapCenter, yesMoveFence) => {
    if (this.callCarRef && noMoveMapCenter) {
      // this.setState({
      //   isShowMask: true
      // })
      await this.callCarRef.init(recommendPoint ? recommendPoint : {
        lon: mapCenter[0],
        lat: mapCenter[1]
      });
      this.setState({
        isShowMask: false
      })
    }
    if (recommendPoint) {
      this.iPathMapRef.panTo([recommendPoint.lon, recommendPoint.lat])

      if (yesMoveFence) {
        //this.map.add(this.recommendMarkers);
        this.iPathMapRef.addRecommendMarkers(this.recommendMarkers);
        this.iPathMapRef.setZoom(AMAP_ZOOM_DEFAULT)
        this.currentAmapZoom = this.iPathMapRef.getZoom();
      }
    } else {
      if (!noMoveMapCenter) {
        this.iPathMapRef.setZoom(AMAP_ZOOM_DEFAULT)
        this.iPathMapRef.panTo(mapCenter)

      }
    }

    setTimeout(() => {
      this.setState({
        amapMoveing: false,
        mapMoveControl: true,
        locationFail: false
      })
      if (this.callCarRef) {
        this.callCarRef.ready()
      }

    }, 200);

  }

  moveMap = (p) => {
    // this.map.panTo(p)
    this.iPathMapRef.panTo(p)
    this.iPathMapRef.setZoom(AMAP_ZOOM_DEFAULT)
  }

  // 手动输入定位获取上车地点
  manualWriteLocationRecommend = async (l, yesMoveFence, isEndDecide = false, addrData) => {
    console.log(l, yesMoveFence, isEndDecide)
    this.setState({
      searchState: true
    })
    let mapCenter = l
    this.threshoCount = 0;
    this.mainStore.setMapCenter(mapCenter);
    await this.hitFencesAndRecommend({
      lat: l[1],
      lon: l[0]
    }, 0, false, yesMoveFence, isEndDecide, null, addrData?.recommendType, this.threshoCount, addrData?.name);
    // if (!isEndDecide) {
    //   this.mainStore.setMapCenter(mapCenter);
    //   this.hitFencesAndRecommend({
    //     lat: l[1],
    //     lon: l[0]
    //   }, 0, false, yesMoveFence, isEndDecide);
    // } else {
    //   this.callCarRef.ready()
    // }


  }

  componentWillUnmount = () => {
    this.recommendPoints = [];

    if (this.recommendMarkers && this.iPathMapRef.getMap()) {
      this.recommendMarkers = []
    }

    if (this.lazyTitle) {
      clearTimeout(this.lazyTitle)
    }

    Toast.clear()
    Modal.clear()
    Dialog.clear()
    sessionStorage.setItem('geo_action_id', '')
  }

  currentHitId = 0;
  /**
   * 检查是否触发推荐上交通枢纽和车地点
   * @param {*} mapCenter 
   * @param {*} distance 
   * @param {*} noMoveMapCenter 
   * @param {*} yesMoveFence
   * @param {*} isEndDecide 是否从终点地址栏返回传 true, true 的时候终点展示围栏弹窗, 但是不画围栏
   * @param {*} onRecommend 
   */
  async hitFencesAndRecommend(mapCenter, distance, noMoveMapCenter = true, yesMoveFence = false, isEndDecide = false, onRecommend, recommendType, count, name) {
    let _currentHitId = new Date().getTime()

    const successCallBack = (recommendPoint) => {
      if (this.currentHitId !== _currentHitId) {
        console.log('过期请求', this.currentHitId, _currentHitId)
        this.callCarRef.ready()
        return;
      }
      this.successRecommendPointCallBack(recommendPoint, [mapCenter.lon, mapCenter.lat], noMoveMapCenter, yesMoveFence);
    }

    // 获取一级围栏
    this.currentHitId = _currentHitId
    // this.setState({
    //   isShowMask: true
    // })
    let d = await this.gethitFences(mapCenter.lat, mapCenter.lon, this.globalStore.userInfo.id, moment().format('X'), recommendType, count, name); // this.globalStore.userInfo.id, moment().format('X')
    if (this.currentHitId !== _currentHitId) {
      console.log('过期请求', this.currentHitId, _currentHitId)
      return;
    }
    this.setState({
      isShowMask: false
    })

    let hit_hub_of_traffic = d.data && d.data.data && d.data.data.detail && d.data.data.detail.hit_hub_of_traffic ? d.data.data.detail.hit_hub_of_traffic : '';
    let hit_sub_fence = d.data && d.data.data && d.data.data.detail && d.data.data.detail.data && d.data.data.detail.data.hit_sub_fence ? d.data.data.detail.data.hit_sub_fence.length : 0;
    let isDist = d.data && d.data.data && d.data.data.detail && d.data.data.detail.flg ? d.data.data.detail.flg : false
    // 起点正常显示围栏，终点判断是否有终点标识并且是终点才显示围栏
    if ((hit_hub_of_traffic === 1 && hit_sub_fence > 0 && this.mainStore.isBegin) || (isDist && !this.mainStore.isBegin)) {
      if (this.mainStore.isBegin) {
        this.iPathMapRef.addFence(d.data.data.detail.data.polygon)
        setTimeout(() => {
          this.setState({
            isShowRecommended: true,
            isShowFenceTitle: true,
            locationFail: false,
            mapMoveControl: false,
            isFenceName: false
          })
        }, 1000);
      } else {
        setTimeout(() => {
          this.setState({
            isShowRecommended: true,
          })
        }, 1000);
        this.iPathMapRef.setDraggable(false)
        this.iPathMapRef.setScrollable(false)
      }

      // 下车地点围栏过滤
      // if (isDist && !this.mainStore.isBegin) {
        d.data.data.detail.data.sub_fence = this.filterFenceDist(d.data.data.detail.data.sub_fence, d.data.data.detail.data.hit_sub_fence, this.mainStore.isBegin).sub_fence
        d.data.data.detail.data.hit_sub_fence = this.filterFenceDist(d.data.data.detail.data.sub_fence, d.data.data.detail.data.hit_sub_fence, this.mainStore.isBegin).hit_sub_fence
      // }


      // 获取一级围栏传给组件数据
      let levelOptions = d.data.data.detail.data.sub_fence.map(item => {
        return {
          label: item.name,
          value: item.id,
        }
      })

      // 获取二级围栏传给组件数据
      let secondLevel = d.data.data.detail.data.hit_sub_fence.map(item => {
        return {
          label: item.name,
          value: item.id,
          data: item.data.map(val => {
            return {
              label: val.title,
              value: val.title,
              location: val.location,
              poi: val.id ? val.id : val.poi
            }
          })
        }
      })


      this.setState({
        levelOptions: [levelOptions],
        secondLevel: secondLevel,
        isFinishEnd: isEndDecide,
        stationName: d.data.data.detail.data.name

      })

    } else {
      this.iPathMapRef.removeFence()
      // 如果没有围栏正常，从终点检索地址回来触发叫车
      if (isEndDecide) {
        // && this.mainStore.beginAddr.lat && this.mainStore.endAddr.lat
        this.callCarRef.ready()
        return
      } else {
        this.setState({
          isShowRecommended: false,
          isShowFenceTitle: false,
          levelOptions: [],
          isFinishEnd: false
        })
        if (onRecommend) {
          onRecommend()
        }
        if (this.mainStore.serverType === 6 && !this.mainStore.beginAddr.flightNumber && !this.isCheckServerTypes) {
          this.mainStore.chooseServerType(this.currentServerType)
        }
        // 获取附近三个推荐上车地点
        if (this.currentHitId !== _currentHitId) {
          console.log('过期请求', this.currentHitId, _currentHitId)
          this.callCarRef.ready()
          return;
        }
        this.getRecommendPoints(mapCenter.lat, mapCenter.lon, this.globalStore.userInfo.id, successCallBack, distance, recommendType, this.threshoCount)
      }
    }

  }

  geolocation = undefined

  /**
   * 定位
   */
  getCurrentPosition = async (_coords, recommendType, count) => {
    loger.info('首页', '当前定位参数', _coords)
    // 防止放大后定位视角恢复 AMAP_ZOOM_DEFAULT 
    this.iPathMapRef.setZoom(AMAP_ZOOM_DEFAULT);

    const ok = async (coords) => {
      let mapCenter = [coords.lon, coords.lat];
      this.mainStore.setMapCenter(mapCenter);
      this.iPathMapRef.showCurrentPoint(coords)
      if (coords.cityCode && coords.cityName) {
        this.mainStore.setCurrentCityOnly({
          cityCode: coords.cityCode,
          cityName: coords.cityName
        })
      }

      await this.hitFencesAndRecommend({
        lat: coords.lat,
        lon: coords.lon
      },
        // 首页启动参数存在，吸附距离设置为0
        this.mainStore.mainParameter?.dest || this.mainStore.mainParameter?.depart ? 0 : 100,
        true, false, false, () => {
          this.iPathMapRef.panTo(this.mainStore.mapCenter.slice())
        }, recommendType, count, coords.name
      );

    }

    if (_coords && _coords.lon) {
      ok(_coords)
      this.setState({
        locationFail: false
      })
    } else {
      let geo_action_id = Math.floor(Math.random() * 1000000) + ''
      sessionStorage.setItem('geo_action_id', geo_action_id)

      try {
        let coords = await getCurrentPosition();
        let _geo_action_id = sessionStorage.getItem('geo_action_id');
        if (geo_action_id !== _geo_action_id) {
          return;
        }
        ok(coords);
        return true;
      } catch (e) {
        this.mainStore.choosedBeginAddr({ name: this.t('main.k006') })
        this.setState({
          locationFail: true,
          mapMoveControl: false,
          isShowFenceTitle: false
        })
        Modal.show({
          maskClassName: `${style.fail}`,
          bodyClassName: `${style.failBody}`,
          content: <div><ExclamationCircleOutline fontSize={20} color='#148bef' /> &nbsp;{this.t('main.k007')}</div>,
          afterShow: () => {
            setTimeout(() => {
              Modal.clear()
            }, 1000)
          }
        })
      }
    }
  }

  // 需要排除的交通枢纽
  excludeHitFences = [];

  /**
   * 获取是否命中一级围栏二级围栏信息
   */
  gethitFences = async (lat, lon, reqid, reqtime, recommendType, count, name) => {
    webService.map.recommend(lat, lon, reqid, recommendType, count)
    webService.map.geocoder(lon, lat)
    // 命中交通枢纽的返回更慢，因此不必等待这两个返回
    // let t1 = webService.map.recommend(lat, lon)
    // let t2 = webService.map.geocoder(lon, lat)
    // const myPromise = (promiseList) => {
    //   return promiseList.map(promise =>
    //       promise.then((res) => (res), (err) => (err))
    //   )   
    // }
    // await Promise.all(myPromise([t1, t2]))

    return new Promise((resolve, reject) => {
      if (this.isIgnoreHitFences(lat, lon)) {
        // 服务端指定的起终点不需要触发交通枢纽
        loger.info('首页', '交通枢纽', '服务端指定的起终点不需要触发交通枢纽')
        resolve({
          data: {
            status: 0,
            message: 'query ok',
            data: {
              status: 0,
              message: 'successful',
              request_id: 'cbabe301957d5cca6f88174a99726c',
              detail: {
                hit_hub_of_traffic: 0
              }
            }
          }
        })
        return
      }
      webService.map.gethitFences(lat, lon, name).then(res => {
        if (res.status === 200) {
          // 判断是否需要承认此交通枢纽
          let hit_hub_of_traffic = res.data && res.data.data && res.data.data.detail ? res.data.data.detail.hit_hub_of_traffic : ''
          let hit_sub_fence = res.data && res.data.data && res.data.data.detail && res.data.data.detail.data ? res.data.data.detail.data.hit_sub_fence.length : 0
          if (hit_hub_of_traffic === 1 && hit_sub_fence > 0) {
            // 事实上是交通枢纽
            let excludeNames = this.excludeHitFences.filter(a => a.name === res.data.data.detail.data.name)
            if (excludeNames && excludeNames.length && excludeNames[0].count >= this.fenceShowCount) {
              // 在排除名单中，返回非枢纽结果
              resolve({
                data: {
                  status: 0,
                  message: 'query ok',
                  data: {
                    status: 0,
                    message: 'successful',
                    request_id: 'cbabe301957d5cca6f88174a99726c',
                    detail: {
                      hit_hub_of_traffic: 0
                    }
                  }
                }
              })
              return
            }
            // 加入交通枢纽排除名单
            let excludeName = this.excludeHitFences.filter(a => a.name === res.data.data.detail.data.name)
            if (excludeName && excludeName.length) {
              excludeName[0].count += 1
            } else {
              this.excludeHitFences.push({name: res.data.data.detail.data.name, count: 1})
            }
          }

          resolve(res)
        } else {
          reject(reject)
        }
      })
    })
  }

  recommendPoints = [];
  recommendMarkers = [];

  /**
   * 获取附近上车地点
   */
  getRecommendPoints = (lat, lon, userId, successCallBack, distance, recommendType, count) => {
    // 使用自定义坐标点
    recommendPoint.recommend(lat, lon, userId, recommendType, count).then(res => {
      if (res && res.length) {
        this.recommendPoints = res;
        this.showRecommendMarkers(successCallBack, distance)
      } else {
        successCallBack();
      }
      ++this.threshoCount
    })
  }




  /**
   * 在地图上绘制推荐的上车地点
   */
  showRecommendMarkers = (successCallBack, distance) => {

    // 删除绘制围栏
    this.iPathMapRef.removeFence()

    if (this.recommendMarkers) {
      //this.map.remove(this.recommendMarkers)
      this.iPathMapRef.removeRecommendMarkers();
      this.recommendMarkers = [] // 清空
    }

    this.recommendPoints.forEach(item => {
      this.recommendMarkers.push(item)
    });

    if (this.iPathMapRef.getZoom() > AMAP_ZOOM_SHOW_RECOMMENDPOINT_LIMIT) {
      // this.map.add(this.recommendMarkers)
      this.iPathMapRef.addRecommendMarkers(this.recommendMarkers)
    }


    this.checkRecommendPoint(successCallBack, distance)


  }

  /**
   * 判断是否吸附推荐上车点
   */
  checkRecommendPoint = (successCallBack, distance) => {
    // 距离最近上车点
    let recommendPoint = this.recommendPoints[0];
    if (recommendPoint) {
      let newDistance = recommendPoint && recommendPoint.distance ? Math.abs(recommendPoint.distance) : 0
      //  && this.threshoCount <= 1
      if (recommendPoint.adsorb && newDistance <= distance) {
        // 上车地点吸附添加, 如果地址检索回来后会先在状态里赋值, isShowRecommendPointName 这个方法中会比较 beginAddr 里面的经纬度不符合会不展示推荐上车地点名称
        // 所以如果有推荐上车地点需要更新一下状态里面的值, 保持一致
        this.setState({
          searchState: false
        })
        this.mainStore.choosedBeginAddrSearch({
          name: recommendPoint.title,
          address: recommendPoint.title,
          lon: recommendPoint.location.lng,
          lat: recommendPoint.location.lat,
          poi: recommendPoint?.poi
        })
        successCallBack({
          name: recommendPoint.title,
          address: recommendPoint.title,
          lon: recommendPoint.location.lng,
          lat: recommendPoint.location.lat,
          poi: recommendPoint?.poi
        });
        setTimeout(() => {
          this.iPathMapRef.hideRecommendMarkerLabel(0)
        }, 10)
      } else {
        this.setState({
          searchState: true
        })
        successCallBack();
      }
    }
  }

  /**
   * 推荐上车地点被点击事件
   * @param {*} d 
   */
  onMarkerClick(d) {
    if (!d) {
      return;
    }
    let index = Number(d.id.substr(2))
    let info = this.recommendMarkers[index]
    if (!info) {
      return;
    }

    let mapCenter = {
      name: info.title,
      address: info.title,
      lon: info.location.lng,
      lat: info.location.lat,
      poi: info.id
    }
    this.ignoreTouch = true
    this.iPathMapRef.panTo([mapCenter.lon, mapCenter.lat])
    this.callCarRef.init(mapCenter)

    this.iPathMapRef.hideRecommendMarkerLabel(index)
    this.setState({
      searchState: false
    })
    this.mainStore.setBeginValidationed(false)
    setTimeout(() => {
      this.callCarRef.ready()
    }, 200);

  }

  debounce = (func, wait) => {
    let timeout; // 创建一个标记用来存放定时器的返回值
    return function () {
      timeout && clearTimeout(timeout) // 每当用户输入的时候把前一个 setTimeout clear 掉
      // 然后又创建一个新的 setTimeout, 这样就能保证输入字符后的 interval 间隔内如果还有字符输入的话，就不会执行 func 函数
      timeout = setTimeout(() => {
        func.apply(this, arguments)
      }, wait)
    }
  }


  // 获取围栏起始地上车地点
  getStartingPlace = (info, bool, go) => {
    loger.debug('getStartingPlace', info, bool, go)
    let mapCenter = {
      name: info.name,
      address: info.addressName,
      lon: info.location.lng,
      lat: info.location.lat,
      poi: info.poi
    }
    this.setState({
      isShowRecommended: bool ? false : true,
      isShowFenceTitle: true,
      isFenceName: true,
      locationFail: false
    })
    if (this.mainStore.isBegin) {
      this.setState({
        fenceName: info.addressName
      })
    }
    if (this.mainStore.serverType === 6) {
      let airportPickup = {
        flightNumber: this.mainStore.beginAddr.flightNumber,
        planArrivalTime: this.mainStore.beginAddr.arrivalTime,
        planDepartTime: this.mainStore.beginAddr.departTime,
        departAirportCode: this.mainStore.beginAddr.departAirportCode,
        arrivalAirportCode: this.mainStore.beginAddr.arrivalAirportCode,
        poi: info.poi ? info.poi : '',
        arrivalCityName: this.mainStore.beginAddr.cityName,
        arrivalCityCode: this.mainStore.beginAddr.cityCode,
        arrivalAirportName: info.name,
        arrivalAirportLocation: info.addressName,
        arrivalAirportCoord: `${info.location.lng},${info.location.lat}`,
        tip: this.mainStore.beginAddr.airTip
      }
      // this.map.panTo([mapCenter.lon, mapCenter.lat])
      this.iPathMapRef.panTo([mapCenter.lon, mapCenter.lat])
      if (this.mainStore.isBegin) {
        this.mainStore.choosedFlight(airportPickup);
      }
      // 起点终点都有围栏时候根据 bool 判断用户是否点击在此上车或在此下车
      if (bool) {
        // && this.mainStore.beginAddr.lat && this.mainStore.endAddr.lat
        this.callCarRef.ready()
        return false;
      }
    } else {
      // 判断围栏组件内选择起点终点都满足后开始叫车
      if (go) {
        // && this.mainStore.beginAddr.lat && this.mainStore.endAddr.lat
        this.mainStore.setParking({
            isFinishEnd: true,
            address: mapCenter.address,
            name: mapCenter.name,
            lon: mapCenter.lon,
            lat: mapCenter.lat,
            poi: mapCenter.poi
        });
        this.callCarRef.ready()
        return false;
      }
      this.setState({
        showFencePoint: false
      })
      // this.map.panTo([mapCenter.lon, mapCenter.lat])
      if (!this.state.isFinishEnd) {
        this.iPathMapRef.panTo([mapCenter.lon, mapCenter.lat])
        this.setState({
          showFencePoint: true
        })
      } else {
        this.iPathMapRef.removeRecommendMarkers()
      }

      if (this.mainStore.isBegin) {
        this.callCarRef.init(mapCenter)
      }
    }

  }

  // 下车地点围栏过滤
  filterFenceDist = (first_fence, second_fence, isBegin) => {
    let sub_fence = []
    // 起点
    if (isBegin) {
      sub_fence = first_fence.filter(item => !item.isdest);
    } else {
      // 终点
      sub_fence = first_fence.filter(item => item.isdest);
    }
    let hit_sub_fence = []
    first_fence.forEach(item => {
      second_fence.forEach(val => {
        if (item.id === val.id) {
          hit_sub_fence.push(val)
        }
      })
    })
    return {
      sub_fence,
      hit_sub_fence
    }
  }

  // 获取用户是否有待支付订单
  getUseCarCheck = async () => {
    let {data} = await webService.order.useCarCheck();
    if ([1009, 1010, 1011].includes(data.code)) {
      Dialog.alert({
        content: data.message,
        confirmText: this.t('common.k007'),
        style: {
          '--adm_dialog_btn_color': '#2F7DCD'
        },
        onClose: () => {
          if (data.data && data.data.ismeituan && data.data.orderid) {
            this.props.history.push(`/orderdetails/${data.data.orderid}`);
          } else {
            this.props.history.push('/payments');
          }
          
        }
    })
    }
  }
}


export default withTranslation()(Main);