import React from 'react';
import style from './applyRecord.scss';
import {inject, observer } from 'mobx-react'

import { Steps, Tag, Checkbox } from 'antd-mobile'

import {
    RightOutline, DownOutline, CheckCircleOutline, CloseCircleOutline, CheckOutline, UserCircleOutline
} from 'antd-mobile-icons'

// import moment from 'moment';

@inject('globalStore')
@observer
class ApplyRecord extends React.Component {
    state = {
        showDetails: false,
        checked: false,
        record: {},

        showApproveStep: false,
        showCheckSign: false,
        showCheckBox: false,
        showReApply: false
    }

    globalStore = this.props.globalStore;
    t = this.props.t


    get approvalStateClass() {
        let approvalState = Number(this.state.record.approvalState)
        switch(approvalState) {
            case 0:
                return '';
            case 1:
                return '';
            case 2:
                return style.success;
            case 3:
                return style.reject;
            default:
                return ''
        }
    }

    renderStep(item) {
        if (item.approveState === 1) {
            return <Steps.Step key={item.index} title={<div className={style.stepTitle}>
                <div>{item.approverNameCn} {this.t('preApproval.k007')}</div>
                <div>{item.time}</div>
            </div>} icon={<UserCircleOutline />} />
        } 
        if (item.approveState === 2) {
            return <Steps.Step key={item.index} title={<div className={style.stepTitle}>
                <div>{item.approverNameCn} {this.t('preApproval.k008')}</div>
                <div>{item.time}</div>
            </div>} icon={<CheckCircleOutline color='#42a737' />} />
        } else if (item.approveState === 3) {
            return <Steps.Step key={item.index} title={<div className={style.stepTitle}>
                <div>{item.approverNameCn} <span style={{'color': '#ff4731'}}>{this.t('preApproval.k009')}</span>
                {
                    (() => {
                        if (this.state.record.approvalState === item.approveState && this.state.showReApply && this.state.record.userId === this.globalStore.userInfo.id) {
                            return <>　<Tag color='#ff6430' fill='outline' onClick={
                                () => {
                                    this.props.history.push('/perApply/' + this.state.record.preDepartApplyId)
                                }
                            }>{this.t('preApproval.k010')}</Tag></>
                        }
                    })()                
                }
                </div>
                <div>{item.time}</div>
            </div>} description={this.t('preApproval.k011') + item.reason} icon={<CloseCircleOutline color='#ff4731' />} />
        } else {
            return <Steps.Step key={item.index} title={<div className={style.stepTitle}>
                <div>{item.approverNameCn} {this.t('preApproval.k021')}</div>
            </div>}  icon={<UserCircleOutline />}/>
        }
         
    }

    renderApprove() {
        let {record} = this.state;
        return <div className={style.approve}>
            <div className={style.title} onClick={(e) => {
                    e.stopPropagation()
                    this.setState({
                        showDetails: !this.state.showDetails
                    })
                }}>
                <div>{this.t('preApproval.k012')}</div>
                <div>
                    {
                        this.state.showDetails
                            ? <DownOutline />
                            : <RightOutline />
                    }
                </div>
            </div>
            {
                this.state.showDetails && record.approvalRecordList && record.approvalRecordList.length
                    ? <div>
                        <Steps current={record.currentApproveIndex} direction='vertical'>
                            {
                                record.approvalRecordList.map(a => {
                                    return this.renderStep(a);
                                })
                            }
                            
                        </Steps>
                    </div>
                    : <></>
            }
        </div>
    }

    render() {
        let {record} = this.state;
        
        return <div className={this.state.checked && this.state.showCheckSign ? `${style.record} ${style.checked}` : style.record} onClick={
                () => {
                    if (this.props.onClick) {
                        this.props.onClick()
                    }
                }
            }>
            <div className={style.head}>
                
                {
                    this.state.showCheckBox
                        ? <div className={style.checkbox}>
                            <Checkbox checked={this.state.checked} onChange={(v) => {
                                if (this.props.onCheck) {
                                    this.props.onCheck(v);
                                }
                                
                            }} />
                        </div>
                        : <></>
                }
                <div className={style.orderNo}>{this.t('preApproval.k013')}{record.preDepartApplyId}　
                    {
                        (() => {
                            if (this.state.showCheckBox) {
                                // 待审批时候只显示“行前审批”
                                return <Tag color='#42a737' >{this.t('preApproval.k014')}</Tag>
                            } else {
                                if (record.applyState !== undefined) {
                                    switch(record.applyState) {
                                        case 0: // 未使用
                                            return <Tag color='#87d068' fill='outline'>{record.applyStateStateName}</Tag>;
                                        case 1: // 使用中
                                            return <Tag color='#c8c8cc' fill='outline'>{record.applyStateStateName}</Tag>;
                                        case 2: // 已使用
                                            return <Tag color='#ff6430' fill='outline'>{record.applyStateStateName}</Tag>;
                                        case 3:
                                            return <Tag fill='outline'>{record.applyStateStateName}</Tag>
                                        default:
                                            return<></>
                                    }
                                }
                            }
                        })()
                    }
                </div>
                {
                    !this.state.showCheckBox && !this.state.showCheckSign && record.approvalStateName !== undefined
                        ? <div className={style.step}>
                            <div className={this.approvalStateClass}>
                                {record.approvalStateName}
                            </div>
                        </div>
                        : <></>
                }
                
            </div>
            <div className={style.body}>
                {record.userName ? <div>{this.t('preApproval.k015')}{record.userName}</div> : ''}
                {record.departCityName ? <div>{this.t('preApproval.k034')}{record.departCityName}</div> : ''}
                {record.destCityName ? <div>{this.t('preApproval.k035')}{record.destCityName}</div> : ''}
                {record.pickupLocationName ? <div>{this.t('preApproval.k016')}{record.pickupLocationName}</div> : ''}
                {record.destLocationName ? <div>{this.t('preApproval.k017')}{record.destLocationName}</div> : ''}
                {record.beginTime && record.endTime ? <div>{this.t('preApproval.k022')}{record.beginTime}-{record.endTime}</div> : ''}
                {record.beginTime && !record.endTime ? <div>{this.t('preApproval.k022')}{record.beginTime}</div> : ''}
                {record.endTime && !record.beginTime ? <div>{this.t('preApproval.k022')}{record.endTime}</div> : ''}
                {record.canUseMoney ? <div>{this.t('perApplyRecord.k015', {canUseMoney: record.canUseMoney})}</div> : ''}
                {record.canUseCount ? <div>{this.t('perApplyRecord.k016', {canUseCount: record.canUseCount})}</div> : ''}
                {/* TODO 金额次数限制 */}
                {/* 可用金额 元 可用次数 次 */}
                {record.useCarMaxMoney ? <div>{this.t('preApproval.k036')}{record.useCarMaxMoney}{this.t('preApproval.k038')}</div> : ''}
                {record.useCarMaxTimes ? <div>{this.t('preApproval.k037')}{record.useCarMaxTimes}{this.t('preApproval.k039')}</div> : ''}
                {record.sceneName ? <div>{this.t('preApproval.k023')}{record.sceneName}</div> : ''}
                {record.applyReason ? <div>{this.t('preApproval.k018')}{record.applyReason}</div> : ''}
            </div>
            {
                this.state.showApproveStep && record.approvalRecordList && record.approvalRecordList.length
                    ? this.renderApprove()
                    : <></>
            }
            {
                this.state.showCheckSign
                    ? <div className={style.checkSign}>
                        <CheckOutline color="#ffffff" fontSize={15} />
                    </div>
                    : <></>
            }
            
        </div>
    }

    componentDidMount() {
        let record = this.props.data;
        if (record.approvalRecordList && record.approvalRecordList.length) {
            
            if (Array.prototype.findLastIndex) {
                record.currentApproveIndex = record.approvalRecordList.findLastIndex(a => a.approveState !== 0)
            } else {
                record.currentApproveIndex = -1;
                for (let i = record.approvalRecordList.length - 1; i >= 0; i--) {
                    if (record.approvalRecordList[i].approveState !== 0) {
                        record.currentApproveIndex = i;
                    }
                }
            }
            
        }
        this.setState({
            record: {
                ...record
            },
            showApproveStep: this.props.showApproveStep === true,
            showCheckSign: this.props.showCheckSign === true,
            showCheckBox: this.props.showCheckBox === true,
            showReApply: this.props.showReApply === true,

            checked: this.props.checked === true
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.checked !== this.props.checked) {
            let checked = nextProps.checked === true
            this.setState({
                checked: checked
            })
        }
    }


}

export default ApplyRecord;