
const turf = require('@turf/turf')

const in_line_threshold = 0.5

/**
 * 一维数值数组转经纬度二维数值数组
 * @param {*} paths 
 * @returns 
 */
const toAMapLine = (paths) => {
    let _array = paths
    let _paths = []
    for (let i = 0; i < _array.length; i += 2) {
        _paths.push({
            lat: Number(_array[i]),
            lng: Number(_array[i + 1])
        })
    }

    return _paths.map(a => [a.lng, a.lat])
}

/**
 * 经纬度二维数组转纬经度一维数值数组
 * @param {*} line 
 * @returns 
 */
const toFlatArrayLagLng = (line) => {
    let _line = line.map(a => [a[1], a[0]])
    let array = []
    for (let i in _line) {
        array.push(..._line[i])
    }

    return array
}

const findPlottedSubLine = (coords, line) => {
    let turf_coords = turf.point([coords.lng, coords.lat])
    let in_line = false
    let sub_line = []
    let pToSubLineResult = []
    for (let i = 0; i < line.length - 1; i++) {
        sub_line = [line[i], line[i + 1]]
        in_line = turf.booleanPointOnLine(turf_coords, turf.lineString(sub_line), {ignoreEndVertices: false})
        if (in_line) {
            // 点在线上
            pToSubLineResult.push({
                index: i,
                sub_line: sub_line,
                in_line: in_line,
                dist: 0
            })
        } else {
            let dist = turf.pointToLineDistance(turf_coords, turf.lineString(sub_line), { units: "kilometers" }) * 1000
            pToSubLineResult.push({
                index: i,
                sub_line: sub_line,
                in_line: in_line,
                dist: dist
            })
        }
    }

    let demarcationIndex = -1
    for (let i = 0; i < pToSubLineResult.length; i++) {
        let item = pToSubLineResult[i]
        if (item.in_line || item.dist < in_line_threshold) {
            // 点在线段上 或 视作点在线段上
            demarcationIndex = i

            if (i + 1 < pToSubLineResult.length) {
                // 当前不是最后一段，需要判断下一段是不是更接近turf_coords点
                let nextItem = pToSubLineResult[i + 1]
                if (nextItem.in_line || nextItem.dist < in_line_threshold) {
                    demarcationIndex = i + 1
                    break
                }
            }

            break
        }
    }

    if (demarcationIndex === -1) {
        // 未能取得投影点，视作偏航
        return {
            code: 2
        }
    }

    // 找到剩余路径
    let remainingSegment = []
    // 已完成路径
    let completeSegment = []
    for (let i in pToSubLineResult) {
        let item = pToSubLineResult[i]
        if (item.index < demarcationIndex) {
            completeSegment.push(item.sub_line[0])
        } else if (item.index === demarcationIndex) {
            completeSegment.push(item.sub_line[0])
            completeSegment.push([coords.lng, coords.lat])
            remainingSegment.push([coords.lng, coords.lat])
            remainingSegment.push(item.sub_line[1])
        } else {
            remainingSegment.push(item.sub_line[1])
        }
    }

    return {
        code: 0,
        remainingSegment: remainingSegment,
        completeSegment: completeSegment
    }
}

/**
 * 查找是否已经偏航
 * @param {*} paths 
 * @param {*} third 
 * @param {*} tolerance 
 */
const getRemainingSegment = (paths, third, tolerance) => {
    let line = toAMapLine(paths)
    let point = turf.point([third[1], third[0]])

    let snapped = turf.nearestPointOnLine(turf.lineString(line), point, { units: "kilometers" })
    let distance = snapped.properties.dist *= 1000
    let coordinates = snapped.geometry.coordinates

    let coords = {
        lat: coordinates[1],
        lng: coordinates[0]
    }

    if (distance > tolerance) {
        // 最近的路径距离大于容忍值，视为偏航
        return {
            code: 1,
            message: '已经偏航',
            distance: distance
        }
    }

    let res = findPlottedSubLine(coords, line)
    if (res.code) {
        return {
            code: res.code,
            message: '未能取得投影点，视作偏航',
            distance: distance
        }
    }
    
    let {remainingSegment, completeSegment} = res

    return {
        code: 0,
        message: '',
        distance: distance,
        path: toFlatArrayLagLng(remainingSegment),
        completePath: toFlatArrayLagLng(completeSegment)
    }
}

/**
 * 获取折线总长度
 * @param {*} paths 
 * @returns 
 */
const totalDistance = (paths) => {
    let length = turf.length(turf.lineString(toAMapLine(paths)), { units: "kilometers" })
    return length * 1000
}

/**
 * 获取剩余路径
 * @param {*} path 
 * @param {*} third 
 */
const getRemainingPath = (path, third) => {
    return getRemainingSegment(path, third, 10)
}

/**
 * 计算两点间直线距离
 * @param {*} a 
 * @param {*} b 
 * @returns 
 */
const distance = (a, b) => {
    return turf.distance(turf.point([a.lng, a.lat]), turf.point([b.lng, b.lat]), { units: "kilometers" }) * 1000;
} 

export {
    getRemainingSegment,
    totalDistance,
    getRemainingPath,
    distance
}
