import React from "react"
import style from './limitedTimeDisable.scss'
import {inject, observer } from 'mobx-react'

import images from '../util/images'
import {customerServiceTelephone} from '../util/config'

import { withTranslation } from "react-i18next";

@inject('globalStore')
@observer
class LimitedTimeDisable extends React.Component {

    globalStore = this.props.globalStore;
    t = this.props.t

    render() {
        return <div className={style.container}>
            <div className={style.tips}>
                <div>
                    <img src={images.limitedTimeDisable} alt={this.t('noAuth.k003')} />
                </div>
                {/* 您已被禁用车辆功能，解禁时间为 */}
                <div>{this.t('noAuth.k004')}{this.globalStore.userInfo.disableToDate}</div>
                {/* 请联系管理员或拨打服务热线 */}
                <div>{this.t('noAuth.k005')} <span>{customerServiceTelephone}</span></div>
            </div>
        </div>
    }
}

export default withTranslation()(LimitedTimeDisable);