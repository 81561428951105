
import CryptoJS from 'crypto-js'


const decryptAES_ECB_PKCS7Padding = (decryptString, key) => {
    key  = CryptoJS.enc.Utf8.parse(key);
	let decrypt = CryptoJS.AES.decrypt(decryptString, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
	return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}

export {
    decryptAES_ECB_PKCS7Padding
}