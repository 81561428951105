
const findLogoSrc = (sourceId) => {
    // if (logos[sourceId]) {
    //     return logos[sourceId]
    // } else {
    //     return `https://ipath-prod-public-1309103551.cos.ap-shanghai.myqcloud.com/translogos/${sourceId}.png`
    // }
    return `https://ipath-prod-public-1309103551.cos.ap-shanghai.myqcloud.com/translogos/${sourceId}.png`
}

window.findLogoSrc = findLogoSrc
export {
    findLogoSrc
}
