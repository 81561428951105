import { observable, action, makeObservable } from "mobx";
// import loger from './../util/loger'
import confirmStore from './confirm.store'

class GlobalStore {
    constructor() {
        makeObservable(this)
    }

    /**
     * 0: 未登录
     * 1: 已登录
     * 2: 无权限
     * 4: 网络错误
     */
    @observable loginState = 0;

    @observable userInfo = {
        isAgreement: true,
        phone: '',
        emergencyPhone: '',
        useCarMode: 2,
        language: 1,
        isManager: false,
        acceptBusinessAgreement: false,
        permissions: {
            runing: true,
            approve: true,
            pay: true,
            trip: true,
            invoice: true,
            scene: true,
            setting: true,
            guide: true,
            coupon: true,
            account: true,
            report: true
        },
        // disable: true,
        // disableToDate: '2023-10-23',
        // defaultServiceType: 2
    }

    @observable companyInfo = {
        id: '',
        nameCn: '',
        nameEn: '',
        manualUrl: '',
        serverTypes: []
    }

    @action.bound setUserInfo(userInfo) {
        // userInfo = {
        //     ...userInfo,
        //     disable: true,
        //     disableToDate: '2023年10月23日'
        // }
        //userInfo.defaultServiceType = 2;

        let adminPermissions = ['approve', 'account', 'report', 'preApproval','preApply'];
        let permissions = userInfo.permissions;
        let companyPermissions = userInfo.companyPermissions.filter(item => {return adminPermissions.indexOf(item) === -1});
        permissions = permissions.concat(companyPermissions);
        userInfo.permissions = {};
        if (permissions) {
            for(let i in permissions) {
                userInfo.permissions[permissions[i]] = true;
            }
        }
        //userInfo.permissions = this.userInfo.permissions;
        this.userInfo = userInfo;
        this.loginState = 1;
        if(this.userInfo.language) {
          window.lang = this.userInfo.language
        }
        
        if (!confirmStore.passenger.flagPhone && !confirmStore.passenger.flagName) {
          confirmStore.setPassenger({
            phone: this.userInfo.phone,
            name: this.userInfo.nameCn
          })
        }
  
        if (userInfo.serverTypes && userInfo.serverTypes.length) {
            this.setCompanyInfo({
                ...this.companyInfo,
                serverTypes: userInfo.serverTypes
            })
        }
        
        // loger.debug('userInfo', JSON.parse(JSON.stringify(this.userInfo)))
    }

    @action.bound setLoginState(loginState) {
        this.loginState = loginState;
    }

    @action.bound setCompanyInfo(companyInfo) {
        companyInfo.serverTypes = [...companyInfo.serverTypes]
        companyInfo.serverTypes = companyInfo.serverTypes.sort((a, b) => {
          return a - b;
        });
        if (this.companyInfo.serverTypes && this.companyInfo.serverTypes.length) {
            let res = companyInfo.serverTypes.filter(a => this.companyInfo.serverTypes.includes(a))
            companyInfo.serverTypes = res
        }
        this.companyInfo = companyInfo;
        window.companyId = companyInfo.id
    }

    @action.bound setUserAgreement(agreement) {
        this.userInfo.isAgreement = agreement;
    }

    @action.bound setUserProtocol(agreement) {
      this.userInfo.acceptBusinessAgreement = agreement;
  }

}

export default new GlobalStore();
