import React from 'react'
import style from './approvalDetails.scss'
import {inject, observer } from 'mobx-react'

import { Collapse, Button, Dialog, TextArea, Toast } from 'antd-mobile'

import ApprovalCell from '../component/approval/approvalcell'

import moment from 'moment'

import images from '../util/images'

import { orderStateToCn } from '../util/paramsHelper'

import webService from '../util/webService'

import { withTranslation } from "react-i18next";

import {customerServiceTelephone} from './../util/config'
@inject('globalStore', 'approvalStore')
@observer
class ApprovalDetails extends React.Component {

  state = {
    // 详情数据
    orderInfo: {

    },
    // 费用明细
    costInfo: [],
    // 审批日志
    approveHistory: [],
    // 增值服务
    extraServices: [],

    coupon: {},

    isShow: false,

    isVisible: false,

    reasonVal: '',  // 拒绝弹窗 value

    checkedList: [], // 选择保存数据
    ApproveListState: [],

    lastApproveList: [],
    isShowApprove: false,
    showDetails: false
    
  }

  t = this.props.t
  
  globalStore = this.props.globalStore

  approvalStore = this.props.approvalStore

  get isApproveState() {
    if (!this.state.approveHistory.currentApproveState) {
      return true
    }
    if (this.state.approveHistory.currentApproveState === 1) {
      return true
    }
    return false
  }

  get isApprovalType() {
    if (this.approvalStore.activeKey) {
      return 3
    }
    return 2
  }

  /**
   *
   *详情
   */
  renderDetailsCell = () => {
    return(
        <ApprovalCell
          t={this.props.t}
          isIcon={false}
          ipathOrderId={this.state.orderInfo.icardcOrderId}
          sceneNameCn={this.state.orderInfo.sceneNameCn}
          useCarReason={this.state.orderInfo.useCarReason}
          approvalId={this.state.orderInfo.id}
          amount={String(this.state.orderInfo && this.state.orderInfo.companyBearAmount ? this.state.orderInfo.companyBearAmount : '')}
          carSource={this.state.orderInfo.carSources}
          cityName={this.state.orderInfo.departCityName}
          approvalState={this.state.orderInfo.approvalState}
          departTime={this.state.orderInfo.departTime ? moment(this.state.orderInfo.departTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          deparLocation={this.state.orderInfo.pickupLocationName}
          destLocation={this.state.orderInfo.destLocationName}
          distance={this.state.orderInfo.travelDistance}
          userNameCn={this.state.orderInfo.userNameCn}
          duration={this.state.orderInfo.duration}
          budgetCode={this.state.orderInfo.budgetCode}
          serviceType={this.state.orderInfo.serviceType}
          charteredBusType={this.state.orderInfo.charteredBusType}
          // 异常行前审批转行后审批 行前申请单号
          rulePreDepartApplyId={this.state.orderInfo.rulePreDepartApplyId}
          // 施维雅自定义用车字段
          customLabel={this.state.orderInfo.customLabel}
          orderState={this.state.orderInfo.state}
        ></ApprovalCell>
    )
  }

  /**
   *
   *费用明细
   */
  renderCostDetail = () => {
    return(
      <div className={style.cost}>
        <Collapse>
          <Collapse.Panel key='1' title={this.t('approvalDetails.k001')}>
            {
              <div className={style.service_box}>
                {
                this.state.costInfo && this.state.costInfo.map((item,index) => {
                  return (
                    <div key={index} className={style.box_expenses}>
                      <div>  
                        <p key={index}>
                          {item.customfeesDisplayName ? item.customfeesDisplayName : item.feeName}: <em className={item.billType === 10011 ? style.marking : ''}>{item.consumeMoney ? item.consumeMoney + this.t('approvalDetails.k002') : ''}</em>
                        </p>
                        {
                          item.billType === 10011 ? <div className={style.housekeeper}>{this.t('orderdetails.k041')}</div> : <></>
                        }
                      </div>
                        {
                          // (()=> {
                          //   if(item.billType === 10011) {
                          //     if (!this.state.isShow) {
                          //       return (
                          //       <div onClick={() => {
                          //         this.setState({
                          //           isShow: true
                          //         })
                          //       }}>
                          //         {/* k038 查看说明 */}
                          //         <em>{this.t('orderdetails.k038')}</em>
                          //         <Image
                          //           src={images.downArrowhead}
                          //           width={10}
                          //           height={7}
                          //           fit='cover'
                          //         />
                          //       </div>
                          //       )
                          //     } else {
                          //       return (
                          //       <div onClick={() => {
                          //         this.setState({
                          //           isShow: false
                          //         })
                          //       }}>
                          //         {/* k039 收起说明 */}
                          //         <em>{this.t('orderdetails.k039')}</em>
                          //         <Image
                          //           src={images.upArrowhead}
                          //           width={10}
                          //           height={7}
                          //           fit='cover'
                          //         />
                          //       </div>
                          //       )
                          //     }
                          //   }
                          // })()
                        }
                    </div>
                  )
                })
                }
                {/* k041 预约管家为员工代叫不同平台车型的差价已直接扣除，无需由企业支付。 */}
                {/* {
                  this.state.isShow ? <div className={style.housekeeper}>{this.t('orderdetails.k041')}</div> : <></>
                }  */}
              </div>
            }
            <div className={style.extraServices}>
              {/* <p className={style.discounts}>{this.state.coupon.nameCn ? this.state.coupon.nameCn + ':' : ''}{this.state.coupon.amount ? this.state.coupon.amount + '元' : ''}</p> */}
              {
                this.state.extraServices && this.state.extraServices.map((item, index) => {
                  return <p key={index} className={style.discounts}>{item ? item.nameCn + ':' : ''} {item ? item.amount + this.t('approvalDetails.k002') : ''}</p>
                })
              }
            </div>
            <span>
            {this.t('approvalDetails.k003', {serviceNumber: customerServiceTelephone})}
            </span>
            <p></p>
          </Collapse.Panel>
        </Collapse>
      </div>
    )
  }

  /**
   *
   *日志
   */
  renderLog = () => {
    return(
        <Collapse>
          <Collapse.Panel key='1' title={this.t('approvalDetails.k004')}>
            {
              (() => {
                if (this.state.approveHistory && this.state.approveHistory.lastApproveState) {
                  return (
                    <p className={style.lastApproveState}>{this.t('approvalDetails.k005')}{orderStateToCn(this.state.approveHistory ? this.state.approveHistory.lastApproveState : '', this.t)}</p>
                  )
                }
              })()
            }
              {
                this.state.approveHistory && this.state.approveHistory.list && this.state.approveHistory.list.map((item,index) => {
                  return(
                    <div key={index} className={index === this.state.approveHistory.list.length - 1 ? style.last : style.first}>
                      <p>{this.t('approvalDetails.k006')} {item.createTime}</p>
                      {
                        (() => {
                          if(item.nameCn) {
                            return(
                              <p>{this.t('approvalDetails.k007')} {item.nameCn}</p>
                            )
                          }
                        })()
                      }
                      <p>{this.t('approvalDetails.k008')} {item.reason}</p>
                      {
                        (() => {
                          // 审核状态
                          if (item.state === 4) {
                            if (this.globalStore.userInfo.language === 1) {
                              return (
                                  <img className={style.refuse} alt="" src={images.refuse}></img>
                                )
                            } else {
                              return (
                                <img className={style.refuse} alt="" src={images.refuseEn}></img>
                              )
                            } 
                          } else if (item.state === 2) {
                              if (this.globalStore.userInfo.language === 1) {
                                return(
                                  <img className={style.pass} alt="" src={images.pass}></img>
                                )
                              } else {
                                return(
                                  <img className={style.pass} alt="" src={images.passEn}></img>
                                )
                              }
                          } else if (item.state === 1) {
                            return(
                              <img className={style.pass} alt="" src={images.unhandle}></img>
                            )
                          }
                        })()
                      }
                    </div>
                  )
                })
              }
          </Collapse.Panel>
        </Collapse>
      )
  }

  renderApprovalBar = () => {
      if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.approvalDetailsBtn) {
        return(
          <div className={style.bar}>
            <div className={style.right}>
              <Button onClick={() => {this.handleReject()}}>{this.t('approval.k010')}</Button>
              <Button onClick={() => {this.handleAffirm()}} style={{'--background-color':'#2F7DCD'}} color='primary'>{this.t('approval.k011')}</Button>
            </div>
          </div>
        )
      }
  }

  render() {
    return (
      <div className={style.details}>
        {
          this.state.showDetails ? 
          <div>
            {/* 详情部分 */}
            {this.renderDetailsCell()}
            {/* 费用部分 */}
            {this.renderCostDetail()}
            {/* 日志部分 */}
            {this.renderLog()}
            {/* 详情审批按钮 */}
            {this.isApproveState && this.approvalStore.activeKey !== '2' && this.state.isShowApprove ? this.renderApprovalBar(): <></>}
          </div> : <></>
        }
      </div>
    )
  }

  componentDidMount() {
    this.getOrderDetails()
  }

  getOrderDetails = async () => {
    const { id } = this.props.match.params
    let {data} = await webService.order.getOrderDetails({orderId: id, type: this.isApprovalType})
    if (data.code === 0) {
      if (!data.data.orderInfo.budgetCode) {
        data.data.orderInfo.budgetCode = []
      }
      if (!data.data.approveHistory || data.data.approveHistory.currentApproveState === 1) {
        this.setState({
          isShowApprove: true
        })
      }
      this.setState({
        showDetails: true,
        orderInfo: data.data.orderInfo,
        costInfo: data.data.bill && data.data.bill[0]? data.data.bill[0].billDetail : [],
        approveHistory: data.data.approveHistory || [],
        extraServices: data.data.extraServices,
        coupon: data.data.coupon ? data.data.coupon : {}
      })
    } else if (data.code === 110) {
      Toast.show({
        content: this.t('approval.k048'),
      })
    }
  }

  handleAffirm = () => {
    Dialog.confirm({
      content: (
        <>
          <div className={style.through}>{this.t('approval.k034')}</div>
        </>
      ),
      getContainer: false,
      maskClassName: `${style.maskApproval}`,
      cancelText: this.t('common.k001'),
      confirmText: this.t('common.k002'),
      onConfirm: async () => {
        const { id } = this.props.match.params
        let params = {
          orderIds: [id],
        }

        Toast.show({
          icon: "loading",
          content: this.t('approval.k036'),
          maskClickable: false,
          duration: 0
        })

        let d = await webService.approval.setApprovalVerify(params)
        if (d.data.code === 0) {
          this.setState({
            checkedList: [],
            isShowApprove: false
          })

          Toast.show({
            icon: 'success',
            content: this.t('approval.k028'),
            duration: 1000
          }, () => {
            Toast.clear()
          })
          this.getOrderDetails()
        } else {
          Toast.clear()
          Dialog.alert({
            content: this.t('approval.k043'),
            confirmText: this.t('common.k003')
          })
        }
      },
    })
  }

  handleReject = () => {
    Dialog.confirm({
      getContainer: false,
      bodyClassName: `${style.prompt}`,
      confirmText: this.t('approval.k031'),
      cancelText: this.t('approval.k032'),
      title: this.t('approval.k033'),
      content: (<>
          <div>
            <TextArea placeholder={this.t('approval.k033')} disabled={this.state.isVisible} maxLength={100} rows={3} defaultValue={this.state.reasonVal} 
                onChange={(val) => {
                  this.setInput(val)
                }} />
          </div>
      </>),
      onConfirm: ()=> {
          this.handleConfirmReject()
      },
      onCancel: () => {
        this.handleCancel()
      }
    })
  }

  /**
   *
   *拒绝原因
   */
   setInput = (val) => {
    this.setState({
      reasonVal: val
    })
  }

  /**
   *
   *取消
   */
   handleCancel = () => {
    this.setState({
      isVisible: false,
      reasonVal: ''
    })
  }

  /**
   *
   *确认拒绝审批
   */
  handleConfirmReject = () => {
    if (!this.state.reasonVal) {
      Toast.show({
        content: this.t('approval.k033'),
      })
    } else {
      const { id } = this.props.match.params
      let params = {
        orderIds: [id],
        reason: this.state.reasonVal
      }

      Toast.show({
        icon: "loading",
        content: this.t('approval.k036'),
        maskClickable: false,
        duration: 0
      })

      webService.approval.setApprovalReject(params).then(d => {
        if (d.data.code === 0) {
          this.setState({
            checkedList: [],
            reasonVal: '',
            isVisible: false,
            isShowApprove: false
          })
          this.getOrderDetails()
          Toast.show({
            icon: 'success',
            content: this.t('approval.k028'),
            duration: 1000
          }, () => {
            Toast.clear()
          })
        } else {
          Toast.clear()
          Dialog.alert({
            content: this.t('approval.k043'),
            confirmText: this.t('common.k003')
          })
        }
      }) 
    }
  }
}

export default withTranslation()(ApprovalDetails)