import React from 'react'
import style from './coupon.scss';
import { RightOutline, DownOutline } from 'antd-mobile-icons'

class Coupon extends React.Component {
    state = {
        showDetails: false
    }

    t = this.props.t
    
    render() {
        let {data, onClick} = this.props;
        return <div className={style.box}>
            <div className={this.getCouponClassName(data) } onClick={onClick}>
                <div onClick={() => {}}>
                    <div className={style.postBorder}></div>
                    <div>
                        <span>￥</span>{data.parValue}
                    </div>
                    <div className={style.tip}>
                        {this.t('chooseCoupon.k001', {money: data.threshold})}
                    </div>
                    
                </div>
                <div onClick={() => {}}>
                    <div className={style.topInnerRadius}></div>
                    <div className={style.leftInnerRadius}></div>
                    <div className={style.case} onClick={() => {}}>
                        <span>{data.source === 1 ? this.t('chooseCoupon.k002') : this.t('chooseCoupon.k003')}</span>
                        {
                          data.state !== 3 ? <p className={style.normal}>{data.title}</p> : ''
                        }

                        {
                          data.state === 3 ? <p className={style.limit}>{data.title}</p> : ''
                        }

                        {
                          data.state === 3 ? <p onClick={() => {
                            this.props.history.push(`/orderdetails/${data.orderId}`);
                          }} className={style.useDetails}>{this.t('coupons.k014')}&gt;</p> : ''
                        }
                        
                    </div>
                    <div className={style.allow} onClick={() => {}}>
                        {
                            data.isFrozen
                                ? <div>{this.t('coupons.k013')}</div>
                                : <div>{data.effectivePeriod}</div>
                        }
                    </div>
                    <div className={style.details} onClick={(event) => {
                        event.stopPropagation();
                        this.setState({
                            showDetails: !this.state.showDetails
                        })
                    }}>{this.t('chooseCoupon.k004')} { !this.state.showDetails ? <RightOutline /> : <DownOutline />}</div>
                </div>
            </div>
            {
                this.state.showDetails
                    ? <div className={style.details}>
                        <div>
                            <div>{this.t('chooseCoupon.k005')}</div>
                            <div>{this.getAllowCars()}</div>
                        </div>
                        <div>
                            <div>{this.t('chooseCoupon.k006')}</div>
                            <div>{this.getAllowLevel()}</div>
                        </div>
                        <div>
                            <div>{this.t('chooseCoupon.k007')}</div>
                            <div>{this.getAllowCities()}</div>
                        </div>
                        
                    </div>
                    : <></>
            }
            
        </div>
    }

    /**
     * 获取卡券顶层样式
     * @param {*} data 
     * @returns 
     */
    getCouponClassName = (data) => {
        let cls = [style.ticket];
        if (data.source === 1) {
            cls.push(style.company)
        }
        if (!data.isValid) {
            cls.push(style.disable)
        }
        if (!data.isValid && !data.isExpired && data.state === 3) {
            cls.push(style.timeout)
        }
        if (data.isFrozen) {
            cls.push(style.frozen)
        }

        return cls.toString().replace(/,/g, ' ')
    }

    getAllowCities = () => {
        let {allowCities} = this.props.data;
        return allowCities && allowCities.list && allowCities.list.length ? `${allowCities.mode === 'contain' ? '' : this.t('chooseCoupon.k008')}${allowCities.list.toString()}` : this.t('chooseCoupon.k009')
    }

    getAllowLevel = () => {
        let {allowCars} = this.props.data;
        let level = allowCars.listCarLevel && allowCars.listCarLevel.length ? allowCars.listCarLevel.toString() : this.t('chooseCoupon.k009');
        return level;

    }

    getAllowCars = () => {
        let {allowCars} = this.props.data;
        let cars = allowCars.listCarSource && allowCars.listCarSource.length ? `${allowCars.mode === 'contain' ? '' : this.t('chooseCoupon.k008')}${allowCars.listCarSource.toString()}` : this.t('chooseCoupon.k009');
        return cars;

    }
}

export default Coupon;