
import React from "react"
import style from './addSanofiMeeting.scss'
import webService from "../../util/webService"
import images from "../../util/images";
import { debounce } from "../../util/utils";
import { withTranslation } from "react-i18next";
import { List, Dialog, Toast, Tag } from 'antd-mobile'
import { ExclamationCircleOutline, SearchOutline } from 'antd-mobile-icons'

class AddSanofiMeeting extends React.Component {

  state = {
    meetingList: [],
    meetingKey: '',
    isMeeting: false
  }

  t = this.props.t
  // 会议列表
  renderList() {
    return <List>
      {
        this.state.meetingList.map((item, index) => {
          return <List.Item key={index} className={style.listItem} clickable={false} onClick={() => { this.handleAdd(item) }}>
            {
              this.state.isMeeting
                ? this.renderMeetingCell(item)
                : this.renderIOCell(item)
            }
          </List.Item>
        })
      }
    </List>
  }
  // IO会议
  renderIOCell(item) {
    return <>
      <div className={style.title}>
        {item.eventName}
      </div>
      <div>{item.eventCode}</div>
    </>
  }
  // 常用会议
  renderMeetingCell(item) {
    return <>
      <div className={style.title}>
        {this.t('sanofiMeeting.k017')}:{item.eventCode}
        {
          item.type === 0
            ? <Tag className={style.tag} style={{ '--border-radius': '6px' }} color='#2F7DCD'>常用会议</Tag>
            : <></>
        }
      </div>
      <div>{this.t('sanofiMeeting.k014')}:{item.ioTitle}</div>
      <div>{this.t('sanofiMeeting.k015')}:{item.ioCode}</div>
      <div>{this.t('sanofiMeeting.k016')}:{item.employeeName}</div>
    </>
  }
  renderEmpty() {
    return <>
      <div className={style.nodata}>
        <div className={style.box}>
          <img alt="" src={images.noSanofiMeeting}></img>
          {/* 暂无会议 */}
          <span>{this.t('sanofiMeeting.k003')}</span>
        </div>
      </div>
    </>
  }

  renderSearch() {
    return <>
      <div className={style.heads}>
        <div className={style.head}>
          <div className={style.input}>
            {/* 请输入会议ID或名称 */}
            {this.state.meetingKey ? <></> : <SearchOutline className={style.searchIcon} />}
            <input type="text" placeholder={this.t('sanofiMeeting.k011')} value={this.state.meetingKey} onChange={this.handleChange} />
          </div>
          <div className={style.cancel} onClick={this.handleCancel}>{this.t('main.k062')}</div>
        </div>
      </div>
    </>
  }

  render() {
    return <>
      <div className={style.addSanofiMeeting}>
        {this.renderSearch()}
        <div className={style.container}>
          <div>
            {
              this.state.meetingList.length ? this.renderList() : this.renderEmpty()
            }
          </div>
        </div>
      </div>
    </>
  }


  async componentDidMount() {
    Toast.show({
      icon: 'loading',
      content: this.t('confirm.k029'),
      duration: 0,
      maskClickable: false
    })
    await this.getList();
    Toast.clear();
  }

  // 获取常用会议
  getCommonlyUsedMeeting = async () => {
    const { data } = await webService.special.sanofiMeeting.getCommonlyUsedMeeting({ param: this.state.meetingKey });
    this.setState({
      meetingList: data.data,
    })
  }

  // 添加io-获取会议列表
  getIOMeetingList = async () => {
    const { data } = await webService.special.sanofiMeeting.getIOMeetingList({ param: this.state.meetingKey });
    this.setState({
      meetingList: data.data,
    })
  }

  handleAdd = async (meeting) => {
    if (this.state.isMeeting) {
      this.props.onChange(meeting);
    } else {
      Dialog.confirm({
        // 确认添加吗
        content: this.t('sanofiMeeting.k012'),
        cancelText: this.t('common.k001'),
        confirmText: this.t('common.k002'),
        onConfirm: async () => {

          const { data } = await webService.special.sanofiMeeting.addPersonalMeeting({ meetingId: meeting.id });
          if (data.code === 0) {
            Toast.show({
              icon: 'success',
              // '操作成功'
              content: this.t('sanofiMeeting.k013'),
            })
            this.props.history.goBack();
          } else {
            Toast.show({
              icon: <ExclamationCircleOutline />,
              content: data.message,
            })
          }
        },
      })
    }

  }

  // 防抖
  handleSearch = debounce(() => this.getList(true), 500);

  handleChange = (e) => {
    this.setState({
      meetingKey: e.target.value
    });
    this.handleSearch(e.target.value)
    // debounce(this.getList(), 1000)();
  }

  getList = async (isSearch = false) => {
    await this.setState({ isMeeting: this.props.isMeeting })
    if (this.props.isMeeting && !isSearch) {
      await this.getCommonlyUsedMeeting();
      return
    }
    await this.getIOMeetingList();
  }

  handleCancel = () => {
    this.setState({
      addrKey: ''
    })
    if (this.state.isMeeting) {
      this.props.onChange('');
    } else {
      this.props.history.goBack();
    }
  }
}

export default withTranslation()(AddSanofiMeeting)