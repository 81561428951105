import React from 'react'
import style from './account.scss';
import { DatePicker, CascadePicker } from 'antd-mobile';
import { UpOutline } from 'antd-mobile-icons'

import moment from 'moment'

import webService from './../util/webService';
import images from './../util/images';

import { withTranslation } from "react-i18next";

class Account extends React.Component {

    state = {
        // 账户列表
        list: [],
        current: {},
        rows: [],
        beginMonth: moment().toDate(),
        endMonth: moment().toDate()
    }

    t = this.props.t

    render() {
        return <div className={style.container}>
            <div className={style.info}>

                <div className={style.companyName}>{this.state.current ? this.state.current.name:''}</div>
                <div className={style.balance}>
                    <div>
                        <div>{this.t('account.k001')}</div>
                        <div><span>￥</span>{this.state.current ? this.state.current.balance : ''}</div>
                    </div>
                    <div>
                        <div>{this.t('account.k002')}</div>
                        <div><span>￥</span>{this.state.current ? this.state.current.frozen : ''}</div>
                    </div>
                </div>

                <div className={style.change}>
                    <div onClick={async () => {
                        let options = this.state.list.map(item => {
                            return {
                                label: item.name,
                                value: item.id
                            }
                        })
                        const value = await CascadePicker.prompt({
                            options: options,
                            value: [this.state.current ? this.state.current.id : '']
                        })
                        if (value && value.length) {
                            this.setState({
                                current: this.state.list.filter(a => a.id === value[0])[0]
                            }, () => {
                              this.loadAccount()
                            })
                        }
                        
                    }}>{this.t('account.k003')}</div>
                    <div onClick={() => {
                        if (this.state.current.id) {
                            this.props.history.push(`/accountCharge/${this.state.current.id}`)
                        }
                        
                    }}>{this.t('account.k004')}</div>
                </div>
                {/* <div className={style.balance}>
                    <div>
                        <div className={style.lab}>可用额度</div>
                        <div>￥{this.state.balance}</div>
                    </div>
                    <div>
                        <div className={style.lab}>已冻结金额</div>
                        <div>￥{this.state.frozen}</div>
                    </div>
                </div>
                <div className={style.charge}>
                    <div onClick={() => {
                        this.props.history.push('/accountCharge')
                    }}>充值</div>
                </div> */}
            </div>
            <div className={style.body}>
                <div className={`${style.head} ${style.colhead}`}>
                    <div>{this.t('account.k005')}</div>
                    <div>{this.t('account.k006')}</div>
                    <div>{this.t('account.k007')}</div>
                </div>
                <div>
                    {
                        this.state.rows && this.state.rows.length
                            ? (this.state.rows.map((item, index) => {
                                return <div key={index} className={style.colhead}>
                                    <div>{item.month}</div>
                                    <div>{item.subjectCn}</div>
                                    <div className={item.subjectType === 1 ? style.inc : style.sub}>{item.amount}</div>
                                </div>
                            }))
                            : (<div className={style.noneData}>
                                <div>
                                    <img src={images.account_nodata} alt={this.t('account.k008')} />
                                </div>
                                <div>{this.t('account.k008')}</div>
                            </div>)
                    }
                </div>
                <div className={style.footbar}>
                    <div onClick={() => { this.chooseMonth(true);}}>{this.t('account.k009')}{moment(this.state.beginMonth).format('YYYY-MM')}&nbsp;<UpOutline color="#C7C7CC"  fontSize={12}/></div>
                    <div onClick={() => { this.chooseMonth(false);}}>{this.t('account.k010')}{moment(this.state.endMonth).format('YYYY-MM')}&nbsp;<UpOutline color="#C7C7CC"  fontSize={12}/></div>
                </div>
            </div>
        </div>
    }

    componentDidMount() {
        webService.account.getBaseInfo().then(d => {
            this.setState({
                list: d.data.data ? d.data.data.list : [],
                current: d.data.data && d.data.data.lsit && d.data.data.list.length ? d.data.data.list[0] : {}
            }, this.loadAccount)
        })
       
    }

    /**
     * 加载交易记录
     */
    loadAccount = () => {
        let id = this.state.current ? this.state.current.id : '';
        if (id) {
            webService.account.getAccount({
                id: id,
                beginMonth: moment(this.state.beginMonth).format('YYYY-MM'),
                endMonth: moment(this.state.endMonth).format('YYYY-MM')
            }).then(d => {
                
                this.setState({
                    rows: d.data.data.rows
                })
            })
        }
        
    }

    chooseMonth = (isBeginMonth) => {
        let month = isBeginMonth ? this.state.beginMonth : this.state.endMonth;
        let min = isBeginMonth ? (moment().add(-3, 'y').toDate()) : this.state.beginMonth;
        DatePicker.prompt({
            defaultValue: month,
            precision: 'month',
            min: min,
            max: moment().toDate()
        }).then(d => {
            if (d) {
              isBeginMonth
                  ? this.setState({
                      beginMonth: d
                  })
                  : this.setState({
                      endMonth: d
                  });
              
                  if (!isBeginMonth) {
                      this.loadAccount();
                  }
            }
        })
    }
}

export default withTranslation()(Account);