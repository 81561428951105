import React from 'react';
import './App.css';
import { HashRouter as Router } from 'react-router-dom'
import { inject, observer } from 'mobx-react'

import routes from './util/router'
import { withRouter } from 'react-router';
import AppRouter from './component/router/index'

import LoadingMask from './component/utils/loadingMask';
import TouchEvent from './component/utils/touchEvent'

import webService from './util/webService';
import { oauthType, appId } from './util/config';
import gc from './util/globalConfiguration';

import i18n from 'i18next'

@inject('globalStore')
@observer
@withRouter
class App extends React.Component {

  state = {
    showLoding: true
  }

  globalStore = this.props.globalStore;

  render() {

    return <>
      {
        this.state.showLoding
          ? <LoadingMask show={this.state.showLoding} />
          : <Router>
            <>
              <AppRouter baseUri={''} routes={routes} />
              {oauthType === '15' ? <TouchEvent></TouchEvent> : <></>}
            </>
          </Router>
      }
    </>

  }


  async componentDidMount() {
    this.loadEDoctorJSSDK();
    let { hash } = window.location;
    if (window.satoken) {
      if (hash.startsWith('#/watchShare')) {
        this.setState({
          showLoding: false
        })
        return;
      }
      await this.getUserInfo();
    } else {
      this.setState({
        showLoding: false
      })
    }

    

    const root = document.documentElement
    root.className = 'imtpath_style'
    if (gc.process.isMeituan) {
      root.className = 'meituan_style'
      console.log('设置美团样式')
    }


  }

  /**
   * 加载当前登陆人信息
   */
  getUserInfo = async () => {
    if (this.globalStore.loginState) {
      return;
    }
    let d;
    try {
      this.globalStore.setLoginState(8)
      d = await webService.basCity.getUserInfo();
    } catch
    {
      this.globalStore.setLoginState(4)
    }

    let { data } = d;
    if (data.code === 0) {
      if (data.data.grayscale) {
        sessionStorage.setItem('grayscale', 'grayscale')
      }
      if (data.data.companyCode) {
        sessionStorage.setItem('clientId', data.data.companyCode)
      }
      this.globalStore.setUserInfo(data.data);
      if (!data.data.nameCn || !data.data.phone) {
      }
      if (data.data.language === 1) {
        i18n.changeLanguage('zh')
      } else if (data.data.language === 2) {
        i18n.changeLanguage('en')
      }
      d = await webService.company.getBasicInfo();
      data = d.data;
      if (data.code === 0) {
        // amap auth 协议的企业用车前需要在前端验证是否具备用车token  
        // data.data.needUseCarToken = true;
        this.globalStore.setCompanyInfo(data.data);

        let company = data.data;
        // 移动用户首页title改成 商旅100
        if (company.nameEn === 'cmcc') {
          let mainPage = routes.filter(a => a.path === '/')[0]
          if (mainPage) {
            mainPage.title = '商旅100'
          }
        }
      }

    }
    else {
      this.globalStore.setLoginState(2);
    }

    this.setState({
      showLoding: false
    })

  }

  loadEDoctorJSSDK = async () => {
    if (oauthType === '7') {
      let {data} = await webService.user.loadEDoctorConfig(appId);
  
      if (data.code === 0 && data.data) {
        let wx_config = window.wx.config;
        window.wx.config = (a) => {
          a.beta = true;
          wx_config(a)
          window.wx.config = wx_config
        }
        let config = data.data;
        let currentLink = window.location.href.split('#')[0];
        function UrlEncode(str) {
          str = (str + '').toString();
          return encodeURIComponent(str).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+');
        }
        let s = 'https://' + config.opUrl + '/api/config.js?key=' + config.key + '&secret=' + config.secret + '&debug=false&url=' + UrlEncode(currentLink);
  
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.onload = () => { 
          setTimeout(() => {
            console.log(`加载e-doctor js签名完成, wx对象${window.wx ? '存在' : '不存在'}  wx.invoke方法${window.wx.invoke ? '存在' : '不存在'}`)
          }, 2000);
        };
        script.src = s;

        
        document.getElementsByTagName('head')[0].appendChild(script); 
      }
  
    }
  }
}

export default App;
