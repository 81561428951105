import axios from 'axios'
const zlib = require('zlib')
const turf = require('@turf/turf')

let _stationData = undefined
let _taskLoadStationData = undefined

/**
 * 下载场站定义文件
 * @param {*} url 
 */
const downloadStationData = (url) => {
    if (_stationData || _taskLoadStationData) {
        return
    }
    _taskLoadStationData = new Promise(async (resolve, reject) => {
        try {
            let { data } = await axios.get(url, { responseType: 'blob' })
            let ab = await data.arrayBuffer()
            const buffer = Buffer.from(ab);
            let stationData = decodeStationData(buffer)
            stationData.url = url

            _stationData = stationData
            resolve()
        } catch (exp) {
            reject(exp)
        }
    })
    
}

/**
 * 解析场站定义文件
 * @param {*} buffer 
 * @returns 
 */
const decodeStationData = (buffer) => {
    let offset = 0
    let len = 0

    len = buffer.readInt16LE(offset)
    offset += 2
    let createDate = buffer.toString('utf8', offset, offset + len)
    offset += len

    len = buffer.readInt16LE(offset)
    offset += 2
    let versionName = buffer.toString('utf8', offset, offset + len)
    offset += len

    len = buffer.readInt16LE(offset)
    offset += 2
    let reason = buffer.toString('utf8', offset, offset + len)
    offset += len

    let listStation = []
    let length = 0
    let buff
    while (buffer.length > offset) {
        offset += 4
        length = buffer.readInt32LE(offset)
        offset += 4

        buff = buffer.subarray(offset, offset + length)
        buff = zlib.gunzipSync(Buffer.from(buff))

        let station = JSON.parse(buff.toString('utf8'))
        let _polygon = station.polygon.split(';').map(a => a.split(',').map(a => Number(a)))
        _polygon.push(_polygon[0])
        station._polygon = turf.polygon([
            _polygon
        ])
        listStation.push(station)

        offset += length
    }

    return {
        createDate: createDate,
        versionName: versionName,
        reason: reason,
        listStation: listStation
    }
}

/**
 * 查找点位是否在有效场站内
 * @param {*} param0 
 * @returns 
 */
const hitStation = async ({ lat, lng, name }) => {
    if (!_stationData) {
        console.log('等待加载')
        await _taskLoadStationData
        console.log('加载完成')
    }

    let station = await loadHitStation(lat, lng, name)
    if (station) {
        let res = {
            ...station
        }
        delete res._polygon
        return res
    }
}

/**
 * 查找给定坐标所在的场站
 * @param {*} lat 
 * @param {*} lng 
 * @param {*} name 
 */
const loadHitStation = async (lat, lng, name) => {

    let pt = turf.point([lat, lng]);
    let listHitStation = []
    for (let i in _stationData.listStation) {
        let station = _stationData.listStation[i]
        if (turf.booleanPointInPolygon(pt, station._polygon, {ignoreBoundary: true})) {
            listHitStation.push(station)
        }
    }

    if (listHitStation && listHitStation.length) {
        // 找到了匹配数据
        if (listHitStation.length === 1) {
            // 单个结果直接返回
            return listHitStation[0]
        } else if (name){
            // 多个结果，有可能是机场与火车站垂直重叠，根据name参数判断用户需要的是站还是场
            if (name.includes('站')) {
                // 坐标名字中包含‘站’字，返回名字里有‘站’的结果
                let arr = listHitStation.filter(a => a.name.includes('站'))
                if (arr && arr.length) {
                    return arr[0]
                }
            } else {
                // 坐标名字中不包含‘站’字，返回名字里没有‘站’的结果
                let arr = listHitStation.filter(a => !a.name.includes('站'))
                if (arr && arr.length) {
                    return arr[0]
                }
            }            
        }

        // 到这里还没返回，则返回结果中的第一条
        return listHitStation[0]
    }

    return undefined
}


export {
    downloadStationData,
    hitStation
}
