import React from 'react'
import style from './shareBar.scss';
import { inject, observer } from 'mobx-react'
import { Toast } from 'antd-mobile'
import images from '../../util/images';

import { FeiShuJsSdk, WeChatWorkJsSdk, DingJsSdk, copy } from './../../util/utils';
import { oauthType, loadJsSdk, unLoadJsSdk, webBaseUrl } from './../../util/config'

@inject('globalStore')
@observer
class ShareBar extends React.Component {
    state = {
      baseInfo: this.props.baseInfo
    }

    globalStore = this.props.globalStore;

    orderId = this.props.orderId
    isGreenTravel = this.props.isGreenTravel

    t = this.props.t

    render() {
        return <div className={style.shareBar}>
            <div className={style.bar}>
                <div className={style.title}>{this.t('runing.k0107')}</div>
                <div className={style.tools}>
                    {
                        this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.ongoing_share_app
                        ? <div>
                            <div className={`${style.btn} ${this.isGreenTravel ? style.green : ''}`} onClick={this.shareSend}>
                                <img src={images.shareSend} alt={this.t('runing.k0106')} />
                            </div>
                            {this.t('runing.k0106')}
                        </div>
                        : <></>
                    }
                    <div>
                        <div className={`${style.btn} ${this.isGreenTravel ? style.green : ''}`} onClick={this.shareCopy}>
                            <img src={images.shareCopy} alt={this.t('runing.k0108')} />
                        </div>
                        {this.t('runing.k0108')}
                    </div>
                
                </div>
            </div>
        </div>
    }

    componentDidMount() {
        
    }

    shareSend = async () => {
        switch (oauthType) {
            case '2': {
                // 飞书
                await loadJsSdk();
                let sdk = new FeiShuJsSdk();
                sdk.share({
                    url: `${window.location.origin}${window.location.pathname}#/watchShare/${this.props.orderId}`,
                    title: this.t('runing.k051'),
                    image: `${window.location.origin}${window.location.pathname}logo192.png`,
                    content: `${this.state.baseInfo.pickupLocationName} ${this.t('runing.k052')} ${this.state.baseInfo.destLocationName}`
                })
                setTimeout(() => {
                    unLoadJsSdk();
                }, 2000)
                break;
            }
            case '11':
            case '1' : {
                // 企业微信
                let sdk = new WeChatWorkJsSdk();
                if (oauthType === '11') {
                  await sdk.init(true)
                } else {
                  await sdk.init()
                }
                sdk.share({
                    link: `${window.location.origin}${window.location.pathname}#/watchShare/${this.props.orderId}`,
                    title: this.t('runing.k051'),
                    imgUrl: `${window.location.origin}${window.location.pathname}logo192.png`,
                    desc: `${this.state.baseInfo.pickupLocationName} ${this.t('runing.k052')} ${this.state.baseInfo.destLocationName}`
                })
                break;
            }
            case '7': {
                if (!window.wx) {
                    console.error('wx对象空，分享失败')
                    break;
                } else {
                    window.wx.invoke("shareAppMessage", {
                            link: `${window.location.origin}${window.location.pathname}#/watchShare/${this.props.orderId}`,
                            title: this.t('runing.k051'),
                            imgUrl: `${window.location.origin}${window.location.pathname}logo192.png`,
                            desc: `${this.state.baseInfo.pickupLocationName} ${this.t('runing.k052')} ${this.state.baseInfo.destLocationName}`
                        },
                        function (res) {
                            if (res.err_msg === "shareAppMessage:ok") {
                                //正确处理
                                console.log('分享成功', `${window.location.origin}${window.location.pathname}#/watchShare/${this.props.orderId}`)
                            } else {
                                console.log('分享失败', res.err_msg)
                                //错误处理
                                // 微信App不持支这个接口，此时需要给页面挂载分享监听，然后引导用户点击右上角三个点进行转发
                                //this.props.history.push(`/share/${this.props.orderId}`)
                                
                            }
                        }
                    );
                }
                break;
            }
            case '13': {
                let sdk = new DingJsSdk();
                sdk.init()
                sdk.share({
                    link: `${window.location.origin}${window.location.pathname}#/watchShare/${this.props.orderId}`,
                    title: this.t('runing.k051'),
                    imgUrl: `${window.location.origin}/share_tip.png`,
                    desc: `${this.state.baseInfo.pickupLocationName} ${this.t('runing.k052')} ${this.state.baseInfo.destLocationName}`
                })
                break;
            }
            case '17' : {
              // 企业微信
              let sdk = new WeChatWorkJsSdk();
              console.log('jnj_sdk.init()')
              await sdk.init(true)
              sdk.share({
                  link: `${window.location.origin}${window.location.pathname}#/watchShare/${this.props.orderId}`,
                  title: this.t('runing.k051'),
                  imgUrl: `${window.location.origin}/share_tip.png`,
                  desc: `${this.state.baseInfo.pickupLocationName} ${this.t('runing.k052')} ${this.state.baseInfo.destLocationName}`
              })
              break;
            }
            default: {
                this.props.history.push(`/share/${this.props.orderId}`)
            }
        }
        // let ua = dge();
        // console.log(ua)
        // ua = 'feishu-mobile';
        // if (ua === 'feishu-mobile') {
        //     // 飞书,单页执行分享逻辑
        //     let sdk = new FeiShuJsSdk();
        //     sdk.share({
        //         url: `${window.location.origin}${window.location.pathname}#/watchShare/${this.props.orderId}`,
        //         title: this.t('runing.k051'),
        //         image: `${window.location.origin}${window.location.pathname}logo192.png`,   
        //         content: `${this.state.baseInfo.pickupLocationName} ${this.t('runing.k052')} ${this.state.baseInfo.destLocationName}`
        //     })
        // } else {
        //     this.props.history.push(`/share/${this.props.orderId}`)
        // }
    }

    shareCopy = async () => {
        let shareUrl = `${webBaseUrl}/#/watchShare/${this.orderId}`
        //await navigator.clipboard.writeText(shareUrl)
        copy(shareUrl, () => {
            Toast.show({
                icon: 'success',
                content: '链接复制成功',
            })
            this.props.onClose()
        })
        

        // const input = document.createElement('input');
        // input.setAttribute('readonly', 'readonly');
        // input.setAttribute('value', 'hello world');
        // document.body.appendChild(input);
        // input.setSelectionRange(0, 9999);
        // input.select();
        // document.execCommand('copy');
        // document.body.removeChild(input);

    }

}

export default ShareBar;
