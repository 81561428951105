import React from 'react';
import style from './jinDuCarInformation.scss';
import { List, SearchBar, CheckList, Popup } from 'antd-mobile';
import { RightOutline } from 'antd-mobile-icons';
import { inject, observer } from 'mobx-react';

import webService from '../../util/webService';

import { debounce } from './../../util/utils';

@inject('globalStore', 'confirmStore')
@observer
class JinDuCarInformation extends React.Component {
  state = {
    showCenter: false,
    showApprover: false,
    showCase: false,
    pageNum: 1,
    pageSize: 20
  };

  globalStore = this.props.globalStore;
  confirmStore = this.props.confirmStore;

  t = this.props.t;

  get isReadonly() {
    if (this.globalStore.userInfo.costCenterCode) {
      return true;
    }
    return false;
  }

  get isConstCenter() {
    if (this.confirmStore.jinDuInfo.constCenterList.length > 0) {
      return true;
    }
    return false;
  }

  get isApprover() {
    if (this.confirmStore.jinDuInfo.approverList.length > 0) {
      return true
    }
    return false
  }

  render() {
    return (
      <div>
        <div className={style.jin_box}>
          <List mode="card" style={{ '--prefix-width': '6em' }}>
            <List.Item
              className={style.addressBook}
              prefix={this.t('confirm.k061')}
              arrow={false}
            >
              <div
                className={
                  !this.isReadonly
                    ? style.jin_center
                    : style.jin_center_readonly
                }
                onClick={() => {
                  if (this.isReadonly) {
                    return;
                  }
                  this.setState({
                    showCenter: true,
                  });
                }}
              >
                {this.confirmStore.jinDuInfo.costCenterName ? (
                  <span>{this.confirmStore.jinDuInfo.costCenterName}</span>
                ) : (
                  <span className={style.center}>
                    {!this.isConstCenter
                      ? '暂无成本中心'
                      : this.t('confirm.k062')}
                  </span>
                )}
                {!this.isReadonly ? (
                  <RightOutline color="#B5B5B5" fontSize={13} />
                ) : (
                  <></>
                )}
              </div>
            </List.Item>

            {this.confirmStore.jinDuInfo.showCaseItem ? (
              <List.Item
                className={style.addressBook}
                prefix={this.t('confirm.k065')}
                arrow={false}
              >
                <div
                  className={style.jin_center}
                  onClick={() => {
                    this.setState({
                      showCase: true,
                    });
                  }}
                >
                  {this.confirmStore.jinDuInfo.caseName ? (
                    <span>{this.confirmStore.jinDuInfo.caseName}</span>
                  ) : (
                    <span className={style.center}>
                      {this.t('confirm.k066')}
                    </span>
                  )}
                  <RightOutline color="#B5B5B5" fontSize={13} />
                </div>
              </List.Item>
            ) : (
              <></>
            )}
            
            <List.Item
              className={style.addressBook}
              prefix={this.t('confirm.k063')}
              arrow={false}
            >
              <div
                className={style.jin_center}
                onClick={() => {
                  this.setState({
                    showApprover: true,
                  });
                }}
              >
                {this.confirmStore.jinDuInfo.approverName ? (
                  <span>{this.confirmStore.jinDuInfo.approverName}</span>
                ) : (
                  <span className={style.center}>
                    {this.t('confirm.k064')}
                  </span>
                )}
                <RightOutline color="#B5B5B5" fontSize={13} />
              </div>
            </List.Item>
            
          </List>
        </div>
        <Popup
          position="bottom"
          bodyStyle={{ height: '60%', overflowY: 'scroll' }}
          bodyClassName={style.popupBox}
          visible={this.state.showCenter}
          onMaskClick={() => {
            this.setState({
              showCenter: false,
            });
          }}
        >
          <div className={style.searchBox}>
            <SearchBar
              placeholder={this.t('confirm.k067')}
              onChange={(val) => {
                this.debouncePrevent(val);
              }}
            />
          </div>
          <CheckList
            value={
              this.confirmStore.jinDuInfo.costCenterCode
                ? [this.confirmStore.jinDuInfo.costCenterCode]
                : []
            }
            onChange={(val, e) => {
              let data = this.confirmStore.jinDuInfo.constCenterList.filter(
                (item) => val[0] === item.code
              );
              if (data.length > 0) {
                this.confirmStore.setCostCenter(
                  data[0]?.name ?? '',
                  data[0]?.code ?? '',
                  data[0]?.companyCode ?? ''
                );
                this.confirmStore.setCostCenterList(this.confirmStore.jinDuInfo.constCenterList)
                if (data[0]?.state === 2) {
                  this.getJinDuProject();
                  this.confirmStore.setShowCaseItem(true);
                } else {
                  this.confirmStore.setCase('', '');
                  this.confirmStore.setCaseList([]);
                  this.confirmStore.setShowCaseItem(false);
                }
                this.getJinDuApprover();
              }
              this.setState({
                showCenter: false,
              });
            }}
          >
            {this.confirmStore.jinDuInfo.constCenterList?.map((item, idx) => {
              return (
                <CheckList.Item key={idx} value={item.code}>
                  {item.name}
                </CheckList.Item>
              );
            })}
          </CheckList>
        </Popup>

        <Popup
          position="bottom"
          bodyStyle={{ height: '60%', overflowY: 'scroll' }}
          bodyClassName={style.popupBox}
          visible={this.state.showApprover}
          onMaskClick={() => {
            this.setState({
              showApprover: false,
            });
          }}
        >
          <div className={style.searchBox}>
            <SearchBar
              placeholder={this.t('confirm.k067')}
              onChange={(val) => {
                this.getJinDuApprover(val, false);
              }}
            />
          </div>
          <CheckList
            value={
              this.confirmStore.jinDuInfo.staffCode
                ? [this.confirmStore.jinDuInfo.staffCode]
                : []
            }
            onChange={(val) => {
              let data = this.confirmStore.jinDuInfo.approverList.filter(
                (item) => val[0] === item.staffCode
              );
              if (data.length > 0) {
                this.confirmStore.setApprover(
                  data[0]?.nameCn ?? '',
                  data[0]?.staffCode ?? '',
                  data[0]?.sourceStaffCode ?? ''
                );
                this.confirmStore.setApproverList(this.confirmStore.jinDuInfo.approverList)
              }
              this.setState({
                showApprover: false,
              });
            }}
          >
            {this.confirmStore.jinDuInfo.approverList?.map((item, idx) => {
              return (
                <CheckList.Item key={idx} value={item.staffCode}>
                  {item.staffCode + '-' + item.nameCn}
                </CheckList.Item>
              );
            })}
          </CheckList>
        </Popup>

        <Popup
          position="bottom"
          bodyStyle={{ height: '60%', overflowY: 'scroll' }}
          bodyClassName={style.popupBox}
          visible={this.state.showCase}
          onMaskClick={() => {
            this.setState({
              showCase: false,
            });
          }}
        >
          <div className={style.searchBox}>
            <SearchBar
              placeholder={this.t('confirm.k067')}
              onChange={(val) => {
                this.getJinDuProject(val);
              }}
            />
          </div>
          <CheckList
            value={
              this.confirmStore.jinDuInfo.caseCode
                ? [this.confirmStore.jinDuInfo.caseCode]
                : []
            }
            onChange={(val) => {
              let data = this.confirmStore.jinDuInfo.caseList.filter(
                (item) => val[0] === item.code
              );
              if (data.length > 0) {
                this.confirmStore.setCase(
                  data[0]?.nameAs ?? '',
                  data[0]?.code ?? ''
                );
                this.confirmStore.setCaseList(this.confirmStore.jinDuInfo.caseList);
              }
              this.setState({
                showCase: false,
              });
            }}
          >
            {this.confirmStore.jinDuInfo.caseList?.map((item, idx) => {
              return (
                <CheckList.Item key={idx} value={item.code}>
                  {item.code + '-' + item.name}
                </CheckList.Item>
              );
            })}
          </CheckList>
        </Popup>
      </div>
    );
  }

  async componentDidMount() {
    // if (!this.confirmStore.jinDuInfo.costCenterInfo.code) {
    //   await this.getLastSelect()
    // }

    if (!this.isConstCenter) {
      this.getJinDuCostCenter(
       '',
       this.globalStore.userInfo.costCenterCode
         ? this.globalStore.userInfo.costCenterCode
         : ''
     );
    }
  }

  getLastSelect = async () => {
    let params = {
      companyId: this.globalStore.companyInfo.id,
      userId: this.globalStore.userInfo.id,
    }
    let {data} = await webService.order.getLastSelect(params);
    if (data.code === 0) {
      if (Object.keys(data.data.costCenterInfo).length > 0) {
        this.confirmStore.setCostCenterInfo(data.data.costCenterInfo)
        this.confirmStore.setCostCenter(
          data.data.costCenterInfo?.name ?? '',
          data.data.costCenterInfo?.code ?? '',
          data.data.costCenterInfo?.companyCode ?? '',
        );
      }
      if (Object.keys(data.data.approverInfo).length > 0) {
        this.confirmStore.setApproverInfo(data.data.approverInfo)
        this.confirmStore.setApprover(
          data.data.approverInfo?.nameCn ?? '',
          data.data.approverInfo?.staffCode ?? '',
          data.data.approverInfo?.sourceStaffCode ?? ''
        );
      }
      if (Object.keys(data.data.caseInfo).length > 0) {
        this.confirmStore.setCaseInfo(data.data.caseInfo)
        this.confirmStore.setCase(
          data.data.caseInfo?.nameAs ?? '',
          data.data.caseInfo?.code ?? '',
        );
      }
    }
  }

  // 成本中心
  getJinDuCostCenter = async (code = '', preciseCode = '', isInput = true) => {
    let params = {
      code: code,
      companyId: this.globalStore.companyInfo.id,
      userId: this.globalStore.userInfo.id,
      preciseCode: preciseCode,
      pageNum: this.state.pageNum,
      pageSize: this.state.pageSize,
    };
    let { data } = await webService.order.getJinDuCostCenter(params);
    if (data.code === 0) {
      if (!this.confirmStore.jinDuInfo.costCenterInfo.code && isInput) {
        if (data.data.length === 1) {
          this.confirmStore.setCostCenter(
            data.data[0]?.name ?? '',
            data.data[0]?.code ?? '',
            data.data[0]?.companyCode ?? ''
          );
        } else {
          this.confirmStore.setCostCenter('', '', '');
        }
      }
      if (data.data[0]?.state === 2) {
        this.getJinDuProject();
        this.confirmStore.setShowCaseItem(true);
      }
      this.confirmStore.setCostCenterList(data.data)
      this.getJinDuApprover();
    }
  };

  // 审批人
  getJinDuApprover = async (staffCode = '', isInput = true) => {
    let params = {
      staffCode: staffCode,
      code: this.confirmStore.jinDuInfo.costCenterCode,
      companyCode: this.confirmStore.jinDuInfo.companyCode,
      companyId: this.globalStore.companyInfo.id,
      userId: this.globalStore.userInfo.id,
    };
    let { data } = await webService.order.getJinDuApprover(params);

    if (data.code === 0) {
      if (!this.confirmStore.jinDuInfo.approverInfo.staffCode && isInput) {
        if (data.data.length === 1) {
          this.confirmStore.setApprover(
            data.data[0]?.nameCn ?? '',
            data.data[0]?.staffCode ?? '',
            data.data[0]?.sourceStaffCode ?? ''
          );
        } else {
          let d = data.data.filter((item) => item.flag)
          if (d.length > 0) {
            this.confirmStore.setApprover(
              d[0]?.nameCn ?? '',
              d[0]?.staffCode ?? '',
              d[0]?.sourceStaffCode ?? ''
            );
          } else {
            this.confirmStore.setApprover('', '', '');
          }
        }
      }
      this.confirmStore.setApproverList(data.data)
    }
  };

  // 案件
  getJinDuProject = async (code = '') => {
    let params = {
      code: code,
      companyId: this.globalStore.companyInfo.id,
    };
    let { data } = await webService.order.getJinDuProject(params);

    if (data.code === 0) {
      if (!this.confirmStore.jinDuInfo.caseInfo.code) {
        this.confirmStore.setCase('77', '77');
      }
      this.confirmStore.setCaseList(data.data);
    }
  };

  debouncePrevent = debounce((val) => {
    this.getJinDuCostCenter(val, '', false)
  }, 500)
}

export default JinDuCarInformation;
