import React from 'react';
import {inject, observer } from 'mobx-react'
import style from './callcar.scss'
import images from './../../util/images'

import moment from 'moment'
import { CascadePicker } from 'antd-mobile'

@inject('mainStore')
@observer
class DepartureTime extends React.Component {

    state = {
        show: false,
        useCarTimes: []
    }

    mainStore = this.props.mainStore;

    t = this.props.t

    render() {
        return <div className={style.line} >
            <div className={style.icon}>
            <img src={images.flightTimeBtn} alt="" />
            </div>
            <div className={`${style.content} ${style.sence}`} >
                <div className={style.time} onClick={this.showUseCarTime}>
                    { this.mainStore.beginAddr.departureTime }
                    {
                        (() => {
                            if(!this.mainStore.beginAddr.departureTime) {
                                return <div className={style.where}>
                                {this.t('main.k021')}
                                </div>
                            } 
                        })()
                    }
                </div>
                
                <div className={style.more}><img src={images.approveSee} alt="" /></div>
            </div>

            <CascadePicker 
                title={this.t('main.k021')}
                options={this.state.useCarTimes}
                visible={this.state.show}
                cancelText={this.t('common.k001')} 
                confirmText={this.t('common.k002')}
                onClose={() => {
                    this.setState({
                        show: false
                    })
                }}
                onConfirm={val => {
                    this.mainStore.setDepartureTime(val[2]);
                    this.props.onChange();                      
                }}
                
            />

        </div>
    }

    /**
     * 弹出可选用车时间选择
     */
    showUseCarTime = () => {
        let list = [];
        for (let d = 0; d < 3; d++) {
            let today;
            if (this.mainStore.serverType !== 20) {
              today = moment().add(d, "d");
            } else {
              today = moment(moment().startOf('days')).add(d+1, 'days')
            }
            let month;
            if (this.props.language === 1) {
              month = today.month() + 1 + this.t('main.k056') + today.date() + this.t('main.k057')
            } else {
              month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][today.month()] + ' ' + today.date() + this.t('main.k057')
            }
            list.push({
            label: month,
            value: today.month() + 1 + this.t('main.k056') + today.date() + this.t('main.k057'),
            year: today.year() + this.t('main.k058'),
            children: [],
            });
            for (let h = 0; h < 24; h++) {
                list[d].children.push({
                    label: this.props.language === 1 ? ("0" + h).slice(-2) + this.t('main.k059') : ("0" + h).slice(-2) + ':00' + this.t('main.k059'),
                    value: list[d].year + list[d].value + ' ' + ("0" + h).slice(-2),
                    children: [],
                });

                for (let m = 0; m < 60; m += 10) {
                    list[d].children[h].children.push({
                        label: ("0" + m).slice(-2) + this.t('main.k060'),
                        value: list[d].children[h].value + ':' +("0" + m).slice(-2)
                    });
                }
            }
        }

        let now = moment()
        // 最近可以约车时间
        if (this.mainStore.serverType !== 20) {
          now = moment()
        } else {
          let minute = moment(now).format('mm')
          if (minute === '00') {
          minute = '01'
          }
          now = moment(moment(now).startOf('hour')).add(24, 'hour').add(minute, 'minute')
        }
        
        //now.hour(23).minute(41);
        let beginTime;
        if (this.mainStore.serverType !== 20) {
          beginTime = now.add(40, "m");
        } else {
          beginTime = now.add(0, "m");
        }
        let td = beginTime.date();
        let th = beginTime.hour();
        let tm = beginTime.minute();
        if (td !== moment().date() && this.mainStore.serverType !== 20) {
            // 可以约车日期不在当天,移除备选第一天
            list.splice(0, 1);
        }
        if (th > 0) {
            // 可以约车小时大于0,移除之前的小时
            list[0].children.splice(0, th + (tm > 50 ? 1 : 0));
        }
        if (tm <= 50) {
            // 移除不可选择的分钟
            list[0].children[0].children.splice(0, (tm + 9) / 10);
        }

        console.log(list)
        this.setState({
            useCarTimes: list,
            show: true
        })
    }
}

export default DepartureTime;