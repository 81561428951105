import React from 'react'
import style from './invoiceDetails.scss'

import { List, Button, Toast, Input } from 'antd-mobile'
import { RightOutline } from 'antd-mobile-icons'

import webService from '../util/webService'

import { invoiceStateToCn } from './../util/paramsHelper'

import { customerServiceTelephone } from '../util/config'

import { withTranslation } from "react-i18next";

class InvoiceDetails extends React.Component {
  
  state = {
    // 入参
    invoiceInfo: {
      id: "",
      invoiceType: "",
      name: "",
      faxNumber: "",
      invoiceAmount: "",
      createTime: "",
      email: "",
      bankAccount: "",
      addAndTel: "",
      qty: 0
    },
  }

  t = this.props.t

  /**
   *
   *发票详情
   */
  renderInvoiceDetails = () => {
    return(
      <div className={style.list}>
        <List>
          <List.Item className={style.message}>
            <div>{this.t('invoiceDetails.k001')}</div>
          </List.Item>
          <List.Item className={style.cell}>
            <div>{this.t('invoiceDetails.k002')}</div>
            <div>{invoiceStateToCn(this.state.invoiceInfo.invoiceType, this.t)}</div>
          </List.Item>
          <List.Item className={style.cell}>
            <div>{this.t('invoiceDetails.k003')}</div>
            <div>{this.state.invoiceInfo.name}</div>
          </List.Item>
          <List.Item className={style.cell}>
            <div>{this.t('invoiceDetails.k004')}</div>
            <div>{this.state.invoiceInfo.faxNumber}</div>
          </List.Item>
          <List.Item className={style.cell}>
            <div>{this.t('invoiceDetails.k018')}</div>
            <div>{this.state.invoiceInfo.bankAccount}</div>
          </List.Item>
          <List.Item className={style.cell}>
            <div>{this.t('invoiceDetails.k019')}</div>
            <div>{this.state.invoiceInfo.addAndTel}</div>
          </List.Item>
          <List.Item className={style.cell}>
            <div>{this.t('invoiceDetails.k005')}</div>
            <div>{this.state.invoiceInfo.invoiceAmount}{this.t('invoiceDetails.k020')}</div>
          </List.Item>
          <List.Item className={style.cell}>
            <div>{this.t('invoiceDetails.k006')}</div>
            <div>{this.state.invoiceInfo.createTime}</div>
          </List.Item>
        </List>
      </div>
    )
  }

  /**
   *
   *接收方式
   */
  renderReceiveWay = () => {
    return(
      <div className={style.list}>
        <List>
          <List.Item className={style.message}>
            <div>{this.t('invoiceDetails.k007')}</div>
          </List.Item>
          <List.Item className={style.cell}>
            <div><span className={style.mandatory}>*</span>{this.t('invoiceDetails.k008')}</div>
            <Input
              value={this.state.invoiceInfo.email}
              onChange={(val) =>  {
                this.setState({
                  invoiceInfo: {
                    ...this.state.invoiceInfo,
                    email: val
                  }
                })
              }}
              placeholder={this.t('invoiceDetails.k009')}
              style={{ '--text-align': 'left' }}
              clearable
            />
          </List.Item>
        </List>
      </div>
    )
  }

  /**
   *
   *相关行程
   */
  renderTrip = () => {
    return(
      <div className={style.list}>
        <List>
          <List.Item className={style.message}>
            <div>{this.t('invoiceDetails.k010')}</div>
          </List.Item>
          <List.Item className={style.cellTrip}>
            <div>{this.t('invoiceDetails.k011', {count: this.state.invoiceInfo.qty})}</div>
            <div onClick={() => {
              this.props.history.push(`/trip/${this.state.invoiceInfo.id}`)
            }}>{this.t('invoiceDetails.k012')}<RightOutline color="#c9c9ce"></RightOutline></div>
          </List.Item>
        </List>
      </div>
    )
  }

  /**
   *
   *提示&按钮
   */
  renderConsultPart  = () => {
    return(
      <div className={style.consult}>
        <p>{this.t('invoiceDetails.k013', {customerServiceNumber: customerServiceTelephone})}</p>
        <Button onClick={() => {
          this.againSend()
        }} block color='primary' size='large'>
          {this.t('invoiceDetails.k014')}
        </Button>
      </div>
    )
  }

  render() {
    return(
      <div className={style.invoiceDetails}>
        {/* 发票详情 */}
        {this.renderInvoiceDetails()}
        {/* 接收方式 */}
        {this.renderReceiveWay()}
        {/* 相关行程 */}
        {this.renderTrip()}
        {/* 提示&按钮 */}
        {this.renderConsultPart()}
      </div>
    )
  }

  componentDidMount() {
    this.loadInvoiceDetails()
  }

  /**
   *
   *详情接口
   */
  loadInvoiceDetails = () => {
    const { id } = this.props.match.params
    let params = {
      invoiceId: String(id)
    }
    webService.invoice.getInvoiceDetails(params).then(d => {
      if (d.data.code === 0 && d.data.data) {
        this.setState({
          invoiceInfo: {
            ...this.state.invoiceInfo,
            invoiceType: d.data.data.invoiceType,
            name: d.data.data.name,
            faxNumber: d.data.data.faxNumber,
            invoiceAmount: d.data.data.invoiceAmount,
            createTime: d.data.data.createTime,
            id: d.data.data.id,
            email: d.data.data.email,
            qty: d.data.data.qty,
            bankAccount: d.data.data.bankAccount,
            addAndTel: d.data.data.addAndTel
          },
        })
      }
    })
  }

  /**
   *
   *重新发送开票
   */
  againSend = () => {
    const { id } = this.props.match.params

    let params = {
      invoiceId: String(this.state.invoiceInfo.id) || String(id),
      email: this.state.invoiceInfo.email
    }

    // eslint-disable-next-line
    let RegEmail = /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/ //邮箱格式

    if (!this.state.invoiceInfo.email) {
      Toast.show({
        content: this.t('invoiceDetails.k009'),
        duration: 1000
      })
    } else if (!RegEmail.test(this.state.invoiceInfo.email)) {
      Toast.show({
        content: this.t('invoiceDetails.k015'),
        duration: 1000
      })
    } else {
      Toast.show({
        icon: "loading",
        content: this.t('invoiceDetails.k016'),
        maskClickable: false,
        duration: 0
      })
  
      webService.invoice.resendInvoice(params).then(d => {
        if (d.data.code === 0) {
          Toast.show({
            icon: 'success',
            content: this.t('invoiceDetails.k017'),
            duration: 1000
          }, () => {
            Toast.clear()
          })
        } else {
          Toast.clear()
        }
      }).catch(err => {
        Toast.clear()
      }) 
    }
  }

}

export default withTranslation()(InvoiceDetails);