// 是否石油商旅APP
const isClient = () => {
  const u = navigator.userAgent;
  return (
    /Optype:Android/.test(u) || /Optype:iOS/.test(u) || /SmartcomTrip/.test(u)
  );
};

// 是否安卓设备
const vmIsAndroid = () => {
  const u = navigator.userAgent;
  return /Android/.test(u) || /Adr/.test(u);
};

// 是否iOS设备
const vmIsIOS = () => /\(i[^;]+;( U;)? CPU.+Mac OS X/.test(navigator.userAgent);

// 判断是否是移动端设备
const isMobile = () => {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  // match的返回值：如果匹配不到，返回null; 否则返回匹配到的 array
  return flag;
}

const setupWebViewJavascriptBridge = callback => {
  if (window.WebViewJavascriptBridge) {
    return callback(window.WebViewJavascriptBridge);
  }
  if (window.WVJBCallbacks) {
    return window.WVJBCallbacks.push(callback);
  }
  window.WVJBCallbacks = [callback];
  const WVJBIframe = document.createElement('iframe');
  WVJBIframe.style.display = 'none';
  WVJBIframe.src = 'https://__bridge_loaded__';
  document.documentElement.appendChild(WVJBIframe);
  setTimeout(() => {
    document.documentElement.removeChild(WVJBIframe);
  }, 0);
  return '';
};

/**
 * 执行bridge方法
 * @param method  原生方法名
 * @param param   原生方法入参
 * @param callback  原生回调的js方法
 * @param callbackName  原生回调函数名称，Android需要
 */
const execBridge = (method, param, callback, callbackName) => {
  if (method === 'bridgeGetCurrentLocation' && vmIsAndroid() && param === null) {
    console.log('52')
    param = 'abc';
  }
  if (!isClient()) {
    return;
  }
  console.log('57')
  if (vmIsAndroid()) {
    console.log('60')
    if (!window.nativeCB[method]) {
      return;
    }
    console.log('64')
    if (callbackName) {
      window[callbackName] = callback;
      console.log('callbackName 67', callbackName)
    }
    if (param) {
      console.log('70')
      if (typeof param === 'object') {
        window.nativeCB[method](JSON.stringify(param));
        console.log('73')
      } else {
        console.log('method 75', method)
        window.nativeCB[method](param);
        console.log('77')
      }
    } else {
      window.nativeCB[method]();
      console.log('80')
    }
  } else if (vmIsIOS()) {
    console.log('77')
    setupWebViewJavascriptBridge(bridge => {
      bridge.callHandler(method, param, response => {
        if (typeof response === 'string') {
          callback && callback(response);
        } else {
          callback && callback(JSON.stringify(response));
        }
      });
    });
  } else {
    console.log('88')
  }
};

// 设备类型
const isDevice = () => {
  if (vmIsAndroid()) {
    return 'Android'
  } else if (vmIsIOS()) {
    return 'IOS'
  } else {
    return 'PC'
  }
}

export {
  isClient,
  vmIsAndroid,
  vmIsIOS,
  execBridge,
  isMobile,
  isDevice
}