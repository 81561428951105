import React from 'react';
import {inject, observer } from 'mobx-react'
import style from './callcar.scss'
import images from './../../util/images'

import { Popup } from 'antd-mobile'
import Address from './address'

import { ForgeRouter } from './../../util/utils'
// import recommendPoint from './../../util/recommendPoint'

@inject('mainStore', 'globalStore')
@observer
class BeginAddr extends React.Component {

    state = {
        show: false        
    }
    mainStore = this.props.mainStore;
    globalStore = this.props.globalStore;
    needCallBack = false;
    t = this.props.t

    fr = undefined

    get beginAddrName() {
        try {
            if (this.mainStore.beginAddr.name) {
                let arr = this.mainStore.beginAddr.name.split('　')
                if (arr && arr.length) {
                    return arr[arr.length - 1]
                }
            }
        } catch (exp) {
            console.error(exp)
        }
        return this.mainStore.beginAddr.name
    }

    render() {
        return <div className={style.line}>            
            <div className={style.icon}>
            <img src={images.begin} alt="" />
            </div>
            <div className={style.content} onClick={() => {
                if(!this.mainStore.beginAddr.name) {
                  return
                }
                
                this.setState({
                    show: true
                }, () => {
                    this.fr = new ForgeRouter((isManual) => {
                        let {hash} = document.location;
                        if (isManual || hash === '#/') {
                            this.setState({
                                show: false
                            }, () => {
                                if (this.needCallBack) {
                                    this.props.onChange();
                                }
                            })
                            
                        } 
                    });
                    this.fr.onpopstate();
                })
            }} >
                {
                    (() => {
                        if (!this.mainStore.beginAddr.name) {
                            return <div className={style.where}>{this.t('main.k083')}</div>
                        }
                    })()
                }   
                { this.beginAddrName }
                {
                    // (() => {
                    //     if (this.mainStore.beginAddr) {
                    //         let c = recommendPoint.getRecommend(this.mainStore.beginAddr.lon, this.mainStore.beginAddr.lat)
                    //         if (c && c.score === 2) {
                    //             return <div className={style.multipleUses}>常用地址</div>
                    //         }
                    //     }
                    // })()
                    
                }
                
            </div>

            <Popup
                position='bottom'
                visible={this.state.show}
                bodyStyle={{ height: '100%' }}
                >
                {/* 地址选择框 */}
                {this.state.show?<Address onChange={this.choosedAddr}
                    moveMap={this.props.moveMap}
                    isCommonlyAddress={true}
                    onCancel={() => {
                        this.props.history.goBack();
                        
                        if (window !== window.top) {
                            this.fr.manualOperation()
                        }
                    }}
                    t={this.props.t}
                    isBegin={true} cityCode={this.mainStore.beginAddr.cityCode} cityName={this.mainStore.beginAddr.cityName}
                    history={this.props.history}
                ></Address>:''}
            </Popup>
        </div>
    }

    componentDidMount() {
        this.needCallBack = false;
    }

    /**
     * 点选地址
     * @param {*} item 
     */
    choosedAddr = (item) => {
        this.needCallBack = true;
        this.props.history.goBack();
        this.props.moveMap([item.lon, item.lat], false, false, item);
        this.mainStore.choosedBeginAddr(item);

        if (window !== window.top) {
            this.fr.manualOperation()
        }
    }
}

export default BeginAddr;