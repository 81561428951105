import React from "react";
import { inject, observer } from 'mobx-react'
import { Dialog, Popup, Toast } from 'antd-mobile'

import style from './habitAddress.scss'

import webService from './../util/webService'

import CommonlyAddress from './../component/callcar/commonlyAddress'

import { RightOutline } from 'antd-mobile-icons'

import { withTranslation } from "react-i18next";

import gc from './../util/globalConfiguration'
@inject('mainStore', 'globalStore')
@observer
class HabitAddress extends React.Component {

  state = {
    address1: {
      address: '',
      cityCode: '',
      cityName: '',
      addressId: '',
      lat: '',
      lon: '',
      name: '',
      poi: ''
    },
    address2: {
      address: '',
      cityCode: '',
      cityName: '',
      addressId: '',
      lat: '',
      lon: '',
      name: '',
      poi: ''
    },
    show: false,
    addressType: '',
    cityCode: '',
    cityName: ''
  }

  mainStore = this.props.mainStore;
  globalStore = this.props.globalStore;

  t = this.props.t

  render() {
    return (
      <div className={style.Container}>
        <div className={style.box}>
          <div>
            <span>{this.t('habitAddress.k001')}</span>
            {
              gc.process.isShowClearHabitAddressBut
                ? <div onClick={() => {
                  if (!this.state.address1.address) {
                    return
                  }
                  Dialog.confirm({
                    cancelText: this.t('habitAddress.k004'),
                    confirmText: this.t('habitAddress.k005'),
                    content: this.t('habitAddress.k006'),
                    onConfirm: () => {
                      this.deleteUserCommonAddress(this.state.address1.addressId)
                      setTimeout(() => {
                        this.getUserCommonAddress()
                      }, 1000)
                    }
                  })
                }} className={`${this.state.address1.address ? '' : style.disable}`}>{this.t('habitAddress.k003')}</div>
                : <></>
            }

          </div>
          <div>
            {
              this.state.address1.address
                ? <span onClick={() => {
                  this.showAddressSetting('1', true)
                }}>{this.state.address1.address}</span>
                : <span className={style.choose} onClick={() => {
                  this.showAddressSetting('1', true)
                }}>{this.t('habitAddress.k008')}</span>}
            <RightOutline onClick={() => {
              this.showAddressSetting('1', true)
            }} />
          </div>
        </div>
        <div className={style.box}>
          <div>
            <span>{this.t('habitAddress.k002')}</span>
            {
              gc.process.isShowClearHabitAddressBut
                ? <div onClick={() => {
                  if (!this.state.address2.address) {
                    return
                  }
                  Dialog.confirm({
                    cancelText: this.t('habitAddress.k004'),
                    confirmText: this.t('habitAddress.k005'),
                    content: this.t('habitAddress.k007'),
                    onConfirm: () => {
                      this.deleteUserCommonAddress(this.state.address2.addressId)
                      setTimeout(() => {
                        this.getUserCommonAddress()
                      }, 1000)
                    }
                  })
                }} className={`${this.state.address2.address ? '' : style.disable}`}>{this.t('habitAddress.k003')}</div>
                : <></>
            }

          </div>
          <div>
            {this.state.address2.address
              ? <span onClick={() => {
                this.showAddressSetting('2', true)
                
              }}>{this.state.address2.address}</span>
              : <span className={style.choose} onClick={() => {
                this.showAddressSetting('2', true)
              }}>{this.t('habitAddress.k008')}</span>}
            <RightOutline onClick={() => {
              this.showAddressSetting('2', true)
            }} />
          </div>

        </div>
        {
          this.globalStore.userInfo.commonAddressValidation
            ? <div className={style.tip}>*注:每个常用地址每月仅能修改一次</div>
            : <></>
        }
        
        <Popup
          position='bottom'
          visible={this.state.show}
          bodyStyle={{ height: '100%' }}
        >
          {/* 地址选择框 */}
          {this.state.show ? <CommonlyAddress onChange={this.choosedAddr}
            onCancel={() => {
              this.setState({
                show: false,
                cityCode: '',
                cityName: ''
              })
            }}
            isShowCitys={true}
            t={this.t}
            isBegin={true} cityCode={this.state.cityCode} cityName={this.state.cityName}
            history={this.props.history}
          ></CommonlyAddress> : ''}
        </Popup>
      </div>
    )
  }

  async componentDidMount() {
    try {
      // 锁频
      Toast.show({
        icon: "loading",
        content: this.t('invoiceDetails.k016'),
        maskClickable: false,
        duration: 0
      })
      // 等待拉去用户信息，设置到gload
      await this.getUserInfo()
      this.getUserCommonAddress()
    } catch(e) {
      console.log(e)
    } finally {
      Toast.clear()
    }
    // 解锁
  }

  showAddressSetting = async (addressType, validation) => {
    console.log(validation, this.globalStore.userInfo.commonAddressValidation)
    if (validation && this.globalStore.userInfo.commonAddressValidation) {
      
      // 如果存在commonAddressValidation限制参数，则分析参数决定是否允许修改
      let commonAddressValidation = this.globalStore.userInfo.commonAddressValidation
      let addressSetting = addressType === '1' ? commonAddressValidation.address_1 : commonAddressValidation.address_2

      if (addressSetting && addressSetting.modifiable === false) {

        Dialog.alert({
          content: addressSetting.tip
        })
        return
      }
      let currentAddress = addressType === '1' ? this.state.address1.address : this.state.address2.address
      console.log(currentAddress)
      if (currentAddress) {
        // 空地址设置需要给出提示，非空设置不给提示
        await Dialog.alert({
          content: '每个常用地址每月仅能修改一次',
        })
      }
      
    }

    this.setState({
      show: true,
      addressType: addressType
    })
    if (addressType === '1') {
      this.setState({
        cityCode: this.state.address1.cityCode ? this.state.address1.cityCode : this.mainStore.beginAddr.cityCode,
        cityName: this.state.address1.cityName ? this.state.address1.cityName : this.mainStore.beginAddr.cityName
      })
    } else if (addressType === '2') {
      this.setState({
        cityCode: this.state.address2.cityCode ? this.state.address2.cityCode : this.mainStore.beginAddr.cityCode,
        cityName: this.state.address2.cityName ? this.state.address2.cityName : this.mainStore.beginAddr.cityName
      })
    }

  }

  /**
   * 获取常用地址
   * @param {*} citys 
   * @returns 
   */
  getUserCommonAddress = () => {
    webService.habitAddress.getUserCommonAddress().then(res => {
      if (res.data.data && res.data.data.address1) {
        this.setState({
          address1: res.data.data.address1
        })
      } else {
        this.setState({
          address1: {}
        })
      }
      if (res.data.data && res.data.data.address2) {
        this.setState({
          address2: res.data.data.address2
        })
      } else {
        this.setState({
          address2: {}
        })
      }
    })
  }

  /**
   * 设置常用地址
   * @param {*} citys 
   * @returns 
   */
  setUserCommonAddress = async (params) => {
    let {data} = await webService.habitAddress.setUserCommonAddress(params)
    if (data.code !== 0) {
      Toast.show({
        content: data.message || this.t('main.k0124'),
        duration: 1000
      })
    }

  }

  /**
   * 清空常用地址
   * @param {*} citys 
   * @returns 
   */
  deleteUserCommonAddress = (id) => {
    webService.habitAddress.deleteUserCommonAddress({ addressId: id }).then(res => {
      if (res.data.code !== 0) {
        Toast.show({
          content: this.t('main.k0125'),
          duration: 1000
        })
      }
    })
  }

  /**
   *
   *用户信息接口
   */
   getUserInfo = async () => {
    let d = await webService.basCity.getUserInfo();
    let {data} = d;
    if (data.code === 0) {
      this.globalStore.setUserInfo(data.data);
    }
  }


  choosedAddr = async (item) => {
    item.address = item.name
    item.addressType = this.state.addressType
    this.setState({
      show: false,
      cityCode: '',
      cityName: ''
    })
    try {
      // 锁屏
      Toast.show({
        icon: "loading",
        content: this.t('invoiceDetails.k016'),
        maskClickable: false,
        duration: 0
      })
      await this.setUserCommonAddress(item)
      await this.getUserInfo()
      // 等待拉用户信息并设置到gload
      this.getUserCommonAddress()
      // 解锁
    } catch(e) {
      console.log(e)
    } finally {
      Toast.clear()
    }
  }

}

export default withTranslation()(HabitAddress)