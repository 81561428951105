import React from 'react';
import { inject, observer } from 'mobx-react'
import { Popup, DatePicker, Divider, Toast } from 'antd-mobile'

import dayjs from 'dayjs'
import style from './callcar.scss'
import traincss from './Train.scss'
import images from './../../util/images'
import { ForgeRouter } from './../../util/utils'
import webService from '../../util/webService';
import moment from 'moment';

import loger from './../../util/loger'


@inject('mainStore')
@observer
class Train extends React.Component {

    mainStore = this.props.mainStore;
    state = {
        showTrainNumber: false,
        showTrains: false,
        key: '',
        trains: undefined,
        date: '',
        visible: false,
    }
    t = this.props.t

    fr = undefined

    render() {
        return <div className={style.line} >
            <div className={style.icon}>
                <img src={images.trainBtn} alt="" />
            </div>
            <div className={`${style.content} ${style.sence}`} onClick={() => {
                if (!this.mainStore.beginAddr.name) {
                    return
                }
                this.setState({
                    showTrainNumber: true
                }, () => {
                    this.fr = new ForgeRouter((isManual) => {
                        let { hash } = document.location;
                        if (isManual || hash === '#/') {
                            this.setState({
                                showTrainNumber: false
                            }, () => {
                            })

                        }
                    });
                    this.fr.onpopstate();
                })
            }} >
                <div className={style.time} onClick={() => { }}>
                    {this.mainStore.beginAddr.trainNumber}
                    {
                        (() => {
                            if (this.mainStore.beginAddr.trainTip) {
                                return <div className={style.tip} >{this.mainStore.beginAddr.trainTip}</div>
                            }
                        })()
                    }
                    {
                        (() => {
                            if (!this.mainStore.beginAddr.trainNumber) {
                                return <div className={style.where} >{this.t('main.k0126')}</div>
                            }
                        })()
                    }

                </div>

                <div className={style.more}><img src={images.approveSee} alt="" /></div>
            </div>
            <Popup
                visible={this.state.showTrainNumber}
                position='right'
                bodyStyle={{ width: '100vw' }}
            >
                <div className={traincss.train}>
                    <div>
                        <div className={traincss.trainInfo}>
                            <div className={`${traincss.content} ${traincss.line}`}>
                                <div className={traincss.icon}><img src={images.train} alt="" /></div>
                                <div className={traincss.input}>
                                    <input placeholder={this.t('main.k0126')} value={this.state.key} onChange={(e) => {
                                        this.setState({
                                            key: e.target.value
                                        })
                                    }}></input>
                                </div>
                            </div>
                            <div className={traincss.content}>
                                <div className={traincss.icon}> <img src={images.time} alt="" /></div>
                                <div className={traincss.input}>
                                    <input placeholder={this.t('main.k0128')} value={this.state.date} readOnly onClick={() => {
                                        this.setState({ visible: true })
                                    }}></input>
                                </div>
                            </div>
                        </div>
                        <div className={traincss.btn}>
                            <div className={traincss.searchbtn} onClick={this.loadFlight}>{this.t('main.k0129')}</div>
                        </div>
                        <Divider style={{ color: '#333333', borderColor: '#333333', borderStyle: 'solid', }}>{this.t('main.k0130')}</Divider>
                    </div>
                    <div>
                        {
                            this.state.trains && this.state.trains.length
                            ? this.state.trains.map((item, index) => {
                                return <div className={traincss.trainitem} key={index}>
                                    <div>{item.trainArr}</div>
                                    <div>{item.arrTimeLabel}</div>
                                    <div>
                                        <div className={traincss.usebtn} onClick={() => this.chooseTrain(item)}>{this.t('main.k0131')}</div>
                                    </div>
                                    
                                </div>
                            })
                            : <></>
                        }
                    </div>
                </div>
                <DatePicker visible={this.state.visible} cancelText={this.t('common.k001')} confirmText={this.t('common.k002')} min={new Date()} onClose={() => { this.setState({ visible: false }) }}
                    onConfirm={(val) => { this.setState({ date: val ? dayjs(val).format('YYYY-MM-DD') : '', visible: false }) }}></DatePicker>
            </Popup>

        </div>
    }

    loadFlight = async () => {
        if (!this.state.date || !this.state.key) {
            Toast.show({
                icon: 'fail',
                // 请补充车次号或日期信息
                content: this.t('main.k0166')
            })
            return;
        }


        Toast.show({
            icon: 'loading',
            content: this.t('main.k076'),
            duration: 0,
            maskClickable: false
        })
        try {
            let { data } = await webService.basCity.getTrainTimetable({
                date: this.state.date,
                tnum: this.state.key
            })
            Toast.clear();
            if (data.code === 0 && data.data && data.data.rows && data.data.rows.length) {
                data.data.rows = data.data.rows.map(a => {
                    let gjc02 = a.arrivalStationCoord.split(',').map(a => Number(a))
                    return {
                        ...a,
                        arrTimeLabel: moment(a.planArrivalTime).format('HH:mm'),
                        // 请您在 日 左右到达上车地点
                        tip: `${this.t('main.k0167', {day : moment(a.planArrivalTime).format('D'), time: moment(a.planArrivalTime).format('HH:mm')})}`,
                        // tip: `请您在${moment(a.planArrivalTime).format('D')}日${moment(a.planArrivalTime).format('HH:mm')}左右到达上车地点`,
                        trainNum: a.trainNumber,
                        trainArr: a.trainArr,
                        trainDep: a.trainDep,
                        lng: gjc02[0],
                        lat: gjc02[1],
                        cityName: a.arrivalCityName,
                        cityCode: a.arrivalCityCode,
                        address: a.arrivalStationLocation,
                        name: a.arrivalStationName
                    }
                })
                this.setState({
                    trains: data.data.rows
                })
            } else {
                loger.debug('fail')
                Toast.show({
                    icon: 'fail',
                    // 无可用车次
                    content: data.message || this.t('main.k0169')
                })
            }
        } catch (exp) {
            Toast.clear();
            loger.debug(exp)
        }
        
    }

    chooseTrain = async (item) => {
        loger.debug(item)

        this.mainStore.chooseTrain(item);
        this.props.moveMap([item.lng, item.lat]);
        this.props.history.goBack();

        if (window !== window.top) {
            this.fr.manualOperation()
        }
    }


}
export default Train;