import React from 'react'
import style from './payed.scss';

import moment from 'moment'
import images from '../../util/images';

class Payed extends React.Component {

    state = {
        order: this.props.order
    }

    t = this.props.t

    render() {
        let {order} = this.state;
        return <div className={style.payed}>
            <div className={style.title}>
                <div>{this.t('payments.k024')}<br />{order.transactionNo}</div>
                <div>{moment(order.payTime).format('YYYY-MM-DD HH:mm')}</div>
            </div>
            <div className={style.body}>
                {
                    order.orders && order.orders.length
                      ? order.orders.map(item => {
                        return (<div key={item.id}>
                            <div>
                                <div>{this.t('payments.k020')}{moment(item.createTime).format('YYYY-MM-DD HH:mm')}</div>
                                <div>{this.t('payments.k021')}{item.pickupLocationName}</div>
                                <div>{this.t('payments.k022')}{item.destLocationName}</div>
                            </div>
                            <div>{item.userBearAmount}{this.t('payments.k009')}</div>
                        </div>)
                      })
                    :<></>
                }
            </div>
            <div className={style.foot}>
                {
                  order.payType === 2 ? 
                  <div>
                    <span>{this.t('payments.k026')}</span>&nbsp; {
                      order.refundMoney > 0 ? <span className={style.moneyReturn}>{this.t('payments.k027', {money: order.refundMoney})}</span> : ''
                    } 
                  </div> : <div>{this.t('payments.k025', {count:order.count})}</div>
                }
                <div>
                  {
                    (() => {
                      if(order.payway === 1) {
                        return <img className={style.payWxStyle} src='https://ipath-pay-1257314668.cos.ap-shanghai.myqcloud.com/wx_pay.png' alt=""/>
                      } else if (order.payway === 2) {
                        return <img className={style.payStyle} src='https://ipath-pay-1257314668.cos.ap-shanghai.myqcloud.com/ali_pay.png' alt=""/>
                      } else if (order.payway === 3) {
                        return <img className={style.payWxStyle} src={images.jd} alt=""/>
                      }
                    })()
                  }
                  &nbsp;&nbsp;¥{order.totalAmount}
                </div>
            </div>
        </div>
    }

    componentWillReceiveProps(props) {
        this.setState({
            order: props.order
        })
    }
}

export default Payed