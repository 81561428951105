import React from 'react';
import style from './userAddreessBook.scss'
import { inject, observer } from 'mobx-react'
import { IndexBar, List } from 'antd-mobile'
import { withTranslation } from "react-i18next";

@inject('mainStore', 'globalStore')
@observer
class UserAddreessBook extends React.Component {

  state = {
    uses: this.props.uses,
    phone: ''
  }
  mainStore = this.props.mainStore;
  globalStore = this.props.globalStore;
  needCallBack = false;
  t = this.props.t


  render() {
    return <div className={style.addreessBook}>
      <IndexBar>
        {
          this.generateUserTree(this.props.uses).map((item) => {
            return <IndexBar.Panel
              index={item.key}
              title={item.key}
              key={item.key}
            >
              <List>

                {
                  item.children.map((c) => {
                    return <List.Item key={c.id} clickable={true} arrow={false} onClick={() => {
                      this.props.onChange(c.phone)
                    }}>

                      <div className={style.name}>{c.name}</div>
                      <div className={style.phone}>{this.t('main.k0168')} {c.phone}</div>
                    </List.Item>
                  })
                }
              </List>

            </IndexBar.Panel>
          })
        }

      </IndexBar>
    </div>
  }

  componentDidMount = () => {
    this.needCallBack = false;

  }

  componentWillReceiveProps(nextProps) {


  }

  componentWillUnmount = () => {
    this.setState = (state, callback) => {
      return;
    };
  }


  /**
   * 点选地址
   * @param {*} item 
   */
  choosedAddr = (item) => {
    item.address = item.name
    // 走接口设置常用地址1、2
    item.addressType = this.state.addressType
    item.empty = false
    if (this.state.addressType === 1) {
      this.setState({
        ...this.state.address1,
        address1: item
      })
      this.setUserCommonAddress(item)
    } else {
      this.setState({
        ...this.state.address2,
        address2: item
      })
      this.setUserCommonAddress(item)
    }
    this.setState({
      show: false
    })
  }
  /**
     * 生成通讯录列表
     */
  generateUserTree = (users) => {
    const userList = []
    users?.forEach((userItem, index) => {
      userItem.id = index
      let user = userList.find(item => item.key === userItem.userGroupName);
      if (!user) {
        user = {
          key: userItem.userGroupName,
          children: []
        }
        userList.push(user)
      }
      user.children.push(userItem)
    })
    return userList.sort((a, b) => {return a.key < b.key ? -1 : 0})
  }

}

export default withTranslation()(UserAddreessBook);