import React from 'react'
import style from './scenes.scss';
import { inject, observer } from 'mobx-react'
import { Dialog, Toast, SearchBar, Image, Popup } from 'antd-mobile'

import Scene from './../component/callcar/scene'
import webService from '../util/webService';
import { ForgeRouter } from './../util/utils'
import ScenesDetails from './../pages/scenesDetails'

import images from './../util/images'

import moment from 'moment'
import { withTranslation } from "react-i18next";
@inject('mainStore')
@observer
class Scenes extends React.Component {

  state = {
    scenes: [],
    isData: null,
    Keyword: '',
    recoveryMode: '',
    remainQuota: '',
    showDetails: false
  }

  mainStore = this.props.mainStore;
  getSceneListTimeStamp = 0;

  t = this.props.t;

  /**
   * 暂无数据
   */
  renderEmpty = () => {
    return <div className={style.nodata}>
      <div className={style.box}>
        <Image src={images.null_bg} width={200} height={150} fit='cover' />
        <span>{this.t('main.k0132')}</span>
      </div>
    </div>
  }

  render() {
    return <div className={style.container}>
      <div className={style.search}>
        <SearchBar placeholder={this.t('scenes.k034')} value={this.state.Keyword} style={{ '--background': '#ffffff', '--border-radius': '8px' }}
          onChange={v => {
            this.setState({
              Keyword: v
            }, () => {
              this.getSceneList()
            })
          }}></SearchBar>
        {/* <div className={style.searchbtn} onClick={()=>{
              this.getSceneList()
            }}>{this.t('scenes.k032')}</div> */}
      </div>
      {
        this.state.isData ?
          <div>
            {
              this.state.Keyword || (!this.state.remainQuota && this.state.remainQuota !== 0) 
                ? ''
                : <div className={style.totalamount}>
                  <span>{this.t('scenes.k033')}{this.state.recoveryMode  ? '(' + this.state.recoveryMode + ')' : ''}</span>
                  <span>{this.state.remainQuota ? this.state.remainQuota : this.state.remainQuota === 0 ? 0 : ''}{this.t('scenes.k003')}</span>
                </div>
            }

            {
              this.state.scenes.map(item => {
                return <Scene t={this.props.t} key={item.id} history={this.props.history} scene={item} showCheckBox={false} onClick={() => { }}
                  onScenesDetails={
                    () => {
                      this.setState({
                        showDetails: true,
                        sceneId: item.id
                      }, () => {
                        let fr = new ForgeRouter(() => {
                          let { hash } = document.location;
                          if (hash === '#/scenes') {
                            this.setState({
                              showDetails: false
                            }, () => {

                            })

                          }
                        });
                        fr.onpopstate();

                      });
                    }
                  }></Scene>
              })
            }
          </div> : ''
      }
      {!this.state.isData && this.state.isData !== null && this.renderEmpty()}
      <Popup
        visible={this.state.showDetails}
        position='right'
        bodyStyle={{ minWidth: '100vw' }}
      >
        {/* 场景详情 */}
        {
          this.state.showDetails
            ? <ScenesDetails t={this.props.t} history={this.props.history} sceneId={this.state.sceneId} />
            : <></>
        }

      </Popup>
    </div>
  }

  componentDidMount() {
    this.getSceneList()
    this.getPersonalQuota()
  }
  getSceneList() {
    // Toast.show({
    //   icon: 'loading',
    //   content: this.t('scenes.k026'),
    //   duration: 0,
    //   maskClickable: false
    // })
    let params = {
      serviceType: this.mainStore.serverType,
      departTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      Keyword: this.state.Keyword
    }
    let now = new Date().getTime();
    this.getSceneListTimeStamp = now;
    webService.scene.getSceneList(params).then(d => {
      if (now !== this.getSceneListTimeStamp) {
        return
      }
      if (d.data.code === 0) {
        if (d.data.data && d.data.data.length) {
          this.setState({
            scenes: d.data.data
          }, () => {
            if (this.state.scenes.length > 0) {
              this.setState({
                isData: true
              })
            } else {
              this.setState({
                isData: false
              })
            }
          });
        }
        else {
          // Dialog.alert({
          //     content: this.t('scenes.k001'),
          //     closeOnMaskClick: true,
          //     onClose: () => {
          //       this.props.history.go(-1);
          //     }
          // })
          this.setState({
            isData: false,
            scenes: []
          })
        }
      } else {
        Dialog.alert({
          content: d.data.message ? d.data.message : this.t('scenes.k001'),
          confirmText: this.t('common.k003')
        })
        this.setState({
          isData: false
        })
      }
      Toast.clear();
    });
  }
  async getPersonalQuota() {
    let { data } = await webService.scene.getPersonalQuota();
    if (data.data) {
      this.setState({
        recoveryMode: data.data.recoveryMode,
        remainQuota: data.data.remainQuota
      })
    }
  }
}

export default withTranslation()(Scenes);