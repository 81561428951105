
import { envName, versionNumber } from './variable'

// 版本对比方法
const compareVersions = (v1, v2) => {
  let v1parts = v1.split('.');
  let v2parts = v2.split('.');
  
  for (let i = 0; i < v1parts.length; i++) {
    if (v2parts.length === i) {
      return 1;
    }
    
    if (v1parts[i] === v2parts[i]) {
      continue;
    }
    else if (v1parts[i] > v2parts[i]) {
      return 1;
    }
    else {
      return -1;
    }
  }
  
  if (v1parts.length !== v2parts.length) {
    return -1;
  }
  
  return 0;
}

// 授权类型（枚举值在 /src/index.js）
let oauthType = '';
// 如是企业微信第三方应用则为suite_id，如是企业微信自建应用则为CorpId，如是微信服务号则是appId，以此类推
let appId = '';
// url中的token
let token = '';

// 从路当前页面路径中读取授权类型和AppId
(() => {
  let pathname = window.location.pathname;
  if (pathname && pathname.indexOf('/', 1) > 1) {
    let arr = pathname.split('/');
    if (arr.length > 2) {
      oauthType = arr[1];
      appId = arr[2];
    }
    if (arr.length > 3) {
      token = arr[3];
      if (token) {
        sessionStorage.setItem('satoken', token);
      }

    }
  }
})()

let globalConfiguration = {
  process: {
    // 首页是否提示先把未付款付掉
    get showPayFirst() {
      if (['15'].includes(oauthType)) {
        return true;
      } else {
        return false;
      }
    },

    // 跳过确认用车页面
    get skipConfirmationPage() {
      if (['15'].includes(oauthType) || ['powerchina', 'zhonghai', 'XCX032424'].includes(appId)) {
        return true
      }
      // if (oauthType === '15') {
      //     // 京东小程序不需要确认用车页面
      //     return true;
      // } else if (appId === 'powerchina') {
      //     // 中电建不需要确认用车页面
      //     return true;
      // } else if (appId === 'zhonghai') {
      //     return true;
      // }
      return false;
    },

    // 预估价页面显示用车人手机号
    get estimatePageShowPhoneInput() {

      return true;
    },

    // 显示回到宿主页面按钮
    get showBackHost() {
      // 20230812 ziru不在需要显示返回按钮
      // if (oauthType === '6' && (appId === 'ziru' || appId === 'ziruziru')) {
      //   if (envName === 'prod21') {
      //     return 'https://h5.zirutmc.com/#/Car/Index'
      //   } else {
      //     return 'http://m.pay.shanglv51.com/#/Car/Index'
      //   }

      // }

      return ''
    },

    // 梓如 menu 个人支付 名称显示为支付中心
    get payMenuName() {
      if (['ziru', 'ziruziru'].includes(appId)) {
        return true
      }
      return false
    },

    // 首页是否显示【去打车】按钮
    get showOrderButton() {
      if (oauthType === '8' && ['cpic', 'fanjia'].includes(appId)) {
        return true
      } else {
        return false
      }
    },

    // 判断是否是强生环境
    get isJnjOauthType() {
      if (['17', '20'].includes(oauthType)) {
        return true;
      }
      return false;
    },

    // 判断是否石油
    get isCNPC() {
      return oauthType === '3'
    },

    // 是否开启2.4.2版本
    get isOpen_2_4_2() {
      return compareVersions(versionNumber, '2.4.2') >= 0
    },

    // 判断是否泛嘉
    get isFanJia() {
      return oauthType === '8' && appId === 'fanjia'
    },

    // 是否显示清空常用地址按钮
    get isShowClearHabitAddressBut() {
      if (oauthType === '8' && appId === 'haige') {
        // 海格不显示清空常用地址按钮
        return false
      }

      return true
    },

    // TODO 去掉ab测试权限
    // 是否显示sanofi会议
    get isShowSanofiMeeting() {
      return oauthType === '19' ||  ['wx67babfc3bdec3239', 'sanofi'].includes(appId);
    },

    // // 是否是默克
    // get isMerk() {
    //   if (oauthType === '11' && ['wx574d96321ed727af'].includes(appId)) {
    //     return true
    //   }
    //   return false
    // },

    // 是否是金杜
    get isJindu() {
      if (oauthType === '11' && ['1000013@wx74a8461adc8df95c', '1000007@wx74a8461adc8df95c', 'wx74a8461adc8df95c', 'wx67babfc3bdec3239'].includes(appId)) {
        return true
      }
      return false
    },

    get isEmailFirst() {
      if (oauthType === '12' && ['wx194fac2cf4f0db91', 'wx5d2519230d553a81', 'WPP'].includes(appId)) {
        return true
      }
      return false
    },
    /**
     * 开启交通枢纽驾驶
     */
    get openHubOfTrafficDriving() {
      if (envName === 'prod21') {
        return ['181061980581000101'].includes(window.companyId)
      }
      // 所有环境中关闭交通枢纽虚线导航
      return true
    },
    /**
     * 触发强制导航罗辑所需连续偏航次数
     */
    get getCountYawByForce() {
      if (envName === 'prod21') {
        return 6
      }
      return 6
    },
    /**
     * 是否开启高德绑路
     */
    get openAMapMatch() {
      if (envName === 'prod21') {
        return ['181061980581000101'].includes(window.companyId)
      }
      return true
    },

    /**
     * 是美团
     */
    get isMeituan() {
      return oauthType === '8' && ['meituan'].includes(appId)
    },

    /**
     * 是默克
     */
    get isMoke() {
      return oauthType === '11' 
        && ['1000199@wxd63d10ddc90e527d', '1000027@wx574d96321ed727af'].includes(appId)
    },

    /**
     * 是AZ
     */
    get isAZ() {
      return oauthType === '7' && ['AZ','az', '450000000000000'].includes(appId)
    }

  },
  computed: {
    /**
     * 
     * @param {*} value 
     * @param {*} rules {limit: 1000, ratio: 0.7}
     */
    segmentedDiscount(value, rules) {
      rules = rules.sort((a, b) => { return b.limit - a.limit })
      let v = value
      let _v = 0
      for (let i in rules) {
        let r = rules[i]
        if (r.limit < v) {
          if (r.ratio) {
            _v += (v - r.limit) * r.ratio
          } else {
            let rv = Number(r.value)
            if (isNaN(rv)) {
              r.value = 0
            } else {
              r.value = rv
            }

            let sub_v = (v - r.limit) + r.value
            if (sub_v < 0) {
              sub_v = 0
            }
            _v += sub_v
          }

          v = r.limit
        }
      }
      _v += v
      _v = Math.round(_v)
      return _v
    },

    calculationTimeKM(d, t) {
      let time;
      let distance;
      if (t > 5) {
        time = t * 0.8
      } else {
        time = t
      }

      if (d > 1000) {
        distance = d * 0.7
      } else {
        distance = d
      }
      return {
        time: time,
        distance: distance
      }
    }
  }
}

window.gc = globalConfiguration

export {
  oauthType,
  appId
}

export default globalConfiguration