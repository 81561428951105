import React from "react";
import {inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import style from './approvalcell.scss'

import { RightOutline } from 'antd-mobile-icons'
import { Checkbox,Dialog } from 'antd-mobile'

import images from '../../util/images'
import { serviceStateToCn } from '../../util/paramsHelper'

@inject('globalStore')
@observer
class ApprovalCell extends React.Component {

  t = this.props.t

  globalStore = this.props.globalStore

  render() {
    return (
      <div className={this.props.isIcon ? style.approvalcellBorderRadius : style.approvalcell}>
        {/* 头部金额及选择 */}
        <div className={style.price}>
          {
            (() => {
              if (this.props.activeKey === '1') {
                return(
                  <Checkbox onChange={(e)=>{
                    this.props.onChange(e, this.props.orderId, this.props.index)
                  }} checked={this.props.checked} style={{'--icon-size': '20px'}} value='apple'></Checkbox>
                )
              }
            })()
          }
          <span className={`${this.props.activeKey === '1' ? style.show : style.noShow}`}>{this.props.amount ? this.props.amount : ''}<em>{this.t('approval.k008')}</em></span>
        </div>
        {this.props.isAbnormal?<div className={style.abnormalBox}><span className={style.abnormal} onClick={()=>{
          Dialog.alert({
            title:this.t('approval.k042'),
            confirmText: this.t('common.k003'),
            getContainer: () => document.getElementsByClassName('Q-3m3LDhTsJj5yjCQmlFp')[0],
            content: (
              <>
              {
                this.props.abnormalRules&&this.props.abnormalRules.map((item,idx)=>{
                 return <div>{idx+=1}、{item}</div>
                })
              }
              {
                this.props.abnormalTipFlag ? <div className={style.tip_message}>{this.props.tip}</div> : ''
              }
              </>
            ),

          })
        }} >{this.t('approval.k041')}</span></div>:""}
        { 
          (() => {
            if (this.props.warning) {
              return(
                <div className={style.abnormalBox}>
                  <span className={style.abnormal} onClick={(e) => {
                    e.stopPropagation()
                    this.props.onClickRisk(this.props.warningRemind)
                  }}>{this.t('approval.k014')}</span>
                </div>
              )
            }
          })()
        }
        {
          (() => {
            if (this.props.orderState === 7 && this.props.amount !== 0) {
              return(
                <div className={style.cancel_fee}><div>{this.t('orders.k033')}</div></div>
              )
            }
          })()
        }
        {/* 中间手机、车型、城市 */}
        <div className={style.middle}>
          <div>
            <img className={style.usecar} alt="" src={images.usecar}></img>&nbsp;
            <span>{this.props.userNameCn}</span>
          </div>
          <div>
            <img className={style.cartype} alt="" src={images.cartype}></img>&nbsp;
            <span>{this.props.carSource}</span>
          </div>
          <div>
            <img className={style.city} alt="" src={images.city}></img>&nbsp;
            <span>{this.props.cityName}</span>
          </div>
        </div>
        {/* 底部信息部分 */}
        <div className={style.message} onClick={() => this.props.onClick(this.props.orderId)}>
          {
            (() => {
              if (this.props.ipathOrderId) {
                return(
                  <p>{this.t('approval.k015')} {this.props.ipathOrderId}</p>
                )
              }
            })()
          }
          <p>{this.t('approval.k003')} {this.props.departTime}</p>
          <p>{this.t('approval.k001')}{this.props.deparLocation}</p>
          <p>{this.t('approval.k002')}{this.props.destLocation}</p>
          {/* 包车类型 */}
          { <p>{this.t('orderdetails.k012')}{serviceStateToCn(this.props.serviceType, this.t, this.props.charteredBusType)}</p> }
          {/* 里程 */}
          {
            (() => {
              if (this.props.distance) {
                if (this.props.distance > 1) {
                  return <p>{this.t('approval.k004')}{this.props.distance}{this.t('approval.k016')}</p>
                } else {
                  return <p>{this.t('approval.k004')}{(this.props.distance * 1000)}{this.t('approval.k017')}</p>
                }
              }
            })()
          }
          {/* 时长 */}
          <p>{this.t('approval.k005')}{this.props.duration}</p>
          {
            (() => {
              if (this.props.sceneNameCn) {
                return (
                  <p>{this.t('approval.k018')}{this.props.sceneNameCn}</p>
                )
              }
            })()
          }
          {
            (() => {
              if (this.props.useCarReason) {
                return (
                  <p>{this.t('approval.k019')}{this.props.useCarReason}</p>
                )
              }
            })()
          }
          {/* 动态数据：预算代码 */}
          {
            this.props.budgetCode && this.props.budgetCode.length
              ? this.props.budgetCode.map(item=>{
                return <p key={item.name}><span>{item.name}：</span><span>{item.value}</span></p>
              })
              : <></>
          }
          {/* 施维雅自定义用车字段 */}
          {
              this.props.customLabel?.map((item, index) => {
                return <p key={index} style={{ color: item.key.color }}><span>{item.key.txt}:</span> <span style={{color: item.value.color }}>{item.value.txt}</span></p>
              })
          }
          {/* TODO 行前转行后判断 */}
          {
            (() => {
              if (this.props.rulePreDepartApplyId) {
                return (
                  // 行前申请单号：
                  <p>{this.t('approval.k047')}{this.props.rulePreDepartApplyId}</p>
                )
              }
            })()
          }
          {
            (() => {
              if (this.props.isIcon) {
                return(
                  <RightOutline fontSize={16} className={style.icon}></RightOutline>
                )
              }
            })()
          }
        </div>
        {
          (() => {
            // 审核状态
            if (this.props.approvalState === 4 && this.props.activeKey === '2') {
                if (this.globalStore.userInfo.language === 1) {
                  return (
                    <img className={style.refuse} alt="" src={images.refuse_approval}></img>
                    )
                } else {
                  return (
                    <img className={style.refuse} alt="" src={images.refuse_approval_en}></img>
                  )
                }
            } else if (this.props.approvalState === 2 && this.props.activeKey === '2') {
              if (this.globalStore.userInfo.language === 1) {
                return(
                  <img className={style.pass} alt="" src={images.pass_approval}></img>
                )
              } else {
                return (
                  <img className={style.refuse} alt="" src={images.pass_approval_en}></img>
                )
              }
            }
          })()
        }
      </div>
    )
  }
}

ApprovalCell.propTypes = {
  index: PropTypes.number,
  // 审批 id
  orderId: PropTypes.string,
  // 金额
  amount: PropTypes.string,
  // 手机类型
  userNameCn: PropTypes.string,
  // 车型
  carSource: PropTypes.string,
  // 城市
  cityName: PropTypes.string,
  // 创建时间
  departTime: PropTypes.string,
  // 起点地址
  deparLocation: PropTypes.string,
  // 终点地址
  destLocation: PropTypes.string,
  // 里程
  distance: PropTypes.number,
  // 是否选中
  checked: PropTypes.bool,
  // 预警
  warning:  PropTypes.bool,
  // 异常提示
  warningRemind: PropTypes.string,
  // 审批状态
  approvalState: PropTypes.number,
  // 订单编号
  ipathOrderId: PropTypes.string,
  // 用车场景
  sceneNameCn: PropTypes.string,
  // 用车事由
  useCarReason: PropTypes.string,
  // 自定义用车信息
  budgetCode: PropTypes.array,
  // tab 选中状态
  activeKey: PropTypes.string,
  // 判断 icon 展示隐藏
  isIcon: PropTypes.bool,
  serviceType: PropTypes.number,
  charteredBusType: PropTypes.number,
  // 是否行前异常转行后审批 行前申请单号
  rulePreDepartApplyId: PropTypes.string,
  // 施维雅自定义用车字段
  customLabel: PropTypes.array,
  orderState: PropTypes.number,
  abnormalTipFlag: PropTypes.bool,
  tip: PropTypes.string
}

ApprovalCell.defaultProps = {
  orderId: "",
  amount: "",
  userNameCn: "",
  carSource: "",
  cityName: "",
  departTime: "",
  deparLocation: "",
  destLocation: "",
  distance: 0,
  ipathOrderId: "",
  sceneNameCn: "",
  checked: null,
  isIcon: true,
  serviceType: null,
  charteredBusType: null,
  rulePreDepartApplyId: null,
  customLabel: null,
  orderState: null,
  onClick: () => {},
  abnormalTipFlag: false,
  tip: ""
}

export default ApprovalCell