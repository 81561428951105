import React from 'react';
import style from './selector.scss'

import { TextArea } from 'antd-mobile'

class Selector extends React.Component {

    state = {
        activeValue: '',
        options: []
    }

    t = this.props.t

    render() {
        return <div className={style.container}>
            {
                this.state.options.map(a => {
                    return <div className={`${style.item} ${this.state.activeValue === a.value ? style.active : ''}`} key={a.value} onClick={() => {
                        this.setState({
                            activeValue: a.value
                        }, () => {
                            this.props.onChange(a);
                        })
                    }}>
                        <div className={style.label_wrap}>{a.label}</div>
                        {
                            a.editing && this.state.activeValue === a.value
                            ? <div className={style.textarea_wrap}>
                                <TextArea rows={3} 
                                    placeholder={a.placeholder} 
                                    value={a.text}
                                    onChange={(val) => {
                                        a.text = val
                                        this.setState({
                                            options: this.state.options
                                        }, () => {
                                            this.props.onChange(a);
                                        })
                                        
                                    }}
                                    style={{
                                        '--font-size': '12px'
                                    }}/>
                            </div>
                            : <></>
                        }
                    </div>
                })
            }
        </div>
    }

    componentDidMount = () => {
        this.setState({
            options: this.props.options,
            activeValue: ''
        })
    }
}

export default Selector;