import React from "react"
import style from './santencostcenter.scss'

import { Button, Popup, Dialog } from 'antd-mobile'

import LoadingMask from './../../component/utils/loadingMask';
import SantenCostCenterAdd from './../../component/special/santencostcenterAdd';
import { ForgeRouter } from './../../util/utils'

import webService from "../../util/webService";
import images from '../../util/images';

import { withTranslation } from "react-i18next";

class SantenCostCenter extends React.Component {

    state = {
        userProjects: [],
        isShowMask: false,
        noDate: false,

        showAdd: false
    }

    needCallBack = false;

    t = this.props.t

    renderUserProject() {
        return <>
            {
                this.state.userProjects.map(item => {
                    return <div key={item.id} className={style.project}>
                        {/* 名称 */}
                        <div>{this.t('special.k003')}{item.name || item.nameCn}</div>
                        {/* 编码： */}
                        <div>{this.t('special.k005')}{item.code}</div>
                        {/* 来源： */}
                        <div>{this.t('special.k006')}{item.source}</div>
                        <div>
                            {/* <Checkbox checked={item.isDefault} />
                            <div>设为默认</div> */}
                            <div></div>
                            <div></div>
                            {/* 删除 */}
                            <div onClick={() => {this.del(item.id)}}>{this.t('special.k007')}</div>
                            
                        </div>
                    </div>
                })
            }
        </>
    }

    render() {
        return <div className={style.container}>
            <LoadingMask show={this.state.isShowMask} />
            <div className={style.projects}>
            {
              this.state.userProjects && this.state.userProjects.length && !this.state.noDate ? this.renderUserProject() : <></>
            }
            
            {
                this.state.noDate ? <div className={style.noOrder}>
                    <div>
                        {/* 暂无数据 */}
                        <img src={images.no_order} alt={this.t('special.k008')} />
                    </div>
                    <div>
                        {this.t('special.k007')}
                    </div>
                </div> : <></>
            }
            
            </div>
            

            <div className={style.btn}>
                <Button block color='primary' onClick={() => {
                    this.setState({
                        showAdd: true
                    }, () => {
                        let fr = new ForgeRouter(() => {
                            let {hash} = document.location;
                            if (hash === '#/special/santencostcenter') {
                                this.setState({
                                    showAdd: false
                                }, () => {
                                    if (this.needCallBack) {
                                        this.props.onChange();
                                    }
                                })
                                
                            } 
                        });
                        fr.onpopstate();
                    })
                    // 新增
                }}>{this.t('special.k007')}</Button>
            </div>

            <Popup
                position='bottom'
                visible={this.state.showAdd}
                bodyStyle={{ height: '100%' }}
                >
                {/* 地址选择框 */}
                {
                    this.state.showAdd 
                    ? <SantenCostCenterAdd t={this.props.t} onClose={this.onClose}
                        history={this.props.history}
                    ></SantenCostCenterAdd> 
                    : <></>}
            </Popup>
        </div>
    }

    async componentDidMount() {

        await this.getUserProjects()
    }

    getUserProjects = async () => {
        this.setState({
            isShowMask: true
        })
        let { data } = await webService.special.costCenter.getUserProjects();
        this.setState({
            isShowMask: false
        })

        if (data.code) {
            await Dialog.alert({
                content: data.message,
            })
        } else {
            this.setState({
                userProjects: [
                    ...data.data
                ],
                noDate: data.data.length === 0
            })
        }

        
    }

    del = async (id) => {
        const result = await Dialog.confirm({
            // 确认删除此成本中心?
            content: this.t('special.k010'),
            cancelText: this.t('common.k001'),
            confirmText: this.t('common.k002')
        })
        if (!result) {
            return
        }

        let {data} = await webService.special.costCenter.deleteUserProject(id);
        if (data.code) {
            await Dialog.alert({
                content: data.message,
            })
        }
        await this.getUserProjects()
    }

    onClose = async () => {
        this.props.history.goBack();
        await this.getUserProjects()
    }
}

export default withTranslation()(SantenCostCenter)