import React from 'react';
import style from './prepaidProgress.scss'
import images from './../util/images'

import webService from './../util/webService'

import { customerServiceTelephone } from '../util/config'

import { copy } from '../util/utils'

import { Divider, Steps, Space, Toast } from 'antd-mobile'
import { CheckCircleFill } from 'antd-mobile-icons'

import { withTranslation } from "react-i18next";

class PrepaidProgress extends React.Component {
  state = {
    current: 0,
    prepaidInfo: {
      prepaymentPayList: [],
      // 预付费金额
      deductionMoney: '',
      // 退还金额
      refoundMoney: '',
      // 退还日期
      refoundDate: ''
    }
  }

  t = this.props.t

  /**
   * 
   * 规则
   */
  renderRules = () => {
    return(
      <div className={style.box}>
        <div className={style.rules}>{this.t('prepaidProgress.k001')}</div>
        <p>
        {this.t('prepaidProgress.k002')}
        </p>
        <p>
        {this.t('prepaidProgress.k003')}
        </p>
        
        {
          //eslint-disable-next-line
          this.state.prepaidInfo.prepaymentPayList.map((item, index) => {
              if (item.tranOrderNo) {
                return (
                  <div key={index} className={style.payNum}>
                  {this.t('prepaidProgress.k004')}{
                  item.tranOrderNo} <em className={style.copy} onClick={() => {
                    copy(item.tranOrderNo, () => {
                      Toast.show({
                        content: this.t('prepaidProgress.k005'),
                        duration: 1000
                      })
                    })
                  }}>{this.t('prepaidProgress.k006')}</em>
                </div>
                )
              }
          })
        }
        <Divider />
      </div>
    )
  }

  /**
   * 
   * 预付费进度
   */

  renderPrepaidProgress = () => {
    const { Step } = Steps
    return(
      <div className={style.progress}>
        <Steps
          direction='vertical'
          current={this.state.current}
          style={{
            '--title-font-size': '17px',
            '--description-font-size': '15px',
            '--indicator-margin-right': '12px',
            '--icon-size': '22px',
          }}
        >
          <Step
            title={
              <div>
                {
                  //eslint-disable-next-line
                    this.state.prepaidInfo.prepaymentPayList.map((item,index) => {
                      if (item.tranOrderNo) {
                        return (
                          <div key={index}>
                            <div className={style.money}>{this.t('prepaidProgress.k007', {payMoney: item.payMoney})}</div>
                            <div className={style.timeDate}>{item.payDate}</div>
                          </div>
                        )
                      }
                    })
                }
              </div>
              }
            icon={<CheckCircleFill />}
          />
          <Step
            title={
              <div>
                <div className={style.money}>
                    {/* 预付款已被抵扣 {this.state.prepaidInfo.deductionMoney} 元车费 */}
                    {this.t('prepaidProgress.k008', {money: this.state.prepaidInfo.deductionMoney})}
                    {this.state.prepaidInfo.refoundMoney > 0 ?  <span>{this.t('prepaidProgress.k009', {money: this.state.prepaidInfo.refoundMoney})}</span> : ''}

                </div>
                <div className={style.timeDate}>{this.state.prepaidInfo.refoundDate}</div>
              </div>
            }
            icon={<CheckCircleFill />}
          />
          {
            (() => {
              if (this.state.prepaidInfo.refoundMoney > 0) {
                return (
                  <Step
                    title={<div className={style.accept}>{this.t('prepaidProgress.k011')}</div>}
                    description={
                      <Space block direction='vertical'>
                        <div className={style.refund}>{this.t('prepaidProgress.k012')}</div>
                      </Space>
                    }
                    icon={<CheckCircleFill />}
                  />
                )
              }
            })()
          }
          
        </Steps>
      </div>
    )
  }

  /**
   * 
   * 客服
   */
  renderCustomerService = () => {
    return(
      <div className={style.customerService} onClick={() => {
        window.top.location.href = `tel://${customerServiceTelephone}`
      }}>
        <img alt='' src={images.customerService} />{this.t('prepaidProgress.k013')}{customerServiceTelephone}
      </div>
    )
  }

  render() {
    return(
      <div className={style.wrap}>
        {this.renderRules()}
        {this.renderPrepaidProgress()}
        {this.renderCustomerService()}
      </div>
    )
  }

  componentDidMount() {
    const { id } = this.props.match.params

    webService.order.getPrepaymentOrderInfo({orderId: id}).then(d => {
      if (d.data.data) {
        this.setState({
          prepaidInfo: d.data.data
        })
        if (d.data.data.refoundMoney && d.data.data.refoundMoney > 0) {
          this.setState({
            current: 2
          })
        } else {
          this.setState({
            current: 1
          })
        }
      }
    })
  }
}

export default withTranslation()(PrepaidProgress);
