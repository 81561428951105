import React from 'react'
import style from './noPay.scss';

import { Tag, Checkbox } from 'antd-mobile'
// import { RightOutline } from 'antd-mobile-icons'

import { appId } from '../../util/globalConfiguration';

import moment from 'moment'

import gc from '../../util/globalConfiguration'

class NoPay extends React.Component {

    state = {
        order: this.props.order
    }

    t = this.props.t

    get tagColor() {
      if(gc.process.isMeituan) {
        return '#FF6A00'
      } else {
        return '#F5A623'
      }
    }

    render() {
        let {order}=this.state;
        return <div className={style.pay}>
            <div className={style.title}>
                <div>
                    <Checkbox checked={order.checked} onChange={(val) => {
                        this.setState({
                            order: {
                                ...this.state.order,
                                checked: val
                            }
                        })
                        this.props.onChange(val)
                    }} />
                </div>
                <div>
                    <div><span>{this.checkNonAmount(order.payType, order)}</span>{this.t('payments.k009')}</div>
                    {
                        order.approveState !== 4
                            ? (<div>{this.t('payments.k016')}{order.totalMoney ? order.totalMoney : 0}{this.t('payments.k009')} {order.enterpriseMoney ||  order.prepaymentUpMoney ? '| ' : ''}
                              {
                                // k029消费金抵扣
                                order.enterpriseMoney ? <Tag color={this.tagColor} fill='outline'>{['zhonghai', 'XCX032424'].includes(appId) ? this.t('payments.k029') : this.t('payments.k017')}{order.enterpriseMoney}{this.t('payments.k009')}</Tag> : ''
                                
                              }
                              &nbsp;
                              {
                                order.prepaymentUpMoney > 0 && order.payType === 4 ? <Tag color={this.tagColor} fill='outline'>{this.t('payments.k018')}{order.prepaymentUpMoney}{this.t('payments.k009')}</Tag> : ''
                              } 
                              
                              </div>)
                            : (order.payType === 1 ? <div className={style.alert}>{this.t('payments.k019')}</div> : '')
                    }
                    
                </div>
            </div>
            <div className={style.body}  onClick={() => {
                        return
                        // let uri = `/orderdetails/${order.id}`;
                        // this.props.history.push(uri);
                    }} >
                <div>
                    <div>{this.t('payments.k020')}{moment(order.createTime).format('YYYY-MM-DD HH:mm')}</div>
                    <div>{this.t('payments.k021')}{order.pickupLocationName}</div>
                    <div>{this.t('payments.k022')}{order.destLocationName}</div>
                    <div>{this.t('payments.k023')}{order.cityName}</div>
                </div>
                <div>
                    {/* <RightOutline /> */}
                </div>
            </div>
        </div>
    }

    componentWillReceiveProps(props) {
        this.setState({
            order: props.order
        })
    }

    // 待支付金额几个状态展示
    checkNonAmount = (type, order) => {
      if (type === 1) {
        return order.enterpriseMoney
      } else if (type === 2) {
        return order.individualMoney
      } else if (type === 4) {
        return order.prepaymentDownMoney
      }

    }

}

export default NoPay