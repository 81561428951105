import React from 'react'
import style from './callPolice.scss';
import { Button, Badge } from 'antd-mobile'
import { PhoneFill} from 'antd-mobile-icons'
import { withTranslation } from "react-i18next";
import { inject, observer } from 'mobx-react'

import webService from '../util/webService';
import {customerServiceTelephone} from './../util/config'

import {getCurrentPosition} from './../util/geo';

import { dge } from './../util/utils';

import loger from './../util/loger'
import gc from './../util/globalConfiguration'

@inject('globalStore')
@observer
class CallPolice extends React.Component {

    state = {
        driverInfo: {},
        location: {}
    }

    globalStore = this.props.globalStore;
    orderId = this.props.match.params.id;

    t = this.props.t

    renderInfoTitle = (title) => {
        return <div>
            <div className={style.dot}><div></div></div>
            <div>{title}</div> 
        </div>
    }

    render() {
        let {driverInfo, location} = this.state;

        return <div className={style.container}>
            <div className={style.title}>{this.t('callPolice.k001')}</div>
            <div className={style.tip}>{this.t('callPolice.k002')}</div>
            
            <div className={style.info}>
            {
                driverInfo && driverInfo.vehicleNo
                    ? (<div>
                        {this.renderInfoTitle(this.t('callPolice.k003'))}
                        <div>{driverInfo.vehicleNo} {driverInfo.vehicleModel} {driverInfo.vehicleColor}</div>
                    </div>)
                    :<></>
            }
            {
                location && location.name
                    ? (<div>
                        {this.renderInfoTitle(this.t('callPolice.k004'))}
                        <div>{location.name}</div>
                    </div>)
                    :<></>
            }
                
            </div>

            <div className={style.tip}>{this.t('callPolice.k005')}</div>

            <div className={style.strategy}>
                <div className={style.dot}><div></div></div>
                <div>{this.t('callPolice.k006')}</div>
            </div>
            <div className={style.strategy}>
              {
                // (() => {
                //   let ua = dge();
                //   if (ua !== 'smartcomtrip-mobile') {
                //     return <>
                //         <div className={style.dot}><div></div></div>
                //         <div>
                //             {this.t('callPolice.k007')}
                //             {
                //                 !this.globalStore.userInfo.emergencyPhone
                //                     ? <span onClick={() => {
                //                         this.props.history.push('/contact')
                //                     }}>{this.t('callPolice.k008')}</span>
                //                     : <></>
                //             }
                            
                //         </div>
                //     </>
                //   }
                // })()
              }
              {
                !gc.process.isCNPC
                ? <>
                    <div className={style.dot}><div></div></div>
                    <div>
                        {this.t('callPolice.k007')}
                        {
                            !this.globalStore.userInfo.emergencyPhone
                                ? <span onClick={() => {
                                    this.props.history.push('/contact')
                                }}>{this.t('callPolice.k008')}</span>
                                : <></>
                        }
                        
                    </div>
                </>
                :<></>
              }
            </div>

            <div className={style.toolbar}>
                <div>
                    <Button block color='primary' fill='outline' onClick={() => { this.call(customerServiceTelephone); }}>
                      {
                        (() => {
                          let ua = dge();
                          if(ua !== 'smartcomtrip-mobile') {
                            return this.t('callPolice.k009')
                          } else {
                            return this.t('callPolice.k009')
                          }
                        })()
                      }
                    </Button>
                </div>
                <div>
                    <Button block color='primary' fill='solid' onClick={() => { this.call('110'); }}>
                        <PhoneFill />
                        <Badge content={this.t('callPolice.k012')}>
                            <div className={style.box}>{this.t('callPolice.k010')}</div>
                        </Badge>
                    </Button>
                </div>
            </div>
        </div>
    }

    componentDidMount() {
        webService.order.getDriverInfo({orderId: this.orderId}).then(d => {
            if (d.data.code === 0) {
                loger.info('一键报警', '司机信息', d.data.data.driverInfo)
                this.setState({
                    driverInfo: d.data.data.driverInfo
                }, () => {
                });
                
            } else {
              loger.info('一键报警', '司机信息', '获取司机信息失败')
            }
            
        });

        getCurrentPosition().then(d => {
            loger.info('一键报警', '当前地址', d.address)
            this.setState({
                location: {
                    name: d.address
                }
            })
        }, d => {
            loger.info('一键报警', '当前地址', '获取当前地址失败')
            this.setState({
                location: {
                    name:this.t('callPolice.k011')
                }
            })
        })

        
    }

    call = (tel) => {
        let {driverInfo, location} = this.state;
        let orderId = this.orderId;
        let data = {
            orderId,
            driverInfo,
            location
        }
        webService.order.callPolice(data);

        setTimeout(() => {
            window.top.location.href = `tel://${tel}`;
        }, 200);
        
    }
}
export default withTranslation()(CallPolice)