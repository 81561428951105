import i18n from './../react-i18next/i18n'
import gc from './globalConfiguration'
import Main from './../pages/main'
import NotFount from '../pages/404'
import Debug from '../pages/debug'
import NoAuth from '../pages/NoAuth'
import Estimate from './../pages/estimate'
import Confirm from './../pages/confirm'
import ChooseCoupon from './../pages/chooseCoupon';
import WaitingShenzhou from './../pages/waitingShenzhou';
import Runing from './../pages/runing';
import CallPolice from './../pages/callPolice';
import RuningOrders from './../pages/runingOrders';
import Scenes from './../pages/scenes';
import scenesDetails from './../pages/scenesDetails'
import Coupons from './../pages/coupons';
import Share from './../pages/share';
import Payments from './../pages/payments';
import Account from './../pages/account';
import AccountCharge from './../pages/accountCharge';
import WatchShare from './../pages/watchShare';
import Agreement from './../pages/agreement';
import AgreementEn from './../pages/agreementEn';
import TripAgreement from './../pages/tripAgreement';
import ChooseScene from './../pages/chooseScene';
import Prepaid from './../pages/prepaid'


import Orders from './../pages/orders'
import OrderDetails from './../pages/orderdetails'
import OrderEvaluate from './../pages/orderevaluate'


import Invoice from './../pages/invoice'
import WriteInvoice from './../pages/writeInvoice'
import InvoiceDetails from './../pages/invoiceDetails'
import Trip from './../pages/trip'

import Settings from './../pages/settings'
import Contact from './../pages/contact'
import Setphone from './../pages/setphone'

import Report from './../pages/report'

import Approval from './../pages/approval'
import ApprovalDetails from '../pages/approvalDetails'

import ClauseDetails from './../pages/clauseDetails'

import Login from './../pages/login'
import Register from './../pages/register'

import PrepaidProgress from './../pages/prepaidProgress'

import PerApply from './../pages/perApply'
import PerApplyRecord from './../pages/perApplyRecord'
import PreApproval from './../pages/preApproval'

import HabitAddress from './../pages/habitAddress'

import SantenCostCenter from './../pages/special/santencostcenter'
import Meeting from './../pages/special/meeting'
import QQMapTest from './../pages/special/qqmaptest'


import AmapMiddleware from './../pages/amapMiddleware'

import CarbonEmission from './../pages/special/carbonEmission'

import SanofiMeeting from './../pages/special/sanofiMeeting'
import AddSanofiMeeting from './../pages/special/addSanofiMeeting'

import OrderNewDetails from './../pages/ordernewdetails'

import ExpenseDetails from './../pages/expenseDetails'

import ServiceCenter from './../pages/serviceCenter'

import ComplaintsDetails from './../pages/complaintsDetails'

import OrderAbnormal from '../pages/orderAbnormal'
import LimitedTimeDisable from '../pages/limitedTimeDisable'

import MapDev from '../pages/mapdev'
import StationDev from '../pages/stationDev'


let routes = [
    {
        path: "/estimate",
        component: Estimate,
        get title() {
          return i18n.t('title.k001')
        }
    },
    {
        path: "/confirm",
        component: Confirm,
        get title() {
          return i18n.t('title.k002')
        },
        childrens: [
            {
                path: '/agreement',
                component: Agreement,
                get title() {
                  return i18n.t('title.k003')
                },
                isPub: true
            },
            {
              path: '/agreementEn',
              component: AgreementEn,
              get title() {
                return i18n.t('title.k003')
              },
              isPub: true
            },
            {
              path: '/tripAgreement',
              component: TripAgreement,
              get title() {
                return i18n.t('title.k004')
              },
              isPub: true
            }
        ]
    },
    {
      path: "/prepaid",
      component: Prepaid,
      get title() {
        return i18n.t('title.k005')
      },
    },
    {
      path: '/prepaidProgress/:id',
      component: PrepaidProgress,
      get title() {
        return i18n.t('title.k006')
      }
    },
    {
        path: "/chooseCoupon",
        component: ChooseCoupon,
        get title() {
          return i18n.t('title.k007')
        }
    },
    {
        path: "/waitingShenzhou/:id",
        component: WaitingShenzhou,
        get title() {
          return i18n.t('title.k008')
        }
    },
    {
        path: "/runing/:id",
        component: Runing,
        get title() {
          return i18n.t('title.k009')
        }
    },
    {
        path: "/callPolice/:id",
        component: CallPolice,
        get title() {
          return i18n.t('title.k010')
        }
    },
    {
        path: "/runingOrders",
        component: RuningOrders,
        get title() {
          return i18n.t('title.k011')
        }
    },
    {
        path: "/scenes",
        component: Scenes,
        get title() {
          return i18n.t('title.k012')
        }
    },
    {
        path: "/scenesDetails/:id",
        component: scenesDetails,
        get title() {
          return i18n.t('title.k043')
        }
    },
    {
        path: "/coupons",
        component: Coupons,
        get title() {
          return i18n.t('title.k013')
        }
    },
    {
        path: "/share/:id",
        component: Share,
        get title() {
          return i18n.t('title.k014')
        }
    },
    {
        path: "/payments",
        component: Payments,
        get title() {
          return gc.process.payMenuName ? i18n.t('title.k051') : i18n.t('title.k015')
        }
    },
    {
        path: "/account",
        component: Account,
        get title() {
          return i18n.t('title.k016')
        }
    },
    {
        path: "/accountCharge/:id",
        component: AccountCharge,
        get title() {
          return i18n.t('title.k017')
        }
    },
    

    {
        path: "/orders",
        component: Orders,
        get title() {
          return i18n.t('title.k018')
        }
    },
    {
        path: "/orderdetails/:id",
        component: OrderDetails,
        get title() {
          return i18n.t('title.k019')
        }
    },
    {
      path: "/ordernewdetails/:id",
      component: OrderNewDetails,
      get title() {
        return i18n.t('title.k019')
      }
    },
    {
      path: "/expenseDetails/:id",
      component: ExpenseDetails,
      get title() {
        return i18n.t('title.k055')
      }
    },
    {
      path: "/serviceCenter/:id",
      component: ServiceCenter,
      get title() {
        return i18n.t('title.k056')
      }
    },
    {
      path: "/complaintsDetails/:id",
      component: ComplaintsDetails,
      get title() {
        return i18n.t('title.k057')
      }
    },
    {
      path: "/orderAbnormal/:id",
      component: OrderAbnormal,
      get title() {
        return i18n.t('title.k058')
      }
    },
    {
        path: "/orderevaluate/:id",
        component: OrderEvaluate,
        get title() {
          return i18n.t('title.k020')
        }
    },

    {
        path: "/invoice",
        component: Invoice,
        get title() {
          return i18n.t('title.k021')
        }
    },
    {
        path: "/writeInvoice",
        component: WriteInvoice,
        get title() {
          return i18n.t('title.k022')
        }
    },
    {
        path: "/invoiceDetails/:id",
        component: InvoiceDetails,
        get title() {
          return i18n.t('title.k023')
        }
    },
    {
        path: "/trip/:id",
        component: Trip,
        get title() {
          return i18n.t('title.k024')
        }
    },

    {
        path: "/approval",
        component: Approval,
        get title() {
          return i18n.t('title.k025')
        }
    },
    {
        path: "/approvalDetails/:id",
        component: ApprovalDetails,
        get title() {
          return i18n.t('title.k026')
        }
    },

    {
        path: "/settings",
        component: Settings,
        get title() {
          return i18n.t('title.k027')
        }
    },
    {
        path: "/contact",
        component: Contact,
        get title() {
          return i18n.t('title.k028')
        }
    },
    {
        path: "/setphone",
        component: Setphone,
        get title() {
          return i18n.t('title.k027')
        }
    },

    {
        path: "/report",
        component: Report,
        get title() {
          return i18n.t('title.k029')
        }
    },


    
    {
        path: "/noauth",
        component: NoAuth,
        get title() {
          return i18n.t('title.k030')
        },
        isPub: true
    },
    {
        path: "/404",
        component: NotFount,
        get title() {
          return i18n.t('title.k031')
        },
        isPub: true
    },
    {
        path: "/debug",
        component: Debug,
        get title() {
          return i18n.t('title.k032')
        },
        isPub: true
    },
    {
        path: '/watchShare/:id',
        component: WatchShare,
        get title() {
          return i18n.t('title.k033')
        },
        isPub: true
    },
    {
      path: "/clauseDetails",
      component: ClauseDetails,
      get title() {
        return i18n.t('title.k034')
      }
    },
    {
        path: "/login/:id",
        component: Login,
        get title() {
          return i18n.t('title.k035')
        },
        isPub: true
    },
    {
        path: "/register",
        component: Register,
        get title() {
          return i18n.t('title.k036')
        },
        isPub: true
    },
    {
        path: "/perApply/:id",
        component: PerApply,
        get title() {
          return i18n.t('title.k037')
        }
    },
    {
        path: "/perApply",
        component: PerApply,
        get title() {
          return i18n.t('title.k038')
        }
    },
    {
        path: "/perApplyRecord",
        component: PerApplyRecord,
        get title() {
          return i18n.t('title.k039')
        }
    },
    {
        path: "/preApproval",
        component: PreApproval,
        get title() {
          return i18n.t('title.k040')
        }
    },
    {
        path: '/habitAddress',
        component: HabitAddress,
        get title() {
          return i18n.t('title.k045')
        }
    },

    {
        path: '/special/santencostcenter',
        component: SantenCostCenter,
        get title() {
          return i18n.t('title.k046')
        }
    },
    {
        path: '/special/meeting',
        component: Meeting,
        get title() {
          return i18n.t('title.k047')
        }
    },
    {
        path: '/special/map',
        component: QQMapTest,
        get title() {
          return i18n.t('title.k048')
        },
        isPub: true
    },
    {
      path: '/special/carbonEmission',
      component: CarbonEmission,
      get title() {
        return i18n.t('title.k049')
      } 
    },
    // 我的会议
    {
      path: '/special/sanofiMeeting',
      component: SanofiMeeting,
      get title() {
        return i18n.t('title.k053')
      } 
    },
    // 选择会议
    {
      path: '/special/addSanofiMeeting',
      component: AddSanofiMeeting,
      get title() {
        return i18n.t('title.k054')
      } 
    },
    {
        path: '/amapMiddleware',
        component: AmapMiddleware,
        get title() {
          return i18n.t('title.k050')
        }
    },
    {
      path: '/limitedTimeDisable',
      component: LimitedTimeDisable,
      get title() {
        return i18n.t('title.k052')
      }
    },
    {
      path: '/mapDev',
      component: MapDev,
      get title() {
        return '地图调试'
      },
      isPub: true
    },
    {
      path: '/stationDev',
      component: StationDev,
      get title() {
        return '场站调试'
      },
      isPub: true
    },
    {
        path: "/",
        component: Main,
        get title() {
          return i18n.t('title.k041')
        },
        childrens: [
            {
                path: '/chooseScene',
                component: ChooseScene,
                title: i18n.t('title.k042'),
                isPub: true
            }
        ]
    }
    
];


export default routes;