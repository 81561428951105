import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router';
@withRouter
class touchEvent extends React.Component{
  state = {
    moveLeft: false,
    moveRight: false
  }
  startY = null
  startX = null
  mybody = null

  touchStartTime = null

  componentDidMount() {


    let _this = this

    this.mybody = document.getElementsByTagName('body')[0]

    this.mybody.addEventListener('touchstart',  (ev) => {
      let clientWidth = document.documentElement.clientWidth
     
      
      _this.startX = ev.touches[0].pageX;
      _this.startY = ev.touches[0].pageY;
      this.touchStartTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
      console.log('startX')
      console.log(_this.startX)
      if ((_this.startX <= 10)) {
        console.log(_this.startX)
        console.log('右滑')
        this.setState({
          moveRight: true
        })
      }
      if (this.startX >= (clientWidth - 10)) {
        console.log(_this.startX)
        console.log(clientWidth-10)
        console.log('左滑')
        this.setState({
          moveLeft: true
        })
      }
    }, false);

    this.mybody.addEventListener('touchend', (ev) => {
      var endX, endY;
      endX = ev.changedTouches[0].pageX;
      endY = ev.changedTouches[0].pageY;
      var direction = _this.getSlideDirection(_this.startX, _this.startY, endX, endY);

      let totalMinutes = moment.duration(moment(new Date()) - moment(this.state.touchStartTime)).asSeconds();
      if (totalMinutes > 1) {
        return
      }
      // 向左
      if (direction === 3 && this.state.moveLeft) {
        this.setState({
          moveLeft: false
        })
        _this.props.history.goBack()
      }
      // 向右 
      else if (direction === 4 && this.state.moveRight) {
        this.setState({
          moveRight: false
        })
        _this.props.history.goBack()
      }
      }, false);
  }
  getSlideAngle = (dx,dy) => {
    return Math.atan2(dy,dx) * 180 / Math.PI;
  }
  getSlideDirection = (startX,startY, endX, endY) => {
    var dy = startY - endY;
    var dx = endX - startX;
    var result = 0;
    //如果滑动距离太短
    if (Math.abs(dx) < 2 && Math.abs(dy) < 2) {
       return result;
    }
    var angle = this.getSlideAngle(dx, dy);
    if (angle >= -45 && angle < 45) {
       result = 4;
    }else if (angle >= 45 && angle < 135) {
       result = 1;
    }else if (angle >= -135 && angle < -45) {
       result = 2;
    }else if ((angle >= 135 && angle <= 180) || (angle >= -180 && angle < -135)) {
       result = 3;
    }

    return result;

  }

  render(){
      return(
        <div></div>
      )
  }
}
export default touchEvent