import gc from './globalConfiguration'

/**
 * gcj02转墨卡托坐标
 * @param {*} lonlat 
 * @returns 
 */
const lnglatTomercator = (lonlat) => {
    let mercator = { x: 0, y: 0 };
    let x = lonlat.x * 20037508.34 / 180;
    let y = Math.log(Math.tan((90 + lonlat.y) * Math.PI / 360)) / (Math.PI / 180);
    y = y * 20037508.34 / 180;
    mercator.x = x;
    mercator.y = y;
    return mercator;
}

/**
 * 墨卡托坐标转gcj02
 * @param {*} mercator 
 * @returns 
 */
const mercatorTolnglat = (mercator) => {
    let lonlat = { x: 0, y: 0 };
    let x = mercator.x / 20037508.34 * 180;
    let y = mercator.y / 20037508.34 * 180;
    y = 180 / Math.PI * (2 * Math.atan(Math.exp(y * Math.PI / 180)) - Math.PI / 2);
    lonlat.x = x;
    lonlat.y = y;
    return lonlat;
}

/**
 * 计算平面两点直线距离
 * @param {*} start 
 * @param {*} end 
 * @returns 
 */
const getLineDistance = (start, end) => {
    let a = Math.abs(start.x - end.x);
    let b = Math.abs(start.y - end.y);
    return Math.sqrt(a * a + b * b);
}

/**
 * 判断值是否在两数之间
 * @param {*} begin 
 * @param {*} end 
 * @param {*} value 
 * @returns 
 */
const isBetween = (begin, end, value) => {
    if (begin > end) {
        return begin >= value && value >= end;
    } else {
        return begin <= value && value <= end;
    }
}

/**
 * 计算点在与当前线段的距离，如果点在线段附近，则返回此线段还未涉足的部分
 * @param {*} 线段起点 
 * @param {*} 线段终点 
 * @param {*} 第三点 
 * @param {*} 阈值 
 */
const getPointInLine = (start, end, third, tolerance, isdebug = false) => {
    let k = (start.y - end.y) / (start.x - end.x);
    let d = start.y - k * start.x
    let a = k, b = -1, c = d;

    // 点在线上
    if (a * third.x + b + third.y + c === 0) {
        if (isdebug) console.log('点在线上');
        return {
            distance: 0,
            start: third,
            end: end
        }
    }

    // 点不再线上
    // 点到线的距离（可能不在线段上）
    let distance = Math.abs(a * third.x + b * third.y + c) / Math.sqrt(a * a + b * b)
    // 到起点端点距离
    let distance1 = getLineDistance(start, third)
    // 到终点端点距离
    let distance2 = getLineDistance(end, third)
    if (isdebug) console.log(distance, distance1, distance2)

    // // 距离大于阈值，不必进行后续判断
    // if (distance > tolerance) {
    //     console.log('线不在线上，点到线的距离大于预期')
    //     return {
    //         distance: distance
    //     }
    // }

    // 找到投影点
    let k2 = -1 / k;
    let d2 = third.y + ((1 / k) * third.x)
    let a2 = k2, c2 = d2;

    let pp = {
        x: 0,
        y: 0
    }

    pp.x = (c2 - c) / (a - a2);
    pp.y = a2 * pp.x + c2;

    // console.log(pp, distance)
    // console.log(a2, b2, c2)
    // console.log(a2 * third.x + b2 * third.y + c2)
    // console.log(a2 * pp.x + b2 * pp.y + c2)
    // console.log(a * pp.x + b * pp.y + c)
    // console.log(a * start.x + b * start.y + c)

    if (isdebug) console.log(start, pp, end)
    if (isdebug) console.log(mercatorTolnglat(pp))
    // 判断投影点是否在线段上
    if (isBetween(start.x, end.x, pp.x) || isBetween(start.y, end.y, pp.y)) {
        // 在线段上
        if (isdebug) console.log('点在线段上')
        if (distance < tolerance) {
            return {
                distance: distance,
                start: pp,
                end: end
            }
        }
    }
    
    // 不在线段上比端点距离
    distance = distance1 < distance2 ? distance1 : distance2;
    
    if (distance > tolerance) {
        // 距离最近的端点大于阈值
        if (isdebug) console.log('距离最近的端点大于阈值')
        return {
            distance: distance
        }
    }
    if (isdebug) console.log(distance1, distance2)
    if (distance === distance1) {
        return {
            distance: distance,
            start: start,
            end: end
        }
    } else {
        return {
            distance: distance,
            start: undefined,
            end: end
        }
    }



}

// 纬度精度一维数组转经度纬度对象数据
const latLngArrayToLngLatObjectArray = (paths) => {
    let arr = [];
    for (let i = 0; i < paths.length; i += 2) {
        if (i === 0) {
            arr.push({
                lng: paths[i + 1],
                lat: paths[i]
            })
        } else {
            let item = {
                lng: paths[i + 1],
                lat: paths[i]
            };
            let last_item = arr[arr.length - 1];
            if (last_item.lng === item.lng && last_item.lat === item.lat) {
                // 剔除相邻重复坐标
                continue;
            } else {
                arr.push(item);
            }
        }
        
    }
    return arr;
}

// 经度纬度对象数据转纬度精度一维数组
const lngLatObjectArrayToLatLngArray = (paths) => {
    let arr = [];
    for (let i in paths) {
        let item = paths[i];
        arr.push(item.y);
        arr.push(item.x);
    }
    arr = arr.map(a => {
        let n = Number(Number(a).toFixed(6))
        return n;
    })
    return arr;
}

/**
 * 查找是否已经偏航
 * @param {*} path 导航路径
 * @param {*} third 司机位置
 * @param {*} tolerance 容忍范围
 * @param {*} isdebug 
 * @returns 
 */
const getRemainingSegment = (path, third, tolerance, isdebug = false) => {
    if (window.AMap && gc.process.openAMapMatch) {
        return getRemainingSegmentAMap(path, third, tolerance, isdebug)
    }

    tolerance = tolerance / 0.78;
    // 入参路径转经度纬度对象
    path = latLngArrayToLngLatObjectArray(path);
    third = latLngArrayToLngLatObjectArray(third)[0];

    // 入参路径坐标转换为墨卡托投影坐标
    let m_path = path.map(a => lnglatTomercator({
        x: a.lng,
        y: a.lat
    }))

    let m_third = lnglatTomercator({
        x: third.lng,
        y: third.lat
    })

    // 得到一个个线段
    let m_segment = []
    for (let i = 0; i < m_path.length - 1; i++) {
        m_segment.push({
            begin: m_path[i],
            end: m_path[i + 1]
        })
    }

    // 将司机所在点与每一个线段进行比对
    let _m_segment = m_segment.map(a => getPointInLine(a.begin, a.end, m_third, tolerance, isdebug))
    if (isdebug) console.log(_m_segment)
    if (path.length < 2) {
        return {
            code: -1,
            message: '入参格式错误，path应至少有两个点',
        }
    }
    // 找到距离最近的线段
    let min = {index: 0, distance: _m_segment[0].distance}
    for (let i = 1; i < _m_segment.length; i++) {
        let item = _m_segment[i];
        if (min.distance > item.distance) {
            min.index = i;
            min.distance = item.distance;
        }
    }
    if (isdebug) console.log(min)
    if (min.distance > tolerance) {
        // 最近的路径距离大于容忍值，视为偏航
        return {
            code: 1,
            message: '已经偏航',
            distance: min.distance
        }
    }

    // 找到剩余路径
    let _m_remainingSegment = m_path.slice(min.index + 1);
    if (_m_segment[min.index].start) {
        _m_remainingSegment = [_m_segment[min.index].start, ..._m_remainingSegment];
    }
    if (isdebug) console.log(_m_remainingSegment)

    let m_remainingSegment = _m_remainingSegment.map(a => mercatorTolnglat(a))
    if (isdebug) console.log(m_remainingSegment)

    let remainingSegment = lngLatObjectArrayToLatLngArray(m_remainingSegment);
    if (remainingSegment.length === 2) {
        remainingSegment = [...remainingSegment, ...remainingSegment];
    }

    // 找到已完成分段
    let _m_completeSegment = m_path.slice(0, min.index + 1);
    let _m_completeSegmentRightPoint = _m_segment[min.index].start ? _m_segment[min.index].start : _m_segment[min.index].end;
    if (_m_completeSegment.length < 2 
        || _m_completeSegment[_m_completeSegment.length - 1].x !== _m_completeSegmentRightPoint.x
        || _m_completeSegment[_m_completeSegment.length - 1].y !== _m_completeSegmentRightPoint.y) {
           
        _m_completeSegment.push(_m_completeSegmentRightPoint)
    }
    let m_completeSegment = _m_completeSegment.map(a => mercatorTolnglat(a))
    let completeSegment = lngLatObjectArrayToLatLngArray(m_completeSegment);
    if (completeSegment.length === 2) {
        completeSegment = [...completeSegment, ...completeSegment];
    }

    return {
        code: 0,
        message: '',
        distance: min.distance,
        path: remainingSegment,
        completePath: completeSegment
    }
}

/**
 * 计算路径总长度
 * @param {*} paths 
 */
const totalDistance = (paths) => {
    if (window.AMap && gc.process.openAMapMatch) {
        return totalDistanceAMap(paths)
    }

    // 0. 把一维数组转成xy结构坐标点数组
    let _paths = []
    for (let i = 0; i < paths.length; i += 2) {
        _paths.push({
            x: Number(paths[i + 1]),
            y: Number(paths[i])
        })
    }

    // 1. 墨卡托变换，把gjc02坐标点转成米制平面坐标点
    let _tomercatorPoint = _paths.map(a => lnglatTomercator(a))

    // 2.计算两点之间的距离
    let total = 0;
    for (let i = 0; i < _tomercatorPoint.length - 1; i++) {
        total += getLineDistance(_tomercatorPoint[i], _tomercatorPoint[i + 1])
    }
    
    return Math.round(total * 0.8)
}

const getRemainingSegmentAMap = (path, third, tolerance, isdebug = false) => {
    let AMap = window.AMap
    let amap_path = []
    for (let i = 0; i < path.length; i+= 2) {
        amap_path.push([path[i + 1], path[i]])
    }
    let amap_pos = [third[1], third[0]]

    let closestPositionOnLine = AMap.GeometryUtil.closestOnLine(amap_pos, amap_path);
    let distance = AMap.GeometryUtil.distanceToLine(amap_pos, amap_path);

    if (distance > tolerance) {
        return {
            code: 1,
            message: '已经偏航',
            distance: distance
        }
    }

    let remainingSegment = []
    let completeSegment = []

    for (let i = 0; i < amap_path.length - 1; i++) {
        var res = AMap.GeometryUtil.isPointOnSegment(closestPositionOnLine, [amap_path[i], amap_path[i + 1]], 0)
        
        if (isdebug) {
            console.log(res, res ? [amap_path[i], amap_path[i + 1]] : 0)
        }
        if (res) {
            // 点在线段上
            // 当前段左点放入完成序列
            completeSegment.push(amap_path[i][1])
            completeSegment.push(amap_path[i][0])
            // 当前点也放入完成序列
            completeSegment.push(closestPositionOnLine[1])
            completeSegment.push(closestPositionOnLine[0])

            // 当前点放入未完成序列
            remainingSegment.push(closestPositionOnLine[1])
            remainingSegment.push(closestPositionOnLine[0])
            // 剩余点放入未完成队列
            for (i++; i < amap_path.length; i++) {
                remainingSegment.push(amap_path[i][1])
                remainingSegment.push(amap_path[i][0])
            }

        } else {
            // 不在线段上，视作已完成路径
            completeSegment.push(amap_path[i][1])
            completeSegment.push(amap_path[i][0])

        }
        
        
    }

     
    return {
        code: 0,
        message: '',
        distance: distance,
        path: remainingSegment,
        completePath: completeSegment
    }
}

const totalDistanceAMap = (path) => {
    let AMap = window.AMap

    let amap_path = []
    for (let i = 0; i < path.length; i+= 2) {
        amap_path.push([path[i + 1], path[i]])
    }

    return AMap.GeometryUtil.distanceOfLine(amap_path);
}

const getRemainingPath = (path, third) => {
    let AMap = window.AMap
    if (!AMap || !gc.process.openAMapMatch) {
        return getRemainingSegment(path, third, 10, false)
    }

    let amap_path = []
    for (let i = 0; i < path.length; i+= 2) {
        amap_path.push([path[i + 1], path[i]])
    }
    let amap_pos = [third[1], third[0]]

    let remainingSegment = []

    for (let i = 0; i < amap_path.length - 1; i++) {
        var res = AMap.GeometryUtil.isPointOnSegment(amap_pos, [amap_path[i], amap_path[i + 1]], 0)
        if (res) {
            // 点在当前下段中，即可认为点在i和i+1坐标之间，则未完成路径点从[当前点,i+1,i+2...开始，因此step=1
            let step = 1;
            if (i < amap_path.length - 2 && AMap.GeometryUtil.isPointOnSegment(amap_pos, [amap_path[i + 1], amap_path[i + 2]], 0)) {
                // 如果点在当前线段同时也在下一个线段上，则认为点出现在下一个线段上，因此统计未完成路径时候，应该从下一个线段开始
                // 即未完成路径从[当前点,i+2,i+3...开始，因此step=2
                step++;
                
            }
            remainingSegment.push(amap_pos[1])
            remainingSegment.push(amap_pos[0])
            for (i += step; i < amap_path.length; i++) {
                remainingSegment.push(amap_path[i][1])
                remainingSegment.push(amap_path[i][0])
            }
        }
    }

    
    
    if (remainingSegment && remainingSegment.length) {
        return {
            code: 0,
            path: remainingSegment
        }
    } else {
        return {
            code: 1,
            message: '点不在剩余路径上',
            path: []
        }
    }
    
}

export {
    getRemainingSegment,
    totalDistance,
    getRemainingPath
}