import React from "react";
import {inject, observer } from 'mobx-react'
import style from './noneDriverReply.scss'
import images from './../../util/images'

import moment from 'moment'

import { Button, Image } from 'antd-mobile'

import { customerServiceTelephone } from './../../util/config'

@inject('mainStore')
@observer
class NoneDriverReply extends React.Component {
  
  state = {

  }

  t = this.props.t

  mainStore = this.props.mainStore

  get monthDayEn() {
    let month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][moment(this.props.departTime ? this.props.departTime : '').month()]
    let day = moment(this.props.departTime ? this.props.departTime : '').format('DD').replace('0', '')
    return {
      month:month,
      day: day
    }
  }

  render() {
    return (
      <div className={style.none}>
        <div className={style.message_box}>
          {/* k098 很抱歉，由于附近车型不足，暂无司机应答 */}
          <div className={style.message}>{this.t('runing.k098')}</div>
          {/* k099 客服将于您联系，建议您重新叫车 */}
          <div className={style.suggestion}>{this.t('runing.k099')}</div>
          <div className={style.box_time}>
            <Image
              src={images.waitForTime}
              width={17}
              height={17}
              fit='cover'
              style={{ borderRadius: 32 }}
            />
            {/* k096 出发 */}
            <span className={style.waitForTime}>{this.t('runing.k096', {timeZh:moment(this.props.departTime).format('Y年M月DD日 HH:mm'), hourEn:moment(this.props.departTime).format('HH:mm'), timeEn: this.monthDayEn.month + ' ' + this.monthDayEn.day + 'th'})}</span>
          </div>
          <div className={style.box_time}>
            <Image
              src={images.smallBell}
              width={20}
              height={20}
              fit='cover'
            />
            {/* k0100 若有任何疑问请拨打服务热线： */}
            <span className={style.bell}>{this.t('runing.k0100')}<span className={style.servicePhone} onClick={() => {
              window.top.location.href="tel://" + customerServiceTelephone
            }}>{customerServiceTelephone}</span></span>
          </div>
        </div>
        {/* k0101 重新叫车 */}
        <Button block color='primary' size='large' onClick={() => {
          this.props.history.replace('/');
        }}>
            {this.t('runing.k0101')}
        </Button>
      </div>
    )
  }
}

export default NoneDriverReply