import React from "react";
import style from './promotion.scss'

import { Mask } from 'antd-mobile'
import { CloseCircleOutline } from 'antd-mobile-icons'

import promotionHelper from '../../util/promotion'

import images from "../../util/images";
import webService from "../../util/webService";

import { dge } from '../../util/utils'

class Promotion extends React.Component {
  
  state = {
    imageSrc: "",
    isShow: false,
    jdPromotion: {}
  }

  get jd_scene () {
    if (['1045'].includes(localStorage.getItem('jd_scene'))) {
      return 10
    } 
    else if (['5006'].includes(localStorage.getItem('jd_scene'))) {
      return 11
    }
    return 0
  }

  render() {
    return <>
      <Mask opacity={0.2} visible={this.state.isShow} onMaskClick={this.close}>
        <div className={style.activityContent}>
          {
            (() => {
              if (this.jd_scene === 11 && this.state.jdPromotion.isShowJDStudentImage) {
                return (
                  <div>
                    <div className={style.box}>
                      <img alt="" src={this.state.imageSrc} />
                      {
                        !this.state.jdPromotion.isJDStudent ? 
                        <div className={style.authentication}>
                          <div onClick={() => {
                            this.setState({
                              isShow: false
                            })
                            window.jd.miniProgram.navigateTo({url: `/pages/middle/index?type=0`});
                          }}>
                            <img src={images.authentication_btn} alt="" />    
                          </div>
                        </div> : <></>
                      }
                    </div>
                    <div className={style.close}>
                      <CloseCircleOutline fontSize={25} onClick={this.close}/>
                    </div>
                  </div>
                )
              }
              if (this.jd_scene !== 11 && !this.state.jdPromotion.isShowJDStudentImage) {
                return (
                  <div>
                    <div className={style.box}>
                      <img alt="" src={this.state.imageSrc} />
                    </div>
                    <div className={style.close}>
                      <CloseCircleOutline fontSize={25} onClick={this.close}/>
                    </div>
                  </div>
                )
              }
            })()
          }
        </div>
      </Mask>
    </>
  }

  componentDidMount = async () => {
    // 如果是京东小程序则刷
    document.addEventListener("visibilitychange", async () => {
      if (document.visibilityState === 'visible') {
        console.log('jd_scene', this.jd_scene)
        if(dge() === 'jd-mini' && this.jd_scene === 11){
          await webService.user.getJdStudents();
          await this.loadActivitie()
          this.props.refreshBadge()
        }
      }
    });

    if(dge() === 'jd-mini' && this.jd_scene === 11){
      await webService.user.getJdStudents();
    }

    await this.loadActivitie()

    // // 查询活动
    // webService.activity.getActivity().then(res => {
    //   if(res.data.code === 0 && res.data.data.list.length > 0) {
    //     let result = res.data.data.list
    //     result.forEach(item => {
    //       // 查询活动优惠
    //       webService.activity.getActivityDiscounts(item.url).then(res => {
    //         if (res.data.code === 0 && res.data.data.imgUrl) {
    //           this.setState({
    //             imageSrc: webBaseUrl + res.data.data.imgUrl
    //           }, () => {
    //             setTimeout(() => {
    //               this.props.showActivity()
    //             }, 200)
    //           })
    //         }
    //       })
    //     });
    //   }
    // })
  }

  close = () => {
    this.setState({
      isShow: false
    })
  }

  loadActivitie = async () => {
    let list = await promotionHelper.load({promotionScene: this.jd_scene});
    let imgUrl = '';
    let jdPromotion = {}
    if (list && list.length && this.jd_scene !== 11) {
      imgUrl = await promotionHelper.exec(list[0]);
    } else {
      imgUrl = list && list[0]?.jdPromotion?.imageUrl;
      jdPromotion = list && list[0]?.jdPromotion;
    }
    if (imgUrl && this.jd_scene !== 11) {
      this.setState({
        imageSrc: imgUrl,
        isShow: true
      })
    }
    if (jdPromotion?.isShowJDStudentImage) {
      this.setState({
        imageSrc: imgUrl,
        isShow: true,
        jdPromotion: jdPromotion
      })
    }
  }

}

export default Promotion