import { Dialog } from 'antd-mobile'

import webService from "../../../util/webService"
import loger from './../../../util/loger'

/**
 * 验证起点终点是否包含敏感字
 * @param {*} data 
 * @returns 
 */
const checkSensitiveWords = async (d) => {
    return new Promise(async (ok, fail) => {
        let {data} = await webService.order.checkSensitiveWords(d)
        loger.debug('checkSensitiveWords response', data)

        if (data && data.code && data.data && data.data.violation && data.data.violation.length) {
            // 存在违规敏感词
            Dialog.alert({
                content: data.message,
                onClose: () => {
                    ok([...data.data.violation])
                }
            })
        } else {
            ok()
        }
    })
    
}

export {
    checkSensitiveWords
}
