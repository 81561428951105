import React from 'react';
import style from './loadingMask.scss';
import PropTypes from 'prop-types'

import { Mask, Loading, SpinLoading } from 'antd-mobile'

class LoadingMask extends React.Component {

    state = {
        show: true
    }

    render() {
        return <Mask color='white'
            opacity={this.props.opacityNum}
            visible={this.state.show}>
              {
                (() => {
                  if(this.props.loadingType === 'dot' ) {
                    return(
                      <span style={{ fontSize: 24 }} >
                      <Loading className={style.loading}  />
                      </span>
                    )
                  } else if (this.props.loadingType === 'spin') {
                    return(
                      <SpinLoading className={this.props.isPopup ? style.loading : style.pop_loading} style={{ '--size': '32px' }} />
                    )
                  } else if (this.props.loadingType === 'move') {
                    return(
                      <span style={{ fontSize: 30 }} >
                        <SpinLoading className={style.loading_move} />
                      </span>
                    )
                  }
                })()
              }
        </Mask>
    }

    componentDidMount() {
        this.setState({
            show: this.props.show
        })
    }

    componentWillReceiveProps(props) {
        this.setState({
            show: props.show
        })
    }
}

LoadingMask.propTypes = {
  opacityNum: PropTypes.number,
  loadingType: PropTypes.string,
  isPopup: PropTypes.bool
}

LoadingMask.defaultProps = {
  opacityNum: 1,
  loadingType: 'dot',
  isPopup: true

}
export default LoadingMask;
