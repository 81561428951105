import moment from 'moment'
/**
 * 构造预估价格参数
 * @param {*} serviceType 
 * @param {*} scene 
 * @param {*} beginAddr 
 * @param {*} endAddr 
 * @param {*} userInfo
 * @param {*} preApply
 * @param {*} charteredBusType
 * @param {*} pathway 途径点
 * @returns 
 */
const generateEstimate = (serviceType, scene, beginAddr, endAddr, userInfo, preApply, charteredBusType, pathway) => {
    let res = {
        departLat: beginAddr.lat + '',
        departLng: beginAddr.lon + '',
        departPoi: beginAddr.poi + '',
        destLat: endAddr.lat + '',
        destLng: endAddr.lon + '',
        destPoi: endAddr.poi + '',
        serviceType: serviceType,
        departCityCode: beginAddr.cityCode,
        departCityName: beginAddr.cityName,
        destCityCode: endAddr.cityCode,
        destCityName: endAddr.cityName,
        pickupLocationName: beginAddr.name,
        pickupLocation: beginAddr.address || beginAddr.name,
        destLocationName: endAddr.name,
        destLocation: endAddr.address || endAddr.name,
        departTime: beginAddr.departureTime,
        flightNumber: beginAddr.flightNumber,
        flightArrivalTime: beginAddr.arrivalTime,
        flightDepartTime: beginAddr.departTime,
        flightArrivalAirportCode: beginAddr.arrivalAirportCode,
        flightDepartAirportCode: beginAddr.departAirportCode,
        trainNumber: beginAddr.trainNumber,
        trainArr: beginAddr.trainArr,
        trainDep: beginAddr.trainDep,
        arrivalTime: beginAddr.arrTime,
        sceneId: scene.id,
        scenePublishId: scene.publishId,
        sceneNameCn: scene.nameCn,
        sceneNameEn: scene.nameEn,
        passengerPhone: userInfo ? userInfo.phone : '',
        preDepartApplyId: preApply ? preApply.preDepartApplyId : '',
        charteredBusType: charteredBusType,
        passingPoints: pathway
    };

    switch(serviceType) {
        case 1:
            {
                res.departTime = moment().format('YYYY-MM-DD HH:mm:ss');
                break;
            }
        case 2:
            {
                res.departTime = moment(res.departTime, 'YYYY年MM月DD日 HH:mm').format('YYYY-MM-DD HH:mm:ss');
                break;
            }
        case 20:
          {
              res.departTime = moment(res.departTime, 'YYYY年MM月DD日 HH:mm').format('YYYY-MM-DD HH:mm:ss');
              break;
          }
        default:
            break;
    }
    
    return res;
}

/**
 * 构造确认页面可选项参数
 * @param {*} serviceType 
 * @param {*} scene 
 * @param {*} beginAddr 
 * @param {*} endAddr 
 * @param {*} choosedEstimates 
 * @param {*} preApply
 * @param {*} charteredBusType
 * @param {*} pathways
 * @returns 
 */
const generateConfirmDependency = (serviceType, scene, beginAddr, endAddr, choosedEstimates, preApply, charteredBusType, pathways) => {
    let res = generateEstimate(serviceType, scene, beginAddr, endAddr, undefined, preApply, charteredBusType, pathways);
    let cars = choosedEstimates.map(item => {
        return {
            carLevel: item.carLevel,
            carSourceId: item.carSourceId,
            estimatePrice: item.estimatePrice,
            dynamicCode: item.dynamicCode,
            carLevelName: item.carLevelName,
            couponAmount: item.couponAmount,
            couponId: item.couponId,
            labelCode: item?.labelCode ? item?.labelCode : '',
            subCarType: item?.subCarType ? item?.subCarType : ''
        }
    });

    res.cars = cars;

    return res;
}

/**
 * 构造下单参数
 * @param {*} serviceType 
 * @param {*} scene 
 * @param {*} beginAddr 
 * @param {*} endAddr 
 * @param {*} choosedEstimates 
 * @param {*} passenger 
 * @param {*} project 
 * @param {*} coupon 
 * @param {*} extraServices 
 * @param {*} extraParameter 
 * @param {*} estimateId 
 * @param {*} accountId 
 * @param {*} customCarList 
 * @param {*} preApply
 * @param {*} isTickedGreenTravel  绿色出行标记给中台下单逻辑使用
 * @param {*} charteredBusType
 * @param {*} carMode
 * @param {*} pathways
 * @param {*} projectCode
 * @param {*} baseLimitationConfirm
 * @param {*} sanofiMeeting 用于sanofi会议，可不传
 * @returns 
 */
const generatePlaceOrder = (serviceType, scene, beginAddr, endAddr, choosedEstimates,
    passenger, project, coupon, extraServices, extraParameter, estimateId,
    accountId, customCarList, preApply, isTickedGreenTravel, charteredBusType, carMode, pathways, projectCode, 
    baseLimitationConfirm, sanofiMeeting, jinDuInfo) => {
    let res = generateConfirmDependency(serviceType, scene, beginAddr, endAddr, choosedEstimates, preApply, charteredBusType, pathways);
    res.passengerName = passenger.name;
    res.passengerPhone = passenger.phone;
    res.projectId = project.id;
    res.couponId = coupon ? coupon.id : undefined;
    res.extraServices = extraServices.filter(a => a.checked).map(item => {
        return {
            id: item.id,
            code: item.code
        }
    })

    let customCarInfo = customCarList.map(item => {
      return {
        value:item.type === 'checkList' || item.type === 'radio' ? item.labelValue : item.value, 
        name: item.title,
        type: item.type,
        required: item.rules[0].required
      }
    })
    res.extraParameter = {
      userCarReason: extraParameter.usedReason,
      customInfo: {
        customCarInfo: customCarInfo,
        isTickedGreenTravel: isTickedGreenTravel,
        carMode: carMode,
        projectCode: projectCode,
        costCenterCode: jinDuInfo?.costCenterCode,
        approver: jinDuInfo?.staffCode,
        caseCode: jinDuInfo?.caseCode,
        companyCode: jinDuInfo?.companyCode,
        sourceStaffCode: jinDuInfo?.sourceStaffCode
      }
    };
    res.estimateId = estimateId;
    res.accountId = accountId;

    // sanofi会议id
    res.meetingId = sanofiMeeting ? sanofiMeeting.id : undefined;

    // res.baseLimitationConfirm = baseLimitationConfirm;
    if (baseLimitationConfirm) {
        res.isUserPay = baseLimitationConfirm.isUserPay;
        res.isNotUserPayReason = baseLimitationConfirm.text || baseLimitationConfirm.label;
    }
    

    return res;
}

/**
 * 订单状态码转中文
 * @param {*} state 
 */
const stateToCn = (state, t) => {
    switch(state) {
        case 1:
            return t('main.k0106')
        case 2:
            return t('main.k0114')
        case 3:
            return t('main.k0115')
        case 4:
            return t('main.k0116')
        case 5:
            return t('main.k0117')
        case 6:
            return t('main.k0118')
        case 7:
            return t('main.k0119')
        default:
            return t('main.k0117')
    }
}

/**
 * 状态码转进行中页面中文标题
 * @param {*} state 
 * @param {*} isWsPush 
 * @returns 
 */
 const stateToRuningTitleCn = (state, isWsPush, t, isBookingFail = false, serviceType, isCharterCarCancel) => {
    // 不是包车页面标题
    if (serviceType !== 20) {
      switch(state) {
          case 1:
              return isBookingFail ? t('runing.k0105') : t('main.k0106')
          case 2:
              return t('main.k0107')
          case 3:
              return t('main.k0108')
          case 4:
              return t('main.k0109')
          case 5:
              return t('main.k0110')
          case 6:
              return t('main.k0111')
          case 7:
              return (isWsPush && !isBookingFail) ? t('main.k0112') : isBookingFail ? t('runing.k0104') : t('main.k0113')
          default:
              return t('main.k0105')
      }
    } else {
      // 包车页面标题
      switch(state) {
        case 1:
            return isCharterCarCancel ? '取消中' : '您的订单已发起预约'
        case 2:
            return '进行中'
        case 3:
            return '进行中'
        case 4:
            return '进行中'
        case 5:
            return '已完成'
        case 6:
            return '已完成'
        case 7:
            return '已取消'
        default:
            return t('main.k0105')
      }
    }
}

/**
 * 接机状态码转中文
 * @param {*} state 
 */
const airportPickupToCn = (state, t) => {
    switch(state) {
        case 0:
            return t('main.k0101')
        case 1:
            return t('main.k0102')
        case 2:
            return t('main.k0103')
        case 3:
            return t('main.k0104')
        default:
            return t('main.k0105')
    }
}

/**
 * 订单审核状态码
 * @param {*} state 
 */
 const orderStateToCn = (state, t) => {
  switch(state) {
      case 1:
          return t('approval.k037')
      case 2:
          return t('approval.k038')
      case 4:
          return t('approval.k039')
      default:
          return t('approval.k040')
  }
}

/**
 * 服务类型
 * @param {*} state 
 */

const serviceStateToCn = (state, t, charteredBusType) => {
  switch(state) {
    case 1:
        return t('orders.k008')
    case 2:
        return t('orders.k009')
    case 6:
        return t('orders.k010')
    case 7:
        return t('orders.k025')
    case 20:
        // 半日包 全日
        return !charteredBusType ? t('orders.k031') : charteredBusType === 1 ? t('runing.k0122') : t('runing.k0123')     
    default:
        return t('orders.k024')
  }
}

/**
 * 发票类型
 * @param {*} state 
 */
 const invoiceStateToCn = (state, t) => {
  switch(state) {
    case 1:
        return t('writeInvoice.k016')
    default:
        return "";
  }
}

export {
    generateEstimate,
    generateConfirmDependency,
    generatePlaceOrder,

    orderStateToCn,
    serviceStateToCn,
    invoiceStateToCn,

    stateToCn,
    stateToRuningTitleCn,
    airportPickupToCn
};

