import React from 'react';
import style from './perApplyRecord.scss';

import { Picker, InfiniteScroll, Dialog, Toast, Loading } from 'antd-mobile'

import ApplyRecord from './../component/perApproval/applyRecord'

import {
    UpOutline
} from 'antd-mobile-icons'

import webService from './../util/webService'
import images from './../util/images'

import { withTranslation } from "react-i18next";

/**
 * 前置审批-申请记录
 */
class PerApplyRecord extends React.Component {

    state = {
        approveState: -1,
        approvalState: -1,

        pageData: {
            list: [],
            pageNum: 1,
            pageSize: 10,
            total: 0
        },
        visible: false,
        visible1: false,
        dataLoadProcess: 'init' // loading // complete
    }

    t = this.props.t

    applyStates = [[{label: this.props.t('perApplyRecord.k001'), value: -1}, {label: this.props.t('perApplyRecord.k002'), value: 0}, {label: this.props.t('perApplyRecord.k003'), value: 1}, {label: this.props.t('perApplyRecord.k004'), value: 2}, {label: this.props.t('perApplyRecord.k005'), value: 3}]];
    approvalStates = [[{label: this.props.t('perApplyRecord.k001'), value: -1}, {label: this.props.t('perApplyRecord.k006'), value: 0}, {label: this.props.t('perApplyRecord.k007'), value: 1}, {label: this.props.t('perApplyRecord.k008'), value: 2}, {label: this.props.t('perApplyRecord.k009'), value: 3}]];

    get approveState() {
        let arr = this.applyStates[0].filter(a => a.value === this.state.approveState);
        return arr.length ? arr[0].label : this.props.t('perApplyRecord.k001');
    }

    get approvalState() {
        let arr = this.approvalStates[0].filter(a => a.value === this.state.approvalState);
        return arr.length ? arr[0].label : this.props.t('perApplyRecord.k001');
    }

    get listHashMore() {
        let pageData = this.state.pageData;
        return !(Number(pageData.total) !== 0 && Number(pageData.total) === pageData.list.length)
    }

    /**
     * 滚动文案
     */
    renderScrollContent = () => {
      return (
        <>
          {this.state.hasMore ? (
            <>
              <div className={style.loadingbox}>
                <div className={style.loading}>{this.t('approval.k036')}</div>
                <Loading />
              </div>
            </>
          ) : (
            <div className={style.msg}>{this.t('approval.k020')}</div>
          )}
        </>
      )
    }

    renderList() {
        return <>
            {
                this.state.pageData.list.map(item => (
                    <ApplyRecord t={this.props.t} history={this.props.history} data={item} key={item.preDepartApplyId} showApproveStep={true} showReApply={item.resubmit} />
                ))
            }
            <InfiniteScroll loadMore={this.load} hasMore={this.listHashMore} threshold={10}>
              {this.renderScrollContent()}
            </InfiniteScroll>
        </>
    }

    renderEmpty() {
        return <div className={style.nodata}>
            <div className={style.box}>
            <img alt="" src={images.reportnodata}></img>
            <span>{this.t('perApplyRecord.k010')}</span>
            </div>
        </div>
    }

    render() {
        return <div className={style.container}>
            <div className={style.body}>
                {
                    this.state.dataLoadProcess === 'complete'
                        ? this.state.pageData.list.length
                            ? this.renderList()
                            : this.renderEmpty()
                        : <></>
                    
                }
                
            </div>
            <div className={style.foot}>
                <div onClick={() => this.setState({visible:true})}>{this.t('perApplyRecord.k011')}{this.approveState} <UpOutline /></div>
                <div onClick={() => this.setState({visible1: true})}>{this.t('perApplyRecord.k012')}{this.approvalState} <UpOutline /></div>
            </div>
            <Picker 
              cancelText={this.t('common.k001')} 
              confirmText={this.t('common.k002')} 
              columns={this.applyStates} 
              visible={this.state.visible} 
              value={[this.state.approveState]} 
              onConfirm={(value) => {
              if (!value || value.length === 0) {
                  return
              }
              this.setState({
                  approveState: value[0],
                  pageData: {
                      ...this.state.pageData,
                      list: [],
                      pageNum: 1,
                      total: 0
                  }
              }, () => {
                  this.load()
                  this.setState({
                    visible:false
                  })
              })
              }} onClose={() => {
                this.setState({
                  visible:false
                })
              }}>
            </Picker>
            <Picker 
              cancelText={this.t('common.k001')} 
              confirmText={this.t('common.k002')} 
              columns={this.approvalStates} 
              visible={this.state.visible1} 
              value={[this.state.approvalState]} 
              onConfirm={(value) => {
            if (!value || value.length === 0) {
                return
            }
            this.setState({
              approvalState: value[0],
              pageData: {
                  ...this.state.pageData,
                  list: [],
                  pageNum: 1,
                  total: 0
                }
            }, () => {
                this.load()
                this.setState({
                  visible1:false
                })
            })
            }} onClose={() => {
              this.setState({
                visible1:false
              })
            }}>
          </Picker>
        </div>
    }

    componentDidMount() {
        this.load();
    }

    componentWillUnmount() {

      this.setState({
        visible: false,
        visible1: false
      })

    }

    onUseStateClick = async () => {
        let value = await Picker.prompt({
            columns: this.applyStates,
            visible: this.state.visible,
            value: [this.state.approveState]
        })
        if (!value || value.length === 0) {
            return
        }
        this.setState({
            approveState: value[0],
            pageData: {
                ...this.state.pageData,
                list: [],
                pageNum: 1,
                total: 0
            }
        }, () => {
            this.load()
        })
    }

    onApplyStateClick = async () => {
        let value = await Picker.prompt({
            columns: this.approvalStates,
            visible: this.state.visible,
            value: [this.state.approvalState]
        })
        this.setState({
            approvalState: value[0],
            pageData: {
                ...this.state.pageData,
                list: [],
                pageNum: 1,
                total: 0
            }
        }, () => {
            this.load()
        })
    }

    load = async () => {
        let pageData = this.state.pageData;
        if (Number(pageData.total) !== 0 && Number(pageData.total) === pageData.list.length) {
            // total非0，表示已经加载过数据，total与list的长度相同，则表示已经加载过最后一页
            return;
        }

        let params = {
            applyState: this.state.approveState === -1 ? undefined : this.state.approveState,
            approvalState: this.state.approvalState === -1 ? undefined : this.state.approvalState,

            pageNum: pageData.pageNum,
            pageSize: pageData.pageSize
        };
        
        Toast.show({
            icon: 'loading',
            content: this.t('perApplyRecord.k014'),
            duration: 0,
            maskClickable: false
        })
        let { data } = await webService.preApproval.queryPreDepartApplyList(params);
        await this.setState({
            dataLoadProcess: 'complete'
        })
        Toast.clear();
        if (data.code === 0) {
            if (data.data && data.data.rows && data.data.rows.length) {
                this.setState({
                    pageData: {
                        ...this.state.pageData,
                        list: [...this.state.pageData.list, ...data.data.rows],
                        total: data.data.totalCounts,
                        pageNum: data.data.currentPage + 1
                    }
                })
            } else {
              await this.setState({
                dataLoadProcess: 'loading'
              })
            }
        } else {
          await this.setState({
            dataLoadProcess: 'loading'
          })
            Dialog.alert({
                content: data.message || this.t('perApplyRecord.k013'),
                confirmText: this.t('common.k003')
            })
        }
    }
}

export default withTranslation()(PerApplyRecord)