import React from 'react'
import style from './report.scss'

import { RightOutline, CheckOutline } from 'antd-mobile-icons'
import { DatePicker, Card, Button, Toast, Empty, CascadePicker } from 'antd-mobile'

import ReactEcharts from 'echarts-for-react';

import moment from 'moment'

import images from './../util/images'

import webService from '../util/webService'

import { withTranslation } from "react-i18next";

class Report extends React.Component {

  state = {
    // 近6天
    reportStartTime: moment().subtract(6, 'days').format('YYYY-MM-DD'),
    // 当天
    reportEndTime : moment().format('YYYY-MM-DD'),

    // 控制开始日期弹窗显隐
    startVisible: false,

    // 控制结束日期弹窗显隐
    endVisible: false,

    // 订单量及消费金额配置
    orderOption: {},

    // 用车场景的单量配置
    sceneOrderOption: {},
    
    // 用车场景金额分布配置
    sceneAmountOption: {},

    // 车型及用单量配置
    carOrderOption: {},

    // 车型及金额配置
    carAmountOption: {},

    // 订单量及消费金额数据
    orderData: [],

    // 用车场景的单量及金额数据
    sceneAmountData: [],

    // 车型及用单量金额数据
    carOrderAmountData: [],

    // 订单量及消费金额用车场景筛选使用
    orderSceneList: [],
    orderSceneValue: [],
    orderSceneLabel: this.props.t('report.k001'),

    // 订单量及消费金额城市筛选使用
    orderCityList: [],
    orderCityValue: [],
    orderCityLabel: this.props.t('report.k002'),

    // 订单量及消费金额用车筛选使用
    ordrCarList: [],
    orderCarValue: [],
    orderCarLabel: this.props.t('report.k003'),

    // 用车场景单量金额用车场景筛选使用
    sceneQtyList: [],
    sceneQtyValue: [],
    sceneQtyLabel: this.props.t('report.k001'),

    // 用车场景单量金额城市筛选使用
    sceneQtyCityList: [],
    sceneQtyCityValue: [],
    sceneQtyCityLabel: this.props.t('report.k002'),

    // 用车场景单量金额车型筛选使用
    sceneQtyCarList: [],
    sceneQtyCarValue: [],
    sceneQtyCarLabel: this.props.t('report.k003'),
    
    // 车型单量及金额场景筛选使用
    carQtySceneList: [],
    carQtySceneValue: [],
    carQtySceneLabel: this.props.t('report.k001'),

    // 车型单量及金额城市筛选使用
    carQtyCityList: [],
    carQtyCityValue: [],
    carQtyCityLabel: this.props.t('report.k002'),

    // 车型单量及金额车型筛选使用
    carQtyCarList: [],
    carQtyCarValue: [],
    carQtyCarLabel: this.props.t('report.k003'),

    // 订单量及消费金额入参
    order: {
      sceneId: null,
      cityCode: null,
      carLevels: null,
    },
    
    // 用车场景单量及金额入参
    sceneQty: {
      sceneId: null,
      cityCode: null,
      carLevels: null,
    },

    // 车型单量及金额入参
    carQty: {
      sceneId: null,
      cityCode: null,
      carLevels: null,
    },
    
    // 控制图表数据展示
    isOrderEcharts: false,
    // 控制图表数据展示
    isSceneAmountEcharts: false,
    // 控制图表数据展示
    isCarOrderAmountEcharts: false
  }

  echarts_order_instance = undefined
  echarts_order_amount = undefined
  echarts_car_order_instance = undefined
  echarts_car_amount_instance = undefined

  t = this.props.t

  /**
   *
   *日期部分
   */
  renderHeaderTime = () => {
    return(
      <div className={style.header}>
        <div className={style.start} onClick={() => {
          this.setState({
            startVisible: true
          })
        }}>
            <span className={style.time}>{this.state.reportStartTime}</span>
            <span className={style.statistics}>{this.t('report.k004')}</span>
            <RightOutline fontSize={13} color="#c9c9ce" className={style.icon}></RightOutline>
        </div>
        <div className={style.end} onClick={() => {
          this.setState({
            endVisible: true
          })
        }}>
            <span className={style.time}>{this.state.reportEndTime}</span>
            <span className={style.statistics}>{this.t('report.k005')}</span>
            <RightOutline fontSize={13} color="#c9c9ce" className={style.icon}></RightOutline>
        </div>
      </div>
    )
  }

  /**
   *
   *开始日期弹窗
   */
  renderStartDatePicker = () => {
    return(
      <DatePicker defaultValue={new Date()} visible={this.state.startVisible} onClose={()=> {
        this.setState({
          startVisible: false
        })
      }} max={new Date()} min={new Date(moment().add(-4, 'Y'))} onConfirm={val => {
        this.resetStartTime(val)
      }}></DatePicker>
    )
  }

  /**
   *
   *结束日期弹窗
   */
  renderEndDatePicker = () => {
    return(
      <DatePicker defaultValue={new Date()} visible={this.state.endVisible} onClose={()=> {
        this.setState({
          endVisible: false
        })
      }} max={new Date()} min={new Date(moment().add(-4, 'Y'))} onConfirm={val => {
        this.resetEndTime(val)
      }}></DatePicker>
    )
  }

  /**
   * 
   * 订单量及消费金额图表 
   */
  renderOrderPriceEcharts = () => {
    return(
        <div className={style.card}>
          <Card title={this.t('report.k006')}>
            <div className={style.operation}>
              <Button
                onClick={() => {
                  // 场景弹窗
                  CascadePicker.prompt({
                    options: this.state.orderSceneList,
                    defaultValue: this.state.orderSceneValue
                  }).then((value) => {
                    if (value) {
                      this.state.orderSceneList.forEach(item => {
                        if (value[0] === item.value) {
                          this.setState({
                            orderSceneLabel: item.label === this.t('report.k007') ?  this.t('report.k001') : item.label
                          })
                        }
                      })
                      this.screenOrderSceneData(value)
                    }
                  })
                }}
              >
                {this.state.orderSceneLabel} <CheckOutline color="#a9a9a9" />
              </Button>
              <Button
                onClick={() => {
                  // 城市弹窗
                  CascadePicker.prompt({
                    options: this.state.orderCityList,
                    defaultValue: this.state.orderCityValue
                  }).then((value) => {
                    if (value) {
                      this.state.orderCityList.forEach(item => {
                        if (value[0] === item.value) {
                          this.setState({
                            orderCityLabel: item.label === this.t('report.k007') ? this.t('report.k002') : item.label
                          })
                        }
                      })
                      this.screenOrderCityData(value)
                    }
                  })
                }}
              >
                {this.state.orderCityLabel} <CheckOutline color="#a9a9a9" />
              </Button>
              <Button
                onClick={() => {
                  // 车型弹窗
                  CascadePicker.prompt({
                    options: this.state.ordrCarList,
                    defaultValue: this.state.orderCarValue
                  }).then((value) => {
                    if (value) {
                      this.state.ordrCarList.forEach(item => {
                        if (value[0] === item.value) {
                          this.setState({
                            orderCarLabel: item.label === this.t('report.k007') ? this.t('report.k002') : item.label
                          })
                        }
                      })
                      this.screenOrderCarData(value)
                    }
                  })
                }}
              >
                {this.state.orderCarLabel} <CheckOutline color="#a9a9a9" />
              </Button>
            </div>
            {
              (() => {
                if (this.state.isOrderEcharts) {
                  return(
                    <div className={style.echarts}>
                      <ReactEcharts renderer="svg" option={this.state.orderOption}></ReactEcharts>
                    </div>
                  )
                } else {
                  return(
                    <Empty description={this.t('report.k008')} image={images.reportnodata}/>
                  )
                }
              })()
            }
          </Card>
        </div>
    )
  }

  /**
   * 
   * 用车场景的单量及金额分布
   */
  renderSceneOrderQtyEcharts = () => {
    return(
        <div className={style.card}>
          <Card title={this.props.t('report.k009')}>
            <div className={style.operation}>
              <Button
                onClick={() => {
                  // 场景弹窗
                  CascadePicker.prompt({
                    options: this.state.sceneQtyList,
                    defaultValue: this.state.sceneQtyValue
                  }).then((value) => {
                    if (value) {
                      this.state.sceneQtyList.forEach(item => {
                        if (value[0] === item.value) {
                          this.setState({
                            sceneQtyLabel: item.label === this.t('report.k007') ? this.t('report.k001') : item.label
                          })
                        }
                      })
                      this.screenSceneQtyData(value)
                    }
                  })
                }}
              >
                {this.state.sceneQtyLabel} <CheckOutline color="#a9a9a9" />
              </Button>
              <Button
                onClick={() => {
                  // 城市弹窗
                  CascadePicker.prompt({
                    options: this.state.sceneQtyCityList,
                    defaultValue: this.state.sceneQtyCityValue
                  }).then((value) => {
                    if (value) {
                      this.state.sceneQtyCityList.forEach(item => {
                        if (value[0] === item.value) {
                          this.setState({
                            sceneQtyCityLabel: item.label === this.t('report.k007') ? this.t('report.k002') : item.label
                          })
                        }
                      })
                      this.screenSceneQtyCityData(value)
                    }
                  })
                }}
              >
                {this.state.sceneQtyCityLabel} <CheckOutline color="#a9a9a9" />
              </Button>
              <Button
                onClick={() => {
                  // 车型弹窗
                  CascadePicker.prompt({
                    options: this.state.sceneQtyCarList,
                    defaultValue: this.state.sceneQtyCarValue
                  }).then((value) => {
                    if (value) {
                      this.state.sceneQtyCarList.forEach(item => {
                        if (value[0] === item.value) {
                          this.setState({
                            sceneQtyCarLabel: item.label === this.t('report.k007') ? this.t('report.k003') : item.label
                          })
                        }
                      })
                      this.screenSceneQtyCarData(value)
                    }
                  })
                }}
              >
                {this.state.sceneQtyCarLabel} <CheckOutline color="#a9a9a9" />
              </Button>
            </div>
            {
              (() => {
                if (this.state.isSceneAmountEcharts) {
                  return(
                    <div className={style.echarts}>
                      <ReactEcharts ref={(e) => {this.echarts_order_instance = e}} renderer="svg" option={this.state.sceneOrderOption}></ReactEcharts>
                      <ReactEcharts ref={(e) => {this.echarts_order_amount = e}} renderer="svg" option={this.state.sceneAmountOption}></ReactEcharts>
                    </div>
                  )
                } else {
                  return(
                    <Empty description={this.t('report.k008')} image={images.reportnodata}/>
                  )
                }
              })()
            }
          </Card>
        </div>
    )
  }

  /**
   * 
   * 车型单量及金额分布
   */
  renderCarOrderAmount = () => {
    return(
      <div className={style.card}>
        <Card title={this.t('report.k010')}>
          <div className={style.operation}>
            <Button
              onClick={() => {
                // 场景弹窗
                CascadePicker.prompt({
                  options: this.state.carQtySceneList,
                  defaultValue: this.state.carQtySceneValue
                }).then((value) => {
                  if (value) {
                    this.state.carQtySceneList.forEach(item => {
                      if (value[0] === item.value) {
                        this.setState({
                          carQtySceneLabel: item.label === this.t('report.k007') ? this.t('report.k001') : item.label
                        })
                      }
                    })
                    this.screenCarQtySceneData(value)
                  }
                })
              }}
            >
              {this.state.carQtySceneLabel} <CheckOutline color="#a9a9a9" />
            </Button>
            <Button
              onClick={() => {
                // 城市弹窗
                CascadePicker.prompt({
                  options: this.state.carQtyCityList,
                  defaultValue: this.state.carQtyCityValue
                }).then((value) => {
                  if (value) {
                    this.state.carQtyCityList.forEach(item => {
                      if (value[0] === item.value) {
                        this.setState({
                          carQtyCityLabel: item.label === this.t('report.k007') ? this.t('report.k002') : item.label
                        })
                      }
                    })
                    this.screenCarQtyCityData(value)
                  }
                })
              }}
            >
              {this.state.carQtyCityLabel} <CheckOutline color="#a9a9a9" />
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  carQtyCarShow: true
                })
                // 车型弹窗
                CascadePicker.prompt({
                  options: this.state.carQtyCarList,
                  defaultValue: this.state.carQtyCarValue
                }).then((value) => {
                  if (value) {
                    this.state.carQtyCarList.forEach(item => {
                      if (value[0] === item.value) {
                        this.setState({
                          carQtyCarLabel: item.label === this.t('report.k007') ? this.t('report.k003') : item.label
                        })
                      }
                    })
                    this.screenCarQtyCarData(value)
                  }
                })
              }}
            >
              {this.state.carQtyCarLabel} <CheckOutline color="#a9a9a9" />
            </Button>
          </div>
          {
            (() => {
              if (this.state.isCarOrderAmountEcharts) {
                return(
                  <div className={style.echarts}>
                    <ReactEcharts ref={(e) => {this.echarts_car_order_instance = e}} renderer="svg" option={this.state.carOrderOption}></ReactEcharts>
                    <ReactEcharts ref={(e) => {this.echarts_car_amount_instance = e}} renderer="svg" option={this.state.carAmountOption}></ReactEcharts>
                  </div>
                )
              } else {
                return(
                  <Empty description={this.t('report.k008')} image={images.reportnodata}/>
                )
              }
            })()
          }
        </Card>
      </div>
    )
  }

  render() {
    return(
      <div className={style.report}>
        {/* 头部时间 */}
        {this.renderHeaderTime()}
        {/* 订单量及消费金额图表 */}
        {this.renderOrderPriceEcharts()}
        {/* 用车场景的单量及金额分布 */}
        {this.renderSceneOrderQtyEcharts()}
        {/* 车型单量及金额分布 */}
        {this.renderCarOrderAmount()}
        {/* 开始日期弹窗 */}
        {this.renderStartDatePicker()}
        {/* 结束日期弹窗 */}
        {this.renderEndDatePicker()}
      </div>
    )
  }
  
  componentDidMount() {
    // 订单量及消费金额
    this.loadOrderPrice()
    // 用车场景的单量及金额分布
    this.loadOrderQtyAmountReport()
    // 车型的单量及金额分布
    this.loadCarOrderAmount()
    // 用车报告场景列表
    this.loadSceneList()
    // 用车报告城市列表
    this.loadCityList()
    // 用车报告车型列表
    this.loadCarLevelList()
  }

  /**
   *
   *订单及消费金额渲染
   */
  loadOrderPrice = () => {
    let params = {
      reportStartTime: this.state.reportStartTime,
      reportEndTime: this.state.reportEndTime,
      sceneId: this.state.order.sceneId,
      cityCode: this.state.order.cityCode,
      carLevels: this.state.order.carLevels ? Number(this.state.order.carLevels) : null
    }
    webService.report.getCompanyOrderReport(params).then(d => {
      let result = []
      let option = {
        grid: {
          left: '3%',
          right: '3%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            dataView: { show: false, readOnly: false },
            magicType: { show: false, type: ['line', 'bar'] },
            restore: { show: false },
            saveAsImage: { show: false }
          }
        },
        legend: {
          data: [
            {
              name: this.t('report.k011'),
              icon : "circle",
              itemStyle: {
                color: "#80c171"
              }
            },
            {
              name: this.t('report.k012'),
              icon : "circle",
              itemStyle: {
                color: "#75a4d8"
              }
            }
          ],
          icon: 'circle',
          left: 'auto'
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: this.t('report.k013'),
            nameTextStyle: {
              color: "#bbbbbb",
              padding: [0, 0, 0, 30]
            },
            minInterval: 1,
            axisLabel: {
              formatter: '{value}'
            }
          },
          {
            type: 'value',
            name: this.t('report.k014'),
            nameTextStyle: {
              color: "#bbbbbb",
              padding: [0, 30, 0, 0]
            },
            minInterval: 1,
            maxInterval: 3600 * 24 * 1000,
            axisLabel: {
              formatter: '{value}'
            }
          }
        ],
        series: [
          {
            name: this.t('report.k012'),
            type: 'bar',
            itemStyle: {
              color: '#76a5dd'
            },
            data: []
          },
          {
            name: this.t('report.k011'),
            type: 'line',
            itemStyle: {
              color: '#80c171'
            },
            yAxisIndex: 1,
            data: []
          }
        ]
      }
      if (d.data.code === 0 && d.data.data) {
          
          result = d.data.data

          result.forEach(item => {
            option.xAxis[0].data.push(`${moment(item.orderTime).format("MM-DD")}`)
            option.series[0].data.push(item.orderCount)
            option.series[1].data.push(item.amount)
          })

          this.setState({
            orderData: result,
            orderOption: option
          }, () => {
            if (result.length > 0) {
              this.setState({
                isOrderEcharts: true
              })
            } else {
              this.setState({
                isOrderEcharts: false
              })
            }
          })
      }
    })
  }
  
  /**
   *
   *用车场景的单量及金额分布渲染
   */
  loadOrderQtyAmountReport = () => {
    let params = {
      reportStartTime: this.state.reportStartTime,
      reportEndTime: this.state.reportEndTime,
      sceneId: this.state.sceneQty.sceneId,
      cityCode: this.state.sceneQty.cityCode,
      carLevels: this.state.sceneQty.carLevels ? Number(this.state.sceneQty.carLevels) : null
    }
    webService.report.getSceneOrderCountAmountReport(params).then(d => {
      let optionOrder = {
          title: {
            text:"",
            left:"center",
            top:"52%",
            textStyle:{
              fontSize:12,
              align:"center",
              fontWeight: "normal"
            }
          },
          graphic:{
            type:"text",
            left:"center",
            top:"46%",
            style:{
              text: this.t('report.k015'),
              textAlign:"center",
              fontSize:14,
            }
          },
          series: [
            {
              name: this.t('report.k015'),
              type: "pie",
              radius: ['30%', '45%'],
              itemStyle: {
                length: 15,
                length2: 10,
                showAbove: true,
              },
              // itemStyle: {
              //   color: function (params) {//设置饼图颜色-渐变
              //     console.log()
              //     var colorList = [ // 饼图颜色，渐变色
              //     { c1: "#2DC4FF", c2: "#1492FF" },
              //     { c1: "#53DEC4", c2: "#29BA91" },
              //     { c1: "#F77392", c2: "#EC3F59" },
              //     { c1: "#F8DC77", c2: "#E5981D" },
              //     { c1: "#CC2CB7", c2: "#FF5AE6" },
              //     { c1: "#1FA9B7", c2: "#62FAFF" },
              //      ];
              //     // 设置饼图渐变色
              //     return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //       {
              //         //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
              //         offset: 0,
              //         color: colorList[params.dataIndex].c1,
              //       },
              //       {
              //         offset: 1,
              //         color: colorList[params.dataIndex].c2,
              //       },
              //     ]);
              //   },
              // },
              label: {
                show: true,
                formatter: "{b}\n{d}%"
              },
              data: []
            }
          ]
      }
      let amountOption = {
          title: {
            text:"",
            left:"center",
            top:"52%",
            textStyle:{
              fontSize:12,
              align:"center",
              fontWeight: "normal"
            }
          },
          graphic:{
            type:"text",
            left:"center",
            top:"46%",
            style:{
              text: this.t('report.k011'),
              textAlign:"center",
              fontSize:14,
            }
          },
          series: [
            {
              name: this.t('report.k011'),
              type: "pie",
              radius: ['30%', '45%'],
              itemStyle: {
                length: 15,
                length2: 10,
                showAbove: true,
              },
              // itemStyle: {
              //   color: function (params) {//设置饼图颜色-渐变
              //     console.log()
              //     var colorList = [ // 饼图颜色，渐变色
              //     { c1: "#2DC4FF", c2: "#1492FF" },
              //     { c1: "#53DEC4", c2: "#29BA91" },
              //     { c1: "#F77392", c2: "#EC3F59" },
              //     { c1: "#F8DC77", c2: "#E5981D" },
              //     { c1: "#CC2CB7", c2: "#FF5AE6" },
              //     { c1: "#1FA9B7", c2: "#62FAFF" },
              //      ];
              //     // 设置饼图渐变色
              //     return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //       {
              //         //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
              //         offset: 0,
              //         color: colorList[params.dataIndex].c1,
              //       },
              //       {
              //         offset: 1,
              //         color: colorList[params.dataIndex].c2,
              //       },
              //     ]);
              //   },
              // },
              label: {
                show: true,
                formatter: "{b}\n{d}%"
              },
              data: []
            }
          ]
      }
      
      if (d.data.code === 0 && d.data.data) {

        /**
         * 订单部分处理数据
         */
        let resultOrder = d.data.data.map(item => {
          return {
            name: item.name,
            value: item.orderCount
          }
        })

        // 求订单总数
        let allOrder = 0

        d.data.data.forEach(item => {
          allOrder += item.orderCount
        })

        optionOrder.series[0].data = resultOrder
        optionOrder.title.text = allOrder

        /**
         * 金额部分处理数据
         */
        let resultAmount = d.data.data.map(item => {
          return {
            name: item.name,
            value: item.amount
          }
        })

        // 求金额总数
        let allAmount = 0

        d.data.data.forEach(item => {
          allAmount += item.amount
        })

        amountOption.series[0].data = resultAmount
        amountOption.title.text = allAmount.toFixed(2)

        this.setState({
          sceneOrderOption: optionOrder,
          sceneAmountOption: amountOption,
          sceneAmountData: d.data.data
        }, () => {
          if (this.state.sceneAmountData.length > 0) {
            this.setState({
              isSceneAmountEcharts: true
            })
          } else {
            this.setState({
              isSceneAmountEcharts: false
            })
          }
        })

      }
    })
  }

  /**
   *
   *车型单量及金额分布渲染
   */
  loadCarOrderAmount = () => {
    let params = {
      reportStartTime: this.state.reportStartTime,
      reportEndTime: this.state.reportEndTime,
      sceneId: this.state.carQty.sceneId,
      cityCode: this.state.carQty.cityCode,
      carLevels: this.state.carQty.carLevels ? Number(this.state.carQty.carLevels) : null
    }
    webService.report.getCompanyCarLevelsOrderQtyAmountReport(params).then(d => {
      let carOrderOption = {
          title: {
            text:"",
            left:"center",
            top:"52%",
            textStyle:{
              fontSize:12,
              align:"center",
              fontWeight: "normal"
            }
          },
          graphic:{
            type:"text",
            left:"center",
            top:"46%",
            style:{
              text: this.t('report.k015'),
              textAlign:"center",
              fontSize:14,
            }
          },
          series: [
            {
              name: this.t('report.k015'),
              type: "pie",
              radius: ['30%', '45%'],
              itemStyle: {
                length: 15,
                length2: 10,
                showAbove: true,
              },
              // itemStyle: {
              //   color: function (params) {//设置饼图颜色-渐变
              //     console.log()
              //     var colorList = [ // 饼图颜色，渐变色
              //     { c1: "#2DC4FF", c2: "#1492FF" },
              //     { c1: "#53DEC4", c2: "#29BA91" },
              //     { c1: "#F77392", c2: "#EC3F59" },
              //     { c1: "#F8DC77", c2: "#E5981D" },
              //     { c1: "#CC2CB7", c2: "#FF5AE6" },
              //     { c1: "#1FA9B7", c2: "#62FAFF" },
              //      ];
              //     // 设置饼图渐变色
              //     return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //       {
              //         //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
              //         offset: 0,
              //         color: colorList[params.dataIndex].c1,
              //       },
              //       {
              //         offset: 1,
              //         color: colorList[params.dataIndex].c2,
              //       },
              //     ]);
              //   },
              // },
              label: {
                show: true,
                formatter: "{b}\n{d}%"
              },
              data: []
            }
          ]
      }
      let carAmountOption = {
          title: {
            text:"",
            left:"center",
            top:"52%",
            textStyle:{
              fontSize:12,
              align:"center",
              fontWeight: "normal"
            }
          },
          graphic:{
            type:"text",
            left:"center",
            top:"46%",
            style:{
              text: this.t('report.k011'),
              textAlign:"center",
              fontSize:14,
            }
          },
          series: [
            {
              name: this.t('report.k011'),
              type: "pie",
              radius: ['30%', '45%'],
              itemStyle: {
                length: 15,
                length2: 10,
                showAbove: true,
              },
              // itemStyle: {
              //   color: function (params) {//设置饼图颜色-渐变
              //     console.log()
              //     var colorList = [ // 饼图颜色，渐变色
              //     { c1: "#2DC4FF", c2: "#1492FF" },
              //     { c1: "#53DEC4", c2: "#29BA91" },
              //     { c1: "#F77392", c2: "#EC3F59" },
              //     { c1: "#F8DC77", c2: "#E5981D" },
              //     { c1: "#CC2CB7", c2: "#FF5AE6" },
              //     { c1: "#1FA9B7", c2: "#62FAFF" },
              //      ];
              //     // 设置饼图渐变色
              //     return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //       {
              //         //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
              //         offset: 0,
              //         color: colorList[params.dataIndex].c1,
              //       },
              //       {
              //         offset: 1,
              //         color: colorList[params.dataIndex].c2,
              //       },
              //     ]);
              //   },
              // },
              label: {
                show: true,
                formatter: "{b}\n{d}%"
              },
              data: []
            }
          ]
      }
      
      if (d.data.code === 0 && d.data.data) {

        /**
         * 订单部分处理数据
         */
        let resultOrder = d.data.data.map(item => {
          return {
            name: item.name,
            value: item.orderCount
          }
        })

        // 求订单总数
        let allOrder = 0

        d.data.data.forEach(item => {
          allOrder += item.orderCount
        })

        carOrderOption.series[0].data = resultOrder
        carOrderOption.title.text = allOrder

        /**
         * 金额部分处理数据
         */
        let resultAmount = d.data.data.map(item => {
          return {
            name: item.name,
            value: item.amount
          }
        })

        // 求金额总数
        let allAmount = 0

        d.data.data.forEach(item => {
          allAmount += item.amount
        })

        carAmountOption.series[0].data = resultAmount
        carAmountOption.title.text = allAmount.toFixed(2)

        this.setState({
          carOrderOption: carOrderOption,
          carAmountOption: carAmountOption,
          carOrderAmountData: d.data.data
        }, () => {
          if (this.state.carOrderAmountData.length > 0) {
            this.setState({
              isCarOrderAmountEcharts: true
            })
          } else {
            this.setState({
              isCarOrderAmountEcharts: false
            })
          }
        })

      }
    })
  }

  /**
   * 获取用车报告场景列表
   */
  loadSceneList = () => {
    let parmas = {
      reportStartTime: this.state.reportStartTime,
      reportEndTime: this.state.reportEndTime
    }
    webService.scene.getSceneEnableList(parmas).then(d => {

      if(d.data.code === 0 && d.data.data) {
        let list = d.data.data.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })

        list.unshift({
          label: this.t('report.k007'),
          value: null,
        })

        this.setState({
          orderSceneList: list,
          sceneQtyList: list,
          carQtySceneList: list
        })
      }
    })
  }

  /**
   * 获取用车报告城市列表
   */
  loadCityList = () => {
    let parmas = {
      reportStartTime: this.state.reportStartTime,
      reportEndTime: this.state.reportEndTime
    }
    webService.scene.getCityList(parmas).then(d => {
      if (d.data.code === 0 && d.data.data) {
        let list = d.data.data.map(item => {
          return {
            label: item.cityName,
            value: item.cityCode
          }
        })

        list.unshift({
          label: this.t('report.k007'),
          value: null,
        })

        this.setState({
          orderCityList: list,
          sceneQtyCityList: list,
          carQtyCityList: list
        })
      }
    })
  }

  /**
   * 获取用车报告车型列表
   */
  loadCarLevelList = () => {
    webService.scene.getCarLevelList().then(d => {
      if (d.data.code === 0 && d.data.data) {
        let list = d.data.data.map(item => {
          return {
            label: item.text,
            value: item.value
          }
        })

        list.unshift({
          label: this.t('report.k007'),
          value: null,
        })

        this.setState({
          ordrCarList: list,
          sceneQtyCarList: list,
          carQtyCarList: list
        })
      }
    })
  }

  /**
   *订单消费金额用车场景筛选
   */
  screenOrderSceneData = (val) => {
    this.setState({
      orderSceneValue: val,
      order: {
        ...this.state.order,
        sceneId: val[0]
      }
    }, () => {
      this.loadOrderPrice()
    })
  }

  /**
   *订单消费金额城市筛选
   */
  screenOrderCityData = (val) => {
    this.setState({
      orderCityValue: val,
      order: {
        ...this.state.order,
        cityCode: val[0]
      }
    }, () => {
      this.loadOrderPrice()
    })
  }

  /**
   *订单消费金额车型筛选
   */
  screenOrderCarData = (val) => {
    this.setState({
      orderCarValue: val,
      order: {
        ...this.state.order,
        carLevels: val[0]
      }
    }, () => {
      this.loadOrderPrice()
    })
  }

  /**
   *用车场景单量及金额分布场景筛选
   */
  screenSceneQtyData = (val) => {
    this.setState({
      sceneQtyValue: val,
      sceneQty: {
        ...this.state.sceneQty,
        sceneId: val[0]
      }
    }, () => {
      this.loadOrderQtyAmountReport()
    })
  }

  /**
   *用车场景单量及金额分布城市筛选
   */
  screenSceneQtyCityData = (val) => {
    this.setState({
      sceneQtyCityValue: val,
      sceneQty: {
        ...this.state.sceneQty,
        cityCode: val[0]
      }
    }, () => {
      this.loadOrderQtyAmountReport()
    })
  }

  /**
   *用车场景单量及金额分布车型筛选
   */
  screenSceneQtyCarData = (val) => {
    this.setState({
      sceneQtyCarValue: val,
      sceneQty: {
        ...this.state.sceneQty,
        carLevels: val[0]
      }
    }, () => {
      this.loadOrderQtyAmountReport()
    })
  }

  /**
   *车型单量及金额分布场景筛选
   */
  screenCarQtySceneData = (val) => {
    this.setState({
      carQtySceneValue: val,
      carQty: {
        ...this.state.carQty,
        sceneId: val[0]
      }
    }, () => {
      this.loadCarOrderAmount()
    })
  }

  /**
   *车型单量及金额分布城市筛选
   */
  screenCarQtyCityData = (val) => {
    this.setState({
      carQtyCityValue: val,
      carQty: {
        ...this.state.carQty,
        cityCode: val[0]
      }
    }, () => {
      this.loadCarOrderAmount()
    })
  }

  /**
   *车型单量及金额分布车型筛选
   */
  screenCarQtyCarData = (val) => {
    this.setState({
      carQtyCarValue: val,
      carQty: {
        ...this.state.carQty,
        carLevels: val[0]
      }
    }, () => {
      this.loadCarOrderAmount()
    })
  }

  /**
   *
   *设置开始日期统计
   */
  resetStartTime(val) {
    this.setState({
      reportStartTime: moment(val).format('YYYY-MM-DD'),
    })
  }

  /**
   *设置结束日期统计
   */
   resetEndTime(val) {
    if (moment(val) < moment(this.state.reportStartTime)) {
      Toast.show({
        content: this.t('report.k016'),
        position: 'bottom',
      })
      return
    } else {
      this.setState({
        reportEndTime: moment(val).format('YYYY-MM-DD')
      }, () => {
        this.loadOrderPrice()
        this.loadOrderQtyAmountReport()
        this.loadCarOrderAmount()
        this.loadSceneList()
        this.loadCityList()
      })
    }
  }
}

export default withTranslation()(Report)