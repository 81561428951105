import React from 'react'
import style from './driverWaitingCountDown.scss';

import {inject, observer } from 'mobx-react'

import moment from 'moment'
import {secondTohh_mm_ssStruct} from './../../util/utils'
// import gc from './../../util/globalConfiguration'

@inject('globalStore')
@observer
class DriverWaitingCountDown extends React.Component {

    state = {
        showTime: 0,
        minuteTime: 0,
        timeUnit: this.props.t('runing.k053')
    }

    driverArrivedTime = this.props.driverArrivedTime;
    // waitingTime = 0;
    timerWaiting = 0;

    t = this.props.t
    
    globalStore = this.props.globalStore;

    
    // 提醒司机等待时间过长
    get isShowCnpcWaitTip() {
        const limit = 5
        return this.isOngoingWaitFeePrompt && Number(this.state.minuteTime) >= limit
    }

    // 石油单独权限功能判断
    get isOngoingWaitFeePrompt() {
      return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.ongoing_wait_fee_prompt
    }

    render() {
        return <>
            <div className={style.countDown}>
                {this.t('runing.k054')}<span className={style.time}>{this.state.showTime}</span>{this.state.timeUnit}{this.isShowCnpcWaitTip ? '，为避免产生等时费，请尽快上车！' : ''}
            </div>
        </>
    }

    componentDidMount() {
        this.timerWaiting = setInterval(() => {
            let s = moment().diff(moment(this.driverArrivedTime), 's')
            let waitingTime = secondTohh_mm_ssStruct(Math.trunc(Number(s)))
            this.setState({
                showTime: waitingTime.m ? waitingTime.m : waitingTime.s,
                timeUnit: waitingTime.m ? this.t('runing.k055') : this.t('runing.k053'),
                minuteTime: waitingTime.m ? waitingTime.m : 0
            })

            // this.waitingTime++;
            // if (this.waitingTime < 60) {
            //     this.setState({
            //         showTime: this.waitingTime,
            //         timeUnit: '秒'
            //     })
            // } else {
            //     this.setState({
            //         showTime: Math.ceil(this.waitingTime / 60),
            //         timeUnit: '分钟'
            //     })
            // }
        }, 1000);
    }

    componentWillUnmount() {
        if (this.timerWaiting) {
            clearInterval(this.timerWaiting)
        }        
    }

    componentWillReceiveProps(props) {
        this.driverArrivedTime = props.driverArrivedTime
    }
}

export default DriverWaitingCountDown
