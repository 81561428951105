import React from 'react'
import style from './cancelReason.scss';

import { Collapse,  TextArea, Button, Toast} from 'antd-mobile'

class CancelReason extends React.Component {
    state = {
        reason: '',
        groupName: ''
    }


    t = this.props.t

    render() {
        return <div className={style.container}>
            <div className={style.title}>{this.t('runing.k056')}</div>
            <div className={style.body}>
            <Collapse defaultActiveKey={['0', '1', '2', '3']}>
                {
                    this.props.list.map((item, index) => {
                        return <Collapse.Panel key={index} title={item.groupName}>
                          {
                            item.children.map((i, idx) => {
                              return <div key={idx}>
                                    <div className={style.reason_span} onClick={() => {
                                      this.setState({reason: item.groupName !== '其他' ? i.label : '', groupName: item.groupName})
                                      this.props.onClick(false, i.label)
                                    }}
                                      >
                                      <span>{i.label}</span>
                                      <div className={i.checked ? style.checked : style.no}></div>
                                    </div>
                                    {
                                      i.label === '其他原因' && i.checked ? <TextArea
                                      onChange={(val) => {
                                        this.setState({
                                          reason: val
                                        })
                                      }}
                                      value={this.state.reason}
                                      placeholder={this.t('runing.k0147')}
                                      autoSize={{ minRows: 3, maxRows: 5 }}
                                    /> : <></>
                                    }
                                </div>
                            })
                          }
                        </Collapse.Panel>
                    })
                }
            </Collapse>
            </div>

            <div className={style.foot}>
              <Button onClick={() => {this.props.onClick(true)}}>
                {/* 继续用车 */}
                {this.t('runing.k0144')}
              </Button>
              <Button block style={
                {
                  'background-image': 'var(--button_background_color)',
                  '--border-color': 'var(--button_border_color)',
                  'background-color': 'var(--button_border_color)',
                  'color': 'var(--button_color)'
                }
              } size='middle' onClick={() => {
                if (!this.state.reason) {
                  Toast.show({
                    content: this.t('runing.k0145') // 请选择或输入原因
                  })
                } else {
                  this.props.onClick(true, this.state.reason)
                }
              }}>{this.t('runing.k0146')}</Button>
            </div>
        </div>
    }
}
export default CancelReason