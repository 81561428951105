
import webService from './webService'
import { getRemainingSegment, totalDistance } from './pointOnSegment'
import { yawThreshold } from './utils'
import loger from './loger'

class Direction {
    constructor() {
        this.Enable = false;
        this.openCachePath = false;
        this.cachePath = undefined;
        this.cachePathCallback = undefined;
    }

    /**
     * 对调经纬度顺序
     * @param {*} coordList 
     */
    swapLatLng(coordList) {
        let resList = []
        if (coordList && coordList.length) {            
            for (let i = 0; i < coordList.length; i += 2) {
                resList.push(coordList[i + 1])
                resList.push(coordList[i])
            }
        }
        return resList
    }

    /**
     * 添加运力发来的导航路径
     * @param {*} path {"coreOrderId":12313212323323,"routeType":1,"coordList":"123123.11,38.123;123123.22,38.123123;123123.11,38.234234;","distance":2342,"duration":345}
     */
    addCachePath(path) {
        if (!path.coordList) {
            return
        }
        path.coordList = path.coordList.replaceAll(';', ',').split(',').filter(a => a)
        console.log('添加运力发来的导航路径', path.coordList)

        let _path = {
            code: 0,
            distance: path.distance ? path.distance : 0,
            duration: path.duration ? Math.round(path.duration / 60) : 0,
            polyline: this.swapLatLng(path.coordList)
        }
        // 没有路径总长度则自计算之
        if (_path.distance === 0) {
            _path.distance = totalDistance(_path.polyline)
        }
        if (_path.duration === 0) {
            _path.duration = Math.round(_path.distance / 300)
        }

        this.cachePath = {
            updateTime: new Date(),
            path: _path
        }

        if (this.cachePathCallback) {
            this.cachePathCallback()
        }
    }

    /**
     * 获取运力发来的导航路径
     */
    async getCachePath() {
        if (this.cachePath && this.cachePath.path) {
            return this.cachePath.path 
        } else {
            //console.log('当前无运力导航,等待4s')
            let task = new Promise((ok, err) => {
                let reted = false
                this.cachePathCallback = () => {
                    if (!reted) {
                        reted = true
                        //console.log('未及4s既有返回')
                        ok()
                    } else {
                        //console.log('得到返回之时，已然4s超时')
                    }
                    
                }
                setTimeout(() => {
                    if (!reted) {
                        reted = true
                        //console.log('等待4s无路径返回')
                        ok()
                    } else {
                        //console.log('等待4s，超时之前即已经返回')
                    }
                    
                }, 4000);
            })
            await task
            if (this.cachePath && this.cachePath.path) {
                return this.cachePath.path
            } else {
                return {
                    code: 2,
                    message: '未取得上游导航路径'
                }
            }
        }
    }

    /**
     * 清空缓存的上游路径
     */
    clearCachePath() {
        this.cachePath = undefined
        this.cachePathCallback = undefined
    }

    /**
     * 解密导航坐标点
     * @param {*} points 
     */
    uncodeing = (points) => {
        if (points && points.length && points.length % 2 === 0) {
            for (let i = 2; i < points.length; i++) {
                points[i] = points[i - 2] + points[i] / 1000000
            }
        }
        return points;
    }

    /**
     * 导航
     * @param {起点} fromPoint 
     * @param {终点} toPonit 
     * @param {是否接乘客} isPickup 
     * @param {途经点} waypoints 
     */
    async driving(fromPoint, toPonit, isPickup, waypoints) {
        if (this.Enable === false) {
            return { code: 1 }
        }

        //console.log('openCachePath', this.openCachePath)
        // 优先从路径缓存中获取
        if (this.openCachePath) {
            // 开启了司乘同显
            //console.log('开启了司乘同显,尝试获取运力导航')
            let path = await this.getCachePath()
            //console.log('getCachePath返回', path)
            this.clearCachePath()
            if (path.code === 0) {
                // 判断实际起点是否在路径上
                let resultSegment = getRemainingSegment(path.polyline, [Number(fromPoint.lat), Number(fromPoint.lng)], yawThreshold)
                //console.log('是否可用判断', resultSegment)
                if (resultSegment.code === 0) {
                    loger.info('direction.js', 'driving', '上游路径可用')
                    let _res = {
                        code: 0,
                        distance: path.distance,
                        duration: path.duration,
                        polyline: resultSegment.path
                    }
                    _res.distance = totalDistance(resultSegment.path)
                    _res.duration = Math.round(path.duration * _res.distance / path.distance)
                    return _res
                } else {
                    loger.info('direction.js', 'driving', '上游路径不可用')
                }
            }
        }
        
        // 未开启司乘同显,或未取得运力导航路径，使用地图服务之导航
        return this.drivingForLbs(fromPoint, toPonit, isPickup, waypoints)
    }

    /**
     * 使用地理位置服务获取导航路线
     * @param {*} fromPoint 
     * @param {*} toPonit 
     * @param {*} isPickup 
     * @param {*} waypoints 
     * @returns 
     */
    async drivingForLbs(fromPoint, toPonit, isPickup, waypoints) {
        let policy = isPickup ? 'PICKUP' : 'TRIP';
        let from = `${fromPoint.lat},${fromPoint.lng}`;
        let from_poi = fromPoint.poi;
        let to = `${toPonit.lat},${toPonit.lng}`;
        let to_poi = toPonit.poi;

        let res = await webService.map.driving(from, from_poi, to, to_poi, policy, waypoints)
        try {
            if (res && res.data) {
                let data = res.data;
                if (data.status === 0) {
                    let { distance, duration, polyline } = data.result.routes[0];
                    
                    polyline = this.uncodeing(polyline);
                    polyline = polyline.map(a => Number(a.toFixed(6)))
                    return {
                        code: 0,
                        distance: distance,
                        duration: duration,
                        polyline: polyline
                    }
                }
            } else {
                console.log(res)
                return { code: 1, message: '导航失败' };
            }
        } catch (exp) {
            console.error(exp);
            loger.error('direction.js', 'drivingForLbs', exp)
            return { code: 1, message: '导航失败' };
        }
    }
}

export default new Direction()