import React from "react"
import style from './NoAuth.scss'

import images from '../util/images'
import {customerServiceTelephone} from '../util/config'

import { withTranslation } from "react-i18next";

class NoAuth extends React.Component {
    t = this.props.t
    render() {
        return <div className={style.container}>
            <div className={style.tips}>
                <div>
                    <img src={images.noauth} alt={this.t('noAuth.k003')} />
                </div>
                <div>{this.t('noAuth.k001')}</div>
                <div>{this.t('noAuth.k002', {customerServiceTelephone:customerServiceTelephone})}</div>
            </div>
        </div>
    }
}

export default withTranslation()(NoAuth);