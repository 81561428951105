import { observable, action, makeObservable } from "mobx";

class OrdersStore {

  constructor() {
    makeObservable(this)
  }

  // 默认当天日期
  @observable startTime = ''

  // 设置日期
  @action.bound setOrderTime(time) {
    this.startTime = time
  }
}

export default new OrdersStore();