import loger from './loger'
import {
    oauthType,
    appId
} from './globalConfiguration'
import { loadJavascript } from './utils'

import {getCurrentLocation_MT} from './mtJsBridge'

// const call = (name, obj, callback) => {
//     console.log(name, obj)
//     if (callback) {
//         callback({
//             longitude: 121.523175,
//             latitude: 38.861572,
//             coordsType: ''
//         })
//     }
// }

// window.JSBridge = {
//     call: call
// }

/**
 * 判断宿主App提供JSBridge方式调用
 * @returns 
 */
const checkJsBridge = async () => {
    if (oauthType === '6' && appId === 'ziru') {
        // 梓如需要加载自有js才能使用JSBridge
        // https://zirufiles.oss-cn-hangzhou.aliyuncs.com/zirufiles/loader.js
        if (!window.JSBridge) {
            try {
                await loadJavascript('https://zirufiles.oss-cn-hangzhou.aliyuncs.com/zirufiles/loader.js')
            } catch (exp) {
                loger.error('定位', 'JSBridge', '加载外部js失败', exp)
            }
        }
    }

    let readyJsBridge = window.JSBridge && window.JSBridge.call

    if (oauthType === '6' && appId === 'ziru') {
        readyJsBridge = readyJsBridge && window.JSBridgeKeys.includes('getCurrentLocation')
    }
    return readyJsBridge
}

/**
 * 获取宿主App提供的定位坐标(通用接口)
 * @returns 
 */
const getCurrentLocationCommon = async () => {
    let checkJsBridgeRes = await checkJsBridge()
    if (!checkJsBridgeRes) {
        loger.debug('宿主机未提供JSBridge调用接口')
        return {
            code: 1,
            message: '宿主机未提供JSBridge调用接口'
        }
    }

    console.log('开始使用JSBridge定位')
    return new Promise((ok, err) => {
        try {
            window.JSBridge.call('getCurrentLocation', {}, (resObj) => {
                const { error, coordsType, longitude, latitude } = resObj;
                console.log('getCurrentLocation 回调触发', resObj)
                if (longitude && latitude) {
                    ok({
                        code: 0,
                        longitude: longitude,
                        latitude: latitude,
                        coordsType: coordsType || 'gcj02'
                    })
                } else {
                    loger.error('定位', 'JSBridge', '宿主机未提供JSBridge调用失败', error)
                    ok({
                        code: 2,
                        message: '宿主机未提供JSBridge调用失败：' + error
                    })
                }
            })
        } catch (exp) {
            console.log('定位', 'JSBridge', '宿主机未提供JSBridge调用异常', exp)
            loger.error('定位', 'JSBridge', '宿主机未提供JSBridge调用异常', exp)
            err({
                code: 100,
                message: '宿主机未提供JSBridge调用异常：' + exp
            })
        }
        
    })
}

/**
 * 获取宿主App提供的定位坐标
 */
const getCurrentLocation = async () => {
    if (oauthType === '8' && appId === 'meituan') {
        return getCurrentLocation_MT()
    }


    return getCurrentLocationCommon()
}



export {
    getCurrentLocation
}