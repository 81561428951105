import React from 'react';
import style from './estimate.scss';
import { inject, observer } from 'mobx-react'

import { Popup, Badge, Toast, Dialog, Modal } from 'antd-mobile'
import Menu from './../component/callcar/menu'

import ManualChoose from './../component/callcar/manualChoose';
import SmartCarTypeChoose from './../component/callcar/smartCarTypeChoose'
import SmartChoose from './../component/callcar/smartChoose';
import LoadingMask from './../component/utils/loadingMask';
import IPathMap from './../component/utils/iPathMap'
// import QQMap from './../component/utils/qqMap'

import { generateEstimate } from './../util/paramsHelper'
import webService from './../util/webService';

import gc from './../util/globalConfiguration'

import { oauthType } from './../util/config'

import { withTranslation } from "react-i18next";

import Address from '../component/callcar/address'
import { ForgeRouter } from '../util/utils'
import MessageBox from '../component/callcar/messageBox'

@inject('mainStore', 'estimateStore', 'globalStore')
@observer
class Estimate extends React.Component {

    state = {
        duration: 0,
        isShowMenu: false,
        isShowMask: true,
        distance: '',
        distanceUnitName: '',
        dispatchModeList: [],
        addressShow: false,
        startOrEndAddress: 'beginAddr',
        currenCheckedDetails: {
            count: 0,
            detail: '',
            leftPrice: 0,
            rightPrice: 0,
            list: [],
        },
        callCar: true
    };
    mainStore = this.props.mainStore;
    estimateStore = this.props.estimateStore;
    globalStore = this.props.globalStore;

    iPathMapRef = undefined;
    chooseRef = undefined;
    messageBoxRef = undefined;

    t = this.props.t

    changeShowMenu = (isShow) => {
        this.setState(() => {
            return {
                isShowMenu: isShow
            }
        });
    }

    showMenu = () => {
        this.changeShowMenu(true);
    }

    hideMenu = () => {
        this.changeShowMenu(false);
    }

    // 是否隐藏使用标签车UI
    get isUserLabelCarUI() {
        return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.main_use_label_car_ui ? true : false
    }

    render() {
        return <div className={oauthType === '15' ? style.estimate_jd : style.estimate}>
            <MessageBox ref={(dom) => { this.messageBoxRef = dom; }} history={this.props.history}></MessageBox>
            <LoadingMask show={this.state.isShowMask} />
            {
                this.state.isShowMenu ?
                    <div>
                        <Popup
                            visible={this.state.isShowMenu}
                            onMaskClick={this.hideMenu}
                            position='left'
                            bodyStyle={{ minWidth: '50vw' }}
                        >
                            <Menu hideMenu={this.hideMenu} t={this.props.t}></Menu>
                        </Popup>
                    </div> : <></>
            }

            <div className={style.container}>
                <div className={oauthType === '15' ? style.amapContainer_jd : style.amapContainer} id='amapContainer' >
                    <IPathMap ref={(dom) => { this.iPathMapRef = dom ? dom.getMapRef() : undefined }} 
                        onMarkerClick={(evn) => { this.editAddress(evn) }}
                        t={this.props.t} ></IPathMap>
                    {/* <QQMap ref={(dom) => { this.qqMapRef = dom }} ></QQMap> */}
                </div>

                {/* <div className={style.amapContainer} id="amap_container"></div> */}
                <div className={oauthType === '15' ? style.estimate_panel_jd : style.estimate_panel}>
                    {
                        this.mainStore.serverType !== 20 ?
                            <div className={style.maprestoration}>
                                <div>
                                    {this.state.distance && this.state.duration ? <div className={style.estimatetime}>
                                        {/* 全程预计行驶<span>{this.state.duration}</span>分钟 */}

                                        {this.t('estimate.k001')}<span>{this.state.distance}</span>{this.state.distanceUnitName}, {this.t('estimate.k024')}<span>{this.state.duration}</span>{this.t('estimate.k023')}
                                    </div> : <></>}
                                </div>
                                {/* <img src={images.maprestoration} alt="" /> */}
                            </div> : <></>
                    }

                    {
                        (() => {
                            if (this.mainStore.beginAddr.name) {
                              if (this.globalStore.userInfo.useCarMode === 1) {
                                return <SmartChoose t={this.props.t} ref={(dom) => { this.chooseRef = dom; }} history={this.props.history} />
                              } else if (this.isUserLabelCarUI) {
                                return <SmartCarTypeChoose dispatchModeList={this.state.dispatchModeList} language={this.globalStore.userInfo.language} t={this.props.t} ref={(dom) => { this.chooseRef = dom; }} history={this.props.history} callCar={this.state.callCar} />
                              } else {
                                return <ManualChoose dispatchModeList={this.state.dispatchModeList} language={this.globalStore.userInfo.language} t={this.props.t} ref={(dom) => { this.chooseRef = dom; }} history={this.props.history} callCar={this.state.callCar} />
                              }
                            }
                        })()

                    }
                </div>
            </div>



            <div className={style.menuIcon} onClick={this.showMenu}>
                <Badge content={this.mainStore.badgeSum !== 0 ? this.mainStore.badgeSum : null} style={{ '--top': '22%', '--right': '16%' }}>
                    <div className={style.box}></div>
                </Badge>
            </div>

            <Popup
                position='bottom'
                visible={this.state.addressShow}
                bodyStyle={{ height: '100%' }}
            >
                {/* 地址选择框 */}
                {this.state.addressShow ? <Address t={this.props.t} onChange={this.choosedAddr}
                    isCommonlyAddress={true}
                    moveMap={this.props.moveMap}
                    onCancel={() => {
                        this.props.history.goBack();
                    }}
                    isBegin={false} cityCode={this.mainStore[this.state.startOrEndAddress]?.cityCode} cityName={this.mainStore[this.state.startOrEndAddress]?.cityName}
                    history={this.props.history}
                ></Address> : ''}
            </Popup>
        </div>
    }

    componentDidMount() {
        let { mainStore } = this;

        window.onpopstate = (e) => {
            let { hash } = document.location;
            if (hash.indexOf('#/runing/') === 0) {
                mainStore.clear();
                this.props.history.replace('/')
                //window.location.href = webBaseUrl;
            } else if (hash === '#/') {
                mainStore.clear();
            } else if (hash === '#/estimate') {
                mainStore.clear();
                this.props.history.goBack()
            }
        }


        if (!mainStore.isComplete()) {
            //this.props.history.replace('/')
            this.props.history.goBack()
            return;
        }

        if (this.AMap) {
            return;
        }

        this.orderPay()


        this.loadBadgeCount();
        // 暂不使用 起止位置更改
        // window.editAddress = this.editAddress
    }

    /**
    * 预估价格
    */
    orderPay = async (choosedAddr = false) => {
        let cep = generateEstimate(this.mainStore.serverType, this.mainStore.scene, this.mainStore.beginAddr, this.mainStore.endAddr, this.globalStore.userInfo, this.mainStore.preApply, this.mainStore.charteredBusType, this.mainStore.pathways);
        const d = await webService.order.estimate(cep);
        try {
            let { data } = d;
            if (data.code === 0) {
                if (d.data.data.cars && d.data.data.cars.length) {
                    for (let index in d.data.data.cars) {
                        let _car = d.data.data.cars[index]
                        _car.list = this.trimCarSource(_car.list)
                    }
                    this.setState({
                        isShowMask: false
                    }, async () => {
                        if (!choosedAddr) {
                            await this.iPathMapRef.initMap()
                        }
                        //this.map = this.qqMapRef.getMap()
                        // this.map.setDraggable(false)
                        // this.map.setScrollable(false)

                        setTimeout(async () => {
                            let res = await this.iPathMapRef?.addDriving({
                                lat: this.mainStore.beginAddr.lat,
                                lng: this.mainStore.beginAddr.lon,
                                poi: this.mainStore.beginAddr.poi,
                                locationName: this.mainStore.beginAddr.name
                            }, {
                                lat: this.mainStore.endAddr.lat,
                                lng: this.mainStore.endAddr.lon,
                                poi: this.mainStore.endAddr.poi,
                                locationName: this.mainStore.endAddr.name
                            }, undefined, this.mainStore.pathways && this.mainStore.pathways.length ? this.mainStore.pathways : undefined)
                            if (res && res.code === 0) {

                                if (res.distance > 1000) {
                                    let _distance = res.distance / 1000

                                    // 强生里程额外, 处理取整数, 四舍五入展示
                                    if (gc.process.isJnjOauthType) {
                                        _distance = Math.round(_distance);
                                    } else {
                                        _distance = Number(_distance.toFixed(1))
                                    }

                                    res.distance = _distance;
                                    this.setState({
                                        distanceUnitName: this.t('estimate.k025'),
                                    })
                                } else {
                                    this.setState({
                                        distanceUnitName: this.t('estimate.k026')
                                    })
                                }
                                this.setState({
                                    duration: Math.ceil(res.duration),
                                    distance: res.distance
                                })
                                // 起止位置弹框
                                // const pops = [
                                //      {
                                //         name: 'endInfo', 
                                //         info: {
                                //             lat: res.polyline[res.polyline.length - 2],
                                //             lng: res.polyline[res.polyline.length - 1],
                                //           }, 
                                //           content: `<div onclick="window.editAddress({id: 'rm_end'})">${this.mainStore.endAddr.name}</div>`
                                //     },
                                //     {
                                //         name: 'startInfo', 
                                //         info: {
                                //             lat: res.polyline[0],
                                //             lng: res.polyline[1],
                                //           }, 
                                //           content: `<div onclick="window.editAddress({id: 'rm_begin'})">${this.mainStore.beginAddr.name}</div>`
                                //     },
                                   
                                // ]
                                // this.iPathMapRef.addPop(pops)
                            }
                        }, 500);

                        for (let i in d.data.data.cars) {
                            let item = d.data.data.cars[i];
                            if (this.mainStore.serverType !== 20) {
                                item.list = item.list.sort((a, b) => { return a.estimatePrice - b.estimatePrice });
                            }
                        }
                        await this.getDispatchMode()
                        d.data.data.carLabels = d.data.data.labels || []

                        await this.chooseRef.init(d.data)
                    })
                } else {
                    if (gc.process.isFanJia) {
                        Modal.show({
                            content: this.t('estimate.k002')
                        })
            
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: this.t('estimate.k002')
                        })
                        setTimeout(() => {
                            this.props.history.go(-1);
                        }, 2000);
                    }
                    
                }
            } else {
                if (data.data && data.data.forwardUrl) {
                    Dialog.show({
                      title: this.t('common.k004'),
                      content: d.data.data.fprwardBtnText,
                      actions: [
                        [
                          {
                            key: 'go_set', text: <div className={style.btn_details}>{this.t('common.k006')}</div>,bold: true, 
                            onClick: () => {
                              this.showMessageBox(d.data.data.forwardUrl)
                            }
                          },
                          {
                            key: 'cancel', text: <div className={style.btn_close}>{this.t('common.k005')}</div>, onClick: () => {
                              this.props.history.go(-1)
                            }
                          }
                        ]
                      ],
                      closeOnAction: true
                    })
                } else {
                  Toast.show({
                    icon: 'fail',
                    content: data.message ? data.message : this.t('estimate.k003')
                  })
                  setTimeout(() => {
                      this.props.history.go(-1);
                  }, 2000);
                }
            }

        } catch (error) {
            console.log('生成订单失败：', error)
        }
    }

    /**
     * 刷新代办任务数
     */
    loadBadgeCount = () => {
        this.mainStore.refreshBadgeCount();
    }

    componentWillUnmount = () => {
        window.onpopstate = undefined;
        Modal.clear()
    }

    /**
     * 去除同名的运力，保留低价
     * @param {*} list 
     * @returns 
     */
    trimCarSource = (list) => {
        let llst = [];
        for (let i in list) {
            let c = list[i]
            let item = llst.filter(a => a.carSource === c.carSource)
            if (item && item.length) {
                // 存在，比价，低价留下，高价放弃
            } else {
                // 没有，放入
                llst.push(c)
            }
        }
        return llst
    }

    /**
     * 获取派车模式配置项
     * @returns 
     */
    getDispatchMode = async () => {
        try {
            let { data } = await webService.company.getDispatchMode();
            if (data.code === 0) {
                this.setState({
                    dispatchModeList: data.data
                })
            }
        } catch (e) {
            console.log(e)
        }
    }

    /**
    * 修改上车起止位置
    * @param {*} e 
    */
    editAddress = (e) => {
        // 判断是否配置开启预估价页面修改起终点功能
        if (!(this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.estimate_changeaddress)) {
            return
        }
        if (this.mainStore.serverType === 6 && e.id === 'rm_begin') {
            // 接机场景不可更改上车地点
            Toast.show({
                content: this.t('estimate.k042'),
                duration: 1000
            })
            return
        }
        if (!['rm_begin', 'rm_end'].includes(e.id)) {
            // 只允许修改起点和终点，其他点（途径点等）不得修改
            return
        }
        this.setState({
            addressShow: true,
            startOrEndAddress: e.id === 'rm_begin' ? 'beginAddr' : 'endAddr'
        }, () => {
            let fr = new ForgeRouter(() => {
                let { hash } = document.location;
                if (hash === '#/estimate') {
                    this.setState({
                        addressShow: false
                    })

                }
            });
            fr.onpopstate();
        })
    }

    /**
     * 点选地址
     * @param {*} item 
     */
    choosedAddr = async (item) => {
        this.props.history.goBack();
        Toast.show({
            icon: 'loading',
            duration: 0,
        })

        try {
            this.setState({ callCar: false })
            const beginAddr = this.state.startOrEndAddress === 'beginAddr' ? { ...this.mainStore.beginAddr, ...item } : this.mainStore.beginAddr
            const endAddr = this.state.startOrEndAddress === 'endAddr' ? { ...this.mainStore.endAddr, ...item } : this.mainStore.endAddr
            let cep = generateEstimate(this.mainStore.serverType, this.mainStore.scene, beginAddr, endAddr, undefined, this.mainStore.preApply, this.mainStore.charteredBusType, this.mainStore.pathways);


            let res = await webService.order.preCheck(cep);
            if (res.data.code !== 0) {
                Dialog.alert({
                    content: res.data.message,
                    confirmText: this.t('common.k003')
                })
                return;
            }
            //this.setState({ addressShow: false })
            if (this.state.startOrEndAddress === 'beginAddr') {
                this.mainStore.setBeginAddrOnly(beginAddr);
            } else {
                this.mainStore.choosedEndAddr(endAddr)
            }
            await this.orderPay(true)

        } catch (exp) {
            console.log(exp)
            Dialog.alert({
                content: this.t('estimate.k011'),
                confirmText: this.t('common.k003')
            })
            return;
        } finally {
            this.setState({ callCar: true })
            Toast.clear()
        }

    }
    
    // 显示错误提示信息
    showMessageBox(url){
      Dialog.clear(); 
      this.messageBoxRef.show(url)
      let fr = new ForgeRouter(() => {
          this.messageBoxRef.hide('messageBoxRef')
      });
      fr.onpopstate();
    }
}

export default withTranslation()(Estimate);