import React from 'react';

import style from './runingcomp.scss'

import images from './../../util/images'

// import { withTranslation } from "react-i18next";
class RuningComp extends React.Component {

    state = {
        activeOrder: this.props.activeOrder
    }
    
    t = this.props.t

    render() {
        let {activeOrder} = this.state;
        return <div className={style.runing}>
            <div className={style.img}>
                <img src={images.smallBell} alt="" />
            </div>
            {
                (() => {
                    if (activeOrder.length > 1) {
                        return <div className={style.tip} >
                            {/* 您有<span>{ activeOrder.length }</span>笔进行中的行程 */}
                            {this.t('main.k024', {count : activeOrder.length})}
                        </div>
                    } else {
                        return <div className={style.tip} >
                            {
                                (() => {
                                    let msg = '';

                                    // 订单为预约订单时候判断类型后状态为1的时候展示预约文案
                                    if (activeOrder[0].serviceType === 20) {
                                      if (activeOrder[0].state === 1) {
                                        msg = this.t('main.k0145')
                                        return msg
                                      }
                                    }

                                    if (activeOrder[0].serviceType === 2 && activeOrder[0].extraServiceCode === 'ES0005') {
                                      if (activeOrder[0].state === 1) {
                                        msg = this.t('main.k0134')
                                        return msg
                                      }
                                    }


                                    if (activeOrder[0].state < 2) {
                                        msg = this.t('main.k026', {who: activeOrder[0].pickupLocationName})
                                    } else {
                                        msg = `${activeOrder[0].sourceNameCn ? activeOrder[0].sourceNameCn : ''} ${activeOrder[0].vehicleNo}`;
                                    }
                                    
                                    switch (activeOrder[0].state) {
                                        case 2:
                                            msg += this.t('main.k027');
                                            break;
                                        case 3:
                                            msg += this.t('main.k028');
                                            break;
                                        case 4:
                                            msg += this.t('main.k029');
                                            break;
                                        default :
                                            break;
                                        
                                    }
                                    
                                    return msg;
                                })()
                            }
                            
                        </div>
                    }
                })()
            }

            <div className={style.opt}>
                <div className={style.but} onClick={() => {
                    if (activeOrder.length === 1) {
                        this.props.history.push(`/runing/${activeOrder[0].id}`)
                    } else {
                        this.props.history.push('/runingOrders')
                    }
                }}>{this.t('main.k075')}</div>
            </div>
        </div>
    }

    componentWillReceiveProps(props) {
        this.setState({
            activeOrder: props.activeOrder
        })
    }

}

export default RuningComp;
