import React from "react";
import { Steps } from 'antd-mobile'

import {customerServiceTelephone} from './../util/config'
import { serviceStateToCn } from './../util/paramsHelper'
import webService from './../util/webService'

import { withTranslation } from "react-i18next";

import images from "../util/images";

import style from './complaintsDetails.scss'

class ComplaintsDetails extends React.Component {

  state = {
    feeList: [], // 投诉标签
    orderInfo: {}, // 订单信息
    reply: '', // 处理结果
    feedback: '', // 投诉内容
    state: 0, // 投诉进度
    feedbackTime: '', // 投诉时间
    startProcessTime: '', // 处理时间
    replayTime: '' // 处理完成时间
  }

  t = this.props.t

  // 投诉进度
  get progressCount() {
    if (this.state.state === 0) {
      return 0
    } else if (this.state.state === 1) {
      return 1
    } else {
      return 2
    }
  }

   /**
   *
   * 订单详情-投诉进度
   */
  renderCellComplaint() {
    const { Step } = Steps
    return(
      <div className={style.complaint_details}>
        <Steps current={this.progressCount} direction={'vertical'} style={{
          '--title-font-size': '17px',
          '--description-font-size': '15px',
          '--indicator-margin-right': '12px',
          '--icon-size': '22px',
        }}>
          <Step
            title={this.t('complaintsDetails.k001')} // 提交投诉
            description={<div className={style.handle}>
              <div className={style.msg}>{this.state.feedback}</div>
              {this.state.feedbackTime}
            </div>}
            icon={<img alt='' src={this.progressCount >= 0 ? images.blueChecked : images.greyChecked}/>}
          />
          <Step
            title={this.t('complaintsDetails.k002')} // 正在处理
            description={<div className={style.handle}>
              {this.state.startProcessTime}
            </div>}
            icon={<img alt='' src={this.progressCount >= 1 ? images.blueChecked : images.greyChecked}/>}
          />
          <Step
            title={this.t('complaintsDetails.k003')} // 处理完成
            description={<div className={style.handle}>
              {this.state.reply}
              <div>{this.state.replayTime}</div>
            </div>}
            icon={<img alt='' src={this.progressCount >= 2 ? images.blueChecked : images.greyChecked}/>}
          />
        </Steps>
      </div>
    )
  }

  render() {
    return <div className={style.container}>
      <div className={style.top}>                     
      <div className={style.details}>
        {/* 用车信息 */}
        <p>{this.t('complaintsDetails.k004')}</p>
        <div className={style.msg}>
          <span className={style.price}>¥ {this.state.orderInfo.totalAmount}</span>
          <span className={style.type}>{serviceStateToCn(this.state.orderInfo.orderType, this.props.t)}</span>
          {/* 待支付 */}
          {
            !this.state.orderInfo.payStatus ? <span className={style.pay_type}>{this.t('complaintsDetails.k005')}</span> : <></>
          }
        </div>
        <div className={style.tiem}>
          <img alt="" src={images.payTime} />
          <span>{this.state.orderInfo.orderTime}</span>
        </div>
        <div className={style.begin}>
          <span></span>
          <p>{this.state.orderInfo.pickupLocationName}</p>
        </div>
        <div className={style.end}>
          <span></span>
          <p>{this.state.orderInfo.destLocationName}</p>
        </div>
      </div>

      <div className={style.problem_type}>
        {/* 投诉详情 */}
        <p>{this.t('complaintsDetails.k006')}</p>
        <div className={style.fee_list}>
          {
            this.state.feeList.map((item, index) => {
              return <div key={index} className={style.fee_cell_bule}>
                {item}
              </div>
            })
          }
        </div>
        {this.renderCellComplaint()}
      </div>

      </div>
      <div className={style.contact}>
        <div onClick={() => {
           window.top.location.href="tel://" + customerServiceTelephone
        }}>
          <img alt="" src={images.customerServiceTwo}/>
          {/* 联系客服 */}
          <span>{this.t('complaintsDetails.k007')}{customerServiceTelephone}</span>
        </div>
      </div>
    </div>
  }

  componentDidMount() {
    this.loadOrderDetailsForCSC()
    this.loadComplaintProgress()
  }

  /**
   * 
   * 获取订单信息
   */
  loadOrderDetailsForCSC = async () => {
    const {id} = this.props.match.params;
    let {data} = await webService.order.getOrderDetailsForCSC({orderId:id});
    if (data.code === 0) {
      this.setState({
        orderInfo: data.data
      })
    }
  }

  /**
   * 
   * 获取投诉进度详情
   */
  loadComplaintProgress = async () => {
    const {id} = this.props.match.params;
    let {data} = await webService.order.getComplaintProgress({orderId:id});
    if (data.code === 0) {
      this.setState({
        feeList: data.data.complaintLabels,
        reply: data.data.reply,
        feedback: data.data.feedback,
        state: data.data.state,
        feedbackTime: data.data.feedbackTime,
        startProcessTime: data.data.startProcessTime,
        replayTime: data.data.replayTime
      })
    }
  }
}

export default withTranslation()(ComplaintsDetails)