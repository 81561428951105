import React from 'react'
import {inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import style from './orderscell.scss'
import images from './../../util/images'

import { appId } from '../../util/config'
@inject('globalStore')
@observer
class OrdersCell extends React.Component {

  t = this.props.t

  globalStore = this.props.globalStore

  // 详情总金额展示
  get totalAmount() {
    if (['zhonghai', 'XCX032424'].includes(appId)) {
      return this.props.userBearAmount ? this.props.userBearAmount : 0
    }
    return this.props.totalAmount ? this.props.totalAmount : 0
  }

  get orderStateIcon() {
    if ([4].includes(this.props.orderState)) {
      return images.triangleRightBlue
    } else if ([1,2,3,5,6].includes(this.props.orderState)) {
      return images.triangleRightDarkGray
    } else {
      return images.triangleRightLightGray
    }
  }

  /**
   *
   *订单状态
   */
  renderOrderState = () => {
    return(
      (()=> {
        switch(this.props.orderState) {
          case 1:
            return (<span>{this.t('orders.k001')}</span>);
          case 2:
            return (<span>{this.t('orders.k002')}</span>);
          case 3:
            return (<span>{this.t('orders.k003')}</span>);
          case 4:
            return (<span className={style.blue}>{this.t('orders.k004')}</span>);
          case 5:
            return (<span>{this.t('orders.k005')}</span>);
          case 6:
            return (<span>{this.t('orders.k006')}</span>);
          case 7:
            return (<span className={style.gray}>{this.t('orders.k007')}</span>);
          default:
            return ''
        }
      })()
    )
  }

  /**
   *
   *服务状态
   */
  renderServiceState = () => {
    return (
      (()=> {
        if (this.props.serviceType === 1) {
          return (<em>{this.t('orders.k008')}</em>)
        } else if (this.props.serviceType === 2) {
          return (<em>{this.t('orders.k009')}</em>)
        } else if (this.props.serviceType === 6) {
          return (<em>{this.t('orders.k010')}</em>)
        } else if (this.props.serviceType === 7) {
          return (<em>{this.t('orders.k025')}</em>)
        } else if (this.props.serviceType === 20) {
          return (<em>{this.t('orders.k031')}</em>)
        } 
      })()
    )
  }

  /**
   *
   *取消费用
   */
  // renderCancelExpenses = () => {
  //   if (this.props.totalAmount !== '0.00' && this.props.orderState === 7) {
  //     // 取消费用
  //     return <span className={style.cancel_expenses}>{this.t('orders.k033')}</span>
  //   }
  // }

  /**
   * 
   * 待审批
   */

  renderApprove = () => {
    return(
      (() => {
        if (this.props.approvalState === 1) {
          return (<em>{this.t('orders.k030')}</em>)
        }
      })()
    )
  }



  /**
   * 
   * 预付状态
   */
   renderPrepaid = () => {
    return (
      (()=> {
        return <em className={style.prepaid}>{this.t('orders.k011')}</em>
      })()
    )
  }
  /**
   * 
   * 未支付
   */
  renderUnpaid=()=>{
    return (
    (()=> {
      return <em className={style.unpaid}>{this.t('orders.k027')}</em>
    })()
    )
  }
  /**
   * 订单异常
   * @returns 
   */
  renderError=()=>{
    return (
    (()=> {
      return <em className={style.error}>{this.t('orders.k029')}</em>
    })()
    )
  }
  /**
   *
   *订单金额展示
   */
  rendertotalAmount = () => {
    return(
      (()=> {
        switch(this.props.orderState) {
          case 1:
            return (
            <>
            <span className={style.frozen}>¥{this.props.frozenAmount}</span>
            <span className={style.frozenIcon}>[{this.t('orders.k012')}]</span>
            </>
            );
          case 2:
            return (
              <>
              <span className={style.frozen}>¥{this.props.frozenAmount}</span>
              <span className={style.frozenIcon}>[{this.t('orders.k012')}]</span>
              </>
            );
          case 3:
            return (
              <>
              <span className={style.frozen}>¥{this.props.frozenAmount}</span>
              <span className={style.frozenIcon}>[{this.t('orders.k012')}]</span>
              </>
            );
          case 4:
            return (
              <>
              <span className={style.frozen}>¥{this.props.frozenAmount}</span>
              <span className={style.frozenIcon}>[{this.t('orders.k012')}]</span>
              </>
            );
          case 5:
            return (<span className={style.money}>¥{this.totalAmount}</span>);
          case 6:
            return (<span className={style.money}>¥{this.totalAmount}</span>);
          case 7:
            return (<span className={style.money}>¥{this.totalAmount}</span>);
          default:
            return ''
        }
      })()
    )
  }
  
  render() {
    return (
      <div className={style.box} onClick={this.props.onClick}>
        {/* 金额展示部分 */}
        <div className={style.cell}>
          <div className={style.price}>
            {/* 订单金额展示 */}
            {this.rendertotalAmount()}
            {/* 服务状态 */}
            {this.renderServiceState()}
            {/* 取消费用 */}
            {/* {this.renderCancelExpenses()} */}
            {/* 审批状态 */}
            {this.renderApprove()}
            {/* 预付状态 */}
            {/* {this.props.customInfo.isPrePay ? this.renderPrepaid() : ''} */}
            {/*待支付状态 */}
            {this.props.payState===1?this.renderUnpaid():''}
            {this.props.isAbnormal==='是'?this.renderError():''}
          </div>
          <div className={style.status}>
            {/* 订单状态展示 */}
            {this.renderOrderState()}
            <img alt='' src={this.orderStateIcon}/>
            {/* <Space wrap style={{ fontSize: 12, marginTop:2 }}>
              {(()=>{
                if(this.props.orderState === 4){
                  return (<RightOutline className={style.icon} color="#1677ff" />)
                }else if(this.props.orderState === 7){
                  return (<RightOutline className={style.icon} color="#ccccd0" />)
                }else{
                  return (<RightOutline className={style.icon} color="#000000" />)
                }
              })()}
              </Space> */}
          </div>
        </div>
        {/* 地址部分 */}
        <div className={style.address}>
          <div className={style.left}>
            {/* <div className={style.date}>
              <span>{this.t('orders.k013')}</span>
              <span>{this.props.departCityName}</span>
            </div> */}
            <div className={style.date}>
              {/* <span>{this.t('orders.k014')}</span> */}
              <img className={style.img} alt="" src={images.payTime} />
              <span>{this.props.departTime}</span>
            </div>
            <div className={style.start}>
              <div>
                <span className={style.circular}></span>
              </div>
              {/* <span>{this.t('orders.k015')}</span> */}
              <span className={style.position}>{this.props.pickupLocation}</span>
            </div>
            <div className={style.end}>
              {/* <span>{this.t('orders.k016')}</span> */}
              <div>
                <span className={style.circular}></span>
              </div>
              <span className={style.position}>{this.props.destLocation}</span>
            </div>
          </div>
          <div className={style.right}>
            {/* <div>
              {(()=> {
                // 开票状态：0: 等待开票 1：开票成功
                if (this.props.orderState === 6 && this.props.invoiceState !== 3) {
                  if (this.props.invoiceState === 0 && this.props.payState === 2) {
                    return(<Button onClick={(e) => this.props.toNext(e)} size='mini' color='primary'>{this.t('orders.k017')}</Button>)
                  } else if (this.props.invoiceState === 1) {
                    return(<Button disabled size='mini' color='primary'>{this.t('orders.k018')}</Button>)
                  }
                }
              })()}
            </div> */}
            {
              (()=>{ 
                 /* 审批通过 */
                 if(this.props.approvalState===2){
                  if (this.globalStore.userInfo.language === 1) {
                    return (<img className={style.pass} alt="" src={images.pass}></img>)
                  } else {
                    return (<img className={style.pass} alt="" src={images.passEn}></img>)
                  }
                 }else if(this.props.approvalState===4){
                  if (this.globalStore.userInfo.language === 1) {
                    return (<img className={style.pass} alt="" src={images.refuse}></img>)
                  } else {
                    return (<img className={style.pass} alt="" src={images.refuseEn}></img>)
                  }
                 }
              })()
            }
          </div>
        </div>
      </div>
    )
  }
}

OrdersCell.propTypes = {
  totalAmount: PropTypes.string,
  frozenAmount:PropTypes.string,
  serviceType: PropTypes.number,
  orderState: PropTypes.number,
  departTime: PropTypes.string,
  pickupLocation: PropTypes.string,
  destLocation: PropTypes.string,
  approvalState: PropTypes.number,
  invoiceState: PropTypes.number,
  departCityName: PropTypes.string,
  orderId: PropTypes.string,
  userBearAmount: PropTypes.string
}

export default OrdersCell