import React from 'react';
import style from './smartCarWaitingOrders.scss';
import { inject, observer } from 'mobx-react';
import { Dialog, Popup, Button } from 'antd-mobile';
import { RightOutline } from 'antd-mobile-icons';

import MakeAppointment from './makeAppointment'
import SmartAppendManualChoose from './smartAppendManualChoose'


@inject('globalStore', 'mainStore', 'estimateStore', 'confirmStore', 'runingOrderStore')
@observer
class SmartCarWaitingOrders extends React.Component {
  state = {
    cars: [],
    smartLabelCar: [],
    smartCheckAll: false,
    labelCode: '',
    activeLevel: 1,
    showAppendCars: false,
    currenCheckedDetails: {
      count: 0,
      detail: '',
      leftPrice: 0,
      rightPrice: 0,
      list: [],
    },
    carLabels: [],
    isScrolling:false
  };
  sectionRefs = [];
  smartRefs = [];
  contentRef = null
  globalStore = this.props.globalStore;
  mainStore = this.props.mainStore;
  estimateStore = this.props.estimateStore;
  confirmStore = this.props.confirmStore;
  runingOrderStore = this.props.runingOrderStore;

  t = this.props.t;

  get isLanguage() {
    return this.globalStore.userInfo.language === 1 ? true : false;
  }

  render() {
    return (
      <div className={style.container}>
          {
          (() => {
            if (this.props.isBookingOrder && this.props.serviceType === 2) {
              return (
                <MakeAppointment cancelOrder={this.props.cancelOrder} departTime={this.props.departTime} t={this.props.t} history={this.props.history} />
              )
            } else {
              return (
                <div className={style.summary}>
                  <div>
                    <div>
                      {this.t('runing.k016', { carLength: this.runingOrderStore.allCarLength })}
                    </div>
                  </div>
                  <div>
                    <Button color='primary' fill='outline' onClick={() => { this.props.cancelOrder(); }}>{this.t('runing.k007')}</Button>
                  </div>
                </div>
              )
            }
          })()
        }
        <div className={style.car_box}>
          <div
            className={
              this.mainStore.serverType === 20
                ? style.carlevel_chartered
                : style.carlevel
            }
          >
            {/* 标签左侧导航 */}
            {this.runingOrderStore.carLabels.map((item, index) => {
              return (
                <div
                  className={
                    this.runingOrderStore.activeLevel === item.dynamicCode
                      ? `${
                          this.props.language === 1
                            ? style.level
                            : style.level_en
                        } ${style.active}`
                      : this.props.language === 1
                      ? style.level
                      : style.level_en
                  }
                  key={index}
                  onClick={() => {
                    this.runingOrderStore.setActiveLevel(item.dynamicCode)
                    this.setState({isScrolling:true });
                    // 延时后重置标志并更新状态
                    setTimeout(() => {
                      this.setState({isScrolling:false });
                    }, 800); // 根据实际情况调整延时
                    const element = this.smartRefs[index].dom
                    element.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                    });
                  }}
                >
                  <div className={style.name}>
                    {this.isLanguage ? item.nameCn : item.nameEn}
                    {item.sideBarCount ? <span>{item.sideBarCount}</span> : <></>}
                  </div>
                  <div className={style.underline}></div>
                </div>
              );
            })}
            {/* 车型左侧导航 */}
            {this.runingOrderStore.cars.map((item, index) => {
              return (
                <div
                  className={
                    this.runingOrderStore.activeLevel === item.id
                      ? `${
                          this.props.language === 1
                            ? style.level
                            : style.level_en
                        } ${style.active}`
                      : this.props.language === 1
                      ? style.level
                      : style.level_en
                  }
                  key={item.id}
                  onClick={() => {
                    this.runingOrderStore.setActiveLevel(item.id)
                    this.setState({isScrolling:true });
                    // 延时后重置标志并更新状态
                    setTimeout(() => {
                      this.setState({isScrolling:false });
                    }, 800); // 根据实际情况调整延时
                    const element = this.sectionRefs[index].dom
                    element.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                    });
                  }}
                >
                  <div className={style.name}>
                    {this.isLanguage ? item.nameCn : item.nameEn}
                    {item.checkCount > 0 && this.mainStore.serverType !== 20 ? (
                      <span>{item.checkCount}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className={style.underline}></div>
                </div>
              );
            })}
            {
              this.state.isScrolling ? <div className={style.carlevel_mask}></div> : <></>
            }
          </div>
          <div className={style.cars} onScroll={this.handleScroll} ref={(ref) => (this.contentRef = ref)}>
            {/* 标签右侧预估 */}
            {this.runingOrderStore.carLabels.map((item, index) => {
              return (
                <div
                  className={`${style.car_smart} ${index === this.state.carLabels.length - 1 ? style.car_smart_border_bottom : style.car_smart_padding_top}`}
                  key={index}
                  ref={(dom) => {
                    this.smartRefs[index] = {dom: dom, id:item.dynamicCode};
                  }}
                >
                  {item.data.map((e, i) => {
                    return (
                      <div
                      key={i}
                        className={`${style.car_type} ${
                          i === item.data.length - 1 ? '' : style.car_bottom
                        }`}
                      >
                        <div className={style.left}>
                          <span className={style.car_source}>
                            {e.labelName}
                          </span>
                          <span className={style.car_desc}>{e.labelDesc}</span>
                          <div className={style.car_select} onClick={() => this.showCarSmart(e, i)}>
                            已选&nbsp;
                            {/* <span className={style.car_count}>{e.checkCount}/{e.carNum}</span>
                             */}
                             <span className={style.car_count}>{e.checkCount}</span>
                            &nbsp;个
                            <RightOutline color="#9DA3AB" fontSize={12} />
                          </div>
                        </div>
                        <div className={style.right}>
                          <div className={style.car_estimate}>
                            预估&nbsp;<span>{e.priceLab}</span>&nbsp;元
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
            {/* 车型右侧预估 */}
            {this.runingOrderStore.cars.map((item, index) => {
              return (
                <div
                  className={style.carpannel}
                  key={item.id}
                  ref={(dom) => {
                    this.sectionRefs[index] = {dom: dom, id: item.id};
                  }}
                >
                  <div className={style.head}>
                    <div>
                      {item.nameCn}
                      {this.t('estimate.k005')}
                      {item.deductionCn && item.deductionCn.length > 0 ? (
                        item.deductionCn.map((val) => {
                          return (
                            <span className={style.deduction_key}>
                              {val.key.txt}
                              <span
                                className={style.deduction_value}
                                style={{ color: val.value.color }}
                              >
                                {val.value.txt}
                              </span>
                            </span>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  <div className={style.carlist}>
                    {item.list.map((car, i) => {
                      return (
                        <div className={style.car} key={i}>
                          <div>
                            <img src={car?.carSourceImg} alt="" />
                          </div>
                          <div>
                            <div>{car.carSource}</div>
                          </div>
                          <div>
                            <div>
                              {this.t('estimate.k007')}
                              <span>{car.estimatePrice}</span>
                              {this.t('estimate.k018')}
                            </div>
                            {car.couponAmount ? (
                              <div>
                                {this.t('estimate.k021')}
                                <span>{car.couponAmount}</span>
                                {this.t('estimate.k018')}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <Popup
          onMaskClick={() => {
            this.setState({
              showSmartCar: false,
            });
          }}
          position="bottom"
          visible={this.state.showSmartCar}
          getContainer={() =>
            document.getElementsByClassName('_2N0GHZ0JjWmRKOLSgRjGMa')[0]
          }
          bodyClassName={style.popupMode}
          bodyStyle={{ minHeight: '30%' }}
        >
          <div className={style.smart_wrap}>
            <div className={style.smart_car}>
              <div className={style.select}>已选以下{this.state.smartLabelCar.length}个服务商</div>
            </div>
            {/* 选择车型 */}
            <div className={style.smart_car_list}>
              {this.state.smartLabelCar.map((car, index) => {
                return (
                  <div className={style.car} key={index}>
                    <div>
                      <img src={car?.carSourceImg} alt="" />
                    </div>
                    <div>
                      <div>{car.carSource}</div>
                    </div>
                    <div>
                      <div>
                        {this.t('estimate.k007')}
                        <span>{car.estimatePrice}</span>
                        {this.t('estimate.k018')}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={style.smart_car_btn}>
            <Button disabled={this.isDissatisfied} block style={
              {
                'background-image': 'var(--button_background_color)',
                '--border-color': 'var(--button_border_color)',
                'background-color': 'var(--button_border_color)',
                'color': 'var(--button_color)'
              }
            } size='middle' onClick={() => this.smartDetermine()}>确定</Button>
            </div>
          </div>
        </Popup>
        <div
          className={style.btncont}
          onClick={() => {
            this.setState({ showAppendCars: true })
            this.props.clearTimer()
          }}
        >
          <div
              className={`${style.btn} ${style.active}`}
            >
              <div>{this.t('runing.k019')}</div>
            </div>
        </div>
        <Popup
          visible={this.state.showAppendCars}
          getContainer={() =>
            document.getElementsByClassName('_2ropcmo-xzBRMIwc5hML2v')[0]
          }
          onMaskClick={() => {
            this.setState({
              showAppendCars: false
            })
          }}
        >
          {
            this.state.showAppendCars
              ? (<SmartAppendManualChoose t={this.props.t} history={this.props.history} orderId={this.props.orderId} changeAppend={this.changeAppend} />)
              : (<></>)
          }

        </Popup>
      </div>
    );
  }

  async componentDidMount() {
    this.props.onRef && this.props.onRef(this);
  }

  componentWillUnmount() {
    Dialog.clear();
  }
  
  changeAppend = () => {
    this.setState({
      showAppendCars: false
    })
    
    this.props.loadOrderBaseInfo();
  }

  /**
   * 推荐标签车型弹出
   */
  showCarSmart = (item, index) => {
    let list = this.runingOrderStore.carLabels;
    for (let i in list) {
      for (let j in list[i].data) {
        let row = list[i].data[j];
        if (row.id === item.id) {
          row.isShow = true;
          this.setState({
            showSmartCar: true,
            smartLabelCar: item.list,
          });
        } else {
          row.isShow = false;
        }
      }
    }
    this.runingOrderStore.setCarLabels(list)
  };

  smartDetermine = () => {
    this.setState({
      showSmartCar: false,
    });
  }

  handleScroll = () => {
    if (this.state.isScrolling) {
      return true
    }

    const scrollTop = this.contentRef.scrollTop;
    const scrollHeight = this.contentRef.scrollHeight;
    const clientHeight = this.contentRef.clientHeight;

    this.smartRefs.forEach(item => {
      let offsetTop = item.dom.offsetTop;
      let offsetHeight = item.dom.offsetHeight;
      let key = item.id;
      // 检查是否滚动到顶部
      if (scrollTop === 0) {
        this.runingOrderStore.setActiveLevel(this.smartRefs[0].id)
        return;
      }

      // 检测当前滚动位置是否在该section范围内
      if (scrollTop >= offsetTop && scrollTop < offsetTop + offsetHeight) {
        this.runingOrderStore.setActiveLevel(key)
      }
    })

    this.sectionRefs.forEach((item, index) => {
      let offsetTop = item.dom.offsetTop;
      let offsetHeight = item.dom.offsetHeight;
      let key = item.id;

       // 检查是否滚动到顶部
      if (scrollTop === 0 && this.smartRefs.length === 0) {
        this.runingOrderStore.setActiveLevel(this.sectionRefs[0].id)
        return;
      }

      // // 检查是否滚动到底部
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        this.runingOrderStore.setActiveLevel(key)
        return;
      }
       // 检测当前滚动位置是否在该section范围内
       if (scrollTop >= offsetTop && scrollTop < offsetTop + offsetHeight) {
        this.runingOrderStore.setActiveLevel(key)
       }
    })
  }
  handleShowPop = () => {
    this.setState({
      showAppendCars: true
    })
  }
}

export default SmartCarWaitingOrders;
