import { observable, action, makeObservable } from "mobx";

class ApprovalStore {

  constructor() {
    makeObservable(this)
  }

  // 默认月日期
  @observable startTime = ''
  @observable activeKey = ''

  // 设置日期
  @action.bound setApprovalTime(time) {
    this.startTime = time
  }

  // 设置tab点击key
  @action.bound setActiveKey(val) {
    this.activeKey = val
  }
}

export default new ApprovalStore();